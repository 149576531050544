import React, { Component } from "react";
import {Helmet} from "react-helmet";
import "./../assets/css/aboutus.css";



class Terms extends Component {

    constructor (props) {
      super(props)
      this.state = {
        startDate: new Date(),
        endDate: new Date(),
        MetaTitle:"EARental | Terms of service",
        MetaDescription:"EARental is Singapore No.1 Online Van, Lorry & Truck commercial vehicles for lease for businesses and individuals. Hassle-Free, book and reserve your vehicle Online Now!",
        MetaKeywords:"terms of service,online rental, van rental, lorry rental, truck rental, commercial vehicles for rent, leasing, daily rental, weekly rental, weekend rental, monthly rental, yearly rental, short term rental, long term rental, cargo van rental singapore, cheap van rental, van rental price, Toyota hiace van rental singapore, van rental daily, auto van rental singapore, commercial van for rent, van rental near me, daily van rental singapore",
        Canonical:"https://www.earental.com/security-deposit",
      };
    }



  componentDidMount() {
      window.scrollTo(0, 0)
    }

  render() {
    return (
        <div classNameName="about-us-cont">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="title" content={this.state.MetaTitle} />
                <meta content={this.state.MetaTitle} property="og:title" />
                <meta name="description" content={this.state.MetaDescription} />
                <meta content={this.state.MetaDescription} property="og:description" />
                <meta name="keywords" content={this.state.MetaKeywords} />
                <meta name="robots" content="index, follow" />
                <title>{this.state.MetaTitle}</title>
                <link rel="canonical" href={this.state.Canonical} />
            </Helmet>
            <div className="container">
                <h1 className="page-title">Terms of Service</h1>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <article className="post">
                            <header className="post-header"></header>

                            <div className="post-inner">
                                <h4>Thank you for using EARental!</h4>
                                <p>
                                These Terms of Service ("Terms") are a binding legal agreement between you and EARental that govern your use of the websites, applications, and other offerings from EARental (collectively, the "<b>EARental Platform</b>"). When used in these Terms, "<b>EARental</b>," "<b>we</b>," "<b>us</b>," or "<b>our</b>" refers to the EARental entity.
                                </p>

                                <p>
                                    The EARental Platform offers an online venue that enables users (“<b>Members</b>”) to publish, offer, search for, and book services. Members who publish and offer services are “<b>Lessor</b>” and Members who search for, book, or use services are “<b>Lessee</b>.” Lessor offer equipment, machinery, and/or commercial vehicles (“<b>Equipment</b>”). You must register an account to access and use many features of the EARental Platform, and must keep your account information accurate. As the provider of the EARental Platform, EARental does not own, control, offer or manage any Listings or Lease Services. EARental is not a party to the contracts concluded directly between Lessor and Lessee, nor is EARental a broker or insurer. EARental is not acting as an agent in any capacity for any Member, except as specified in the Payments Terms of Service (“<b>Payment Terms</b>”). To learn more about EARental’s role see Section 16.
                                </p>

                                <p>
                                    We maintain other Terms like our <a href="/privacy-policy">Privacy Policy</a> that supplement these Terms, which describes our collection and use of personal data, and our <a href="/payment-terms">Payments Terms</a>, which govern any payment services provided to Members by EARental payment ("<b>EARental Payments</b>").
                                </p>

                                <p>
                                    If you a Lessor, you are responsible for understanding and complying with all laws, rules, regulations and contracts with third parties that apply to your Lease Services.
                                </p>


                                <div className="gap gap-small"></div>
                                <h4>Lessee Terms</h4>
                                <h5>1. Our Mission.</h5>
                                <p>
                                    Our mission is to create a one-stop platform where you can lease anything. From fans, refrigerator, fork lift to trucks. Browse through thousands of Listings to find the ones that fit your needs. Learn more about a Listing by reviewing the description and photos.
                                </p>

                                <div className="gap gap-small"></div>

                                <h5>2. Searching and Booking on EARental.</h5>
                                <p>
                                    <b>2.1 Searching</b>. You can search for Lease Services by using criteria like the type of Lease Service, and rental dates. You can also use filters to refine your search results. Search results are based on their relevance to your search and other criteria. Relevance considers factors like price, and availability.
                                </p>
                                <p>
                                    <b>2.2 Booking</b>. When you book a Listing, you are agreeing to pay all charges for your booking including the Listing price, applicable fees like <a href="/service-fees">EARental’s service fee</a>, <a href="/offline-fee">offline fees</a>, <a href="/taxes">taxes</a>, and any other items identified during checkout (collectively, “Total Price”). You are also agreeing that EARental Payments may charge and collect any <a href="/security-deposit">security deposit</a> identified during checkout. When you receive the booking confirmation, a copy of the signed Lease Agreement Contract is formed directly between you and the Lessor. The cancellation policy and any other rules, standards, policies, or requirements identified in the Listing or during checkout for
                                </p>

                                <div className="gap gap-small"></div>

                                <h5>3. Cancellations, Refunds and Booking Modifications.</h5>
                                <p>
                                    <b>3.1 Cancellations and Refunds</b>. In general, if as a Lessee you cancel a reservation, the amount refunded to you is less S$200 cancellation fee that applies to that reservation. If the Lessor cancels, you will be eligible to receive S$100 in credit for rebooking.
                                </p>
                                <p>
                                    <b>3.2 Booking Modifications</b>. Lessee and Lessor are responsible for any booking modifications they agree to make via the EARental Platform or direct EARental customer service to make on their behalf ("Booking Modifications"), and agree to pay any additional amounts, fees, or taxes associated with any Booking Modification.
                                </p>

                                <div className="gap gap-small"></div>

                                <h5>4. Your Responsibilities and Assumption of Risk.</h5>
                                <p>
                                    <b>4.1 Your Responsibilities</b>. You are responsible and liable for your own acts and omissions and are also responsible for the acts and omissions of anyone who you allow to use the Equipment.
                                </p>
                                <p>
                                    <b>4.2 Your Assumption of Risk. You acknowledge that many activities carry inherent risks and agree that, to the maximum extent permitted by applicable law, you assume the entire risk arising out of your access to and use of the EARental Platform and any Content (as defined in Section 10). This means it is your responsibility to investigate the Lease Equipment to determine whether it is suitable for you. For example, Lessor Services may carry risk of illness, bodily injury, disability, or death, and you freely and willfully assume those risks by choosing to lease the Equipment. </b>
                                </p>

                                <div className="gap gap-small"></div>
                                <h4>Lessor Terms</h4>
                                <h5>5. Hosting on EARental.</h5>
                                <p>
                                    <b>5.1 Lessor</b>.  As a Lessor, EARental offers you the opportunity to share your equipment, machinery, or commercial vehicles with our vibrant community of Lessee - and earn money doing it. It’s easy to create a Listing and you are in control of how you lease - set your price, availability, and rules for each Listing.
                                </p>
                                <p>
                                    <b>5.2 Contracting with Lessee</b>.  When you accept a booking request, or receive a booking confirmation through the EARental Platform, you are entering into a contract directly with the Lessee, and are responsible for delivering your Lease Service under the terms and at the price specified in your Listing. You are also agreeing to pay applicable fees like <a href="/service-fees">EARental service fee</a> (and applicable <a href="/taxes">taxes</a>) for each booking. EARental Payments will deduct amounts you owe from your payout unless we and you agree to a different method. Any terms, policies or conditions that you include in any supplemental contract with Lessee must: (i) be consistent with the Terms, our Policies, and the information provided in your Listing, and (ii) be prominently disclosed in your Listing description.
                                </p>
                                <p>
                                    <b>5.3 Independence of Lessor</b>.  Your relationship with EARental is that of an independent individual or entity and not an employee, agent, joint venturer, or partner of EARental, except that EARental acts as a payment collection agent as described in the Payments Terms. EARental does not direct or control your Lease Service, and you agree that you have complete discretion whether and when to provide Lease Services, and at what price and on what term to offer them.
                                </p>

                                <div className="gap gap-small"></div>

                                <h5>6. Managing Your Listing.</h5>
                                <p>
                                    <b>6.1 Creating and Managing Your Listing</b>.  The EARental Platform provides tools that make it easy for you to set up and manage a Listing. Your Listing must include complete and accurate information about your Lease Service, your price, other charges like cleaning fees, transport fees, security deposits, and any rules or requirements that apply to your Lessee or Listing. You are responsible for keeping your Listing information (including calendar availability) and content (like photos) up-to-date and accurate at all times. We recommend that you obtain appropriate insurance for your Lease Services and suggest you carefully review policy terms and conditions including coverage details and exclusions. You may only maintain one Listing per Equipment.
                                </p>
                                <p>
                                    <b>6.2 Know Your Legal Obligations</b>.  You are responsible for understanding and complying with any laws, rules, regulations, and contracts with third parties that apply to your Listing or Lease Services. For example: Some equipment may require Lessee to have license to operate. Check your local rules to learn what rules apply to the Lease Services you plan to offer. Information we provide regarding legal requirements is for informational purposes only and you should independently confirm your obligations. You are responsible for handling and using personal data of Lesee and others in compliance with applicable privacy laws. If you have questions about how local laws apply you should always seek legal advice.
                                </p>
                                <p>
                                    <b>6.3 Search Ranking</b>.  The ranking of Listings in search results on the EARental Platform depends on a variety of factors, including these main parameter
                                </p>
                                <ul style={{marginLeft:"35px"}}>
                                    <li>Lessee search parameters (e.g. type of lease, time and duration of the lease, price range),</li>
                                    <li>Listing characteristics (e.g. price, calendar availability, number and quality of images, Reviews, type of Lease Service, Lessor status, age of the Listing, average Lessee popularity),</li>
                                    <li>Lessee booking experience (e.g. customer service and cancellation history of the Lessor, ease of booking),</li>
                                    <li>Lessor requirements (e.g. minimum lease period, booking cut-off time), and</li>
                                    <li>Lessee preferences (e.g. previous lease, saved Listings, location from where the Lessee is searching).</li>

                                </ul>
                                <p>
                                    Search results may appear different on our mobile application than they appear on our website. EARental may allow Lessor to promote their Listings in search or elsewhere on the EARental Platform by paying an additional fee. More information about the factors that determine how your Listing appears in search results, our current promotional programs (if any), and how we identify promoted Content can be found in our Help Center.
                                </p>
                                <p>
                                    <b>6.4 Your Responsibilities</b>.  You are responsible and liable for your own acts and omissions and are also responsible for the acts and omissions of anyone you allow to participate in providing your Lease Services. You are responsible for setting your price and establishing rules and requirements for your Listing. You must describe any and all fees and charges in your Listing description and may not collect any additional fees or charges outside the EARental Platform. Do not encourage Lessee to create third-party accounts, submit reviews, provide their contact information, or take other actions outside the EARental Platform in violation of our Policy.
                                </p>
                                <p>
                                    <b>6.5 Leasing as a Team or Organization</b>.  If you work with a co-lessor or lessor as part of a team, business, or other organization, the entity and each individual who participates in providing Lease Services is responsible and liable as a Lessor under these Terms. If you accept terms or enter into contracts, you represent and warrant that you are authorized to enter into contracts for and bind your team, business or other organization, and that each entity you use is in good standing under the laws of the place where it is established. If you perform other functions, you represent and warrant that you are authorized to perform those functions. If you instruct EARental to transfer a portion of your payout to a co-lessor or other Lessor, or to send payments to someone else, you must be authorized to do so, and are responsible and liable for the payment amounts and accuracy of any payout information you provide.
                                </p>
                                <p>
                                    <b>6.6 Your Assumption of Risk. You acknowledge that leasing carries inherent risks and agree that you assume the entire risk arising out of your access to and use of the EARental Platform, offering Lease Services, or any interaction you have with other Members whether in person or online. You agree that you have had the opportunity to investigate the EARental Platform and any laws, rules, regulations, or obligations that may be applicable to your Listings or Lease Services and that you are not relying upon any statement of law made by EARental.</b>
                                </p>

                                <div className="gap gap-small"></div>

                                <h5>7. Cancellations and Booking Modifications.</h5>
                                <p>
                                    <b>7.1 Cancellations</b>. In general, if a Lessee cancels a reservation before the lease commence, you will be credited with S$100 in loss of opportunity. The amount will be credited to your security deposit with EARental. As a Lessor, you should not cancel on a Lessee without a valid reason unless the said Equipment required urgent maintenance that make it unsafe to the Lessee. You’ll be asked to provide all of the following documents:
                                </p>
                                <ul style={{marginLeft:"35px"}}>
                                    <li>Proof that the issue is being fixed</li>
                                    <li>An estimate of when it will be fixed</li>
                                    <li>An invoice for the repairs being done</li>
                                    <li>Photos of the damage</li>
                                </ul>
                                <p>
                                    If you cancel on a Lessee without such valid reason, a S$200 cancellation penalty will be imposed. The amount will be deducted from your security deposits. If a Lessee receives a refund after you have already been paid, or the amount of the refund and other costs incurred by EARental exceeds your payout, EARental may recover that amount from you, including by offsetting the refund against your future payouts.
                                </p>
                                <p>
                                    <b>7.2 Booking Modifications</b>. Lessors and Lessees are responsible for any Booking Modifications they agree to make via the EARental Platform or direct EARental customer service to make on their behalf, and agree to pay any additional amounts, fees or taxes associated with a Booking Modification.
                                </p>

                                <div className="gap gap-small"></div>

                                <h5>8. Taxes.</h5>
                                <p>
                                    <b>8.1 Lessor Taxes</b>. As a Lessor, you are responsible for determining and fulfilling your obligations under applicable laws to report, collect, remit, or include in your price any applicable GST or other indirect taxes, income, or other taxes ("<b>Taxes</b>").
                                </p>
                                <p>
                                    <b>8.2 Tax Information</b>. In certain jurisdictions, Tax regulations may require that we collect and/or report Tax information about you, or withhold Taxes from payouts to you, or both. If you fail to provide us with documentation that we determine to be sufficient to support any such obligation to withhold Taxes from payouts to you, we may withhold payouts up to the amount as required by law, until sufficient documentation is provided. You agree that EARental may issue on your behalf invoices or similar documentation for GST, consumption or other Taxes for your Lease Services to facilitate accurate tax reporting by you, our Lessees, and/or their organizations.
                                </p>

                                <div className="gap gap-small"></div>
                                <h4>General Terms</h4>
                                <h5>9. Reviews.</h5>
                                <p>
                                    After each Lease Service, Lessees and Lessors will have an opportunity to review each other. Your Review must be accurate and may not contain any discriminatory, offensive, defamatory, or other language that violates our <a href="content-policy">Content Policy</a> or <a href="review-policy">Review Policy</a>. Reviews are not verified by EARental for accuracy and may be incorrect or misleading.
                                </p>

                                <div className="gap gap-small"></div>
                                <h5>10. Content.</h5>
                                <p>
                                    Parts of the EARental Platform enable you to provide feedback, text, photos, video, information, and other content (collectively, “<b>Content</b>”). By providing Content, in whatever form and through whatever means, you grant EARental a non-exclusive, worldwide, royalty-free, irrevocable, perpetual, sub-licensable and transferable license to copy, modify, prepare derivative works of, distribute, publish and otherwise exploit, that Content, without limitation. If Content includes personal information, our <a href="/privacy-policy">Privacy Policy</a> describes how we use that personal information. Where EARental pays for the creation of Content or facilitates its creation, EARental may own that Content, in which case supplemental terms or disclosures will say that. You are solely responsible for all Content that you provide and warrant that you either own it or are authorized to grant EARental the rights described in these Terms. You are responsible and liable if any of your Content violates or infringes the intellectual property or privacy rights of any third party. Content must comply with our <a href="/content-policy">Content Policy</a> and Nondiscrimination Policy, which prohibit, among other things, discriminatory, obscene, harassing, deceptive, violent, and illegal content. You agree that EARental may make available services or automated tools to translate Content and that your Content may be translated using such services or tools. EARental does not guarantee the accuracy or quality of translations and Members are responsible for confirming the accuracy of such translations.
                                </p>

                                <div className="gap gap-small"></div>
                                <h5>11. Fees.</h5>
                                <p>
                                    EARental may charge fees (and applicable Taxes) to Lessors and Lessees for use of the EARental Platform. More information about when service fees apply and how they are calculated can be found on our <a href="service-fees">Service Fees page</a>. Except as otherwise provided on the EARental Platform, service fees are non-refundable. EARental reserves the right to change the service fees at any time, and will provide Members notice of any fee changes before they become effective. Fee changes will not affect bookings made prior to the effective date of the fee change. If you disagree with a fee change you may terminate this agreement at any time pursuant to Section 13.2.
                                </p>

                                <div className="gap gap-small"></div>
                                <h5>12. EARental Platform Rules.</h5>
                                <p>
                                    <b>12.1 Rules</b>. You must follow these rules and must not help or induce others to break or circumvent these rules.
                                </p>
                                <ul style={{marginLeft:"35px"}}>
                                    <li>
                                        Act with integrity and treat others with respect
                                        <ul style={{marginLeft:"35px"}}>
                                            <li>Do not lie, misrepresent something or someone, or pretend to be someone else.</li>
                                            <li>Be polite and respectful when you communicate or interact with others.</li>
                                            <li>Follow our Nondiscrimination Policy and do not discriminate against or harass others.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        Do not scrape, hack, reverse engineer, compromise or impair the EARental Platform
                                        <ul style={{marginLeft:"35px"}}>
                                            <li>Do not use bots, crawlers, scrapers, or other automated means to access or collect data or other content from or otherwise interact with the EARental Platform.</li>
                                            <li>Do not hack, avoid, remove, impair, or otherwise attempt to circumvent any security or technological measure used to protect the EARental Platform or Content.</li>
                                            <li>Do not decipher, decompile, disassemble, or reverse engineer any of the software or hardware used to provide the EARental Platform.</li>
                                            <li>Do not take any action that could damage or adversely affect the performance or proper functioning of the EARental Platform.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        Only use the EARental Platform as authorized by these Terms or another agreement with us
                                        <ul style={{marginLeft:"35px"}}>
                                            <li>You may only use another Member’s personal information as necessary to facilitate a transaction using the EARental Platform as authorized by these Terms.</li>
                                            <li>Do not use the EARental Platform, our messaging tools, or Members’ personal information to send commercial messages without the recipient’s express consent.</li>
                                            <li>You may use Content made available through the EARental Platform solely as necessary to enable your use of the EARental Platform as a Lessee or Lessor.</li>
                                            <li>Do not use Content unless you have permission from the Content owner or the use is authorized by us in these Terms or another agreement you have with us.</li>
                                            <li>Do not request, make, or accept a booking or any payment outside of the EARental Platform to avoid paying fees, taxes or for any other reason. </li>
                                            <li>Do not require or encourage Lessees to open an account, leave a review, complete a survey, or otherwise interact, with a third party website, application or service before, during or after a reservation, unless authorized by EARental.</li>
                                            <li>Do not engage in any practices that are intended to manipulate our search algorithm.</li>
                                            <li>Do not book Lease Services unless you are actually using the Lease Services.</li>
                                            <li>Do not use, copy, display, mirror or frame the EARental Platform, any Content, any EARental branding, or any page layout or design without our consent.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        Honor your legal obligations
                                        <ul style={{marginLeft:"35px"}}>
                                            <li>Understand and follow the laws that apply to you, including privacy, data protection, and export laws.</li>
                                            <li>If you provide us with someone else’s personal information, you: (i) must do so in compliance with applicable law, (ii) must be authorized to do so, and (iii) authorize us to process that information under our <a href="/privacy-policy">Privacy Policy</a>.</li>
                                            <li>Do not use the name, logo, branding, or trademarks of EARental or others without permission.</li>
                                            <li>Do not use or register any domain name, social media handle, trade name, trademark, branding, logo, or other source identifier that may be confused with EARental branding.</li>
                                            <li>Do not offer Lease Services that violate the laws or agreements that apply to you.</li>
                                            <li>Do not offer or solicit prostitution or participate in or facilitate human trafficking.</li>
                                        </ul>
                                    </li>
                                </ul>

                                <p>
                                    <b>12.2 Reporting Violations</b>. If you believe that a Member, Listing or Content poses an imminent risk of harm to a person or property, you should immediately contact local authorities before contacting EARental. In addition, if you believe that a Member, Listing or Content has violated our Standards, you should report your concerns to EARental. If you reported an issue to local authorities, EARental may request a copy of that report. Except as required by law, you agree that we are not obligated to take action in response to any report.
                                </p>
                                <p>
                                    <b>12.3 Copyright Notifications</b>. If you believe that Content on the EARental Platform infringes copyrights, please notify us in accordance with our <a href="/copyright-policy">Copyright Policy</a>.
                                </p>

                                <div className="gap gap-small"></div>
                                <h5>13. Termination, Suspension and other Measures.</h5>
                                <p>
                                    <b>13.1 Term</b>. The agreement between you and EARental reflected by these Terms is effective when you access the EARental Platform (for example to create an account) and remains in effect until either you or we terminate the agreement in accordance with these Terms.
                                </p>

                                <p>
                                    <b>13.2 Termination</b>. You may terminate this agreement at any time by sending us <a href="mailto:support@EARental.com">an email</a> or by deleting your account. EARental may terminate this agreement and your account for any reason by giving you 30 days’ notice via email or using any other contact information you have provided for your account. EARental may also terminate this agreement immediately and without notice and stop providing access to the EARental Platform if you breach these Terms, you violate our Policies, you violate applicable laws, or we reasonably believe termination is necessary to protect EARental, its Members, or third parties. If your account has been inactive for more than two years, we may terminate your account without prior notice.
                                </p>
                                <p>
                                    <b>13.3 Member Violations</b>. If (i) you breach these Terms, our Policies, or our Standards, (ii) you violate applicable laws, regulations, or third-party rights, or (iii) EARental believes it is reasonably necessary to protect EARental, its Members, or third parties; EARental may, with or without prior notice:
                                </p>
                                <ul style={{marginLeft:"35px"}}>
                                    <li>suspend or limit your access to or use of the EARental Platform and/or your account;</li>
                                    <li>suspend or remove Listings, Reviews, or other Content;</li>
                                    <li>cancel pending or confirmed bookings; or</li>
                                    <li>suspend or revoke any special status associated with your account.</li>
                                </ul>
                                <p>
                                    For minor violations or where otherwise appropriate as EARental determines in its sole discretion, you will be given notice of any intended measure by EARental and an opportunity to resolve the issue. You may appeal actions taken by us under this Section by contacting customer service. If a reservation is cancelled under this Section, the amount paid to the Lessor will be reduced by the amount we refund or otherwise provide to the Lessee, and by any other costs we incur as a result of the cancellation.
                                </p>
                                <p>
                                    <b>13.4 Legal Mandates</b>. EARental may take any action it determines is reasonably necessary to comply with applicable law, or the order or request of a court, law enforcement, or other administrative agency or governmental body, including the measures described above in Section 13.3.
                                </p>
                                <p>
                                    <b>13.5 Effect of Termination</b>. If you are a Lessor and terminate your EARental account, any confirmed booking(s) will be automatically cancelled and your Lessees will receive a full refund. Cancellation penalty will be applied for each outstanding booking. If you terminate your account as a Lessee, any confirmed booking(s) will be automatically cancelled and any refund will less the cancellation fees for each outstanding booking. When this agreement has been terminated, you are not entitled to a restoration of your account or any of your Content. If your access to or use of the EARental Platform has been limited, or your EARental account has been suspended, or this agreement has been terminated by us, you may not register a new account or access or use the EARental Platform through an account of another Member.
                                </p>
                                <p>
                                    <b>13.6 Survival</b>. Parts of these Terms that by their nature survive termination, will survive termination of this agreement, including Sections 2 through 26.
                                </p>

                                <div className="gap gap-small"></div>
                                <h5>14. Modification.</h5>
                                <p>
                                    EARental may modify these Terms at any time. When we make material changes to these Terms, we will post the revised Terms on the EARental Platform and update the “Last Updated” date at the top of these Terms. We will also provide you with notice of any material changes by email at least 30 days before the date they become effective. If you disagree with the revised Terms, you may terminate this agreement immediately as provided in these Terms. If you do not terminate your agreement before the date the revised Terms become effective, your continued access to or use of the EARental Platform will constitute acceptance of the revised Terms.
                                </p>

                                <div className="gap gap-small"></div>
                                <h5>15. Damage Claims.</h5>
                                <p>
                                    If a Lessor provides evidence that a Lessee damaged their property ("<b>Damage Claim</b>"), the Lessor can seek compensation through the Lessor Dashboard upon returning of Equipment by Lessee. Filing must be completed within 14 days upon returning of Equipment. EARental can collect any sums required to cover the Damage Claim via EARental Payments against the security deposit. Thereafter, all claims are denied. You agree that EARental may seek to recover from you under any insurance policies you maintain and that EARental may also pursue against you any remedies it may have available under applicable law. You agree to cooperate in good faith, provide any information EARental requests, execute documents, and take further reasonable action, in connection with Damage Claims, claims under insurance policies, or other claims related to your provision or use of Lease Services.
                                </p>

                                <div className="gap gap-small"></div>
                                <h5>16. EARental’s Role.</h5>
                                <p>
                                    We offer a platform that enables Members to publish, offer, search for, and book Lease Services. While we work hard to ensure our Members have great experiences using EARental, we do not and cannot control the conduct of Lessees and Lessors. You acknowledge that EARental has the right, but does not have any obligation, to monitor the use of the EARental Platform and verify information provided by our Members. For example, we may review, disable access to, remove, or edit Content to: (i) operate, secure and improve the EARental Platform (including for fraud prevention, risk assessment, investigation and customer support purposes); (ii) ensure Members’ compliance with these Terms; (iii) comply with applicable law or the order or requirement of a court, law enforcement or other administrative agency or governmental body; (iv) address Content that we determine is harmful or objectionable; (v) take actions set out in these Terms; and (vi) maintain and enforce any quality or eligibility criteria, including by removing Listings that don’t meet quality and eligibility criteria. Members acknowledge and agree that EARental administers its Policies and Standards, including decisions about whether and how to apply them to a particular situation, at its sole discretion. Members agree to cooperate with and assist EARental in good faith, and to provide EARental with such information and take such actions as may be reasonably requested by EARental with respect to any investigation undertaken by EARental regarding the use or abuse of the EARental Platform. EARental is not acting as an agent for any Member except for where EARental Payments acts as a collection agent as provided in the Payments Terms.
                                </p>

                                <div className="gap gap-small"></div>
                                <h5>17. Member Accounts.</h5>
                                <p>
                                    You must register an account to access and use many features of the EARental Platform. Registration is only permitted for legal entities, partnerships and natural persons who are 18 years or older. You represent and warrant that you are not a person or entity barred from using the EARental Platform under the laws of the Singapore, your place of residence, or any other applicable jurisdiction. You must provide accurate, current, and complete information during registration and keep your account information up-to-date. You may not register more than one account or transfer your account to someone else. You are responsible for maintaining the confidentiality and security of your account credentials and may not disclose your credentials to any third party. You are responsible and liable for activities conducted through your account and must immediately notify EARental if you suspect that your credentials have been lost, stolen, or your account is otherwise compromised. If and as permitted by applicable law, we may, but have no obligation to (i) ask you to provide identification or other information, (ii) undertake checks designed to help verify your identity or background, (iii) screen you against third-party databases or other sources and request reports from service providers, and (iv) obtain reports from public records of criminal convictions or sex offender registrations or their local equivalents.
                                </p>

                                <div className="gap gap-small"></div>
                                <h5>18. Disclaimer of Warranties.</h5>
                                <p>
                                    <b>We provide the EARental Platform and all Content “as is” without warranty of any kind and we disclaim all warranties, whether express or implied. For example: (i) we do not endorse or warrant the existence, conduct, performance, safety, quality, legality or suitability of any Lessee, Lessor, Lease Service, Listing or third party; (ii) we do not warrant the performance or non-interruption of the EARental Platform; and (iii) we do not warrant that verification, identity or background checks conducted on Listings or Members (if any) will identify past misconduct or prevent future misconduct. Any references to a Member or Listing being "verified" (or similar language) indicate only that the Member or Listing or EARental has completed a relevant verification or identification process and nothing else. The disclaimers in these Terms apply to the maximum extent permitted by law. If you have statutory rights or warranties we cannot disclaim, the duration of any such statutorily required rights or warranties, will be limited to the maximum extent permitted by law.</b>
                                </p>

                                <div className="gap gap-small"></div>
                                <h5>19. Limitations on Liability.</h5>
                                <p>
                                    <b>Neither EARental (including its affiliates and personnel) nor any other party involved in creating, producing, or delivering the EARental Platform or any Content will be liable for any incidental, special, exemplary or consequential damages, including lost profits, loss of data or loss of goodwill, service interruption, computer damage or system failure or the cost of substitute products or services, or for any damages for personal or bodily injury or emotional distress arising out of or in connection with (i) these Terms, (ii) the use of or inability to use the EARental Platform or any Content, (iii) any communications, interactions or meetings you may have with someone you interact or meet with through, or as a result of, your use of the EARental Platform, or (iv) publishing or booking of a Listing, including the provision or use of Lease Services, whether based on warranty, contract, tort (including negligence), product liability or any other legal theory, and whether or not EARental has been informed of the possibility of such damage, even if a limited remedy set out in these Terms is found to have failed of its essential purpose.</b>
                                </p>
                                <p>
                                    <b>Except for our obligation to transmit payments to Lessors under these Terms, in no event will EARental’s aggregate liability for any claim or dispute arising out of or in connection with these Terms, your interaction with any Members, or your use of or inability to use the EARental Platform, any Content, or any Lease Service, exceed: (A) to Lessees, the amount you paid as a Lessee during the 12-month period prior to the event giving rise to the liability, (B) to Lessors, the amount paid to you as a Lessor in the 12-month period prior to the event giving rise to the liability, or (C) to anyone else, one hundred Singapore Dollars (S$100).</b>
                                </p>
                                <p>
                                    <b>These limitations of liability and damages are fundamental elements of the agreement between you and EARental. If applicable law does not allow the limitations of liability set out in these Terms, the above limitations may not apply to you.</b>
                                </p>

                                <div className="gap gap-small"></div>
                                <h5>20. Indemnification.</h5>
                                <p>
                                    <b>To the maximum extent permitted by applicable law, you agree to release, defend (at EARental’s option), indemnify, and hold EARental (including EARental Payments, other affiliates, and their personnel) harmless from and against any claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with: (i) your breach of these Terms (including any supplemental or additional terms that apply to a product or feature) or our Policies or Standards, (ii) your improper use of the EARental Platform, (iii) your interaction with any Member, leasing of Equipment, or other Lease Service, including without limitation any injuries, losses or damages (whether compensatory, direct, incidental, consequential or otherwise) of any kind arising in connection with or as a result of such interaction, stay, participation or use, (iv) your failure, or our failure at your direction, to accurately report, collect or remit Taxes, or (v) your breach of any laws, regulations or third party rights such as intellectual property or privacy rights.</b>
                                </p>

                                <div className="gap gap-small"></div>
                                <h5>21. Contracting Entities.</h5>
                                <p>
                                    Based on your country of residence or establishment and what you are doing on the EARental Platform, Schedule 1 below sets out the EARental entity with whom you are contracting. If we identify through the EARental Platform, an EARental entity other than the one set out on Schedule 1 as being responsible for a product, feature or transaction, the EARental entity so identified is your contracting entity with respect to that product, feature or transaction. If you change your country of residence or establishment, the EARental company you contract with (as set out on Schedule 1) and the applicable version of the Terms of Service will be determined by your new country of residence or establishment, from the date on which your country of residence or establishment changes.
                                </p>

                                <div className="gap gap-small"></div>
                                <h5>22. Governing Law and Jurisdiction.</h5>
                                <p>
                                    These Terms will be interpreted, governed by and construed in accordance with the laws of the Republic of Singapore.
                                </p>

                                <div className="gap gap-small"></div>
                                <h5>23. Severability.</h5>
                                <p>
                                    If and insofar as any part or provision of these Terms of Service is or becomes illegal, void or unenforceable under any applicable laws, it shall be deemed not to be or never to have been or formed a part of these Terms of Service and the remaining provisions of these Terms of Service shall continue in full force and effect.
                                </p>

                                <div className="gap gap-small"></div>
                                <h5>24. Survival. </h5>
                                <p>
                                    This Section 24 will survive any termination of these Terms and will continue to apply even if you stop using the EARental Platform or terminate your EARental account.
                                </p>

                                <div className="gap gap-small"></div>
                                <h5>25. Miscellaneous. </h5>
                                <p>
                                    <b>25.1 Other Terms Incorporated by Reference</b>. Our <a href="/content-policy">Content Policy</a>, <a href="/review-policy">Review Policy</a> and other supplemental policies and terms linked to in these Terms apply to your use of the EARental Platform, are incorporated by reference, and form part of your agreement with EARental.
                                </p>

                                <p>
                                    <b>25.2 Interpreting these Terms</b>. Except as they may be supplemented by additional terms, conditions, policies, guidelines, standards, and in-product disclosures, these Terms (including those items incorporated by reference) constitute the entire agreement between EARental and you pertaining to your access to or use of the EARental Platform and supersede any and all prior oral or written understandings or agreements between EARental and you. These Terms do not and are not intended to confer any rights or remedies upon anyone other than you and EARental. Where the word “will” is used in these Terms it connotes an obligation with the same meaning as “shall.”
                                </p>

                                <p>
                                    <b>25.3 No Waiver</b>. EARental’s failure to enforce any right or provision in these Terms will not constitute a waiver of such right or provision unless acknowledged and agreed to by us in writing. Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise permitted under law.
                                </p>

                                <p>
                                    <b>25.4 Assignment</b>. You may not assign, transfer or delegate this agreement or your rights and obligations hereunder without EARental's prior written consent. EARental may without restriction assign, transfer or delegate this agreement and any rights and obligations hereunder, at its sole discretion, with 30 days’ prior notice.
                                </p>

                                <p>
                                    <b>25.5 Notice</b>. Unless specified otherwise, any notices or other communications to Members permitted or required under this agreement, will be provided electronically and given by EARental via email, EARental Platform notification, messaging service (including SMS and Whatsapp), or any other contact method we enable and you provide.
                                </p>

                                <p>
                                    <b>25.6 Third-Party Services</b>. The EARental Platform may contain links to third-party websites, applications, services or resources (“Third-Party Services”) that are subject to different terms and privacy practices. EARental is not responsible or liable for any aspect of such Third-Party Services and links to such Third-Party Services are not an endorsement.
                                </p>

                                <p>
                                    <b>25.7 Google Terms</b>. Some translations on the EARental Platform are powered by Google. Google disclaims all warranties related to the translations, express or implied, including any warranties of accuracy, reliability, and any implied warranties for merchantability, fitness for a particular purpose and non-infringement. Some areas of the EARental Platform implement Google Maps/Earth mapping services, including Google Maps API(s). Your use of Google Maps/Earth is subject to the <a href="https://www.google.com/help/terms_maps/" target="_blank">Google Maps/Google Earth Additional Terms of Service</a>.
                                </p>

                                <p>
                                    <b>25.8 Apple Terms</b>. If you access or download our application from the Apple App Store, you agree to <a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/" target="_blank">Apple’s Licensed Application End User License Agreement</a>.
                                </p>

                                <p>
                                    <b>25.9 EARental Platform Content</b>. Content made available through the EARental Platform may be protected by copyright, trademark, and/or other laws of the Republic of Singapore and other countries. You acknowledge that all intellectual property rights for that Content are the exclusive property of EARental and/or its licensors and agree that you will not remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices. You may not use, copy, adapt, modify, prepare derivative works of, distribute, license, sell, transfer, publicly display, publicly perform, transmit, broadcast or otherwise exploit any Content accessed through the EARental Platform except to the extent you are the legal owner of that Content or as expressly permitted in these Terms. Subject to your compliance with these Terms, EARental grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferable license to (i) download and use the Application on your personal device(s); and (ii) access and view the Content made available on or through the EARental Platform and accessible to you, solely for your personal and non-commercial use.
                                </p>

                                <p>
                                    <b>25.10 Force Majeure</b>. EARental shall not be liable for any delay or failure to perform resulting from causes outside its reasonable control, including, but not limited to, acts of God, natural disasters, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, pandemics, epidemics or disease, strikes or shortages of transportation facilities, fuel, energy, labor or materials.
                                </p>

                                <p>
                                    <b>25.11 Emails and SMS</b>. You will receive administrative communications from us using the email address or other contact information you provide for your EARental account. Enrollment in additional email subscription programs will not affect the frequency of these administrative emails, though you should expect to receive additional emails specific to the program(s) to which you have subscribed. You may also receive promotional emails from us. No fee is charged for these promotional emails, but third-party data rates could apply. You can control whether you receive promotional emails using the notification preferences in your account settings. Please note that you will not be able to take advantage of certain promotions if you disable certain communication settings or do not have an EARental account.
                                </p>




                            </div>

                        </article>
                    </div>
                </div>
            </div>

        </div>
    );
  }
}

export default Terms;
