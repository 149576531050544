import React,{Component} from 'react';
import {Link,NavLink, BrowserRouter} from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import PeopleIcon from '@material-ui/icons/People';
import ListAltIcon from '@material-ui/icons/ListAlt';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PaymentIcon from '@material-ui/icons/Payment';
import DescriptionIcon from '@material-ui/icons/Description';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import { store } from 'react-notifications-component';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';

class Sidebar extends Component {
    static propTypes = {
        cookies: Cookies
    };
    constructor (props) {
      super(props)
      this.state = {
        currentPendingSchedule: 0
      }

    }

    componentDidMount() {

        var ctoken = localStorage.getItem('ctoken');
        var token = localStorage.getItem('token');
        if(ctoken && token){
            this.getPendingSchedule();
            setInterval(this.getPendingSchedule.bind(this), 30000);
        }

    }
    getPendingSchedule() {
        const { cookies } = this.props;
        var ctoken = localStorage.getItem('ctoken');
        var token = localStorage.getItem('token');

        var bodyFormData = new FormData();
        bodyFormData.append('access_token', ctoken);
        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
        bodyFormData.append('jham_token', token);
        bodyFormData.append('limit', 1);
        bodyFormData.append('offset', 0);
        bodyFormData.append('active', "Active");
        bodyFormData.append('pending_only', "1");

        axios({
                method: 'post',
                url: 'https://api.earental.com/lessor/lessor_schedule',
                data: bodyFormData,
                withCredentials: true,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then((response) => {

                console.log(response.data.lessor_ongoing_rental_total);
                this.setState({
                  currentPendingSchedule: response.data.lessor_ongoing_rental_total
                })

            })
            .catch((error) => {

        });


    }

      render() {

          return (
              <>
                  <div>
                    <ListItem button component={NavLink} exact to="/" >
                      <ListItemIcon>
                        <DashboardIcon />
                      </ListItemIcon>
                      <ListItemText primary="Dashboard" />
                    </ListItem>
                    <ListItem button component={NavLink} to="/lease" >
                      <ListItemIcon>
                        <AirportShuttleIcon />
                      </ListItemIcon>
                      <ListItemText primary={<Badge badgeContent={this.state.currentPendingSchedule} color="secondary">
                            Lease &nbsp;&nbsp;
                        </Badge>} />
                    </ListItem>
                    <ListItem button component={NavLink} to="/vehicles">
                      <ListItemIcon>
                        <DriveEtaIcon />
                      </ListItemIcon>
                      <ListItemText primary="Vehicles" />
                    </ListItem>
                    {/* <ListItem button component={NavLink} to="/agreement-contract" >
                      <ListItemIcon>
                        <ListAltIcon />
                      </ListItemIcon>
                      <ListItemText primary="Agreement Contract" />
                    </ListItem> */}
                    <ListItem button component={NavLink} to="/lease-agreement" >
                      <ListItemIcon>
                        <ListAltIcon />
                      </ListItemIcon>
                      <ListItemText primary="Lease Agreement" />
                    </ListItem>
                    <ListItem button component={NavLink} to="/insurance-letter" >
                      <ListItemIcon>
                        <DescriptionIcon />
                      </ListItemIcon>
                      <ListItemText primary="Insurance Letter" />
                    </ListItem>
                    <ListItem button component={NavLink} to="/payout-method" >
                      <ListItemIcon>
                        <PaymentIcon />
                      </ListItemIcon>
                      <ListItemText primary="Payout Method" />
                    </ListItem>
                    <ListItem button component={NavLink} to="/users" >
                      <ListItemIcon>
                        <PeopleAltIcon />
                      </ListItemIcon>
                      <ListItemText primary="Users" />
                    </ListItem>
                    <ListItem button component={NavLink} to="/rental-promotions" >
                      <ListItemIcon>
                        <LocalOfferIcon />
                      </ListItemIcon>
                      <ListItemText primary="Rental Promotions" />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon>
                        <BarChartIcon />
                      </ListItemIcon>
                      <ListItemText primary="Reports" />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon>
                        <LayersIcon />
                      </ListItemIcon>
                      <ListItemText primary="Integrations" />
                    </ListItem>
                  </div>
                    <Divider />
                  <div>
                    <ListSubheader>Saved reports</ListSubheader>
                    <ListItem button>
                      <ListItemIcon>
                        <AssignmentIcon />
                      </ListItemIcon>
                      <ListItemText primary="Current month" />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon>
                        <AssignmentIcon />
                      </ListItemIcon>
                      <ListItemText primary="Last quarter" />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon>
                        <AssignmentIcon />
                      </ListItemIcon>
                      <ListItemText primary="Year-end sale" />
                    </ListItem>
                  </div>
              </>
          );
  }
}

export default Sidebar
