import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { store } from 'react-notifications-component';
import axios from 'axios';
import ContentLoader, { Facebook } from "react-content-loader";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { grey } from '@material-ui/core/colors';
import ReactGA from 'react-ga';
import CheckoutStepper from './CheckoutStepper';
import CheckoutDetails from './CheckoutDetails';
import NewAddress from './NewAddress';
import EditAddress from './EditAddress';
import "../assets/css/checkout.css";

const useStyles = theme => ({
  layout: {
    padding: theme.spacing(5, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
    }
  },
  title: {
    fontWeight: 300,
    marginBottom: theme.spacing(3),
  },
  address: {
    marginTop: theme.spacing(3),
    '& .MuiButtonBase-root': {
      fontSize: '1.1rem',
    },
    '& .MuiTypography-body1': {
      fontSize: '1.25rem',
    },
  },
  agreementCheckbox: {
    transform: 'scale(1.25)',
  },
  addressLoader: {
    '& .MuiCardContent-root': {
      height: 155,
    },
  },
  addressCard: {
    '& .MuiCardContent-root': {
      padding: theme.spacing(3, 3, 0),
      '& h5': {
        fontSize: '1.75rem',
      },
      '& p': {
        fontSize: '1.25rem',
        lineHeight: '1.5em',
        maxHeight: '3em',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
      },
    },
    '& button': {
      fontSize: '1.1rem !important',
      fontWeight: '600',
    },
  },
  addressSelected: {
    border: '3px solid #D40029',
  },
  newAddress: {
    color: grey[600],
    cursor: 'pointer',
    '& .MuiCardContent-root': {
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 155,
      '& > *': {
        color: '#2F96B4',
        fontSize: '1.5rem',
        fontWeight: 500,
      },
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
      },
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    color: "#FFF",
    backgroundColor: "#262626",
    padding: theme.spacing(2) - 4,
    '& span': {
      fontSize: '1.1rem',
    },
  },
  gridItem1: {
    order: 2,
    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  gridItem2: {
    order: 1,
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  }
});

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);

    this.api = {
      url: props.mainState.apiURL,
      key: props.mainState.apiKey,
      accessToken: localStorage.getItem('ctoken'),
      jhamToken: localStorage.getItem('token'),
      // jhamToken: "8862828b9645d204ed6c070cfa043644",
    };

    this.state = {
      loading: true,
      addresses: [],
      billingAddress: {
        id: 0,
        purpose: "personal",
        firstName: "",
        lastName: "",
        nric: "",
        type: "",
        block: "",
        street: "",
        floor: "",
        unit: "",
        building: "",
        country: "Singapore",
        postal: "",
        company: "",
        roc: "",
        telephone: "",
        mobile: "",
        returnAgreement: false,
      },
      currentVehicle: null,
      schedule: null,
      popover: null,
      openAddDialog: false,
      openEditDialog: false,
      addressToEdit: null,
      rawCheckout: null,
      checkoutStepsCompleted: [],
    };
  };

  componentDidMount() {
    // Unset checkout progress
    localStorage.removeItem('checkoutProgress');
    this.props.handlerState('checkoutProgress', null);

    // Get current checkout data
    let formData = new FormData();
        formData.append('api_key', this.api.key);
        formData.append('access_token', this.api.accessToken);
        formData.append('jham_token', this.api.jhamToken);
    axios({
      method: 'post',
      url: this.api.url + '/transaction/current_checkout',
      data: formData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      if (response.data.status == 1) {
        var checkout = response.data.checkout;
        var addonsTotal = 0;
        var additionalsArr = checkout.data[0].addons;
        additionalsArr.map((addon) => {
          if (addon.checked && addon.qty > 0) {
            addonsTotal += parseFloat(addon.price) * addon.qty;
          }
        });
        var currentVehicleData = {
          name: checkout.data[0].product_name,
          image: checkout.data[0].product_image,
        };
        var scheduleData = {
          startDate: checkout.date_from,
          endDate: checkout.date_to,
          rateTitle: checkout.summary.currentRateTitle,
          rateSubTitle: checkout.summary.currentRateSubTitle,
          rateCount: checkout.summary.currentRateCount,
          rateType: checkout.summary.currentRateType,
          rate: parseFloat(checkout.summary.currentRateValue),
          total: parseFloat(checkout.summary.currentTotalRate),
          deposit: parseFloat(checkout.summary.currentDeposit),
          totalAddons: parseFloat(addonsTotal),
          rentalTotal: parseFloat(checkout.summary.rentalTotal),
          discountAmount: parseFloat(checkout.summary.currentPromoDiscount) + parseFloat(checkout.summary.creditBalanceUsed),
          freeDays: parseInt(checkout.bonus_day),
          freeMonths: parseInt(checkout.bonus_month),
        };
        console.log(scheduleData);
        // Get completed checkout steps
        var checkoutStepsCompleted = [];
        if (checkout.billingAddress)
          checkoutStepsCompleted.push(1);
        if (checkout.selectedDrivers)
          checkoutStepsCompleted.push(2);
        if (checkout.leasingAgreement)
          checkoutStepsCompleted.push(3);
        if (checkout.insuranceAgreement)
          checkoutStepsCompleted.push(4);

        // Get customer addresses
        axios({
          method: 'post',
          url: this.api.url + '/account/customer_address_list',
          data: formData,
          withCredentials: true,
          headers: {'Content-Type': 'multipart/form-data'}
        })
        .then((response) => {
          let addresses = [];
          let addressIds = [];
          response.data.data.map((address, i) => {
            addresses.push({
              id: address.id,
              purpose: address.purpose,
              firstName: address.billing_first_name,
              lastName: address.billing_last_name,
              nric: address.billing_nric,
              nricFrontImg: address.billing_nric_front_img,
              nricBackImg: address.billing_nric_back_img,
              type: address.billing_type,
              block: address.billing_block,
              street: address.billing_street,
              floor: address.billing_floor,
              unit: address.billing_unit,
              building: address.billing_building,
              country: address.billing_country,
              postal: address.billing_postal,
              company: address.billing_company,
              roc: address.billing_roc,
              acra: address.billing_acra,
              telephone: address.billing_phone,
              mobile: address.billing_mobile,
            });
            addressIds.push(address.id);
          });
          this.setState({
            loading: false,
            currentVehicle: currentVehicleData,
            schedule: scheduleData,
            rawCheckout: checkout,
            addresses: addresses,
            checkoutStepsCompleted: checkoutStepsCompleted,
          });
          if (checkout.billingAddress) {
            if (addressIds.indexOf(checkout.billingAddress.id) > -1) {
              this.setState({ billingAddress: checkout.billingAddress });
            }
          }

          // Set checkout progress
          localStorage.setItem('checkoutProgress', 1);
          this.props.handlerState('checkoutProgress', 1);
        })

      } else {
        var reload = false;
        var redirect = false;
        var redirectURL = "";
        var errorTitle = "";
        var errorMsg = "";
        switch (response.data.error) {
          case 1:
          case 2:
            reload = true;
            redirect = true;
            redirectURL = "/sign-in";
            errorTitle = "Your session has expired";
            errorMsg = "Please login again. This page will automatically reload.";
            break;
          case 3:
            let listingFilters = localStorage.getItem('listing_filters');
                listingFilters = JSON.parse(listingFilters);
            redirectURL = "/vehicles/van-rental";
            if (listingFilters) {
              redirectURL = "/vehicles/van-rental?start=" + listingFilters.startDate2 + "&end=" + listingFilters.endDate2 + "&type=" + listingFilters.lType + "&count=" + listingFilters.lCount;
            }
            reload = true;
            redirect = true;
            errorTitle = "Something went wrong";
            errorMsg = response.data.error_msg + " This page will automatically reload.";
            break;
          default:
            break;
        }

        // Show error notification
        store.addNotification({
          title: errorTitle,
          message: errorMsg,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: false
          }
        });
        // Redirect
        if (redirect == true) {
          setTimeout(function() {
            if (reload == true) {
              window.location.href = redirectURL;
            } else {
              this.props.history.push(redirectURL);
            }
          }.bind(this), 3000);
        } else {
          if (reload == true) {
            window.location.reload();
          }
        }
        return false;
      }
    })
    .catch(error => {
      // handle error
      // if (error.response && error.response.status == 403) {
      // }
      store.addNotification({
        title: "Something went wrong",
        message: "The action you have requested is not allowed. Please reload the page",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
            onScreen: false
        }
      });
  });
  }

  handleSelectAddress = (address) => {
    address.returnAgreement = this.state.billingAddress.returnAgreement;
    this.setState({
      billingAddress: address,
    });
  }

  handleReturnAgreementChange = (event) => {
    event.persist();
    this.setState({
      billingAddress: {
        ...this.state.billingAddress,
        returnAgreement: event.target.checked
      },
    });
  }

  handleSubmit = () => {
    this.setState({ loading: true });

    let rawCheckout = this.state.rawCheckout;
    rawCheckout.billingAddress = this.state.billingAddress;
    let billingAddress = this.buildBillingAddress(this.state.billingAddress); // Build billing address details
    let updateData = {
      billing_purpose: this.state.billingAddress.purpose,
      billing_fname: this.state.billingAddress.firstName,
      billing_lname: this.state.billingAddress.lastName,
      billing_nric: this.state.billingAddress.nric,
      billing_address: billingAddress,
      billing_company: this.state.billingAddress.company,
      billing_roc: this.state.billingAddress.roc,
      billing_telephone: this.state.billingAddress.telephone,
      billing_mobile: this.state.billingAddress.mobile,
      billing_nric_front_img: this.state.billingAddress.nricFrontImg,
      billing_nric_back_img: this.state.billingAddress.nricBackImg,
      billing_company_acra: this.state.billingAddress.acra,
    };
    let formData = new FormData();
        formData.append('api_key', this.api.key);
        formData.append('access_token', this.api.accessToken);
        formData.append('jham_token', this.api.jhamToken);
        formData.append('lessor_id', rawCheckout.data[0].vo_id);
        formData.append('update_data', JSON.stringify(updateData));
        formData.append('raw_current_checkout', JSON.stringify(rawCheckout));
    axios({
      method: 'post',
      url: this.api.url + '/transaction/update_schedule',
      data: formData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      if (response.data.error == 0 || response.data.error == 3) {
        this.props.history.push('/checkout/driver');
      } else {
        var reload = false;
        var redirect = false;
        var redirectURL = "";
        var errorTitle = "";
        var errorMsg = "";
        switch (response.data.error) {
          case 1:
          case 2:
            reload = true;
            redirect = true;
            redirectURL = "/sign-in";
            errorTitle = "Your session has expired";
            errorMsg = "Please login again. This page will automatically reload.";
            break;
          case 4:
            reload = true;
            errorTitle = "Something went wrong";
            errorMsg = "This page will automatically reload. Please try again after reload.";
            break;
          default:
            break;
        }

        // Show error notification
        store.addNotification({
          title: errorTitle,
          message: errorMsg,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: false
          }
        });
        // Redirect
        if (redirect == true) {
          setTimeout(function() {
            if (reload == true) {
              window.location.href = redirectURL;
            } else {
              this.props.history.push(redirectURL);
            }
          }.bind(this), 3000);
        } else {
          if (reload == true) {
            window.location.reload();
          }
        }
        this.setState({ loading: false });
      }
    })
    .catch((error) => {
      console.log(error);
      // handle error
      if (error.response && error.response.status == 403) {

      }
      store.addNotification({
        title: "Oops Something went wrong",
        message: "The action you have requested is not allowed. Please reload the page",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: false
        }
      });

      this.setState({ loading: false });
    });

  }

  handlePopoverOpen = (event) => {
    if (this.state.billingAddress.returnAgreement == false || this.state.billingAddress.id == 0) {
      this.setState({popover: event.currentTarget});
    }
  };

  handlePopoverClose = () => {
    this.setState({popover: null});
  };

  handleAddDialogClose = () => {
    this.setState({ openAddDialog: false });
  }

  handleAddDialogOpen = () => {
    this.setState({ openAddDialog: true });
  }

  handleEditDialogClose = () => {
    this.setState({ openEditDialog: false });
  }

  handleEditDialogOpen = (address) => {
    this.setState({
      openEditDialog: true,
      addressToEdit: address,
    });
  }

  reloadAddressList = () => {
    let bodyFormData = new FormData();
        bodyFormData.append('api_key', this.api.key);
        bodyFormData.append('access_token', this.api.accessToken);
        bodyFormData.append('jham_token', this.api.jhamToken);
    axios({
      method: 'post',
      url: this.api.url + '/account/customer_address_list',
      data: bodyFormData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      let addresses = [];
      response.data.data.map((address, i) => {
        addresses.push({
          id: address.id,
          purpose: address.purpose,
          firstName: address.billing_first_name,
          lastName: address.billing_last_name,
          nric: address.billing_nric,
          nricFrontImg: address.billing_nric_front_img,
          nricBackImg: address.billing_nric_back_img,
          type: address.billing_type,
          block: address.billing_block,
          street: address.billing_street,
          floor: address.billing_floor,
          unit: address.billing_unit,
          building: address.billing_building,
          country: address.billing_country,
          postal: address.billing_postal,
          company: address.billing_company,
          roc: address.billing_roc,
          acra: address.billing_acra,
          telephone: address.billing_phone,
          mobile: address.billing_mobile,
        });
      });
      this.setState({
        openAddDialog: false,
        openEditDialog: false,
        addresses: addresses,
      });
    })
    .catch((error) => {
      // Handle error
      if (error.response && error.response.status == 403) {
        store.addNotification({
          title: "Something went wrong",
          message: "Please reload the page",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: false
          }
        });
      }
    });
  }

  buildBillingAddress = (billingAddress) => {
    var address = capitalize(billingAddress.type) + " " + billingAddress.block + " " + billingAddress.street;
    if (billingAddress.floor && billingAddress.unit) {
      address += ", #" + billingAddress.floor + "-" + billingAddress.unit;
    }
    if (billingAddress.building) {
      if (!billingAddress.floor && !billingAddress.unit) {
        address += ", " + billingAddress.building;
      } else {
        address += " " + billingAddress.building;
      }
    }
    address += ", " + billingAddress.country + " " + billingAddress.postal;
    return address;
  }

  render() {
    const { classes } = this.props;

    function NewAddressCard(props) {
      return (
        <Grid item xs={12} sm={6}>
          <Card className={classes.newAddress} onClick={props.onClick}>
            <CardContent>
              <AddCircleIcon fontSize="large" color="primary" />
              <Typography variant="h5" component="span" align="center" color="primary">
                Add new address
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      );
    }

    function AddressListLoader() {
      return (
        <ContentLoader
          height={155}
          speed={1}
          style={{
            width: '100%',
          }}
        >
          <rect x="15" y="15" rx="4" ry="4" width="180" height="20" />
          <rect x="15" y="50" rx="4" ry="4" width="300" height="15" />
          <rect x="15" y="75" rx="4" ry="4" width="280" height="15" />
        </ContentLoader>
      )
    }

    function AddressCard(props) {
      var acraRequired = props.address.purpose == "business" && props.address.acra == "" ? true : false;
      var nricImgRequired = props.address.purpose == "personal" && (props.address.nricFrontImg == "" || props.address.nricBackImg == "");
      var addressType = props.address.type.charAt(0).toUpperCase() + props.address.type.slice(1);
      var billingAddress = addressType + " " +
                           props.address.block + " " +
                           props.address.street;
      if (props.address.floor && props.address.unit) {
        billingAddress += ", #" + props.address.floor + "-" + props.address.unit;
      }
      if (props.address.building) {
        if (!props.address.floor && !props.address.unit) {
          billingAddress += ", " + props.address.building;
        } else {
          billingAddress += " " + props.address.building;
        }
      }
      billingAddress += ", " +
                        props.address.country + " " +
                        props.address.postal;
      return (
        <Grid item xs={12} sm={6} key={props.address.id} className={classes.addressCard}>
          <Card className={props.selected ? classes.addressSelected : null}>
            <CardContent>
              <Typography variant="h5" component="h5" gutterBottom>
                {props.address.purpose == "personal" ? props.address.firstName + " " + props.address.lastName : props.address.company}
              </Typography>
              <Typography variant="body1" component="p" color="textSecondary" title={billingAddress}>
                {billingAddress}
              </Typography>
            </CardContent>
            <CardActions>
              {acraRequired ? (
                <Button color="primary" fullWidth onClick={() => { props.onEditClick(props.address) }} color="secondary">
                  Upload ACRA Document
                </Button>
              ) : nricImgRequired ? (
                <Button color="primary" fullWidth onClick={() => { props.onEditClick(props.address) }} color="secondary">
                  Upload NRIC Images
                </Button>
              ) : (
                <Button color="primary" fullWidth onClick={() => { props.onClick(props.address) }} disabled={props.selected}>
                  {!props.selected ? "Select" : "Selected"}
                </Button>
              )}
            </CardActions>
          </Card>
        </Grid>
      );
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <Container component="main" maxWidth="lg" className="checkout" classes={{root: classes.layout}}>
          <CheckoutStepper active={0} history={this.props.history} completed={this.state.checkoutStepsCompleted} />
          <Grid container spacing={6}>
            <Grid item xs={12} sm={7} md={8} className={classes.gridItem1}>
              <Paper elevation={0}>
                <Typography variant="h3" className={classes.title} gutterBottom>
                  Billing Address
                </Typography>

                <Grid container spacing={3} className={classes.address}>
                  {this.state.loading ? (
                    <React.Fragment>
                      <Grid item xs={12} sm={6} className={classes.addressLoader}>
                        <Card>
                          <CardContent>
                            <AddressListLoader />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={6} className={classes.addressLoader}>
                        <Card>
                          <CardContent>
                            <AddressListLoader />
                          </CardContent>
                        </Card>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {this.state.addresses.map((address, index) =>
                        <AddressCard
                          key={index}
                          address={address}
                          onClick={this.handleSelectAddress}
                          onEditClick={this.handleEditDialogOpen}
                          selected={address.id == this.state.billingAddress.id}
                        />
                      )}
                      <NewAddressCard onClick={this.handleAddDialogOpen} />
                    </React.Fragment>
                  )}
                  <Grid item xs={12} style={{ marginTop: 24,padding:"30px" }}>
                    <FormControlLabel
                      control={<Checkbox color="secondary" name="returnAgreement" value="1" onChange={this.handleReturnAgreementChange} checked={this.state.billingAddress.returnAgreement} className={classes.agreementCheckbox} />}
                      label="I/We will return the vehicle with full tank of gasoline. Otherwise, I agreed to pay $2.00 per litre."
                    />
                  </Grid>
                  <Grid item xs={12} align="right" spacing={3}>
                    <Box
                      display="inline-block"
                      aria-haspopup="true"
                      onMouseEnter={this.handlePopoverOpen}
                      onMouseLeave={this.handlePopoverClose}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}
                        disabled={!this.state.billingAddress.returnAgreement || this.state.billingAddress.id == 0 || this.state.loading}
                      >
                        NEXT: Choose Driver
                      </Button>
                    </Box>
                    <Popover
                      id="mouse-over-popover"
                      className={classes.popover}
                      classes={{
                        paper: classes.popoverPaper,
                      }}
                      open={Boolean(this.state.popover)}
                      anchorEl={this.state.popover}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                      onClose={this.handlePopoverClose}
                    >
                      <Typography component="span">Select your billing address and check vehicle gasoline agreement to continue</Typography>
                    </Popover>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={5} md={4} className={classes.gridItem2}>
              <CheckoutDetails vehicle={this.state.currentVehicle} schedule={this.state.schedule} />
            </Grid>
          </Grid>
        </Container>
        <NewAddress
          open={this.state.openAddDialog}
          onClose={this.handleAddDialogClose}
          onAddressAdded={this.reloadAddressList}
          handlerState={this.handlerState}
          mainState={this.props.mainState}
        />
        <EditAddress
          addressDetails={this.state.addressToEdit}
          open={this.state.openEditDialog}
          onClose={this.handleEditDialogClose}
          onAddressUpdated={this.reloadAddressList}
          handlerState={this.handlerState}
          mainState={this.props.mainState}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(Checkout);

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
