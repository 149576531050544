import React, { Component }  from "react";
import {
  NavLink,
} from "react-router-dom";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import axios, { post } from 'axios';
import { store } from 'react-notifications-component';

// import { render } from "@testing-library/react";

import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import MailIcon from '@material-ui/icons/Mail';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import AirportShuttleOutlinedIcon from '@material-ui/icons/AirportShuttleOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import moment from "moment";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 345
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    media: {
        height: 140,
    },
}));


class Header extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor (props) {
        super(props)
        const { cookies } = props;
        this.state ={
          redirect: false,
          drawerOpen: false,
          openNestedLink:false,
          barPosition:"static"
        }
        this.handleLogout = this.handleLogout.bind(this);
        this.handleNestedLink = this.handleNestedLink.bind(this);

        const urlQuery = new URLSearchParams(window.location.search);
        const tagRid = urlQuery.get('s1');
        if(tagRid){
            localStorage.setItem('tag_rid', tagRid);
        }

    }

    toggleDrawer = (status, e) => {
        this.setState({
          drawerOpen:status,
        })
    };

    handleNestedLink = () => {
        this.setState({
          openNestedLink:!this.state.openNestedLink,
        })
    };




    handleLogout = event => {
        const { cookies } = this.props;
        var ctoken = localStorage.getItem('ctoken');
        var token = localStorage.getItem('token');
        if(ctoken){
            var bodyFormData = new FormData();
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
            bodyFormData.append('jham_token', token);
            axios({
                    method: 'post',
                    url: 'https://api.earental.com/account/checkpass?c='+moment().unix(),
                    data: bodyFormData,
                    withCredentials: true,
                    headers: {'Content-Type': 'multipart/form-data' }
                })
                .then((response) => {

                    if(response.data.error > 0){
                        localStorage.removeItem("ctoken");
                        localStorage.removeItem("current_name");
                        localStorage.removeItem("current_avatar");
                        localStorage.removeItem('checkout');
                        localStorage.removeItem('checkoutProgress');
                        localStorage.removeItem('checkoutCompleted');
                        localStorage.removeItem('hasPendingPayment');
                        this.props.handlerState('currentUserName',false);
                        this.props.handlerState('currentAvatar',false);
                        this.props.handlerState('ctoken',false);
                        this.props.handlerState('checkoutProgress', null);

                        cookies.remove('ctoken', { path:'/',domain: '.earental.com' });
                        cookies.remove('cavatar', { path:'/',domain: '.earental.com' });

                        store.addNotification({
                        title: "Oops you have been logout",
                        message: "Your session have expired/used in different device, please re-login",
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                        duration: 4000,
                        onScreen: false
                        }
                        });




                    }else{
                        this.props.handlerState('currentUserName',false);
                        localStorage.removeItem("current_name");
                        localStorage.removeItem("ctoken");
                        this.setState({ redirect: true })
                        store.addNotification({
                          title: "You are now logged out",
                          message: "Hoping to see you again :)",
                          type: "success",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animated", "fadeIn"],
                          animationOut: ["animated", "fadeOut"],
                          dismiss: {
                            duration: 5000,
                            onScreen: false
                          }
                       });


                       localStorage.removeItem("ctoken");
                       localStorage.removeItem("current_name");
                       localStorage.removeItem("current_avatar");
                       this.props.handlerState('currentUserName',false);
                       this.props.handlerState('currentAvatar',false);
                       this.props.handlerState('ctoken',false);

                       cookies.remove('ctoken', { path:'/',domain: '.earental.com' });
                       cookies.remove('cavatar', { path:'/',domain: '.earental.com' });

                       setTimeout(
                         () => window.location.href = "/",
                         150
                       );
                    }



                    // window.scrollTo(0, 0);
                })
                .catch((error) => {
                    localStorage.removeItem("ctoken");
                    localStorage.removeItem("current_name");
                    localStorage.removeItem("current_avatar");
                    this.props.handlerState('currentUserName',false);
                    this.props.handlerState('currentAvatar',false);
                    this.props.handlerState('ctoken',false);

                    cookies.remove('ctoken', { path:'/',domain: '.earental.com' });
                    cookies.remove('cavatar', { path:'/',domain: '.earental.com' });

                    store.addNotification({
                      title: "Oops you have been logout",
                      message: "Your session have expired/used in different device, please re-login",
                      type: "warning",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 4000,
                        onScreen: false
                      }
                    });

                });
        }



    }

    renderUserState(){
       if(this.props.mainState.currentUserName){
          let checkoutRedirectURL = "";
          if (this.props.mainState.checkoutProgress) {
            if (this.props.mainState.checkoutProgress == 1) {
              checkoutRedirectURL = "/checkout";
            } else if (this.props.mainState.checkoutProgress == 2) {
              checkoutRedirectURL = "/checkout/driver";
            } else if (this.props.mainState.checkoutProgress == 3) {
              checkoutRedirectURL = "/checkout/agreement";
            } else if (this.props.mainState.checkoutProgress == 4) {
              checkoutRedirectURL = "/checkout/insurance";
            } else if (this.props.mainState.checkoutProgress == 5) {
              checkoutRedirectURL = "/checkout/payment";
            }
          }
           return (
               <>
                {this.props.mainState.checkoutProgress && (
                  <li className="top-user-area-checkout">
                    <NavLink exact to={checkoutRedirectURL}>
                      <Badge color="error" variant="dot" badgeContent=" ">
                        <DriveEtaIcon />
                      </Badge>
                    </NavLink>
                  </li>
                )}
                <Hidden smDown>
                    <li className="top-user-area-avatar">
                        <NavLink exact to="/user-profile">
                            <Avatar alt={this.props.mainState.currentUserName} src={'https://api.earental.com/uploads/profile/'+this.props.mainState.currentAvatar} />
                        </NavLink>
                    </li>
                </Hidden>
                <li className="top-user-area-avatar">
                    <NavLink exact to="/user-profile">
                        Hi, {this.props.mainState.currentUserName} &nbsp;
                    </NavLink>
                </li>
                    <Hidden smDown>

                       <li><a onClick={this.handleLogout}>Sign Out</a>
                       </li>
                   </Hidden>
               </>
           );
       }else{
           return (
               <>
                   <li><NavLink exact to="/sign-in">Sign In &nbsp;&nbsp;</NavLink></li>
                   <li><NavLink exact to="/sign-up">Sign Up</NavLink></li>
               </>
           );
       }

    }

    componentDidMount() {
      this.setState({
        redirect: false,
      })

    //   window.addEventListener('scroll', this.handleScroll, { passive: true })
    }

    handleScroll(event) {
        // do something like call `this.setState`
        // access window.scrollY etc
    }





      render() {
          return (
                  <header id="main-header">
                      <div className="header-top">
                          <div className="container">
                              <Grid container>

                                  <Hidden mdUp>
                                      <Grid item sm={1} xs={1} align="left">
                                          <IconButton edge="start"color="inherit" aria-label="menu" onClick={this.toggleDrawer.bind(this,true)}>
                                              <MenuIcon  className="text-white" fontSize="medium"  />
                                          </IconButton>
                                      </Grid>

                                  </Hidden>
                                  <Grid item md={6} sm={4} xs={4} style={{position:"relative"}}>
                                      <NavLink exact to="/" className="logo" >
                                          <img src="/img/EARental-Logo-w.png" alt="" title="EARental | Your Trusted Vehicle"  />
                                          <Hidden mdUp>
                                              <p className="text-white"  style={{position:"absolute",fontSize:"11px",left:"56px",bottom:"-18px"}}><b>Singapore</b></p>
                                          </Hidden>
                                          <Hidden smDown>
                                              <p className="text-white"  style={{position:"absolute",fontSize:"11px",left:"56px",bottom:"-28px"}}><b>Singapore</b></p>
                                          </Hidden>
                                      </NavLink>
                                  </Grid>
                                  <Grid item md={6} sm={7} xs={7} align="right">
                                      <div className="top-user-area clearfix">
                                          <ul className="top-user-area-list list list-horizontal">
                                          { this.renderUserState() }
                                          </ul>
                                      </div>
                                  </Grid>
                              </Grid>
                          </div>
                      </div>
                      <div className="container top-nav-cont">
                          <div className="nav top-nav">
                              <Hidden smDown>
                                  <ul className="slimmenu" id="slimmenu">
                                      <li><NavLink exact to="/">Home</NavLink></li>
                                      <li><NavLink to="/about-us">About</NavLink></li>
                                      <li><NavLink to="/services">Services</NavLink></li>
                                      <li><NavLink to="/vehicles/van-rental" style={{color:"#d40029"}}>Book now</NavLink>
                                          <ul>
                                              <li><NavLink exact to="/vehicles/van-rental">Van Rental</NavLink></li>
                                          </ul>
                                      </li>
                                      <li><NavLink to="/loyalty-miles">Loyalty Miles</NavLink></li>
                                      <li><NavLink to="/contact">Contact</NavLink></li>
                                  </ul>
                              </Hidden>

                              <Hidden mdUp>
                                <SwipeableDrawer
                                  // variant="overlay"
                                  anchor={"left"}
                                  open={this.state.drawerOpen}
                                  onClose={this.toggleDrawer.bind(this,false)}
                                  onOpen={this.toggleDrawer.bind(this,false)}
                                >
                                    <List component="nav" aria-label="main mailbox folders" className="mobile-nav">
                                        <NavLink exact to="/">
                                            <ListItem button className="list-nav-mobile">
                                              <ListItemIcon onClick={this.toggleDrawer.bind(this,false)}>
                                                <HomeOutlinedIcon />
                                              </ListItemIcon>
                                              <ListItemText onClick={this.toggleDrawer.bind(this,false)} primary={"Home"} />
                                            </ListItem>
                                        </NavLink>
                                        <NavLink exact to="/about-us">
                                            <ListItem button className="list-nav-mobile">
                                              <ListItemIcon onClick={this.toggleDrawer.bind(this,false)}>
                                                <InfoOutlinedIcon />
                                              </ListItemIcon>
                                              <ListItemText onClick={this.toggleDrawer.bind(this,false)} primary={"About"} />
                                            </ListItem>
                                        </NavLink>
                                        <NavLink exact to="/services">
                                            <ListItem button className="list-nav-mobile">
                                              <ListItemIcon onClick={this.toggleDrawer.bind(this,false)}>
                                                <AssignmentTurnedInOutlinedIcon />
                                              </ListItemIcon>
                                              <ListItemText onClick={this.toggleDrawer.bind(this,false)} primary={"Services"} />
                                            </ListItem>
                                        </NavLink>
                                        <ListItem button onClick={this.handleNestedLink}>
                                          <ListItemIcon>
                                            <AirportShuttleOutlinedIcon />
                                          </ListItemIcon>
                                          <ListItemText primary="Book now" />
                                          {this.state.openNestedLink ? <ExpandLess /> : <ExpandMore />}
                                        </ListItem>
                                        <Collapse in={this.state.openNestedLink} timeout="auto" unmountOnExit>
                                          <List component="div" disablePadding>
                                              <NavLink exact to="/vehicles/van-rental">
                                                  <ListItem button className="list-nav-mobile">
                                                    <ListItemText onClick={this.toggleDrawer.bind(this,false)} primary={"Van Rental"} />
                                                  </ListItem>
                                              </NavLink>
                                          </List>
                                        </Collapse>
                                        <NavLink exact to="/contact">
                                            <ListItem button className="list-nav-mobile">
                                              <ListItemIcon onClick={this.toggleDrawer.bind(this,false)}>
                                                <MailOutlineOutlinedIcon />
                                              </ListItemIcon>
                                              <ListItemText onClick={this.toggleDrawer.bind(this,false)} primary={"Contact"} />
                                            </ListItem>
                                        </NavLink>
                                        <NavLink exact to="/faq">
                                            <ListItem button className="list-nav-mobile">
                                              <ListItemIcon onClick={this.toggleDrawer.bind(this,false)}>
                                                <ContactSupportOutlinedIcon />
                                              </ListItemIcon>
                                              <ListItemText onClick={this.toggleDrawer.bind(this,false)} primary={"FAQ"} />
                                            </ListItem>
                                        </NavLink>
                                        <br/>
                                        <Divider />
                                        {this.props.mainState.currentUserName && (
                                            <>
                                                <ListItem button className="list-nav-mobile">
                                                    <ListItemIcon onClick={this.handleLogout}>
                                                        <ExitToAppTwoToneIcon />
                                                    </ListItemIcon>
                                                    <ListItemText onClick={this.handleLogout} primary={"Sign Out"} />
                                                </ListItem>
                                                <Divider />
                                                <br/>
                                            </>
                                        )}

                                        <NavLink to="/privacy-policy">
                                            <ListItem button className="list-nav-mobile">
                                              <ListItemText onClick={this.toggleDrawer.bind(this,false)} primary={"Privacy Policy"} />
                                            </ListItem>
                                        </NavLink>
                                        <NavLink to="/terms-of-service">
                                            <ListItem button className="list-nav-mobile">
                                              <ListItemText onClick={this.toggleDrawer.bind(this,false)} primary={"Terms of Service"} />
                                            </ListItem>
                                        </NavLink>
                                    </List>
                                </SwipeableDrawer>
                              </Hidden>

                          </div>
                      </div>
                  </header>
          );
  }
}

export default withCookies(Header)
