import React from "react";
import {
  createMuiTheme,
  withStyles,
  ThemeProvider
} from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import NumberFormat from 'react-number-format';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { green } from '@material-ui/core/colors';

import "../assets/css/checkout.css";

const useStyles = theme => ({
  layout: {
    padding: theme.spacing(8),
    minHeight: 500,
    [theme.breakpoints.up('xs')]: {
      minHeight: 400,
    },
  },
  icon: {
    fontSize: '10rem',
    color: green[600],
  },
  message: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '3.5rem',
    },
  },
  caption: {
    fontSize: '2rem !important',
    marginTop: theme.spacing(4),
    maxWidth: 800,
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.5rem !important',
    },
  },
});

const theme = createMuiTheme({
  typography: {
    fontSize: 18,
  },
});

class PaymentSuccessful extends React.Component {
  constructor(props) {
    super(props);
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
    this.state = {
      loading: false,
      paymentType: "ONE_OFF_PAYMENT",
      paymentMethod: "windcave",
      cancellationFee: 0,
    };
  };

  componentDidMount() {
    let paymentSuccessful = localStorage.getItem('paymentSuccessful');
    let paymentType = localStorage.getItem('paymentType');
    let paymentMethod = localStorage.getItem('paymentMethod');
    let paymentAmount = localStorage.getItem('paymentAmount');
    let cancellationFee = localStorage.getItem('cancellationFee');

    if (paymentSuccessful) {
      localStorage.removeItem('paymentSuccessful');
      localStorage.removeItem('paymentType');
      localStorage.removeItem('paymentMethod');
      localStorage.removeItem('cancellationFee');
      localStorage.removeItem('tag_rid');
      // ReactGA.event({
      //   category: 'Payment',
      //   action: 'Payment successful',
      //   value: paymentAmount
      // });
      if (paymentType) {
        this.setState({
          paymentType: paymentType,
          paymentMethod: paymentMethod,
          cancellationFee: cancellationFee,
        });
      }
      // setTimeout(function() {
      //   this.props.history.push('/user-rental-history');
      // }.bind(this), 3000);
    } else {
      this.props.history.push('/');
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="lg" className="checkout" classes={{root: classes.layout}}>
            <Box align="center">
              <Typography variant="h3" align="center" gutterBottom>
                <CheckCircleRoundedIcon className={classes.icon} />
              </Typography>
              <Typography variant="h3" component="h1" align="center" gutterBottom className={classes.message}>
                Transaction Completed
              </Typography>
              {this.state.paymentMethod == "stripe" && (
                <React.Fragment>
                  {this.state.paymentType == "one-time" && (
                    <React.Fragment>
                      <Typography variant="caption" component="h1" align="center" className={classes.caption}>
                        Thank you for leasing with EARental. You will receive an SMS &amp; Email confirmation shortly once the lease has been approved. Have a wonderful day.
                      </Typography>
                      <Typography variant="caption" component="h1" align="center" className={classes.caption}>
                        No charges have been made to your credit card at this point.
                      </Typography>
                    </React.Fragment>
                  )}
                  {this.state.paymentType == "recurring" && (
                    <Typography variant="caption" component="h1" align="center" className={classes.caption}>
                      Thank you for leasing with EARental. You will receive an SMS &amp; Email confirmation shortly once the lease has been approved. Have a wonderful day.
                    </Typography>
                  )}
                </React.Fragment>
              )}
            </Box>
          </Container>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(PaymentSuccessful);
