import React from "react";
import { withRouter } from 'react-router-dom';
import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider
} from '@material-ui/core/styles';
import axios from 'axios';
import ReactGA from 'react-ga';
import { store } from 'react-notifications-component';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from "@material-ui/core/Select";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import UpdatePaymentDetails from './UpdatePaymentDetails';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import PaymentIcon from 'react-payment-icons';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { grey, green, red } from '@material-ui/core/colors';
import 'react-credit-cards/es/styles-compiled.css';
import "../assets/css/checkout.css";
import NewCardForm from "../components/NewCardForm";

const useStyles = theme => ({
  layout: {
    padding: theme.spacing(5, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
    }
  },
  title: {
    fontWeight: 300,
    marginBottom: theme.spacing(3),
  },
  cardsDropdown: {
    color: grey[800],
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '& .MuiFormLabel-root': {
      fontSize: '1.7rem',
      backgroundColor: '#FFF',
      paddingRight: 8,
      marginBottom: theme.spacing(-2),
      '&.Mui-focused': {
        color: grey[800],
      },
    },
    '& .MuiFormControl-root': {
      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        '&:hover fieldset': {
          borderColor: '#B4B4B4',
        },
        '&.Mui-focused fieldset': {
          borderColor: "#2F96B4",
        },
      },
    },
  },
  cardsDropdownMenuItem: {
    padding: theme.spacing(2),
    '& span': {
      fontSize: '1.25rem',
    }
  },
  gridItem1: {
    order: 2,
    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  gridItem2: {
    order: 1,
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
  },
  completedStatusIcon: {
    fontSize: '10rem',
    color: green[600],
  },
  replayStatusIcon: {
    fontSize: '10rem',
    color: red[600],
  },
  statusMessage: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '3.5rem',
    },
  },
  statusCaption: {
    fontSize: '2rem !important',
    margin: '0 auto',
    marginTop: theme.spacing(4),
    maxWidth: 800,
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.5rem !important',
    },
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: grey[200] + '!important',
    height: 3,
  },
});

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#2F96B4',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

function FacebookCircularProgress(props) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}

const theme = createMuiTheme({
  typography: {
    fontSize: 18,
  },
});

class UpdatePayment extends React.Component {
  constructor(props) {
    super(props);

    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);

    this.api = {
      url: props.mainState.apiURL,
      key: props.mainState.apiKey,
    };

    this.state = {
      loading: true,
      stripePublishableKey: "",
      sid: "",
      schedule: null,
      vehicle: null,
      cardList: [],
      card: 0,
      useNewCard: false,
      paymentStatus: "pending",
      // paymentStatus: "completed",
      // paymentStatus: "retry",
    };
  };

  componentDidMount() {
    const sid = this.props.match.params.id;

    let formData = new FormData();
    formData.append('api_key', this.api.key);
    formData.append('access_token', localStorage.getItem('ctoken'));
    formData.append('jham_token', localStorage.getItem('token'));
    formData.append('schedule_id', sid);

    // Check for pending payment
    axios({
      method: 'post',
      url: this.api.url + '/transaction/check_schedule_payment',
      data: formData,
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => {
        if (response.data.error == 0) {
          var schedule = {
            id: response.data.schedule.s_id,
            leaseType: response.data.schedule.s_payment_mode,
            startDate: response.data.schedule.s_start_date,
            endDate: response.data.schedule.s_end_date,
            billingFname: response.data.schedule.billing_fname,
            billingLname: response.data.schedule.billing_lname,
          };
          var vehicle = {
            id: response.data.vehicle.product_id,
            name: response.data.vehicle.product_name,
            image: response.data.vehicle.product_image,
            type: response.data.vehicle.product_type,
            model: response.data.vehicle.product_model,
            brand: response.data.vehicle.product_brand,
            plateNumber: response.data.vehicle.product_platenumber,
          };
          var cards = [];
          var primaryCard = 0;
          response.data.cards.map((card) => {
            cards.push({
              id: card.stripe_pm_id,
              number: card.stripe_card_last4,
              type: card.stripe_card_type,
              primary: parseInt(card.stripe_card_primary)
            });
            if (card.stripe_card_primary == 1) {
              primaryCard = card.stripe_pm_id;
            }
          });
          this.setState({
            cardsLoading: false,
            card: primaryCard,
            cardList: cards,
          });
          this.setState({
            loading: false,
            stripePublishableKey: response.data.payment_params.publishable_key,
            schedule: schedule,
            vehicle: vehicle,
            sid: sid,
          });
        } else {
          var redirect = false;
          var redirectURL = "";
          var errorTitle = "";
          var errorMsg = "";
          switch (response.data.error) {
            case 1:
            case 2:
              redirect = true;
              redirectURL = "/sign-in";
              errorTitle = "Your session has expired";
              errorMsg = "Please login again. This page will automatically reload.";
              break;
            case 3:
              redirect = true;
              redirectURL = "/";
              errorTitle = "An error has occurred";
              errorMsg = response.data.error_mg;
              break;
            default:
              break;
          }
          // Show error notification
          store.addNotification({
            title: errorTitle,
            message: errorMsg,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: false
            }
          });
          // Redirect
          if (redirect == true) {
            setTimeout(function () {
              this.props.history.push(redirectURL);
            }.bind(this), 3000);
          }
          return false;
        }
      })
      .catch(error => {
        // handle error
        if (error.response && error.response.status == 403) {
          store.addNotification({
            title: "Something went wrong",
            message: "The action you have requested is not allowed. Please reload the page",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false
            }
          });
        }
      });
  }

  handleCardChange = (event) => {
    const target = event.target;
    this.setState({ card: target.value });
  }

  handleUpdatePayment = () => {
    this.setState({
      loading: true,
    });

    let formData = new FormData();
    formData.append('api_key', this.api.key);
    formData.append('access_token', localStorage.getItem('ctoken'));
    formData.append('jham_token', localStorage.getItem('token'));
    formData.append('schedule_id', this.state.sid);
    if (this.state.useNewCard == false) {
      formData.append('stripe_card_id', this.state.card);
    } else {
      formData.append('stripe_card_id', this.state.newCard);
    }
    formData.append('use_new_card', this.state.useNewCard);

    axios({
      method: 'post',
      url: this.api.url + '/transaction/update_recurring_payment',
      data: formData,
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => {
        if (response.data.status == 1 && response.data.error == 0) {
          this.setState({
            paymentStatus: "completed",
          });
        } else if (response.data.status == 0 && response.data.error == 5) {
          // Payment declined
          // ReactGA.event({
          //   category: 'User',
          //   action: 'Payment failed'
          // });
          localStorage.setItem('paymentFailed', 1);
          this.props.history.push('/checkout/payment/failed');
        } else {
          var reload = false;
          var redirect = false;
          var redirectURL = "";
          var errorTitle = "";
          var errorMsg = "";
          switch (response.data.error) {
            case 1:
            case 2:
              reload = true;
              redirect = true;
              redirectURL = "/signin";
              errorTitle = "Your session has expired";
              errorMsg = "Please login again. This page will automatically reload.";
              break;
            case 3:
              reload = true;
              redirect = true;
              redirectURL = "/";
              errorTitle = "Something went wrong";
              errorMsg = response.data.error_msg + " This page will automatically reload.";
              break;
            case 4:
              reload = true;
              redirect = true;
              redirectURL = "/payment";
              errorTitle = "Something went wrong";
              errorMsg = response.data.error_msg + " This page will automatically reload.";
              break;
            default:
              break;
          }

          // Show error notification
          store.addNotification({
            title: errorTitle,
            message: errorMsg,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: false
            }
          });
          // Redirect
          if (redirect == true) {
            setTimeout(function () {
              if (reload == true) {
                window.location.href = redirectURL;
              } else {
                this.props.history.push(redirectURL);
              }
            }.bind(this), 3000);
          } else {
            if (reload == true) {
              window.location.reload();
            }
          }
        }

        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log(error);
        // handle error
        this.setState({ loading: false });
        if (error.response && error.response.status == 403) {
          store.addNotification({
            title: "Oops Something went wrong",
            message: "The action you have requested is not allowed. please re-load the page",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false
            }
          });
        }
      });
  }

  handleSetupSuccess = (setupIntent) => {
    this.setState({
      newCard: setupIntent.payment_method,
      useNewCard: true,
    });
    this.handleUpdatePayment();
  }

  handleSetupFailed = () => {
    
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          {this.state.paymentStatus == "pending" &&
            <Container component="main" maxWidth="lg" className="checkout" classes={{ root: classes.layout }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={7} md={8} className={classes.gridItem1}>
                  {this.state.loading && (
                    <Box className={classes.loadingWrapper}>
                      <FacebookCircularProgress />
                    </Box>
                  )}

                  {!this.state.loading && this.state.cardList.length > 0 && !this.state.cardsLoading && (
                    <React.Fragment>
                      <Grid container spacing={3} className={classes.cardsDropdown} alignItems="center">
                        <Grid item xs={12}>
                          <FormControl variant="outlined" fullWidth={true}>
                            <InputLabel id="card-list">Select a card to use</InputLabel>
                            <Select
                              labelId="card-list"
                              id="card-list"
                              value={this.state.card}
                              onChange={this.handleCardChange}
                              label="Select a card to use"
                            >
                              {this.state.cardList.map((item, i) => {
                                return (
                                  <MenuItem key={i} value={item.id} className={classes.cardsDropdownMenuItem}>
                                    <PaymentIcon id={item.type.toLowerCase()} style={{ marginRight: 10, width: 50 }} className="payment-icon" />
                                    <Typography component="span">
                                      {item.type.toUpperCase()} ending in <strong>&#9679;&#9679;{item.number}</strong> {item.primary ? <Typography component="span">(<em>Primary</em>)</Typography> : ""}
                                    </Typography>
                                  </MenuItem>
                                )
                              })}
                              <MenuItem value={0}>
                                <CreditCardIcon className="payment-icon" style={{ marginRight: 10, width: 50, verticalAlign: 'middle' }} />
                                <Typography component="span">
                                  <em>Enter new card</em>
                                </Typography>
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Divider className={classes.divider} variant="middle" />
                      {this.state.card != 0 && (
                        <Grid container spacing={3} alignItems="center">
                          <Grid item xs={12}>
                            <Box textAlign="right">
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={this.handleUpdatePayment}
                                disabled={this.state.loading}
                                size="large"
                              >
                                {!this.state.loading ? (
                                  <span>Continue</span>
                                ) : (
                                  <span>Please wait</span>
                                )}
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    </React.Fragment>
                  )}
                  {!this.state.loading && this.state.card == 0 && (
                    <NewCardForm publishableKey={this.state.stripePublishableKey} onSuccess={this.handleSetupSuccess} onFailed={this.handleSetupFailed} />
                  )}
                </Grid>

                <Grid item xs={12} sm={5} md={4} className={classes.gridItem2}>
                  <UpdatePaymentDetails schedule={this.state.schedule} vehicle={this.state.vehicle} />
                </Grid>
              </Grid>
            </Container>
          }
          {this.state.loading == false && this.state.paymentStatus == "completed" &&
            <Container component="main" maxWidth="lg" className={classes.layout}>
              <Typography variant="h3" align="center" gutterBottom>
                <CheckCircleRoundedIcon className={classes.completedStatusIcon} />
              </Typography>
              <Typography variant="h3" component="h1" align="center" gutterBottom className={classes.statusMessage}>
                Payment Updated
              </Typography>
              <Typography variant="caption" component="h1" align="center" className={classes.statusCaption}>
                The payment for your lease with schedule no.: {this.state.schedule.id} has been updated.
              </Typography>
            </Container>
          }
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(withRouter(UpdatePayment));

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
