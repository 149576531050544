import React from 'react';
import {
  NavLink,
  HashRouter,
  Redirect,
  useLocation,
} from "react-router-dom";

import axios, { post } from 'axios';
import { store } from 'react-notifications-component';
import { loadProgressBar } from 'axios-progress-bar'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import queryString from 'query-string';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import ReactGA from 'react-ga';
import Container from '@material-ui/core/Container';
import moment from "moment";

import "./assets/css/login.css";


class Login extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor (props) {
        ReactGA.set({ page: window.location.pathname });
        ReactGA.pageview(window.location.pathname);

        super(props)
        const { cookies } = props;
        var email = "";
        var password = "";
        var remember = false;
        if(localStorage.getItem('remember_email')){
            email = localStorage.getItem('remember_email');
            remember = true;
        }
        this.state ={
          openModal: false,
          passToken:localStorage.getItem('token'),
          username:email,
          password:password,
          remember:remember,
          loading: false,
          redirect: false,
          confirmCheck: "",
          currentUserName:localStorage.getItem('current_name')
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.handleCheckBox = this.handleCheckBox.bind(this);
        this.handleUsername = this.handleUsername.bind(this);
        this.handlePasword = this.handlePasword.bind(this);

    }

    onFormSubmit(event){
        event.preventDefault();
        loadProgressBar();
        var bodyFormData = new FormData();
        var token = this.state.passToken;
        var goBack = localStorage.getItem('back_continue');
        this.setState({ loading: true });
        bodyFormData.append('email', event.target.email.value);
        bodyFormData.append('password', event.target.password.value);
        bodyFormData.append('jham_token', token);
        var email = event.target.email.value;
        var password = event.target.password.value;
        const { cookies } = this.props;

        ReactGA.event({
          category: 'User',
          action: 'Login'
        });

        axios({
          method: 'post',
          url: 'https://api.earental.com/account/login',
          data: bodyFormData,
          withCredentials: true,
          headers: {'Content-Type': 'multipart/form-data'}
        })
        .then((response) => {
             this.setState({ loading:false });
             if(response.data.error > 0){
                 store.addNotification({
                   title: "Wrong email/password",
                   message: response.data.message,
                   type: "danger",
                   insert: "top",
                   container: "top-right",
                   animationIn: ["animated", "fadeIn"],
                   animationOut: ["animated", "fadeOut"],
                   dismiss: {
                     duration: 5000,
                     onScreen: false
                   }
                 });
             }else{

                //  this.setState({ redirect: true })
                 if(this.state.remember){
                     localStorage.setItem('remember_email', email);
                 }else{
                     localStorage.removeItem("remember_email");
                     localStorage.removeItem("remember_pass");
                 }
                 localStorage.setItem('current_name', response.data.data.customer_info.customer_firstname);
                 localStorage.setItem('current_avatar', response.data.data.customer_info.customer_profile_image);
                 localStorage.setItem('ctoken', response.data.data.customer_info.customer_token);
                 localStorage.removeItem('checkout');
                 cookies.remove('ctoken', { path:'/',domain: '.earental.com' });
                 cookies.remove('cavatar', { path:'/',domain: '.earental.com' });
                if(goBack){
                    store.addNotification({
                      title: "Login Successful",
                      message: "Redirecting to product...",
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 1000,
                        onScreen: false
                      }
                    });
                    setTimeout(
                        () => window.location.href = goBack,
                        1000
                    );
                }else{
                    store.addNotification({
                      title: "Login Successful",
                      message: "Welcome back...",
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: false
                      }
                    });
                    const urlParams = new URLSearchParams(this.props.location.search);
                    const redirectURL = urlParams.get('r');
                    if (!redirectURL) {
                      redirectURL = "/";
                    }
                    setTimeout(
                        // () => window.location.href = "/",
                        () => window.location.href = redirectURL,
                        150
                    );
                }



             }

        })
        .catch((error) => {
          //handle error
          console.log(error);
          this.setState({ loading:false });

          store.addNotification({
            title: "Oops Something went wrong",
            message: "The action you have requested is not allowed. page re-loading...",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false
            }
          });

          setTimeout(
              () => window.location.href = "/sign-in",
              1500
          );



        });

    }

    componentDidMount () {

      var hash = window.location.hash.substr(10);
      axios.get('https://api.earental.com/listing/getPass?c='+moment().unix(), { withCredentials: true })
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        this.setState({passToken:response.data.token});
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });

    //   var result = hash.split('&').reduce(function (result, item) {
    //       var parts = item.split('=');
    //       result[parts[0]] = parts[1];
    //       return result;
    //   }, {});

      const urlQuery = new URLSearchParams(window.location.search);
      const checkConfirm = urlQuery.get('w');
      if(checkConfirm == "1"){
        this.setState({openModal:true});
      }
    }

     handleCheckBox(event){
       this.setState({
         remember:event.target.checked,
       })
     }


     handleUsername(event){
       this.setState({
         username:event.target.value,
       })
     }

     handlePasword(event){
       this.setState({
         password:event.target.value,
       })
     }

    handleClose = () => {
      this.setState({openModal:false});
    };

  render() {
    const { currentUserName } = this.state;
    if (currentUserName) {
        return <Redirect to="/" />;
    }

    function ButtonComponent(props) {
        const { loading } = props;
        return (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="loginSubmit"
              disabled={loading}
            >
            {loading && <CircularProgress size={14} />}
            {!loading && 'Sign In'}

            </Button>
        );
    }


    return (
        <Container component="main" maxWidth="xs" className="login-cont">
          <CssBaseline />
          <div className="loginPaper">
            <Typography component="h1" variant="h3">
              Welcome to EARental
            </Typography>
            <form  className="loginForm" noValidate onSubmit={this.onFormSubmit} autoComplete="off">
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Mobile or Email Address"
                name="email"
                autoComplete="new-email"
                value={this.state.username}
                onChange={this.handleUsername}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={this.state.password}
                onChange={this.handlePasword}
                autoComplete="new-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" name="remember" onChange={this.handleCheckBox} checked={this.state.remember} />}
                label="Remember me"
              />
               <ButtonComponent loading={this.state.loading} />
              <Grid container>
                <Grid item xs>
                    <NavLink exact to="/forgot-password" className="form-links">Forgot password?</NavLink>
                </Grid>
                <Grid item>
                    <NavLink exact to="/sign-up" className="form-links">Don't have an account? Sign Up For Free</NavLink>
                </Grid>
              </Grid>
            </form>
          </div>
          <div className="gap"></div>
          <div>
            <Dialog
              open={this.state.openModal}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Confirmation Successful!</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description" className='signupmodal'>
                  Your account has been confirmed and activated.
                  <br/>
                    Please login your account to continue.
                  <br/>
                  Thank you!!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary" autoFocus className="bigBtn">
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Container>
    );
  }
}

export default withCookies(Login);
