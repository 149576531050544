import React, { Component } from "react";
import aboutTopBanner from './../assets/img/test.jpg';
import aboutParallaxBanner from './../assets/img/parallax-about.jpg';

import signupImg from './../assets/img/sign-up.png';
import rentImg from './../assets/img/rent.png';
import redeemImg from './../assets/img/redeem.png';
import earnImg from './../assets/img/earn.png';
import spendImg from './../assets/img/spend.png';
import expiryImg from './../assets/img/expiry-date.png';


import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";
import "./../assets/css/aboutus.css";

var aboutTopBannerImg = {
  backgroundImage: "url(" + aboutTopBanner + ")",
  marginTop:"-48px"
};

var aboutParallaxBannerImg = {
  backgroundImage: "url(" + aboutParallaxBanner + ")"
};

var signUpHeader = {
  backgroundImage: "url(" + signupImg + ")",
  backgroundRepeat:"no-repeat",
  backgroundPosition: "center"
};

var rentHeader = {
  backgroundImage: "url(" + rentImg + ")",
  backgroundRepeat:"no-repeat",
  backgroundPosition: "center"
};

var redeemHeader = {
  backgroundImage: "url(" + redeemImg + ")",
  backgroundRepeat:"no-repeat",
  backgroundPosition: "center"
};

var earnHeader = {
  backgroundImage: "url(" + earnImg + ")",
  backgroundRepeat:"no-repeat",
  backgroundPosition: "center"
};

var spendHeader = {
  backgroundImage: "url(" + spendImg + ")",
  backgroundRepeat:"no-repeat",
  backgroundPosition: "center"
};

var expiryHeader = {
  backgroundImage: "url(" + expiryImg + ")",
  backgroundRepeat:"no-repeat",
  backgroundPosition: "center"
};





class LoyaltyMiles extends Component {

    constructor (props) {
      super(props)
      ReactGA.set({ page: window.location.pathname });
      ReactGA.pageview(window.location.pathname);

      this.state = {
        startDate: new Date(),
        endDate: new Date(),
        MetaTitle:"EARental | Loyalty Miles",
        MetaDescription:"EARental is Singapore No.1 Online Van, Lorry & Truck commercial vehicles for lease for businesses and individuals. Hassle-Free, book and reserve your vehicle Online Now!",
        MetaKeywords:"online rental,Loyalty Miles, van rental, lorry rental, truck rental, commercial vehicles for rent, leasing, daily rental, weekly rental, weekend rental, monthly rental, yearly rental, short term rental, long term rental, cargo van rental singapore, cheap van rental, van rental price, Toyota hiace van rental singapore, van rental daily, auto van rental singapore, commercial van for rent, van rental near me, daily van rental singapore",
        Canonical:"https://www.earental.com/loyalty-miles",
      };
    }



    componentDidMount() {
        window.scrollTo(0, 0)
    }

  render() {
    return (
        <div className="about-us-cont">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="title" content={this.state.MetaTitle} />
                <meta content={this.state.MetaTitle} property="og:title" />
                <meta name="description" content={this.state.MetaDescription} />
                <meta content={this.state.MetaDescription} property="og:description" />
                <meta name="keywords" content={this.state.MetaKeywords} />
                <meta name="robots" content="index, follow" />
                <title>{this.state.MetaTitle}</title>
                <link rel="canonical" href={this.state.Canonical} />
            </Helmet>
                <div className="top-area show-onload">

                        <div className="bg-holder full text-center text-white">
                            <div className="bg-mask"></div>
                            <div className="bg-img" style={aboutTopBannerImg}></div>
                            <div className="bg-front full-center">
                                <div className="owl-cap">
                                    <h1 className="owl-cap-title fittext">Loyalty Miles Club</h1>
                                </div>
                            </div>
                        </div>

                </div>

                <div className="container text-center">
                    <div className="gap"></div>
                     <h2 className="text-uc mb20">Welcome to Loyalty Miles Club</h2>
                    <p className="text-bigger">With Loyalty Miles, every Dollar you spent is precious. <br />Be rewarded with Miles Points on every booking made. </p>
                    <div className="gap"></div>
                </div>

                <div className="bg-holder">
                    <div className="bg-mask"></div>
                    <div className="bg-blur"> </div>
                    <div className="bg-content">
                        <div className="container">
                            <div className="gap gap-big text-center text-white">
                                <h2 className="uc" style={{fontWeight:"400"}}>Benefit</h2>
                                <div className="gap"></div>
                                <div className="text-center row">
                                    <div className="col-md-12">
                                        <div className="row row-wrap" data-gutter="60">
                                            <div className="col-md-4">
                                                <div className="thumb">
                                                    <header className="thumb-header"><i className=" box-icon-center box-icon-large round box-icon-big animate-icon-border-rise round" style={spendHeader}></i>
                                                    </header>
                                                    <div className="thumb-caption">
                                                        <h5 className="thumb-title"><a className="text-white uc">Greater Savings</a></h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="thumb">
                                                    <header className="thumb-header"><i className="box-icon-center box-icon-large round box-icon-big animate-icon-border-rise round" style={earnHeader}></i>
                                                    </header>
                                                    <div className="thumb-caption">
                                                        <h5 className="thumb-title"><a className="text-white uc">Better Rewards</a></h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="thumb">
                                                    <header className="thumb-header"><i className="box-icon-center box-icon-large round box-icon-big animate-icon-border-rise round" style={expiryHeader}></i>
                                                    </header>
                                                    <div className="thumb-caption">
                                                        <h5 className="thumb-title"><a className="text-white uc" >No Expiry Date</a></h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="gap gap-big line" style={{borderBottom: "2px dashed #e6e6e6"}}></div>
                            <div className="gap gap-big text-center text-white">
                                <h2 className="uc" style={{fontWeight:"400"}}>How it works</h2>
                                <div className="gap"></div>
                                <div className="text-center row">
                                    <div className="col-md-12">
                                        <div className="row row-wrap" data-gutter="60">
                                            <div className="col-md-4">
                                                <div className="thumb">
                                                    <header className="thumb-header"><i className=" box-icon-center box-icon-large box-icon-info round box-icon-big animate-icon-border-rise round" style={signUpHeader}></i>
                                                    </header>
                                                    <div className="thumb-caption">
                                                        <h5 className="thumb-title"><a className="text-white uc">Create an account</a></h5>
                                                        <p className="thumb-desc">Sign Up for free account at <a href="earental.com">EARental</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="thumb">
                                                    <header className="thumb-header"><i className="box-icon-center box-icon-large box-icon-info round box-icon-big animate-icon-border-rise round" style={rentHeader}></i>
                                                    </header>
                                                    <div className="thumb-caption">
                                                        <h5 className="thumb-title"><a className="text-white uc">Rent & Earn</a></h5>
                                                        <p className="thumb-desc">Every <b>$1</b> spend on rental fee, you earn <b>1 Mile Point</b>.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="thumb">
                                                    <header className="thumb-header"><i className="box-icon-center box-icon-large box-icon-info round box-icon-big animate-icon-border-rise round" style={redeemHeader}></i>
                                                    </header>
                                                    <div className="thumb-caption">
                                                        <h5 className="thumb-title"><a className="text-white uc" >Redeem & Save</a></h5>
                                                        <p className="thumb-desc">Redeem cash rebates using accumulated Miles Point.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="gap"></div>
                            <article className="post">

                                <div className="post-inner">
                                    <h4 className="post-title text-darken">About Loyalty Miles</h4>

                                    <div className="gap gap-small"></div>

                                    <h4>Where do I start and how?</h4>
                                    <p>1. Sign Up or Login with <a href="/sign-in">EARental</a><br />2. Verified your account via SMS Verification Code<br /> 3. Rent vehicle and start earning Miles Point. </p>

                                    <hr />
                                    <h4>How to earn Miles Point?</h4>
                                    <p>You can earn 1 Mile Point per $1 on with every successful rental transaction.<br />e.g If you spent $1,000 on the rental fee, you are entitle to 1,000 Miles Point.
                                    </p>

                                    <hr />
                                    <h4>What can I redeem the Miles Point for?</h4>
                                    <p>For every 1,000 Miles Point accumulated, you can redeem $10 Cash Rebate.</p>

                                    <hr />
                                    <h4>Where can I use the Miles Point?</h4>
                                    <p>You can use your Miles Point during checkout. You can redeem the points and enjoy great savings! </p>

                                    <hr />
                                    <h4>How can I keep track on my Miles Point transaction?</h4>
                                    <p>Just simply login to your account. And go to <b>Loyalty Miles</b> to view your transcation.  </p>

                                    <hr />
                                    <h4>Is there any limit to what I can redeem?</h4>
                                    <p>*You can redeem up to $100 cash rebate per rental.*</p>

                                    <hr />
                                    <h4>Is there an expiry date for my Miles Point?</h4>
                                    <p>There are no expiry date for your Miles Point earned. *However, it is encouraged that you used your Miles Point on your next rental to enjoy great saving.* </p>

                                </div>

                            </article>

                </div>

        </div>
    );
  }
}

export default LoyaltyMiles;
