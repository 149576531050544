import React, { Component } from 'react';
import {
  NavLink,
  HashRouter,
  Redirect
} from "react-router-dom";
import axios, { post } from 'axios';
import { store } from 'react-notifications-component';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import { ReCaptcha } from 'react-recaptcha-google';
import { loadProgressBar } from 'axios-progress-bar';
import queryString from 'query-string';
import ReactGA from 'react-ga';
import "./assets/css/login.css";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const styles = theme => ({
    paper: {
      marginTop: '64px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: '8px',
    },
    submit: {
      margin: "24px 0px 16px",
    },
});

class Forgotpassword extends React.Component {
  constructor(props, context) {
    super(props, context);
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);

    const values = queryString.parse(this.props.location.search)
    // if(values.q)
    if(typeof values.q === 'undefined' || !values.q ){;
        this.state = {
            withPasscode: false,
        };
    }else{
        this.state = {
            withPasscode: values.q,
        };

    }


    this.onFormSubmit = this.onFormSubmit.bind(this);

  }

  onFormSubmit(event){
    event.preventDefault();
    loadProgressBar();

    var bodyFormData = new FormData();
    var token = localStorage.getItem('token');

    bodyFormData.append('password', event.target.password.value);
    bodyFormData.append('password2', event.target.password_repeat.value);
    bodyFormData.append('pass_code', this.state.withPasscode);
    bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
    bodyFormData.append('jham_token', token);

    axios({
            method: 'post',
            url: 'https://api.earental.com/account/r_pass',
            data: bodyFormData,
            withCredentials: true,
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then((response) => {
           if(response.data.status > 0){
               store.addNotification({
                 title: "Great!",
                 message: "Changed Password Success!",
                 type: "success",
                 insert: "top",
                 container: "top-right",
                 animationIn: ["animated", "fadeIn"],
                 animationOut: ["animated", "fadeOut"],
                 dismiss: {
                   duration: 5000,
                   onScreen: false
                 }
               });
               setTimeout(
                 () => window.location.href = "/sign-in",
                 150
               );
           }else{

               function ErrorMsg(props) {
                 return (
                     props.msgs.map((item,i) =>
                         <li key={"err-"+item.i}>{item.value}</li>
                     )

                 );
               }

               store.addNotification({
                title: "Error",
                message: <ErrorMsg msgs={response.data.error_msg} />,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                duration: 5000,
                onScreen: false
                }
               });
              //  this.setState({ redirect: true })

           }

        })
        .catch((error) => {

            if (error.response.status == 403) {

                store.addNotification({
                  title: "Oops Something went wrong",
                  message: "The action you have requested is not allowed. please re-load the page",
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                    duration: 5000,
                    onScreen: false
                  }
                });

           }

    });



  }


  render() {
    const { withPasscode } = this.state;
    const { classes } = this.props;
    if (!withPasscode) {
      return <Redirect to="/" />;
    }


    return (
      <Container component="main" maxWidth="xs" className="login-cont">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h3">
            Change Password
          </Typography>
          <Typography component="h1" variant="h6">
            Please enter a new password for your account.
          </Typography>
          <form id="forgot-form" className={classes.form} noValidate onSubmit={this.onFormSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="New Password"
              name="password"
              autoFocus
              type="password"
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Repeat Password"
              name="password_repeat"
              type="password"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Set New Password
            </Button>

          </form>
        </div>
        <div className="gap"></div>

      </Container>
    );
  };
};

Forgotpassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Forgotpassword);
