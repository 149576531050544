import React, { Component,useState } from "react";
import {
  NavLink,
  HashRouter
} from "react-router-dom";

import axios, { post } from 'axios';
import { store } from 'react-notifications-component';
import Pager from 'react-pager';
import moment from "moment";

import aboutTopBanner from './../assets/img/about-us.jpg';
import aboutParallaxBanner from './../assets/img/parallax-about.jpg';

import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';


import "./../assets/css/profile.css";

//SIDE CARD UNG MY AVATAR
import ProfileCard from "./../profile/Profilecard";


var aboutTopBannerImg = {
  backgroundImage: "url(" + aboutTopBanner + ")"
};

var aboutParallaxBannerImg = {
  backgroundImage: "url(" + aboutParallaxBanner + ")"
};

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        outline: 'none'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paragraph: {
        fontSize:15
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
        fontSize:"20px",
        textTransform:"uppercase"
    },
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(3),
    },
    button: {
      marginTop: '35px',
      float: 'right'
    },
}));


class History extends Component {

    constructor (props) {
        super(props)
        window.scrollTo(0, 0);
        this.state = {
            ongoingRental: [],
            limit:10,
            offset:0,
            visiblePage: 5,
            currentPage:0,
            totalPages: 0,
            noResult: false,
            openCancelConfirm: false,
            currentTransaction:false,
            openSb: false,
            lessorCode:false
        };
        this.handlePageChanged = this.handlePageChanged.bind(this);
        this.confirmCancel = this.confirmCancel.bind(this);
        this.cancelTransaction = this.cancelTransaction.bind(this);
        this.handleCloseSb = this.handleCloseSb.bind(this);
    }

    handleCloseSb(){
        this.setState({
          openSb:false,
        })
    }


    confirmCancel(sId,e){
        this.setState({
          openCancelConfirm:true,
          currentTransaction: sId
        })
    }

    cancelTransaction(confirmation,e){

        if(confirmation){


            var bodyFormData = new FormData();
            var token = localStorage.getItem('token');
            var ctoken = localStorage.getItem('ctoken');

            bodyFormData.append('s_id', this.state.currentTransaction);
            bodyFormData.append('jham_token', token);
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


            axios({
                  method: 'post',
                  url: 'https://api.earental.com/account/cancel_transaction',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {

                 if(response.data.error > 0){
                     function ErrorMsg(props) {
                       return (
                           props.msgs.map((item,i) =>
                               <li key={"err-"+item.id}>{item.value}</li>
                           )
                       );
                     }
                     store.addNotification({
                       title: "Invalid information",
                       message: <ErrorMsg msgs={response.data.error_message} />,
                       type: "danger",
                       insert: "top",
                       container: "top-right",
                       animationIn: ["animated", "fadeIn"],
                       animationOut: ["animated", "fadeOut"],
                       dismiss: {
                         duration: 20000,
                         onScreen: false
                       }
                     });

                     this.setState({
                       openCancelConfirm:false,
                       currentTransaction: false,
                       openSb: false
                     })
                 }else{

                     this.componentDidMount();

                     this.setState({
                       openCancelConfirm:false,
                       currentTransaction: false,
                       openSb: true
                     })

                 }



              })
              .catch((error) => {
                  //handle error
                  console.log(error);

            });

        }else{
            this.setState({
              openCancelConfirm:false,
              currentTransaction: false
            })
        }

    }



  componentDidMount() {
    var ctoken = localStorage.getItem('ctoken');
    var token = localStorage.getItem('token');
    if(ctoken){
        var bodyFormData = new FormData();
        bodyFormData.append('access_token', ctoken);
        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
        bodyFormData.append('jham_token', token);
        bodyFormData.append('limit', this.state.limit);
        bodyFormData.append('offset', this.state.offset);
        bodyFormData.append('active', "");


        axios({
              method: 'post',
              url: 'https://api.earental.com/account/my_profile',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
              if(response.data.error > 0){
                  store.addNotification({
                    title: "Session expire",
                    message: response.data.error_msg,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: false
                    }
                  });
              }else{

                  var totaLongTerms = 0;
                  var totaShortTerms = 0;
                  if(response.data.schedules !== null){
                      totaLongTerms = response.data.schedules.longterms;
                      totaShortTerms = response.data.schedules.shortterms;
                  }

                  var ongoingRental = [];
                  if(response.data.ongoingrental !== null && response.data.ongoingrental.length > 0){
                      ongoingRental = response.data.ongoingrental;
                  }else{
                      this.setState({noResult:true});
                  }

                  this.setState({
                    firstName:response.data.data.customer_firstname,
                    lastName:response.data.data.customer_lastname,
                    email:response.data.data.customer_email,
                    lessorCode:response.data.data.c_lessor_code,
                    dateCreated: moment(response.data.data.customer_date_created).format('LL'),
                    phoneNumber:response.data.data.customer_phonenumber,
                    totalOngoinRental:response.data.customer_ongoing_rental_total,
                    currentAvatar:response.data.data.customer_profile_image,
                    totalLongTerm:totaLongTerms,
                    totalShortTerm:totaShortTerms,
                    ongoingRental:ongoingRental,
                    totalPages:response.data.total_pages,

                  })

                  localStorage.setItem('current_name', response.data.data.customer_firstname);
                  localStorage.setItem('current_avatar', response.data.data.customer_profile_image);
                //   this.props.handlerState('currentAvatar',response.data.data.customer_profile_image);
              }
          })
          .catch((error) => {
              //handle error
              store.addNotification({
                title: "Session expired",
                message: "Kindly reload/refresh the page and sign-in again.",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 5000,
                  onScreen: false
                }
              });



        });
    }else{
        store.addNotification({
          title: "Session expired",
          message: "Kindly reload/refresh the page and sign-in again.",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: false
          }
        });
    }


  }

  handlePageChanged(newPage) {
      this.setState({ currentPage : newPage });

      var ctoken = localStorage.getItem('ctoken');
      var token = localStorage.getItem('token');
      var currentOffset = this.state.limit * newPage;
      var bodyFormData = new FormData();
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', currentOffset);
      bodyFormData.append('active', "");


      axios({
              method: 'post',
              url: 'https://api.earental.com/account/my_profile',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
              if(response.data.error > 0){
                  store.addNotification({
                    title: "Session expire",
                    message: response.data.error_msg,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: false
                    }
                  });
              }else{
                  this.setState({
                    firstName:response.data.data.customer_firstname,
                    lastName:response.data.data.customer_lastname,
                    email:response.data.data.customer_email,
                    dateCreated: response.data.data.customer_date_created,
                    phoneNumber:response.data.data.customer_phonenumber,
                    totalOngoinRental:response.data.customer_ongoing_rental_total,
                    totalLongTerm:response.data.schedules.longterms,
                    totalShortTerm:response.data.schedules.shortterms,
                    ongoingRental:response.data.ongoingrental,
                    totalPages:response.data.total_pages,

                  })
              }
          })
          .catch((error) => {
              //handle error
              this.setState({ loading:false });
              if (error.response.status == 403) {

                  store.addNotification({
                    title: "Oops Something went wrong",
                    message: "The action you have requested is not allowed. please re-load the page",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: false
                    }
                  });

             }

      });
  }

  renderOngoing() {
      if(this.state.noResult){
          return (
              <div className="vehicle-listing text-center">
                  <br />
                  <p className="not-found"><b>No History Found</b></p>
                  <p>Rental history will be listed here...
                  </p>
              </div>
          );
      }else{
          function CancelButton(props) {

            if(props.status == "Active"){
                return (
                    <td className="text-center">
                    <a className="btn btn-default btn-sm"
                    onClick={props.confirmCancel.bind(this,props.sid)}>Cancel</a>
                    </td>
                );
            }else if(props.status == "Cancelled"){
                return (
                    <td className="text-center">Cancelled
                    </td>
                );
            }else{
                return (
                    <td className="text-center">Done
                    </td>
                );

            }

          }

          function StatusText(props) {

            if(props.status == "Active"){
                return (
                    <>{props.status} <i className="fa fa-check"></i></>
                );
            }else if(props.status == "Cancelled"){
                return (
                    <>{props.status} <i className="fa fa-times"></i></>
                );
            }else{
                return (
                    <>{props.status}</>
                );

            }

          }
          return (
              <div>
                  <table className="table table-bordered table-striped table-booking-history">
                      <thead>
                          <tr>
                              <th>Type</th>
                              <th>Invoice No</th>
                              <th>Vehicle Name</th>
                              <th>Order Date</th>
                              <th>Execution Date</th>
                              <th>Cost</th>
                              <th>Status</th>
                          </tr>
                      </thead>
                      <tbody>
                          { this.state.ongoingRental.map(rental =>
                                  <tr key={rental.s_id}>
                                      <td className="booking-history-type"><i className="fa fa-calendar"></i><small>{rental.data.s_payment_mode}</small>
                                      </td>
                                      <td className="booking-history-title">{rental.data.s_id}</td>
                                      <td className="booking-history-title">{rental.data.product_name} - {rental.data.product_type}</td>
                                      <td>{moment(rental.data.s_date_added).format('MM/DD/YYYY')}</td>
                                      <td>{moment(rental.data.s_start_date).format('MM/DD/YYYY')}<i className="fa fa-long-arrow-right"></i>{moment(rental.data.s_end_date).format('MM/DD/YYYY')}</td>
                                      <td>${rental.data.s_payable_amount}</td>
                                      <td className="text-center">
                                          <StatusText  status={rental.data.s_status}  />
                                      </td>
                                  </tr>
                              )
                          }

                      </tbody>
                  </table>
                  <Pager
                      total={this.state.totalPages}
                      current={this.state.currentPage}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: 'First', last: 'Last' }}
                      className="pagination-md"
                      onPageChanged={this.handlePageChanged}
                  />
              </div>
          );
      }

  }


  render() {
      function ModalConfirmCancel(props){
          const classes = useStyles();
          const [isOpen, setIsOpen] = useState(false);

          const Transition = React.forwardRef(function Transition(props, ref) {
            return <Slide direction="up" ref={ref} {...props} />;
          });

          return (
              <Dialog
                open={props.openState}
                TransitionComponent={Transition}
                keepMounted
                onClose={props.cancelTransaction.bind(this,false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle id="alert-dialog-slide-title">{"Your about to cancel a transaction"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    Are you sure you want to proceed?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={props.cancelTransaction.bind(this,false)} color="primary">
                    No
                  </Button>
                  <Button onClick={props.cancelTransaction.bind(this,true)} color="primary">
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            );
      }

      function Alert(props) {
          return <MuiAlert elevation={6} variant="filled" {...props} />;
      }

    return (
        <>
            <ModalConfirmCancel
                openState={this.state.openCancelConfirm}
                cancelTransaction={this.cancelTransaction}
             />
            {this.renderOngoing()}
            <Snackbar open={this.state.openSb} autoHideDuration={6000} onClose={this.handleCloseSb}>
                <Alert onClose={this.handleCloseSb} severity="success" className="sb-msg">
                    Transaction successfully cancelled.
                </Alert>
            </Snackbar>
        </>
    );
  }
}

export default History
