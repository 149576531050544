import React, { Component } from "react";
import bgNotFound from './assets/img/196_365_2048x1365.jpg';
import "./assets/css/aboutus.css";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import ReactGA from 'react-ga';

var notFoundCss = {
  backgroundImage: "url(" + bgNotFound + ")"
};

var titleCss = {
  fontSize:"170px",
  marginBottom:"0px"
};

var buttonCss = {
  fontSize:"17px",
};



class NotFoundPage extends Component {

    constructor (props) {
        ReactGA.set({ page: window.location.pathname });
        ReactGA.pageview(window.location.pathname);
        super(props)
        this.state = {
            startDate: new Date(),
            endDate: new Date()
        };
    }



    componentDidMount() {
        window.scrollTo(0, 0)
    }

  render() {
    return (
        <div className="about-us-cont">
            <div className="bg-holder">
                <div className="bg-mask"></div>
                <div className="bg-blur" style={notFoundCss}></div>
                <div className="bg-content">
                    <div className="container">
                        <div className="gap gap-big text-center text-white">
                            <Typography variant="h1" component="h1" gutterBottom style={titleCss}>
                                404
                            </Typography>
                            <Typography variant="h3" gutterBottom>
                                Ooops!!!
                            </Typography>
                            <Typography variant="h4" gutterBottom>
                                THIS PAGE DOESN'T EXIST OR IN UNAVAILABLE
                            </Typography>
                            <br />

                            <Link
                             component="button"
                             variant="body2"
                             onClick={() => {
                               window.location.href = "/";
                             }}
                           >
                               <Button size="large" variant="contained" color="primary" style={buttonCss}>
                               Go Back to Home
                               </Button>
                           </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default NotFoundPage;
