import React, {forwardRef,Component,useState } from "react";
import {
  NavLink,
  HashRouter
} from "react-router-dom";

import axios, { post } from 'axios';
import { store } from 'react-notifications-component';

import noImage from './../../assets/img/no-image.jpg';
import DatePicker from "react-datepicker";
import Image, { Shimmer } from 'react-shimmer'
import ContentLoader, { Facebook } from "react-content-loader";
import Pager from 'react-pager';
import ScriptTag from 'react-script-tag';
import moment from "moment";

import { makeStyles } from '@material-ui/core/styles';
import {
  createMuiTheme,
  withStyles,
  ThemeProvider
} from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import SendIcon from '@material-ui/icons/Send';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import PaymentIcon from '@material-ui/icons/Payment';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import Switch from '@material-ui/core/Switch';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Input from '@material-ui/core/Input';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import BackspaceRoundedIcon from '@material-ui/icons/BackspaceRounded';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { grey, orange } from '@material-ui/core/colors';
import Chip from '@material-ui/core/Chip';
import ProductPreview from '../dashboardPages/preview/ProductPreview';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        outline: 'none'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paragraph: {
        fontSize:15
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
        fontSize:"20px",
        textTransform:"uppercase"
    },
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(3),
    },
    button: {
      marginTop: '35px',
      float: 'right'
    },
    newCardForm: {
      color: grey[800],
      '& .MuiFormControl-root': {
        '& .MuiFormLabel-root': {
          fontSize: '1.2rem',
          '&.Mui-focused': {
            color: grey[800],
            backgroundColor: 'white',
            paddingRight: 5,
          },
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: 0,
          '&:hover fieldset': {
            borderColor: '#B4B4B4',
          },
          '&.Mui-focused fieldset': {
            borderColor: orange[600],
          },
          '& input': {
            fontSize: 15,
            font:'auto'
          },
        },
      }
    }
}));

const MyLoader = () => (
    <ContentLoader
        height={170}
        width={262}
        speed={1}
    >
        <rect x="0" y="0" rx="0" ry="0" width="262" height="170" />
    </ContentLoader>
);

const columns = [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 's_start_date', headerName: 'Start Date', width: 130 },
    { field: 's_end_date', headerName: 'End Date', width: 130 },
    { field: 's_date_added', headerName: 'Date Added', width: 150 }
];



class Users extends Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
        this.handlePageChanged = this.handlePageChanged.bind(this);

        this.state = {
             users: [],
             loadingclass: 'noHidden',
             driverDob: "",
             openSb: false,
             openAddModal: false,
             openSbRemove: false,
             openSbEdit: false,
             openToggleConfirm: false,
             currentPayoutEmail:false,
             currentVehicleData:false,
             openEditModal: false,
             limit:8,
             totalPage:0,
             currentPage:0,
             visiblePage:5,
             offset:0,
             addDname:"",
             addDDob:"",
             addDMobile:"",
             addDExp:"",
             addDLicense:"",
             addDNric:"",
             addDAddress:"",
             noResult:false,
             hidePager:"",
             src: null,
             crop: {
               unit: '%',
               width: '120',
               aspect: 16 / 16,
           },
           lessorCode:false,
           disableFields:false,
           selectedRangeDate:null,
           restrictedDates:null,
           openSchedule:false,
           proceedSchedule: true,
           currentProductRestricted:null,
           currentVehicleName:"",
           currentRestrictedList:null,
           deleteFromRestricted:null,
           openSbSchedule:false,
           openSbResend: false,
           manualSchedText:"update schedule",
           customer_lessor_parent:1,
           openSbAdmin: false
        };

        this.onAddFormSubmit = this.onAddFormSubmit.bind(this);
        this.onEditFormSubmit = this.onEditFormSubmit.bind(this);
        this.handleCloseSb = this.handleCloseSb.bind(this);
        this.handleCloseSbDelete = this.handleCloseSbDelete.bind(this);
        this.openAddModal = this.openAddModal.bind(this);
        this.closeAddModal = this.closeAddModal.bind(this);
        this.openToggleConfirmModal = this.openToggleConfirmModal.bind(this);
        this.handleChangeAddInput = this.handleChangeAddInput.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.handleCloseSbEdit = this.handleCloseSbEdit.bind(this);
        this.handleCloseSbResend = this.handleCloseSbResend.bind(this);
        this.resendInvitation = this.resendInvitation.bind(this);
        this.removeUser = this.removeUser.bind(this);
        this.toggleAdmin = this.toggleAdmin.bind(this);



    }


    handleCloseSb(){
        this.setState({
          openSb:false,
        })
    }

    handleCloseSbDelete(){
        this.setState({
          openSbRemove:false,
        })
    }

    handleCloseSbEdit(){
        this.setState({
          openSbEdit:false,
        })
    }

    handleCloseSbResend(){
        this.setState({
          openSbResend:false,
          openSbAdmin:false,
        })
    }

    openAddModal(){
        this.setState({
          openAddModal:true,
        })
    }

    closeAddModal(){
        this.setState({
          openAddModal:false,
        })

    }

    openEditModal(data,e){
        console.log(data);
        this.setState({
          openEditModal:true,
          currentVehicleData:data,
          currentPayoutEmail:data.lp_id
        })
    }

    closeEditModal(){

        this.setState({
          openEditModal:false,
          currentVehicleData:false,
          currentPayoutEmail: false
        })

    }


    handleChangeAddInput(name,e){
        this.setState({
          [name]:e.target.value,
        })
    }


    openToggleConfirmModal(email,e){
        this.setState({
          openToggleConfirm:true,
          currentUserEmail: email
        })
    }

    removeUser(confirmation,e){

        if(confirmation){


            var bodyFormData = new FormData();
            var token = localStorage.getItem('token');
            var ctoken = localStorage.getItem('ctoken');

            bodyFormData.append('email', this.state.currentUserEmail);
            bodyFormData.append('jham_token', token);
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


            axios({
                  method: 'post',
                  url: 'https://api.earental.com/lessor/lessor_delete_user',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {

                 if(response.data.error > 0){

                     this.setState({
                       openToggleConfirm:false,
                       openSbRemove: false,
                     })


                 }else{
                     this.setState({
                         openToggleConfirm:false,
                         openSbRemove: true,
                     })

                     this.getUsers();

                 }

              })
              .catch((error) => {
                  //handle error
                  console.log(error);

            });

        }else{
            this.setState({
              openToggleConfirm:false,
              currentPayoutEmail: false
            })
        }

    }

    toggleAdmin(uid,e){

        var bodyFormData = new FormData();
        var token = localStorage.getItem('token');
        var ctoken = localStorage.getItem('ctoken');

        bodyFormData.append('jham_token', token);
        bodyFormData.append('access_token', ctoken);
        bodyFormData.append('uid', uid);
        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


        axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/lessor_user_toggle_admin',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {

             if(response.data.error > 0){

                 store.addNotification({
                   title: "Error",
                   message: response.data.error_message,
                   type: "danger",
                   insert: "top",
                   container: "top-right",
                   animationIn: ["animated", "fadeIn"],
                   animationOut: ["animated", "fadeOut"],
                   dismiss: {
                     duration: 3000,
                     onScreen: false
                   }
                 });

             }else{
                //  this.setState({
                //      openSbAdmin: true,
                //  })
                 //
                //  this.getUsers();

             }

          })
          .catch((error) => {
              //handle error
              console.log(error);

        });


    }

    resendInvitation(email,e){

        var bodyFormData = new FormData();
        var token = localStorage.getItem('token');
        var ctoken = localStorage.getItem('ctoken');

        bodyFormData.append('email', email);
        bodyFormData.append('jham_token', token);
        bodyFormData.append('access_token', ctoken);
        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


        axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/resend_invitation',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {

             if(response.data.error > 0){

                 store.addNotification({
                   title: "Error",
                   message: response.data.error_message,
                   type: "danger",
                   insert: "top",
                   container: "top-right",
                   animationIn: ["animated", "fadeIn"],
                   animationOut: ["animated", "fadeOut"],
                   dismiss: {
                     duration: 3000,
                     onScreen: false
                   }
                 });

                 this.setState({
                   openSbResend: false,
                   currentPayoutEmail: false
                 })


             }else{
                 this.setState({
                   openSbResend: true
                 })
             }

          })
          .catch((error) => {
              //handle error
              console.log(error);

        });

    }



    onAddFormSubmit(event){
        event.preventDefault();

        document.getElementById("addUserBtn").disabled = true;
        document.getElementById("addUserBtn").innerText = "Sending...";


        var bodyFormData = new FormData();
        var token = localStorage.getItem('token');
        var ctoken = localStorage.getItem('ctoken');
        bodyFormData.append('email', event.target.email.value);
        // bodyFormData.append('status', event.target.status.value);


        bodyFormData.append('jham_token', token);
        bodyFormData.append('access_token', ctoken);
        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


        axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/add_new_user',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {

             if(response.data.error > 0){
                 store.addNotification({
                   title: "Oops Something Went Wrong",
                   message: "Please try again after a minute.",
                   type: "danger",
                   insert: "top",
                   container: "top-right",
                   animationIn: ["animated", "fadeIn"],
                   animationOut: ["animated", "fadeOut"],
                   dismiss: {
                     duration: 20000,
                     onScreen: false
                   }
                 });
             }else{

                 this.setState({ openSb: true });
                 this.setState({ openAddModal: false });
                 this.getUsers();
                 document.getElementById("vehicle-add-form").reset();

             }

             document.getElementById("addUserBtn").disabled = false;
             document.getElementById("addUserBtn").innerText = "Send Invitation";


          })
          .catch((error) => {
              //handle error
              console.log(error);

        });




    }

    onEditFormSubmit(event){
        event.preventDefault();

        document.getElementById("updatePayoutBtn").disabled = true;
        document.getElementById("updatePayoutBtn").innerText = "Updating...";

        var bodyFormData = new FormData();
        var token = localStorage.getItem('token');
        var ctoken = localStorage.getItem('ctoken');
        bodyFormData.append('method', event.target.method.value);
        bodyFormData.append('bn', event.target.bn.value);
        bodyFormData.append('bb', event.target.bb.value);
        bodyFormData.append('ban', event.target.ban.value);
        // bodyFormData.append('status', event.target.status.value);

        bodyFormData.append('lp_id', this.state.currentPayoutEmail);

        bodyFormData.append('jham_token', token);
        bodyFormData.append('access_token', ctoken);
        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


        axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/update_payout_method',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
             if(response.data.error > 0){
                 function ErrorMsg(props) {
                   return (
                       props.msgs.map((item,i) =>
                           <li key={"err-"+item.i}>{item.value}</li>
                       )

                   );
                 }
                 store.addNotification({
                   title: "Invalid information",
                   message: <ErrorMsg msgs={response.data.error_message} />,
                   type: "danger",
                   insert: "top",
                   container: "top-right",
                   animationIn: ["animated", "fadeIn"],
                   animationOut: ["animated", "fadeOut"],
                   dismiss: {
                     duration: 2000,
                     onScreen: false
                   }
                 });
             }else{

                 this.setState({ openSbEdit: true });
                 this.setState({ openEditModal: false });

                 this.getUsers();




             }

             document.getElementById("updatePayoutBtn").disabled = false;
             document.getElementById("updatePayoutBtn").innerText = "Update this payout method";

          })
          .catch((error) => {
              //handle error
              console.log(error);
            //   document.getElementById("updatePayoutBtn").disabled = false;
            //   document.getElementById("updatePayoutBtn").innerText = "Update this vehicle";

        });



    }




    handlePageChanged(newPage) {
        this.setState({ currentPage : newPage });
        setTimeout(() => {
            this.getUsers();

        }, 150);
    }


  getUsers() {
      var ctoken = localStorage.getItem('ctoken');
      var token = localStorage.getItem('token');
      var bodyFormData = new FormData();

      var currentOffset = this.state.limit * this.state.currentPage;
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', currentOffset);
      bodyFormData.append('keyword', "");


      axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/lessor_users',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
              console.log(response);
              if(response.data.lessor_user && response.data.lessor_user !== null){
                  this.setState({
                      users:response.data.lessor_user,
                      loading:false,
                      loadingclass:'isHidden',
                      totalPage:response.data.total_pages,
                      noResult:false,
                      hidePager: ""
                  });
              }else{
                  this.setState({
                      users:[],
                      loading:false,
                      loadingclass:'isHidden',
                      totalPage:0,
                      noResult:true,
                      hidePager:"isHidden"
                  });
              }
              this.setState({
                  customer_lessor_parent:parseInt(response.data.data.customer_lessor_parent),
                  customer_lessor_acc_admin:parseInt(response.data.data.customer_lessor_acc_admin)
              });



          })
          .catch((error) => {
              //handle error
              this.setState({
                  users:[],
                  loading:false,
                  loadingclass:'isHidden',
                  totalPage:0
              });

      });


  }

  componentDidMount() {
      this.getUsers();
  }


    renderListing() {
        function UserList(props){
            const classes = useStyles();
            const [checkedAdmin, setCheckedAdmin] = React.useState(parseInt(props.details.customer_lessor_acc_admin));


            const handleChangeAdmin = (uid) => () => {
                setCheckedAdmin(!checkedAdmin);
                props.toggleAdmin(uid);

            };

            return (
                <Card className={classes.root+' payout-card'}>
                    {( props.details.customer_firstname == null) && (
                        <CardHeader
                          avatar={
                            <Avatar  className={classes.avatar} alt={props.details.li_email.charAt(0)} />
                          }
                          title={"Pending"}
                          subheader={props.details.li_email}
                        />
                    )}
                    {( props.details.customer_firstname != null) && (
                        <CardHeader
                          avatar={
                            <Avatar  className={classes.avatar} alt={props.details.li_email.charAt(0)} src={"https://api.earental.com/uploads/profile/"+props.details.customer_profile_image}/>
                          }
                          title={"Active"}
                          subheader={props.details.li_email}
                        />
                    )}
                    <CardContent>
                        {( props.details.customer_firstname != null) && (
                            <>
                                <Typography variant="h6" style={{textTransform:"uppercase"}}>
                                    {props.details.customer_firstname+" "+props.details.customer_lastname}
                                </Typography>
                                <Typography  component="p">
                                    {props.details.customer_phonenumber}
                                </Typography>
                            </>
                        )}
                        {(props.subAccount == 0 || props.adminStatus == 1) && (
                            <hr />
                        )}
                      <Grid container spacing={0}>
                          {( props.details.customer_firstname == null && (props.subAccount == 0 || props.adminStatus == 1)) && (
                              <>
                                  <Grid item md={12} sm={12} xs={12} align="right">
                                    <ButtonGroup >
                                        <Button color="primary" startIcon={<SendIcon />} onClick={props.resendInvitation.bind(this,props.details.li_email)}>
                                            Re-Send Invitation
                                        </Button>
                                        <Button color="secondary" startIcon={<DeleteIcon />} onClick={props.openToggleConfirmModal.bind(this,props.details.li_email)}>
                                            Remove user
                                        </Button>
                                    </ButtonGroup>
                                  </Grid>
                              </>
                          )}
                          {( props.details.customer_firstname != null && (props.subAccount == 0 || props.adminStatus == 1)) && (
                            <>
                                <Grid item md={6} sm={6} xs={6} align="left">
                                    <FormControlLabel
                                    control={<Switch color="primary" size="small" checked={checkedAdmin} onChange={handleChangeAdmin(props.details.customer_id)} />}
                                    label="Admin"
                                />
                                </Grid>
                                <Grid item md={6} sm={6} xs={6} align="right">
                                    <ButtonGroup  color="primary" >
                                    <Button color="secondary" startIcon={<DeleteIcon />} onClick={props.openToggleConfirmModal.bind(this,props.details.li_email)}>
                                        Remove user
                                    </Button>
                                    </ButtonGroup>
                                </Grid>
                            </>
                          )}


                      </Grid>
                    </CardContent>
                </Card>
              );

        }
        if (!this.state.noResult) {
            return (
                <div className="row row-wrap">
                    <div className={this.state.loadingclass}>
                        <div className="col-md-3">
                            <MyLoader  />
                        </div>
                        <div className="col-md-3">
                            <MyLoader  />
                        </div>
                        <div className="col-md-3">
                            <MyLoader  />
                        </div>
                        <div className="col-md-3">
                            <MyLoader  />
                        </div>
                    </div>

                    { this.state.users.map(user =>
                        <div className="col-md-3" key={"dbox-"+user.li_id}>
                            <UserList
                                details={user}
                                openToggleConfirmModal={this.openToggleConfirmModal}
                                openEditModal={this.openEditModal}
                                resendInvitation={this.resendInvitation}
                                subAccount={this.state.customer_lessor_parent}
                                adminStatus={this.state.customer_lessor_acc_admin}
                                toggleAdmin={this.toggleAdmin}
                            />
                        </div>
                        )
                    }

                </div>
            );
        }else{
            return (
                <div className="vehicle-listing text-center">
                    <br />
                    <p className="not-found"><b>No User Found</b></p>
                    <p>Start creating now by clicking the "Blue icon" Button at the Bottom Right
                    </p>
                </div>
            );
        }

    }



  render() {

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    function ModalAdd(props){
        const classes = useStyles();
        const status = [
          { title: 'Active' },
          { title: 'Inactive' }
        ];
        const methods = [
          { title: 'Bank Transfer' },
        ];
        return (

            <Dialog
                disableEnforceFocus={false}
                fullWidth={true}
                maxWidth = {'md'}
                open={props.openState}
                onClose={props.closeModal}
                disableBackdropClick={true}
                aria-labelledby="form-dialog-title"
                className="driver-modal">
                <DialogTitle>
                    New user
                    <IconButton aria-label="close" onClick={props.closeModal} style={{float:"right"}}>
                        <CloseIcon fontSize="large"	/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="mfp-with-anim mfp-driver-dialog" id="new-card-dialog">
                        <form className="cc-form" id="vehicle-add-form" onSubmit={props.onAddFormSubmit.bind(this)}>
                            <fieldset disabled={props.disableFields} >
                                <Grid container spacing={3} className={classes.newCardForm}>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Email"
                                            name="email"
                                            type="email"
                                            fullWidth
                                            required

                                         />
                                    </Grid>

                                </Grid>
                                <br />
                                <Divider />
                                <br />
                                <div className="clearfix">
                                    <Button
                                        id="addUserBtn"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        size="large"
                                        className="pull-right"
                                        endIcon={<SendIcon />}
                                    >
                                      Send Invitation
                                    </Button>
                                </div>
                            </fieldset>


                        </form>

                    </div>

                </DialogContent>

            </Dialog>
          );
    }

    function ModalEdit(props){
        const classes = useStyles();
        const [isOpen, setIsOpen] = useState(false);
        const [selectedDate, setSelectedDate] = React.useState(new Date())
        const [currentMethod, setCurrentMethod] = useState(props.currentData.lp_method);
        const [currentBn, setCurrentBn] = useState(props.currentData.lp_beneficiary_name);
        const [currentBb, setCurrentBb] = useState(props.currentData.lp_beneficiary_bank);
        const [currentBan, setCurrentBan] = useState(props.currentData.lp_beneficiary_account_no);
        const [currentStatus, setCurrentStatus] = useState(props.currentData.lp_status);

        const handleMethodChange = (e) => {
            setCurrentMethod(e.target.value);
        };
        const handleBnChange = (e) => {
            setCurrentBn(e.target.value);
        };
        const handleBbChange = (e) => {
            setCurrentBb(e.target.value);
        };
        const handleBanChange = (e) => {
            setCurrentBan(e.target.value);
        };

        const handleStatusChange = (e,values) => {
            setCurrentStatus(values);
        };

        const status = [
          { title: 'Active' },
          { title: 'Inactive' }
        ];
        const methods = [
          { title: 'Bank Transfer' },
        ];

        return (

            <Dialog
                fullWidth={true}
                maxWidth = {'md'}
                disableBackdropClick={true}
                disableEnforceFocus={false}
                open={props.openState}
                onClose={props.closeModal}
                aria-labelledby="form-dialog-title"
                className="driver-modal">
                <DialogTitle id="form-dialog-title">
                    Update Payout Method
                    <IconButton aria-label="close" onClick={props.closeModal} style={{float:"right"}}>
                        <CloseIcon fontSize="large"	/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="mfp-with-anim mfp-driver-dialog" id="new-card-dialog">
                        <form className="cc-form" onSubmit={props.onEditFormSubmit.bind(this)}>
                            <fieldset >
                                <Grid container spacing={3} className={classes.newCardForm}>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <Autocomplete
                                            value={currentMethod}
                                            options={methods.map((option) => option.title)}
                                            renderInput={(params) => (
                                            <TextField {...params} label="Method" name="method" variant="outlined" required  />
                                            )}
                                         />
                                    </Grid>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            value={currentBn}
                                            onChange={handleBnChange}
                                            variant="outlined"
                                            label="Beneficiary Name"
                                            name="bn"
                                            type="text"
                                            fullWidth
                                            required

                                         />
                                    </Grid>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            value={currentBb}
                                            onChange={handleBbChange}
                                            variant="outlined"
                                            label="Beneficiary Bank"
                                            name="bb"
                                            type="text"
                                            fullWidth
                                            required

                                         />
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <TextField
                                            value={currentBan}
                                            onChange={handleBanChange}
                                            variant="outlined"
                                            label="Beneficiary Account No"
                                            name="ban"
                                            type="text"
                                            fullWidth
                                            required

                                         />
                                    </Grid>
                                </Grid>
                                <br />
                                <Divider />
                                <br />
                                <div className="clearfix">
                                    <Button id="updatePayoutBtn" type="submit" variant="contained" className="pull-right" size="large" color="primary">
                                      Update this payout method
                                    </Button>
                                </div>
                            </fieldset>

                        </form>

                    </div>

                </DialogContent>

            </Dialog>
          );
    }


    function ModalConfirmDelete(props){
        const classes = useStyles();
        const [isOpen, setIsOpen] = useState(false);

        const Transition = React.forwardRef(function Transition(props, ref) {
          return <Slide direction="up" ref={ref} {...props} />;
        });

        return (
            <Dialog
              open={props.openState}
              TransitionComponent={Transition}
              keepMounted
              onClose={props.removeUser.bind(this,false)}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">{"You're about to remove a user"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Are you sure you want to proceed?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" size="large" onClick={props.removeUser.bind(this,false)} color="secondary">
                  No
                </Button>
                <Button variant="contained" size="large"  onClick={props.removeUser.bind(this,true)} color="primary">
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          );
    }

    return (
        <div className="vehicle-cont">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/">
              Dashboard
          </Link>
          <Typography color="textPrimary">Users</Typography>
        </Breadcrumbs>
        <br />

        <div className="">
            <ModalAdd
                onAddFormSubmit={this.onAddFormSubmit}
                openState={this.state.openAddModal}
                closeModal={this.closeAddModal}
                handleInputChange={this.handleChangeAddInput}
                driverState={this.state}
                onSelectFile={this.onSelectFile}
                onImageLoaded={this.onImageLoaded}
                onCropComplete={this.onCropComplete}
                onCropChange={this.onCropChange}
                disableFields={this.state.disableFields}
             />
             <ModalConfirmDelete
                 openState={this.state.openToggleConfirm}
                 removeUser={this.removeUser}
              />

             <ModalEdit
                  onEditFormSubmit={this.onEditFormSubmit}
                  openState={this.state.openEditModal}
                  closeModal={this.closeEditModal}
                  currentData={this.state.currentVehicleData}
                  disableFields={this.state.disableFields}
             />


            <div className="row">
                <div className="col-md-12">
                    {this.renderListing()}

                    <Pager
                        total={this.state.totalPage}
                        current={this.state.currentPage}
                        visiblePages={this.state.visiblePage}
                        titles={{ first: 'First', last: 'Last' }}
                        className={"pagination-md "+this.state.hidePager}
                        onPageChanged={this.handlePageChanged}
                        showPagination={false}
                    />

                </div>

            </div>

             <Snackbar open={this.state.openSb} autoHideDuration={6000} onClose={this.handleCloseSb}>
                 <Alert onClose={this.handleCloseSb} severity="success" className="sb-msg">
                     Invitation successfully sent.
                 </Alert>
             </Snackbar>

             <Snackbar open={this.state.openSbRemove} autoHideDuration={6000} onClose={this.handleCloseSbDelete}>
                 <Alert onClose={this.handleCloseSbDelete} severity="success" className="sb-msg">
                     User successfully removed.
                 </Alert>
             </Snackbar>

             <Snackbar open={this.state.openSbEdit} autoHideDuration={6000} onClose={this.handleCloseSbEdit}>
                 <Alert onClose={this.handleCloseSbEdit} severity="success" className="sb-msg">
                     Payout Method successfully updated.
                 </Alert>
             </Snackbar>

             <Snackbar open={this.state.openSbResend} autoHideDuration={6000} onClose={this.handleCloseSbResend}>
                 <Alert onClose={this.handleCloseSbResend} severity="success" className="sb-msg">
                     Invitation successfully re-sent.
                 </Alert>
             </Snackbar>

             <Snackbar open={this.state.openSbAdmin} autoHideDuration={6000} onClose={this.handleCloseSbResend}>
                 <Alert onClose={this.handleCloseSbResend} severity="success" className="sb-msg">
                     Admin status successfully updated.
                 </Alert>
             </Snackbar>



            {(this.state.customer_lessor_parent == 0 || this.state.customer_lessor_acc_admin == 1) && (
                <div className="fab-cont-right">
                    <Fab color="primary" aria-label="add" onClick={this.openAddModal}>
                      <GroupAddIcon />
                    </Fab>
                </div>
            )}


        </div>
        <div className="gap"></div>


        </div>
    );
  }
}

export default Users
