import React, {forwardRef,Component,useState } from "react";
import {
  NavLink,
  HashRouter
} from "react-router-dom";

import axios, { post } from 'axios';
import { store } from 'react-notifications-component';

import noImage from './../../assets/img/no-image.jpg';
import DatePicker from "react-datepicker";
import Image, { Shimmer } from 'react-shimmer'
import ContentLoader, { Facebook } from "react-content-loader";
import Pager from 'react-pager';
import ScriptTag from 'react-script-tag';
import moment from "moment";

import { makeStyles } from '@material-ui/core/styles';
import {
  createMuiTheme,
  withStyles,
  ThemeProvider
} from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import PaymentIcon from '@material-ui/icons/Payment';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Input from '@material-ui/core/Input';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import BackspaceRoundedIcon from '@material-ui/icons/BackspaceRounded';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { grey, orange } from '@material-ui/core/colors';
import Chip from '@material-ui/core/Chip';
import ProductPreview from '../dashboardPages/preview/ProductPreview';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        outline: 'none'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paragraph: {
        fontSize:15
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
        fontSize:"20px",
        textTransform:"uppercase"
    },
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(3),
    },
    button: {
      marginTop: '35px',
      float: 'right'
    },
    newCardForm: {
      color: grey[800],
      '& .MuiFormControl-root': {
        '& .MuiFormLabel-root': {
          fontSize: '1.2rem',
          '&.Mui-focused': {
            color: grey[800],
            backgroundColor: 'white',
            paddingRight: 5,
          },
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: 0,
          '&:hover fieldset': {
            borderColor: '#B4B4B4',
          },
          '&.Mui-focused fieldset': {
            borderColor: orange[600],
          },
          '& input': {
            fontSize: 15,
            font:'auto'
          },
        },
      }
    }
}));

const MyLoader = () => (
    <ContentLoader
        height={170}
        width={262}
        speed={1}
    >
        <rect x="0" y="0" rx="0" ry="0" width="262" height="170" />
    </ContentLoader>
);

const columns = [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 's_start_date', headerName: 'Start Date', width: 130 },
    { field: 's_end_date', headerName: 'End Date', width: 130 },
    { field: 's_date_added', headerName: 'Date Added', width: 150 }
];



class PayoutMethod extends Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
        this.handlePageChanged = this.handlePageChanged.bind(this);

        this.state = {
             payouts: [],
             loadingclass: 'noHidden',
             driverDob: "",
             openSb: false,
             openAddModal: false,
             openSbDefaulted: false,
             openSbEdit: false,
             openDeleteConfirm: false,
             currentPayout:false,
             currentVehicleData:false,
             openEditModal: false,
             limit:8,
             totalPage:0,
             currentPage:0,
             visiblePage:5,
             offset:0,
             addDname:"",
             addDDob:"",
             addDMobile:"",
             addDExp:"",
             addDLicense:"",
             addDNric:"",
             addDAddress:"",
             noResult:false,
             hidePager:"",
             src: null,
             crop: {
               unit: '%',
               width: '120',
               aspect: 16 / 16,
           },
           lessorCode:false,
           disableFields:false,
           selectedRangeDate:null,
           restrictedDates:null,
           openSchedule:false,
           proceedSchedule: true,
           currentProductRestricted:null,
           currentVehicleName:"",
           currentRestrictedList:null,
           deleteFromRestricted:null,
           openSbSchedule:false,
           manualSchedText:"update schedule"
        };

        this.onAddFormSubmit = this.onAddFormSubmit.bind(this);
        this.onEditFormSubmit = this.onEditFormSubmit.bind(this);
        this.handleCloseSb = this.handleCloseSb.bind(this);
        this.handleCloseSbDelete = this.handleCloseSbDelete.bind(this);
        this.openAddModal = this.openAddModal.bind(this);
        this.closeAddModal = this.closeAddModal.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.openDeleteConfirmModal = this.openDeleteConfirmModal.bind(this);
        this.handleChangeAddInput = this.handleChangeAddInput.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.handleCloseSbEdit = this.handleCloseSbEdit.bind(this);

        this.onSelectFile = this.onSelectFile.bind(this);
        this.onImageLoaded = this.onImageLoaded.bind(this);
        this.onCropComplete = this.onCropComplete.bind(this);
        this.onCropChange = this.onCropChange.bind(this);
        this.handleRangeSelect = this.handleRangeSelect.bind(this);
        this.disableCalendarRestrictedOnly = this.disableCalendarRestrictedOnly.bind(this);
        this.handleToggleSchedule = this.handleToggleSchedule.bind(this);
        this.handleRowSelection = this.handleRowSelection.bind(this);
        this.handleUpdateSchedule = this.handleUpdateSchedule.bind(this);
        this.handleCloseSbSchedule = this.handleCloseSbSchedule.bind(this);


    }

    disableCalendarRestrictedOnly = (date, event) => {
        if(this.state.restrictedDates){
                for (let i = 0; i < this.state.restrictedDates.length; i++) {
                    var endDate = moment(this.state.restrictedDates[i].s_end_date).add(1, 'day').format("YYYY-MM-DD");
                    var startDate = moment(this.state.restrictedDates[i].s_start_date).subtract(1, 'day').format("YYYY-MM-DD");
                    if(moment(date.date).isBetween(startDate, endDate)){
                        return true;
                    }
                }

        }else{
            return false;
        }
    }

    handleRowSelection = (newSelections) => {

        if(!this.state.selectedRangeDate && newSelections.rows.length <= 0){
            this.setState({
                proceedSchedule:true,
                deleteFromRestricted:newSelections.rows,
                manualSchedText:"Update schedule"
            })
        }else{
            if(newSelections.rows.length <= 0){
                this.setState({
                    deleteFromRestricted:null,
                    manualSchedText:"Update schedule"
                })
            }else{
                this.setState({
                    proceedSchedule:false,
                    deleteFromRestricted:newSelections.rows,
                    manualSchedText:"REMOVE SCHEDULE"
                })
            }

        }

    };


    handleRangeSelect = (value, event) => {

        var startDate = moment(value[0]).format("YYYY-MM-DD");
        var endDate = moment(value[1]).format("YYYY-MM-DD");

        this.setState({
          proceedSchedule:false,
          selectedRangeDate:[new Date(startDate),new Date(endDate)]
        })


    };

    onSelectFile = e => {
      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          this.setState({ src: reader.result })
        );
        reader.readAsDataURL(e.target.files[0]);
      }
    };

    // If you setState the crop in here you should return false.
      onImageLoaded = image => {
        this.imageRef = image;
      };

      onCropComplete = crop => {
        this.makeClientCrop(crop);
      };

      onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
      };

      async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
          );
          this.setState({ croppedImageUrl });

        }
      }


      getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
        const base64Image = canvas.toDataURL('image/jpeg');

        return base64Image;

      }

      handleUpdateSchedule(name,product_id){
          this.setState({
              proceedSchedule:true
          });

          var bodyFormData = new FormData();
          var token = localStorage.getItem('token');
          var ctoken = localStorage.getItem('ctoken');
          var bodyFormData = new FormData();

          bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
          bodyFormData.append('jham_token', token);
          bodyFormData.append('access_token', ctoken);
          bodyFormData.append('product_id', this.state.currentProductRestricted);

          if(this.state.selectedRangeDate){

             var startDate = moment(this.state.selectedRangeDate[0]).format("YYYY-MM-DD");
             var endDate = moment(this.state.selectedRangeDate[1]).format("YYYY-MM-DD");
             var rangeDate = startDate+","+endDate;
             console.log(rangeDate);
             bodyFormData.append('rangeDate', rangeDate);
          }
          if(this.state.deleteFromRestricted){
              bodyFormData.append('toDelete', JSON.stringify(this.state.deleteFromRestricted));
          }


          axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/update_restricted_date',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
            if(response.data.error > 0){
                store.addNotification({
                  title: "Error",
                  message: response.data.error_msg,
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                    duration: 3000,
                    onScreen: false
                  }
                });
                this.setState({
                    selectedRangeDate:null,
                    deleteFromRestricted:null,
                    proceedSchedule:true
                });
            }else{
                if(response.data.data){
                    this.setState({
                        restrictedDates: response.data.data,
                        currentRestrictedList: response.data.list,
                    });
                }else{
                    this.setState({
                        restrictedDates: null,
                        currentRestrictedList: null
                    });
                }

                this.setState({
                    selectedRangeDate:null,
                    deleteFromRestricted:null,
                    proceedSchedule:true,
                    openSbSchedule:true
                });
            }



          })
          .catch((error) => {


          });



      }

    handleToggleSchedule(name,product_id){
        if(this.state.openSchedule == false){
            var bodyFormData = new FormData();
            var token = localStorage.getItem('token');
            var ctoken = localStorage.getItem('ctoken');
            var bodyFormData = new FormData();

            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
            bodyFormData.append('jham_token', token);
            bodyFormData.append('product_id', product_id);

            axios({
                method: 'post',
                url: 'https://api.earental.com/lessor/restricted_date',
                data: bodyFormData,
                withCredentials: true,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then((response) => {
                if(response.data.data){
                    this.setState({
                        restrictedDates: response.data.data,
                        currentRestrictedList: response.data.list,
                    });
                }else{
                    this.setState({
                        restrictedDates: null,
                        currentRestrictedList: null
                    });
                }

                this.setState({
                    currentVehicleName:name,
                    currentProductRestricted:product_id
                })



            })
            .catch((error) => {


            });
        }else{
            this.setState({
                restrictedDates: null,
                currentRestrictedList: null,
                currentVehicleName:null,
                currentProductRestricted:null,
                selectedRangeDate:null,
                deleteFromRestricted:null,
            });
        }

        this.setState({
            openSchedule:!this.state.openSchedule
        })
    }

    handleCloseSbSchedule(){
        this.setState({
          openSbSchedule:false,
        })
    }
    handleCloseSb(){
        this.setState({
          openSb:false,
        })
    }

    handleCloseSbDelete(){
        this.setState({
          openSbDefaulted:false,
        })
    }

    handleCloseSbEdit(){
        this.setState({
          openSbEdit:false,
        })
    }

    openAddModal(){
        this.setState({
          openAddModal:true,
        })
    }

    closeAddModal(){
        this.setState({
          openAddModal:false,
        })

    }

    openEditModal(data,e){
        console.log(data);
        this.setState({
          openEditModal:true,
          currentVehicleData:data,
          currentPayout:data.lp_id
        })
    }

    closeEditModal(){

        this.setState({
          openEditModal:false,
          currentVehicleData:false,
          currentPayout: false
        })

    }


    handleChangeAddInput(name,e){
        this.setState({
          [name]:e.target.value,
        })
    }


    openDeleteConfirmModal(data,e){
        this.setState({
          openDeleteConfirm:true,
          currentPayout: data.lp_id
        })
    }

    closeDeleteModal(confirmation,e){

        if(confirmation){


            var bodyFormData = new FormData();
            var token = localStorage.getItem('token');
            var ctoken = localStorage.getItem('ctoken');

            bodyFormData.append('lp_id', this.state.currentPayout);
            bodyFormData.append('jham_token', token);
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


            axios({
                  method: 'post',
                  url: 'https://api.earental.com/lessor/update_payout_method_default',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {

                 if(response.data.error > 0){

                     store.addNotification({
                       title: "Error",
                       message: response.data.error_message,
                       type: "danger",
                       insert: "top",
                       container: "top-right",
                       animationIn: ["animated", "fadeIn"],
                       animationOut: ["animated", "fadeOut"],
                       dismiss: {
                         duration: 3000,
                         onScreen: false
                       }
                     });

                     this.setState({
                       openDeleteConfirm:false,
                       openSbDefaulted: false,
                       currentPayout: false
                     })


                 }else{
                     this.setState({
                       openDeleteConfirm:false,
                       openSbDefaulted: true,
                       currentPayout: false,
                       loadingclass: 'noHidden',
                     })


                     this.getPayouts();


                 }

              })
              .catch((error) => {
                  //handle error
                  console.log(error);

            });

        }else{
            this.setState({
              openDeleteConfirm:false,
              currentPayout: false
            })
        }

    }





    onAddFormSubmit(event){
        event.preventDefault();

        document.getElementById("addPayoutBtn").disabled = true;
        document.getElementById("addPayoutBtn").innerText = "Saving...";


        var bodyFormData = new FormData();
        var token = localStorage.getItem('token');
        var ctoken = localStorage.getItem('ctoken');
        bodyFormData.append('method', event.target.method.value);
        bodyFormData.append('bn', event.target.bn.value);
        bodyFormData.append('bb', event.target.bb.value);
        bodyFormData.append('ban', event.target.ban.value);
        // bodyFormData.append('status', event.target.status.value);


        bodyFormData.append('jham_token', token);
        bodyFormData.append('access_token', ctoken);
        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


        axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/add_payout_method',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {

             if(response.data.error > 0){
                 store.addNotification({
                   title: "Oops Something Went Wrong",
                   message: "Please try again after a minute.",
                   type: "danger",
                   insert: "top",
                   container: "top-right",
                   animationIn: ["animated", "fadeIn"],
                   animationOut: ["animated", "fadeOut"],
                   dismiss: {
                     duration: 20000,
                     onScreen: false
                   }
                 });
             }else{

                 this.setState({ openSb: true });
                 this.setState({ openAddModal: false });
                 this.getPayouts();
                 document.getElementById("vehicle-add-form").reset();

             }

             document.getElementById("addPayoutBtn").disabled = false;
             document.getElementById("addPayoutBtn").innerText = "save this payout method";


          })
          .catch((error) => {
              //handle error
              console.log(error);

        });




    }

    onEditFormSubmit(event){
        event.preventDefault();

        document.getElementById("updatePayoutBtn").disabled = true;
        document.getElementById("updatePayoutBtn").innerText = "Updating...";

        var bodyFormData = new FormData();
        var token = localStorage.getItem('token');
        var ctoken = localStorage.getItem('ctoken');
        bodyFormData.append('method', event.target.method.value);
        bodyFormData.append('bn', event.target.bn.value);
        bodyFormData.append('bb', event.target.bb.value);
        bodyFormData.append('ban', event.target.ban.value);
        // bodyFormData.append('status', event.target.status.value);

        bodyFormData.append('lp_id', this.state.currentPayout);

        bodyFormData.append('jham_token', token);
        bodyFormData.append('access_token', ctoken);
        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


        axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/update_payout_method',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
             if(response.data.error > 0){
                 function ErrorMsg(props) {
                   return (
                       props.msgs.map((item,i) =>
                           <li key={"err-"+item.i}>{item.value}</li>
                       )

                   );
                 }
                 store.addNotification({
                   title: "Invalid information",
                   message: <ErrorMsg msgs={response.data.error_message} />,
                   type: "danger",
                   insert: "top",
                   container: "top-right",
                   animationIn: ["animated", "fadeIn"],
                   animationOut: ["animated", "fadeOut"],
                   dismiss: {
                     duration: 2000,
                     onScreen: false
                   }
                 });
             }else{

                 this.setState({ openSbEdit: true });
                 this.setState({ openEditModal: false });

                 this.getPayouts();




             }

             document.getElementById("updatePayoutBtn").disabled = false;
             document.getElementById("updatePayoutBtn").innerText = "Update this payout method";

          })
          .catch((error) => {
              //handle error
              console.log(error);
            //   document.getElementById("updatePayoutBtn").disabled = false;
            //   document.getElementById("updatePayoutBtn").innerText = "Update this vehicle";

        });



    }




    handlePageChanged(newPage) {
        this.setState({ currentPage : newPage });
        setTimeout(() => {
            this.getPayouts();

        }, 150);
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = event => {
        event.preventDefault();

        const request = {
            pickup: this.state.pickup,
            checkin: this.state.checkin,
            timein: this.state.timein,
            dropoff: this.state.dropoff,
            checkout: this.state.checkout,
            timeout: this.state.timeout,
        };

        axios.post(`https://jsonplaceholder.typicode.com/users`, { request })
        .then(res => {
          console.log(res);
          console.log(res.data);
        })
    }






  getPayouts() {
      var ctoken = localStorage.getItem('ctoken');
      var token = localStorage.getItem('token');
      var bodyFormData = new FormData();

      var currentOffset = this.state.limit * this.state.currentPage;
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', currentOffset);
      bodyFormData.append('keyword', "");


      axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/lessor_payout_method',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
              console.log(response);
              if(response.data.lessor_payouts && response.data.lessor_payouts !== null){
                console.log(response.data.data);
                  this.setState({
                      payouts:response.data.lessor_payouts,
                      loading:false,
                      loadingclass:'isHidden',
                      totalPage:response.data.total_pages,
                      noResult:false,
                      hidePager: ""
                  });
              }else{
                  this.setState({
                      payouts:[],
                      loading:false,
                      loadingclass:'isHidden',
                      totalPage:0,
                      noResult:true,
                      hidePager:"isHidden"
                  });
              }



          })
          .catch((error) => {
              //handle error
              this.setState({
                  payouts:[],
                  loading:false,
                  loadingclass:'isHidden',
                  totalPage:0
              });

      });


  }

  componentDidMount() {
      this.getPayouts();
  }


    renderListing() {
        function Method(props){
            const classes = useStyles();

            return (
                <Card className={classes.root+' payout-card'}>
                    <CardHeader
                      avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                          {props.details.lp_beneficiary_name.charAt(0)}
                        </Avatar>
                      }
                      action={
                        <IconButton aria-label="update" title="Update payout method" onClick={props.openEditModal.bind(this,props.details)}>
                          <EditOutlinedIcon title="Update payout method" />
                        </IconButton>
                      }
                      title={props.details.lp_beneficiary_name}
                      subheader={props.details.lp_method}
                    />
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                        {props.details.lp_beneficiary_bank}
                    </Typography>
                    <Typography variant="body2" color="secondaryText" component="p">
                        {props.details.lp_beneficiary_account_no}
                    </Typography>
                    <br />
                    <Grid container spacing={0}>

                        {( parseInt(props.details.lp_default) == 1) && (
                            <Grid item md={12} sm={12} xs={12} align="right">
                                <Button variant="outlined" color="primary" startIcon={<CheckOutlinedIcon />}>
                                    Default
                                </Button>
                            </Grid>
                        )}

                        {(parseInt(props.details.lp_default) == 0) && (
                            <Grid item md={12} sm={12} xs={12} align="right">
                                <Button variant="contained" color="primary" onClick={props.openDeleteConfirmModal.bind(this,props.details)}>
                                Set as Default
                                </Button>
                            </Grid>
                        )}

                    </Grid>
                  </CardContent>
                </Card>
              );

        }
        if (!this.state.noResult) {
            return (
                <div className="row row-wrap">
                    <div className={this.state.loadingclass}>
                        <div className="col-md-3">
                            <MyLoader  />
                        </div>
                        <div className="col-md-3">
                            <MyLoader  />
                        </div>
                        <div className="col-md-3">
                            <MyLoader  />
                        </div>
                        <div className="col-md-3">
                            <MyLoader  />
                        </div>
                    </div>

                    { this.state.payouts.map(payout =>
                        <div className="col-md-3" key={"dbox-"+payout.lp_id}>
                            <Method details={payout} openDeleteConfirmModal={this.openDeleteConfirmModal} openEditModal={this.openEditModal} />
                        </div>
                        )
                    }

                </div>
            );
        }else{
            return (
                <div className="vehicle-listing text-center">
                    <br />
                    <p className="not-found"><b>No Payout Method Found</b></p>
                    <p>Start creating now by clicking the "Blue icon" Button at the Bottom Right
                    </p>
                </div>
            );
        }

    }



  render() {

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    function ModalAdd(props){
        const classes = useStyles();
        const status = [
          { title: 'Active' },
          { title: 'Inactive' }
        ];
        const methods = [
          { title: 'Bank Transfer' },
        ];
        return (

            <Dialog
                disableEnforceFocus={false}
                fullWidth={true}
                maxWidth = {'md'}
                open={props.openState}
                onClose={props.closeModal}
                disableBackdropClick={true}
                aria-labelledby="form-dialog-title"
                className="driver-modal">
                <DialogTitle>
                    New Payout Method
                    <IconButton aria-label="close" onClick={props.closeModal} style={{float:"right"}}>
                        <CloseIcon fontSize="large"	/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="mfp-with-anim mfp-driver-dialog" id="new-card-dialog">
                        <form className="cc-form" id="vehicle-add-form" onSubmit={props.onAddFormSubmit.bind(this)}>
                            <fieldset disabled={props.disableFields} >
                                <p><b>Basic Information</b></p>
                                <Divider />
                                <br />
                                <Grid container spacing={3} className={classes.newCardForm}>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <Autocomplete
                                            value="Bank Transfer"
                                            options={methods.map((option) => option.title)}
                                            renderInput={(params) => (
                                            <TextField {...params} label="Method" name="method" variant="outlined" required  />
                                            )}
                                         />
                                    </Grid>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Beneficiary Name"
                                            name="bn"
                                            type="text"
                                            fullWidth
                                            required

                                         />
                                    </Grid>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Beneficiary Bank"
                                            name="bb"
                                            type="text"
                                            fullWidth
                                            required

                                         />
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Beneficiary Account No"
                                            name="ban"
                                            type="text"
                                            fullWidth
                                            required

                                         />
                                    </Grid>
                                </Grid>
                                <br />
                                <Divider />
                                <br />
                                <div className="clearfix">
                                    <Button id="addPayoutBtn" type="submit" variant="contained" className="pull-right" size="large" color="primary">
                                      Save this Payout Method
                                    </Button>
                                </div>
                            </fieldset>


                        </form>

                    </div>

                </DialogContent>

            </Dialog>
          );
    }

    function ModalEdit(props){
        const classes = useStyles();
        const [isOpen, setIsOpen] = useState(false);
        const [selectedDate, setSelectedDate] = React.useState(new Date())
        const [currentMethod, setCurrentMethod] = useState(props.currentData.lp_method);
        const [currentBn, setCurrentBn] = useState(props.currentData.lp_beneficiary_name);
        const [currentBb, setCurrentBb] = useState(props.currentData.lp_beneficiary_bank);
        const [currentBan, setCurrentBan] = useState(props.currentData.lp_beneficiary_account_no);
        const [currentStatus, setCurrentStatus] = useState(props.currentData.lp_status);

        const handleMethodChange = (e) => {
            setCurrentMethod(e.target.value);
        };
        const handleBnChange = (e) => {
            setCurrentBn(e.target.value);
        };
        const handleBbChange = (e) => {
            setCurrentBb(e.target.value);
        };
        const handleBanChange = (e) => {
            setCurrentBan(e.target.value);
        };

        const handleStatusChange = (e,values) => {
            setCurrentStatus(values);
        };

        const status = [
          { title: 'Active' },
          { title: 'Inactive' }
        ];
        const methods = [
          { title: 'Bank Transfer' },
        ];

        return (

            <Dialog
                fullWidth={true}
                maxWidth = {'md'}
                disableBackdropClick={true}
                disableEnforceFocus={false}
                open={props.openState}
                onClose={props.closeModal}
                aria-labelledby="form-dialog-title"
                className="driver-modal">
                <DialogTitle id="form-dialog-title">
                    Update Payout Method
                    <IconButton aria-label="close" onClick={props.closeModal} style={{float:"right"}}>
                        <CloseIcon fontSize="large"	/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="mfp-with-anim mfp-driver-dialog" id="new-card-dialog">
                        <form className="cc-form" onSubmit={props.onEditFormSubmit.bind(this)}>
                            <fieldset >
                                <Grid container spacing={3} className={classes.newCardForm}>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <Autocomplete
                                            value={currentMethod}
                                            options={methods.map((option) => option.title)}
                                            renderInput={(params) => (
                                            <TextField {...params} label="Method" name="method" variant="outlined" required  />
                                            )}
                                         />
                                    </Grid>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            value={currentBn}
                                            onChange={handleBnChange}
                                            variant="outlined"
                                            label="Beneficiary Name"
                                            name="bn"
                                            type="text"
                                            fullWidth
                                            required

                                         />
                                    </Grid>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            value={currentBb}
                                            onChange={handleBbChange}
                                            variant="outlined"
                                            label="Beneficiary Bank"
                                            name="bb"
                                            type="text"
                                            fullWidth
                                            required

                                         />
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <TextField
                                            value={currentBan}
                                            onChange={handleBanChange}
                                            variant="outlined"
                                            label="Beneficiary Account No"
                                            name="ban"
                                            type="text"
                                            fullWidth
                                            required

                                         />
                                    </Grid>
                                </Grid>
                                <br />
                                <Divider />
                                <br />
                                <div className="clearfix">
                                    <Button id="updatePayoutBtn" type="submit" variant="contained" className="pull-right" size="large" color="primary">
                                      Update this payout method
                                    </Button>
                                </div>
                            </fieldset>

                        </form>

                    </div>

                </DialogContent>

            </Dialog>
          );
    }


    function ModalConfirmDelete(props){
        const classes = useStyles();
        const [isOpen, setIsOpen] = useState(false);

        const Transition = React.forwardRef(function Transition(props, ref) {
          return <Slide direction="up" ref={ref} {...props} />;
        });

        return (
            <Dialog
              open={props.openState}
              TransitionComponent={Transition}
              keepMounted
              onClose={props.closeDeleteModal.bind(this,false)}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">{"You're about to set this as default payout method"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Are you sure you want to proceed?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" size="large" onClick={props.closeDeleteModal.bind(this,false)} color="secondary">
                  No
                </Button>
                <Button variant="contained" size="large"  onClick={props.closeDeleteModal.bind(this,true)} color="primary">
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          );
    }

    return (
        <div className="vehicle-cont">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/">
              Dashboard
          </Link>
          <Typography color="textPrimary">Payout Method</Typography>
        </Breadcrumbs>
        <br />

        <div className="">
            <ModalAdd
                onAddFormSubmit={this.onAddFormSubmit}
                openState={this.state.openAddModal}
                closeModal={this.closeAddModal}
                handleInputChange={this.handleChangeAddInput}
                driverState={this.state}
                onSelectFile={this.onSelectFile}
                onImageLoaded={this.onImageLoaded}
                onCropComplete={this.onCropComplete}
                onCropChange={this.onCropChange}
                disableFields={this.state.disableFields}
             />
             <ModalConfirmDelete
                 openState={this.state.openDeleteConfirm}
                 closeDeleteModal={this.closeDeleteModal}
              />

             <ModalEdit
                  onEditFormSubmit={this.onEditFormSubmit}
                  openState={this.state.openEditModal}
                  closeModal={this.closeEditModal}
                  currentData={this.state.currentVehicleData}
                  disableFields={this.state.disableFields}
             />


            <div className="row">
                <div className="col-md-12">
                    {this.renderListing()}

                    <Pager
                        total={this.state.totalPage}
                        current={this.state.currentPage}
                        visiblePages={this.state.visiblePage}
                        titles={{ first: 'First', last: 'Last' }}
                        className={"pagination-md "+this.state.hidePager}
                        onPageChanged={this.handlePageChanged}
                        showPagination={false}
                    />

                </div>

            </div>

             <Snackbar open={this.state.openSb} autoHideDuration={6000} onClose={this.handleCloseSb}>
                 <Alert onClose={this.handleCloseSb} severity="success" className="sb-msg">
                     Payout Method successfully added.
                 </Alert>
             </Snackbar>

             <Snackbar open={this.state.openSbDefaulted} autoHideDuration={6000} onClose={this.handleCloseSbDelete}>
                 <Alert onClose={this.handleCloseSbDelete} severity="success" className="sb-msg">
                     Payout Method successfully updated as default.
                 </Alert>
             </Snackbar>

             <Snackbar open={this.state.openSbEdit} autoHideDuration={6000} onClose={this.handleCloseSbEdit}>
                 <Alert onClose={this.handleCloseSbEdit} severity="success" className="sb-msg">
                     Payout Method successfully updated.
                 </Alert>
             </Snackbar>

             <Snackbar open={this.state.openSbSchedule} autoHideDuration={6000} onClose={this.handleCloseSbSchedule}>
                 <Alert onClose={this.handleCloseSbSchedule} severity="success" className="sb-msg">
                     Schedule successfully updated.
                 </Alert>
             </Snackbar>
             <div className="fab-cont-right">
                 <Fab color="primary" aria-label="add" onClick={this.openAddModal}>
                   <PaymentIcon />
                 </Fab>
             </div>

        </div>
        <div className="gap"></div>


        </div>
    );
  }
}

export default PayoutMethod
