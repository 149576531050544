import React, { Component,useState } from "react";
import {
  NavLink,
  HashRouter
} from "react-router-dom";

import axios, { post } from 'axios';
import { store } from 'react-notifications-component';
import Pager from 'react-pager';
import moment from "moment";

import aboutTopBanner from './../assets/img/about-us.jpg';
import aboutParallaxBanner from './../assets/img/parallax-about.jpg';

import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';


import "./../assets/css/profile.css";

//SIDE CARD UNG MY AVATAR
import ProfileCard from "./../profile/Profilecard";


var aboutTopBannerImg = {
  backgroundImage: "url(" + aboutTopBanner + ")"
};

var aboutParallaxBannerImg = {
  backgroundImage: "url(" + aboutParallaxBanner + ")"
};

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        outline: 'none'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paragraph: {
        fontSize:15
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
        fontSize:"20px",
        textTransform:"uppercase"
    },
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(3),
    },
    button: {
      marginTop: '35px',
      float: 'right'
    },
}));


class History extends Component {

    constructor (props) {
        super(props)
        window.scrollTo(0, 0);
        this.state = {
            ongoingRental: [],
            limit:10,
            offset:0,
            visiblePage: 5,
            currentPage:0,
            totalPages: 0,
            noResult: false,
            openCancelConfirm: false,
            currentTransaction:false,
            openSb: false,
            lessorCode:false
        };
        this.handlePageChanged = this.handlePageChanged.bind(this);
    }

  componentDidMount() {
    var ctoken = localStorage.getItem('ctoken');
    var token = localStorage.getItem('token');
    if(ctoken){
        var bodyFormData = new FormData();
        bodyFormData.append('access_token', ctoken);
        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
        bodyFormData.append('jham_token', token);
        bodyFormData.append('limit', this.state.limit);
        bodyFormData.append('offset', this.state.offset);
        bodyFormData.append('active', "");


        axios({
              method: 'post',
              url: 'https://api.earental.com/account/loyalty_mile_activity',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
              if(response.data.error > 0){
                  store.addNotification({
                    title: "Session expire",
                    message: response.data.error_msg,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: false
                    }
                  });
              }else{


                  var activity = [];
                  if(response.data.activity !== null && response.data.activity.length > 0){
                      activity = response.data.activity;
                  }else{
                      this.setState({noResult:true});
                  }

                  this.setState({
                    firstName:response.data.data.customer_firstname,
                    lastName:response.data.data.customer_lastname,
                    email:response.data.data.customer_email,
                    lessorCode:response.data.data.c_lessor_code,
                    dateCreated: moment(response.data.data.customer_date_created).format('LL'),
                    phoneNumber:response.data.data.customer_phonenumber,
                    currentAvatar:response.data.data.customer_profile_image,
                    ongoingRental:activity,
                    totalPages:response.data.total_pages,

                  })

                  localStorage.setItem('current_name', response.data.data.customer_firstname);
                  localStorage.setItem('current_avatar', response.data.data.customer_profile_image);
                //   this.props.handlerState('currentAvatar',response.data.data.customer_profile_image);
              }
          })
          .catch((error) => {
              //handle error
              store.addNotification({
                title: "Session expired",
                message: "Kindly reload/refresh the page and sign-in again.",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 5000,
                  onScreen: false
                }
              });



        });
    }else{
        store.addNotification({
          title: "Session expired",
          message: "Kindly reload/refresh the page and sign-in again.",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: false
          }
        });
    }


  }

  handlePageChanged(newPage) {
      this.setState({ currentPage : newPage });

      var ctoken = localStorage.getItem('ctoken');
      var token = localStorage.getItem('token');
      var currentOffset = this.state.limit * newPage;
      var bodyFormData = new FormData();
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', currentOffset);


      axios({
              method: 'post',
              url: 'https://api.earental.com/account/loyalty_mile_activity',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
              if(response.data.error > 0){
                  store.addNotification({
                    title: "Session expire",
                    message: response.data.error_msg,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: false
                    }
                  });
              }else{

                  this.setState({
                    firstName:response.data.data.customer_firstname,
                    lastName:response.data.data.customer_lastname,
                    email:response.data.data.customer_email,
                    lessorCode:response.data.data.c_lessor_code,
                    dateCreated: moment(response.data.data.customer_date_created).format('LL'),
                    phoneNumber:response.data.data.customer_phonenumber,
                    currentAvatar:response.data.data.customer_profile_image,
                    ongoingRental:response.data.activity,
                    totalPages:response.data.total_pages,

                  })
              }
          })
          .catch((error) => {
              //handle error
              this.setState({ loading:false });
              if (error.response.status == 403) {

                  store.addNotification({
                    title: "Oops Something went wrong",
                    message: "The action you have requested is not allowed. please re-load the page",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: false
                    }
                  });

             }

      });
  }

  renderOngoing() {
      if(this.state.noResult){
          return (
              <div className="vehicle-listing text-center">
                  <br />
                  <p className="not-found"><b>No Activity Found</b></p>
                  <p>Loyalty Miles activity will be listed here...
                  </p>
              </div>
          );
      }else{
          function CancelButton(props) {

            if(props.status == "Active"){
                return (
                    <td className="text-center">
                    <a className="btn btn-default btn-sm"
                    onClick={props.confirmCancel.bind(this,props.sid)}>Cancel</a>
                    </td>
                );
            }else if(props.status == "Cancelled"){
                return (
                    <td className="text-center">Cancelled
                    </td>
                );
            }else{
                return (
                    <td className="text-center">Done
                    </td>
                );

            }

          }

          function StatusText(props) {

            if(props.status == "Active"){
                return (
                    <>{props.status} <i className="fa fa-check"></i></>
                );
            }else if(props.status == "Cancelled"){
                return (
                    <>{props.status} <i className="fa fa-times"></i></>
                );
            }else{
                return (
                    <>{props.status}</>
                );

            }

          }

          function TypeIcon(props) {

            if(props.type == "earn"){
                return (
                    <><i className="fa fa-money"></i></>
                );
            }else if(props.type == "convert"){
                return (
                    <><i className="fa fa-exchange"></i></>
                );
            }else if(props.type == "refund"){
                return (
                    <><i className="fa fa-times-circle"></i></>
                );
            }else if(props.type == "used"){
                return (
                    <><i className="fa fa-check-circle"></i></>
                );

            }

          }

          function TypeMiles(props) {

            if(props.type == "earn"){
                return (
                    <><td>+ {props.miles}</td></>
                );
            }else if(props.type == "convert"){
                return (
                    <><td>- {props.miles}</td></>
                );
            }else if(props.type == "refund"){
                return (
                    <><td>+ 0</td></>
                );
            }else if(props.type == "used"){
                return (
                    <><td>+ 0</td></>
                );

            }

          }

          function TypeCredit(props) {

            if(props.type == "used"){
                return (
                    <><td>- {props.credit}</td></>
                );
            }else{
                return (
                    <><td>+ {props.credit}</td></>
                );
            }

          }

          function TypeActivity(props) {

            if(props.type == "earn"){
                return (
                    <>
                        <td>{props.activity}
                        <br />
                        {props.schedule}
                    </td></>
                );
            }else if(props.type == "convert"){
                return (
                    <>
                        <td>
                            {props.activity}
                            <br />
                            {props.miles} Miles Point
                        </td>
                    </>
                );
            }else if(props.type == "refund"){
                return (
                    <>
                        <td>
                            {props.activity}
                            <br />
                            {props.schedule} - Refund
                        </td>
                    </>
                );
            }else if(props.type == "used"){
                return (
                    <>
                        <td>
                            {props.activity}
                            <br />
                            {props.schedule} - Credit Used
                        </td>
                    </>
                );

            }

          }
          return (
              <div>
                  <table className="table table-bordered table-striped table-booking-history">
                      <thead>
                          <tr>
                              <th>Type</th>
                              <th>Activity</th>
                              <th>Miles Point</th>
                              <th>Available Point</th>
                              <th>Credit Added</th>
                              <th>Available Credit</th>
                              <th>Date Tracked</th>
                          </tr>
                      </thead>
                      <tbody>
                          { this.state.ongoingRental.map(rental =>
                                  <tr key={rental.lml_log}>
                                      <td className="booking-history-type"><TypeIcon type={rental.lml_type}/><small>{rental.lml_type}</small>
                                      </td>
                                      <TypeActivity type={rental.lml_type} activity={rental.lml_activity} schedule={rental.lml_schedule_id} miles={rental.lml_miles}/>
                                      <TypeMiles type={rental.lml_type} miles={rental.lml_miles}/>
                                      <td>{rental.lml_current_total_miles}</td>
                                      <TypeCredit type={rental.lml_type} credit={rental.lml_credit_added}/>
                                      <td>{rental.lml_current_credit}</td>
                                      <td>{moment(rental.lml_added_date).format('MM/DD/YYYY')}</td>
                                  </tr>
                              )
                          }

                      </tbody>
                  </table>
                  <Pager
                      total={this.state.totalPages}
                      current={this.state.currentPage}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: 'First', last: 'Last' }}
                      className="pagination-md"
                      onPageChanged={this.handlePageChanged}
                  />
              </div>
          );
      }

  }


  render() {

      function Alert(props) {
          return <MuiAlert elevation={6} variant="filled" {...props} />;
      }

    return (
        <>
            {this.renderOngoing()}
            <Snackbar open={this.state.openSb} autoHideDuration={6000} onClose={this.handleCloseSb}>
                <Alert onClose={this.handleCloseSb} severity="success" className="sb-msg">
                    Transaction successfully cancelled.
                </Alert>
            </Snackbar>
        </>
    );
  }
}

export default History
