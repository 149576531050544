import React from 'react';
import {
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import ReactCrop from 'react-image-crop';
import Camera from 'react-html5-camera-photo';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import { Img } from 'react-image';
import NoImage from './../assets/img/no-image.jpg';

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: 'none',
  },
  noImage: {
    width: '100%'
  },
  imgError: {
    color: '#F44336',
    fontSize: '1rem',
    margin: 0,
    marginLeft: 14,
    marginRight: 14,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImageUpload(props) {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [openUploader, setOpenUploader] = React.useState(false);
  const [openCamera, setOpenCamera] = React.useState(false);
  const [imgSrc, setImgSrc] = React.useState(null);
  const [selectedImg, setSelectedImg] = React.useState(null);
  const [cropOptions, setCropOptions] = React.useState({
    unit: '%',
    width: 90,
    height: 50,
    x: 5,
    y: 25
  });
  const [imgRef, setImgRef] = React.useState(false);
  const [history, setHistory] = React.useState(null);
  const error = props.error;
  let inputFileButton = React.useRef(null);

  const handleOpenCropper = (openCamera) => {
    setOpenUploader(true);
    setOpenCamera(openCamera);
    setHistory({
      imgSrc: imgSrc,
      selectedImg: selectedImg,
      cropOptions: cropOptions,
      imgRef: imgRef,
    })
  }

  const handleCancel = (e) => {
    setOpenUploader(false);
    if (history) {
      setImgSrc(history.imgSrc);
      setSelectedImg(history.selectedImg);
      setCropOptions(history.cropOptions);
      setImgRef(history.imgRef);
      setHistory(null);
    }
  }

  const handleDone = (type, crop) => {
    if (selectedImg) {
      const croppedImage = getCroppedImg(imgRef, cropOptions, 'newFile.jpeg');
      try {
        setImgSrc(croppedImage);
        props.onImageChange(croppedImage, props.stateKey);
        setTimeout(() => {
          setOpenUploader(false);
        }, 350);
      } catch (e) {
        console.error(e)
      }
    } else {
      setOpenUploader(false);
    }
  }

  const handleToggleCamera = (e) => {
    setOpenCamera(!openCamera);
  }

  const handleTakePhoto = (dataUri) => {
    setSelectedImg(dataUri);
    setOpenCamera(!openCamera);
  }

  const handleFileSelect = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      var reader = new FileReader();
      reader.addEventListener('load', () => {
        setSelectedImg(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
      setOpenCamera(false);
      setCropOptions({
        unit: '%',
        width: 90,
        height: 50,
        x: 5,
        y: 25
      });
    }
  };

  const onCropChange = (crop, percentCrop) => {
    setCropOptions(percentCrop);
  };

  const onCropImageLoaded = (image) => {
    setImgRef(image);
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);
    const base64Image = canvas.toDataURL('image/jpeg');
    return base64Image;
  }

  return (
    <React.Fragment>
      <FormControl style={{ width: '100%' }}>
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          <Grid item>{props.label}</Grid>
          <Grid item>
            <ButtonGroup color="secondary">
              <Button onClick={() => handleOpenCropper(true)}><CameraAltIcon /></Button>
              <Button onClick={() => handleOpenCropper(false)}>Upload</Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        {imgSrc ? (
          <Box>
            <Img
              src={imgSrc}
              loader={<CircularProgress size="1.5rem" />}
              unloader={<img src={NoImage} />}
            />
          </Box>
        ) : (
          <Box>
            {props.type == "add" && (
              <img src={NoImage} className={classes.noImage} />
            )}
            {props.type == "edit" && (
              <Img
                src={props.api.url + "/uploads/profile/" + props.initialImage}
                loader={<CircularProgress size="1.5rem" />}
                unloader={<img src={NoImage} />}
              />
            )}
          </Box>
        )}
        {error != "" && (
          <Typography component="span" className={classes.imgError}>
            {error}
          </Typography>
        )}
      </FormControl>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openUploader}
        TransitionComponent={Transition}
        onClose={handleCancel}
        fullScreen={fullScreen}
      >
        <DialogTitle>
          Upload/Browse Photo
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2} style={{ marginLeft: 0 }}>
            <Grid item xs={12} >
              {openCamera && (
                <Camera
                  idealFacingMode={"environment"}
                  isImageMirror={false}
                  imageCompression={0.97}
                  isSilentMode={true}
                  idealResolution={{ width: 640, height: 480 }}
                  isMaxResolution={true}
                  isFullscreen={false}
                  sizeFactor={1}
                  onTakePhoto={dataUri => handleTakePhoto(dataUri)}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <ButtonGroup color="secondary">
                <input
                  ref={input => inputFileButton = input}
                  accept="image/*"
                  type="file"
                  onChange={handleFileSelect}
                  style={{ display: 'none' }}
                />
                <Button type="button" startIcon={<ImageSearchIcon />} component="span" onClick={() => inputFileButton.click()} disableElevation>
                  Browse File
                </Button>
                <Button type="button" startIcon={<CameraAltIcon />} component="span" onClick={handleToggleCamera} disableElevation>
                  Toggle Camera
                </Button>
              </ButtonGroup>

            </Grid>
          </Grid>
          {selectedImg && (
            <ReactCrop src={selectedImg} onChange={onCropChange} crop={cropOptions} onChange={newCrop => setCropOptions(newCrop)} onImageLoaded={onCropImageLoaded} />
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions} style={{ display: "flex", justifyContent: "space-between" }}>
          <Button color="default" onClick={handleCancel}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleDone}>Done</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}