import React from 'react';
import {
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from 'axios';
import moment from 'moment';
import { store } from 'react-notifications-component';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Calendar from 'react-calendar';
import { grey, green } from '@material-ui/core/colors';
import "react-daterange-picker/dist/css/react-calendar.css";

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#1a90ff',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

const FacebookCircularProgress = (props) => {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: 'none',
  },
  dialogContent: {
    minHeight: 150,
    paddingBottom: theme.spacing(4),
  },
  dialogActions: {
    justifyContent: 'space-between',
    '& button': {
      fontSize: '1rem',
    },
  },
  extendScheduleForm: {
    color: grey[800],
    '& .MuiFormControl-root': {
      '& .MuiFormLabel-root': {
        fontSize: '1.2rem',
        '&.Mui-focused, &.MuiFormLabel-filled': {
          color: grey[800],
          backgroundColor: 'white',
          paddingRight: 5,
        },
      },
      '& .MuiFormControlLabel-label': {
        fontSize: '1.2rem',
      },
      '& .MuiRadio-colorSecondary.Mui-checked': {
        // color: orange[600],
      },
      '& .MuiSelect-select': {
        fontSize: '1.2rem',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        '&:hover fieldset': {
          borderColor: '#B4B4B4',
        },
        '&.Mui-focused fieldset': {
          // borderColor: orange[600],
          borderColor: "#D40029",
        },
        '& input': {
          fontSize: '1.2rem',
        },
      },
      '& .MuiRadio-root': {
        '& .Mui-focused': {
          // color: orange[600],
          color: "#D40029",
        },
      },
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      cursor: 'not-allowed',
      color: '#000000',
    },
    '& .Mui-error': {
      fontSize: '0.9rem',
    },
  },
  checkAvailabilityButton: {
    backgroundColor: green[600] + ' !important',
  },
  calendarContainer: {
    '& .react-calendar': {
      margin: '0 auto',
      border: '1px solid #ECECEC',
      '& .react-calendar__month-view__days': {
        '& button.current-lease': {
          backgroundColor: '#A2F4A5 !important',
        },
        '& button:disabled': {
          backgroundColor: '#FFF',
        },
        '& button.unavailable': {
          backgroundColor: '#F0F0F0',
        },
        '& button.react-calendar__tile--active': {
          backgroundColor: '#3CB371 !important',
        },
        '& button.extension': {
          backgroundColor: green[600],
        },
      },
      '& .react-calendar__tile--now:not(:disabled)': {
        backgroundColor: '#2F96B4',
        color: '#FFF',
      },
    },
  },
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
  },
  legendsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  calendarLegend: {
    width: 20,
    height: 20,
    display: 'inline-block',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ScheduleExtension(props) {
  const theme = useTheme();
  const classes = useStyles();
  const api = {
    url: props.api.url,
    key: props.api.key,
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [state, setState] = React.useState({
    types: ["days", "months"],
    selectedType: "days",
    qty: 0,
  });
  const [errors, setErrors] = React.useState({
    type: "",
    qty: "",
  });
  const [calendarActiveStartDate, setCalendarActiveStartDate] = React.useState(new Date());
  const [unavailableDateRanges, setUnavailableDateRanges] = React.useState([]);
  const [disableCalendarButtons, setDisableCalendarButtons] = React.useState(true);
  const [calendarMinDate, setCalendarMinDate] = React.useState(null);
  const [currentEndDate, setCurrentEndDate] = React.useState(null);
  const [selectedDateRange, setSelectedDateRange] = React.useState(null);
  const [saving, setSaving] = React.useState(false);
  let formSubmitButton = React.useRef(null);

  React.useEffect(() => {
    if (props.open) {
      resetFields();

      let types = ["days"];
      let selectedType = "days";
      let paymentMode = props.schedule.s_payment_mode.toLowerCase();
      if (paymentMode == "daily") {
        types = ["days"];
      } else if (paymentMode == "weekend") {
        types = ["days"];
      } else if (paymentMode == "weekly") {
        types = ["days", "weeks"];
      } else if (paymentMode == "monthly" || paymentMode == "1-year" || paymentMode == "2-years") {
        types = ["days", "months"];
      }

      let calendarMinDate = moment(props.schedule.s_start_date, 'YYYY-MM-DD');
      setCalendarMinDate(calendarMinDate);
      let currentEndDate = moment(props.schedule.s_end_date, 'YYYY-MM-DD');
      setCurrentEndDate(currentEndDate);
      setCalendarActiveStartDate(new Date(calendarMinDate));

      let formData = new FormData();
      formData.append('api_key', api.key);
      formData.append('access_token', localStorage.getItem('ctoken'));
      formData.append('jham_token', localStorage.getItem('token'));
      formData.append('product_id', props.schedule.product_id);

      axios({
        method: 'post',
        url: 'https://api.earental.com/listing/restricted_date',
        data: formData,
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then((response) => {
          if (response.data.data) {
            let restrictedDates = response.data.data;
            let unavailableDateRanges = [];
            for (let i = 0; i < restrictedDates.length; i++) {
              unavailableDateRanges.push({
                // start: moment(restrictedDates[i].s_start_date).subtract(1, "day").format('YYYY-MM-DD'),
                // end: moment(restrictedDates[i].s_end_date).add(1, "day").format('YYYY-MM-DD'),
                start: moment(restrictedDates[i].s_start_date).format('YYYY-MM-DD'),
                end: moment(restrictedDates[i].s_end_date).format('YYYY-MM-DD'),
              });
            }
            // For testing
            // unavailableDateRanges.push({
            //   start: moment("2021-06-28").subtract(1, "day").format('YYYY-MM-DD'),
            //   end: moment("2021-06-30").add(1, "day").format('YYYY-MM-DD'),
            // });
            setUnavailableDateRanges(unavailableDateRanges);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      setState({
        ...state,
        types: types,
        selectedType: selectedType,
      });
    }
  }, [props.open]);

  const isCalendarButtonDisabled = (date) => {
    return disableCalendarButtons;
  }

  const handleCalendarViewChange = (calendar) => {
    if (calendar.view != "month") {
      setDisableCalendarButtons(false);
    } else {
      setDisableCalendarButtons(true);
    }
  }

  const calendarTileClass = (schedule, date) => {
    // let currentLeaseStartDate = moment(schedule.s_start_date).subtract(1, "day").format('YYYY-MM-DD');
    // let currentLeaseEndDate = moment(schedule.s_end_date).add(1, "day").format('YYYY-MM-DD');
    let currentLeaseStartDate = moment(schedule.s_start_date).format('YYYY-MM-DD');
    let currentLeaseEndDate = moment(schedule.s_end_date).format('YYYY-MM-DD');
    if (moment(date.date).isBetween(currentLeaseStartDate, currentLeaseEndDate, undefined, '[]')) {
      return "cal-date-listing current-lease";
    }
    if (unavailableDateRanges.length > 0) {
      // console.log(date.date);
      for (let i = 0; i < unavailableDateRanges.length; i++) {
        if (moment(date.date).isBetween(unavailableDateRanges[i].start, unavailableDateRanges[i].end, undefined, '[]')) {
          return "cal-date-listing unavailable";
        }
      }
    }
    if (moment(date.date).isSameOrBefore(currentLeaseStartDate)) {
      return "cal-date-listing unavailable";
    }
    return "cal-date-listing";
  }

  const handleInputChange = (event) => {
    let target = event.target;
    setState({
      ...state,
      [target.name]: target.value,
    });
    setErrors({
      ...errors,
      [target.name]: "",
    });
  };

  const handleCheckAvailabilityButton = () => {
    if (state.qty > 0) {
      let extStartDate = moment(props.schedule.s_end_date, 'YYYY-MM-DD');
      extStartDate.add(1, 'days');
      let extEndDate = moment(props.schedule.s_end_date, 'YYYY-MM-DD');
      extEndDate.add(state.qty, state.selectedType);
      setCalendarActiveStartDate(new Date(extStartDate));
      // if (props.schedule.s_payment_mode == "daily") {
      //   extEndDate.add(state.qty, 'days');
      // } else if (props.schedule.s_payment_mode == "weekly") {
      //   extEndDate.add(state.qty, 'weeks');
      // } else if (props.schedule.s_payment_mode == "monthly" || props.schedule.s_payment_mode == "1-year" || props.schedule.s_payment_mode == "2-years") {
      //   extEndDate.add(state.qty, 'months');
      // }
      let datesAvailable = true;
      if (unavailableDateRanges) {
        for (let i = 0; i < unavailableDateRanges.length; i++) {
          if (moment(extEndDate).isBetween(unavailableDateRanges[i].start, unavailableDateRanges[i].end, undefined, '[]')) {
            datesAvailable = false;
            break;
          }
        }
      }

      if (datesAvailable) {
        setSelectedDateRange([new Date(extStartDate), new Date(extEndDate)]);
      } else {
        setSelectedDateRange(null);
        setErrors({
          ...errors,
          qty: "Dates not available"
        });
      }
    } else {
      setSelectedDateRange(null);
    }
  }

  const handleSaveButton = (event) => {
    if (props.schedule.s_release == 1) {
      let errorCount = 0;
      let errorMessages = {};
      if (state.qty == 0) {
        errorCount++;
        errorMessages['qty'] = "Enter the number of " + state.type + " you wish to extend";
      } else {
        if (isNaN(state.qty) || state.qty == "") {
          errorCount++;
          errorMessages['qty'] = "Must be a number";
        } else if (state.qty <= 0) {
          errorCount++;
          errorMessages['qty'] = "Must be more than 0";
        } else {
          errorMessages['qty'] = "";
        }
      }
      setErrors({
        ...errors,
        ...errorMessages
      })
      console.log(errorMessages);
      if (errorCount == 0) {
        formSubmitButton.click();
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSaving(true);

    let extStartDateObj = moment(props.schedule.s_end_date, 'YYYY-MM-DD');
    extStartDateObj.add(1, 'days');
    let extEndDateObj = moment(props.schedule.s_end_date, 'YYYY-MM-DD');
    extEndDateObj.add(state.qty, state.selectedType);
    // if (props.schedule.s_payment_mode == "daily") {
    //   extEndDateObj.add(state.qty, 'days');
    // } else if (props.schedule.s_payment_mode == "weekly") {
    //   extEndDateObj.add(state.qty, 'weeks');
    // } else if (props.schedule.s_payment_mode == "monthly" || props.schedule.s_payment_mode == "1-year" || props.schedule.s_payment_mode == "2-years") {
    //   extEndDateObj.add(state.qty, 'months');
    // }
    let extStartDate = extStartDateObj.format('YYYY-MM-DD');
    let extEndDate = extEndDateObj.format('YYYY-MM-DD');

    let formData = new FormData();
    formData.append('api_key', api.key);
    formData.append('access_token', localStorage.getItem('ctoken'));
    formData.append('jham_token', localStorage.getItem('token'));
    formData.append('schedule_id', props.schedule.s_id);
    formData.append('product_id', props.schedule.product_id);
    formData.append('date_from', extStartDate);
    formData.append('date_to', extEndDate);
    formData.append('type', state.selectedType);
    formData.append('count', state.qty);

    axios({
      method: 'post',
      url: api.url + '/transaction/extend_schedule',
      data: formData,
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => {
        if (response.data.error > 0) {
          store.addNotification({
            title: "Invalid information",
            message: response.data.error_msg,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 20000,
              onScreen: false
            }
          });
          setSaving(false);
        } else {
          store.addNotification({
            title: "Success",
            message: response.data.error_msg,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false
            }
          });
          setTimeout(() => {
            window.location.href = "/extension/payment";
          }, 3000);
          // if (props.schedule.s_payment_mode == "daily" || props.schedule.s_payment_mode == "weekly") {
          // } else {
          //   resetFields();
          //   props.onExtend();
          // }
        }
      })
      .catch((error) => {
        // Handle error
        if (error.response && error.response.status == 403) {
          store.addNotification({
            title: "Oops Something went wrong",
            message: "The action you have requested is not allowed. Please reload the page",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false
            }
          });
        }
      });

  };

  const resetFields = () => {
    setState({
      type: "",
      qty: 0,
    });
    setErrors({
      type: "",
      qty: "",
    });
    setUnavailableDateRanges([]);
    setDisableCalendarButtons(true);
    setCalendarMinDate(null);
    setSelectedDateRange(null);
    setSaving(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={props.open}
      TransitionComponent={Transition}
      onClose={props.onClose}
      className={classes.root}
      fullScreen={fullScreen}
      disableBackdropClick
    >
      <DialogTitle>
        Extend Your Rent
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form onSubmit={handleSubmit} className={classes.extendScheduleForm}>
          <Button type="submit" ref={button => formSubmitButton = button} className={classes.hidden}>hidden</Button>
          <Grid container spacing={3}>
            {props.schedule &&
              <Grid item xs={12}>
                <Typography variant="h6" component="p">
                  Schedule No.: {props.schedule.s_id}
                </Typography>
              </Grid>
            }
            <Grid item xs={6}>
              {state.types.length > 0 &&
                <TextField
                  variant="outlined"
                  id="selectedType"
                  label="Extend By"
                  name="selectedType"
                  value={state.selectedType}
                  onChange={handleInputChange}
                  select
                  fullWidth
                >
                  {state.types.map((type, index) => {
                    return <MenuItem key={index} value={type}>{type.toUpperCase()}</MenuItem>
                  })}
                </TextField>
              }
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                variant="outlined"
                id="qty"
                name="qty"
                label={"No. of " + state.selectedType.toLowerCase() + " to extend"}
                fullWidth
                autoComplete="off"
                value={state.qty}
                onChange={handleInputChange}
                error={errors.qty != ""}
                helperText={errors.qty}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center", paddingTop: 0 }}>
              <Button
                type="button"
                color="secondary"
                variant="contained"
                size="large"
                onClick={handleCheckAvailabilityButton}
                className={classes.checkAvailabilityButton}
              >
                Check availability
              </Button>
            </Grid>
            {props.schedule &&
              <React.Fragment>
                <Grid item xs={12} style={{ paddingBottom: 0 }}>
                  <Typography variant="subtitle1" component="p" align="center">
                    Current Lease Period: {moment(props.schedule.s_start_date, 'YYYY-MM-DD').format('MMM DD, YYYY')} - {moment(props.schedule.s_end_date, 'YYYY-MM-DD').format('MMM DD, YYYY')}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.calendarContainer}>
                  <Calendar
                    selectRange={true}
                    minDate={new Date(calendarMinDate)}
                    // defaultActiveStartDate={new Date(calendarMinDate)}
                    defaultActiveStartDate={calendarActiveStartDate}
                    // activeStartDate={calendarActiveStartDate}
                    calendarType="US"
                    value={selectedDateRange}
                    onViewChange={handleCalendarViewChange}
                    tileDisabled={isCalendarButtonDisabled}
                    tileClassName={calendarTileClass.bind(this, props.schedule)}
                  />
                </Grid>
                <Grid item xs={12} className={classes.legendsContainer}>
                  <span className={classes.calendarLegend} style={{ backgroundColor: '#F0F0F0', marginRight: theme.spacing(0.5) }}></span>
                  <Typography variant="body1" component="span" style={{ marginRight: theme.spacing(2) }}>
                    Unavailable Dates
                  </Typography>
                  <span className={classes.calendarLegend} style={{ backgroundColor: '#A2F4A5', marginRight: theme.spacing(0.5) }}></span>
                  <Typography variant="body1" component="span" style={{ marginRight: theme.spacing(2) }}>
                    Current Lease
                  </Typography>
                  <span className={classes.calendarLegend} style={{ backgroundColor: '#3CB371', marginRight: theme.spacing(0.5) }}></span>
                  <Typography variant="body1" component="span" style={{ marginRight: theme.spacing(2) }}>
                    Selected Dates
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: theme.spacing(2) }}>
                  {/* {props.schedule.s_payment_mode == "weekend" &&
                    <Typography variant="body1" component="p" color="error" style={{ fontWeight: 600 }}>
                      Extension is not available for Weekend lease
                    </Typography>
                  } */}
                  {props.schedule.s_release != 1 &&
                    <Typography variant="body1" component="p" color="error" style={{ fontWeight: 600 }}>
                      Cannot extend. Vehicle is not collected/delivered yet
                    </Typography>
                  }
                </Grid>
              </React.Fragment>
            }
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button disabled={saving} onClick={props.onClose}>
          Back
        </Button>
        {props.schedule &&
          <Button variant="contained" color="primary" onClick={handleSaveButton} disabled={saving || props.schedule.s_release != 1}>
            Extend
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}