import React, { Component,useState } from "react";
import {
  NavLink,
  HashRouter
} from "react-router-dom";

import axios, { post } from 'axios';
import { store } from 'react-notifications-component';
import ReactCrop from 'react-image-crop';
import LessorAgreementContent from './LessorAgreement';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import 'react-image-crop/dist/ReactCrop.css';

import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import SignatureCanvas from 'react-signature-canvas'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Avatar from '@material-ui/core/Avatar';
import { orange, grey } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
// import Home from "./Home";
// import Stuff from "./Stuff";
// import Contact from "./Contact";
// import ScriptTag from 'react-script-tag';

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  layout: {
    padding: theme.spacing(5, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
    }
  },
  title: {
    fontWeight: 300,
    marginBottom: theme.spacing(3),
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    color: "#FFF",
    backgroundColor: "#262626",
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  agreementPaper: {
    padding: theme.spacing(5, 3),
    '& .img-signature': {
      display: 'block',
      marginBottom: theme.spacing(2),
      width: '100%',
      maxWidth: 200,
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8),
    }
  },
  signatureDialog: {
    '& header': {
      position: 'relative',
      backgroundColor: '#ED8323',
      '& .title': {
        marginLeft: theme.spacing(2),
        flexGrow: 1,
      },
    },
    '& .signpad-container': {
      height: '100%',
      '& canvas': {
        width: '100%',
        height: '100%',
      },
    },
  },
}));

class ProfileCard extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor (props) {
        super(props)

        const { cookies } = props;
        this.state = {
            src: null,
            crop: {
            unit: '%',
            width: '120',
            aspect: 16 / 16,
            },
            openConfirmLessor: false,
            openCheckCreditCard: false,
            openWaitingForApproval: false,
            ccErrorType: 0,
            openCheckBillingDetail: false,
            signPad: {},
            openSignatureDialog: false,
            agreementSignature: null,
            lessorAgreement:"",
            redirectUrl:"https://lessor.earental.com/",
            cookieDomain:".earental.com",
            // redirectUrl:"https://lessor.earental.dbg/",
            // cookieDomain:".earental.dbg",
        }
        this.onSelectFile = this.onSelectFile.bind(this);
        this.onImageLoaded = this.onImageLoaded.bind(this);
        this.onCropComplete = this.onCropComplete.bind(this);
        this.onCropChange = this.onCropChange.bind(this);
        this.inputFileRef = React.createRef();
        this.onAvatarClick = this.onAvatarClick.bind(this)
        this.cancelUpdate = this.cancelUpdate.bind(this);
        this.saveAvatar = this.saveAvatar.bind(this);
        this.checkLessor = this.checkLessor.bind(this);
        this.closeConfirmLessor = this.closeConfirmLessor.bind(this);
        this.closeCreditCardError1 = this.closeCreditCardError1.bind(this);
        this.closeCreditCardError2 = this.closeCreditCardError2.bind(this);
        this.closeConfirmBillingDetails = this.closeConfirmBillingDetails.bind(this);

        this.openSignatureDialog = this.openSignatureDialog.bind(this);
        this.closeSignatureDialog = this.closeSignatureDialog.bind(this);
        this.trimSignPad = this.trimSignPad.bind(this);
    }


    openSignatureDialog = () => {
      this.setState({
        openSignatureDialog: true,
      });
    };

    closeSignatureDialog = () => {
      this.setState({
        openSignatureDialog: false,
      });
    };


    trimSignPad = (signPad,e) => {
      let agreementSignature = null;
      if (!signPad.isEmpty()) {
        agreementSignature = signPad.getTrimmedCanvas().toDataURL('image/png');
      }
      this.setState({
        openSignatureDialog: false,
        agreementSignature: agreementSignature,
      });
    }


    onSelectFile = e => {
      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          this.setState({ src: reader.result })
        );
        reader.readAsDataURL(e.target.files[0]);
      }
    };

    // If you setState the crop in here you should return false.
      onImageLoaded = image => {
        this.imageRef = image;
      };

      onCropComplete = crop => {
        this.makeClientCrop(crop);
      };

      onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
      };

      async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
          );
          this.setState({ croppedImageUrl });

        }
      }


      getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
        const base64Image = canvas.toDataURL('image/jpeg');

        return base64Image;
        // return new Promise((resolve, reject) => {
        //   canvas.toBlob(blob => {
        //     if (!blob) {
        //       //reject(new Error('Canvas is empty'));
        //       console.error('Canvas is empty');
        //       return;
        //     }
        //     blob.name = fileName;
        //     window.URL.revokeObjectURL(this.fileUrl);
        //     this.fileUrl = window.URL.createObjectURL(blob);
        //     resolve(this.fileUrl);
        //   }, 'image/jpeg');
        // });
      }

      saveAvatar() {

          var ctoken = localStorage.getItem('ctoken');
          var token = localStorage.getItem('token');
          var bodyFormData = new FormData();
          bodyFormData.append('access_token', ctoken);
          bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
          bodyFormData.append('jham_token', token);
          bodyFormData.append('customer_profile_image', this.state.croppedImageUrl);


          axios({
                  method: 'post',
                  url: 'https://api.earental.com/account/my_profile_update',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {
                  localStorage.setItem('current_avatar', this.state.croppedImageUrl);
                  this.setState({ src: null });
                  window.location.reload(true);

              })
              .catch((error) => {
                  //handle error
                  console.log(error);

          });
      }

      onAvatarClick() {
       /*Collecting node-element and performing click*/
       this.inputFileRef.current.click();
     }

     cancelUpdate() {
      /*Collecting node-element and performing click*/
       this.inputFileRef.current.value = "";
       this.setState({ src: null });
    }

    checkLessor() {
        const { cookies } = this.props;
        var lessor = this.props.profileState.lessorCode;
        var signed = this.props.profileState.agreementSigned;
        var acra = this.props.profileState.customer_acra;
        var lessorStatus = this.props.profileState.customer_lessor_status;
        if(!lessor || parseInt(signed) == 0 || acra == "" || lessorStatus != "Approve"){

            var bodyFormData = new FormData();
            var token = localStorage.getItem('token');
            var ctoken = localStorage.getItem('ctoken');

            bodyFormData.append('jham_token', token);
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');

            axios({
                  method: 'post',
                  url: 'https://api.earental.com/account/check_valid_cards',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {
                // if(response.data.valid.length <= 0){
                //
                //     if(response.data.about_to_expire.length > 0){
                //         this.setState({
                //             openCheckCreditCard: true,
                //             ccErrorType:2
                //         });
                //     }else{
                //         this.setState({
                //             openCheckCreditCard: true,
                //             ccErrorType:1
                //         });
                //     }
                //
                // }else{
                // }
                if(response.data.billing_details == 1 && acra != ""){
                    if(parseInt(signed) == 0){
                        var bodyFormData = new FormData();
                        bodyFormData.append('access_token', ctoken);
                        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
                        bodyFormData.append('jham_token', token);

                        axios({
                              method: 'post',
                              url: 'https://api.earental.com/lessor/get_lessor_agreement_letter',
                              data: bodyFormData,
                              withCredentials: true,
                              headers: {'Content-Type': 'multipart/form-data' }
                          })
                          .then((response) => {
                              this.setState({
                                openConfirmLessor:true,
                                lessorAgreement:response.data.agreement,
                              })
                          })
                          .catch((error) => {
                              //handle error
                              store.addNotification({
                                title: "Session expired",
                                message: "Kindly reload/refresh the page and sign-in again.",
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                  duration: 5000,
                                  onScreen: false
                                }
                              });

                        });
                    }else{
                        if(lessorStatus != "Approve"){
                            this.setState({
                              openWaitingForApproval:true
                            })
                        }
                    }



                }else{
                    this.setState({
                      openCheckBillingDetail:true
                    })
                }



              })
              .catch((error) => {
                  //handle error
                  console.log(error);

            });


        }else{

            var bodyFormData = new FormData();
            var token = localStorage.getItem('token');
            var ctoken = localStorage.getItem('ctoken');

            bodyFormData.append('jham_token', token);
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');

            axios({
                  method: 'post',
                  url: 'https://api.earental.com/account/check_valid_cards',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {
                // if(response.data.valid.length <= 0){
                //
                //     if(response.data.about_to_expire.length > 0){
                //         this.setState({
                //             openCheckCreditCard: true,
                //             ccErrorType:2
                //         });
                //     }else{
                //         this.setState({
                //             openCheckCreditCard: true,
                //             ccErrorType:1
                //         });
                //     }
                //
                // }else{
                // }

                if(response.data.billing_details == 1){
                    var ctoken = localStorage.getItem('ctoken');
                    var token = localStorage.getItem('token');
                    cookies.set('token', token, { path:'/',domain: this.state.cookieDomain });
                    cookies.set('ctoken', ctoken, { path:'/',domain: this.state.cookieDomain });
                    cookies.set('cavatar', this.props.profileState.currentAvatar, { path:'/',domain: this.state.cookieDomain });
                    setTimeout(
                        () =>{
                            window.location.href = this.state.redirectUrl;
                        },
                        150
                    );
                    // cookies.set('ctoken', ctoken, { path:'/',domain: this.state.cookieDomain });
                    // cookies.set('cavatar', this.props.profileState.currentAvatar, { path:'/',domain: this.state.cookieDomain });
                    // setTimeout(() =>{
                    //   window.location.href = "https://lessor.earental.com/";
                    // }, 150);
                }else{
                    this.setState({
                      openCheckBillingDetail:true
                    })
                }


              })
              .catch((error) => {
                  //handle error
                  console.log(error);

            });
            //
            // var ctoken = localStorage.getItem('ctoken');
            // cookies.set('ctoken', ctoken, { path:'/',domain: this.state.cookieDomain });
            // cookies.set('cavatar', this.props.profileState.currentAvatar, { path:'/',domain: this.state.cookieDomain });
            // window.open('https://lessor.earental.com/');
        }

    }


    closeCreditCardError1(confirmation,e){

        if(confirmation){
            window.location.href = "/user-cards";
        }else{
            this.setState({
              openCheckCreditCard:false
            })
        }
    }

    closeConfirmBillingDetails(confirmation,e){

        if(confirmation){
            window.location.href = "/user-settings";
        }else{
            this.setState({
              openCheckBillingDetail:false
            })
        }
    }

    closeCreditCardError2(confirmation,e){
        const { cookies } = this.props;

        if(confirmation){
            window.location.href = "/user-cards";
        }else{
            var lessor = this.props.profileState.lessorCode;
            if(!lessor){
                this.setState({
                  openConfirmLessor:true,
                  openCheckCreditCard:false
                })
            }else{
                this.setState({
                  openConfirmLessor:false,
                  openCheckCreditCard:false
                })
                var ctoken = localStorage.getItem('ctoken');
                cookies.set('ctoken', ctoken, { path:'/',domain: this.state.cookieDomain });
                cookies.set('cavatar', this.props.profileState.currentAvatar, { path:'/',domain: this.state.cookieDomain });
                // window.open('https://lessor.earental.com/');
                // cookies.set('ctoken', ctoken, { path:'/',domain: this.state.cookieDomain });
                // cookies.set('cavatar', this.props.profileState.currentAvatar, { path:'/',domain: this.state.cookieDomain });
                setTimeout(
                    () =>{
                        window.location.href = this.state.redirectUrl;
                    },
                    150
                );

            }


        }
    }


    closeConfirmLessor(confirmation,e){

        if(confirmation){
            const { cookies } = this.props;

            var bodyFormData = new FormData();
            var token = localStorage.getItem('token');
            var ctoken = localStorage.getItem('ctoken');

            bodyFormData.append('jham_token', token);
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('signature', this.state.agreementSignature);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


            axios({
                  method: 'post',
                  url: 'https://api.earental.com/lessor/activate_lessor',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {

                if(response.data.error > 0){
                    store.addNotification({
                      title: "Invalid token",
                      message: "Kindly reload/refresh the page and sign-in again.",
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: false
                      }
                    });
                }else{
                    this.setState({
                        openConfirmLessor:false,
                        openCheckCreditCard:false
                    })

                    if(this.props.profileState.customer_lessor_status == "Approve"){
                        var ctoken = localStorage.getItem('ctoken');
                        cookies.set('ctoken', ctoken, { path:'/',domain: this.state.cookieDomain });
                        cookies.set('cavatar', this.props.profileState.currentAvatar, { path:'/',domain: this.state.cookieDomain });

                        setTimeout(
                            () =>{
                                window.location.href = "https://lessor.earental.com/";
                            },
                            150
                        );
                    }else{
                        this.setState({
                            openWaitingForApproval:true,
                        })
                    }

                }


              })
              .catch((error) => {
                  //handle error
                  console.log(error);

            });

        }else{
            this.setState({
              openConfirmLessor:false,
              openCheckCreditCard:false
            })
        }

    }


      render() {

          function ConfirmLessor(props){
                const classes = useStyles();
                const [isOpen, setIsOpen] = useState(false);
                const Transition = React.forwardRef(function Transition(props, ref) {
                    return <Slide direction="up" ref={ref} {...props} />;
                });
                let doneSign = true;
                if(props.agreementSignature){
                    doneSign = false;
                    setTimeout(
                        () =>{
                            var elmnt = document.getElementById("lAgreementCont");
                            elmnt.scrollTop = elmnt.scrollHeight;
                        },
                        150
                    );

                }

              return (
                  <Dialog
                    open={props.openState}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={props.closeConfirmLessor.bind(this,false)}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    classes={{root: classes.layout}}
                    fullWidth={true}
                    maxWidth = {'lg'}
                  >
                    <DialogTitle id="alert-dialog-slide-title">{"Do you want to be a Lessor?"}</DialogTitle>
                    <DialogContent
                        id="lAgreementCont"
                    >
                      <DialogContentText className="dialogContentCustom" id="alert-dialog-slide-description">
                        <LessorAgreementContent profileState={props.profileState} agreement={props.agreement} signature={props.agreementSignature}/>
                      </DialogContentText>

                      <Box align="right">
                        <Button
                          type="button"
                          variant="contained"
                          color="default"
                          onClick={props.openSignatureDialog}
                        >
                          Sign Agreement
                        </Button>
                    </Box>
                    </DialogContent>
                    <DialogActions>
                        <span>Sign the agreeement to proceed</span>
                        <Button variant="contained" size="large" onClick={props.closeConfirmLessor.bind(this,false)} color="secondary">
                          Maybe Next time
                        </Button>
                        <Button variant="contained" size="large" disabled={doneSign} onClick={props.closeConfirmLessor.bind(this,true)} color="primary" title="Sign the agreeement to proceed">
                          Yes,I want to be a lessor
                        </Button>


                    </DialogActions>
                  </Dialog>
                );
          }

          function CheckBillingDetail(props){
              const classes = useStyles();
              const [isOpen, setIsOpen] = useState(false);

              const Transition = React.forwardRef(function Transition(props, ref) {
                return <Slide direction="up" ref={ref} {...props} />;
              });

              return (
                  <Dialog
                    open={props.openState}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={props.closeConfirmBillingDetails.bind(this,false)}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    fullWidth={true}
                    maxWidth = {'sm'}
                  >
                      {props.acra == "" && (
                          <DialogTitle id="alert-dialog-slide-title">{"Lessor Billing Details/Acra Incomplete?"}</DialogTitle>
                      )}
                      {props.acra != "" && (
                          <DialogTitle id="alert-dialog-slide-title">{"Lessor Billing Details Incomplete?"}</DialogTitle>
                      )}

                    <DialogContent>
                          {props.acra == "" && (
                              <p>
                                  Kindly check your billing details and update <b>ACRA File</b> information under <b>Profile Settings</b>.
                                  Make sure all information are provided and correct to avoid invalid transactions
                              </p>
                          )}
                          {props.acra != "" && (
                              <p>
                                  Kindly check your billing details information under <b>Profile Settings</b>.
                                  Make sure all information are provided and correct to avoid invalid transactions
                              </p>
                          )}

                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" size="large" onClick={props.closeConfirmBillingDetails.bind(this,false)} color="secondary">
                          Maybe Next time
                        </Button>
                        <Button variant="contained" size="large" onClick={props.closeConfirmBillingDetails.bind(this,true)} color="primary">
                          Yes,I will update it now
                        </Button>

                    </DialogActions>
                  </Dialog>
                );
          }

          function CheckCreditCard(props){
            const classes = useStyles();
            const [isOpen, setIsOpen] = useState(false);
            const [ccErrorType, setCcErrorType] = useState(props.ccErrorType);

            const Transition = React.forwardRef(function Transition(props, ref) {
                return <Slide direction="up" ref={ref} {...props} />;
            });

            var errorTitle = "Missing Credit Card"
            var errorMessage = "A lessor needs to have a valid credit card to deposit/credit to process necessary actions during the leasing period";

            if(ccErrorType == 1){
                errorTitle = "Missing Credit Card";
                errorMessage = "A lessor needs to have a valid credit card to process necessary actions during the leasing period";
            }else if(ccErrorType == 2){
                errorTitle = "Your Credit Card is about to expire";
                errorMessage = "A lessor needs to have a valid credit card to process necessary actions during the leasing period";
            }

              return (
                  <Dialog
                    open={props.openState}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={props.closeConfirmLessor.bind(this,false)}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    fullWidth={true}
                    maxWidth = {'sm'}
                  >
                    <DialogTitle id="alert-dialog-slide-title">{errorTitle}</DialogTitle>
                    <DialogContent>
                      <DialogContentText className="dialogContentCustom" id="alert-dialog-slide-description">
                        {/* <p> */}
                          {errorMessage}
                        {/* </p> */}
                      </DialogContentText>
                    </DialogContent>
                    {ccErrorType == 1 && (
                        <DialogActions>
                            <Button variant="contained" size="large" onClick={props.closeCreditCardError1.bind(this,false)} color="secondary">
                              Maybe Next time
                            </Button>
                            <Button variant="contained" size="large" onClick={props.closeCreditCardError1.bind(this,true)} color="primary">
                              Yes,I will set it up now
                            </Button>

                        </DialogActions>
                    )}

                    {ccErrorType == 2 && (
                        <DialogActions>
                            <Button variant="contained" size="large" onClick={props.closeCreditCardError2.bind(this,false)} color="secondary">
                              Do it later
                            </Button>
                            <Button variant="contained" size="large" onClick={props.closeCreditCardError2.bind(this,true)} color="primary">
                              Yes,I will set it up now
                            </Button>

                        </DialogActions>
                    )}

                  </Dialog>
                );
          }


          function LessorAgreement(props){

              const classes = useStyles();
              const [isOpen, setIsOpen] = useState(props.openSignatureDialog);
              const [signpadRef, setSignpadRef] = useState({});

              const clearSignPad = () => {
                setSignpadRef({});
              }

              return(
                  <Dialog fullScreen className={classes.signatureDialog} open={isOpen} onClose={props.closeSignatureDialog}>
                    <AppBar>
                      <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={props.closeSignatureDialog} aria-label="close">
                          <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className="title">
                          Sign on the blank space below
                        </Typography>
                        <Button color="inherit" onClick={clearSignPad}>
                          Clear
                        </Button>
                        <Button color="inherit" onClick={props.trimSignPad.bind(this,signpadRef)}>
                          Save
                        </Button>
                      </Toolbar>
                    </AppBar>
                    <Box className="signpad-container">
                      <SignatureCanvas
                        ref={(ref) => { setSignpadRef(ref) }}
                        minWidth={1}
                        maxWidth={8}
                      />
                    </Box>
                  </Dialog>
              )
          }


          function UserAvatar(props){
              const classes = useStyles();
              return (
               <div className="avatar-cont">
                 <Badge
                   overlap="circle"
                   anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'right',
                   }}
                   onClick={props.onAvatarClick}
                   badgeContent={<AddAPhotoIcon />}
                 >
                   <Avatar src={'https://api.earental.com/uploads/profile/'+props.currentAvatar} className="profile-avatar" />
                 </Badge>
               </div>
             );
          }
          const { crop, croppedImageUrl, src } = this.state;

          return (
              <aside className="user-profile-sidebar">
                  <Dialog
                    open={this.state.openWaitingForApproval}
                    fullWidth={true}
                    maxWidth = {'sm'}
                  >
                    <DialogTitle id="alert-dialog-slide-title">{"Account Currently Under Review."}</DialogTitle>

                    <DialogContent>
                      <DialogContentText className="dialogContentCustom" id="alert-dialog-slide-description">
                          <p>
                              Hi <b>{this.props.profileState.customer_company_name}</b>,
                              <br /> <br />
                              We are currenlty reviewing your account if it's valid to be a lessor. We will notify you via you're email <b>({this.props.profileState.email})</b> regarding the result.
                              <br /> <br />
                              Thank you for your cooperation.
                          </p>

                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" size="large"  color="primary" onClick={() => { this.setState({openWaitingForApproval: false}) }}>
                          Got It!
                        </Button>
                    </DialogActions>
                  </Dialog>
                  <ConfirmLessor
                      openState={this.state.openConfirmLessor}
                      closeConfirmLessor={this.closeConfirmLessor}
                      openSignatureDialog={this.openSignatureDialog}
                      agreementSignature={this.state.agreementSignature}
                      profileState={this.props.profileState}
                      agreement={this.state.lessorAgreement}
                   />
                   <CheckBillingDetail
                       openState={this.state.openCheckBillingDetail}
                       acra={this.props.profileState.customer_acra}
                       closeConfirmBillingDetails={this.closeConfirmBillingDetails}
                    />
                   <CheckCreditCard
                       openState={this.state.openCheckCreditCard}
                       closeCreditCardError1={this.closeCreditCardError1}
                       closeCreditCardError2={this.closeCreditCardError2}
                       ccErrorType={this.state.ccErrorType}
                       closeConfirmLessor={this.closeConfirmLessor}
                    />
                    <LessorAgreement
                        closeSignatureDialog={this.closeSignatureDialog}
                        trimSignPad={this.trimSignPad}
                        signPad={this.state.signPad}
                        openSignatureDialog={this.state.openSignatureDialog}
                    />

                  <input
                      className="isHidden"
                      id="profile-avatar-input"
                      type="file" accept="image/*"
                      onChange={this.onSelectFile}
                      ref={this.inputFileRef}
                      />
                    {src && (
                      <ReactCrop
                        src={src}
                        crop={crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                      />
                    )}
                    <div className="text-center">
                        {src && (
                          <Button variant="contained" onClick={this.cancelUpdate}>Cancel</Button>
                        )}
                        {src && (
                          <>&nbsp;</>
                        )}


                        {src && (
                            <Button variant="contained" onClick={this.saveAvatar} color="primary">
                            Update
                            </Button>
                        )}
                    </div>




                  <div className="user-profile-avatar text-center">
                      {src && (
                          <br />
                      )}

                      {!src && (
                        <UserAvatar onAvatarClick={this.onAvatarClick} currentAvatar={this.props.profileState.currentAvatar} firstName={this.props.profileState.firstName} />
                      )}

                      {src && (
                          <img src={croppedImageUrl} />
                      )}




                      <h5>{this.props.profileState.firstName} {this.props.profileState.lastName}</h5>
                      <p>Member Since {this.props.profileState.dateCreated}</p>
                  </div>
                  <ul className="list user-profile-nav">
                      <li><NavLink exact to="/user-profile"><i className="fa fa-user"></i>Overview</NavLink></li>
                      <li><NavLink exact to="/user-settings"><i className="fa fa-cog"></i>Profile</NavLink></li>
                      <li><NavLink exact to="/user-rental-history"><i className="fa fa-clock-o"></i>Rental History</NavLink></li>
                      <Hidden xsUp>
                          <li><NavLink exact to="/user-cards"><i className="fa fa-credit-card"></i>Credit/Debit Cards</NavLink></li>
                      </Hidden>

                      <li><NavLink exact to="/user-loyalty-miles"><i className="fa fa-road"></i>Loyalty Miles</NavLink></li>
                      <li><NavLink exact to="/user-lessee-addresses"><i className="fa fa-map-marker"></i>Lessee Billing Addresses</NavLink></li>
                      <li><NavLink exact to="/user-cards"><i className="fa fa-credit-card"></i>Payment Methods</NavLink></li>
                      <li><NavLink exact to="/user-drivers"><i className="fa fa-truck"></i>Drivers</NavLink></li>
                      <li><a onClick={this.checkLessor}><i className="fa fa-book"></i>Lessor</a></li>
                  </ul>
              </aside>
          );
  }
}

export default withCookies(ProfileCard)
