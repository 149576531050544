import React, { Component } from "react";

import "./../assets/css/aboutus.css";
import {Helmet} from "react-helmet";


class Terms extends Component {

    constructor (props) {
      super(props)
      this.state = {
        startDate: new Date(),
        endDate: new Date(),
        MetaTitle:"EARental | Copyright Policy",
        MetaDescription:"EARental is Singapore No.1 Online Van, Lorry & Truck commercial vehicles for lease for businesses and individuals. Hassle-Free, book and reserve your vehicle Online Now!",
        MetaKeywords:"Copyright Policy,online rental, van rental, lorry rental, truck rental, commercial vehicles for rent, leasing, daily rental, weekly rental, weekend rental, monthly rental, yearly rental, short term rental, long term rental, cargo van rental singapore, cheap van rental, van rental price, Toyota hiace van rental singapore, van rental daily, auto van rental singapore, commercial van for rent, van rental near me, daily van rental singapore",
        Canonical:"https://www.earental.com/copyright-policy",
      };
    }



  componentDidMount() {
      window.scrollTo(0, 0)
    }

  render() {
    return (
        <div classNameName="about-us-cont">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="title" content={this.state.MetaTitle} />
                <meta content={this.state.MetaTitle} property="og:title" />
                <meta name="description" content={this.state.MetaDescription} />
                <meta content={this.state.MetaDescription} property="og:description" />
                <meta name="keywords" content={this.state.MetaKeywords} />
                <meta name="robots" content="index, follow" />
                <title>{this.state.MetaTitle}</title>
                <link rel="canonical" href={this.state.Canonical} />
            </Helmet>
            <div className="container">
                <h1 className="page-title">Copyright Policy</h1>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <article className="post">
                            <header className="post-header"></header>
                            <div className="post-inner">
                                <h4>Notification of Copyright Infringement</h4>
                                <p>
                                    EARental ("<b>EARental</b>") respects the intellectual property rights of others and expects its users to do the same.
                                </p>

                                <p>
                                    It is EARental’s policy, in appropriate circumstances and at its discretion, to disable and/or terminate the account or access of users who repeatedly infringe or are repeatedly charged with infringing the copyright or other intellectual property rights of others. EARental will respond to claims of copyright infringement committed using the EARental website (the "<b>Site</b>") that are reported to EARental following this Policy.
                                </p>

                                <p>
                                    If you are a copyright owner, or are authorized to act on behalf of one, please report alleged copyright infringement taking place on or through the Site and Application by completing the following Notice of Alleged Infringement and delivering it to EARental as described below. Upon receipt of the Notice as described below, EARental will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged material from the Site and Application.
                                </p>

                                <div className="gap gap-small"></div>
                                <h4>Notice of Alleged Copyright Infringement ("Notice")</h4>
                                <ul style={{marginLeft:"35px"}}>
                                    <li>Identify the specific copyright protected work that you claim has been infringed, or - if multiple works are covered by this Notice - you may provide a representative list of the works that you claim have been infringed.</li>
                                    <li>Identify the material that you claim is infringing (or to be the subject of infringing activity) and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material, including at a minimum, if applicable, the URL of the link shown on the Site and Application where such material may be found.</li>
                                    <li>Provide your mailing address, telephone number, and, if available, email address.</li>
                                    <li>Include both of the following statements in the body of the Notice
                                        <ul style={{marginLeft:"35px"}}>
                                            <li>"I hereby state that I have a good faith belief that the disputed use of the copyright material is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use).”</li>
                                            <li>"I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed."</li>
                                        </ul>
                                    </li>
                                    <li>5. Provide your full legal name and your electronic or physical signature.</li>

                                </ul>
                            </div>
                        </article>
                    </div>
                </div>
            </div>

        </div>
    );
  }
}

export default Terms;
