import React from 'react';
import {
  makeStyles,
  styled,
  withTheme,
  useTheme,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from 'axios';
import { store } from 'react-notifications-component';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CloseIcon from '@material-ui/icons/Close';
import { orange, grey } from '@material-ui/core/colors';

import ImageUpload from './ImageUpload';

const DashedDivider = styled(withTheme(Divider))(props => ({
  borderTop: '2px dashed',
  borderColor: grey[300],
  height: 0,
  backgroundColor: 'transparent',
  marginTop: props.theme.spacing(2),
}));

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: 'none',
  },
  dialogContent: {
    minHeight: 150,
    paddingBottom: theme.spacing(4),
  },
  dialogActions: {
    justifyContent: 'space-between',
    '& button': {
      fontSize: '1rem',
    },
  },
  newAddressForm: {
    color: grey[800],
    '& .MuiFormControl-root': {
      '& .MuiFormLabel-root': {
        fontSize: '1.2rem',
        '&.Mui-focused': {
          color: grey[800],
          backgroundColor: 'white',
          paddingRight: 5,
        },
      },
      '& .MuiFormControlLabel-label': {
        fontSize: '1.2rem',
      },
      '& .MuiRadio-colorSecondary.Mui-checked': {
        // color: orange[600],
      },
      '& .MuiSelect-select': {
        fontSize: '1.2rem',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        '&:hover fieldset': {
          borderColor: '#B4B4B4',
        },
        '&.Mui-focused fieldset': {
          borderColor: orange[600],
        },
        '& input': {
          fontSize: '1.2rem',
        },
      },
      '& .MuiRadio-root': {
        '& .Mui-focused': {
          color: orange[600],
        },
      },
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      cursor: 'not-allowed',
      color: '#000000',
    },
  },
  radioGroup: {
    paddingBottom: '0 !important',
    '& .MuiFormLabel-root': {
      marginBottom: 0,
    },
    '& .MuiFormControlLabel-root': {
      marginBottom: 0,
    },
  },
  searchAddressInput: {
    '& .MuiInputLabel-root': {
      fontSize: '1.75rem !important',
    },
    '& .MuiInput-root': {
      '& input': {
        fontSize: '1.5rem',
        textAlign: 'center',
        textTransform: 'uppercase',
        paddingTop: theme.spacing(2),
      },
      '& .MuiCircularProgress-root': {
        color: orange[600],
      },
      '&:after': {
        borderColor: orange[600],
      },
      '&:hover::before': {
        borderColor: grey[500],
      },
    },
  },
  searchAddressResult: {
    '& .MuiListItemText-primary': {
      fontSize: '1.25rem',
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: orange[600],
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewAddress(props) {
  const theme = useTheme();
  const classes = useStyles();
  const api = {
    url: props.mainState.apiURL,
    key: props.mainState.apiKey,
    accessToken: localStorage.getItem('ctoken'),
    jhamToken: localStorage.getItem('token'),
    // jhamToken: "b80a47892a5f250cc6bee347d78cc543",
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [state, setState] = React.useState({
    purpose: "personal",
    firstName: "",
    lastName: "",
    nric: "",
    nricFrontImg: "",
    nricBackImg: "",
    type: "apartment",
    block: "",
    street: "",
    floor: "",
    unit: "",
    building: "",
    country: "Singapore",
    postal: "",
    company: "",
    roc: "",
    acra: "",
    telephone: "",
    mobile: "",
  });
  const [lockPostalField, setLockPostalField] = React.useState(false);
  const [showAddressForm, setShowAddressForm] = React.useState(false);
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [searchAddressResult, setSearchAddressResult] = React.useState([]);
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const [searchTimeout, setSearchTimeout] = React.useState(null);
  const [saving, setSaving] = React.useState(false);
  let formSubmitButton = React.useRef(null);
  let fileInputAcra = React.useRef(null);

  const handleSearchAddressChange = (event) => {
    var currentSearch = event.target.value;

    if (currentSearch == "") {
      setSearchAddressResult([]);
      clearTimeout(searchTimeout);
    } else {
      setLoadingSearch(true);
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      setSearchTimeout(setTimeout(() => {
        var searchAPI = "https://developers.onemap.sg/commonapi/search?searchVal=" + currentSearch + "&returnGeom=Y&getAddrDetails=Y&pageNum=1";
        axios.get(searchAPI)
        .then(response => {
          var searchResult = [];
          response.data.results.map((address, i) => {
            if (address.POSTAL != "NIL") {
              searchResult.push({
                text: address.ADDRESS,
                block: address.BLK_NO != "NIL" ? address.BLK_NO : "",
                building: address.BUILDING != "NIL" ? address.BUILDING : "",
                street: address.ROAD_NAME != "NIL" ? address.ROAD_NAME : "",
                postal: address.POSTAL,
              });
            }
          });
          setSearchAddressResult(searchResult);
          setLoadingSearch(false);
        });
      }, 750));
    }
  };

  const handleSearchResultClick = (address) => {
    setSelectedAddress(address);
    setState({
      ...state,
      block: address.block,
      street: address.street,
      building: address.building,
      postal: address.postal,
    });
    setShowAddressForm(true);
    setLockPostalField(true);
  };

  const handlePurposeChange = (event) => {
    event.persist();
    setState({
      ...state,
      purpose: event.target.value,
    });
  };

  const handleInputChange = (event) => {
    let target = event.target;
    setState({
      ...state,
      [target.name]: target.value,
    });
  };

  const handleSaveButton = (event) => {
    formSubmitButton.click();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSaving(true);
    let bodyFormData = new FormData();
        bodyFormData.append('api_key', api.key);
        bodyFormData.append('access_token', api.accessToken);
        bodyFormData.append('jham_token', api.jhamToken);
        bodyFormData.append('purpose', state.purpose);
        bodyFormData.append('fname', state.firstName);
        bodyFormData.append('lname', state.lastName);
        bodyFormData.append('nric', state.nric);
        bodyFormData.append('nric_front_img', state.purpose == "personal" ? state.nricFrontImg : "");
        bodyFormData.append('nric_back_img', state.purpose == "personal" ? state.nricBackImg : "");
        bodyFormData.append('type', state.type);
        bodyFormData.append('block', state.block);
        bodyFormData.append('street', state.street);
        bodyFormData.append('floor', state.floor);
        bodyFormData.append('unit', state.unit);
        bodyFormData.append('building', state.building);
        bodyFormData.append('country', state.country);
        bodyFormData.append('postal', state.postal);
        bodyFormData.append('company', state.purpose == "business" ? state.company : "");
        bodyFormData.append('roc', state.purpose == "business" ? state.roc : "");
        bodyFormData.append('acra', state.purpose == "business" ? state.acra : "");
        bodyFormData.append('telephone', state.telephone);
        bodyFormData.append('mobile', state.mobile);

    axios({
      method: 'post',
      url: api.url + '/account/add_address',
      data: bodyFormData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      if (response.data.error > 0) {
        function ErrorMsg(props) {
          return (
            props.msgs.map((item, i) =>
              <li key={i}>{item.value}</li>
            )
          );
        }
        store.addNotification({
          title: "Invalid information",
          message: <ErrorMsg msgs={response.data.error_msg} />,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 20000,
            onScreen: false
          }
        });
      } else {
        resetFields();
        props.onAddressAdded();
      }
      setSaving(false);
    })
    .catch((error) => {
      // Handle error
      if (error.response && error.response.status == 403) {
        store.addNotification({
          title: "Oops Something went wrong",
          message: "The action you have requested is not allowed. Please reload the page",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: false
          }
        });
      }
    });

  };

  const resetFields = () => {
    setState({
      purpose: "personal",
      firstName: "",
      lastName: "",
      nric: "",
      nricFrontImg: "",
      nricBackImg: "",
      type: "apartment",
      block: "",
      street: "",
      floor: "",
      unit: "",
      building: "",
      country: "Singapore",
      postal: "",
      company: "",
      roc: "",
      acra: "",
      telephone: "",
      mobile: "",
    });
    setLockPostalField(false);
    setShowAddressForm(false);
    setLoadingSearch(false);
    setSearchAddressResult([]);
    setSelectedAddress(null);
    setSearchTimeout(null);
    setSaving(false);
  };

  const onImageChange = (img, key) => {
    setState({
      ...state,
      [key]: img,
    });
  };

  const handleAcraUpload = (event) => {
    setState({
      ...state,
      acra: event.target.files[0],
    });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={props.open}
      TransitionComponent={Transition}
      onClose={props.onClose}
      className={classes.root}
      fullScreen={fullScreen}
    >
      <DialogTitle>
        New Billing Address
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form onSubmit={handleSubmit}>
          <Button type="submit" ref={button => formSubmitButton = button} style={{ display: 'none' }}>hidden</Button>
          <Grid container spacing={3} className={classes.newAddressForm}>
            <Grid item xs={12} sm={12} className={showAddressForm ? "hidden" : null}>
              <TextField
                fullWidth
                label="Enter your postal code or location name"
                onChange={handleSearchAddressChange}
                className={classes.searchAddressInput}
                InputProps={{
                  endAdornment: loadingSearch && (
                    <InputAdornment position="end">
                      <CircularProgress size="1.5rem" />
                    </InputAdornment>
                  )
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} style={{ paddingTop: 0 }} className={showAddressForm ? "hidden" : null}>
              <List className={classes.searchAddressResult}>
                {searchAddressResult.map((address, i) => {
                  return (
                    <React.Fragment key={i}>
                      <ListItem button onClick={() => { handleSearchResultClick(address) }}>
                        <ListItemText
                          primary={address.text}
                        />
                        <ListItemSecondaryAction>
                          <Radio
                            edge="end"
                            checked={selectedAddress == address}
                            onChange={() => { handleSearchResultClick(address) }}
                            value={i}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider component="li" />
                    </React.Fragment>
                  );
                })}
              </List>
            </Grid>
            <Grid item xs={12} sm={12} className={[classes.radioGroup, !showAddressForm ? "hidden" : null].join(' ')}>
              <FormControl component="fieldset">
                <FormLabel>Purpose</FormLabel>
                <RadioGroup name="purpose" value={state.purpose} onChange={handlePurposeChange} row>
                  <FormControlLabel value="personal" control={<Radio />} label="Personal" />
                  <FormControlLabel value="business" control={<Radio />} label="Business" />
                </RadioGroup>
              </FormControl>
            </Grid>
            {state.purpose == "business" && (
              <React.Fragment>
                <Grid item xs={12} sm={6} className={!showAddressForm ? "hidden" : null}>
                  <TextField
                    required
                    variant="outlined"
                    id="company"
                    name="company"
                    label="Company Name"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={state.company}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={!showAddressForm ? "hidden" : null}>
                  <TextField
                    required
                    variant="outlined"
                    id="roc"
                    name="roc"
                    label="ROC No."
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={state.roc}
                    onChange={handleInputChange}
                    disabled={state.company == ""}
                  />
                </Grid>
                <Grid item xs={12} className={!showAddressForm ? "hidden" : null} style={{ paddingTop: 0 }}>
                  <Box textAlign="right" marginBottom={1} display="flex" justifyContent="flex-end" alignItems="center">
                    <input
                      ref={fileInputAcra}
                      type="file"
                      onChange={handleAcraUpload}
                      style={{ display: "none" }}
                    />
                    <Typography variant="body1">
                      {state.acra ? state.acra.name : ""}
                      {state.acra && (
                        <Button type="button" onClick={() => setState({ ...state, acra: "" })} color="default" style={{ minWidth: 0, borderRadius: '50%' }}><CloseIcon /></Button>
                      )}
                    </Typography>
                    <Button type="button" variant="outlined" color="secondary" onClick={() => fileInputAcra.current.click()}>Upload ACRA</Button>
                  </Box>
                </Grid>
              </React.Fragment>
            )}
            <Grid item xs={12} sm={4} className={!showAddressForm ? "hidden" : null}>
              <TextField
                required
                variant="outlined"
                id="firstName"
                name="firstName"
                label="First name"
                fullWidth
                autoComplete="off"
                size="small"
                value={state.firstName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={!showAddressForm ? "hidden" : null}>
              <TextField
                required
                variant="outlined"
                id="lastName"
                name="lastName"
                label="Last name"
                fullWidth
                autoComplete="off"
                size="small"
                value={state.lastName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={!showAddressForm ? "hidden" : null}>
              <TextField
                required
                variant="outlined"
                id="nric"
                name="nric"
                label="NRIC"
                fullWidth
                autoComplete="off"
                size="small"
                value={state.nric}
                onChange={handleInputChange}
              />
            </Grid>
            {state.purpose == "personal" && (
              <React.Fragment>
                <Grid item xs={12} sm={6} className={!showAddressForm ? "hidden" : null}>
                  <ImageUpload label="NRIC (Front)" onImageChange={onImageChange} type="add" stateKey="nricFrontImg" />
                </Grid>
                <Grid item xs={12} sm={6} className={!showAddressForm ? "hidden" : null}>
                  <ImageUpload label="NRIC (Back)" onImageChange={onImageChange} type="add" stateKey="nricBackImg" />
                </Grid>
              </React.Fragment>
            )}
            <Grid item xs={12} sm={4}  className={!showAddressForm ? "hidden" : null}>
              <TextField
                required
                select
                SelectProps={{native: true}}
                variant="outlined"
                id="type"
                name="type"
                label="House/Apt/Business"
                fullWidth
                autoComplete="off"
                size="small"
                value={state.type}
                onChange={handleInputChange}
              >
                <option value={'apartment'}>Apartment</option>
                <option value={'house'}>House</option>
                <option value={'business'}>Business</option>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}  className={!showAddressForm ? "hidden" : null}>
              <TextField
                required
                variant="outlined"
                id="block"
                name="block"
                label="Block/House No."
                fullWidth
                autoComplete="off"
                size="small"
                value={state.block}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}  className={!showAddressForm ? "hidden" : null}>
              <TextField
                required
                variant="outlined"
                id="street"
                name="street"
                label="Street"
                fullWidth
                autoComplete="off"
                size="small"
                value={state.street}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={5}  className={!showAddressForm ? "hidden" : null}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="floor"
                    name="floor"
                    label="Floor"
                    autoComplete="off"
                    size="small"
                    value={state.floor}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={2} align="center">
                  <Typography component="span">&mdash;</Typography>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="unit"
                    name="unit"
                    label="Unit"
                    autoComplete="off"
                    size="small"
                    value={state.unit}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={7}  className={!showAddressForm ? "hidden" : null}>
              <TextField
                fullWidth
                variant="outlined"
                id="building"
                name="building"
                label="Building Name"
                autoComplete="off"
                size="small"
                value={state.building}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}  className={!showAddressForm ? "hidden" : null}>
              <TextField
                required
                variant="outlined"
                id="country"
                name="country"
                label="Country"
                fullWidth
                autoComplete="off"
                value="Singapore"
                size="small"
                value={state.country}
                onChange={handleInputChange}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}  className={!showAddressForm ? "hidden" : null}>
              <TextField
                required
                variant="outlined"
                id="postal"
                name="postal"
                label="Postal Code"
                fullWidth
                autoComplete="off"
                size="small"
                value={state.postal}
                onChange={handleInputChange}
                disabled={lockPostalField}
              />
            </Grid>
            <Grid item xs={12} sm={6}  className={!showAddressForm ? "hidden" : null}>
              <TextField
                required
                variant="outlined"
                id="telephone"
                name="telephone"
                label="Home/Work Number"
                fullWidth
                autoComplete="off"
                size="small"
                value={state.telephone}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}  className={!showAddressForm ? "hidden" : null}>
              <TextField
                required
                variant="outlined"
                id="mobile"
                name="mobile"
                label="Mobile Number"
                fullWidth
                autoComplete="off"
                size="small"
                InputProps={{
                  startAdornment: <InputAdornment position="start">+65</InputAdornment>,
                  maxLength: 12,
                }}
                value={state.mobile}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {!showAddressForm && (
          <React.Fragment>
            <Button onClick={props.onClose}>
              Cancel
            </Button>
            <Box>
              <Typography component="span" style={{ fontSize: '1.2rem' }}>
                Can't find your address?&nbsp;&nbsp;&nbsp;
              </Typography>
              <Button variant="contained" color="primary" onClick={() => { setShowAddressForm(true) }}>
                Add Address Manually
              </Button>
            </Box>
          </React.Fragment>
        )}
        {showAddressForm && (
          <React.Fragment>
            <Button disabled={saving} onClick={() => {
              setShowAddressForm(false);
              setLockPostalField(false);
            }}>
              Back
            </Button>
            <Button variant="contained" color="primary" onClick={handleSaveButton} disabled={saving}>
              Save Address
            </Button>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
}