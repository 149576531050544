import React, { useState } from "react";
import {
  NavLink,
  HashRouter
} from "react-router-dom";
import axios, { post } from 'axios';
import { store } from 'react-notifications-component';

import noImage from './../assets/img/no-image.jpg';

import DatePicker from "react-datepicker";
import Image, { Shimmer } from 'react-shimmer'
import ContentLoader, { Facebook } from "react-content-loader";
import Pager from 'react-pager';
import ScriptTag from 'react-script-tag';
import moment from "moment";

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Input from '@material-ui/core/Input';

import NewDriver from '../checkout/NewDriver';
import EditDriver from '../checkout/EditDriver';

//SIDE CARD UNG MY AVATAR
import ProfileCard from "./../profile/Profilecard";

import "../assets/css/profile.css";




// var parallaxBgStyle = {
//   backgroundImage: "url(" + parallaxBg1 + ")"
// };
const MyLoader = () => (
    <ContentLoader
        height={170}
        width={262}
        speed={1}
    >
        <rect x="0" y="0" rx="0" ry="0" width="262" height="170" />
    </ContentLoader>
);


const useStyles = makeStyles((theme) => ({
    root: {
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        outline: 'none'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paragraph: {
        fontSize:15
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
        fontSize:"20px",
        textTransform:"uppercase"
    },
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(3),
    },
    button: {
      marginBottom: '5px',
    },
}));




export default class Drivers extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);

        this.handlePageChanged = this.handlePageChanged.bind(this);

        this.state = {
             drivers: [],
             loadingclass: 'noHidden',
             driverDob: "",
             openSb: false,
             openAddModal: false,
             openSbDeleted: false,
             openSbEdit: false,
             openDeleteConfirm: false,
             openAddDialog: false,
             openEditDialog: false,
             currentDriver:false,
             currentDriverData:false,
             openEditModal: false,
             limit:6,
             totalPage:0,
             currentPage:0,
             visiblePage:5,
             offset:0,
             addDname:"",
             addDDob:"",
             addDMobile:"",
             addDExp:"",
             addDLicense:"",
             addDNric:"",
             addDAddress:"",
             noResult:false,
             hidePager:"",
             src: null,
             crop: {
               unit: '%',
               width: '120',
               aspect: 16 / 16,
           },
           lessorCode:false
        };


        this.handleChangeAddDob = this.handleChangeAddDob.bind(this);
        this.onAddFormSubmit = this.onAddFormSubmit.bind(this);
        this.onEditFormSubmit = this.onEditFormSubmit.bind(this);
        this.handleCloseSb = this.handleCloseSb.bind(this);
        this.handleCloseSbDelete = this.handleCloseSbDelete.bind(this);
        this.openAddModal = this.openAddModal.bind(this);
        this.closeAddModal = this.closeAddModal.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.openDeleteConfirmModal = this.openDeleteConfirmModal.bind(this);
        this.handleChangeAddInput = this.handleChangeAddInput.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.handleCloseSbEdit = this.handleCloseSbEdit.bind(this);

        this.onSelectFile = this.onSelectFile.bind(this);
        this.onImageLoaded = this.onImageLoaded.bind(this);
        this.onCropComplete = this.onCropComplete.bind(this);
        this.onCropChange = this.onCropChange.bind(this);


    }

    onSelectFile = e => {
      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          this.setState({ src: reader.result })
        );
        reader.readAsDataURL(e.target.files[0]);
      }
    };

    // If you setState the crop in here you should return false.
      onImageLoaded = image => {
        this.imageRef = image;
      };

      onCropComplete = crop => {
        this.makeClientCrop(crop);
      };

      onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
      };

      async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
          );
          this.setState({ croppedImageUrl });

        }
      }


      getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
        const base64Image = canvas.toDataURL('image/jpeg');

        return base64Image;

      }

    handleChangeAddDob(date) {
        this.setState({
        driverDob:date,
        })
    }

    handleCloseSb(){
        this.setState({
          openSb:false,
        })
    }

    handleCloseSbDelete(){
        this.setState({
          openSbDeleted:false,
        })
    }

    handleCloseSbEdit(){
        this.setState({
          openSbEdit:false,
        })
    }

    openAddModal(){
        this.setState({
          openAddDialog: true,
          // openAddModal:true,
        })
    }

    closeAddModal(){
        this.setState({
          openAddModal:false,
        })

    }

    openEditModal(data,e){
        this.setState({
          openEditDialog: true,
          // openEditModal:true,
          currentDriverData:data,
          currentDriver:data.cd_id
        })
    }

    closeEditModal(){

        this.setState({
          openEditDialog: false,
          // openEditModal:false,
          currentDriverData:false,
          currentDriver: false
        })

    }


    handleChangeAddInput(name,e){
        this.setState({
          [name]:e.target.value,
        })
    }


    openDeleteConfirmModal(driverId,e){
        console.log(driverId);
        this.setState({
          openDeleteConfirm:true,
          currentDriver: driverId
        })
    }

    closeDeleteModal(confirmation,e){
        console.log(confirmation);

        if(confirmation){


            var bodyFormData = new FormData();
            var token = localStorage.getItem('token');
            var ctoken = localStorage.getItem('ctoken');

            bodyFormData.append('cd_id', this.state.currentDriver);
            bodyFormData.append('jham_token', token);
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


            axios({
                  method: 'post',
                  url: 'https://api.earental.com/account/delete_driver',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {

                console.log(response.data)
                 if(response.data.error > 0){
                     function ErrorMsg(props) {
                       return (
                           props.msgs.map((item,i) =>
                               <li key={"err-"+item.i}>{item.value}</li>
                           )

                       );
                     }
                     store.addNotification({
                       title: "Invalid information",
                       message: <ErrorMsg msgs={response.data.error_message} />,
                       type: "danger",
                       insert: "top",
                       container: "top-right",
                       animationIn: ["animated", "fadeIn"],
                       animationOut: ["animated", "fadeOut"],
                       dismiss: {
                         duration: 20000,
                         onScreen: false
                       }
                     });
                 }else{
                     this.setState({
                       openDeleteConfirm:false,
                       openSbDeleted: true,
                       currentDriver: false,
                       loadingclass: 'noHidden',
                     })


                     this.getDrivers();


                 }

              })
              .catch((error) => {
                  //handle error
                  console.log(error);

            });

        }else{
            this.setState({
              openDeleteConfirm:false,
              currentDriver: false
            })
        }

    }





    onAddFormSubmit(event){
        event.preventDefault();

        var bodyFormData = new FormData();
        var token = localStorage.getItem('token');
        var ctoken = localStorage.getItem('ctoken');

        var dob = moment(event.target.dDob.value).format("YYYY-MM-DD");
        bodyFormData.append('cd_driver_name', event.target.dName.value);
        bodyFormData.append('cd_driver_licenseno', event.target.dLicense.value);
        bodyFormData.append('cd_mobile_no', event.target.dMobile.value);
        bodyFormData.append('cd_birthdate', dob);
        bodyFormData.append('cd_nric', event.target.dNric.value);
        bodyFormData.append('cd_address', event.target.dAddress.value);
        bodyFormData.append('cd_driver_yearexp', event.target.dExp.value);
        bodyFormData.append('cd_photo_driverlicfront', event.target.dLicenseFVal.value);
        bodyFormData.append('cd_photo_driverlicback', event.target.dLicenseBVal.value);
        bodyFormData.append('cd_photo_nricfront', event.target.dNricFVal.value);
        bodyFormData.append('cd_photo_nricback', event.target.dNricBVal.value);
        bodyFormData.append('jham_token', token);
        bodyFormData.append('access_token', ctoken);
        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


        axios({
              method: 'post',
              url: 'https://api.earental.com/account/add_driver',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {

            console.log(response.data)
             if(response.data.error > 0){
                 function ErrorMsg(props) {
                   return (
                       props.msgs.map((item,i) =>
                           <li key={"err-"+item.i}>{item.value}</li>
                       )

                   );
                 }
                 store.addNotification({
                   title: "Invalid information",
                   message: <ErrorMsg msgs={response.data.error_message} />,
                   type: "danger",
                   insert: "top",
                   container: "top-right",
                   animationIn: ["animated", "fadeIn"],
                   animationOut: ["animated", "fadeOut"],
                   dismiss: {
                     duration: 20000,
                     onScreen: false
                   }
                 });
             }else{

                 this.setState({ openSb: true });
                 this.setState({ openAddModal: false });

                 this.getDrivers();




             }

          })
          .catch((error) => {
              //handle error
              console.log(error);

        });


    }

    onEditFormSubmit(event){
        event.preventDefault();

        var bodyFormData = new FormData();
        var token = localStorage.getItem('token');
        var ctoken = localStorage.getItem('ctoken');

        var dob = moment(event.target.dDob.value).format("YYYY-MM-DD");
        console.log(dob);

        bodyFormData.append('cd_driver_name', event.target.dName.value);
        bodyFormData.append('cd_driver_licenseno', event.target.dLicense.value);
        bodyFormData.append('cd_mobile_no', event.target.dMobile.value);
        bodyFormData.append('cd_birthdate', dob);
        bodyFormData.append('cd_nric', event.target.dNric.value);
        bodyFormData.append('cd_address', event.target.dAddress.value);
        bodyFormData.append('cd_driver_yearexp', event.target.dExp.value);
        if(event.target.dLicenseFVal.value != "false")
            bodyFormData.append('cd_photo_driverlicfront', event.target.dLicenseFVal.value);
        if(event.target.dLicenseBVal.value != "false")
            bodyFormData.append('cd_photo_driverlicback', event.target.dLicenseBVal.value);
        if(event.target.dNricFVal.value != "false")
            bodyFormData.append('cd_photo_nricfront', event.target.dNricFVal.value);
        if(event.target.dNricBVal.value != "false")
            bodyFormData.append('cd_photo_nricback', event.target.dNricBVal.value);

        bodyFormData.append('cd_id', this.state.currentDriver);
        bodyFormData.append('jham_token', token);
        bodyFormData.append('access_token', ctoken);
        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


        axios({
              method: 'post',
              url: 'https://api.earental.com/account/update_driver',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {

            console.log(response.data)
             if(response.data.error > 0){
                 function ErrorMsg(props) {
                   return (
                       props.msgs.map((item,i) =>
                           <li key={"err-"+item.i}>{item.value}</li>
                       )

                   );
                 }
                 store.addNotification({
                   title: "Invalid information",
                   message: <ErrorMsg msgs={response.data.error_message} />,
                   type: "danger",
                   insert: "top",
                   container: "top-right",
                   animationIn: ["animated", "fadeIn"],
                   animationOut: ["animated", "fadeOut"],
                   dismiss: {
                     duration: 20000,
                     onScreen: false
                   }
                 });
             }else{

                 this.setState({ openSbEdit: true });
                //  this.setState({ openEditModal: false });
                 this.setState({ openEditDialog: false });

                 this.getDrivers();




             }

          })
          .catch((error) => {
              //handle error
              console.log(error);

        });


    }




    handlePageChanged(newPage) {
        this.setState({ currentPage : newPage });
        setTimeout(() => {
            this.getDrivers();

        }, 150);
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = event => {
        event.preventDefault();

        const request = {
            pickup: this.state.pickup,
            checkin: this.state.checkin,
            timein: this.state.timein,
            dropoff: this.state.dropoff,
            checkout: this.state.checkout,
            timeout: this.state.timeout,
        };

        axios.post(`https://jsonplaceholder.typicode.com/users`, { request })
        .then(res => {
          console.log(res);
          console.log(res.data);
        })
    }






  getDrivers() {
      var ctoken = localStorage.getItem('ctoken');
      var token = localStorage.getItem('token');
      if(ctoken){
          var bodyFormData = new FormData();

          var currentOffset = this.state.limit * this.state.currentPage;
          bodyFormData.append('access_token', ctoken);
          bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
          bodyFormData.append('jham_token', token);
          bodyFormData.append('limit', this.state.limit);
          bodyFormData.append('offset', currentOffset);
          bodyFormData.append('keyword', "");


          axios({
                  method: 'post',
                  url: 'https://api.earental.com/account/driver_list',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {
                  if(response.data.data && response.data.data !== null){
                    console.log(response.data.data);
                      this.setState({
                          drivers:response.data.data,
                          loading:false,
                          loadingclass:'isHidden',
                          totalPage:response.data.total_pages,
                          noResult:false,
                          hidePager: ""
                      });
                  }else{
                      this.setState({
                          drivers:[],
                          loading:false,
                          loadingclass:'isHidden',
                          totalPage:0,
                          noResult:true,
                          hidePager:"isHidden"
                      });
                  }



              })
              .catch((error) => {
                  //handle error
                  this.setState({
                      drivers:[],
                      loading:false,
                      loadingclass:'isHidden',
                      totalPage:0
                  });

          });
      }else{
          store.addNotification({
            title: "Session expired",
            message: "Kindly reload/refresh the page and sign-in again.",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false
            }
          });
      }



  }

  componentDidMount() {
      window.scrollTo(0, 0)

      this.getDrivers();

  }


    renderListing() {
        function Driver(props){
            const classes = useStyles();
            const [expanded, setExpanded] = React.useState(false);

            const handleExpandClick = () => {
                setExpanded(!expanded);
            };
            var driverImage = noImage;
            if(props.details.cd_photo_driverlicfront){
                driverImage = 'https://api.earental.com/uploads/profile/'+props.details.cd_photo_driverlicfront;
            }

            const handleImageError = (e) => {
                e.target.onerror = null;
                // e.target.style.display = 'none'
                e.target.src = noImage
            }

            return (
                <Card className={classes.root+' driver-card'}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={classes.avatar}>
                        {props.details.cd_driver_name.charAt(0)}
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="delete" title="Remove this driver" onClick={props.openDeleteConfirmModal.bind(this,props.details.cd_id)}>
                        <DeleteIcon title="Remove this driver" />
                      </IconButton>
                    }
                    title={props.details.cd_status}
                    subheader={'Exp:'+props.details.cd_driver_yearexp}
                  />

                  <CardMedia
                    className={classes.media}
                    onError={handleImageError}
                    image={driverImage}
                    title="Image not available"
                    onClick={props.openEditModal.bind(this,props.details)}
                  />
                  <CardContent>
                      <Typography variant="h6" gutterBottom>
                          {props.details.cd_driver_name}
                      </Typography>
                    <Typography variant="body1" color="textSecondary" component="p">
                      DOB: {moment(props.details.cd_birthdate).format("MMMM DD YYYY")}
                    </Typography>
                  </CardContent>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography paragraph className={classes.paragraph}>Contact No.: {props.details.cd_mobile_no}</Typography>
                        <Typography paragraph className={classes.paragraph}>NRIC.: {props.details.cd_mobile_no}</Typography>
                        <Typography paragraph className={classes.paragraph}>License No.: {props.details.cd_driver_licenseno}</Typography>
                        <Typography paragraph className={classes.paragraph}>
                        {props.details.cd_address}
                        </Typography>
                    </CardContent>
                  </Collapse>
                </Card>
              );

        }
        if (!this.state.noResult) {
            return (
                <div className="row row-wrap">
                    <div className={this.state.loadingclass}>
                        <div className="col-md-4">
                            <MyLoader  />
                        </div>
                        <div className="col-md-4">
                            <MyLoader  />
                        </div>
                        <div className="col-md-4">
                            <MyLoader  />
                        </div>
                    </div>

                    { this.state.drivers.map(driver =>
                        <div className="col-md-4" key={driver.cd_id}>
                            <Driver details={driver} openDeleteConfirmModal={this.openDeleteConfirmModal} openEditModal={this.openEditModal} />
                        </div>
                        )
                    }

                </div>
            );
        }else{
            return (
                <div className="vehicle-listing text-center">
                    <br />
                    <p className="not-found"><b>No Driver Found</b></p>
                    <p>Start creating now by clicking the "Add New Driver" Button at Top
                    </p>
                </div>
            );
        }

    }

  handleAddDialogClose = () => {
    this.setState({
      openAddDialog: false
    });
  }

  handleDriverAdded = () => {
    this.setState({
      openAddDialog: false,
    });
    this.getDrivers();
  }


  handleEditDialogClose = () => {
    this.setState({
      openEditDialog: false,
      currentDriverData: false,
      currentDriver: false,
    });
  }

  handleDriverUpdated = () => {
    this.setState({
      openEditDialog: false,
      currentDriverData: false,
      currentDriver: false,
    });
    this.getDrivers();
  }

  render() {
      function DriverAdd(props){
          const classes = useStyles();
          const [expanded, setExpanded] = React.useState(false);

          const handleExpandClick = () => {
          setExpanded(!expanded);
          };
          return (
              <Button
                   variant="contained"
                   color="primary"
                   size="large"
                   className={classes.button+' popup-text'}
                   data-effect="mfp-zoom-out"
                   startIcon={<AddIcon />}
                   onClick={props.click}
                 >
                   Add New Driver
             </Button>
            );

      }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }


    function ModalAdd(props){
        const classes = useStyles();
        const [isOpen, setIsOpen] = useState(false);
        const [selectedDate, setSelectedDate] = React.useState(new Date())


        const handleDateChange = (date) => {
           setSelectedDate(date);
           setIsOpen(false)
         };



         const [src1, setSrc1] = useState(false);
         const [src2, setSrc2] = useState(false);
         const [src3, setSrc3] = useState(false);
         const [src4, setSrc4] = useState(false);
         const [imageRef1, setImageRef1] = useState(false);
         const [imageRef2, setImageRef2] = useState(false);
         const [imageRef3, setImageRef3] = useState(false);
         const [imageRef4, setImageRef4] = useState(false);
         const [croppedImageUrlLF, setCroppedImageUrlLF] = useState(false);
         const [croppedImageUrlLB, setCroppedImageUrlLB] = useState(false);
         const [croppedImageUrlNF, setCroppedImageUrlNF] = useState(false);
         const [croppedImageUrlNB, setCroppedImageUrlNB] = useState(false);
         const [crop1, setCrop1] = React.useState(
             {
                 unit: '%',
                 width:50,
                 aspect: 16 / 8,
                 x: 25,
                 y: 25
             }
         )

         const [crop2, setCrop2] = React.useState(
             {
                 unit: '%',
                 width:50,
                 aspect: 16 / 8,
                 x: 25,
                 y: 25
             }
         )
         const [crop3, setCrop3] = React.useState(
             {
                 unit: '%',
                 width:50,
                 aspect: 16 / 8,
                 x: 25,
                 y: 25
             }
         )

         const [crop4, setCrop4] = React.useState(
             {
                 unit: '%',
                 width:50,
                 aspect: 16 / 8,
                 x: 25,
                 y: 25
             }
         )


         const onSelectFile = (type,e) => {
           if (e.target.files && e.target.files.length > 0) {
             var reader = new FileReader();
             reader.addEventListener('load', () =>{
                 if(type == 1){
                     setSrc1(reader.result)
                 }else if(type == 2){
                     setSrc2(reader.result)
                 }else if(type == 3){
                     setSrc3(reader.result)
                 }else if(type == 4){
                     setSrc4(reader.result)
                 }

             });
             reader.readAsDataURL(e.target.files[0]);
           }
         };

         const onImageLoaded = (type,image) => {

           if(type == 1){
               setImageRef1(image)
           }else if(type == 2){
               setImageRef2(image)
           }else if(type == 3){
               setImageRef3(image)
           }else if(type == 4){
               setImageRef4(image)
           }
         };

         const onCropComplete = (type,crop) => {
             console.log(type)
           makeClientCrop(crop,type);
         };

         const onCropChange = (type,crop, percentCrop) => {
           // You could also use percentCrop:
           // this.setState({ crop: percentCrop });
        //    setCrop(crop);
            if(type == 1){
                setCrop1(crop)
            }else if(type == 2){
                setCrop2(crop)
            }else if(type == 3){
                setCrop3(crop)
            }else if(type == 4){
                setCrop4(crop)
            }

         };

         const makeClientCrop = (crop,type) => {
             var currentImage = null;
             if(type == 1){
                 currentImage = imageRef1;
             }else if(type == 2){
                 currentImage = imageRef2;
             }else if(type == 3){
                 currentImage = imageRef3;
             }else if(type == 4){
                 currentImage = imageRef4;
             }
           if (currentImage && crop.width && crop.height) {
             const croppedImageUrl = getCroppedImg(
               currentImage,
               crop,
               'newFile.jpeg'
             );
             if(type == 1){
                 setCroppedImageUrlLF(croppedImageUrl)
             }else if(type == 2){
                 setCroppedImageUrlLB(croppedImageUrl)
             }else if(type == 3){
                 setCroppedImageUrlNF(croppedImageUrl)
             }else if(type == 4){
                 setCroppedImageUrlNB(croppedImageUrl)
             }
           }
         }

         const getCroppedImg = (image, crop, fileName) => {
           const canvas = document.createElement('canvas');
           const scaleX = image.naturalWidth / image.width;
           const scaleY = image.naturalHeight / image.height;
           canvas.width = crop.width;
           canvas.height = crop.height;
           const ctx = canvas.getContext('2d');

           ctx.drawImage(
             image,
             crop.x * scaleX,
             crop.y * scaleY,
             crop.width * scaleX,
             crop.height * scaleY,
             0,
             0,
             crop.width,
             crop.height
           );
           const base64Image = canvas.toDataURL('image/jpeg');

           return base64Image;

         }




        return (

            <Dialog
                disableEnforceFocus={false}
                open={props.openState}
                onClose={props.closeModal}
                aria-labelledby="form-dialog-title"
                className="driver-modal">
                <DialogTitle id="form-dialog-title">New Driver</DialogTitle>
                <DialogContent>
                    <div className="mfp-with-anim mfp-driver-dialog" id="new-card-dialog">
                        <form className="cc-form" onSubmit={props.onAddFormSubmit}>
                            <div className="clearfix">
                                <div className="form-group form-group-driver-name">
                                    <label>Name</label>
                                    <input
                                    className="form-control d-name"
                                    name="dName"
                                    placeholder="John Doe"
                                    type="text"
                                     required />
                                </div>
                                <div className="form-group form-group-driver-dob">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} className="dobCalendar">
                                        <KeyboardDatePicker
                                           autoOk={true}
                                           disableFuture
                                           variant="inline"
                                           id="date-picker-dialog"
                                           label="Date of birth"
                                           format="MMM d, yyyy"
                                           name="dDob"
                                           className="dobCalendar"
                                           value={selectedDate}
                                           onChange={handleDateChange}
                                           KeyboardButtonProps={{
                                             'aria-label': 'change date',
                                           }}
                                           KeyboardButtonProps={{
                                              onFocus: e => {
                                                setIsOpen(true);
                                              }
                                            }}
                                            PopoverProps={{
                                              disableRestoreFocus: true,
                                              onClose: () => {
                                                setIsOpen(false);
                                              }
                                            }}
                                            InputProps={{
                                              onFocus: () => {
                                                setIsOpen(true);
                                              }
                                            }}
                                            open={isOpen}

                                         />
                                     </MuiPickersUtilsProvider>

                                </div>
                            </div>
                            <div className="clearfix">
                                <input className="form-control" name="dLicenseFVal" placeholder="" value={croppedImageUrlLF} type="hidden" />
                                <input className="form-control" name="dLicenseBVal" placeholder="" value={croppedImageUrlLB} type="hidden" />
                                <input className="form-control" name="dNricFVal" placeholder="" value={croppedImageUrlNF} type="hidden" />
                                <input className="form-control" name="dNricBVal" placeholder="" value={croppedImageUrlNB} type="hidden" />
                                <div className="form-group form-group-driver-name">
                                    <label>Mobile</label>
                                    <input className="form-control d-mobile" name="dMobile" placeholder="" type="text" required />
                                </div>
                                <div className="form-group form-group-driver-dob">
                                    <label>Years of exp</label>
                                    <input className="form-control" name="dExp" placeholder="" type="number" required />
                                </div>
                            </div>
                            <div className="clearfix">
                                <div className="form-group form-group-driver-name">
                                    <label>Driver License </label>
                                    <input className="form-control d-license" name="dLicense" placeholder="" type="text" required />
                                </div>
                            </div>
                            <div className="clearfix">
                                <div className="form-group-driver-name">
                                    <label>License Front </label>
                                    <input className="form-control d-license-f" name="dLicenseF" placeholder="" type="file" onChange={onSelectFile.bind(this,1)} required />
                                    {src1 && (
                                      <ReactCrop
                                        src={src1}
                                        crop={crop1}
                                        ruleOfThirds

                                        onImageLoaded={onImageLoaded.bind(this,1)}
                                        onComplete={onCropComplete.bind(this,1)}
                                        onChange={onCropChange.bind(this,1)}
                                      />
                                    )}
                                    {src1 && (
                                      <p className="text-right">Move/Resize the box to the center of your id</p>
                                    )}
                                </div>
                            </div>
                            <br />
                            <div className="clearfix">
                                <div className="form-group-nric-number">
                                    <label>License Back</label>
                                    <input className="form-control d-license-b" name="dLicenseB" placeholder="" type="file" onChange={onSelectFile.bind(this,2)} required  />
                                    {src2 && (
                                      <ReactCrop
                                        src={src2}
                                        crop={crop2}
                                        ruleOfThirds

                                        onImageLoaded={onImageLoaded.bind(this,2)}
                                        onComplete={onCropComplete.bind(this,2)}
                                        onChange={onCropChange.bind(this,2)}
                                      />
                                    )}
                                    {src2 && (
                                      <p className="text-right">Move/Resize the box to the center of your id</p>
                                    )}
                                </div>
                            </div>
                            <br />
                            <div className="clearfix">
                                <div className="form-group form-group-nric-number">
                                    <label>NRIC</label>
                                    <input className="form-control d-nric" name="dNric" placeholder="" type="text" required />
                                </div>
                            </div>
                            <div className="clearfix">
                                <div className="form-group-driver-name">
                                    <label>NRIC Front </label>
                                    <input className="form-control d-nric-f"  placeholder="" type="file" onChange={onSelectFile.bind(this,3)} required />
                                    {src3 && (
                                      <ReactCrop
                                        src={src3}
                                        crop={crop3}
                                        ruleOfThirds

                                        onImageLoaded={onImageLoaded.bind(this,3)}
                                        onComplete={onCropComplete.bind(this,3)}
                                        onChange={onCropChange.bind(this,3)}
                                      />
                                    )}
                                    {src3 && (
                                      <p className="text-right">Move/Resize the box to the center of your id</p>
                                    )}

                                </div>
                            </div>
                            <br />
                            <div className="clearfix">
                                <div className="form-group-nric-number">
                                    <label>NRIC Back</label>
                                    <input className="form-control d-nric-b" placeholder="" type="file" onChange={onSelectFile.bind(this,4)} required />
                                    {src4 && (
                                      <ReactCrop
                                        src={src4}
                                        crop={crop4}
                                        ruleOfThirds

                                        onImageLoaded={onImageLoaded.bind(this,4)}
                                        onComplete={onCropComplete.bind(this,4)}
                                        onChange={onCropChange.bind(this,4)}
                                      />
                                    )}
                                    {src4 && (
                                      <p className="text-right">Move/Resize the box to the center of your id</p>
                                    )}
                                </div>
                            </div>
                            <br />
                            <div className="clearfix">
                                <div className="form-group form-group-driver-address">
                                    <label>Address</label>
                                    <textarea className="form-control d-address" name="dAddress" required></textarea>
                                </div>
                            </div>

                            <br />
                            <Button variant="contained" size="large" color="primary" type="submit">
                              Save this driver
                            </Button>


                        </form>

                    </div>

                </DialogContent>

            </Dialog>
          );
    }

    function ModalEdit(props){
        const classes = useStyles();
        const [isOpen, setIsOpen] = useState(false);
        const [selectedDate, setSelectedDate] = React.useState(new Date(props.currentDriverData.cd_birthdate));

        const [currentName, setCurrentName] = useState(props.currentDriverData.cd_driver_name);
        const [currentMobile, setCurrentMobile] = useState(props.currentDriverData.cd_mobile_no);
        const [currentExp, setCurrentExp] = useState(props.currentDriverData.cd_driver_yearexp);
        const [currentLicense, setCurrentLicense] = useState(props.currentDriverData.cd_driver_licenseno);
        const [currentNric, setCurrentNric] = useState(props.currentDriverData.cd_nric);
        const [currentAddress, setCurrentAddress] = useState(props.currentDriverData.cd_address);


        var noImagaAvail = noImage;
        if(props.currentDriverData.cd_photo_driverlicfront){
            var srcImage1 = 'https://api.earental.com/uploads/profile/'+props.currentDriverData.cd_photo_driverlicfront;
        }else{
            var srcImage1 = noImagaAvail;
        }

        if(props.currentDriverData.cd_photo_driverlicback){
            var srcImage2 = 'https://api.earental.com/uploads/profile/'+props.currentDriverData.cd_photo_driverlicback;
        }else{
            var srcImage2 = noImagaAvail;
        }

        if(props.currentDriverData.cd_photo_nricfront){
            var srcImage3 = 'https://api.earental.com/uploads/profile/'+props.currentDriverData.cd_photo_nricfront;
        }else{
            var srcImage3 = noImagaAvail;
        }

        if(props.currentDriverData.cd_photo_nricback){
            var srcImage4 = 'https://api.earental.com/uploads/profile/'+props.currentDriverData.cd_photo_nricback;
        }else{
            var srcImage4 = noImagaAvail;
        }
        const [currentSrc1, setcurrentSrc1] = useState(srcImage1);
        const [currentSrc2, setcurrentSrc2] = useState(srcImage2);
        const [currentSrc3, setcurrentSrc3] = useState(srcImage3);
        const [currentSrc4, setcurrentSrc4] = useState(srcImage4);


        const [src1, setSrc1] = useState(false);
        const [src2, setSrc2] = useState(false);
        const [src3, setSrc3] = useState(false);
        const [src4, setSrc4] = useState(false);
        const [imageRef1, setImageRef1] = useState(false);
        const [imageRef2, setImageRef2] = useState(false);
        const [imageRef3, setImageRef3] = useState(false);
        const [imageRef4, setImageRef4] = useState(false);
        const [croppedImageUrlLF, setCroppedImageUrlLF] = useState(false);
        const [croppedImageUrlLB, setCroppedImageUrlLB] = useState(false);
        const [croppedImageUrlNF, setCroppedImageUrlNF] = useState(false);
        const [croppedImageUrlNB, setCroppedImageUrlNB] = useState(false);
        const [crop1, setCrop1] = React.useState(
            {
                unit: '%',
                width:50,
                aspect: 16 / 8,
                x: 25,
                y: 25
            }
        )

        const [crop2, setCrop2] = React.useState(
            {
                unit: '%',
                width:50,
                aspect: 16 / 8,
                x: 25,
                y: 25
            }
        )
        const [crop3, setCrop3] = React.useState(
            {
                unit: '%',
                width:50,
                aspect: 16 / 8,
                x: 25,
                y: 25
            }
        )

        const [crop4, setCrop4] = React.useState(
            {
                unit: '%',
                width:50,
                aspect: 16 / 8,
                x: 25,
                y: 25
            }
        )


        const onSelectFile = (type,e) => {
          if (e.target.files && e.target.files.length > 0) {
            var reader = new FileReader();
            reader.addEventListener('load', () =>{
                if(type == 1){
                    setSrc1(reader.result)
                }else if(type == 2){
                    setSrc2(reader.result)
                }else if(type == 3){
                    setSrc3(reader.result)
                }else if(type == 4){
                    setSrc4(reader.result)
                }

            });
            reader.readAsDataURL(e.target.files[0]);
          }
        };

        const onImageLoaded = (type,image) => {

          if(type == 1){
              setImageRef1(image)
          }else if(type == 2){
              setImageRef2(image)
          }else if(type == 3){
              setImageRef3(image)
          }else if(type == 4){
              setImageRef4(image)
          }
        };

        const onCropComplete = (type,crop) => {

          makeClientCrop(crop,type);
        };

        const onCropChange = (type,crop, percentCrop) => {
          // You could also use percentCrop:
          // this.setState({ crop: percentCrop });
       //    setCrop(crop);
           if(type == 1){
               setCrop1(crop)
           }else if(type == 2){
               setCrop2(crop)
           }else if(type == 3){
               setCrop3(crop)
           }else if(type == 4){
               setCrop4(crop)
           }

        };

        const makeClientCrop = (crop,type) => {
            var currentImage = null;
            if(type == 1){
                currentImage = imageRef1;
            }else if(type == 2){
                currentImage = imageRef2;
            }else if(type == 3){
                currentImage = imageRef3;
            }else if(type == 4){
                currentImage = imageRef4;
            }

          if (currentImage && crop.width && crop.height) {
            const croppedImageUrl = getCroppedImg(
              currentImage,
              crop,
              'newFile.jpeg'
            );
            if(type == 1){
                setCroppedImageUrlLF(croppedImageUrl)
            }else if(type == 2){
                setCroppedImageUrlLB(croppedImageUrl)
            }else if(type == 3){
                setCroppedImageUrlNF(croppedImageUrl)
            }else if(type == 4){
                setCroppedImageUrlNB(croppedImageUrl)
            }
          }
        }

        const makeClientCropInitial = (crop,type) => {
            var currentImage = null;
            if(type == 1){
                currentImage = imageRef1;
            }else if(type == 2){
                currentImage = imageRef2;
            }else if(type == 3){
                currentImage = imageRef3;
            }else if(type == 4){
                currentImage = imageRef4;
            }
            console.log(crop)
          if (currentImage && crop.width && crop.height) {
            const croppedImageUrl = getCroppedImg(
              currentImage,
              crop,
              'newFile.jpeg'
            );
            if(type == 1){
                setCroppedImageUrlLF(croppedImageUrl)
            }else if(type == 2){
                setCroppedImageUrlLB(croppedImageUrl)
            }else if(type == 3){
                setCroppedImageUrlNF(croppedImageUrl)
            }else if(type == 4){
                setCroppedImageUrlNB(croppedImageUrl)
            }
          }
        }

        const getCroppedImg = (image, crop, fileName) => {
          const canvas = document.createElement('canvas');
          const scaleX = image.naturalWidth / image.width;
          const scaleY = image.naturalHeight / image.height;
          canvas.width = crop.width;
          canvas.height = crop.height;
          const ctx = canvas.getContext('2d');

          ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
          );
          const base64Image = canvas.toDataURL('image/jpeg');

          return base64Image;

        }



        const handleDateChange = (date) => {
           setSelectedDate(date);
           setIsOpen(false)
         };

        const handleNameChange = (e) => {
            setCurrentName(e.target.value);
        };
        const handleMobileChange = (e) => {
            setCurrentMobile(e.target.value);
        };
        const handleExpChange = (e) => {
            setCurrentExp(e.target.value);
        };
        const handleLicenseChange = (e) => {
            setCurrentLicense(e.target.value);
        };
        const handleNricChange = (e) => {
            setCurrentNric(e.target.value);
        };
        const handleAddressChange = (e) => {
            setCurrentAddress(e.target.value);
        };

        return (

            <Dialog
                disableEnforceFocus={false}
                open={props.openState}
                onClose={props.closeModal}
                aria-labelledby="form-dialog-title"
                className="driver-modal">
                <DialogTitle id="form-dialog-title">Update Driver</DialogTitle>
                <DialogContent>
                    <div className="mfp-with-anim mfp-driver-dialog" id="new-card-dialog">
                        <form className="cc-form" onSubmit={props.onEditFormSubmit}>
                            <input className="form-control" name="dLicenseFVal" placeholder="" value={croppedImageUrlLF} type="hidden" />
                            <input className="form-control" name="dLicenseBVal" placeholder="" value={croppedImageUrlLB} type="hidden" />
                            <input className="form-control" name="dNricFVal" placeholder="" value={croppedImageUrlNF} type="hidden" />
                            <input className="form-control" name="dNricBVal" placeholder="" value={croppedImageUrlNB} type="hidden" />
                            <div className="clearfix">
                                <div className="form-group form-group-driver-name">
                                    <label>Name</label>
                                    <input
                                    className="form-control d-name"
                                    name="dName"
                                    placeholder="John Doe"
                                    value={currentName}
                                    onChange={handleNameChange}
                                    type="text"
                                     required />
                                </div>
                                <div className="form-group form-group-driver-dob">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} className="dobCalendar">
                                        <KeyboardDatePicker
                                           autoOk={true}
                                           disableFuture
                                           variant="inline"
                                           id="date-picker-dialog-edit"
                                           label="Date of birth"
                                           format="MMM d, yyyy"
                                           name="dDob"
                                           className="dobCalendar"
                                           value={selectedDate}
                                           onChange={handleDateChange}
                                           KeyboardButtonProps={{
                                             'aria-label': 'change date',
                                           }}
                                           KeyboardButtonProps={{
                                              onFocus: e => {
                                                setIsOpen(true);
                                              }
                                            }}
                                            PopoverProps={{
                                              disableRestoreFocus: true,
                                              onClose: () => {
                                                setIsOpen(false);
                                              }
                                            }}
                                            InputProps={{
                                              onFocus: () => {
                                                setIsOpen(true);
                                              }
                                            }}
                                            open={isOpen}

                                         />
                                     </MuiPickersUtilsProvider>

                                </div>
                            </div>
                            <div className="clearfix">
                                <div className="form-group form-group-driver-name">
                                    <label>Mobile</label>
                                    <input
                                        className="form-control d-mobile"
                                        name="dMobile"
                                         placeholder=""
                                         type="text"
                                         value={currentMobile}
                                         onChange={handleMobileChange}
                                     required />
                                </div>
                                <div className="form-group form-group-driver-dob">
                                    <label>Years of exp</label>
                                    <input
                                         className="form-control"
                                         name="dExp"
                                         placeholder=""
                                         value={currentExp}
                                         onChange={handleExpChange}
                                         type="number" required />
                                </div>
                            </div>
                            <div className="clearfix">
                                <div className="form-group form-group-driver-name">
                                    <label>Driver License </label>
                                    <input
                                        className="form-control d-license"
                                        name="dLicense"
                                        placeholder=""
                                        type="text"
                                        value={currentLicense}
                                        onChange={handleLicenseChange}
                                        required />
                                </div>
                            </div>

                            <div className="clearfix">
                                <div className="form-group-driver-name">
                                    <label>License Front </label>
                                    <input className="form-control d-license-f" name="dLicenseF" placeholder="" type="file" onChange={onSelectFile.bind(this,1)} />
                                    {src1 && (
                                      <ReactCrop
                                        src={src1}
                                        crop={crop1}
                                        ruleOfThirds
                                        onImageLoaded={onImageLoaded.bind(this,1)}
                                        onComplete={onCropComplete.bind(this,1)}
                                        onChange={onCropChange.bind(this,1)}
                                      />
                                    )}

                                    {src1 && (
                                      <p className="text-right">Move/Resize the box to the center of your id</p>
                                    )}
                                    {!src1 && (
                                        <CardMedia
                                          className={classes.media}
                                          image={currentSrc1}
                                        />

                                    )}
                                </div>
                            </div>
                            <br />
                            <div className="clearfix">
                                <div className="form-group-nric-number">
                                    <label>License Back</label>
                                    <input className="form-control d-license-b" name="dLicenseB" placeholder="" type="file" onChange={onSelectFile.bind(this,2)}  />
                                    {src2 && (
                                      <ReactCrop
                                        src={src2}
                                        crop={crop2}
                                        ruleOfThirds

                                        onImageLoaded={onImageLoaded.bind(this,2)}
                                        onComplete={onCropComplete.bind(this,2)}
                                        onChange={onCropChange.bind(this,2)}
                                      />
                                    )}
                                    {src2 && (
                                      <p className="text-right">Move/Resize the box to the center of your id</p>
                                    )}
                                    {!src2 && (
                                        <CardMedia
                                          className={classes.media}
                                          image={currentSrc2}
                                        />

                                    )}
                                </div>
                            </div>
                            <div className="clearfix">
                                <div className="form-group form-group-nric-number">
                                    <label>NRIC</label>
                                    <input
                                        className="form-control d-nric"
                                        name="dNric"
                                        placeholder=""
                                        type="text"
                                        value={currentNric}
                                        onChange={handleNricChange}
                                         />
                                </div>
                            </div>
                            <div className="clearfix">
                                <div className="form-group-driver-name">
                                    <label>NRIC Front </label>
                                    <input className="form-control d-nric-f" placeholder="" type="file" onChange={onSelectFile.bind(this,3)}  />
                                    {src3 && (
                                      <ReactCrop
                                        src={src3}
                                        crop={crop3}
                                        ruleOfThirds

                                        onImageLoaded={onImageLoaded.bind(this,3)}
                                        onComplete={onCropComplete.bind(this,3)}
                                        onChange={onCropChange.bind(this,3)}
                                      />
                                    )}
                                    {src3 && (
                                      <p className="text-right">Move/Resize the box to the center of your id</p>
                                    )}
                                    {!src3 && (
                                        <CardMedia
                                          className={classes.media}
                                          image={currentSrc3}
                                        />

                                    )}

                                </div>
                            </div>
                            <br />
                            <div className="clearfix">
                                <div className="form-group-nric-number">
                                    <label>NRIC Back</label>
                                    <input className="form-control d-nric-b" placeholder="" type="file" onChange={onSelectFile.bind(this,4)}  />
                                    {src4 && (
                                      <ReactCrop
                                        src={src4}
                                        crop={crop4}
                                        ruleOfThirds

                                        onImageLoaded={onImageLoaded.bind(this,4)}
                                        onComplete={onCropComplete.bind(this,4)}
                                        onChange={onCropChange.bind(this,4)}
                                      />
                                    )}
                                    {src4 && (
                                      <p className="text-right">Move/Resize the box to the center of your id</p>
                                    )}
                                    {!src4 && (
                                        <CardMedia
                                          className={classes.media}
                                          image={currentSrc4}
                                        />

                                    )}
                                </div>
                            </div>
                            <br />
                            <div className="clearfix">
                                <div className="form-group form-group-driver-address">
                                    <label>Address</label>
                                    <textarea className="form-control d-address" name="dAddress"
                                    value={currentAddress}
                                    onChange={handleAddressChange}
                                    required>
                                    </textarea>
                                </div>
                            </div>

                            <br />
                            <Button variant="contained" size="large" color="primary" type="submit">
                              Update this driver
                            </Button>


                        </form>

                    </div>

                </DialogContent>

            </Dialog>
          );
    }


    function ModalConfirmDelete(props){
        const classes = useStyles();
        const [isOpen, setIsOpen] = useState(false);

        const Transition = React.forwardRef(function Transition(props, ref) {
          return <Slide direction="up" ref={ref} {...props} />;
        });

        return (
            <Dialog
              open={props.openState}
              TransitionComponent={Transition}
              keepMounted
              onClose={props.closeDeleteModal.bind(this,false)}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">{"Your about to delete a driver"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                <p>Are you sure you want to delete this driver?</p>

                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" size="large" onClick={props.closeDeleteModal.bind(this,false)} color="secondary">
                  No
                </Button>
                <Button variant="contained" size="large" onClick={props.closeDeleteModal.bind(this,true)} color="primary">
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          );
    }


    return (
        <>
            <p className="text-right"><DriverAdd click={this.openAddModal} /></p>
            <ModalAdd
                onAddFormSubmit={this.onAddFormSubmit}
                openState={this.state.openAddModal}
                closeModal={this.closeAddModal}
                handleInputChange={this.handleChangeAddInput}
                driverState={this.state}
                onSelectFile={this.onSelectFile}
                onImageLoaded={this.onImageLoaded}
                onCropComplete={this.onCropComplete}
                onCropChange={this.onCropChange}
             />
             <ModalConfirmDelete
                 openState={this.state.openDeleteConfirm}
                 closeDeleteModal={this.closeDeleteModal}
              />

             <ModalEdit
                  onEditFormSubmit={this.onEditFormSubmit}
                  openState={this.state.openEditModal}
                  closeModal={this.closeEditModal}
                  currentDriverData={this.state.currentDriverData}
             />

                {this.renderListing()}

                <Pager
                    total={this.state.totalPage}
                    current={this.state.currentPage}
                    visiblePages={this.state.visiblePage}
                    titles={{ first: 'First', last: 'Last' }}
                    className={"pagination-md "+this.state.hidePager}
                    onPageChanged={this.handlePageChanged}
                    showPagination={false}
                />

            <Snackbar open={this.state.openSb} autoHideDuration={6000} onClose={this.handleCloseSb}>
                <Alert onClose={this.handleCloseSb} severity="success" className="sb-msg">
                    Driver successfully added.
                </Alert>
            </Snackbar>

            <Snackbar open={this.state.openSbDeleted} autoHideDuration={6000} onClose={this.handleCloseSbDelete}>
                <Alert onClose={this.handleCloseSbDelete} severity="success" className="sb-msg">
                    Driver successfully deleted.
                </Alert>
            </Snackbar>

            <Snackbar open={this.state.openSbEdit} autoHideDuration={6000} onClose={this.handleCloseSbEdit}>
                <Alert onClose={this.handleCloseSbEdit} severity="success" className="sb-msg">
                    Driver successfully updated.
                </Alert>
            </Snackbar>
            <div className="gap"></div>
            <ScriptTag isHydrating={true} type="text/javascript" src="js/profile/cards.js" />

        <NewDriver open={this.state.openAddDialog} onClose={this.handleAddDialogClose} onDriverAdded={this.handleDriverAdded} />
        <EditDriver open={this.state.openEditDialog} onClose={this.handleEditDialogClose} onDriverUpdated={this.handleDriverUpdated} driverDetails={this.state.currentDriverData} />
      </>
    )
  }
}
