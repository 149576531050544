import React from "react";
import {
  NavLink,
  HashRouter,
  Redirect
} from "react-router-dom";
import axios from 'axios';
import { store } from 'react-notifications-component';
// import parallaxBg1 from './assets/img/196_365_2048x1365.jpg';
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import Image, { Shimmer } from 'react-shimmer'
import ContentLoader, { Facebook } from "react-content-loader";
import Pager from 'react-pager';
import ScriptTag from 'react-script-tag';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import moment from "moment";
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

// import Checkbox from './Checkbox';
import 'icheck/skins/all.css'; // or single skin css
import "../assets/css/vehicles.css";


import {Checkbox, Radio} from 'react-icheck';



// var parallaxBgStyle = {
//   backgroundImage: "url(" + parallaxBg1 + ")"
// };
const MyLoader = () => (
    <ContentLoader
        height={120}
        width={900}
        speed={1}
    >
        <rect x="0" y="0" rx="5" ry="5" width="180" height="90" />
        <rect x="0" y="100" rx="5" ry="5" width="180" height="15" />
        <rect x="200" y="17" rx="4" ry="4" width="300" height="13" />
        <rect x="200" y="40" rx="4" ry="4" width="300" height="13" />
        <rect x="200" y="65" rx="4" ry="4" width="300" height="13" />
    </ContentLoader>
);
const date = new Date();

export default class LorreyRental extends React.Component {

    constructor(props) {
        super(props);


        const values = queryString.parse(this.props.location.search)

        let endDate = values.end;
        let startDate = values.start;
        if (typeof startDate === 'undefined' || startDate == "" || moment(startDate).isValid() == false){
            startDate = new Date();
        }else{
            startDate = new Date(startDate);
        }

        if (typeof endDate === 'undefined' || endDate == "" || moment(endDate).isValid() == false){
            endDate = new Date();
        }else{
            endDate = new Date(endDate);
        }


        var prevFilters = localStorage.getItem('listing_filters');
        if(prevFilters){
            prevFilters = JSON.parse(prevFilters);
            this.state = {
                vehicles: [],
                passengers:prevFilters.passengers,
                price: prevFilters.price,
                transmission: prevFilters.transmission,
                engine: prevFilters.engine,
                equipment: prevFilters.equipment,
                rental: prevFilters.rental,
                priceRange:prevFilters.priceRange,
                sliderMarks: [
                    {
                    value: 0,
                    label: '$0',
                    },
                    {
                    value: 250,
                    label: '$250',
                    },
                    {
                    value: 500,
                    label: '$500',
                    },
                ],
                priceFrom:prevFilters.priceFrom,
                priceTo:prevFilters.priceTo,
                passengers1: prevFilters.passengers1,
                passengers2: prevFilters.passengers2,
                passengers3: prevFilters.passengers3,
                passengers4: prevFilters.passengers4,
                transmission1: prevFilters.transmission1,
                transmission2: prevFilters.transmission2,
                engine1:prevFilters.engine1,
                engine2:prevFilters.engine2,
                engine3:prevFilters.engine3,
                engine4:prevFilters.engine4,
                equipment1:prevFilters.equipment1,
                equipment2:prevFilters.equipment2,
                equipment3:prevFilters.equipment3,
                equipment4:prevFilters.equipment4,
                equipment5:prevFilters.equipment5,
                equipment6:prevFilters.equipment6,
                equipment7:prevFilters.equipment7,
                equipment8:prevFilters.equipment8,
                rental1: prevFilters.rental1,
                rental2: prevFilters.rental2,
                startDate: startDate,
                endDate: endDate,
                startDate2: startDate,
                endDate2: endDate,
                timePick:'10:00',
                timeDrop:'10:00',
                startDateQuery:moment().format("YYYY-MM-DD"),
                endDateQuery: moment().format("YYYY-MM-DD"),
                limit:10,
                totalPage:0,
                currentPage:prevFilters.currentPage,
                visiblePage:10,
                offset:prevFilters.offset,
                hideclass:'',
                noResult: false,
                resultTotal: 0
            };
        }else{
            this.state = {
                vehicles: [],
                passengers:[],
                price: '',
                transmission: '',
                engine: '',
                equipment: '',
                rental: '',
                priceRange:[0,500],
                sliderMarks: [
                    {
                    value: 0,
                    label: '$0',
                    },
                    {
                    value: 250,
                    label: '$250',
                    },
                    {
                    value: 500,
                    label: '$500',
                    },
                ],
                priceFrom:0,
                priceTo:500,
                passengers1: false,
                passengers2: false,
                passengers3: false,
                passengers4: false,
                transmission1: false,
                transmission2: false,
                engine1:false,
                engine2:false,
                engine3:false,
                engine4:false,
                equipment1:false,
                equipment2:false,
                equipment3:false,
                equipment4:false,
                equipment5:false,
                equipment6:false,
                equipment7:false,
                equipment8:false,
                rental1: false,
                rental2: false,
                startDate: startDate,
                endDate: endDate,
                startDate2: startDate,
                endDate2: endDate,
                timePick:'10:00',
                timeDrop:'10:00',
                startDateQuery:moment().format("YYYY-MM-DD"),
                endDateQuery: moment().format("YYYY-MM-DD"),
                limit:10,
                totalPage:0,
                currentPage:0,
                visiblePage:10,
                offset:0,
                hideclass:'',
                noResult: false,
                resultTotal: 0
            };
        }


        // this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePageChanged = this.handlePageChanged.bind(this);
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
        this.handleChangeTimePick = this.handleChangeTimePick.bind(this);
        this.handleChangeTimeDrop = this.handleChangeTimeDrop.bind(this);
        this.handleInputChangePassenger = this.handleInputChangePassenger.bind(this);
        this.handleInputChangeTransmission = this.handleInputChangeTransmission.bind(this);
        this.handleInputChangeEngine = this.handleInputChangeEngine.bind(this);
        this.handleInputChangeEquipment = this.handleInputChangeEquipment.bind(this);
        this.handleInputChangeRental = this.handleInputChangeRental.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectVehicle = this.handleSelectVehicle.bind(this);
        this.sliderValue = this.sliderValue.bind(this);
        this.handleSliderChange = this.handleSliderChange.bind(this);

    }

    sliderValue(value){
        return '$'+value;
    }

    handleSliderChange = (event, newValue) => {
        this.setState({
          priceRange: newValue
        })
    };
    handleSliderChangeCommitted = (event, newValue) => {

        this.setState({
            priceFrom: newValue[0] ,
            priceTo: newValue[1] ,
        });
        this.setState({ loadingclass:'' });
        this.setState({ hideClass:'isHidden' });
        this.setState({ currentPage : 0 });
        setTimeout(() => {
            this.searchList();
        }, 500);
    };
    handleSelectVehicle(vehicle,e) {

      localStorage.setItem('listing_filters', JSON.stringify(this.state));

      this.props.history.push({
          pathname: '/vehicle-details/'+vehicle.v_name,
          state: { detail: vehicle,start: this.state.startDate2,end: this.state.endDate2 }
        })


    }

    handleChangeStart(date) {
      this.setState({
        startDate2:date,
        startDateQuery:moment(date).format("YYYY-MM-DD")
      })
      console.log(this.state.startDate);
    }

    handleChangeEnd(date) {
      this.setState({
        endDate2: date,
        endDateQuery:moment(date).format("YYYY-MM-DD")
      })
    }


    handleChangeTimePick(time) {
        this.setState({ timePick: time })

    }

    handleChangeTimeDrop(time) {
        this.setState({ timeDrop: time })

    }


    handleInputChangePassenger(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        const inputVal = e.target.value;


        this.setState({ passengers1: false });
        this.setState({ passengers2: false });
        this.setState({ passengers3: false });
        this.setState({ passengers4: false });

        this.setState({ [item]: isChecked });


        if(isChecked){
            this.setState({ passengers: inputVal });
        }else{
            this.setState({ passengers: 0 });
        }

        this.setState({ loadingclass:'' });
        this.setState({ hideClass:'isHidden' });
        this.setState({ currentPage : 0 });
        setTimeout(() => {
            this.searchList();
        }, 500);

    }

    handleInputChangeTransmission(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        const inputVal = e.target.value;

        var tempVal = this.state.transmission;
        this.setState({ [item]: isChecked });

        if(isChecked){
            tempVal = tempVal+''+inputVal+',';
            this.setState({ transmission: tempVal });
        }else{
            tempVal = tempVal.replace(inputVal+",","");
            this.setState({ transmission: tempVal });
        }
        this.setState({ loadingclass:'' });
        this.setState({ hideClass:'isHidden' });
        this.setState({ currentPage : 0 });

        setTimeout(() => {
            this.searchList();
        }, 500);

    }

    handleInputChangeEngine(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        const inputVal = e.target.value;

        var tempVal = this.state.engine;
        this.setState({ [item]: isChecked });

        if(isChecked){
            tempVal = tempVal+''+inputVal+',';
            this.setState({ engine: tempVal });
        }else{
            tempVal = tempVal.replace(inputVal+",","");
            this.setState({ engine: tempVal });
        }
        this.setState({ loadingclass:'' });
        this.setState({ hideClass:'isHidden' });
        this.setState({ currentPage : 0 });

        setTimeout(() => {
            this.searchList();

        }, 500);

    }

    handleInputChangeEquipment(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        const inputVal = e.target.value;

        var tempVal = this.state.equipment;
        this.setState({ [item]: isChecked });

        if(isChecked){
            tempVal = tempVal+''+inputVal+',';
            this.setState({ equipment: tempVal });
        }else{
            tempVal = tempVal.replace(inputVal+",","");
            this.setState({ equipment: tempVal });
        }
        this.setState({ loadingclass:'' });
        this.setState({ hideClass:'isHidden' });
        this.setState({ currentPage : 0 });

        setTimeout(() => {
            this.searchList();

        }, 500);

    }

    handleInputChangeRental(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        const inputVal = e.target.value;

        var tempVal = this.state.rental;
        this.setState({ [item]: isChecked });

        if(isChecked){
            tempVal = tempVal+''+inputVal+',';
            this.setState({ rental: tempVal });
        }else{
            tempVal = tempVal.replace(inputVal+",","");
            this.setState({ rental: tempVal });
        }
        this.setState({ loadingclass:'' });
        this.setState({ hideClass:'isHidden' });
        this.setState({ currentPage : 0 });

        setTimeout(() => {
            this.searchList();

        }, 500);

    }



    searchList() {

        var ctoken = localStorage.getItem('ctoken');
        var token = localStorage.getItem('token');
        var currentOffset = this.state.limit * this.state.currentPage;
        var bodyFormData = new FormData();
        var transmission = this.state.transmission;
        if(transmission != "" || transmission != null){
            transmission = transmission.slice(0, -1);
        }

        var engine = this.state.engine;
        if(engine != "" || engine != null){
            engine = engine.slice(0, -1);
        }

        var equipment = this.state.equipment;
        if(equipment != "" || equipment != null){
            equipment = equipment.slice(0, -1);
        }

        var rental = this.state.rental;
        if(rental != "" || rental != null){
            rental = rental.slice(0, -1);
        }
        bodyFormData.append('access_token', ctoken);
        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
        bodyFormData.append('jham_token', token);
        bodyFormData.append('limit', this.state.limit);
        bodyFormData.append('offset', currentOffset);
        bodyFormData.append('date_from', "");
        bodyFormData.append('date_to', "");
        bodyFormData.append('passengers', this.state.passengers);
        bodyFormData.append('price_from', this.state.priceFrom);
        bodyFormData.append('price_to',this.state.priceTo);
        bodyFormData.append('v_group', "Lorry");
        bodyFormData.append('transmission', transmission);
        bodyFormData.append('engine', engine);
        bodyFormData.append('equipment', equipment);
        bodyFormData.append('rentaloption', rental);


        axios({
                method: 'post',
                url: 'https://api.earental.com/listing/search',
                data: bodyFormData,
                withCredentials: true,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then((response) => {

                if(response.data.error > 0){
                    store.addNotification({
                      title: "Search failed",
                      message: response.data.error_msg,
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: false
                      }
                    });
                    this.setState({ vehicles:[] });
                    this.setState({ loadingclass:'isHidden' });
                    this.setState({ hideClass:'' });
                    this.setState({ noResult:true });
                }else{
                    if(response.data.data == null){
                        this.setState({ vehicles:[] });
                        this.setState({ noResult:true });

                    }else{
                        this.setState({ vehicles:response.data.data });
                        this.setState({ noResult:false });
                    }

                    this.setState({ loadingclass:'isHidden' });
                    this.setState({ hideClass:'' });
                    this.setState({ totalPage:response.data.total_pages });
                }
                this.setState({ resultTotal: response.data.result_total })

                // window.scrollTo(0, 0);
            })
            .catch((error) => {
                //handle error
            //     this.setState({ loading:false });
            //     if (error.response.status == 403) {
               //
            //         store.addNotification({
            //           title: "Oops Something went wrong",
            //           message: "The action you have requested is not allowed. please re-load the page",
            //           type: "danger",
            //           insert: "top",
            //           container: "top-right",
            //           animationIn: ["animated", "fadeIn"],
            //           animationOut: ["animated", "fadeOut"],
            //           dismiss: {
            //             duration: 5000,
            //             onScreen: false
            //           }
            //         });
               //
            //    }
            this.setState({ vehicles:[] });
            this.setState({ noResult:true });

        });

    }


    handleSubmit(e) {
        e.preventDefault();
        this.props.history.push("/vehicles/all-vehicles?start="+this.state.startDateQuery+"&end="+this.state.endDateQuery+"&term="+this.state.rental);
        //$.magnificPopup.close();

        this.setState({ loadingclass:'' });
        this.setState({ hideClass:'isHidden' });
        this.setState({ currentPage : 0 });
        setTimeout(() => {
            this.searchList();
        }, 500);
    }

    componentDidMount() {
        localStorage.removeItem("listing_filters");
        var ctoken = localStorage.getItem('ctoken');
        var token = localStorage.getItem('token');
        var currentOffset = this.state.limit * this.state.currentPage;
        var bodyFormData = new FormData();

        var transmission = this.state.transmission;
        if(transmission != "" || transmission != null){
            transmission = transmission.slice(0, -1);
        }

        var engine = this.state.engine;
        if(engine != "" || engine != null){
            engine = engine.slice(0, -1);
        }

        var equipment = this.state.equipment;
        if(equipment != "" || equipment != null){
            equipment = equipment.slice(0, -1);
        }

        var rental = this.state.rental;
        if(rental != "" || rental != null){
            rental = rental.slice(0, -1);
        }

        bodyFormData.append('access_token', ctoken);
        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
        bodyFormData.append('jham_token', token);
        bodyFormData.append('limit', this.state.limit);
        bodyFormData.append('offset', currentOffset);
        bodyFormData.append('date_from', "");
        bodyFormData.append('date_to', "");
        bodyFormData.append('passengers', this.state.passengers);
        bodyFormData.append('price_from', this.state.priceFrom);
        bodyFormData.append('price_to',this.state.priceTo);
        bodyFormData.append('v_group', "Lorry");
        bodyFormData.append('transmission', transmission);
        bodyFormData.append('engine', engine);
        bodyFormData.append('equipment', equipment);
        bodyFormData.append('rentaloption', rental);


        axios({
                method: 'post',
                url: 'https://api.earental.com/listing/search',
                data: bodyFormData,
                withCredentials: true,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then((response) => {

                if(response.data.error > 0){
                    store.addNotification({
                      title: "Search failed",
                      message: response.data.error_msg,
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: false
                      }
                    });
                    this.setState({ vehicles:[] });
                    this.setState({ loadingclass:'isHidden' });
                    this.setState({ noResult:true });
                }else{

                    if(response.data.data == null){
                        this.setState({ vehicles:[] });
                        this.setState({ noResult:true });
                    }else{
                        this.setState({ vehicles:response.data.data });
                        this.setState({ noResult:false });
                    }

                    this.setState({ loadingclass:'isHidden' });
                    this.setState({ totalPage:response.data.total_pages });
                }

                this.setState({ resultTotal: response.data.result_total })
            })
            .catch((error) => {
                //handle error
            //     this.setState({ loading:false });
            //     if (error.response.status == 403) {
               //
            //         store.addNotification({
            //           title: "Oops Something went wrong",
            //           message: "The action you have requested is not allowed. please re-load the page",
            //           type: "danger",
            //           insert: "top",
            //           container: "top-right",
            //           animationIn: ["animated", "fadeIn"],
            //           animationOut: ["animated", "fadeOut"],
            //           dismiss: {
            //             duration: 5000,
            //             onScreen: false
            //           }
            //         });
               //
            //    }
                this.setState({ vehicles:[] });
                this.setState({ noResult:true });

        });

    }

    handlePageChanged(newPage) {
        this.setState({ currentPage : newPage });
        this.setState({ loadingclass:'' });
        this.setState({ hideClass:'isHidden' });

        setTimeout(() => {
            this.searchList();

        }, 500);

    }

    renderSideFilter() {
        const checkboxesPassenger = [
        {
            name: "passengers1",
            key: "checkBoxPassenger",
            label: "2 Passengers",
            value:2,
            id:1
        },
        {
            name: "passengers2",
            key: "checkBoxPassenger",
            label: "3 Passengers",
            value:3,
            id:2
        },
        {
            name: "passengers3",
            key: "checkBoxPassenger",
            label: "4 Passengers",
            value:4,
            id:3
        },
        {
            name: "passengers4",
            key: "checkBoxPassenger",
            label: "4+ Passengers",
            value:5,
            id:4
        }
    ];
    const checkboxesToRenderPassenger = checkboxesPassenger.map(item => {
           return (
               <div className="checkbox" key={item.key+'-'+item.id}>
                   <Checkbox
                      checkboxClass="i-check"
                      increaseArea="20%"
                      name={item.name}
                      checked={this.state.[item.name]}
                      onChange={this.handleInputChangePassenger}
                      label={item.label}
                      value={item.value}
                    />
               </div>
           );
       });

       const checkboxesTransmission = [
           {
               name: "transmission1",
               key: "checkBoxTransmission",
               label: "Automatic",
               value: "Automatic",
               id:1
           },
           {
               name: "transmission2",
               key: "checkBoxTransmission",
               label: "Manual",
               value: "Manual",
               id:2
           },
        ];
       const checkboxesToRenderTransmission = checkboxesTransmission.map(item => {
                return (
                    <div className="checkbox" key={item.key+'-'+item.id}>
                       <Checkbox
                          checkboxClass="i-check"
                          increaseArea="20%"
                          name={item.name}
                          checked={this.state.[item.name]}
                          onChange={this.handleInputChangeTransmission}
                          label={item.label}
                          value={item.value}
                        />
                    </div>
                );
          });

          const checkboxesEngine = [
              {
                  name: "engine1",
                  key: "checkBoxEngine",
                  label: "Gas",
                  value: "Gas",
                  id:1
              },
              {
                  name: "engine2",
                  key: "checkBoxEngine",
                  label: "Diesel",
                  value: "Diesel",
                  id:2
              },
              {
                  name: "engine3",
                  key: "checkBoxEngine",
                  label: "Hybrid",
                  value: "Hybrid",
                  id:3
              },
              {
                  name: "engine4",
                  key: "checkBoxEngine",
                  label: "Electric",
                  value: "Electric",
                  id:4
              },
           ];
          const checkboxesToRenderEngine = checkboxesEngine.map(item => {
                 return (
                         <div className="checkbox" key={item.key+'-'+item.id}>
                            <Checkbox
                               checkboxClass="i-check"
                               increaseArea="20%"
                               name={item.name}
                               checked={this.state.[item.name]}
                               onChange={this.handleInputChangeEngine}
                               value={item.value}
                               label={item.label}
                             />
                         </div>
                     );
             });

         const checkboxesEquipment = [
             {
                name: "equipment1",
                key: "checkBoxEquipment",
                label: "Climate Control",
                value: "Climate Control",
                id:1
             },
             {
                name: "equipment2",
                key: "checkBoxEquipment",
                label: "Air Conditioning",
                value: "Air Conditioner",
                id:2
             },
             {
                name: "equipment3",
                key: "checkBoxEquipment",
                label: "Satellite Navigation",
                value: "Satellite Navigation",
                id:3
             },
             {
                name: "equipment4",
                key: "checkBoxEquipment",
                label: "Power Door Locks",
                value: "Power Door Locks",
                id:4
             },
             {
                name: "equipment5",
                key: "checkBoxEquipment",
                label: "FM Radio",
                value: "FM Radio",
                id:5
             },
             {
                name: "equipment6",
                key: "checkBoxEquipment",
                label: "Stereo CD/MP3",
                value: "Stereo CD/MP3",
                id:6
             },
             {
                name: "equipment7",
                key: "checkBoxEquipment",
                label: "Tilt Steering Wheel",
                value: "Tilt Steering Wheel",
                id:7
             },
             {
                name: "equipment8",
                key: "checkBoxEquipment",
                label: "Power Windows",
                value: "Power Windows",
                id:8
             },
          ];
         const checkboxesToRenderEquipment = checkboxesEquipment.map(item => {
                return (
                        <div className="checkbox" key={item.key+'-'+item.id}>
                           <Checkbox
                              checkboxClass="i-check"
                              increaseArea="20%"
                              name={item.name}
                              checked={this.state.[item.name]}
                              onChange={this.handleInputChangeEquipment}
                              value={item.value}
                              label={item.label}
                            />
                        </div>
                    );
            });

        const checkboxesRental = [
            {
                name: "rental1",
                key: "checkBoxEquipment",
                label: "Short Term",
                value: "Short Term",
                id:1
            },
            {
                name: "rental2",
                key: "checkBoxEquipment",
                label: "Long Term",
                value: "Long Term",
                id:2
            },
         ];
        const checkboxesToRenderRental = checkboxesRental.map(item => {
               return (
                       <div className="checkbox" key={item.key+'-'+item.id}>
                          <Checkbox
                             checkboxClass="i-check"
                             increaseArea="20%"
                             name={item.name}
                             checked={this.state.[item.name]}
                             onChange={this.handleInputChangeRental}
                             value={item.value}
                             label={item.label}
                           />
                       </div>
                   );
           });

        return (

            <aside className="booking-filters text-white">
                <h3>Filter By:</h3>
                <ul className="list booking-filters-list">
                    <li>
                        <h5 className="booking-filters-title">Passengers Quantity</h5>
                        {checkboxesToRenderPassenger}

                    </li>
                    <li>
                        <h5 className="booking-filters-title">Price </h5>
                        <Slider
                           value={this.state.priceRange}
                           onChange={this.handleSliderChange}
                           onChangeCommitted={this.handleSliderChangeCommitted}
                           valueLabelDisplay="auto"
                           aria-labelledby="range-slider"
                           getAriaValueText={this.sliderValue}
                           min={0}
                           max={500}
                           marks={this.state.sliderMarks}
                           className="v-price-slider"
                         />
                    </li>
                    <li>
                        <h5 className="booking-filters-title">Transmission</h5>
                        {checkboxesToRenderTransmission}
                    </li>
                    <li>
                        <h5 className="booking-filters-title">Engine</h5>
                        {checkboxesToRenderEngine}
                    </li>
                    <li>
                        <h5 className="booking-filters-title">Equipment</h5>
                        {checkboxesToRenderEquipment}
                    </li>
                    <li>
                        <h5 className="booking-filters-title">Rental Options</h5>
                        {checkboxesToRenderRental}
                    </li>
                </ul>
            </aside>
        );
    }

    renderListing() {

        function TermIcons(props) {
            var terms = props.terms.split(',');
            return (
                  terms.map((item,i) =>
                    (item == "Short Term") ?
                    (<li rel="tooltip" data-placement="top" title={item} key={'t-'+i+'-'+props.vid}><i className="fa fa-clock-o"></i></li>) :
                    (<li rel="tooltip" data-placement="top" title={item} key={'t-'+i+'-'+props.vid}><i className="fa fa-calendar-o"></i></li>)
                  )
            );

        }

        function Equipments2(props) {

            if(props.equipments == null){
                return false;
            }else{
                var equipments = props.equipments.split(',');
                return (
                      equipments.map((item,i) =>
                        (item.trim() == "Air Conditioner") ?
                        (<li rel="tooltip" data-placement="top" title={item} ><i className="im im-air"></i></li>) :
                        null



                      )
                );

            }

        }

        function Equipments(props) {

            if(props.equipments == null){
                return false;
            }else{
                var equipments = props.equipments.split(',');
                return (
                      equipments.map((item,i) => {
                            switch (item.trim()) {
                                case "Air Conditioner":
                                    return (<li rel="tooltip" data-placement="top" title={item} key={'xve-'+i+'-'+props.vid}><i className="im im-air"></i></li>)
                                break;
                                case "Stereo CD/MP3":
                                    return (<li rel="tooltip" data-placement="top" title={item} key={'xve-'+i+'-'+props.vid}><i className="im im-stereo"></i></li>)
                                break;
                                case "FM Radio":
                                    return (<li rel="tooltip" data-placement="top" title={item} key={'xve-'+i+'-'+props.vid}><i className="im im-fm"></i></li>)
                                break;
                                case "Power Door Locks":
                                    return (<li rel="tooltip" data-placement="top" title={item} key={'xve-'+i+'-'+props.vid}><i className="im im-lock"></i></li>)
                                break;
                                case "Satellite Navigation":
                                    return (<li rel="tooltip" data-placement="top" title={item} key={'xve-'+i+'-'+props.vid}><i className="im im-satellite"></i></li>)
                                break;
                                case "Universal power adapter":
                                    return (<li rel="tooltip" data-placement="top" title={item} key={'xve-'+i+'-'+props.vid}><i className="im im-air"></i></li>)
                                break;
                                case "Power Windows":
                                    return (<li rel="tooltip" data-placement="top" title={item} key={'xve-'+i+'-'+props.vid}><i className="im im-car-window"></i></li>)
                                break;
                                case "Tilt Steering Wheel":
                                    return (<li rel="tooltip" data-placement="top" title={item} key={'xve-'+i+'-'+props.vid}><i className="im im-car-wheel"></i></li>)
                                break;
                                case "Climate Control":
                                    return (<li rel="tooltip" data-placement="top" title={item} key={'xve-'+i+'-'+props.vid}><i className="im im-climate-control"></i></li>)
                                break;


                            }
                      })
                );

            }

        }

        if (!this.state.noResult) {
            return (
                <div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="nav-drop booking-sort">
                                <h5 className="booking-sort-title"><a href="#">Sort: Price (low to high)<i className="fa fa-angle-down"></i><i className="fa fa-angle-up"></i></a></h5>
                                <ul className="nav-drop-menu">
                                    <li><a href="#">Price (high to low)</a>
                                    </li>
                                    <li><a href="#">Van Name (A-Z)</a>
                                    </li>
                                    <li><a href="#">Van Name (Z-A)</a>
                                    </li>
                                    <li><a href="#">Van Type</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-6 text-right">
                            <p>Not what youre looking for? <a className="popup-text" href="#search-dialog" data-effect="mfp-zoom-out">Try changing date</a>
                            </p>

                        </div>
                    </div>

                    <ul className="booking-list">
                        <div className={this.state.loadingclass}>
                            <li>
                                <div className="booking-item">
                                    <MyLoader  />
                                </div>
                            </li>
                            <li>
                                <div className="booking-item">
                                    <MyLoader  />
                                </div>
                            </li>
                            <li>
                                <div className="booking-item">
                                    <MyLoader  />
                                </div>
                            </li>
                            <li>
                                <div className="booking-item">
                                    <MyLoader  />
                                </div>
                            </li>
                        </div>
                        { this.state.vehicles.map(vehicle =>
                                <li key={vehicle.v_id}>
                                    <div className={this.state.hideClass}>
                                        <a onClick={this.handleSelectVehicle.bind(this,vehicle)}  className="booking-item" >
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="booking-item-car-img">
                                                    <Image
                                                        src="img/van.jpg"
                                                        alt="Image Alternative text"
                                                        title="Image Title"
                                                        fallback={<Shimmer width={262} height={200} />}
                                                    />
                                                        <p className="booking-item-car-title">{vehicle.v_name}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <ul className="booking-item-features booking-item-features-sign clearfix">
                                                                <li rel="tooltip" data-placement="top" title="Passengers"><i className="fa fa-male"></i><span className="booking-item-feature-sign">x {vehicle.v_passengers}</span>
                                                                </li>
                                                                <li rel="tooltip" data-placement="top" title="Doors"><i className="im im-car-doors"></i><span className="booking-item-feature-sign">x 4</span>
                                                                </li>
                                                                <li rel="tooltip" data-placement="top" title="Baggage Quantity"><i className="fa fa-briefcase"></i><span className="booking-item-feature-sign">x 2</span>
                                                                </li>
                                                                <li rel="tooltip" data-placement="top" title={vehicle.v_transmission}><i className="im im-shift"></i><span className="booking-item-feature-sign"></span>
                                                                </li>
                                                                <li rel="tooltip" data-placement="top" title={vehicle.v_gas}><i className="im im-diesel"></i>
                                                                <span className="booking-item-feature-sign">{vehicle.v_gas}</span>
                                                                </li>
                                                            </ul>
                                                            <ul className="booking-item-features booking-item-features-small clearfix">
                                                                <Equipments equipments={vehicle.xve_equipment} vid={vehicle.v_id} />
                                                            </ul>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <ul className="booking-item-features booking-item-features-dark">
                                                                <TermIcons terms={vehicle.v_rent_terms} vid={vehicle.v_id} />
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3"><span className="booking-item-price">${Math.floor(vehicle.v_price_day)}</span><span>/day</span>
                                                    <p className="booking-item-flight-className">{vehicle.v_type}</p><span className="btn btn-primary">Select</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </li>
                            )
                        }


                    </ul>
                    <div className="row">
                        <div className="col-md-6">
                            <p>{this.state.resultTotal} rental van in EARental. &nbsp;&nbsp;Showing 1 – 10
                            </p>

                            <Pager
                                total={this.state.totalPage}
                                current={this.state.currentPage}
                                visiblePages={this.state.visiblePage}
                                titles={{ first: 'First', last: 'Last' }}
                                className="pagination-md"
                                onPageChanged={this.handlePageChanged}
                            />



                        </div>

                    </div>
                </div>
            );
        }else{
            return (
                <div className="vehicle-listing text-center">
                    <div className="row">
                        <div className="col-md-6">

                        </div>
                        <div className="col-md-6 text-right">
                            <p>Not what youre looking for? <a className="popup-text" href="#search-dialog" data-effect="mfp-zoom-out">Try changing date</a>
                            </p>

                        </div>
                    </div>
                    <br />
                    <p className="search-icon"><b><i class="fa fa-search"></i></b></p>
                    <p className="not-found"><b>No Result Found</b></p>
                    <p>We cannot find the vehicle you are searching <br /> try changing your filters
                    </p>
                </div>
            );
        }


    }

    renderPopup() {
        return (
            <div className="mfp-with-anim mfp-hide mfp-dialog mfp-search-dialog" id="search-dialog">
                <h3>Select your desired date</h3>
                <form onSubmit={this.handleSubmit}>
                    <div className="input-daterange" data-date-format="MM d, D">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group form-group-lg form-group-icon-left"><i className="fa fa-calendar input-icon input-icon-highlight"></i>
                                    <label>Start Date</label>
                                    <DatePicker
                                      selected={this.state.startDate2}
                                      className="form-control"
                                      onChange={ this.handleChangeStart }
                                      name="start"
                                      dateFormat="MMM d, yyyy"
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group form-group-lg form-group-icon-left"><i className="fa fa-calendar input-icon input-icon-highlight"></i>
                                    <label>End Date</label>
                                    <DatePicker
                                      selected={this.state.endDate2}
                                      className="form-control"
                                      onChange={ this.handleChangeEnd }
                                      name="end"
                                      dateFormat="MMM d, yyyy"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="btn btn-primary btn-lg" type="submit">Search</button>
                </form>
            </div>
        );
    }


  render() {
    return (
        <HashRouter>
            <div>
                <div className="container">
                    {this.renderPopup()}
                    <h3 className="booking-title">Lorry Rental</h3>
                    <div className="row">
                        <div className="col-md-3">
                                {this.renderSideFilter()}
                        </div>
                        <div className="col-md-9">
                            {this.renderListing()}

                        </div>
                    </div>
                    <div className="gap"></div>
                </div>
                <ScriptTag isHydrating={true} type="text/javascript" src="js/vehicle/vehicle.js" />


            </div>
        </HashRouter>
    )
  }
}
