import React, {forwardRef,Component,useState } from "react";
import {
  NavLink,
  HashRouter
} from "react-router-dom";

import axios, { post } from 'axios';
import { store } from 'react-notifications-component';
import Pager from 'react-pager';

import Typography from '@material-ui/core/Typography';
import { DataGrid } from '@material-ui/data-grid';
import { createMuiTheme } from '@material-ui/core/styles';
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@material-ui/icons/EventBusyOutlined';
import moment from "moment";
import TextField from '@material-ui/core/TextField';


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import Divider from '@material-ui/core/Divider';
import { red,grey, orange } from '@material-ui/core/colors';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import RejectPayment from './dashboardPages/RejectPayment';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const useStyles = makeStyles({
    root: {
        backgroundColor: "blue",
        color: "green"
    },
    toolbar: {
        backgroundColor: "white"
    },
    caption: {
        color: "red",
        fontSize: "20px"
    },
    selectIcon: {
        color: "green"
    },
    select: {
        color: "green",
        fontSize: "20px"
    },
    actions: {
        color: "blue"
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        outline: 'none'
    },
    newCardForm: {
      color: grey[800],
      '& .MuiFormControl-root': {
        '& .MuiFormLabel-root': {
          fontSize: '1.2rem',
          '&.Mui-focused': {
            color: grey[800],
            backgroundColor: 'white',
            paddingRight: 5,
          },
        },
        '& .MuiInputBase-root': {
          borderRadius: 0,
          '&:hover fieldset': {
            borderColor: '#B4B4B4',
          },
          '&.Mui-focused fieldset': {
            borderColor: orange[600],
          },
          '& input': {
            fontSize: 15,
            font:'auto'
          },
        },
      }
    }
});

let renderFlag = true;
let tableFields = [
        's.s_id',
        'o.order_invoice_no',
        'c.customer_firstname',
        'p.product_category',
        'p.product_name',
        'p.product_platenumber',
        'p.product_rent_terms',
        's.s_start_date',
        's.s_end_date',
        's.s_price_type',
        's.s_lease_rate',
        's.s_payable_amount'
    ]

class Schedule extends Component {

    constructor (props) {
        super(props)
        window.scrollTo(0, 0);
        this.rejectReason = React.createRef();
        this.fileInputUrgentMaintenance = React.createRef();
        this.state = {
            ongoingRentalActive: [],
            ongoingRentalPending: [],
            totalRentsActive:0,
            totalRentsPending:0,
            limit:10,
            offsetActive:0,
            offsetPending: 0,
            visiblePage: 5,
            currentPageActive:0,
            totalPagesActive: 0,
            totalPagesPending: 0,
            currentPagePending:0,
            noResult: false,
            openCancelConfirm: false,
            currentTransaction:false,
            openSb: false,
            lessorCode:false,
            openCancelConfirm:false,
            openApproveConfirm: false,
            openRejectConfirm: false,
            openRejectPayment: false,
            sbstatus:'success',
            sbMessage:'',
            keyword:'',
            timeout:0,
            currentTab: 1,
            currentSort: 'desc',
            currentSortField: 's.s_id',
            tableLoading:false,
            actionProcessing: false,
            cancellationFee: 0,
            cancellationProcessingFee: 0,
            rejectReason: "urgent_maintenance",
            rejectDocument: null,
        };
        this.handlePageChangedActive = this.handlePageChangedActive.bind(this);
        this.handlePageChangedPending = this.handlePageChangedPending.bind(this);
        this.handleCloseCancel = this.handleCloseCancel.bind(this);
        this.handleClickCancel = this.handleClickCancel.bind(this);
        this.handleCloseReject = this.handleCloseReject.bind(this);
        this.handleCloseSb = this.handleCloseSb.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleClickApprove = this.handleClickApprove.bind(this);
        this.handleCloseApprove = this.handleCloseApprove.bind(this);
        this.handleClickCancelPending = this.handleClickCancelPending.bind(this);
        this.handleSortChangeActive = this.handleSortChangeActive.bind(this);
        this.handleSortChangePending = this.handleSortChangePending.bind(this);
        this.handleClickRejectPending = this.handleClickRejectPending.bind(this);
    }

    handleCloseSb(){
        this.setState({
          openSb:false,
        })
    }

    handleSortChangeActive = (orderedColumnId, orderDirection) => {

      var currentDirection = this.state.currentSort;
      if(currentDirection == "asc"){
          currentDirection = "desc";
      }else{
          currentDirection = "asc";
      }

      this.setState({
        currentSort:currentDirection,
        tableLoading: true,
        currentSortField: tableFields[orderedColumnId],
        currentTab: 0
      })
      setTimeout(() => {
            this.componentDidMount();
      }, 150);

    };

    handleSortChangePending = (orderedColumnId, orderDirection) => {

      var currentDirection = this.state.currentSort;
      if(currentDirection == "asc"){
          currentDirection = "desc";
      }else{
          currentDirection = "asc";
      }

      this.setState({
        currentSort:currentDirection,
        tableLoading: true,
        currentSortField: tableFields[orderedColumnId],
        currentTab: 1
      })
      setTimeout(() => {
            this.componentDidMount();
      }, 150);

    };


    shouldComponentUpdate(nextProps, nextState) {

        if (!renderFlag) {
            return false;
        } else {
            return true;
        }
    }



    handleCloseCancel(confirmation,e){

        if(confirmation){

            var bodyFormData = new FormData();
            var token = localStorage.getItem('token');
            var ctoken = localStorage.getItem('ctoken');

            bodyFormData.append('s_id', this.state.currentSchduleId);
            bodyFormData.append('jham_token', token);
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


            axios({
                  method: 'post',
                  url: 'https://api.earental.com/lessor/cancel_schedule',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {
                console.log(response.data.error);
                 if(response.data.error > 0){
                     function ErrorMsg(props) {
                       return (
                           props.msgs.map((item,i) =>
                               <li key={"err-"+item.id}>{item.value}</li>
                           )
                       );
                     }
                     store.addNotification({
                       title: "Error",
                       message: response.data.error_message,
                       type: "danger",
                       insert: "top",
                       container: "top-right",
                       animationIn: ["animated", "fadeIn"],
                       animationOut: ["animated", "fadeOut"],
                       dismiss: {
                         duration: 20000,
                         onScreen: false
                       }
                     });

                     this.setState({
                         openCancelConfirm:false,
                         currentSchduleId: false,
                         sbMessage: response.data.error_message,
                         sbstatus:'error',
                         openSb: true
                     })
                 }else{

                     this.componentDidMount();

                     this.setState({
                        openCancelConfirm:false,
                        currentSchduleId: false,
                        sbMessage:"Schedule successfully cancelled.",
                        sbstatus:'success',
                        openSb: true
                     })

                 }

                 renderFlag = true;



              })
              .catch((error) => {
                  //handle error
                  console.log(error);

            });

        }else{
            this.setState({
                sbstatus:'success',
                openCancelConfirm:false,
                currentSchduleId: false
            })
        }
    }

    handleCloseReject(confirmation,e){
        if(confirmation){
            if(this.rejectReason.current.value == "" || this.rejectReason.current.value.length < 10){
                store.addNotification({
                  title: "Error",
                  message: "Your message is too short, kindly specify your message to avoid confusion.",
                  type: "warning",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                    duration: 20000,
                    onScreen: false
                  }
                });

            }else{
              if (this.state.rejectReason == "lessee_request") {
                this.setState({
                  actionProcessing: true
                })

                var bodyFormData = new FormData();
                var token = localStorage.getItem('token');
                var ctoken = localStorage.getItem('ctoken');

                bodyFormData.append('s_id', this.state.currentSchduleId);
                bodyFormData.append('cancel_reason', this.rejectReason.current.value);
                bodyFormData.append('reject_reason', this.state.rejectReason);
                bodyFormData.append('reject_document', this.state.rejectDocument);
                bodyFormData.append('jham_token', token);
                bodyFormData.append('access_token', ctoken);
                bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


                axios({
                  method: 'post',
                  url: 'https://api.earental.com/lessor/cancel_schedule',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: { 'Content-Type': 'multipart/form-data' }
                })
                  .then((response) => {
                    console.log(response.data.error);
                    if (response.data.error > 0) {
                      function ErrorMsg(props) {
                        return (
                          props.msgs.map((item, i) =>
                            <li key={"err-" + item.id}>{item.value}</li>
                          )
                        );
                      }
                      store.addNotification({
                        title: "Error",
                        message: response.data.error_message,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 20000,
                          onScreen: false
                        }
                      });

                      this.setState({
                        openRejectConfirm: false,
                        currentSchduleId: false,
                        sbMessage: response.data.error_message,
                        sbstatus: 'error',
                        openSb: true,
                        actionProcessing: false
                      })
                    } else {

                      this.componentDidMount();

                      this.setState({
                        openRejectConfirm: false,
                        currentSchduleId: false,
                        sbMessage: "Schedule successfully rejected.",
                        sbstatus: 'success',
                        openSb: true,
                        actionProcessing: false
                      })

                    }

                    renderFlag = true;
                  })
                  .catch((error) => {
                    //handle error
                    console.log(error);

                  });
              } else {
                this.setState({
                  actionProcessing: false,
                  openRejectPayment: true,
                  openRejectConfirm: false,
                });
              }
            }


        }else{
            this.setState({
                sbstatus:'success',
                openRejectConfirm:false,
                currentSchduleId: false,
                actionProcessing: false
            })
        }
    }

    handleCloseApprove(confirmation,e){

        if(confirmation){
            this.setState({
                actionProcessing:true,
            })
            var bodyFormData = new FormData();
            var token = localStorage.getItem('token');
            var ctoken = localStorage.getItem('ctoken');

            bodyFormData.append('s_id', this.state.currentSchduleId);
            bodyFormData.append('jham_token', token);
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


            axios({
                  method: 'post',
                  url: 'https://api.earental.com/lessor/approve_schedule',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {
                if (response.data.error > 0) {
                  if (response.data.error_type == "validation_error") {
                    function ErrorMsg(props) {
                      return (
                        props.msgs.map((item, i) =>
                          <li key={"err-" + item.name}>{item.value}</li>
                        )
                      );
                    }
                    store.addNotification({
                      title: "Error",
                      message: <ErrorMsg msgs={response.data.error_message} />,
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 20000,
                        onScreen: false
                      }
                    });
                  } else if (response.data.error_type == "payment_error") {
                    store.addNotification({
                      title: "Error",
                      message: response.data.error_message,
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 20000,
                        onScreen: false
                      }
                    });
                  }
                  this.setState({
                    openApproveConfirm: false,
                    currentSchduleId: false,
                    // sbMessage: response.data.error_message,
                    // sbstatus: 'error',
                    // openSb: true,
                    actionProcessing: false,
                  });
                } else {
                  this.componentDidMount();
                  this.setState({
                    openApproveConfirm: false,
                    currentSchduleId: false,
                    sbMessage: "Schedule successfully approved.",
                    sbstatus: 'success',
                    openSb: true,
                    actionProcessing: false,
                  })
                }
                renderFlag = true;
              })
              .catch((error) => {
                  //handle error
                  console.log(error);

            });

        }else{
            this.setState({
                sbstatus:'success',
                openApproveConfirm:false,
                currentSchduleId: false,
                actionProcessing:false,
            })
        }
    }


    handleClickCancel(sid){
        this.setState({
          openCancelConfirm:true,
          currentSchduleId:sid,
          currentTab: 0
        })
    }

    handleClickCancelPending(sid){
        this.setState({
          openCancelConfirm:true,
          currentSchduleId:sid,
          currentTab: 1
        })
    }

    handleClickRejectPending(rowData){
        this.setState({
          openRejectConfirm:true,
          currentSchduleId:rowData.s_id,
          currentRow: rowData,
          currentTab: 1
        })
    }

    handleClickApprove(rowData){
        this.setState({
          openApproveConfirm:true,
          currentSchduleId:rowData.s_id,
          currentTab:1
        })
    }


  componentDidMount() {

      var ctoken = localStorage.getItem('ctoken');
      var token = localStorage.getItem('token');
      var bodyFormData = new FormData();
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', this.state.offsetActive);
      bodyFormData.append('active', "Active");
      bodyFormData.append('order', this.state.currentSortField);
      bodyFormData.append('orderby', this.state.currentSort);


      axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/lessor_schedule',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
              var ongoingRental = [];
              if(response.data.ongoingrental !== null){
                  ongoingRental = response.data.ongoingrental;
              }else{
                  this.setState({noResult:true});
              }

              this.setState({
                totalOngoinRentalActive:response.data.lessor_ongoing_rental_total,
                ongoingRentalActive:ongoingRental,
                totalPagesActive:response.data.total_pages,
                totalRentsActive:response.data.lessor_ongoing_rental_total,
                tableLoading: false,
                cancellationProcessingFee: response.data.company_cancellation_processing_fee,
                cancellationFee: response.data.company_cancellation_fee,
                deposit: response.data.lessor_deposit,
              })
              renderFlag = true;


          })
          .catch((error) => {


      });

      var bodyFormData = new FormData();
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', this.state.offsetPending);
      bodyFormData.append('active', "Active");
      bodyFormData.append('pending_only', "1");
      bodyFormData.append('order', this.state.currentSortField);
      bodyFormData.append('orderby', this.state.currentSort);


      axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/lessor_schedule',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {

              var ongoingRental = [];
              if(response.data.ongoingrental !== null){
                  ongoingRental = response.data.ongoingrental;
              }else{
                  this.setState({noResult:true});
              }

              this.setState({
                totalOngoinRentalPending:response.data.lessor_ongoing_rental_total,
                ongoingRentalPending:ongoingRental,
                totalPagesPending:response.data.total_pages,
                totalRentsPending:response.data.lessor_ongoing_rental_total,
                cancellationProcessingFee: response.data.company_cancellation_processing_fee,
                cancellationFee: response.data.company_cancellation_fee,
                deposit: response.data.lessor_deposit,
              })

          })
          .catch((error) => {

      });


  }


  handleSearch(keyword,e) {
    this.setState({
        keyword:keyword,
    })
    if(this.state.timeout) clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(() => {
        this.searchList();
    }, 300);


  }

  searchList() {

      var ctoken = localStorage.getItem('ctoken');
      var token = localStorage.getItem('token');
      var bodyFormData = new FormData();
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', this.state.offsetActive);
      bodyFormData.append('keyword', this.state.keyword);
      bodyFormData.append('active', "Active");


      axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/lessor_schedule',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {

              var ongoingRental = [];
              if(response.data.ongoingrental !== null){
                  ongoingRental = response.data.ongoingrental;
              }else{
                  this.setState({noResult:true});
              }

              this.setState({
                totalOngoinRentalActive:response.data.lessor_ongoing_rental_total,
                ongoingRentalActive:ongoingRental,
                totalPagesActive:response.data.total_pages,
                offsetActive:0,
                currentPageActive:0,
              })
          })
          .catch((error) => {


      });

  }

  handlePageChangedActive(newPage) {
      this.setState({ currentPageActive : newPage });

      var ctoken = localStorage.getItem('ctoken');
      var token = localStorage.getItem('token');
      var currentOffset = this.state.limit * newPage;
      var bodyFormData = new FormData();
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', currentOffset);
      bodyFormData.append('active', "Active");
      bodyFormData.append('order', this.state.currentSortField);
      bodyFormData.append('orderby', this.state.currentSort);


      axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/lessor_schedule',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
              if(response.data.error > 0){
                  store.addNotification({
                    title: "Session expire",
                    message: response.data.error_msg,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: false
                    }
                  });
              }else{

                  var ongoingRentalActive = [];
                  if(response.data.ongoingrental !== null){
                      ongoingRentalActive = response.data.ongoingrental;
                  }else{
                      this.setState({noResult:true});
                  }

                  this.setState({
                      totalOngoinRentalActive:response.data.lessor_ongoing_rental_total,
                      ongoingRentalActive:ongoingRentalActive,
                      offsetActive: currentOffset,
                      currentTab:0
                  })

                  renderFlag = true;
              }
          })
          .catch((error) => {
              //handle error
              this.setState({ loading:false });
              if (error.response.status == 403) {

                  store.addNotification({
                    title: "Oops Something went wrong",
                    message: "The action you have requested is not Activeowed. please re-load the page",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: false
                    }
                  });

             }

      });
  }

  handlePageChangedPending(newPage) {
      this.setState({ currentPagePending : newPage });

      var ctoken = localStorage.getItem('ctoken');
      var token = localStorage.getItem('token');
      var currentOffset = this.state.limit * newPage;
      var bodyFormData = new FormData();
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', currentOffset);
      bodyFormData.append('active', "Active");
      bodyFormData.append('pending_only', "1");
      bodyFormData.append('order', this.state.currentSortField);
      bodyFormData.append('orderby', this.state.currentSort);


      axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/lessor_schedule',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
              if(response.data.error > 0){
                  store.addNotification({
                    title: "Session expire",
                    message: response.data.error_msg,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: false
                    }
                  });
              }else{

                  var ongoingRentalPending= [];
                  if(response.data.ongoingrental !== null){
                      ongoingRentalPending = response.data.ongoingrental;
                  }else{
                      this.setState({noResult:true});
                  }

                  this.setState({
                      totalOngoinRentalPending:response.data.lessor_ongoing_rental_total,
                      ongoingRentalPending:ongoingRentalPending,
                      offsetActive: currentOffset,
                      currentTab:1

                  })

                  renderFlag = true;
              }
          })
          .catch((error) => {
              //handle error
              this.setState({ loading:false });
              if (error.response.status == 403) {

                  store.addNotification({
                    title: "Oops Something went wrong",
                    message: "The action you have requested is not Activeowed. please re-load the page",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: false
                    }
                  });

             }

      });
  }

  handleRejectReasonChange = (event) => {
    this.setState({
      rejectReason: event.target.value,
      rejectDocument: null,
    });
  }

  handleRejectDocUpload = (event) => {
    console.log(event.target.files[0]);
    this.setState({
      rejectDocument: event.target.files[0],
    });
  }

  handleRejectPaymentDialogClose = () => {
    this.setState({
      openRejectPayment: false,
      rejectReason: "urgent_maintenance",
      rejectDocument: null,
    });
  };

  handleScheduleCancelled = () => {
    this.setState({
      openRejectPayment: false,
      rejectReason: "urgent_maintenance",
      rejectDocument: null,
    });
    this.componentDidMount();
  };

  render() {
      function GridTable(props) {
          const classes = useStyles();
          const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)


          };

        //   const totalPage = props.totalPages;
          const onChangePage = props.onChangePage;
        //   const currentPage = props.currentPage;
          const handleClickCancel = props.handleClickCancel;
          const handleClickApprove = props.handleClickApprove;
          const handleClickReject = props.handleClickReject;
        //   const handleSearch = props.handleSearch;

          const [totalPage, setTotalPage] = useState(props.totalPages);
          const [currentPage, setCurrentPage] = useState(props.currentPage);
          const [currentRental, setCurrentRental] = useState(props.rentals);
          const [currentInvoice, setCurrentInvoice] = useState(null);
          const [openInvoice, setOpenInvoice] = useState(null);

          const handleOpenInvoice = (e,row) => {
              console.log(row);
              setOpenInvoice(true);
              var bodyFormData = new FormData();
              var token = localStorage.getItem('token');
              var ctoken = localStorage.getItem('ctoken');
            //   var ctoken = "e52fedd02acc588f4dd2a9e334903564";

              bodyFormData.append('s_id', row.s_id);
              bodyFormData.append('jham_token', token);
              bodyFormData.append('access_token', ctoken);
              bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


              axios({
                    method: 'post',
                    url: 'https://api.earental.com/lessor/lessor_schedule_details',
                    data: bodyFormData,
                    withCredentials: true,
                    headers: {'Content-Type': 'multipart/form-data' }
                })
                .then((response) => {
                   if(response.data.error > 0){

                       store.addNotification({
                         title: "Oops!",
                         message: "Something went wrong, please try again.",
                         type: "danger",
                         insert: "top",
                         container: "top-right",
                         animationIn: ["animated", "fadeIn"],
                         animationOut: ["animated", "fadeOut"],
                         dismiss: {
                           duration: 5000,
                           onScreen: false
                         }
                       });

                   }else{
                      setCurrentInvoice(response.data.data);

                   }

                })
                .catch((error) => {
                    //handle error
                    console.log(error);

              });
          };



          const handleCloseInvoice = (e) => {
              setCurrentInvoice(false)
              setOpenInvoice(false);
          };
          var action = [
               {
                   icon: () =>  <DeleteOutline  style={{fill: "red"}} title="test" />,
                   tooltip: <Typography>Cancel Schedule</Typography>,
                   onClick: (event, rowData) => handleClickCancel(rowData)
               }
           ];

           if(props.type == 1){
               action = [
                    {
                        icon: () =>  <CheckCircleOutlinedIcon style={{fill: "green"}} />,
                        tooltip: <Typography>Approve Schedule</Typography>,
                        onClick: (event, rowData) => handleClickApprove(rowData)
                    },
                    {
                        icon: () =>  <CancelOutlinedIcon style={{fill: "red"}}/>,
                        tooltip: <Typography>Reject Schedule</Typography>,
                        onClick: (event, rowData) => handleClickReject(rowData)
                    }
                ];
           }

           if(props.type == 2){
               action = [
                    {
                        icon: () =>  <EventAvailableOutlinedIcon style={{fill: "green"}} />,
                        tooltip: <Typography>Successful Delivery/Collection</Typography>,
                        onClick: (event, rowData) => handleClickApprove(rowData)
                    },
                    {
                        icon: () =>  <EventBusyOutlinedIcon style={{fill: "red"}}/>,
                        tooltip: <Typography>Change/Cancel Order</Typography>,
                        onClick: (event, rowData) => handleClickReject(rowData)
                    }
                ];
           }
          const currentSort = props.currentSort;
          return (
              <>
                  {(currentInvoice) &&
                      <Dialog
                          fullWidth={true}
                          maxWidth = {'md'}
                          open={openInvoice}
                          onClose={handleCloseInvoice}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                            <Grid container >
                                <Grid item md={6} sm={6} xs={6}>
                                    {currentInvoice.s_id}
                                </Grid>
                                <Grid item md={6} sm={6} xs={6} align="right">
                                    <p style={{fontSize:"15px",fontWeight:"300"}}>Transaction Date: {moment(currentInvoice.s_date_added).format('lll')}</p>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={3} className={classes.newCardForm}>
                                <Grid item md={6} sm={6} xs={6}>
                                    <p><AccountCircleOutlinedIcon /> <b>Lessee Information</b></p>
                                </Grid>
                                <Grid item md={6} sm={6} xs={6} align="right">
                                    {(currentInvoice.billing_purpose == "business") &&
                                        <Button startIcon={<PictureAsPdfIcon />} variant="outlined" color="primary" href={"https://api.earental.com/uploads/customer-address/"+currentInvoice.billing_company_acra} target="_blank">
                                        ACRA
                                        </Button>
                                    }
                                </Grid>
                            </Grid>
                            <Divider />
                            <br />
                            <Grid container spacing={3} className={classes.newCardForm}>

                                {(currentInvoice.billing_company) &&
                                    <>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <TextField
                                                label="Company Name"
                                                type="text"
                                                value={currentInvoice.billing_company}
                                                fullWidth


                                             />
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <TextField
                                                label="ROC"
                                                type="text"
                                                value={currentInvoice.billing_roc}
                                                fullWidth


                                             />
                                        </Grid>
                                    </>

                                }

                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="First Name"
                                        type="text"
                                        value={currentInvoice.billing_fname}
                                        fullWidth


                                     />
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="Last Name"
                                        type="text"
                                        value={currentInvoice.billing_lname}
                                        fullWidth


                                     />
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="NRIC"
                                        type="text"
                                        value={currentInvoice.billing_nric}
                                        fullWidth

                                     />
                                </Grid>


                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="Email"
                                        type="text"
                                        value={currentInvoice.customer_email}
                                        fullWidth

                                     />
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="Phone No."
                                        type="text"
                                        value={currentInvoice.billing_telephone}
                                        fullWidth

                                     />
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="Mobile No."
                                        type="text"
                                        value={currentInvoice.billing_mobile}
                                        fullWidth

                                     />
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <TextField
                                        label="Address"
                                        type="text"
                                        value={currentInvoice.billing_address}
                                        fullWidth

                                     />
                                </Grid>
                                {(currentInvoice.billing_purpose == "personal") &&
                                    <>
                                        <Grid item md={6} sm={6} xs={6} >
                                            <div className="clearfix">
                                                <div className="">
                                                    <CardMedia
                                                      className={classes.media}
                                                      image={"https://api.earental.com/uploads/profile/"+currentInvoice.billing_nric_front_img}
                                                    />
                                                    <label>NRIC Front</label>
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item md={6} sm={6} xs={6} >
                                            <div className="clearfix">
                                                <div className="">
                                                    <CardMedia
                                                      className={classes.media}
                                                      image={"https://api.earental.com/uploads/profile/"+currentInvoice.billing_nric_back_img}
                                                    />
                                                    <label>NRIC Back</label>
                                                </div>
                                            </div>
                                        </Grid>
                                    </>
                                }
                            </Grid>

                            <br />
                            <br />
                            <p><FaceOutlinedIcon /> <b>First Driver Information</b></p>
                            <Divider />
                            <br />

                            <Grid container spacing={3} className={classes.newCardForm}>
                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="Full Name"
                                        type="text"
                                        value={currentInvoice.cd_driver_name1}
                                        fullWidth


                                     />
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="NRIC"
                                        type="text"
                                        value={currentInvoice.cd_nric1}
                                        fullWidth


                                     />
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="License No."
                                        type="text"
                                        value={currentInvoice.cd_driver_licenseno1}
                                        fullWidth


                                     />
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="Contact No."
                                        type="text"
                                        value={currentInvoice.cd_mobile_no1}
                                        fullWidth


                                     />
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="Date of birth"
                                        type="text"
                                        value={currentInvoice.cd_birthdate1}
                                        fullWidth


                                     />
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="Driving Exp"
                                        type="text"
                                        value={currentInvoice.cd_driver_yearexp1}
                                        fullWidth


                                     />
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <TextField
                                        label="Address"
                                        type="text"
                                        value={currentInvoice.cd_address1}
                                        fullWidth


                                     />
                                </Grid>

                                <Grid item md={6} sm={6} xs={6} >
                                    <div className="clearfix">
                                        <div className="">
                                            <CardMedia
                                              className={classes.media}
                                              image={"https://api.earental.com/uploads/profile/"+currentInvoice.cd_photo_nricfront1}
                                            />
                                            <label>NRIC Front</label>
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item md={6} sm={6} xs={6} >
                                    <div className="clearfix">
                                        <div className="">
                                            <CardMedia
                                              className={classes.media}
                                              image={"https://api.earental.com/uploads/profile/"+currentInvoice.cd_photo_nricback1}
                                            />
                                            <label>NRIC Back</label>
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item md={6} sm={6} xs={6} >
                                    <div className="clearfix">
                                        <div className="">
                                            <CardMedia
                                              className={classes.media}
                                              image={"https://api.earental.com/uploads/profile/"+currentInvoice.cd_photo_driverlicfront1}
                                            />
                                            <label>License Front</label>
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item md={6} sm={6} xs={6} >
                                    <div className="clearfix">
                                        <div className="">
                                            <CardMedia
                                              className={classes.media}
                                              image={"https://api.earental.com/uploads/profile/"+currentInvoice.cd_photo_driverlicback1}
                                            />
                                            <label>License Back</label>
                                        </div>
                                    </div>
                                </Grid>

                            </Grid>
                            {(currentInvoice.cd_driver_name2) &&
                                <>
                                    <br />
                                    <br />
                                    <p><FaceOutlinedIcon />  <b>Second Driver Information</b></p>
                                    <Divider />
                                    <br />

                                    <Grid container spacing={3} className={classes.newCardForm}>
                                        <Grid item md={4} sm={12} xs={12}>
                                            <TextField
                                                label="Full Name"
                                                type="text"
                                                value={currentInvoice.cd_driver_name2}
                                                fullWidth


                                             />
                                        </Grid>
                                        <Grid item md={4} sm={12} xs={12}>
                                            <TextField
                                                label="NRIC"
                                                type="text"
                                                value={currentInvoice.cd_nric2}
                                                fullWidth


                                             />
                                        </Grid>
                                        <Grid item md={4} sm={12} xs={12}>
                                            <TextField
                                                label="License No."
                                                type="text"
                                                value={currentInvoice.cd_driver_licenseno2}
                                                fullWidth


                                             />
                                        </Grid>
                                        <Grid item md={4} sm={12} xs={12}>
                                            <TextField
                                                label="Contact No."
                                                type="text"
                                                value={currentInvoice.cd_mobile_no2}
                                                fullWidth


                                             />
                                        </Grid>
                                        <Grid item md={4} sm={12} xs={12}>
                                            <TextField
                                                label="Date of birth"
                                                type="text"
                                                value={currentInvoice.cd_birthdate2}
                                                fullWidth


                                             />
                                        </Grid>
                                        <Grid item md={4} sm={12} xs={12}>
                                            <TextField
                                                label="Driving Exp"
                                                type="text"
                                                value={currentInvoice.cd_driver_yearexp2}
                                                fullWidth


                                             />
                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <TextField
                                                label="Address"
                                                type="text"
                                                value={currentInvoice.cd_address2}
                                                fullWidth


                                             />
                                        </Grid>

                                        <Grid item md={6} sm={6} xs={6} >
                                            <div className="clearfix">
                                                <div className="">
                                                    <CardMedia
                                                      className={classes.media}
                                                      image={"https://api.earental.com/uploads/profile/"+currentInvoice.cd_photo_nricfront2}
                                                    />
                                                    <label>NRIC Front</label>
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item md={6} sm={6} xs={6} >
                                            <div className="clearfix">
                                                <div className="">
                                                    <CardMedia
                                                      className={classes.media}
                                                      image={"https://api.earental.com/uploads/profile/"+currentInvoice.cd_photo_nricback2}
                                                    />
                                                    <label>NRIC Back</label>
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item md={6} sm={6} xs={6} >
                                            <div className="clearfix">
                                                <div className="">
                                                    <CardMedia
                                                      className={classes.media}
                                                      image={"https://api.earental.com/uploads/profile/"+currentInvoice.cd_photo_driverlicfront2}
                                                    />
                                                    <label>License Front</label>
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item md={6} sm={6} xs={6} >
                                            <div className="clearfix">
                                                <div className="">
                                                    <CardMedia
                                                      className={classes.media}
                                                      image={"https://api.earental.com/uploads/profile/"+currentInvoice.cd_photo_driverlicback2}
                                                    />
                                                    <label>License Back</label>
                                                </div>
                                            </div>
                                        </Grid>

                                    </Grid>
                                </>
                            }

                            <br />
                            <br />
                            <p><b><DateRangeOutlinedIcon /> Rental Information</b></p>
                            <Divider />
                            <br />

                            <Grid container spacing={3} className={classes.newCardForm}>
                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="Category"
                                        type="text"
                                        value={currentInvoice.category_name}
                                        fullWidth


                                     />
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="Vehicle Name"
                                        type="text"
                                        value={currentInvoice.product_name}
                                        fullWidth


                                     />
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="Plate No."
                                        type="text"
                                        value={currentInvoice.product_platenumber}
                                        fullWidth


                                     />
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="Start Date"
                                        type="text"
                                        value={currentInvoice.s_start_date}
                                        fullWidth


                                     />
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="End Date"
                                        type="text"
                                        value={currentInvoice.s_end_date}
                                        fullWidth


                                     />
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="Term"
                                        type="text"
                                        value={currentInvoice.product_rent_terms}
                                        fullWidth


                                     />
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="Rental Type"
                                        type="text"
                                        value={currentInvoice.s_payment_mode}
                                        fullWidth


                                     />
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="Rate"
                                        type="text"
                                        value={currentInvoice.s_lease_rate}
                                        fullWidth


                                     />
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <TextField
                                        label="Deposit Amount"
                                        type="text"
                                        value={currentInvoice.s_deposit_amount}
                                        fullWidth


                                     />
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                            <Grid container spacing={3} className={classes.newCardForm}>
                              <Grid item md={12} sm={12} xs={12} align="right">
                                  <Button variant="contained"
                                    size="large"
                                    color="default"
                                    target="_blank"
                                    href={"https://api.earental.com/lessor/view_agreement/lease/"+currentInvoice.s_id+"/"+currentInvoice.vo_id}>
                                    signed Lease agreement
                                  </Button>
                                  &nbsp;
                                  &nbsp;
                                  <Button variant="contained"
                                    size="large"
                                    color="default"
                                    target="_blank"
                                    href={"https://api.earental.com/lessor/view_agreement/insurance/"+currentInvoice.s_id+"/"+currentInvoice.vo_id}>
                                    signed Insurance Agreement
                                  </Button>
                              </Grid>
                          </Grid>
                          <br />
                          <br />
                          <p><b><AddShoppingCartOutlinedIcon /> Rental Addons</b></p>
                          <Divider />
                          <br />
                          {!currentInvoice.sa_items && (
                              <Typography>No Addons availed</Typography>
                          )}
                          {currentInvoice.sa_items && (
                              <Grid container spacing={3} className={classes.newCardForm}>
                                  {currentInvoice.sa_items.map((item,i) => {
                                      return (
                                          <>
                                              <Grid item md={4} sm={12} xs={12}>
                                                  <TextField
                                                      label="Item"
                                                      type="text"
                                                      value={item.trim()}
                                                      fullWidth


                                                   />
                                              </Grid>
                                              <Grid item md={4} sm={12} xs={12}>
                                                  <TextField
                                                      label="Quantity"
                                                      type="text"
                                                      value={currentInvoice.sa_qtys[i].trim()}
                                                      fullWidth


                                                   />
                                              </Grid>
                                              <Grid item md={4} sm={12} xs={12}>
                                                  <TextField
                                                      label="Price"
                                                      type="text"
                                                      value={currentInvoice.sa_amounts[i].trim()}
                                                      fullWidth


                                                   />
                                              </Grid>
                                          </>
                                      );

                                  })}

                              </Grid>
                          )}



                        </DialogContent>
                        <DialogActions>
                            <>
                                <Button variant="contained" size="large"  color="primary" onClick={handleClickReject.bind(this,currentInvoice)}>
                                  Reject
                                </Button>
                                <Button variant="contained" size="large" color="primary" onClick={handleClickApprove.bind(this,currentInvoice)} >
                                    Approve
                                </Button>
                            </>

                        </DialogActions>
                      </Dialog>
                  }
                  <div className="schedule-table">
                     <MaterialTable
                       icons={tableIcons}
                       title={props.title}
                       isLoading={props.isLoading}
                       onOrderChange={(orderedColumnField, orderDirection) => {
                          props.handleSortChange(orderedColumnField,orderDirection)
                        }}
                       columns={[
                         {
                           title: 'ID', field: 's_id',
                           render: row => <Chip onClick={() => handleOpenInvoice(this,row)} variant="outlined" label={row.s_id} />
                         },
                         { title: 'Invoice No.', field: 'order_invoice_no' },
                         { title: 'Name',
                             field: 'customer_fullname',
                             render: row => <span>{row.billing_company || (row.billing_fname+" "+row.billing_lname)}</span>
                         },
                         { title: 'Category', field: 'category_name' },
                         { title: 'Vehicle', field: 'product_name' },
                         { title: 'Plate No.', field: 'product_platenumber'},
                         { title: 'Term', field: 'product_rent_terms' },
                         { title: 'Start Rent', field: 's_date_added'},
                         { title: 'End Rent', field: 's_end_date'},
                         {
                           title: 'Lease Rate',
                           type: 'numeric',
                           field: 's_lease_rate'
                         },
                         {
                           title: 'Deposit Amount',
                           type: 'numeric',
                           field: 's_deposit_amount'
                         },
                         {
                           title: 'Receivable Amount',
                           type: 'numeric',
                           field: 's_payable_amount'
                         }
                       ]}
                       data={currentRental}
                       actions={action}
                       options={{
                        sorting: false,
                        thirdSortClick: false,
                        draggable:false,
                        search:false,
                        pageSize: 10,
                        selection: false,
                        paging:false,
                        pageSizeOptions : [10,50,100]
                       }}
                     />
                     </div>
              </>


          );
        }

        function Alert(props) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }



    return (
        <div className="schedule-cont">
            <div className="">
                <br />
                <div className="row">
                    <div className="col-md-12">

                        <Dialog
                          open={this.state.openCancelConfirm}
                          onClose={this.handleCloseCancel.bind(this,false)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{"You're about to cancel a schedule"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Are you sure you want to proceed on cancellation of this schedule #{this.state.currentSchduleId}?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button variant="contained" size="large" onClick={this.handleCloseCancel.bind(this,false)} color="primary">
                              No
                            </Button>
                            <Button variant="contained" size="large" onClick={this.handleCloseCancel.bind(this,true)} color="primary" autoFocus>
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>

                        <Dialog
                          open={this.state.openApproveConfirm}
                          onClose={this.handleCloseApprove.bind(this,false)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{"Congratulations! You are about to approve a new lease"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Do you wish to proceed?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            {!this.state.actionProcessing && (
                                <>
                                    <Button variant="contained" size="large" onClick={this.handleCloseApprove.bind(this,false)} color="secondary">
                                      No
                                    </Button>
                                    <Button variant="contained" size="large" onClick={this.handleCloseApprove.bind(this,true)} color="primary" autoFocus>
                                      Yes
                                    </Button>
                                </>
                            )}

                            {this.state.actionProcessing && (
                                <Button variant="contained" size="large" color="primary"  startIcon={<CircularProgress size="1.5rem" />} autoFocus>
                                   Processing...
                                </Button>
                            )}

                          </DialogActions>
                        </Dialog>

                        <Dialog
                          open={this.state.openRejectConfirm}
                          onClose={this.handleCloseReject.bind(this,false)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">You're about to reject a schedule</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              <Typography variant="body1" gutterBottom>
                                Kindly select your reason for rejecting schedule #{this.state.currentSchduleId}
                              </Typography>
                              <Select variant="outlined" value={this.state.rejectReason} onChange={this.handleRejectReasonChange} fullWidth style={{ marginBottom: 8 }}>
                                <MenuItem value="urgent_maintenance">
                                  Urgent Maintenance (documentation proof required)
                                </MenuItem>
                                <MenuItem value="vehicle_not_available">
                                  Vehicle Not Available
                                </MenuItem>
                                <MenuItem value="lessee_request">
                                  Lessee Requested Cancellation
                                </MenuItem>
                              </Select>
                              {this.state.rejectReason == "urgent_maintenance" && (
                                <Box textAlign="right" marginBottom={1} display="flex" justifyContent="space-between" alignItems="center">
                                  <input
                                    ref={this.fileInputUrgentMaintenance}
                                    type="file"
                                    onChange={this.handleRejectDocUpload}
                                    style={{ display: "none" }}
                                  />
                                  <Typography variant="body1">
                                    {this.state.rejectDocument ? this.state.rejectDocument.name : ""}
                                    {this.state.rejectDocument && (
                                      <Button type="button" onClick={() => this.setState({ rejectDocument: null })} color="default" style={{
                                        minWidth: 0,
                                        borderRadius: '50%',
                                      }}><CloseIcon /></Button>
                                    )}
                                  </Typography>
                                  <Button type="button" variant="contained" onClick={() => this.fileInputUrgentMaintenance.current.click()} color="default">Upload Document</Button>
                                </Box>
                              )}
                              <Typography variant="body1"  gutterBottom>
                                Your comments will be seen by the lessee. Keep your comments to the issue
                              </Typography>
                              <HtmlTooltip
                                title={
                                  <React.Fragment>
                                    <Typography variant="body1">Writing Guidlines</Typography>
                                    <p>{'Your comments will be view by the lessee.'}</p>
                                    <p>{'1. Keep your comment focused on the issue.'}</p>
                                    <p>{'2. Be professional.'}</p>
                                    <p>{'3. Refrain from using vulgarity or inappropriate language.'}</p>
                                    <p>{'4. Do not include any personal contact information.'}</p>

                                  </React.Fragment>
                                }
                                arrow
                                placement="left"
                              >
                                  <TextField
                                    className="reason-text"
                                    label="Message"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    fullWidth={true}
                                    inputRef={this.rejectReason}
                                  />
                              </HtmlTooltip>
                              <Alert variant="outlined" severity="warning" fullWidth={true} elevation={0} style={{ marginTop: 16 }}>
                                Wrongfully reject a lease will result in $200 in cancellation fee. Do you wish to proceed?
                              </Alert>
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            {!this.state.actionProcessing && (
                              <React.Fragment>
                                <Button variant="contained" size="large" onClick={this.handleCloseReject.bind(this,false)} color="secondary">
                                  No
                                </Button>
                                <Button variant="contained" size="large" onClick={this.handleCloseReject.bind(this,true)} color="primary" autoFocus>
                                  Yes
                                </Button>
                              </React.Fragment>
                            )}

                            {this.state.actionProcessing && (
                              <Button variant="contained" size="large" color="primary" startIcon={<CircularProgress size="1.5rem" style={{ color: "#FFF" }} />} autoFocus>
                                Processing...
                              </Button>
                            )}
                          </DialogActions>
                        </Dialog>

                        <TextField id="standard-basic"
                            label="Search Keyword"
                            className="table-search"
                            value={this.state.keyword}
                            onChange={e => this.handleSearch(e.target.value)}
                            />
                            <GridTable
                                type="1"
                                title={'Pending Listing'}
                                rentals={this.state.ongoingRentalPending}
                                totalPages={this.state.totalPagesPending}
                                currentPage={this.state.currentPagePending}
                                onChangePage={this.handlePageChangedPending}
                                handleClickApprove={this.handleClickApprove}
                                handleClickCancel={this.handleClickCancelPending}
                                handleClickReject={this.handleClickRejectPending}
                                handleSortChange={this.handleSortChangePending}
                                currentSort={this.state.currentSort}
                                isLoading={this.state.tableLoading}
                            />

                    </div>
                </div>
            </div>

            <Snackbar open={this.state.openSb} autoHideDuration={6000} onClose={this.handleCloseSb}>
                <Alert onClose={this.handleCloseSb} severity={this.state.sbstatus} className="sb-msg">
                    {this.state.sbMessage}
                </Alert>
            </Snackbar>


            <div className="gap"></div>
            <RejectPayment open={this.state.openRejectPayment} onClose={this.handleRejectPaymentDialogClose} onScheduleCancelled={this.handleScheduleCancelled} sid={this.state.currentSchduleId} cancellationReason={this.rejectReason.current ? this.rejectReason.current.value : ""} rejectReason={this.state.rejectReason} rejectDocument={this.state.rejectDocument} cancellationFee={this.state.cancellationFee} cancellationProcessingFee={this.state.cancellationProcessingFee} deposit={this.state.deposit} />

        </div>
    );
  }
}

export default Schedule
