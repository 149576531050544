import React from "react";
import {
  NavLink,
  HashRouter,
  Redirect
} from "react-router-dom";
import axios from 'axios';
import { store } from 'react-notifications-component';
// import parallaxBg1 from './assets/img/196_365_2048x1365.jpg';
import { makeStyles } from '@material-ui/core/styles';
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import Image, { Shimmer } from 'react-shimmer'
import ContentLoader, { Facebook } from "react-content-loader";
import Pager from 'react-pager';
import ScriptTag from 'react-script-tag';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import noImage from './../assets/img/no-image.jpg';

// import Checkbox from './Checkbox';
import 'icheck/skins/all.css'; // or single skin css
import "../assets/css/vehicles.css";

import EnhancedSwitch from 'react-icheck/lib/EnhancedSwitch';
import {Checkbox, Radio} from 'react-icheck';
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";

import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CircularProgress from '@material-ui/core/CircularProgress';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Rating from '@material-ui/lab/Rating';
import EllipsisText from "react-ellipsis-text";

import { red,grey, orange } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import RadioMu from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import SearchIcon from '@material-ui/icons/Search';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import Chip from '@material-ui/core/Chip';
import NumberFormat from 'react-number-format';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import CheckIcon from '@material-ui/icons/Check';
import TodayIcon from '@material-ui/icons/Today';
import EditIcon from '@material-ui/icons/Edit';
import EventIcon from '@material-ui/icons/Event';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Divider from '@material-ui/core/Divider';
import FilterListIcon from '@material-ui/icons/FilterList';
import Hidden from '@material-ui/core/Hidden';
import Alert from '@material-ui/lab/Alert';
import ReactGA from 'react-ga';
import Badge from '@material-ui/core/Badge';
import Fab from '@material-ui/core/Fab';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';

const moment = extendMoment(originalMoment);

EnhancedSwitch.propTypes = {
  ...EnhancedSwitch.propTypes,
  cursor: PropTypes.string
}

// var parallaxBgStyle = {
//   backgroundImage: "url(" + parallaxBg1 + ")"
// };
const MyLoader = () => (
    <ContentLoader
        height={120}
        width={"100%"}
        speed={1}
    >
        <rect x="0" y="0" rx="5" ry="5" width="180" height="90" />
        <rect x="0" y="100" rx="5" ry="5" width="180" height="15" />
        <rect x="200" y="17" rx="4" ry="4" width="300" height="13" />
        <rect x="200" y="40" rx="4" ry="4" width="300" height="13" />
        <rect x="200" y="65" rx="4" ry="4" width="300" height="13" />
    </ContentLoader>
);

const useStyles = makeStyles((theme) => ({
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#d40029',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#d40029',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#d40029',
        },
    },
    newCardForm: {
    color: grey[800],
    '& .MuiFormControl-root': {
    '& .MuiFormLabel-root': {
      fontSize: '1.2rem',
      '&.Mui-focused': {
        color: grey[800],
        backgroundColor: 'white',
        paddingRight: 5,
      },
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      '&:hover fieldset': {
        borderColor: '#B4B4B4',
      },
      '&.Mui-focused fieldset': {
        borderColor: orange[600],
      },
      '& input': {
        fontSize: 20,
        font:'auto'
      },
    },
    },
}
}));
const date = new Date();

export default class VanRental extends React.Component {

    constructor(props) {
        super(props);
        ReactGA.set({ page: window.location.pathname });
        ReactGA.pageview(window.location.pathname);

        const today = moment();

        const values = queryString.parse(this.props.location.search)

        let endDate = values.end;
        let startDate = values.start;
        let term = values.term;
        var lType = values.type;
        var lCount = values.count;

        let startDateDaily = null;
        let startDateWeekend = null;
        let startDateWeekly = null;
        let startDateMonthly = null;

        if(lType == "daily"){
            startDateDaily = startDate;
            if(parseInt(lCount) > 0){
                lCount = parseInt(lCount);
            }else{
                lCount = 1;
            }

        }else if(lType == "weekend"){
            startDateWeekend = startDate;
            lCount = 1;
        }else if(lType == "weekly"){
            startDateWeekly = startDate;
            if(parseInt(lCount) > 0){
                lCount = parseInt(lCount);
            }else{
                lCount = 1;
            }
        }else if(lType == "monthly"){
            startDateMonthly = startDate;
            if(parseInt(lCount) > 0){
                lCount = parseInt(lCount);
            }else{
                lCount = 1;
            }

        }else if(lType == "1-year"){
            startDateMonthly = startDate;
            lCount = 1;
        }else if(lType == "2-years"){
            startDateMonthly = startDate;
            lCount = 1;
        }else{
            lType = "";
            lCount = 1;
        }


        var currentDate = props.mainState.currentDate;
        currentDate = moment(currentDate).add(1, 'days');

        if (typeof startDate === 'undefined' || startDate == "" || moment(startDate).isValid() == false){

            startDate = "";
        }else{
            startDate = new Date(startDate);
            startDate = moment(startDate).format("YYYY-MM-DD")
        }

        if (typeof endDate === 'undefined' || endDate == "" || moment(endDate).isValid() == false){
            endDate = "";
        }else{
            endDate = new Date(endDate);
            endDate = moment(endDate).format("YYYY-MM-DD")
        }










        var rental1Check = false;
        var rental2Check = false;
        if (typeof term === 'undefined' || term == ""){
            term = "";
        }else{
            if(term == "all"){
                term = "";
                rental1Check = true;
                rental2Check = true;
            }else if(term == "short"){
                term = "Short Term,";
                rental1Check = true;
            }else if(term =="long"){
                term = "Long Term,"
                rental2Check = true;
            }else{
                term = "";
            }
        }

        var prevFilters = localStorage.getItem('listing_filters');
        if(prevFilters){
            prevFilters = JSON.parse(prevFilters);
            this.state = {
                passToken:localStorage.getItem('token'),
                products: [],
                passengers:prevFilters.passengers,
                price: prevFilters.price,
                transmission: prevFilters.transmission,
                engine: prevFilters.engine,
                equipment: prevFilters.equipment,
                rental: prevFilters.rental,
                priceRange:prevFilters.priceRange,
                sliderMarks: [
                    {
                    value: 0,
                    label: 'S$0',
                    },
                    {
                    value: 250,
                    label: 'S$250',
                    },
                    {
                    value: 500,
                    label: 'S$500',
                    },
                ],
                priceFrom:prevFilters.priceFrom,
                priceTo:prevFilters.priceTo,
                passengers1: prevFilters.passengers1,
                passengers2: prevFilters.passengers2,
                passengers3: prevFilters.passengers3,
                passengers4: prevFilters.passengers4,
                transmission1: prevFilters.transmission1,
                transmission2: prevFilters.transmission2,
                engine1:prevFilters.engine1,
                engine2:prevFilters.engine2,
                engine3:prevFilters.engine3,
                engine4:prevFilters.engine4,
                equipment1:prevFilters.equipment1,
                equipment2:prevFilters.equipment2,
                equipment3:prevFilters.equipment3,
                equipment4:prevFilters.equipment4,
                equipment5:prevFilters.equipment5,
                equipment6:prevFilters.equipment6,
                equipment7:prevFilters.equipment7,
                equipment8:prevFilters.equipment8,
                rental1: prevFilters.rental1,
                rental2: prevFilters.rental2,
                minDate: new Date(currentDate),
                startDate: startDate,
                endDate: endDate,
                startDate2: startDate,
                endDate2: endDate,
                timePick:'10:00',
                timeDrop:'10:00',
                startDateQuery:moment().format("YYYY-MM-DD"),
                endDateQuery: moment().format("YYYY-MM-DD"),
                limit:10,
                totalPage:0,
                currentPage:prevFilters.currentPage,
                visiblePage:10,
                offset:prevFilters.offset,
                hideclass:'',
                noResult: false,
                resultTotal: 0,
                isOpenRangeListing: false,
                valueRangeListing: [new Date(startDate),new Date(endDate)],
                lType:lType,
                lCount:lCount,
                startDateDaily:startDateDaily,
                startDateWeekend:startDateWeekend,
                startDateWeekly:startDateWeekly,
                startDateMonthly:startDateMonthly,
                drawerOpen: false
            };
        }else{
            this.state = {
                passToken:localStorage.getItem('token'),
                products: [],
                passengers:[],
                price: '',
                transmission: '',
                engine: '',
                equipment: '',
                rental: term,
                priceRange:[0,500],
                sliderMarks: [
                    {
                    value: 0,
                    label: 'S$0',
                    },
                    {
                    value: 250,
                    label: 'S$250',
                    },
                    {
                    value: 500,
                    label: 'S$500',
                    },
                ],
                priceFrom:0,
                priceTo:500,
                passengers1: false,
                passengers2: false,
                passengers3: false,
                passengers4: false,
                transmission1: false,
                transmission2: false,
                engine1:false,
                engine2:false,
                engine3:false,
                engine4:false,
                equipment1:false,
                equipment2:false,
                equipment3:false,
                equipment4:false,
                equipment5:false,
                equipment6:false,
                equipment7:false,
                equipment8:false,
                rental1: rental1Check,
                rental2: rental2Check,
                minDate: new Date(currentDate),
                startDate: startDate,
                endDate: endDate,
                startDate2: startDate,
                endDate2: endDate,
                timePick:'10:00',
                timeDrop:'10:00',
                startDateQuery:moment(currentDate).format("YYYY-MM-DD"),
                endDateQuery: moment(currentDate).format("YYYY-MM-DD"),
                limit:10,
                totalPage:0,
                currentPage:0,
                visiblePage:10,
                offset:0,
                hideclass:'',
                noResult: false,
                resultTotal: 0,
                isOpenRangeListing: false,
                valueRangeListing: [new Date(startDate),new Date(endDate)],
                lType:lType,
                lCount:lCount,
                startDateDaily:startDateDaily,
                startDateWeekend:startDateWeekend,
                startDateWeekly:startDateWeekly,
                startDateMonthly:startDateMonthly,
                drawerOpen: false
            };
        }


        // this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePageChanged = this.handlePageChanged.bind(this);
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
        this.handleChangeTimePick = this.handleChangeTimePick.bind(this);
        this.handleChangeTimeDrop = this.handleChangeTimeDrop.bind(this);
        this.handleInputChangePassenger = this.handleInputChangePassenger.bind(this);
        this.handleInputChangeTransmission = this.handleInputChangeTransmission.bind(this);
        this.handleInputChangeEngine = this.handleInputChangeEngine.bind(this);
        this.handleInputChangeEquipment = this.handleInputChangeEquipment.bind(this);
        this.handleInputChangeRental = this.handleInputChangeRental.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectVehicle = this.handleSelectVehicle.bind(this);
        this.sliderValue = this.sliderValue.bind(this);
        this.handleSliderChange = this.handleSliderChange.bind(this);
        this.handleChangeRange = this.handleChangeRange.bind(this);
        this.onToggleRangeListing = this.onToggleRangeListing.bind(this);
        this.onSelectRangeListing = this.onSelectRangeListing.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);

    }

    toggleDrawer = (status, e) => {
        this.setState({
          drawerOpen:status,
        })
    };

    onSelectRangeListing = (value, states) => {

      this.setState({ valueRangeListing:value });

      this.setState({
          startDate2:value.start.format("YYYY-MM-DD"),
          startDateQuery:value.start.format("YYYY-MM-DD"),
          endDate2:value.end.format("YYYY-MM-DD"),
          endDateQuery:value.end.format("YYYY-MM-DD"),
          isOpenRangeListing: false,
      })


      this.props.history.push("/vehicles/van-rental?start="+this.state.startDateQuery+"&end="+this.state.endDateQuery+"&term="+this.state.rental);

      this.setState({ loadingclass:'' });
      this.setState({ hideClass:'isHidden' });
      this.setState({ currentPage : 0 });
      setTimeout(() => {
          this.searchList();
      }, 500);

    };

    onSelectRangeProduct = (product, start, end,type,count) => {
        this.setState({
          startDate2:start,
          startDateQuery:start,
          endDate2:end,
          endDateQuery:end,
          lType:type,
          lCount:count
        })


        this.props.history.push("/vehicles/van-rental?start="+start+"&end="+end+"&type="+type+"&count="+count);

        localStorage.removeItem("currrent_vehicle");
        localStorage.setItem('listing_filters', JSON.stringify(this.state));
        var productName = product.product_name.replace(/\s+/g, '-').toLowerCase();
        this.props.history.push({
          pathname: '/vehicle-details/'+productName,
          state: { detail: product,start: start,end: end,lType:type,lCount:count }
        })



    };

    onToggleRangeListing = () => {
      this.setState({ isOpenRangeListing: !this.state.isOpenRangeListing });
      window.scrollTo(0, 0);
    };

    renderSelectionValueListing = () => {
      return (
        <>
            <Chip
                icon={<TodayIcon />}
                label={this.state.startDate2}
                style={{fontSize:"13px"}}
                title="Start Date"
            />
            &nbsp;
            -
            &nbsp;
            <Chip
                icon={<EventIcon />}
                label={this.state.endDate2}
                style={{fontSize:"13px"}}
                title="Return Date"
            />

        </>
      );
    };

    sliderValue(value){
        return 'S$'+value;
    }

    handleChangeRange(value){

    }

    handleSliderChange = (event, newValue) => {
        this.setState({
          priceRange: newValue
        })
    };
    handleSliderChangeCommitted = (event, newValue) => {

        this.setState({
            priceFrom: newValue[0] ,
            priceTo: newValue[1] ,
        });
        this.setState({ loadingclass:'' });
        this.setState({ hideClass:'isHidden' });
        this.setState({ currentPage : 0 });
        setTimeout(() => {
            this.searchList();
        }, 500);
    };

    handleSelectVehicle(product,e) {
        localStorage.removeItem("currrent_vehicle");
        var productName = product.product_name.replace(/\s+/g, '-').toLowerCase();
        var endDate = this.state.endDate2;
        if(this.state.lType == "monthly"){
            if(product.month_bonus == "1" && product.product_month_bonus_rental_fee == "1"){
                if(product.product_month_bonus_type == "days"){
                    endDate = moment(endDate).add(parseInt(product.product_month_bonus_days_months_count), 'days').format("YYYY-MM-DD");
                }else{
                    endDate = moment(endDate).add(parseInt(product.product_month_bonus_days_months_count), 'months').format("YYYY-MM-DD");
                }
            }
        }else if(this.state.lType == "1-year"){
            if(product.year_bonus == "1" && product.product_year_bonus_rental_fee == "1"){
                if(product.product_year_bonus_type == "days"){
                    endDate = moment(endDate).add(parseInt(product.product_year_bonus_days_months_count), 'days').format("YYYY-MM-DD");
                }else{
                    endDate = moment(endDate).add(parseInt(product.product_year_bonus_days_months_count), 'months').format("YYYY-MM-DD");
                }
            }
        }else if(this.state.lType == "2-years"){
            if(product.year2_bonus == "1" && product.product_year2_bonus_rental_fee == "1"){
                if(product.product_year2_bonus_type == "days"){
                    endDate = moment(endDate).add(parseInt(product.product_year2_bonus_days_months_count), 'days').format("YYYY-MM-DD");
                }else{
                    endDate = moment(endDate).add(parseInt(product.product_year2_bonus_days_months_count), 'months').format("YYYY-MM-DD");
                }
            }
        }

        this.setState({
          endDate2:endDate,
          endDateQuery:endDate,
        })
        localStorage.setItem('listing_filters', JSON.stringify(this.state));



        this.props.history.push({
          pathname: '/vehicle-details/'+productName,
          state: { detail: product,start: this.state.startDate2,end:endDate,lType:this.state.lType,lCount:this.state.lCount }
        })

    }

    handleChangeStart(date) {
      this.setState({
          startDate2:date,
          startDateQuery:moment(date).format("YYYY-MM-DD")
      })
      var now = moment(date).format("YYYY-MM-DD");
      var dateCheck = moment(this.state.endDateQuery).unix();
      now = moment(now).unix();
      if (now > dateCheck) {
          this.setState({
              endDate2:date,
              endDateQuery:moment(date).format("YYYY-MM-DD")
          })
      }
    }

    handleChangeEnd(date) {
      this.setState({
        endDate2: date,
        endDateQuery:moment(date).format("YYYY-MM-DD")
      })
    }


    handleChangeTimePick(time) {
        this.setState({ timePick: time })

    }

    handleChangeTimeDrop(time) {
        this.setState({ timeDrop: time })

    }


    handleInputChangePassenger(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        const inputVal = e.target.value;


        this.setState({ passengers1: false });
        this.setState({ passengers2: false });
        this.setState({ passengers3: false });
        this.setState({ passengers4: false });

        this.setState({ [item]: isChecked });


        if(isChecked){
            this.setState({ passengers: inputVal });
        }else{
            this.setState({ passengers: 0 });
        }

        this.setState({ loadingclass:'' });
        this.setState({ hideClass:'isHidden' });
        this.setState({ currentPage : 0 });
        setTimeout(() => {
            this.searchList();
        }, 500);

    }

    handleInputChangeTransmission(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        const inputVal = e.target.value;

        var tempVal = this.state.transmission;
        this.setState({ [item]: isChecked });

        if(isChecked){
            tempVal = tempVal+''+inputVal+',';
            this.setState({ transmission: tempVal });
        }else{
            tempVal = tempVal.replace(inputVal+",","");
            this.setState({ transmission: tempVal });
        }
        this.setState({ loadingclass:'' });
        this.setState({ hideClass:'isHidden' });
        this.setState({ currentPage : 0 });

        setTimeout(() => {
            this.searchList();
        }, 500);

    }

    handleInputChangeEngine(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        const inputVal = e.target.value;

        var tempVal = this.state.engine;
        this.setState({ [item]: isChecked });

        if(isChecked){
            tempVal = tempVal+''+inputVal+',';
            this.setState({ engine: tempVal });
        }else{
            tempVal = tempVal.replace(inputVal+",","");
            this.setState({ engine: tempVal });
        }
        this.setState({ loadingclass:'' });
        this.setState({ hideClass:'isHidden' });
        this.setState({ currentPage : 0 });

        setTimeout(() => {
            this.searchList();

        }, 500);

    }

    handleInputChangeEquipment(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        const inputVal = e.target.value;

        var tempVal = this.state.equipment;
        this.setState({ [item]: isChecked });

        if(isChecked){
            tempVal = tempVal+''+inputVal+',';
            this.setState({ equipment: tempVal });
        }else{
            tempVal = tempVal.replace(inputVal+",","");
            this.setState({ equipment: tempVal });
        }
        this.setState({ loadingclass:'' });
        this.setState({ hideClass:'isHidden' });
        this.setState({ currentPage : 0 });

        setTimeout(() => {
            this.searchList();

        }, 500);

    }

    handleInputChangeRental(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        const inputVal = e.target.value;

        var tempVal = this.state.rental;
        this.setState({ [item]: isChecked });

        if(isChecked){
            tempVal = tempVal+''+inputVal+',';
            this.setState({ rental: tempVal });
        }else{
            tempVal = tempVal.replace(inputVal+",","");
            this.setState({ rental: tempVal });
        }
        this.setState({ loadingclass:'' });
        this.setState({ hideClass:'isHidden' });
        this.setState({ currentPage : 0 });

        setTimeout(() => {
            this.searchList();

        }, 500);

    }



    searchList() {

        var ctoken = localStorage.getItem('ctoken');
        var token = this.state.passToken;
        var currentOffset = this.state.limit * this.state.currentPage;
        var bodyFormData = new FormData();
        var transmission = this.state.transmission;
        if(transmission != "" || transmission != null){
            transmission = transmission.slice(0, -1);
        }

        var engine = this.state.engine;
        if(engine != "" || engine != null){
            engine = engine.slice(0, -1);
        }

        var equipment = this.state.equipment;
        if(equipment != "" || equipment != null){
            equipment = equipment.slice(0, -1);
        }

        var rental = this.state.rental;
        if(rental != "" || rental != null){
            rental = rental.slice(0, -1);
        }

        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
        bodyFormData.append('jham_token', token);
        bodyFormData.append('limit', this.state.limit);
        bodyFormData.append('offset', currentOffset);
        bodyFormData.append('date_from', this.state.startDate2);
        bodyFormData.append('date_to',this.state.endDate2);
        bodyFormData.append('passengers', this.state.passengers);
        bodyFormData.append('price_from', this.state.priceFrom);
        bodyFormData.append('price_to',this.state.priceTo);
        bodyFormData.append('v_group', "Van");
        bodyFormData.append('transmission', transmission);
        bodyFormData.append('engine', engine);
        bodyFormData.append('equipment', equipment);
        bodyFormData.append('rentaloption', rental);
        bodyFormData.append('search_type', this.state.lType);


        axios({
                method: 'post',
                url: 'https://api.earental.com/listing/search',
                data: bodyFormData,
                withCredentials: true,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then((response) => {

                if(response.data.error > 0){
                    store.addNotification({
                      title: "Search failed",
                      message: response.data.error_msg,
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: false
                      }
                    });
                    this.setState({ products:[] });
                    this.setState({ loadingclass:'isHidden' });
                    this.setState({ hideClass:'' });
                    this.setState({ noResult:true });
                }else{
                    if(response.data.data == null){
                        this.setState({ products:[] });
                        this.setState({ noResult:true });

                    }else{
                        this.setState({ products:response.data.data });
                        this.setState({ noResult:false });
                    }

                    this.setState({ loadingclass:'isHidden' });
                    this.setState({ hideClass:'' });
                    this.setState({ totalPage:response.data.total_pages });
                }
                this.setState({ resultTotal: response.data.result_total })

                // window.scrollTo(0, 0);
            })
            .catch((error) => {
                //handle error
            //     this.setState({ loading:false });
            //     if (error.response.status == 403) {
               //
            //         store.addNotification({
            //           title: "Oops Something went wrong",
            //           message: "The action you have requested is not allowed. please re-load the page",
            //           type: "danger",
            //           insert: "top",
            //           container: "top-right",
            //           animationIn: ["animated", "fadeIn"],
            //           animationOut: ["animated", "fadeOut"],
            //           dismiss: {
            //             duration: 5000,
            //             onScreen: false
            //           }
            //         });
               //
            //    }
            this.setState({ products:[] });
            this.setState({ noResult:true });

        });

    }


    handleSubmit(e) {
        e.preventDefault();

        var type = e.target.leasingType.value;
        var count = "";
        var endDate = "";
        if(type == "daily"){
            count = e.target.noDays.value;
            var checkCurrentDay = moment(e.target.startDate.value);
            if(checkCurrentDay.day() == 5 && count < 5) {
                var noDays = 3;
                count = "";
                type = "weekend";
                endDate = moment(e.target.startDate.value).add(noDays, 'days').format("YYYY-MM-DD");
            }else{
                var noDays = e.target.noDays.value;
                    noDays = parseInt(noDays);
                endDate = moment(e.target.startDate.value).add(noDays, 'days').format("YYYY-MM-DD");

                var checkCurrentDay = moment(new Date(endDate));
                if(checkCurrentDay.day() == 6 || checkCurrentDay.day() == 0) {
                    store.addNotification({
                        title: "Oops!",
                        message: "Saturday/Sunday is not available as End date",
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: false
                        }
                    });

                    return false;
                }
            }
        }else if(type == "weekend"){
            var noDays = 3;
            endDate = moment(e.target.startDate.value).add(noDays, 'days').format("YYYY-MM-DD");
        }else if(type == "weekly"){
            var noWeeks = e.target.noWeeks.value;
            count = e.target.noWeeks.value;
                noWeeks = parseInt(noWeeks) * 7;
                noWeeks = noWeeks;
            endDate = moment(e.target.startDate.value).add(noWeeks, 'days').format("YYYY-MM-DD");
        }else if(type == "monthly"){
            count = e.target.noMonths.value;
            endDate = moment(e.target.startDate.value).add(count, 'months').format("YYYY-MM-DD");
            endDate = moment(endDate).subtract(1, 'days').format("YYYY-MM-DD");
        }else if(type == "1-year"){
            endDate = moment(e.target.startDate.value).add(1, 'years').format("YYYY-MM-DD");
            endDate = moment(endDate).subtract(1, 'days').format("YYYY-MM-DD");
        }else if(type == "2-years"){
            endDate = moment(e.target.startDate.value).add(2, 'years').format("YYYY-MM-DD");
            endDate = moment(endDate).subtract(1, 'days').format("YYYY-MM-DD");
        }
        var startDate = moment(e.target.startDate.value).format("YYYY-MM-DD");

        this.setState({
            startDate2:startDate,
            startDateQuery:startDate,
            endDate2:endDate,
            endDateQuery:endDate,
            isOpenRangeListing: false,
            lType: type,
            lCount:count,
            valueRangeListing: [new Date(startDate),new Date(endDate)],
        })

        this.props.history.push("/vehicles/van-rental?start="+startDate+"&end="+endDate+"&type="+type+"&count="+count);

        this.setState({ loadingclass:'' });
        this.setState({ hideClass:'isHidden' });
        this.setState({ currentPage : 0 });
        setTimeout(() => {
            this.searchList();
        }, 500);
    }

    componentDidMount() {
        axios.get('https://api.earental.com/listing/getPass?c='+moment().unix(), { withCredentials: true })
        .then((response) => {
          localStorage.setItem('token', response.data.token);
          this.setState({ passToken:response.data.token});

          setTimeout(() => {
              var ctoken = localStorage.getItem('ctoken');
              var token = this.state.passToken;

              var currentOffset = this.state.limit * this.state.currentPage;
              var bodyFormData = new FormData();

              var transmission = this.state.transmission;
              if(transmission != "" || transmission != null){
                  transmission = transmission.slice(0, -1);
              }

              var engine = this.state.engine;
              if(engine != "" || engine != null){
                  engine = engine.slice(0, -1);
              }

              var equipment = this.state.equipment;
              if(equipment != "" || equipment != null){
                  equipment = equipment.slice(0, -1);
              }

              var rental = this.state.rental;
              if(rental != "" || rental != null){
                  rental = rental.slice(0, -1);
              }

              bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
              bodyFormData.append('jham_token', token);
              bodyFormData.append('limit', this.state.limit);
              bodyFormData.append('offset', currentOffset);
              bodyFormData.append('date_from', this.state.startDate2);
              bodyFormData.append('date_to',this.state.endDate2);
              bodyFormData.append('passengers', this.state.passengers);
              bodyFormData.append('price_from', this.state.priceFrom);
              bodyFormData.append('price_to',this.state.priceTo);
              bodyFormData.append('v_group', "Van");
              bodyFormData.append('transmission', transmission);
              bodyFormData.append('engine', engine);
              bodyFormData.append('equipment', equipment);
              bodyFormData.append('rentaloption', rental);
              bodyFormData.append('search_type', this.state.lType);


              axios({
                      method: 'post',
                      url: 'https://api.earental.com/listing/search',
                      data: bodyFormData,
                      withCredentials: true,
                      headers: {'Content-Type': 'multipart/form-data' }
                  })
                  .then((response) => {

                      if(response.data.error > 0){
                          store.addNotification({
                            title: "Search failed",
                            message: response.data.error_msg,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                              duration: 5000,
                              onScreen: false
                            }
                          });
                          this.setState({ products:[] });
                          this.setState({ loadingclass:'isHidden' });
                          this.setState({ noResult:true });
                      }else{

                          if(response.data.data == null){
                              this.setState({ products:[] });
                              this.setState({ noResult:true });
                          }else{
                              this.setState({ products:response.data.data });
                              this.setState({ noResult:false });
                          }

                          this.setState({ loadingclass:'isHidden' });
                          this.setState({ totalPage:response.data.total_pages });
                      }

                      this.setState({ resultTotal: response.data.result_total })
                  })
                  .catch((error) => {
                      this.setState({ products:[] });
                      this.setState({ noResult:true });

              });

          }, 300);
        })
        .catch((error) => {
          // handle error
          console.log(error);
        });

        localStorage.removeItem("listing_filters");
        // localStorage.removeItem("currrent_vehicle");
        localStorage.removeItem("back_continue");




    }

    handlePageChanged(newPage) {
        this.setState({ currentPage : newPage });
        this.setState({ loadingclass:'' });
        this.setState({ hideClass:'isHidden' });
        window.scrollTo(0, 0)
        setTimeout(() => {
            this.searchList();

        }, 500);


    }

    renderSideFilter(type) {
        const checkboxesPassenger = [
        {
            name: "passengers1",
            key: "checkBoxPassenger",
            label: "2 Passengers",
            value:2,
            id:1
        },
        {
            name: "passengers2",
            key: "checkBoxPassenger",
            label: "3 Passengers",
            value:3,
            id:2
        },
        {
            name: "passengers3",
            key: "checkBoxPassenger",
            label: "4 Passengers",
            value:4,
            id:3
        },
        {
            name: "passengers4",
            key: "checkBoxPassenger",
            label: "4+ Passengers",
            value:5,
            id:4
        }
    ];
    const checkboxesToRenderPassenger = checkboxesPassenger.map(item => {
           return (
               <div className="checkbox" key={item.key+'-'+item.id} style={{fontSize:"15px"}}>
                   <Checkbox
                      checkboxClass="i-check"
                      increaseArea="20%"
                      name={item.name}
                      checked={this.state.[item.name]}
                      onChange={this.handleInputChangePassenger}
                      label={item.label}
                      value={item.value}
                      cursor="pointer"

                    />
               </div>
           );
       });

       const checkboxesTransmission = [
           {
               name: "transmission1",
               key: "checkBoxTransmission",
               label: "Automatic",
               value: "Automatic",
               id:1
           },
           {
               name: "transmission2",
               key: "checkBoxTransmission",
               label: "Manual",
               value: "Manual",
               id:2
           },
        ];
       const checkboxesToRenderTransmission = checkboxesTransmission.map(item => {
                return (
                    <div className="checkbox" key={item.key+'-'+item.id} style={{fontSize:"15px"}}>
                       <Checkbox
                          checkboxClass="i-check"
                          increaseArea="20%"
                          name={item.name}
                          checked={this.state.[item.name]}
                          onChange={this.handleInputChangeTransmission}
                          label={item.label}
                          value={item.value}
                          cursor="pointer"
                        />
                    </div>
                );
          });

          const checkboxesEngine = [
              {
                  name: "engine1",
                  key: "checkBoxEngine",
                  label: "Gas",
                  value: "Gas",
                  id:1
              },
              {
                  name: "engine2",
                  key: "checkBoxEngine",
                  label: "Diesel",
                  value: "Diesel",
                  id:2
              },
              {
                  name: "engine3",
                  key: "checkBoxEngine",
                  label: "Hybrid",
                  value: "Hybrid",
                  id:3
              },
              {
                  name: "engine4",
                  key: "checkBoxEngine",
                  label: "Electric",
                  value: "Electric",
                  id:4
              },
           ];
          const checkboxesToRenderEngine = checkboxesEngine.map(item => {
                 return (
                         <div className="checkbox" key={item.key+'-'+item.id} style={{fontSize:"15px"}}>
                            <Checkbox
                               checkboxClass="i-check"
                               increaseArea="20%"
                               name={item.name}
                               checked={this.state.[item.name]}
                               onChange={this.handleInputChangeEngine}
                               value={item.value}
                               label={item.label}
                               cursor="pointer"
                             />
                         </div>
                     );
             });

         const checkboxesEquipment = [
             {
                name: "equipment1",
                key: "checkBoxEquipment",
                label: "Climate Control",
                value: "Climate Control",
                id:1
             },
             {
                name: "equipment2",
                key: "checkBoxEquipment",
                label: "Air Conditioning",
                value: "Air Conditioner",
                id:2
             },
             {
                name: "equipment3",
                key: "checkBoxEquipment",
                label: "Satellite Navigation",
                value: "Satellite Navigation",
                id:3
             },
             {
                name: "equipment4",
                key: "checkBoxEquipment",
                label: "Power Door Locks",
                value: "Power Door Locks",
                id:4
             },
             {
                name: "equipment5",
                key: "checkBoxEquipment",
                label: "FM Radio",
                value: "FM Radio",
                id:5
             },
             {
                name: "equipment6",
                key: "checkBoxEquipment",
                label: "Stereo CD/MP3",
                value: "Stereo CD/MP3",
                id:6
             },
             {
                name: "equipment7",
                key: "checkBoxEquipment",
                label: "Tilt Steering Wheel",
                value: "Tilt Steering Wheel",
                id:7
             },
             {
                name: "equipment8",
                key: "checkBoxEquipment",
                label: "Power Windows",
                value: "Power Windows",
                id:8
             },
          ];
         const checkboxesToRenderEquipment = checkboxesEquipment.map(item => {
                return (
                        <div className="checkbox" key={item.key+'-'+item.id} style={{fontSize:"15px"}}>
                           <Checkbox
                              checkboxClass="i-check"
                              increaseArea="20%"
                              name={item.name}
                              checked={this.state.[item.name]}
                              onChange={this.handleInputChangeEquipment}
                              value={item.value}
                              label={item.label}
                              cursor="pointer"
                            />
                        </div>
                    );
            });

        const checkboxesRental = [
            {
                name: "rental1",
                key: "checkBoxEquipment",
                label: "Short Term",
                value: "Short Term",
                id:1
            },
            {
                name: "rental2",
                key: "checkBoxEquipment",
                label: "Long Term",
                value: "Long Term",
                id:2
            },
         ];
        const checkboxesToRenderRental = checkboxesRental.map(item => {
               return (
                       <div className="checkbox" key={item.key+'-'+item.id} style={{fontSize:"15px"}}>
                          <Checkbox
                             checkboxClass="i-check"
                             increaseArea="20%"
                             name={item.name}
                             checked={this.state.[item.name]}
                             onChange={this.handleInputChangeRental}
                             value={item.value}
                             label={item.label}
                             cursor="pointer"
                           />
                       </div>
                   );
           });

        return (

            <aside className={type+" booking-filters text-black"}>
                <h3>Filter By:</h3>
                <ul className="list booking-filters-list">
                    <li>
                        <h5 className="booking-filters-title">Passengers Quantity</h5>
                        {checkboxesToRenderPassenger}

                    </li>
                    <li>
                        <h5 className="booking-filters-title">Price </h5>
                        <Slider
                           value={this.state.priceRange}
                           onChange={this.handleSliderChange}
                           onChangeCommitted={this.handleSliderChangeCommitted}
                           valueLabelDisplay="auto"
                           aria-labelledby="range-slider"
                           getAriaValueText={this.sliderValue}
                           min={0}
                           max={500}
                           marks={this.state.sliderMarks}
                           className="v-price-slider"
                         />
                    </li>
                    <li>
                        <h5 className="booking-filters-title">Transmission</h5>
                        {checkboxesToRenderTransmission}
                    </li>
                    <li>
                        <h5 className="booking-filters-title">Engine</h5>
                        {checkboxesToRenderEngine}
                    </li>
                    <li>
                        <h5 className="booking-filters-title">Equipment</h5>
                        {checkboxesToRenderEquipment}
                    </li>
                    <li>
                        <h5 className="booking-filters-title">Rental Options</h5>
                        {checkboxesToRenderRental}
                    </li>
                </ul>
            </aside>
        );
    }

    renderListing() {

        function TermIcons(props) {
            var terms = props.terms.split(',');
            return (
                  terms.map((item,i) =>
                    (item == "Short Term") ?
                    (<li rel="tooltip" data-placement="top" title={item} key={'t-'+i+'-'+props.vid}><i className="fa fa-clock-o"></i></li>) :
                    (<li rel="tooltip" data-placement="top" title={item} key={'t-'+i+'-'+props.vid}><i className="fa fa-calendar-o"></i></li>)
                  )
            );

        }



        function Equipments(props) {

            if(props.equipments == null){
                return false;
            }else{
                var equipments = props.equipments.trim().split(',');
                var price = props.price.trim().split(',');
                return (
                      equipments.map((item,i) => {
                        if(parseInt(price[i]) == 0 && item.trim() != ""){
                            return (
                                <Chip
                                    key={'xve-'+i+'-'+props.vid}
                                    icon={<CheckIcon style={{fill: "green"}} />}
                                    label={item.trim()}
                                    variant="outlined"
                                    className="add-ons-chips"
                                    style={{fontSize:"15px",marginBottom:"5px",fontWeight:"300"}}
                                />
                            )
                        }else if(parseInt(price[i]) > 0 && item.trim() != ""){
                            return (
                                <Chip
                                    key={'xve-'+i+'-'+props.vid}
                                    icon={<AddShoppingCartIcon style={{fill: "grey"}} />}
                                    label={item.trim()}
                                    variant="outlined"
                                    className="add-ons-chips"
                                    style={{fontSize:"15px",marginBottom:"5px",fontWeight:"300"}}
                                />
                            )
                        }


                      })
                );



            }

        }


        function DateRangeProduct(props){
            const stateDefinitions = {
                available: {
                  color: null,
                  label: 'Available',
                },
                unavailable: {
                  selectable: false,
                  color: '#78818b',
                  label: 'Unavailable',
                },
              };

              const unavailableDates = [];
              for (let i = 0; i < props.restrictedDate.length; i++) {
                  var startDate = moment(props.restrictedDate[i].s_start_date).subtract(1, 'day').format("YYYY-MM-DD");
                  var endDate = moment(props.restrictedDate[i].s_end_date).add(2, 'day').format("YYYY-MM-DD");

                  unavailableDates.push(
                      {
                      state: 'unavailable',
                      range: moment.range(
                          startDate,
                          endDate,
                      ),
                      },
                  );

              }

              if(unavailableDates.length > 0)
                  unavailableDates.sort((a, b) => (moment(a.range.start).isBefore(moment(b.range.start)) ? -1 : 1));

              return(
                  <DateRangePicker
                    numberOfCalendars={1}
                    disabled={true}
                    selectionType={false}
                    minimumDate={props.minDate}
                    stateDefinitions={stateDefinitions}
                    dateStates={unavailableDates}
                    defaultState="available"
                    fullDayStates={false}
                    halfDayStates={false}
                    value={props.value}
                    showLegend={true}
                    className="text-center"
                    singleDateRange={true}
                  />
              );
        }


        function ListTitle(props){
            return (
                <span className="list-title-price">{props.title}</span>
            )
        }

        function disableWeekDays(restrictedDates,date) {

            if(restrictedDates && restrictedDates.length > 0){

                    for (let i = 0; i < restrictedDates.length; i++) {
                        var endDate = moment(restrictedDates[i].s_end_date).add(1, 'day').format("YYYY-MM-DD");
                        var startDate = moment(restrictedDates[i].s_start_date).subtract(1, 'day').format("YYYY-MM-DD");
                        var checkBetween = moment(date).isBetween(startDate, endDate);
                        if(!checkBetween){
                            if((date.getDay() === 6 || date.getDay() === 0)){
                                return true;
                            }
                        }else{
                            return true;
                        }
                    }

            }else{
               return date.getDay() === 6 || date.getDay() === 0;
            }

        }

        function disableWeekEnd(restrictedDates,date) {

            if(restrictedDates && restrictedDates.length > 0){

                    for (let i = 0; i < restrictedDates.length; i++) {
                        var endDate = moment(restrictedDates[i].s_end_date).add(1, 'day').format("YYYY-MM-DD");
                        var startDate = moment(restrictedDates[i].s_start_date).subtract(1, 'day').format("YYYY-MM-DD");
                        var checkBetween = moment(date).isBetween(startDate, endDate);
                        if(!checkBetween){
                            if((date.getDay() === 1 || date.getDay() === 2 || date.getDay() === 3 || date.getDay() === 4 || date.getDay() === 6 || date.getDay() === 0)){
                                return true;
                            }
                        }else{
                            return true;
                        }
                    }

            }else{
               return date.getDay() === 1 || date.getDay() === 2 || date.getDay() === 3 || date.getDay() === 4 || date.getDay() === 6 || date.getDay() === 0;
            }

        }

        function disableRestrictedOnly(restrictedDates,date) {
            if(restrictedDates){

                    for (let i = 0; i < restrictedDates.length; i++) {
                        var endDate = moment(restrictedDates[i].s_end_date).add(1, 'day').format("YYYY-MM-DD");
                        var startDate = moment(restrictedDates[i].s_start_date).subtract(1, 'day').format("YYYY-MM-DD");
                        return moment(date).isBetween(startDate, endDate);
                    }

            }else{
                return false;
            }
        }

        function disableCalendarRestrictedOnly(restrictedDates,weekdays,date) {

            // if(weekdays){
            //     if((date.date.getDay() === 6 || date.date.getDay() === 0)){
            //         return true;
            //     }
            // }


            if(restrictedDates){

                    for (let i = 0; i < restrictedDates.length; i++) {
                        var endDate = moment(restrictedDates[i].s_end_date).add(1, 'day').format("YYYY-MM-DD");
                        var startDate = moment(restrictedDates[i].s_start_date).subtract(1, 'day').format("YYYY-MM-DD");
                        if(moment(date.date).isBetween(startDate, endDate)){
                            return true;
                        }
                    }

            }else{
                return false;
            }
        }


        function SearchFormProduct(props){
          const classes = useStyles();
          const [isOpen, setIsOpen] = React.useState(false);
          const [selectedDate, setSelectedDate] =  React.useState(null);
          const [selectedDateWeekEnd, setSelectedDateWeekEnd] =  React.useState(null);
          const [selectedDateWeekly, setSelectedDateWeekly] =  React.useState(null);
          const [selectedDateMonthly, setSelectedDateMonthly] =  React.useState(null);
          const [selectedRangeDate, setSelectedRangeDate] = React.useState(null);
          const [noDays, setNoDays] =  React.useState("");
          const [btnLoading, setBtnLoading] = React.useState(false);
          const [withWeekdays, setWithWeekdays] = React.useState(true);
          const [useDate, setUseDate] = React.useState(false);
          const [minDate, setMinDate] = React.useState(props.minDate);
          const [calendarRange, setCalendarRange] = React.useState(true);
          const [calendarNote, setCalendarNote] = React.useState("Note: Saturday/Sunday not available as start/end date");
          const [toggleRange, setToggleRange] = React.useState(true);

          var currentLtype = "";
          if(currentLtype == ""){
              if(parseFloat(props.product.product_price_month) > 0){
                  currentLtype = "monthly";
              }else if(parseFloat(props.product.product_price_day) > 0){
                  currentLtype = "daily";
              }else if(parseFloat(props.product.product_price_weekend) > 0){
                  currentLtype = "weekend";
              }else if(parseFloat(props.product.product_price_week) > 0){
                  currentLtype = "weekly";
              }else if(parseFloat(props.product.product_price_year) > 0){
                  currentLtype = "1-year";
              }else if(parseFloat(props.product.product_price_year_2) > 0){
                  currentLtype = "2-years";
              }

          }


          const [type, setType] = React.useState(currentLtype);
          const handleDateChange = (date) => {
              setUseDate(false);
              setMinDate(props.minDate);
              setToggleRange(false);
              if(selectedDate !== null){
                  setIsOpen(false);
              }
              setSelectedDate(date);
              setSelectedRangeDate([new Date(date),new Date(date)]);

          };

          const handleDateChangeWeekEnd = (date) => {
                setUseDate(false);
                if(selectedDateWeekEnd !== null){
                    setIsOpen(false);
                }
                setSelectedDateWeekEnd(date);
                var endDate = date;

                var checkCurrentDay = moment(date);
                if(checkCurrentDay.day() == 5)
                    endDate = moment(date).add(3, 'days').format("YYYY-MM-DD");

                setSelectedRangeDate([new Date(date),new Date(endDate)]);
          };

          const handleDateChangeWeekly = (date) => {
              setUseDate(false);
              if(selectedDateWeekly !== null){
                  setIsOpen(false);
              }
              setSelectedDateWeekly(date);
              setSelectedRangeDate([new Date(date),new Date(date)]);
          };

          const handleDateChangeMonthly = (date) => {
              setUseDate(false);
              if(selectedDateMonthly !== null){
                  setIsOpen(false);
              }
              setSelectedDateMonthly(date);
              setSelectedRangeDate([new Date(date),new Date(date)]);
          };

          const handlePasteChange = (e) => {
              e.preventDefault();
          }

          const handleDaysChange = (e) => {
              setUseDate(false);
              e.preventDefault();
              if(e.target.value == ""){
                  setNoDays("");
              }else{
                  if(parseInt(e.target.value) <= 0 || isNaN(parseInt(e.target.value))){
                      setNoDays(1);
                  }else{
                      setNoDays(e.target.value);
                  }

              }

          }

          const handleWeeklyChange = (e) => {
              setUseDate(false);
              e.preventDefault();
              if(e.target.value == ""){
                  setNoDays("");
              }else{
                  if(parseInt(e.target.value) <= 0 || isNaN(parseInt(e.target.value))){
                      setNoDays(1);
                  }else{
                      setNoDays(e.target.value);
                  }
              }

          }

          const handleMonthlyChange = (e) => {
              setUseDate(false);

          }

          const handleStartDate = (value, event) => {
              setUseDate(false);
                console.log(value);

              if(type == "monthly" || type == "weekly" || type == "1-year" || type == "2-years"){
                  var checkCurrentDay = moment(new Date(value));
                  if(checkCurrentDay.day() == 6 || checkCurrentDay.day() == 0) {
                      setSelectedRangeDate(null);
                      setMinDate(props.minDate);
                      store.addNotification({
                          title: "Oops!",
                          message: "Saturday/Sunday is not available as start/end date",
                          type: "warning",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animated", "fadeIn"],
                          animationOut: ["animated", "fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: false
                          }
                      });
                  }else{
                      setSelectedDateWeekly(value);
                      setSelectedDateMonthly(value);
                  }
              }else if(type == "weekend"){
                  var endDate = value;

                  var checkCurrentDay = moment(value);
                  if(checkCurrentDay.day() == 5){
                        setSelectedDateWeekEnd(value);
                        endDate = moment(value).add(3, 'days').format("YYYY-MM-DD");
                        setSelectedRangeDate([new Date(value),new Date(endDate)]);
                        setMinDate(props.minDate);
                  }else{
                     setWithWeekdays(false);
                     setMinDate(new Date(value));
                  }


              }else{
                  setToggleRange(true);
                  setWithWeekdays(false)
                  setMinDate(new Date(value));

              }
          }

          const handleRangeSelect = (value, event) => {

                var startDate = moment(value[0]).format("YYYY-MM-DD");
                var endDate = moment(value[1]).format("YYYY-MM-DD");
                if(startDate == endDate){
                    endDate = moment(startDate).add(1, 'days').format("YYYY-MM-DD");
                }
                if(type == "weekend"){
                    startDate = moment(value).format("YYYY-MM-DD");
                    endDate = moment(value).format("YYYY-MM-DD");
                }
                if(type == "daily"){
                    var checkCurrentDay = moment(startDate);
                    if(!value[1]){
                        if(checkCurrentDay.day() == 6 || checkCurrentDay.day() == 0) {
                            setMinDate(props.minDate);
                            store.addNotification({
                                title: "Oops!",
                                message: "Saturday/Sunday is not available as start/end date",
                                type: "warning",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 5000,
                                    onScreen: false
                                }
                            });
                        }else{
                            setSelectedDate(startDate);
                        }
                        return false;
                    }

                    var checkCurrentEndDay = moment(endDate);
                    if(checkCurrentDay.day() != 5  && (checkCurrentDay.day() == 6 || checkCurrentDay.day() == 0 || checkCurrentEndDay.day() == 6 || checkCurrentEndDay.day() == 0)) {
                        setSelectedRangeDate(selectedRangeDate);
                        setMinDate(props.minDate);
                        store.addNotification({
                            title: "Oops!",
                            message: "Saturday/Sunday is not available as start/end date",
                            type: "warning",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: false
                            }
                        });
                    }else{

                        setWithWeekdays(true);
                        setMinDate(props.minDate);
                        var a = moment(endDate);
                        var b = moment(startDate);
                        var dayCount = a.diff(b, 'days');
                        var count = dayCount + 1;
                        var checkCurrentDay = moment(startDate);

                        if(checkCurrentDay.day() == 5 && count < 5) {
                            var noDays = 3;
                            count = 1;
                            endDate = moment(startDate).add(noDays, 'days').format("YYYY-MM-DD");
                            setType("weekend");
                            setSelectedDateWeekEnd(startDate);
                            setCalendarNote("Note: Weekend Fri – Mon (3 days)");
                        }else{
                            setType("daily");
                            setCalendarNote("Note: Saturday/Sunday not available as start/end date");
                            if(checkCurrentDay.day() == 6 || checkCurrentDay.day() == 0 || checkCurrentEndDay.day() == 6 || checkCurrentEndDay.day() == 0) {
                                setSelectedRangeDate(selectedRangeDate);
                                setMinDate(props.minDate);
                                store.addNotification({
                                    title: "Oops!",
                                    message: "Saturday/Sunday is not available as start/end date",
                                    type: "warning",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 5000,
                                        onScreen: false
                                    }
                                });
                                return false;
                            }


                            if(count < parseInt(props.product.product_min_days)){
                                store.addNotification({
                                  title: "Oops!",
                                  message: "This product can be lease with minimum of "+props.product.product_min_days+" days",
                                  type: "warning",
                                  insert: "top",
                                  container: "top-right",
                                  animationIn: ["animated", "fadeIn"],
                                  animationOut: ["animated", "fadeOut"],
                                  dismiss: {
                                    duration: 5000,
                                    onScreen: false
                                  }
                                });

                                return false;
                            }
                        }


                        // var daysDiff = getDaysDiff('10-01-2019', '10-30-2019'));
                        setSelectedDate(startDate);
                        setNoDays(dayCount);
                        setSelectedRangeDate([new Date(startDate),new Date(endDate)]);

                        setBtnLoading(true);


                        var ctoken = localStorage.getItem('ctoken');
                        var token = props.token;
                        var bodyFormData = new FormData();
                        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
                        bodyFormData.append('jham_token', token);
                        bodyFormData.append('limit', 1);
                        bodyFormData.append('offset', 0);
                        bodyFormData.append('date_from',startDate);
                        bodyFormData.append('date_to',endDate);
                        bodyFormData.append('product_id', props.product.product_id);


                        axios({
                        method: 'post',
                        url: 'https://api.earental.com/listing/search',
                        data: bodyFormData,
                        withCredentials: true,
                        headers: {'Content-Type': 'multipart/form-data' }
                        })
                        .then((response) => {
                           if(response.data.data[0].available == 0){
                               store.addNotification({
                                 title: "Not Available",
                                 message: "Product already rented for this date.",
                                 type: "warning",
                                 insert: "top",
                                 container: "top-right",
                                 animationIn: ["animated", "fadeIn"],
                                 animationOut: ["animated", "fadeOut"],
                                 dismiss: {
                                   duration: 5000,
                                   onScreen: false
                                 }
                               });
                               setBtnLoading(false);
                               setUseDate(false);
                           }else{

                               store.addNotification({
                                 title: "Great!",
                                 message: "This date is available for booking.",
                                 type: "success",
                                 insert: "top",
                                 container: "top-right",
                                 animationIn: ["animated", "fadeIn"],
                                 animationOut: ["animated", "fadeOut"],
                                 dismiss: {
                                   duration: 5000,
                                   onScreen: false
                                 }
                               });
                               setUseDate(true);
                               setBtnLoading(false);


                           }



                        })
                        .catch((error) => {
                            setBtnLoading(false);

                        });


                    }
                }else{

                    if(type == "weekend"){
                        var checkCurrentDay = moment(value);
                        setMinDate(props.minDate);
                        if(checkCurrentDay.day() != 5) {
                            store.addNotification({
                                title: "Oops!",
                                message: "Please select friday as starting date",
                                type: "warning",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 5000,
                                    onScreen: false
                                }
                            });
                        }else{
                            var noDays = 3;
                            count = 1;
                            endDate = moment(startDate).add(noDays, 'days').format("YYYY-MM-DD");
                            setSelectedRangeDate([new Date(startDate),new Date(endDate)]);
                        }
                    }else{
                        var checkCurrentDay = moment(value);
                        if(checkCurrentDay.day() == 6 || checkCurrentDay.day() == 0) {
                            setSelectedRangeDate(selectedRangeDate);
                        }else{
                            setSelectedRangeDate([new Date(value),new Date(value)]);
                        }
                        setMinDate(props.minDate);
                    }


                }




          };


           const days = [
           ];

           const months = [
               { title: "1" },
               { title: "2" },
               { title: "3" },
               { title: "4" },
               { title: "5" },
               { title: "6" },
               { title: "7" },
               { title: "8" },
               { title: "9" },
               { title: "10" },
               { title: "11" },
           ];

           const handleTypeChange = (e) => {
               setUseDate(false);
               setNoDays("");
               setType(e.target.value);
               setSelectedRangeDate(props.currentRange);
                if(e.target.value == "daily"){
                    setSelectedRangeDate([new Date(selectedDate),new Date(selectedDate)]);
                    setCalendarNote("Note: Saturday/Sunday not available as start/end date");
                }else if(e.target.value == "weekend"){
                    setCalendarNote("Note: Weekend Fri – Mon (3 days)");
                    setSelectedRangeDate([new Date(selectedDateWeekEnd),new Date(selectedDateWeekEnd)]);
                }else if(e.target.value == "weekly"){
                    setSelectedRangeDate([new Date(selectedDateWeekly),new Date(selectedDateWeekly)]);
                    setCalendarNote("Note: Saturday/Sunday not available as start/end date");
                }else if(e.target.value == "monthly"){
                    setSelectedRangeDate([new Date(selectedDateMonthly),new Date(selectedDateMonthly)]);
                    setCalendarNote("Note: Saturday/Sunday not available as start/end date");
                }else if(e.target.value == "1-year"){
                    setSelectedRangeDate([new Date(selectedDateMonthly),new Date(selectedDateMonthly)]);
                    setCalendarNote("Note: Saturday/Sunday not available as start/end date");
                }else if(e.target.value == "2-years"){
                    setSelectedRangeDate([new Date(selectedDateMonthly),new Date(selectedDateMonthly)]);
                    setCalendarNote("Note: Saturday/Sunday not available as start/end date");
                }

           };

           function StyledRadio(props) {
               const classes = useStyles();

               return (
                   <RadioMu
                     className={classes.root}
                     disableRipple
                     color="default"
                     checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                     icon={<span className={classes.icon} />}
                     {...props}
                   />
               );
           }




           const handleCheckDate = (e) => {
               e.preventDefault();

               setToggleRange(false);
               var type = e.target.leasingType.value;
               var endDate = "";
               var count = 1;
               var checkCurrentDay = moment(e.target.startDate.value);
               if(type == "daily"){
                  count = e.target.noDays.value;
                  if(checkCurrentDay.day() == 5 && count < 5) {
                      var noDays = 3;
                      count = "";
                      endDate = moment(e.target.startDate.value).add(noDays, 'days').format("YYYY-MM-DD");
                      type = "weekend";
                      setType("weekend");
                      setSelectedDateWeekEnd(e.target.startDate.value);
                  }else{
                      var noDays = e.target.noDays.value;
                          noDays = parseInt(noDays);
                      endDate = moment(e.target.startDate.value).add(noDays, 'days').format("YYYY-MM-DD");
                  }
               }else if(type == "weekend"){
                  var noDays = 3;

                  endDate = moment(e.target.startDate.value).add(noDays, 'days').format("YYYY-MM-DD");
               }else if(type == "weekly"){
                  var noWeeks = e.target.noWeeks.value;
                  count = e.target.noWeeks.value;
                      noWeeks = parseInt(noWeeks) * 7;
                      noWeeks = noWeeks;
                  endDate = moment(e.target.startDate.value).add(noWeeks, 'days').format("YYYY-MM-DD");
               }else if(type == "monthly"){
                   count = e.target.noMonths.value;
                   endDate = moment(e.target.startDate.value).add(count, 'months').format("YYYY-MM-DD");
                   endDate = moment(endDate).subtract(1, 'days').format("YYYY-MM-DD");

                   if(props.product.month_bonus == "1" && props.product.product_month_bonus_rental_fee == "1"){
                       if(props.product.product_month_bonus_type == "days"){
                           endDate = moment(endDate).add(parseInt(props.product.product_month_bonus_days_months_count), 'days').format("YYYY-MM-DD");
                       }else{
                           endDate = moment(endDate).add(parseInt(props.product.product_month_bonus_days_months_count), 'months').format("YYYY-MM-DD");
                       }
                   }
               }else if(type == "1-year"){
                   endDate = moment(e.target.startDate.value).add(1, 'years').format("YYYY-MM-DD");
                   endDate = moment(endDate).subtract(1, 'days').format("YYYY-MM-DD");
                   if(props.product.year_bonus == "1" && props.product.product_year_bonus_rental_fee == "1"){
                       if(props.product.product_year_bonus_type == "days"){
                           endDate = moment(endDate).add(parseInt(props.product.product_year_bonus_days_months_count), 'days').format("YYYY-MM-DD");
                       }else{
                           endDate = moment(endDate).add(parseInt(props.product.product_year_bonus_days_months_count), 'months').format("YYYY-MM-DD");
                       }
                   }
               }else if(type == "2-years"){
                   endDate = moment(e.target.startDate.value).add(2, 'years').format("YYYY-MM-DD");
                   endDate = moment(endDate).subtract(1, 'days').format("YYYY-MM-DD");
                   if(props.product.year2_bonus == "1" && props.product.product_year2_bonus_rental_fee == "1"){
                       if(props.product.product_year2_bonus_type == "days"){
                           endDate = moment(endDate).add(parseInt(props.product.product_year2_bonus_days_months_count), 'days').format("YYYY-MM-DD");
                       }else{
                           endDate = moment(endDate).add(parseInt(props.product.product_year2_bonus_days_months_count), 'months').format("YYYY-MM-DD");
                       }
                   }
               }
               var startDate = moment(e.target.startDate.value).format("YYYY-MM-DD");

               var checkCurrentEndDay = moment(endDate);

               var rangeDate = moment.range(startDate,endDate);

               if(type == "daily" && (checkCurrentDay.day() == 6 || checkCurrentDay.day() == 0 || checkCurrentEndDay.day() == 6 || checkCurrentEndDay.day() == 0)) {
                   setSelectedRangeDate(selectedRangeDate);
                   setMinDate(props.minDate);
                   store.addNotification({
                       title: "Oops!",
                       message: "Saturday/Sunday is not available as start/end date",
                       type: "warning",
                       insert: "top",
                       container: "top-right",
                       animationIn: ["animated", "fadeIn"],
                       animationOut: ["animated", "fadeOut"],
                       dismiss: {
                           duration: 5000,
                           onScreen: false
                       }
                   });
                   return false;
               }

               if(type == "daily" && count < parseInt(props.product.product_min_days)){
                   setSelectedRangeDate([new Date(startDate),new Date(endDate)]);
                   store.addNotification({
                     title: "Oops!",
                     message: "This product can be lease with minimum of "+props.product.product_min_days+" days",
                     type: "warning",
                     insert: "top",
                     container: "top-right",
                     animationIn: ["animated", "fadeIn"],
                     animationOut: ["animated", "fadeOut"],
                     dismiss: {
                       duration: 5000,
                       onScreen: false
                     }
                   });
               }else{
                   setMinDate(props.minDate);
                   setSelectedRangeDate([new Date(startDate),new Date(endDate)]);
                   if(useDate){
                       props.onSelect(startDate,endDate,type,count);
                   }else{
                       setBtnLoading(true);
                       setSelectedRangeDate([new Date(startDate),new Date(endDate)]);
                       var ctoken = localStorage.getItem('ctoken');
                       var token = props.token;
                       var bodyFormData = new FormData();
                       bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
                       bodyFormData.append('jham_token', token);
                       bodyFormData.append('limit', 1);
                       bodyFormData.append('offset', 0);
                       bodyFormData.append('date_from',startDate);
                       bodyFormData.append('date_to',endDate);
                       bodyFormData.append('product_id', props.product.product_id);
                       bodyFormData.append('search_type', type);


                       axios({
                           method: 'post',
                           url: 'https://api.earental.com/listing/search',
                           data: bodyFormData,
                           withCredentials: true,
                           headers: {'Content-Type': 'multipart/form-data' }
                       })
                       .then((response) => {
                          if(response.data.data[0].available == 0){
                              store.addNotification({
                                title: "Not Available",
                                message: "Product already rented for this date.",
                                type: "warning",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                  duration: 5000,
                                  onScreen: false
                                }
                              });
                              setBtnLoading(false);
                              setUseDate(false);
                          }else{

                              if(useDate){
                                  props.onSelect(startDate,endDate,type,count);
                              }else{
                                  store.addNotification({
                                    title: "Great!",
                                    message: "This date is available for booking.",
                                    type: "success",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                      duration: 5000,
                                      onScreen: false
                                    }
                                  });
                                  setUseDate(true);
                                  setBtnLoading(false);
                              }

                          }



                       })
                       .catch((error) => {
                           setBtnLoading(false);

                       });
                   }
               }


           }


          return(
              <Grid container spacing={1} className={classes.newCardForm}>
                  <Grid item md={6} sm={12} xs={12}>
                      {parseInt(props.product.product_min_days) > 1 && (
                          <>
                            <Alert severity="info" style={{fontSize:"13px"}}>Minimum of {props.product.product_min_days} days to lease this product.</Alert>
                            <br />
                          </>
                      )}
                      <form onSubmit={handleCheckDate }>
                          <Grid container spacing={1} className={classes.newCardForm}>
                            <Grid item md={4} sm={5} xs={5}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Leasing Type</FormLabel>
                                    <RadioGroup defaultValue={type} value={type} aria-label="leasing-type" onChange={handleTypeChange} name="leasingType">
                                        {parseFloat(props.product.product_price_day) > 0 && (
                                            <FormControlLabel value="daily" control={<StyledRadio />} label="Daily" />
                                        )}

                                        {parseFloat(props.product.product_price_weekend) > 0 && (
                                            <FormControlLabel value="weekend" control={<StyledRadio />} label="Weekend" />
                                        )}

                                        {parseFloat(props.product.product_price_week) > 0 && (
                                            <FormControlLabel value="weekly" control={<StyledRadio />} label="Weekly" />
                                        )}

                                        {parseFloat(props.product.product_price_month) > 0 && (
                                            <FormControlLabel value="monthly" control={<StyledRadio />} label="Monthly" />
                                        )}

                                        {parseFloat(props.product.product_price_year) > 0 && (
                                            <FormControlLabel value="1-year" control={<StyledRadio />} label="1-Year Lease" />
                                        )}

                                        {parseFloat(props.product.product_price_year_2) > 0 && (
                                            <FormControlLabel value="2-years" control={<StyledRadio />} label="2-Year Lease" />
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid container md={8} sm={7} xs={7} spacing={2} className="home-search-right">
                                <Grid item md={12} sm={12} xs={12}>
                                        <br/>
                                        {type == "weekend" && (
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                                                <KeyboardDatePicker
                                                    required
                                                    autoOk={true}
                                                    disablePast
                                                    variant="inline"
                                                    label="Start Date"
                                                    format="MMM d, yyyy"
                                                    name="startDate"
                                                    className="startDate"
                                                    minDate={props.minDate}
                                                    shouldDisableDate={disableWeekEnd.bind(this,props.restrictedDate)}
                                                    value={selectedDateWeekEnd}
                                                    defaultValue={selectedDateWeekEnd}
                                                    onChange={handleDateChangeWeekEnd}
                                                    fullWidth
                                                    className="home-date-picker"
                                                    KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                    }}
                                                    KeyboardButtonProps={{
                                                        onFocus: e => {
                                                        setIsOpen(true);
                                                        }
                                                    }}
                                                    PopoverProps={{
                                                        disableRestoreFocus: true,
                                                        onClose: () => {
                                                            setIsOpen(false);
                                                        }
                                                    }}
                                                    InputProps={{
                                                    onFocus: () => {
                                                      setIsOpen(true);
                                                    }
                                                    }}
                                                    open={isOpen}

                                                />
                                            </MuiPickersUtilsProvider>
                                     )}

                                     {type == "daily" && (
                                         <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                                             <KeyboardDatePicker
                                                 required
                                                 autoOk={true}
                                                 disablePast
                                                 variant="inline"
                                                 label="Start Date"
                                                 format="MMM d, yyyy"
                                                 name="startDate"
                                                 minDate={props.minDate}
                                                 shouldDisableDate={disableWeekDays.bind(this,props.restrictedDate)}
                                                 className="startDate"
                                                 value={selectedDate}
                                                 defaultValue={selectedDate}
                                                 onChange={handleDateChange}
                                                 fullWidth
                                                 className="home-date-picker"
                                                 KeyboardButtonProps={{
                                                 'aria-label': 'change date',
                                                 }}
                                                 KeyboardButtonProps={{
                                                     onFocus: e => {
                                                     setIsOpen(true);
                                                     }
                                                 }}
                                                 PopoverProps={{
                                                     disableRestoreFocus: true,
                                                     onClose: () => {
                                                     setIsOpen(false);
                                                     }
                                                 }}
                                                 InputProps={{
                                                     onFocus: () => {
                                                       setIsOpen(true);
                                                     }
                                                 }}
                                                 open={isOpen}

                                             />
                                         </MuiPickersUtilsProvider>
                                  )}
                                  {type == "weekly" && (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                                        <KeyboardDatePicker
                                            required
                                            autoOk={true}
                                            disablePast
                                            variant="inline"
                                            label="Start Date"
                                            format="MMM d, yyyy"
                                            name="startDate"
                                            minDate={props.minDate}
                                            shouldDisableDate={disableWeekDays.bind(this,props.restrictedDate)}
                                            className="startDate"
                                            value={selectedDateWeekly}
                                            defaultValue={selectedDateWeekly}
                                            onChange={handleDateChangeWeekly}
                                            fullWidth
                                            className="home-date-picker"
                                            KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                            }}
                                            KeyboardButtonProps={{
                                                onFocus: e => {
                                                    setIsOpen(true);
                                                }
                                            }}
                                            PopoverProps={{
                                                disableRestoreFocus: true,
                                                onClose: () => {
                                                    setIsOpen(false);
                                                }
                                            }}
                                            InputProps={{
                                                onFocus: () => {
                                                  setIsOpen(true);
                                                }
                                            }}
                                            open={isOpen}

                                        />
                                    </MuiPickersUtilsProvider>
                                  )}

                                  {(type == "monthly" || type == "1-year" || type == "2-years" ) && (
                                      <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                                         <KeyboardDatePicker
                                             required
                                             autoOk={true}
                                             disablePast
                                             variant="inline"
                                             label="Start Date"
                                             format="MMM d, yyyy"
                                             name="startDate"
                                             minDate={props.minDate}
                                             shouldDisableDate={disableWeekDays.bind(this,props.restrictedDate)}
                                             className="startDate"
                                             value={selectedDateMonthly}
                                             defaultValue={selectedDateMonthly}
                                             onChange={handleDateChangeMonthly}
                                             fullWidth
                                             className="home-date-picker"
                                             KeyboardButtonProps={{
                                             'aria-label': 'change date',
                                             }}
                                             KeyboardButtonProps={{
                                                 onFocus: e => {
                                                     setIsOpen(true);
                                                 }
                                             }}
                                             PopoverProps={{
                                                 disableRestoreFocus: true,
                                                 onClose: () => {
                                                     setIsOpen(false);
                                                 }
                                             }}
                                             InputProps={{
                                                 onFocus: () => {
                                                   setIsOpen(true);
                                                 }
                                             }}
                                             open={isOpen}

                                         />
                                      </MuiPickersUtilsProvider>
                                  )}
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    {type == "daily" && (
                                        <TextField
                                            label="No. of days"
                                            type="number"
                                            name="noDays"
                                            defaultValue={props.product.product_min_days}
                                            value={noDays}
                                            className="home-no-days"
                                            InputProps={{
                                             inputProps: { min: props.product.product_min_days},
                                           }}
                                           onChange={handleDaysChange}
                                           onPaste={handlePasteChange}
                                            fullWidth
                                            required
                                        />
                                    )}

                                    {type == "weekly" && (
                                        <TextField
                                            label="No. of weeks"
                                            type="number"
                                            name="noWeeks"
                                            value={noDays}
                                            className="home-no-days"
                                            InputProps={{
                                             inputProps: { min: 1 }
                                           }}
                                           onChange={handleWeeklyChange}
                                           onPaste={handlePasteChange}
                                            fullWidth
                                            required
                                        />
                                    )}

                                    {type == "monthly" && (
                                        <Autocomplete
                                           freeSolo={false}
                                           options={months.map((option) => option.title)}
                                           onChange={handleMonthlyChange}
                                           renderInput={(params) => (
                                             <TextField {...params} label="No. of months" name="noMonths" type="number" className="home-no-days" required />
                                           )}
                                         />
                                    )}

                                </Grid>
                                <Grid item md={12} sm={12} xs={12} align="center">
                                    <br/>
                                    {btnLoading && (
                                        <>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                fullWidth
                                                disabled={btnLoading}
                                                endIcon={<EventAvailableIcon>Use this date</EventAvailableIcon>}
                                                >
                                                Checking...
                                            </Button>
                                        </>
                                    )}

                                    {(!btnLoading && !useDate) && (
                                        <>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                fullWidth
                                                disabled={btnLoading}
                                                endIcon={<EventAvailableIcon>Use this date</EventAvailableIcon>}
                                                >
                                                Check availability
                                            </Button>
                                        </>
                                    )}

                                    {(!btnLoading && useDate) && (
                                        <>
                                            <Button
                                                type="submit"
                                                variant=""
                                                color="primary"
                                                size="large"
                                                fullWidth
                                                className="proceed-green-btn"
                                                disabled={btnLoading}
                                                endIcon={<EventAvailableIcon>Use this date</EventAvailableIcon>}
                                                >
                                                Proceed Booking
                                            </Button>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                          </Grid>
                      </form>

                  </Grid>
                <Grid item md={6} sm={12} xs={12} align="center">
                    <br/>
                    <i>{calendarNote}</i>
                    {(type == "daily" && !toggleRange) && (
                        <Calendar
                            selectRange={false}
                            calendarType={"US"}
                            minDate={minDate}
                            value={selectedRangeDate}
                            onChange={handleRangeSelect}
                            onClickDay={handleStartDate}
                            allowPartialRange={true}
                            tileDisabled={disableCalendarRestrictedOnly.bind(this,props.restrictedDate,withWeekdays)}
                            tileClassName="cal-date-listing"
                        />
                    )}

                    {(type == "daily" && toggleRange) && (
                        <Calendar
                            selectRange={true}
                            minDate={minDate}
                            calendarType={"US"}
                            value={selectedRangeDate}
                            onChange={handleRangeSelect}
                            onClickDay={handleStartDate}
                            allowPartialRange={true}
                            tileDisabled={disableCalendarRestrictedOnly.bind(this,props.restrictedDate,withWeekdays)}
                            tileClassName="cal-date-listing"
                        />
                    )}

                    {(type == "weekend") && (
                        <Calendar
                            selectRange={false}
                            minDate={minDate}
                            calendarType={"US"}
                            value={selectedRangeDate}
                            onChange={handleRangeSelect}
                            onClickDay={handleStartDate}
                            tileDisabled={disableCalendarRestrictedOnly.bind(this,props.restrictedDate,withWeekdays)}
                            tileClassName="cal-date-listing"
                        />
                    )}

                    {(type == "weekly" || type == "monthly" || type == "1-year" || type == "2-years") && (
                        <Calendar
                            selectRange={false}
                            minDate={minDate}
                            calendarType={"US"}
                            value={selectedRangeDate}
                            onChange={handleRangeSelect}
                            onClickDay={handleStartDate}
                            tileDisabled={disableCalendarRestrictedOnly.bind(this,props.restrictedDate,withWeekdays)}
                            tileClassName="cal-date-listing"
                        />
                    )}
                    <br />
                    <Chip style={{background:"#3CB371",color:"#fff",marginRight:"5px",fontSize:"12px"}} label="Selected" />
                    <Chip style={{background:"#fff",color:"#111",marginRight:"5px",fontSize:"12px"}} label="Available" />
                    <Chip style={{background:"#f0f0f0",fontSize:"12px"}} label="Unavailable" />


                </Grid>

              </Grid>

          );
        }

        function PerProduct(props){
            const classes = useStyles();
            const [expanded, setExpanded] = React.useState(false);
            const [restrictedDates, setRestrictedDates] = React.useState([]);
            const [openPicker, setOpenPicker] = React.useState(false);

            const handleExpandClick = (e) => {
                e.stopPropagation();
                if(!expanded){
                    setExpanded(true);
                    var token = props.token;
                    var bodyFormData = new FormData();
                    bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
                    bodyFormData.append('jham_token', token);
                    bodyFormData.append('product_id', props.product.product_id);

                    axios({
                            method: 'post',
                            url: 'https://api.earental.com/listing/restricted_date',
                            data: bodyFormData,
                            withCredentials: true,
                            headers: {'Content-Type': 'multipart/form-data' }
                        })
                        .then((response) => {
                            if(response.data.data){
                                setRestrictedDates(response.data.data);
                            }

                            setOpenPicker(true);
                        })
                        .catch((error) => {


                    });
                }else{
                    setExpanded(false);
                    setOpenPicker(false);
                }


            };

            var availability = parseInt(props.product.available);
            var notAvailLabel = "Leased out";
            var minDays = parseInt(props.product.product_min_days);
            var withType = true;

            if(props.lType == "daily" && props.lCount < minDays){
                availability = 0;
                notAvailLabel = "Min "+props.product.product_min_days+" days";
            }

            if(props.lType == ""){
                withType = false;
            }


            var clickProduct = handleExpandClick;
            if(availability){
                clickProduct = props.onClickSelect;
            }


              return(
                <>
                  <a className="booking-item" onClick={clickProduct}>
                      <div className="row">
                          <div className="col-md-3">
                              <Grid container>
                                  <Grid item md={12} sm={6} xs={6} align="left">
                                      <div className="booking-item-car-img">
                                      {props.product.product_image &&
                                        <Badge badgeContent={props.product.product_type} className="product-type-badge"
                                            anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                            }}>
                                            <Image
                                                src={"https://api.earental.com/uploads/vehicles/"+props.product.product_image}
                                                alt={props.product.product_name}
                                                title={props.product.product_name}
                                                fallback={<Shimmer width={262} height={200} />}
                                            />
                                        </Badge>

                                      }
                                      <p className="booking-item-car-title"><b>{props.product.product_name}</b></p>
                                      </div>
                                  </Grid>
                                  <Hidden mdUp>
                                      <Grid item sm={1} xs={1} align="left">
                                      </Grid>
                                      <Grid item sm={5} xs={5} align="left">
                                          <ul className="booking-item-features booking-item-features-sign clearfix">
                                              <li rel="tooltip" data-placement="top" title="Passengers"><i className="fa fa-male"></i><span className="booking-item-feature-sign">x {props.product.product_passengers}</span>
                                              </li>
                                              <li rel="tooltip" data-placement="top" title="Doors"><i className="im im-car-doors"></i><span className="booking-item-feature-sign">x {props.product.product_doors}</span>
                                              </li>
                                              {props.product.product_baggage != "0" && (
                                                  <li rel="tooltip" data-placement="top" title="Baggage Quantity"><i className="fa fa-briefcase"></i><span className="booking-item-feature-sign">x {props.product.product_baggage}</span>
                                                  </li>
                                              )}

                                              {props.product.product_transmission == "Automatic" && (
                                                  <li rel="tooltip" data-placement="top" title={props.product.product_transmission}><i className="im im-shift"></i><span className="booking-item-feature-sign">Auto</span>
                                                  </li>
                                              )}

                                              {props.product.product_transmission != "Automatic" && (
                                                  <li rel="tooltip" data-placement="top" title={props.product.product_transmission}><i className="im im-shift"></i><span className="booking-item-feature-sign">{props.product.product_transmission}</span>
                                                  </li>
                                              )}

                                              <li rel="tooltip" data-placement="top" title={props.product.product_gas}><i className="im im-diesel"></i>
                                              <span className="booking-item-feature-sign">{props.product.product_gas}</span>
                                              </li>
                                          </ul>
                                      </Grid>
                                  </Hidden>
                              </Grid>

                                <hr />
                                <Hidden smDown>
                                    <ul className="booking-item-features booking-item-features-sign clearfix">
                                        <li rel="tooltip" data-placement="top" title="Passengers"><i className="fa fa-male"></i><span className="booking-item-feature-sign">x {props.product.product_passengers}</span>
                                        </li>
                                        <li rel="tooltip" data-placement="top" title="Doors"><i className="im im-car-doors"></i><span className="booking-item-feature-sign">x {props.product.product_doors}</span>
                                        </li>
                                        {props.product.product_baggage != "0" && (
                                            <li rel="tooltip" data-placement="top" title="Baggage Quantity"><i className="fa fa-briefcase"></i><span className="booking-item-feature-sign">x {props.product.product_baggage}</span>
                                            </li>
                                        )}

                                        {props.product.product_transmission == "Automatic" && (
                                            <li rel="tooltip" data-placement="top" title={props.product.product_transmission}><i className="im im-shift"></i><span className="booking-item-feature-sign">Auto</span>
                                            </li>
                                        )}

                                        {props.product.product_transmission != "Automatic" && (
                                            <li rel="tooltip" data-placement="top" title={props.product.product_transmission}><i className="im im-shift"></i><span className="booking-item-feature-sign">{props.product.product_transmission}</span>
                                            </li>
                                        )}

                                        <li rel="tooltip" data-placement="top" title={props.product.product_gas}><i className="im im-diesel"></i>
                                            <span className="booking-item-feature-sign">{props.product.product_gas}</span>
                                        </li>
                                    </ul>
                                </Hidden>

                          </div>
                          <div className="col-md-5">
                              <div className="row">
                                  <div className="col-md-12">
                                      <ul className="booking-item-features booking-item-features-small clearfix">
                                          <Equipments equipments={props.product.xve_equipment} price={props.product.xve_price} vid={props.product.product_id} />
                                      </ul>
                                  </div>
                              </div>
                              <hr />
                              <p><EllipsisText text={props.product.product_description} length={80} /></p>
                          </div>
                          <div className="col-md-4 text-right">
                              <ul className="booking-item-features-terms booking-item-features-dark terms-cont text-right">
                                  <TermIcons terms={props.product.product_rent_terms} vid={props.product.product_id} />
                              </ul>
                            <List>
                                {parseFloat(props.product.product_price_day) > 0 && (
                                    <ListItem className="price-list">
                                        <ListItemText edge="start"
                                            primary={<ListTitle title="Daily Rate" />}
                                        />
                                        <ListItemSecondaryAction>
                                            <NumberFormat className="list-price" value={props.product.product_price_day} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}

                                {parseFloat(props.product.product_price_weekend) > 0 && (
                                    <ListItem className="price-list">
                                        <ListItemText edge="start" primary={<ListTitle title="Weekend Rate" />} />
                                        <ListItemSecondaryAction>
                                            <NumberFormat className="list-price" value={props.product.product_price_weekend} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}

                                {parseFloat(props.product.product_price_week) > 0 && (
                                    <ListItem className="price-list">
                                        <ListItemText edge="start" primary={<ListTitle title="Weekly Rate" />} />
                                        <ListItemSecondaryAction>
                                            <NumberFormat className="list-price" value={props.product.product_price_week} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}

                                {parseFloat(props.product.product_price_month) > 0 && (
                                    <>
                                        {(props.product.month_bonus == "1" && props.product.product_month_bonus_rental_fee == "1")
                                          ? <ListItem className="price-list">
                                              <ListItemText edge="start" primary={<ListTitle title="Monthly Rate" />}secondary={
                                                  <React.Fragment>
                                                    <Typography
                                                      component="span"
                                                      variant="body2"
                                                      style={{color:"#d40029",textTransform:"capitalize"}}
                                                    >
                                                     + Free {props.product.product_month_bonus_days_months_count} {props.product.product_month_bonus_type.replace("s", "(s)")} Rent
                                                    </Typography>
                                                  </React.Fragment>
                                                } />
                                              <ListItemSecondaryAction>
                                                  <NumberFormat className="list-price" value={props.product.product_price_year_2} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                              </ListItemSecondaryAction>
                                          </ListItem>
                                          :<ListItem className="price-list">
                                              <ListItemText edge="start" primary={<ListTitle title="Monthly Rate" />} />
                                              <ListItemSecondaryAction>
                                                  <NumberFormat className="list-price" value={props.product.product_price_month} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                              </ListItemSecondaryAction>
                                          </ListItem>
                                        }
                                    </>



                                )}

                                {parseFloat(props.product.product_price_year) > 0 && (
                                    <>
                                        {(props.product.year_bonus == "1" && props.product.product_year_bonus_rental_fee == "1")
                                          ? <ListItem className="price-list">
                                              <ListItemText edge="start" primary={<ListTitle title="1-Yr Monthly Rate" />}secondary={
                                                  <React.Fragment>
                                                    <Typography
                                                      component="span"
                                                      variant="body2"
                                                      style={{color:"#d40029",textTransform:"capitalize"}}
                                                    >
                                                     + Free {props.product.product_year_bonus_days_months_count} {props.product.product_year_bonus_type.replace("s", "(s)")} Rent
                                                    </Typography>
                                                  </React.Fragment>
                                                } />
                                              <ListItemSecondaryAction>
                                                  <NumberFormat className="list-price" value={props.product.product_price_year} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                              </ListItemSecondaryAction>
                                          </ListItem>
                                          :<ListItem className="price-list">
                                              <ListItemText edge="start" primary={<ListTitle title="1-Yr Monthly Rate" />} />
                                              <ListItemSecondaryAction>
                                                  <NumberFormat className="list-price" value={props.product.product_price_year} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                              </ListItemSecondaryAction>
                                          </ListItem>
                                        }
                                    </>
                                )}

                                {parseFloat(props.product.product_price_year_2) > 0 && (

                                    <>
                                        {(props.product.year2_bonus == "1" && props.product.product_year2_bonus_rental_fee == "1")
                                          ? <ListItem className="price-list">
                                              <ListItemText edge="start" primary={<ListTitle title="2-Yr Monthly Rate" />}secondary={
                                                  <React.Fragment>
                                                    <Typography
                                                      component="span"
                                                      variant="body2"
                                                      style={{color:"#d40029",textTransform:"capitalize"}}
                                                    >
                                                     + Free {props.product.product_year2_bonus_days_months_count} {props.product.product_year2_bonus_type.replace("s", "(s)")} Rent
                                                    </Typography>
                                                  </React.Fragment>
                                                } />
                                              <ListItemSecondaryAction>
                                                  <NumberFormat className="list-price" value={props.product.product_price_year_2} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                              </ListItemSecondaryAction>
                                          </ListItem>
                                          :<ListItem className="price-list">
                                              <ListItemText edge="start" primary={<ListTitle title="2-Yr Monthly Rate" />} />
                                              <ListItemSecondaryAction>
                                                  <NumberFormat className="list-price" value={props.product.product_price_year_2} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                              </ListItemSecondaryAction>
                                          </ListItem>
                                        }
                                    </>
                                )}


                            </List>
                            <br />
                              <ButtonGroup variant="contained">
                                  {(!parseInt(availability) && notAvailLabel && withType) && (
                                    <>
                                        <Button size="large" variant="outlined" color="secondary">{notAvailLabel}</Button>
                                        &nbsp;

                                    </>
                                  )}

                                  {!withType && (
                                    <Button size="large" color="primary" onClick={handleExpandClick}
                                    endIcon={<ExpandMoreIcon className={clsx(classes.expand, {
                                     [classes.expandOpen]: expanded,
                                     })} />}

                                    >BOOK NOW</Button>
                                  )}

                                  {(parseInt(availability) && withType) && (
                                    <Button size="large" color="primary"
                                    >BOOK NOW</Button>
                                  )}

                                  <Button size="large"  variant="outlined" style={{background:"#2f96b4",color:"#fff",border:"0"}}
                                   >
                                      Vehicle Info
                                  </Button>
                              </ButtonGroup>
                              {(!parseInt(availability) && notAvailLabel && withType) && (
                                <>
                                    <br />
                                    <small>Click "Leased Out" to check the vehicle availability</small>
                                </>
                              )}
                          </div>
                      </div>
                    </a>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>

                    <hr />
                    {openPicker && (
                        <SearchFormProduct product={props.product} onSelect={props.onSelect} minDate={props.minDate} restrictedDate={restrictedDates} currentRange={props.value} token={props.token}  />
                    )}
                    {!openPicker && (
                         <CircularProgress />
                    )}

                  </Collapse>
                </>

              );
        }


        if (!this.state.noResult) {
            return (
                <div>
                    <div className="row">
                        <div className="col-md-6">
                            <Pager
                                total={this.state.totalPage}
                                current={this.state.currentPage}
                                visiblePages={this.state.visiblePage}
                                titles={{ first: 'First', last: 'Last' }}
                                className="pagination-md"
                                onPageChanged={this.handlePageChanged}
                            />
                            <div className="nav-drop booking-sort">
                                <h5 className="booking-sort-title hidden"><a href="#">Sort: Price (low to high)<i className="fa fa-angle-down"></i><i className="fa fa-angle-up"></i></a></h5>
                                <ul className="nav-drop-menu">
                                    <li><a href="#">Price (high to low)</a>
                                    </li>
                                    <li><a href="#">Van Name (A-Z)</a>
                                    </li>
                                    <li><a href="#">Van Name (Z-A)</a>
                                    </li>
                                    <li><a href="#">Van Type</a>
                                    </li>
                                </ul>
                            </div>
                        </div>


                        <Hidden mdUp>
                            <div className="col-md-6">
                                <div>
                                    {this.state.lType != "" && (
                                        this.renderSelectionValueListing()
                                    )}
                                </div>
                            </div>
                            <Fab aria-label="changedate"
                                style={{position:"fixed",bottom:"10px",right:"10px",zIndex:"10",background:"#2f96b4",color:"#fff" }}
                                onClick={this.onToggleRangeListing}>
                                  <DateRangeOutlinedIcon />
                            </Fab>
                            <Fab aria-label="togglefilter"
                                style={{position:"fixed",bottom:"80px",right:"10px",zIndex:"10",background:"#2f96b4",color:"#fff" }}
                                onClick={this.toggleDrawer.bind(this,true)}>
                                  <FilterListIcon />
                            </Fab>
                        </Hidden>

                        <Hidden smDown>
                            <div className="col-md-6 text-right">
                                <div>
                                    {this.state.lType != "" && (
                                        this.renderSelectionValueListing()
                                    )}
                                    &nbsp;
                                    &nbsp;
                                    <ButtonGroup variant="contained">
                                        <Button size="large" color="primary" variant="contained"
                                        color="primary"
                                        onClick={this.onToggleRangeListing} endIcon={<EditIcon />}>
                                            Change Date
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </Hidden>


                    </div>


                    <ul className="booking-list">
                        <div className={this.state.loadingclass}>
                            <li>
                                <div className="booking-item">
                                    <MyLoader  />
                                </div>
                            </li>
                            <li>
                                <div className="booking-item">
                                    <MyLoader  />
                                </div>
                            </li>
                            <li>
                                <div className="booking-item">
                                    <MyLoader  />
                                </div>
                            </li>
                            <li>
                                <div className="booking-item">
                                    <MyLoader  />
                                </div>
                            </li>
                        </div>
                        { this.state.products.map(product =>
                                <li key={product.product_id}>
                                    <div className={this.state.hideClass}>
                                        <PerProduct
                                            product={product}
                                            value={this.state.valueRangeListing}
                                            onSelect={this.onSelectRangeProduct.bind(this,product)}
                                            onClickSelect={this.handleSelectVehicle.bind(this,product)}
                                            lType={this.state.lType}
                                            lCount={this.state.lCount}
                                            minDate={this.state.minDate}
                                            token={this.state.passToken} />

                                    </div>
                                </li>
                            )
                        }


                    </ul>
                    <div className="row">
                        <div className="col-md-6">
                            <Pager
                                total={this.state.totalPage}
                                current={this.state.currentPage}
                                visiblePages={this.state.visiblePage}
                                titles={{ first: 'First', last: 'Last' }}
                                className="pagination-md"
                                onPageChanged={this.handlePageChanged}
                            />



                        </div>

                    </div>
                </div>
            );
        }else{
            return (
                <div className="vehicle-listing text-center">
                    <div className="row">
                        <div className="col-md-6">

                        </div>
                        <div className="col-md-6 text-right">
                            {this.state.lType != "" && (
                                <div>this.renderSelectionValueListing()</div>
                            )}

                            <Button
                                style={{fontSize:"13px"}}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={this.onToggleRangeListing} endIcon={<EditIcon />}>
                              Change Date
                            </Button>
                        </div>
                    </div>
                    <br />
                    <p className="search-icon"><b><i class="fa fa-search"></i></b></p>
                    <p className="not-found"><b>No Result Found</b></p>
                    <p>We cannot find the product you are searching <br /> try changing your filters
                    </p>
                </div>
            );
        }


    }

    renderPopup() {
        return (
            <div className="mfp-with-anim mfp-hide mfp-dialog mfp-search-dialog" id="search-dialog">
                <h3>Select your desired date</h3>
                <form onSubmit={this.handleSubmit}>
                    <div className="input-daterange" data-date-format="MM d, D">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group form-group-lg form-group-icon-left"><i className="fa fa-calendar input-icon input-icon-highlight"></i>
                                    <label>Start Date</label>

                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group form-group-lg form-group-icon-left"><i className="fa fa-calendar input-icon input-icon-highlight"></i>
                                    <label>End Date</label>

                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="btn btn-primary btn-lg" type="submit">Search</button>
                </form>
            </div>
        );
    }


  render() {
      function DateRangeListing(props){
          const stateDefinitions = {
              available: {
                color: null,
                label: 'Available',
              }
            };
            const dateRanges = [
            ];


            return(
                <DateRangePicker
                  numberOfCalendars={2}
                  selectionType='range'
                  minimumDate={props.minDate}
                  stateDefinitions={stateDefinitions}
                  dateStates={dateRanges}
                  defaultState="available"
                  value={props.value}
                  onSelect={props.onSelect}
                  showLegend={true}
                  className="text-center"
                  singleDateRange={true}
                />
            );
      }

      function disableWeekDays(date) {
          return date.getDay() === 6 || date.getDay() === 0;
      }

      function disableWeekEnd(date) {
          return date.getDay() === 1 || date.getDay() === 2 || date.getDay() === 3 || date.getDay() === 4 || date.getDay() === 6 || date.getDay() === 0;
      }

      function StyledRadio(props) {
          const classes = useStyles();

          return (
              <Radio
                className={classes.root}
                disableRipple
                color="default"
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                icon={<span className={classes.icon} />}
                {...props}
              />
          );
      }


      function SearchForm(props){
            const classes = useStyles();
            const [isOpen, setIsOpen] = React.useState(false);
            const [selectedDate, setSelectedDate] =  React.useState(props.startDateDaily);
            const [selectedDateWeekEnd, setSelectedDateWeekEnd] =  React.useState(props.startDateWeekend);
            const [selectedDateWeekly, setSelectedDateWeekly] =  React.useState(props.startDateWeekly);
            const [selectedDateMonthly, setSelectedDateMonthly] =  React.useState(props.startDateMonthly);
            const [noDays, setNoDays] =  React.useState(null);
            const [calendarNote, setCalendarNote] = React.useState("Note: Saturday/Sunday not available as start/end date");
            var currentLtype = props.lType;
            if(props.lType == ""){
                currentLtype = "monthly";
            }
            const [type, setType] = React.useState(currentLtype);
            const handleDateChange = (date) => {
                if(selectedDate !== null){
                    setIsOpen(false);
                }
                setSelectedDate(date);

            };

            const handleDateChangeWeekEnd = (date) => {
            if(selectedDateWeekEnd !== null){
            setIsOpen(false);
            }
            setSelectedDateWeekEnd(date);
            };

          const handleDateChangeWeekly = (date) => {
              if(selectedDateWeekly !== null){
                  setIsOpen(false);
              }
              setSelectedDateWeekly(date);
          };

          const handleDateChangeMonthly = (date) => {
              if(selectedDateMonthly !== null){
                  setIsOpen(false);
              }
              setSelectedDateMonthly(date);
          };

          const handlePasteChange = (e) => {
              e.preventDefault();
          }

          const handleDaysChange = (e) => {
              e.preventDefault();
              if(e.target.value == ""){
                  setNoDays("");
              }else{
                  if(parseInt(e.target.value) <= 0 || isNaN(parseInt(e.target.value))){
                      setNoDays(1);
                  }else{
                      setNoDays(e.target.value);
                  }
              }

          }


         const days = [
         ];

         const months = [
             { title: "1" },
             { title: "2" },
             { title: "3" },
             { title: "4" },
             { title: "5" },
             { title: "6" },
             { title: "7" },
             { title: "8" },
             { title: "9" },
             { title: "10" },
             { title: "11" },
         ];

         const handleTypeChange = (e) => {
             setType(e.target.value)
             if(e.target.value == "daily"){
                 setCalendarNote("Note: Saturday/Sunday not available as start/end date");
             }else if(e.target.value == "weekend"){
                 setCalendarNote("Note: Weekend Fri – Mon (3 days)");
             }else if(e.target.value == "weekly"){
                 setCalendarNote("Note: Saturday/Sunday not available as start/end date");
             }else if(e.target.value == "monthly"){
                 setCalendarNote("Note: Saturday/Sunday not available as start/end date");
             }else if(e.target.value == "1-year"){
                 setCalendarNote("Note: Saturday/Sunday not available as start/end date");
             }else if(e.target.value == "2-years"){
                 setCalendarNote("Note: Saturday/Sunday not available as start/end date");
             }
         };


         function StyledRadio(props) {
             const classes = useStyles();

             return (
                 <RadioMu
                   className={classes.root}
                   disableRipple
                   color="default"
                   checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                   icon={<span className={classes.icon} />}
                   {...props}
                 />
             );
         }


        return(
            <Grid container spacing={0} className={classes.newCardForm}>
              <Grid item md={4} sm={5} xs={5}>
                  <FormControl component="fieldset">
                      <FormLabel component="legend">Leasing Type</FormLabel>
                      <RadioGroup defaultValue={type} value={type} aria-label="leasing-type" onChange={handleTypeChange} name="leasingType">
                          <FormControlLabel value="daily" control={<StyledRadio />} label="Daily" />
                          <FormControlLabel value="weekend" control={<StyledRadio />} label="Weekend" />
                          <FormControlLabel value="weekly" control={<StyledRadio />} label="Weekly" />
                          <FormControlLabel value="monthly" control={<StyledRadio />} label="Monthly" />
                          <FormControlLabel value="1-year" control={<StyledRadio />} label="1-Year Lease" />
                          <FormControlLabel value="2-years" control={<StyledRadio />} label="2-Year Lease" />
                      </RadioGroup>
                  </FormControl>
              </Grid>
              <Grid container md={8} sm={7} xs={7} spacing={2} className="home-search-right">
                  <i>{calendarNote}</i>
                  <Grid item md={12} sm={12} xs={12}>
                      <br />
                      {type == "weekend" && (
                          <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                              <KeyboardDatePicker
                                  required
                                  autoOk={true}
                                  disablePast
                                  variant="inline"
                                  label="Start Date"
                                  format="MMM d, yyyy"
                                  name="startDate"
                                  className="startDate"
                                  minDate={props.minDate}
                                  shouldDisableDate={disableWeekEnd}
                                  value={selectedDateWeekEnd}
                                  defaultValue={selectedDateWeekEnd}
                                  onChange={handleDateChangeWeekEnd}
                                  fullWidth
                                  className="home-date-picker"
                                  KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                  }}
                                  KeyboardButtonProps={{
                                  onFocus: e => {
                                      setIsOpen(true);
                                  }
                                  }}
                                  PopoverProps={{
                                  disableRestoreFocus: true,
                                  onClose: () => {
                                      setIsOpen(false);
                                  }
                                  }}
                                  InputProps={{
                                  onFocus: () => {
                                      setIsOpen(true);
                                  }
                                  }}
                                  open={isOpen}

                              />
                          </MuiPickersUtilsProvider>
                      )}

                       {type == "daily" && (
                           <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                               <KeyboardDatePicker
                                   required
                                   autoOk={true}
                                   disablePast
                                   variant="inline"
                                   label="Start Date"
                                   format="MMM d, yyyy"
                                   name="startDate"
                                   minDate={props.minDate}
                                   shouldDisableDate={disableWeekDays}
                                   className="startDate"
                                   value={selectedDate}
                                   defaultValue={selectedDate}
                                   onChange={handleDateChange}
                                   fullWidth
                                   className="home-date-picker"
                                   KeyboardButtonProps={{
                                   'aria-label': 'change date',
                                   }}
                                   KeyboardButtonProps={{
                                       onFocus: e => {
                                           setIsOpen(true);
                                       }
                                   }}
                                   PopoverProps={{
                                       disableRestoreFocus: true,
                                       onClose: () => {
                                           setIsOpen(false);
                                       }
                                   }}
                                   InputProps={{
                                       onFocus: () => {
                                         setIsOpen(true);
                                       }
                                   }}
                                   open={isOpen}

                               />
                           </MuiPickersUtilsProvider>
                      )}
                      {type == "weekly" && (
                        <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                            <KeyboardDatePicker
                                required
                                autoOk={true}
                                disablePast
                                variant="inline"
                                label="Start Date"
                                format="MMM d, yyyy"
                                name="startDate"
                                minDate={props.minDate}
                                className="startDate"
                                value={selectedDateWeekly}
                                shouldDisableDate={disableWeekDays}
                                defaultValue={selectedDateWeekly}
                                onChange={handleDateChangeWeekly}
                                fullWidth
                                className="home-date-picker"
                                KeyboardButtonProps={{
                                'aria-label': 'change date',
                                }}
                                KeyboardButtonProps={{
                                    onFocus: e => {
                                        setIsOpen(true);
                                    }
                                }}
                                PopoverProps={{
                                    disableRestoreFocus: true,
                                    onClose: () => {
                                        setIsOpen(false);
                                    }
                                }}
                                InputProps={{
                                    onFocus: () => {
                                      setIsOpen(true);
                                    }
                                }}
                                open={isOpen}

                            />
                        </MuiPickersUtilsProvider>
                      )}

                      {(type == "monthly" || type == "1-year" || type == "2-years" ) && (
                          <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                             <KeyboardDatePicker
                                 required
                                 autoOk={true}
                                 disablePast
                                 variant="inline"
                                 label="Start Date"
                                 format="MMM d, yyyy"
                                 name="startDate"
                                 minDate={props.minDate}
                                 className="startDate"
                                 value={selectedDateMonthly}
                                 shouldDisableDate={disableWeekDays}
                                 defaultValue={selectedDateMonthly}
                                 onChange={handleDateChangeMonthly}
                                 fullWidth
                                 className="home-date-picker"
                                 KeyboardButtonProps={{
                                 'aria-label': 'change date',
                                 }}
                                 KeyboardButtonProps={{
                                     onFocus: e => {
                                         setIsOpen(true);
                                     }
                                 }}
                                 PopoverProps={{
                                     disableRestoreFocus: true,
                                     onClose: () => {
                                         setIsOpen(false);
                                     }
                                 }}
                                 InputProps={{
                                     onFocus: () => {
                                       setIsOpen(true);
                                     }
                                 }}
                                 open={isOpen}

                             />
                          </MuiPickersUtilsProvider>
                      )}
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                      {type == "daily" && (
                          <TextField
                              label="No. of days"
                              type="number"
                              name="noDays"
                              value={noDays}
                              className="home-no-days"
                              InputProps={{
                               inputProps: { min: 1},
                             }}
                             onChange={handleDaysChange}
                             onPaste={handlePasteChange}
                              fullWidth
                              required
                          />
                      )}

                      {type == "weekly" && (
                          <TextField
                              label="No. of weeks"
                              type="number"
                              name="noWeeks"
                              value={noDays}
                              className="home-no-days"
                              InputProps={{
                               inputProps: { min: 1 }
                             }}
                             onChange={handleDaysChange}
                             onPaste={handlePasteChange}
                              fullWidth
                              required
                          />
                      )}

                      {type == "monthly" && (
                          <Autocomplete
                             freeSolo={false}
                             options={months.map((option) => option.title)}
                             renderInput={(params) => (
                               <TextField {...params} label="No. of months" name="noMonths" type="number" className="home-no-days" required />
                             )}
                           />
                      )}

                  </Grid>
                  <Grid item md={12} sm={12} xs={12} align="center">
                      <br/>
                      <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth
                          endIcon={<SearchIcon>Start Search</SearchIcon>}
                          >
                          Start Search
                      </Button>
                  </Grid>
              </Grid>

            </Grid>
        );
      }

    return (
        <div>
            <div className="container">
                {this.renderPopup()}
                <div>
                    <Hidden mdUp>
                        <br />
                        <h3 className="booking-title-mobile">
                            Van Rental
                        </h3>
                    </Hidden>
                    <Hidden smDown>
                        <h3 className="booking-title">
                            Van Rental
                        </h3>
                    </Hidden>
                  <SwipeableDrawer
                    anchor={"left"}
                    open={this.state.drawerOpen}
                    onClose={this.toggleDrawer.bind(this,false)}
                    onOpen={this.toggleDrawer.bind(this,false)}
                  >
                    {this.renderSideFilter("bfilter-mobile")}
                  </SwipeableDrawer>

                </div>

                <div className="row">
                    <div className="col-md-3">
                            {this.renderSideFilter("bfilter-desktop")}
                    </div>
                    <div className="col-md-9">
                        {this.state.isOpenRangeListing && (
                            <form onSubmit={ this.handleSubmit }>
                                <SearchForm
                                    value={this.state.valueRangeListing}
                                    onSelect={this.onSelectRangeListing}
                                    minDate={this.state.minDate}
                                    startDateDaily={this.state.startDateDaily}
                                    startDateWeekend={this.state.startDateWeekend}
                                    startDateWeekly={this.state.startDateWeekly}
                                    startDateMonthly={this.state.startDateMonthly}
                                    lType={this.state.lType}


                                />
                                <br />
                                <br />
                            </form>

                        )}
                        {this.renderListing()}

                    </div>
                </div>
                <div className="gap"></div>
            </div>
            <ScriptTag isHydrating={true} type="text/javascript" src="js/vehicle/vehicle.js" />


        </div>

    )
  }
}
