import React, { Component,useState,useEffect } from 'react';
import ReactDOM from "react-dom";
import {
  NavLink,
  HashRouter
} from "react-router-dom";
import axios, { post } from 'axios';
import { loadProgressBar } from 'axios-progress-bar'
import PropTypes from 'prop-types';
import OtpInput from 'react-otp-input';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment';
import { store } from 'react-notifications-component';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactGA from 'react-ga';
import moment from "moment";
import queryString from 'query-string';
import "./assets/css/register.css";

import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// const useStyles = makeStyles((theme) => ({
const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  otpInput: {
    textAlign: 'center',
    fontSize: 30,
    '& div': {
      width: '200px',
      '& div': {
        width: '200px',
        '& input':{
          width: '200',
          color: 'red'
        }
      }
    }
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
});

class Register extends React.Component {
//function Register({otp,handleChangeOtp}){

  constructor (props) {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);

    super(props)
    this.state ={
        passToken:localStorage.getItem('token'),
        smsphone: this.state.smsphone,
        otp: this.state.otp,
        otp_number: this.state.otp_number,
        smscounter: this.state.smscounter,
        otpBtn:'1',
        smsTimer: 0,
        uid: this.state.uid,
        BtnTxtSms:"Send SMS code",
        firstName: "",
        lastName: "",
        email: "",
        password:"",
        dob:null,
        nric: "",
        extraEmails:true,
        openModal: false,
        smsInvertal: null,
        codeEmail: false,
        invitationCode: ""
    }
    this.onFormSubmitPhone = this.onFormSubmitPhone.bind(this);
    this.onFormSubmitRegister = this.onFormSubmitRegister.bind(this);
    this.handlerState = this.handlerState.bind(this); //kpg function iba ata kulay
    this.handleClose = this.handleClose.bind(this);
    this.handlerChange = this.handlerChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleChangeOtp = this.handleChangeOtp.bind(this);
  }

  componentDidMount () {
    axios.get('https://api.earental.com/listing/getPass?c='+moment().unix(), { withCredentials: true })
    .then((response) => {
      localStorage.setItem('token', response.data.token);
      this.setState({passToken:response.data.token});

      const urlQuery = new URLSearchParams(window.location.search);
      const invitationCode = urlQuery.get('c');
      if(invitationCode){
        var bodyFormData = new FormData();
        bodyFormData.append('invitationCode', invitationCode);
        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
        bodyFormData.append('jham_token', this.state.passToken);
        axios({
          method: 'post',
          url: 'https://api.earental.com/account/check_invitation_code',
          data: bodyFormData,
          withCredentials: true,
          headers: {'Content-Type': 'multipart/form-data' }
        })
        .then((response) => {
          if(response.data.error > 0){
              this.setState({
                  codeEmail:false,
                  email:"",
                  invitationCode:""
              })

          }else{
              this.setState({
                  codeEmail:response.data.email,
                  email:response.data.email,
                  invitationCode:invitationCode
              })
          }

          // window.scrollTo(0, 0);
        })
        .catch((error) => {
            this.setState({
                codeEmail:false,
                email:"",
                invitationCode:""
            })
        });
      }


    })
    .catch((error) => {
      // handle error
    });






  }

  onFormSubmitPhone(event){
    event.preventDefault();

    loadProgressBar();

    var bodyFormData = new FormData();
    var token = this.state.passToken;
    // this.setState({ loading: true });
    bodyFormData.append('smsphone', event.target.smsphone.value);
    bodyFormData.append('jham_token', token);

    this.setState({ otpBtn: "" });

    axios({
            method: 'post',
            url: 'https://api.earental.com/account/get_sms_code',
            data: bodyFormData,
            withCredentials: true,
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then((response) => {
          //  this.setState({ loading:false });
          this.setState({otp_number:response.data.otp_number});
           if(response.data.error > 0){
               store.addNotification({
                 title: "Something went wrong",
                 message: response.data.message,
                 type: "danger",
                 insert: "top",
                 container: "top-right",
                 animationIn: ["animated", "fadeIn"],
                 animationOut: ["animated", "fadeOut"],
                 dismiss: {
                   duration: 5000,
                   onScreen: false
                 }
               });
               this.setState({ otpBtn: "1" });// wait my seartch lng ako

               var smscounter = 5
               const interval = setInterval(() => {
                 console.log(smscounter);
                 smscounter = smscounter-1;
                 if(smscounter <= 0){
                  clearInterval(interval);
                   this.setState({ BtnTxtSms: "Send SMS code" });
                   this.setState({ otpBtn: "1" });
                 }else{
                  var btnTxtSmsCode =  "("+smscounter+") Before re-send";
                  this.setState({ BtnTxtSms: btnTxtSmsCode });
                  this.setState({ otpBtn: "" });
                 }
               }, 1000);
           }else{
              var type_error = "success"
              var errormessage = "Something went wrong."
              if(response.data.data.code == 1002){
                type_error = "danger"
                this.setState({ otpBtn: "1" });

                store.addNotification({
                  title: "Something went wrong.",
                  message: response.data.message,
                  type: type_error,
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                    duration: 5000,
                    onScreen: false
                  }
                });

                var smscounter = 5
                const interval = setInterval(() => {
                  smscounter = smscounter-1;
                  if(smscounter <= 0){
                    clearInterval(interval);
                    this.setState({ BtnTxtSms: "Send SMS code" });
                    this.setState({ otpBtn: "1" });
                  }else{
                    var btnTxtSmsCode =  "("+smscounter+") Before re-send";
                    this.setState({ BtnTxtSms: btnTxtSmsCode });
                    this.setState({ otpBtn: "" });
                  }
                }, 1000);
              }else{
                type_error = "success"

                this.setState({ otpBtn: "" });

                this.setState({ uid: response.data.data.uid });

               store.addNotification({
                 title: "SMS Code sent!",
                 message: response.data.message,
                 type: type_error,
                 insert: "top",
                 container: "top-right",
                 animationIn: ["animated", "fadeIn"],
                 animationOut: ["animated", "fadeOut"],
                 dismiss: {
                   duration: 60000,
                   onScreen: false
                 }
               });

                this.setState({smsTimer:60});
                const interval = setInterval(() => {
                  var smscounter = this.state.smsTimer;
                  console.log(smscounter);
                  smscounter = smscounter-1;//ndi pala tstsk
                  if(smscounter <= 0){
                    this.setState({ BtnTxtSms: "Send SMS code" });
                    clearInterval(interval); //tae nasira ahahaha tanggalin ko ung inadd ko
                    this.setState({ otpBtn: "1" });
                  }else{
                    var btnTxtSmsCode =  "("+smscounter+") Before re-send";
                    this.setState({ BtnTxtSms: btnTxtSmsCode });
                    this.setState({ otpBtn: "" });
                  }
                  this.setState({ smsTimer: smscounter });
                }, 1000);

                this.setState({ smsInvertal: interval});
              }
              //  this.setState({ redirect: true })

               localStorage.setItem('uid', response.data.data.uid);
              console.log(response.data.data);

              //  setTimeout(
              //    () => window.location.href = "/",
              //    2000
              //  );


           }

        })
        .catch((error) => {
            //handle error
            this.setState({ otpBtn: "1" });
            console.log(error.response);
            // this.setState({ loading:false });
          //   if (error.response.status == 403) {

          //       store.addNotification({
          //         title: "Oops Something went wrong",
          //         message: "The action you have requested is not allowed. please re-load the page",
          //         type: "danger",
          //         insert: "top",
          //         container: "top-right",
          //         animationIn: ["animated", "fadeIn"],
          //         animationOut: ["animated", "fadeOut"],
          //         dismiss: {
          //           duration: 5000,
          //           onScreen: false
          //         }
          //       });

          //  }

    });

      // fetch('https://api.earental.com/account/login', {
      //   crossDomain:true,
      //   method: 'POST',
      //   headers: {'Content-Type':'application/json'},
      //   body: JSON.stringify({
      //     email: event.target.email.value,
      //     password: event.target.password.value,
      //     jham_token: '1950f1d50a4c2543a6ccc755ef676357'
      //   })
      // })
  }

  onFormSubmitRegister(event){
    event.preventDefault();

    loadProgressBar();

    this.setState({
      firstName: event.target.firstName.value,
      lastName: event.target.lastName.value,
      email: event.target.email.value,
      password: event.target.password.value,
      extraEmails: event.target.extraEmails.value
    })

    var bodyFormData = new FormData();
    var token = this.state.passToken;
    // if(this.state.dob){
    //     var dob = moment(this.state.dob).format("YYYY-MM-DD");
    // }else{
    //     var dob = 0
    // }

    // this.setState({ loading: true });
    bodyFormData.append('phoneNumber', this.state.otp_number);
    // bodyFormData.append('phoneNumber', "+6588888886");
    bodyFormData.append('firstName', this.state.firstName);
    bodyFormData.append('lastName', this.state.lastName);
    bodyFormData.append('email', this.state.email);
    bodyFormData.append('password', this.state.password);
    bodyFormData.append('extraEmails', this.state.extraEmails);
    bodyFormData.append('verification', this.state.otp);
    bodyFormData.append('uid', this.state.uid);
    bodyFormData.append('otp_number', this.state.otp_number);
    bodyFormData.append('invitationCode', this.state.invitationCode);
    // bodyFormData.append('dob', dob);
    // bodyFormData.append('nric', this.state.nric);
    bodyFormData.append('jham_token', token);


    axios({
            method: 'post',
            url: 'https://api.earental.com/account/register',
            data: bodyFormData,
            withCredentials: true,
            headers: {'Content-Type': 'multipart/form-data' }
    })
    .then((response) => {
      console.log(response.data);
      //  this.setState({ loading:false });
        clearInterval(this.state.smsInvertal);
        if(response.data.status == "0"){
            store.addNotification({
              title: "Something went wrong",
              message: response.data.data,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: false
              }
            });
            clearInterval(this.state.smsInvertal);
            this.setState({
              BtnTxtSms: "Send SMS code",
              otpBtn: "1",
            });

        }else{
          clearInterval(this.state.smsInvertal);
          this.setState({
            smsphone: "",
            openModal: true,
            smsTimer:0,
            otp: "",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            otp: "",
            uid: "",
            dob: null,
            nric: "",
            BtnTxtSms: "Send SMS code",
            otpBtn: "1",
          });

          ReactGA.event({
            category: 'User',
            action: 'Created an Account'
          });
        }

    })
    .catch((error) => {
        //handle error
        console.log(error);
        console.log(error.response);
        clearInterval(this.state.smsInvertal);
        this.setState({
          loading:false,
          BtnTxtSms: "Send SMS code",
          otpBtn: "1",
        });


        store.addNotification({
          title: "Oops Something went wrong",
          message: "The action you have requested is not allowed. please re-load the page",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: false
          }
        });

        setTimeout(
            () => window.location.href = "/sign-up",
            1500
        );



    });
  }

  state = { otp: "" };

  handlerState(key,val) {

    this.props.handlerState(key,val);
  }

  handleChangeOtp = otp => this.setState({ otp });

  // handleChangeOtp(key,otp){
  //   this.setState({ otp })
  //   console.log(otp);
  // }

  handleClickOpen = () => {
    this.setState({openModal:true});
  };

  // handleClose = () => {
  //   console.log(this.state.openModal);
  //   // this.setState({
  //   //   openModal:false
  //   // });
  //   this.setState({openModal:false});
  // };
  handleClose(date) {
    this.setState({
      openModal:false,
    })
    console.log(this.state.openModal);
  }


  handlerChange = (evt) => {
    if(evt.target.name == "extraEmails"){
      if(evt.target.checked == true){
        this.setState({ [evt.target.name]: true});
      }else{
        this.setState({ [evt.target.name]: false });
      }
      console.log(evt.target.checked);
      console.log(evt.target.value);
    }else{
      this.setState({ [evt.target.name]: evt.target.value });
    }
  };


  handlerChangeDob = (date) => {
     this.setState({ dob: date });
  };


  render() {
    console.log(this.state);
    // const classes = useStyles(); nilagyan ko ng ()
    const { classes } = this.props;
    //nasan ung pang open
    return (
      <Container component="main" maxWidth="lg" className="register-cont">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h3">
            Create your account
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <form className={classes.form} noValidate  onSubmit={this.onFormSubmitPhone}>
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="smsphone"
                      label="Phone Number"
                      name="smsphone"
                      autoComplete="smsphone"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">+65</InputAdornment>,
                        maxLength: 12,
                      }}
                      maxLength="8"
                      autoFocus
                      value={this.state.smsphone || ""}
                      onChange={this.handlerChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className="otpInput">
                      <Button variant="contained" color="primary" type="submit" className={classes.button} disabled={!this.state.otpBtn} >
                        {this.state.BtnTxtSms}
                        <SendIcon className={classes.rightIcon} />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div>
                      <Typography component="h3" variant="h5">
                        SMS Verification Code*
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="otpInput">
                      <OtpInput
                        value={this.state.otp}
                        onChange={this.handleChangeOtp}
                        numInputs={6}
                        separator={<span>-</span>}
                      />
                    </div>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={12} sm={6}>
              <form className={classes.form}  onSubmit={this.onFormSubmitRegister} autocomplete="off">
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="firstName"
                      name="firstName"
                      variant="outlined"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      value={this.state.firstName}
                      onChange={this.handlerChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="lname"
                      value={this.state.lastName}
                      onChange={this.handlerChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                      {(this.state.codeEmail)
                          ?
                              <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="new-email"
                                value={this.state.codeEmail}
                                disabled
                              />
                          :
                              <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                type="email"
                                label="Email Address"
                                name="email"
                                autoComplete="new-email"
                                value={this.state.email}
                                onChange={this.handlerChange}
                              />

                      }

                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      value={this.state.password}
                      onChange={this.handlerChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox color="primary"
                      value={this.state.extraEmails}
                      onChange={this.handlerChange}/>}
                      checked={this.state.extraEmails}
                      label="I want to receive marketing promotions and updates via email."
                      name="extraEmails"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className="registerSubmit"
                    >
                      Sign Up
                    </Button>
                    <Grid container justify="flex-end">
                      <Grid item>
                          <NavLink exact to="/sign-in" className="form-links">Already have an account? Sign in</NavLink>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </div>
        <div className="gap"></div>
        <div>
          <Dialog
            open={this.state.openModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Email confirmation link sent!</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" className='signupmodal'>
                A confirmation email has been sent to your mailbox
                  <Typography className="signup_email" component={'span'}>
                  {this.state.email}
                  </Typography>
                <br />
                  Please check your email inbox and continue your registration within 24 hours
                <br/>
                Thank you!!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={this.handleClose} autoFocus className="bigBtn">
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Container>
    );
  //}
  }
}
Register.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(Register);
