import React, { Component } from "react";
import {Helmet} from "react-helmet";
import "./../assets/css/aboutus.css";



class Terms extends Component {

    constructor (props) {
      super(props)
      this.state = {
        startDate: new Date(),
        endDate: new Date(),
        MetaTitle:"EARental | Review Policy",
        MetaDescription:"EARental is Singapore No.1 Online Van, Lorry & Truck commercial vehicles for lease for businesses and individuals. Hassle-Free, book and reserve your vehicle Online Now!",
        MetaKeywords:"review policy,online rental, van rental, lorry rental, truck rental, commercial vehicles for rent, leasing, daily rental, weekly rental, weekend rental, monthly rental, yearly rental, short term rental, long term rental, cargo van rental singapore, cheap van rental, van rental price, Toyota hiace van rental singapore, van rental daily, auto van rental singapore, commercial van for rent, van rental near me, daily van rental singapore",
        Canonical:"https://www.earental.com/review-policy",
      };
    }



  componentDidMount() {
      window.scrollTo(0, 0)
    }

  render() {
    return (
        <div classNameName="about-us-cont">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="title" content={this.state.MetaTitle} />
                <meta content={this.state.MetaTitle} property="og:title" />
                <meta name="description" content={this.state.MetaDescription} />
                <meta content={this.state.MetaDescription} property="og:description" />
                <meta name="keywords" content={this.state.MetaKeywords} />
                <meta name="robots" content="index, follow" />
                <title>{this.state.MetaTitle}</title>
                <link rel="canonical" href={this.state.Canonical} />
            </Helmet>
            <div className="container">
                <h1 className="page-title">EARental's Review Policy</h1>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <article className="post">
                            <header className="post-header"></header>
                            <div className="post-inner">
                                <h5>1. Reviews should not violate our content policy.</h5>
                                <p>
                                    Some content is never allowed on EARental. Read EARental’s <a href="/content-policy">Content Policy</a>.
                                </p>

                                <div className="gap gap-small"></div>
                                <h5>2. Reviews should be unbiased.</h5>
                                <p>
                                    Reviews are most helpful when they provide unbiased information. Therefore, we don't allow individuals or entities who own or are affiliated with a listing or experience to post reviews of their business, nor do we allow individuals who are confirmed to offer competing listings or experiences to post reviews of their direct competitors.
                                </p>
                                <p>
                                    You are not allowed to incentivise positive reviews, to use the threat of a negative review to manipulate a desired outcome, or to influence another’s review with the promise of compensation.
                                </p>

                                <p>
                                    You are also not allowed to accept fake reservations in exchange for a positive review, use a second account to leave yourself a review, or coordinate with business partners to get positive reviews.
                                </p>

                                <div className="gap gap-small"></div>
                                <h5>3. Reviews should be relevant.</h5>
                                <p>
                                    Keep your reviews relevant to EARental and your lease experience. Other lessors and lesses are reading your reviews to learn about the lessor and listing. Reviews that are off-topic are distracting and don’t help our guests make informed booking decisions. For this reason, reviews should focus on your interactions with other community members and your time during the stay or experience.
                                </p>

                                <p>
                                    To keep reviews relevant, we recommend avoiding the following:
                                </p>
                                <ul style={{marginLeft:"35px"}}>
                                    <li>Commentary about a person’s social, political or religious views</li>
                                    <li>Profanity, name calling, and assumptions about a person’s character or personality</li>
                                    <li>Content that refers to circumstances entirely outside of another’s control</li>
                                    <li>Content about services not related to EARental (ex. an airline, rideshare, restaurant, etc)</li>
                                    <li>Commentary about past EARental reservations, lessors, or lessees, or about the EARental product where it does not relate to the listing, lessor or lessee you are rating</li>
                                </ul>
                                <p>
                                    When we receive a report of a review that violates this policy, we may remove the review from our platform. Repeated violations may lead to suspension or permanent deactivation of responsible account(s).
                                </p>

                                <div className="gap gap-small"></div>
                                <h5>Reporting a review that violates this policy</h5>
                                <p>
                                    To report a review for violating EARental’s review policy, <a href="/contact">contact us</a>.
                                </p>

                                <div className="gap gap-small"></div>
                                <h5>If you feel a review is untrue</h5>
                                <p>
                                    While we encourage and expect all community members to post reviews that contain objective and accurate information, EARental does not mediate disputes concerning truth or fairness. We expect the author of the review to stand behind the content of their review.
                                </p>

                            </div>
                        </article>
                    </div>
                </div>
            </div>

        </div>
    );
  }
}

export default Terms;
