import React from "react";
import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider
} from '@material-ui/core/styles';
import axios from 'axios';
import { store } from 'react-notifications-component';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import SignatureCanvas from 'react-signature-canvas'
import { orange } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactGA from 'react-ga';
import CheckoutStepper from './CheckoutStepper';
import "../assets/css/checkout.css";

const useStyles = theme => ({
  layout: {
    padding: theme.spacing(5, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
    },
    '& .MuiButtonBase-root': {
      fontSize: '1.1rem',
    },
    '& .MuiTypography-body1': {
      fontSize: '1.25rem',
    },
  },
  title: {
    fontWeight: 300,
    marginBottom: theme.spacing(3),
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    color: "#FFF",
    backgroundColor: "#262626",
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
  },
  agreementPaper: {
    padding: theme.spacing(5, 3),
    '& p': {
      marginTop: theme.spacing(1) + 2,
      marginBottom: theme.spacing(1) + 2,
    },
    '& li': {
      marginLeft: theme.spacing(3),
    },
    '& .img-signature': {
      display: 'block',
      marginBottom: theme.spacing(2),
      width: '100%',
      maxWidth: 200,
    },
    '& td': {
      padding: theme.spacing(1) - 3,
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8),
    }
  },
  signatureDialog: {
    '& header': {
      position: 'relative',
      backgroundColor: '#D40029',
      '& .title': {
        color: '#FFF',
        marginLeft: theme.spacing(2),
        flexGrow: 1,
      },
    },
    '& .signpad-container': {
      height: '100%',
      '& canvas': {
        width: '100%',
        height: '100%',
      },
    },
    '& .MuiButtonBase-root': {
      fontSize: '1.1rem',
    },
  },
  agreementCheckbox: {
    transform: 'scale(1.25)',
  },
});

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#2F96B4',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

function FacebookCircularProgress(props) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}

const theme = createMuiTheme({
  // typography: {
  //   fontSize: 18,
  // }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Agreement extends React.Component {
  constructor(props) {
    super(props);

    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
    ReactGA.plugin.execute('ec', 'setAction', 'agreement', { step: 3 })

    this.api = {
      url: props.mainState.apiURL,
      key: props.mainState.apiKey,
      accessToken: localStorage.getItem('ctoken'),
      jhamToken: localStorage.getItem('token'),
      // jhamToken: "8862828b9645d204ed6c070cfa043644",
    };

    this.state = {
      loading: true,
      origAgreement: "",
      agreement: "",
      signPad: {},
      agreementCheck: false,
      agreementSignature: null,
      openSignatureDialog: false,
      popover: null,
      rawCheckout: null,
      checkoutStepsCompleted: [],
    };
  };

  componentDidMount() {
    // Unset checkout progress
    localStorage.removeItem('checkoutProgress');
    this.props.handlerState('checkoutProgress', null);

    // Get current checkout data
    let formData = new FormData();
        formData.append('api_key', this.api.key);
        formData.append('access_token', this.api.accessToken);
        formData.append('jham_token', this.api.jhamToken);
    axios({
      method: 'post',
      url: this.api.url + '/transaction/current_checkout',
      data: formData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      if (response.data.status == 1) {
        var checkout = response.data.checkout;
        var scheduleData = {
          startDate: checkout.date_from,
          endDate: checkout.date_to,
          rateTitle: checkout.summary.currentRateTitle,
          rateSubTitle: checkout.summary.currentRateSubTitle,
          rateCount: checkout.summary.currentRateCount,
          rateType: checkout.summary.currentRateType,
          rate: checkout.summary.currentRateValue,
          total: checkout.summary.currentTotalRate,
          deposit: checkout.summary.currentDeposit,
          addons: checkout.summary.currentTotalAddons,
          rentalTotal: checkout.summary.rentalTotal,
          discount: checkout.summary.currentPromoDiscount,
        };
        // Get completed checkout steps
        var checkoutStepsCompleted = [];
        if (checkout.billingAddress) {
          checkoutStepsCompleted.push(1);
        } else {
          this.props.history.push('/checkout');
        }
        if (checkout.selectedDrivers) {
          checkoutStepsCompleted.push(2);
        } else {
          this.props.history.push('/checkout/driver');
        }
        if (checkout.leasingAgreement)
          checkoutStepsCompleted.push(3);
        if (checkout.insuranceAgreement)
          checkoutStepsCompleted.push(4);

        var billingAddress = this.buildBillingAddress(checkout.billingAddress); // Build billing address details
        // Get agreement content
        let formData2 = new FormData();
            formData2.append('api_key', this.api.key);
            formData2.append('access_token', this.api.accessToken);
            formData2.append('jham_token', this.api.jhamToken);
            formData2.append('lessor_id', checkout.data[0].vo_id);
            formData2.append('cd_id1', checkout.selectedDrivers[0].cd_id);
            formData2.append('cd_id2', checkout.selectedDrivers[1] ? checkout.selectedDrivers[1].cd_id : 0);
            formData2.append('lease_start', scheduleData.startDate);
            formData2.append('lease_end', scheduleData.endDate);
            formData2.append('deposit_amount', scheduleData.deposit);
            formData2.append('lease_type', checkout.lType);
            formData2.append('rental_rate', scheduleData.rate);
            formData2.append('addons', scheduleData.addons);
            formData2.append('discount', scheduleData.discount);
            formData2.append('product_id', checkout.data[0].product_id);
            formData2.append('billing_purpose', checkout.billingAddress.purpose);
            formData2.append('billing_fname', checkout.billingAddress.firstName);
            formData2.append('billing_lname', checkout.billingAddress.lastName);
            formData2.append('billing_nric', checkout.billingAddress.nric);
            formData2.append('billing_address', billingAddress);
            formData2.append('billing_company', checkout.billingAddress.company);
            formData2.append('billing_roc', checkout.billingAddress.roc);
            formData2.append('billing_telephone', checkout.billingAddress.telephone);
            formData2.append('billing_mobile', checkout.billingAddress.mobile);
        return axios({
          method: 'post',
          url: this.api.url + '/transaction/lease_agreement',
          data: formData2,
          withCredentials: true,
          headers: {'Content-Type': 'multipart/form-data'}
        })
        .then((response) => {
          var origAgreement = "";
          var agreement = "";
          if (response.data.error == 0) {
            origAgreement = response.data.agreement;
            agreement = response.data.agreement;
            if (checkout.leasingAgreement && checkout.leasingAgreement.hasAgreed && checkout.leasingAgreement.signature) {
              agreement = agreement.replaceAll("[[YOUR SIGNATURE GOES HERE]]", '<img src="' + checkout.leasingAgreement.signature + '" className="img-signature" style="width: 100%; max-width: 200px;" />')
            }

            this.setState({
              loading: false,
              origAgreement: origAgreement,
              agreement: agreement,
              rawCheckout: checkout,
              checkoutStepsCompleted: checkoutStepsCompleted,
            });
            if (checkout.leasingAgreement) {
              this.setState({
                agreementCheck: checkout.leasingAgreement.hasAgreed,
                agreementSignature: checkout.leasingAgreement.signature,
              });
            }
          }
        })
      } else {
        var redirect = false;
        var redirectURL = "";
        var errorTitle = "";
        var errorMsg = "";
        switch (response.data.error) {
          case 1:
          case 2:
            redirect = true;
            redirectURL = "/sign-in";
            errorTitle = "Your session has expired";
            errorMsg = "Please login again. This page will automatically reload.";
            break;
          case 3:
            redirect = true;
            redirectURL = "/checkout";
            errorTitle = "Something went wrong";
            errorMsg = response.data.error_msg + " This page will automatically reload.";
            break;
          default:
            break;
        }
        // Show error notification
        store.addNotification({
          title: errorTitle,
          message: errorMsg,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: false
          }
        });
        // Redirect
        if (redirect == true) {
          setTimeout(function() {
            this.props.history.push(redirectURL);
          }.bind(this), 3000);
        }
        return false;
      }
    })
    .catch(error => {
      // handle error
      store.addNotification({
        title: "Something went wrong",
        message: "The action you have requested is not allowed. Please reload the page.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: false
        }
      });
    });

    // Set checkout progress
    localStorage.setItem('checkoutProgress', 3);
    this.props.handlerState('checkoutProgress', 3);
  };

  openSignatureDialog = () => {
    this.setState({
      openSignatureDialog: true,
    });
  };

  closeSignatureDialog = () => {
    this.setState({
      openSignatureDialog: false,
    });
  };

  clearSignPad = () => {
    this.state.signPad.clear();
  }

  trimSignPad = () => {
    let agreementSignature = null;
    let agreement = this.state.origAgreement;

    if (!this.state.signPad.isEmpty()) {
      agreementSignature = this.state.signPad.getTrimmedCanvas().toDataURL('image/png');
      agreement = agreement.replaceAll("[[YOUR SIGNATURE GOES HERE]]", '<img src="' + agreementSignature + '" className="img-signature" style="width: 100%; max-width: 200px;" />')
    }
    this.setState({
      openSignatureDialog: false,
      agreement: agreement,
      agreementSignature: agreementSignature,
    });
  }

  handleAgreementCheckChange = (event) => {
    event.persist();
    this.setState({
      agreementCheck: event.target.checked,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });

    let rawCheckout = this.state.rawCheckout;
    rawCheckout.leasingAgreement = {
      hasAgreed: this.state.agreementCheck,
      signature: this.state.agreementSignature,
    };
    let updateData = {
      leasing_agreement: (rawCheckout.leasingAgreement.hasAgreed == true) ? 1 : 0,
      leasing_agreement_signature: rawCheckout.leasingAgreement.signature,
    };
    let formData = new FormData();
        formData.append('api_key', this.api.key);
        formData.append('access_token', this.api.accessToken);
        formData.append('jham_token', this.api.jhamToken);
        formData.append('lessor_id', rawCheckout.data[0].vo_id);
        formData.append('update_data', JSON.stringify(updateData));
        formData.append('raw_current_checkout', JSON.stringify(this.state.rawCheckout));
    axios({
      method: 'post',
      url: this.api.url + '/transaction/update_schedule',
      data: formData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      if (response.data.error == 0 || response.data.error == 3) {
        this.props.history.push('/checkout/insurance');
      } else {
        var reload = false;
        var redirect = false;
        var redirectURL = "";
        var errorTitle = "";
        var errorMsg = "";
        switch (response.data.error) {
          case 1:
          case 2:
            reload = true;
            redirect = true;
            redirectURL = "/sign-in";
            errorTitle = "Your session has expired";
            errorMsg = "Please login again. This page will automatically reload.";
            break;
          case 4:
            reload = true;
            errorTitle = "Something went wrong";
            errorMsg = "This page will automatically reload. Please try again after reload.";
            break;
          default:
            break;
        }

        // Show error notification
        store.addNotification({
          title: errorTitle,
          message: errorMsg,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: false
          }
        });
        // Redirect
        if (redirect == true) {
          setTimeout(function() {
            if (reload == true) {
              window.location.href = redirectURL;
            } else {
              this.props.history.push(redirectURL);
            }
          }.bind(this), 3000);
        } else {
          if (reload == true) {
            window.location.reload();
          }
        }
        this.setState({ loading: false });
      }
    })
    .catch((error) => {
      console.log(error);
      // handle error
      if (error.response && error.response.status == 403) {

      }
      store.addNotification({
        title: "Oops Something went wrong",
        message: "The action you have requested is not allowed. Please reload the page",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: false
        }
      });

      this.setState({ loading: false });
    });

  };

  handlePopoverOpen = (event) => {
    if (!this.state.agreementCheck) {
      this.setState({popover: event.currentTarget});
    }
  };

  handlePopoverClose = () => {
    this.setState({popover: null});
  };

  buildBillingAddress = (billingAddress) => {
    var address = capitalize(billingAddress.type) + " " + billingAddress.block + " " + billingAddress.street;
    if (billingAddress.floor && billingAddress.unit) {
      address += ", #" + billingAddress.floor + "-" + billingAddress.unit;
    }
    if (billingAddress.building) {
      if (!billingAddress.floor && !billingAddress.unit) {
        address += ", " + billingAddress.building;
      } else {
        address += " " + billingAddress.building;
      }
    }
    address += ", " + billingAddress.country + " " + billingAddress.postal;
    return address;
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="lg" className="checkout" classes={{root: classes.layout}}>
            <CheckoutStepper active={2} history={this.props.history} completed={this.state.checkoutStepsCompleted} />
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Paper elevation={0}>
                  <Typography variant="h3" className={classes.title} gutterBottom>
                    Vehicle Leasing Agreement
                  </Typography>
                  {this.state.loading && (
                    <Box className={classes.loadingWrapper}>
                      <FacebookCircularProgress />
                    </Box>
                  )}
                  {!this.state.loading && (
                    <React.Fragment>
                      <Paper variant="outlined" className={classes.agreementPaper} square>
                        <Box dangerouslySetInnerHTML={{ __html: this.state.agreement }} />
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <Box align="right">
                          <Button
                            type="button"
                            variant="contained"
                            color="default"
                            onClick={this.openSignatureDialog}
                          >
                            Sign Agreement
                          </Button>
                        </Box>
                      </Paper>
                      <Box style={{padding:"30px"}}>
                        <FormControlLabel
                          control={<Checkbox color="secondary" name="agreementCheck" value="1" onChange={this.handleAgreementCheckChange} checked={this.state.agreementCheck} className={classes.agreementCheckbox} />}
                          label="I agreed to the terms and conditions in the Agreement Leasing"
                        />
                      </Box>
                    </React.Fragment>
                  )}
                  <Box align="right">
                    <Box
                      display="inline-block"
                      aria-haspopup="true"
                      onMouseEnter={this.handlePopoverOpen}
                      onMouseLeave={this.handlePopoverClose}
                    >
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        disabled={!this.state.agreementCheck || !this.state.agreementSignature || this.state.loading}
                        onClick={this.handleSubmit}
                      >
                        Next: Insurance Agreement
                      </Button>
                    </Box>
                    <Popover
                      id="mouse-over-popover"
                      className={classes.popover}
                      classes={{
                        paper: classes.popoverPaper,
                      }}
                      open={Boolean(this.state.popover)}
                      anchorEl={this.state.popover}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                      onClose={this.handlePopoverClose}
                    >
                      <Typography>Sign and accept the Vehicle Leasing Agreement</Typography>
                    </Popover>
                  </Box>
                </Paper>
              </Grid>
            </Grid>

            <Dialog fullScreen className={classes.signatureDialog} open={this.state.openSignatureDialog} onClose={this.closeSignatureDialog} TransitionComponent={Transition}>
              <AppBar>
                <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={this.closeSignatureDialog} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h5" className="title">
                    Sign on the blank space below
                  </Typography>
                  <Button color="inherit" onClick={this.clearSignPad}>
                    Clear
                  </Button>
                  <Button color="inherit" onClick={this.trimSignPad}>
                    Save
                  </Button>
                </Toolbar>
              </AppBar>
              <Box className="signpad-container">
                <SignatureCanvas
                  ref={(ref) => { this.state.signPad = ref }}
                  minWidth={1}
                  maxWidth={8}
                />
              </Box>
            </Dialog>
          </Container>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(Agreement);

const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(find, 'gi'), replace);
}

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

String.prototype.replaceAll = function(str1, str2, ignore) {
  return this.replace(new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g,"\\$&"),(ignore?"gi":"g")),(typeof(str2)=="string")?str2.replace(/\$/g,"$$$$"):str2);
};
