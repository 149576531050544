import React, { Component }  from "react";
import {
    BrowserRouter,
    Route,
    Switch,
    withRouter
} from "react-router-dom";
import { createBrowserHistory } from "history";
import PropTypes from 'prop-types';
import ScrollToTop from "./utils/ScrollToTop";
import PendingPayment from "./PendingPayment";

//MAIN PAGES
import Home from "./Home";
import Contact from "./contact/Contact";
import About from "./about/About";
import Services from "./services/Services";
//END

//VEHICLES
import Vehicles from "./vehicles/Vehicles";
import VehiclesDetails from "./vehicles/VehiclesDetails";
import VehicleVan from "./vehicles/VehiclesVan";
import VehicleLorry from "./vehicles/VehiclesLorry";
// import VehicleAll from "./vehicles//VehiclesAll";
//END


import Header from "./Header";
import Footer from "./Footer";

// LOGIN ETC
import Login from "./Login";
import Register from "./Register";
import Forgotpassword from "./Forgotpassword";
import Setpassword from "./Setpassword";
//END

//PROFILES
import ProfileContent from "./profile/Content";
//END

//OTHERS
import Privacy from "./others/Privacy";
import Terms from "./others/Terms";
import Faq from "./others/Faq";
import Taxes from "./others/Taxes";
import ServiceFees from "./others/ServiceFees";
import ReviewPolicy from "./others/ReviewPolicy";
import PaymentTerms from "./others/PaymentTerms";
import OfflineFee from "./others/OfflineFee";
import SecurityDeposit from "./others/SecurityDeposit";
import CopyrightPolicy from "./others/CopyrightPolicy";
import ContentPolicy from "./others/ContentPolicy";
import LoyaltyMiles from "./loyaltyMiles/LoyaltyMiles";
//END
import axios from 'axios';
//CHECKOUT
import Checkout from "./checkout/Checkout";
import CheckoutDriver from "./checkout/Driver";
import CheckoutAgreement from "./checkout/Agreement";
import CheckoutInsurance from "./checkout/Insurance";
import CheckoutPayment from "./checkout/Payment";
import CheckoutPaymentSuccessful from "./checkout/PaymentSuccessful";
import CheckoutPaymentFailed from "./checkout/PaymentFailed";
import ExtensionPayment from "./checkout/ExtensionPayment";
import UpdatePayment from "./checkout/UpdatePayment";
import RecurringManualPayment from "./checkout/RecurringManualPayment";
//END

import Cancellation from "./cancellation/Cancellation";

import NotFoundPage from "./404"
import moment from "moment";

class Content extends React.Component {
  constructor (props) {
    super(props)
    this.handlerState = this.handlerState.bind(this);
    this.state = {
      xxx: ""
    }

    axios.get('https://api.earental.com/listing/getPass?c='+moment().unix(), { withCredentials: true })
    .then((response) => {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('currentDate', response.data.current_date);
    })
    .catch((error) => {
      // handle error
      console.log(error);
    });
  }

  handlerState(key,val) {
    this.props.handlerState(key,val);
  }

  render() {
    const hist = createBrowserHistory();
    return (
      <BrowserRouter>
        <ScrollToTop>
          <div className="content">
            <Header handlerState = {this.handlerState} mainState={this.props.mainState} />
            <Switch>
              <Route exact path="/" render={(props) => (
                  <Home handlerState = {this.handlerState} mainState={this.props.mainState}   {...props}/>
              )} />
              <Route exact path="/about-us" component={About}/>
              <Route path="/services" component={Services}/>
              <Route exact path="/loyalty-miles" component={LoyaltyMiles}/>
              <Route exact path="/vehicles"  component={Vehicles}/>
              <Route path="/vehicle-details" render={(props) => (
                  <VehiclesDetails  handlerState = {this.handlerState} mainState={this.props.mainState} {...props} />
              )} />
              <Route exact path="/vehicles/van-rental" render={(props) => (
                  <VehicleVan handlerState = {this.handlerState} mainState={this.props.mainState} {...props} />
              )} />
              <Route path="/lorry-rental" component={VehicleLorry}/>
              <Route path="/contact" component={Contact}/>
              <Route path="/sign-in" render={(props) => (
              <Login handlerCurrentName={this.handlerState} {...props}/>
              )} />
              <Route path="/sign-up" render={() => (
              <Register {...this.state}/>
              )} />
              {/* <Route path="/sign-up" component={Register}/> */}
              <Route path="/forgot-password" component={Forgotpassword}/>
              <Route path="/set-password" component={Setpassword}/>
              <Route path="/user-profile" render={() => (
                  <ProfileContent handlerState = {this.handlerState} mainState={this.props.mainState} />
              )} />

              <Route path="/user-rental-history" render={() => (
                  <ProfileContent handlerState = {this.handlerState} mainState={this.props.mainState} />
              )} />

              <Route path="/user-settings" render={() => (
                  <ProfileContent handlerState = {this.handlerState} mainState={this.props.mainState} />
              )} />

              <Route path="/user-cards" render={() => (
                  <ProfileContent handlerState = {this.handlerState} mainState={this.props.mainState} />
              )} />

              <Route path="/user-loyalty-miles" render={() => (
                  <ProfileContent handlerState = {this.handlerState} mainState={this.props.mainState} />
              )} />

              <Route path="/user-lessee-addresses" render={() => (
                  <ProfileContent handlerState = {this.handlerState} mainState={this.props.mainState} />
              )} />

              <Route path="/user-drivers" render={() => (
                  <ProfileContent handlerState = {this.handlerState} mainState={this.props.mainState} />
              )} />
              <Route exact path="/checkout" render={({history}) => (
                <Checkout mainState={this.props.mainState} handlerState={this.handlerState} history={history} />
              )}/>
              <Route path="/checkout/driver" render={({history}) => (
                <CheckoutDriver currentDate={this.props.mainState.currentDate} mainState={this.props.mainState} handlerState={this.handlerState} history={history} />
              )}/>
              <Route path="/checkout/agreement" render={({history}) => (
                <CheckoutAgreement mainState={this.props.mainState} handlerState={this.handlerState} history={history} />
              )}/>
              <Route path="/checkout/insurance" render={({history}) => (
                <CheckoutInsurance mainState={this.props.mainState} handlerState={this.handlerState} history={history} />
              )}/>
              <Route exact path="/checkout/payment" render={({history}) => (
                <CheckoutPayment mainState={this.props.mainState} handlerState={this.handlerState} history={history} />
              )}/>
              <Route exact path="/cancellation" render={({history}) => (
                <Cancellation mainState={this.props.mainState} handlerState={this.handlerState} history={history} />
              )}/>
              {/* <Route path="/checkout/agreement" component={CheckoutAgreement}/> */}
              {/* <Route path="/checkout/insurance" component={CheckoutInsurance}/> */}
              {/* <Route exact path="/checkout/payment" component={CheckoutPayment}/> */}
              <Route path="/checkout/payment/success" component={CheckoutPaymentSuccessful}/>
              <Route path="/checkout/payment/failed" component={CheckoutPaymentFailed} />
              <Route exact path="/extension/payment" render={({ history }) => (
                <ExtensionPayment mainState={this.props.mainState} handlerState={this.handlerState} history={history} />
              )} />
              <Route exact path="/lease/update-payment/:id" render={({ history }) => (
                <UpdatePayment mainState={this.props.mainState} handlerState={this.handlerState} history={history} />
              )} />
              <Route exact path="/lease/manual-payment/:id" render={({ history }) => (
                <RecurringManualPayment mainState={this.props.mainState} handlerState={this.handlerState} history={history} />
              )} />
              <Route exact path="/privacy-policy" component={Privacy}/>
              <Route exact path="/terms-of-service" component={Terms}/>
              <Route exact path="/taxes" component={Taxes}/>
              <Route exact path="/service-fees" component={ServiceFees}/>
              <Route exact path="/review-policy" component={ReviewPolicy}/>
              <Route exact path="/payment-terms" component={PaymentTerms}/>
              <Route exact path="/offline-fee" component={OfflineFee}/>
              <Route exact path="/security-deposit" component={SecurityDeposit}/>
              <Route exact path="/copyright-policy" component={CopyrightPolicy}/>
              <Route exact path="/content-policy" component={ContentPolicy}/>
              <Route exact path="/faq" component={Faq}/>
              <Route component={NotFoundPage}/>

            </Switch>
            <PendingPayment handlerState = {this.handlerState} mainState={this.props.mainState} />
          </div>
          <Footer handlerState = {this.handlerState} mainState={this.mainState} />
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default Content
