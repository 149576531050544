import React, {userState,Component} from 'react';
import {HashRouter,
  Route} from 'react-router-dom';
import axios from 'axios';
import { store } from 'react-notifications-component';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Avatar from '@material-ui/core/Avatar';
import Sidebar from './Sidebar'
import DashboardHome from "./dashboardPages/dashboard";
import Vehicles from "./dashboardPages/Vehicles";
import Schedules from "./dashboardPages/Schedules";
import PayoutMethod from "./dashboardPages/PayoutMethod";
import Users from "./dashboardPages/Users";
import RentalPromotions from "./dashboardPages/RentalPromotions";
// import Agreements from "./dashboardPages/Agreements";
import LeaseAgreement from "./dashboardPages/LeaseAgreement";
import InsuranceLetter from "./dashboardPages/InsuranceLetter";

import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie';

import Footer from "./Footer";

import ReactNotification from 'react-notifications-component';
import moment from "moment";

import {
  orange,
  lightBlue,
  deepPurple,
  deepOrange
} from "@material-ui/core/colors";
import Iframe from 'react-iframe'

// For Switch Theming
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  fixedHeight: {
    height: 240
  }
}));

// function dashboardHome(classes){
//   return (
//     <DashboardHome {...classes} />
//   )
// }

class Main extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor (props) {
      super(props)
      const { cookies } = props;
      this.state = {
        currentPendingSchedule:0,
        cavatar: cookies.get('cavatar') || false,
        ctoken: cookies.get('ctoken') || false,
        token: cookies.get('csrf_cookie_jham') || false
      };
      localStorage.setItem('ctoken', cookies.get('ctoken') || false);
      localStorage.setItem('token', cookies.get('token') || false);
      this.handlerState = this.handlerState.bind(this);
    }

    handlerState(key,val) {
      this.setState({
        [key]: val
      })
    }

    componentDidMount() {
        var jToken = false;
        axios.get('https://api.earental.com/listing/getPass?c='+moment().unix(), { withCredentials: true })
        .then((response) => {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('currentDate', response.data.current_date);
            jToken = response.data.token;
            if(!this.state.ctoken){
                localStorage.removeItem("ctoken");
                localStorage.removeItem("token");
                localStorage.removeItem("current_name");
                localStorage.removeItem("current_avatar");

                window.location.href = "https://www.earental.com";
            }else{
                localStorage.setItem('ctoken', this.state.ctoken);
                this.checkUserToken();
                setInterval(this.checkUserToken.bind(this), 30000);
            }


        })
        .catch((error) => {
            //handle error
            console.log(error);

        });





    }

    async checkUserToken(){
        const { cookies } = this.props;
        var ctoken = this.state.ctoken;
        var token = localStorage.getItem('token');
        var cTokenCookie = cookies.get('ctoken') || false
        if(!cTokenCookie){
            localStorage.removeItem("ctoken");
            localStorage.removeItem("token");
            localStorage.removeItem("current_name");
            localStorage.removeItem("current_avatar");

            window.location.href = "https://www.earental.com";
        }else{
            if(ctoken){
                var bodyFormData = new FormData();
                bodyFormData.append('access_token', ctoken);
                bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
                bodyFormData.append('jham_token', token);
                axios({
                        method: 'post',
                        url: 'https://api.earental.com/account/checkpass?c='+moment().unix(),
                        data: bodyFormData,
                        withCredentials: true,
                        headers: {'Content-Type': 'multipart/form-data' }
                    })
                    .then((response) => {

                        if(response.data.error > 0){

                                store.addNotification({
                                  title: "Oops you have been logout",
                                  message: "Your session have expired/used in different device, please re-login",
                                  type: "warning",
                                  insert: "top",
                                  container: "top-right",
                                  animationIn: ["animated", "fadeIn"],
                                  animationOut: ["animated", "fadeOut"],
                                  dismiss: {
                                    duration: 4000,
                                    onScreen: false
                                  }
                                });
                                localStorage.removeItem("ctoken");
                                localStorage.removeItem("current_name");
                                localStorage.removeItem("current_avatar");

                                this.setState({
                                  currentUserName: false,
                                  currentAvatar: false,
                                  ctoken: false
                                })

                                window.location.href = "https://www.earental.com";


                        }

                        // window.scrollTo(0, 0);
                    })
                    .catch((error) => {


                    });
            }
        }


    }



  render() {

    function Dashboard(props) {
      const palletType = "dark";
      const mainPrimaryColor =  orange[500] ;
      const mainSecondaryColor = deepOrange[900];
      const darkTheme = createMuiTheme({
        palette: {
          type: palletType,
          primary: {
            main: mainPrimaryColor
          },
          secondary: {
            main: mainSecondaryColor
          }
        }
      });
      const classes = useStyles();
      const [open, setOpen] = React.useState(true);
      const handleDrawerOpen = () => {
        setOpen(true);
      };
      const handleDrawerClose = () => {
        setOpen(false);
      };
      const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
      return (
        <div className={classes.root}>
          <ReactNotification />
          <CssBaseline />
          <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)+ ' primary-color-earental'}>
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
              >
                <MenuIcon />
              </IconButton>
              <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                Dashboard
              </Typography>
                <a href="https://www.earental.com/user-profile"><Avatar alt="" src={'https://api.earental.com/uploads/profile/'+props.mainState.cavatar}  /></a>
              <IconButton color="inherit">
                <Badge badgeContent={0} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Toolbar>
          </AppBar>
          <HashRouter>
            <Drawer
              variant="permanent"
              classes={{
                paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
              }}
              open={open}
            >
              <div className={classes.toolbarIcon+' primary-color-earental'}>
                <div className="dashboardName">
                  <a href="https://www.earental.com"><img className="dashboardLogo" src={'img/EARental-Logo-w.png'} alt=""/></a>
                </div>
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
              </div>
              <Divider />
              <List className="list-nav-cont"><Sidebar  /></List>
            </Drawer>
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
                <div>
                  <Route exact path="/" component={DashboardHome} />
                  <Route path="/vehicles" component={Vehicles} />
                  <Route path="/lease" component={Schedules} />
                  {/* <Route path="/agreement-contract" component={Agreements} /> */}
                  <Route path="/lease-agreement" component={LeaseAgreement} />
                  <Route path="/insurance-letter" component={InsuranceLetter} />
                  <Route path="/payout-method" component={PayoutMethod} />
                  <Route path="/users" component={Users} />
                  {/* <Route path="/rental-promotions" component={RentalPromotions} /> */}
                  <Route path="/rental-promotions" component={() => <RentalPromotions handlerState={props.handlerState} mainState={props.mainState} />} />

                  {/* <DashboardHome {...classes} /> */}
                 <Footer  handlerState = {props.handlerState} mainState={props.mainState}  />
              </div>
            </main>
          </HashRouter>
        </div>


      );
    }
    return (
        <div>
             <Dashboard handlerState = {this.handlerState} mainState={this.state} />
        </div>
    );
  }
}
export default withCookies(Main);
