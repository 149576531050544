import React from 'react';
import {
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from 'axios';
import moment from 'moment';
import { store } from 'react-notifications-component';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import { orange, grey } from '@material-ui/core/colors';

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#1a90ff',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

const FacebookCircularProgress = (props) => {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: 'none',
  },
  dialogContent: {
    minHeight: 150,
    paddingBottom: theme.spacing(4),
  },
  dialogActions: {
    justifyContent: 'space-between',
    '& button': {
      fontSize: '1rem',
    },
  },
  newPromotionForm: {
    color: grey[800],
    '& .MuiFormControl-root': {
      '& .MuiFormLabel-root': {
        fontSize: '1.2rem',
        '&.Mui-focused': {
          color: grey[800],
          backgroundColor: 'white',
          paddingRight: 5,
        },
      },
      '& .MuiFormControlLabel-label': {
        fontSize: '1.2rem',
      },
      '& .MuiRadio-colorSecondary.Mui-checked': {
        // color: orange[600],
      },
      '& .MuiSelect-select': {
        fontSize: '1.2rem',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        '&:hover fieldset': {
          borderColor: '#B4B4B4',
        },
        '&.Mui-focused fieldset': {
          borderColor: orange[600],
        },
        '& input': {
          fontSize: '1.2rem',
        },
      },
      '& .MuiRadio-root': {
        '& .Mui-focused': {
          color: orange[600],
        },
      },
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      cursor: 'not-allowed',
      color: '#000000',
    },
  },
  radioGroup: {
    paddingBottom: '0 !important',
    '& .MuiFormLabel-root': {
      marginBottom: 0,
    },
    '& .MuiFormControlLabel-root': {
      marginBottom: 0,
    },
  },
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
  },
  vehicleCard: {
    marginBottom: theme.spacing(1),
    width: '100%',
    '& .MuiCardContent-root': {
      padding: theme.spacing(2),
      '& .MuiFormControlLabel-root': {
        width: '100%',
        alignItems: 'flex-start',
        '& .MuiFormControlLabel-label': {
          width: '100%',
          marginLeft: theme.spacing(1),
          '& img': {
            width: 120,
          },
        },
        '& p': {
          fontSize: '1rem',
        },
      },
      '& .MuiFormControlLabel-root:not(.Mui-disabled)': {
        '& p:not(.MuiTypography-colorError)': {
          color: '#4C4C4C',
        },
      },
      '& .MuiFormControlLabel-root.Mui-disabled': {
        cursor: 'not-allowed',
      },
    },
    '& .MuiCardActions-root': {
      justifyContent: 'space-between',
    },
    '& button': {
      fontSize: '1.1rem !important',
      fontWeight: '600',
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewRentalPromotion(props) {
  const theme = useTheme();
  const classes = useStyles();
  const api = {
    url: props.api.url,
    key: props.api.key,
    accessToken: localStorage.getItem('ctoken'),
    jhamToken: localStorage.getItem('token'),
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [state, setState] = React.useState({
    title: "",
    startDate: null,
    endDate: null,
    freeType: "days",
    rateType: "monthly",
    freeCount: 0,
    vehicles: [],
  });
  const [errors, setErrors] = React.useState({
    title: "",
    startDate: "",
    endDate: "",
    freeType: "",
    rateType: "",
    freeCount: "",
    vehicles: "",
  });
  const [vehicleList, setVehicleList] = React.useState([]);
  const [loadingVehicles, setLoadingVehicles] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  let formSubmitButton = React.useRef(null);

  const handleFreeTypeChange = (event) => {
    event.persist();
    setState({
      ...state,
      freeType: event.target.value,
    });
  };

  const handleInputChange = (event) => {
    let target = event.target;
    setState({
      ...state,
      [target.name]: target.value,
    });
    setErrors({
      ...errors,
      [target.name]: "",
    })
  };

  const handleDatePickerChange = (field, value) => {
    setState({
      ...state,
      [field]: value,
    });
    setErrors({
      ...errors,
      startDate: "",
      endDate: "",
    })
  };

  const handleCheckVehicle = (vehicleId, e) => {
    let newVehicles = [...state.vehicles];
    if (state.vehicles.indexOf(vehicleId) == -1) {
      newVehicles.push(vehicleId);
    } else {
      newVehicles.splice(newVehicles.indexOf(vehicleId), 1);
    }
    setState({
      ...state,
      vehicles: newVehicles,
    });
    setErrors({
      ...errors,
      vehicles: "",
    });
  }

  React.useEffect(() => {
    if (state.startDate && state.endDate && state.startDate < state.endDate) {
      setLoadingVehicles(true);

      var formData = new FormData();
      formData.append('api_key', api.key);
      formData.append('access_token', api.accessToken);
      formData.append('jham_token', api.jhamToken);
      formData.append('start_date', moment(state.startDate).format('YYYY-MM-DD'));
      formData.append('end_date', moment(state.endDate).format('YYYY-MM-DD'));
      formData.append('rate_type', state.rateType);

      axios({
        method: 'post',
        url: api.url + '/lessor/rental_promotion_vehicle_list',
        data: formData,
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then((response) => {
          var vehicleList = [];
          response.data.list.map((vehicle, index) => {
            var promotionsConflict = [];
            vehicle.promotions_conflict.map((promotion, index) => {
              promotionsConflict.push({
                title: promotion.rp_title,
                startDate: moment(promotion.rp_start_date, 'YYYY-MM-DD').format('MM/DD/YYYY'),
                endDate: moment(promotion.rp_end_date, 'YYYY-MM-DD').format('MM/DD/YYYY'),
              });
            });
            vehicleList.push({
              id: vehicle.product_id,
              name: vehicle.product_name,
              plateNumber: vehicle.product_platenumber,
              type: vehicle.product_type,
              brand: vehicle.product_brand,
              model: vehicle.product_model,
              thumbnail: vehicle.product_image,
              promotionsConflict: promotionsConflict,
            });
          });
          setVehicleList(vehicleList);
          setLoadingVehicles(false);
        })
        .catch((error) => {
          // handle error
        });
    }
  }, [state.startDate, state.endDate, state.rateType]);

  const handleSaveButton = (event) => {
    let errorCount = 0;
    let errorMessages = {};

    if (state.title == "") {
      errorCount++;
      errorMessages['title'] = "Enter a title for this promotion";
    } else {
      errorMessages['title'] = "";
    }
    if (isNaN(state.freeCount) || state.freeCount == "") {
      errorCount++;
      errorMessages['freeCount'] = "Must be a number";
    } else if (state.freeCount <= 0) {
      errorCount++;
      errorMessages['freeCount'] = "Must be more than 0";
    } else {
      errorMessages['freeCount'] = "";
    }
    if (state.startDate && state.endDate && state.startDate >= state.endDate) {
      errorCount++;
      errorMessages['startDate'] = "Select a valid start date";
      errorMessages['endDate'] = "Select a valid end date";
    } else {
      if (state.startDate == null) {
        errorCount++;
        errorMessages['startDate'] = "Select a start date";
      } else {
        errorMessages['startDate'] = "";
      }
      if (state.endDate == null) {
        errorCount++;
        errorMessages['endDate'] = "Select an end date";
      } else {
        errorMessages['endDate'] = "";
      }
    }
    if (state.vehicles.length == 0) {
      errorCount++;
      errorMessages['vehicles'] = "Select vehicle(s) to include in this promotion";
    } else {
      errorMessages['vehicles'] = "";
    }

    setErrors({
      ...errors,
      ...errorMessages
    })
    console.log(errorMessages);
    if (errorCount == 0) {
      formSubmitButton.click();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSaving(true);
    let formData = new FormData();
    formData.append('api_key', api.key);
    formData.append('access_token', api.accessToken);
    formData.append('jham_token', api.jhamToken);
    formData.append('title', state.title);
    formData.append('start_date', moment(state.startDate).format('YYYY-MM-DD'));
    formData.append('end_date', moment(state.endDate).format('YYYY-MM-DD'));
    formData.append('rent_type', state.freeType);
    formData.append('rate_type', state.rateType);
    formData.append('rent_count', state.freeCount);
    formData.append('vehicles', JSON.stringify(state.vehicles));

    axios({
      method: 'post',
      url: api.url + '/lessor/create_rental_promotion',
      data: formData,
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => {
        if (response.data.error == 1) {
          function ErrorMsg(props) {
            return (
              props.msgs.map((item, i) =>
                <li key={i}>{item.value}</li>
              )
            );
          }
          store.addNotification({
            title: "Invalid information",
            message: <ErrorMsg msgs={response.data.error_msg} />,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 20000,
              onScreen: false
            }
          });
        } else if (response.data.error == 2) {
          store.addNotification({
            title: "Oops Something went wrong",
            message: "The action you have requested is not allowed. Please reload the page",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false
            }
          });
        } else {
          resetFields();
          props.onCreate();
        }
        setSaving(false);
      })
      .catch((error) => {
        // Handle error
        if (error.response && error.response.status == 403) {
          store.addNotification({
            title: "Oops Something went wrong",
            message: "The action you have requested is not allowed. Please reload the page",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false
            }
          });
        }
      });

  };

  const resetFields = () => {
    setVehicleList([]);
    setState({
      title: "",
      startDate: null,
      endDate: null,
      freeType: "days",
      rateType: "monthly",
      freeCount: 0,
    });
    setErrors({
      title: "",
      startDate: "",
      endDate: "",
      freeType: "",
      rateType: "",
      freeCount: "",
    });
    setSaving(false);
  };

  const VehicleListItem = (props) => {
    function Label(labelProps) {
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h6" component="h6">
              {labelProps.vehicle.name}
            </Typography>
            <Typography variant="body1" component="p">
              Plate Number: {labelProps.vehicle.plateNumber}
            </Typography>
            <Typography variant="body1" component="p">
              Brand &amp; Model: {labelProps.vehicle.brand} {labelProps.vehicle.model}
            </Typography>
            {props.vehicle.promotionsConflict.length > 0 && (
              <React.Fragment>
                <Typography variant="caption" component="p" color="error">
                  Included in the following promotions:
                </Typography>
                <Typography variant="caption" component="p">
                  {props.vehicle.promotionsConflict.map((promotion, index) => {
                    return index < props.vehicle.promotionsConflict.length - 1 ? (
                      <span key={index}>{promotion.title} ({promotion.startDate} &mdash; {promotion.endDate}), </span>
                    ) : (
                        <span key={index}>{promotion.title} ({promotion.startDate} &mdash; {promotion.endDate})</span>
                      )
                  })}
                </Typography>
              </React.Fragment>
            )}
          </Box>
          <Box>
            <img src={api.url + "/uploads/vehicles/" + labelProps.vehicle.thumbnail} />
          </Box>
        </Box>
      )
    }

    return (
      <Card className={classes.vehicleCard}>
        <CardContent>
          <FormControlLabel
            control={<Checkbox color="secondary" onChange={(e) => props.onCheck(props.vehicle.id, e)} checked={state.vehicles.indexOf(props.vehicle.id) > -1} />}
            label={<Label vehicle={props.vehicle} />}
            disabled={props.vehicle.promotionsConflict.length > 0}
          />
        </CardContent>
      </Card>
    );
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={props.open}
      TransitionComponent={Transition}
      onClose={props.onClose}
      className={classes.root}
      fullScreen={fullScreen}
    >
      <DialogTitle>
        New Rental Promotion
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form onSubmit={handleSubmit} className={classes.newPromotionForm}>
          <Button type="submit" ref={button => formSubmitButton = button} className={classes.hidden}>hidden</Button>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                variant="outlined"
                id="title"
                name="title"
                label="Title"
                fullWidth
                autoComplete="off"
                value={state.title}
                onChange={handleInputChange}
                error={errors.title != ""}
                helperText={errors.title}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
                id="rateType"
                name="rateType"
                label="Applicable to"
                fullWidth
                autoComplete="off"
                value={state.rateType}
                onChange={handleInputChange}
                error={errors.rateType != ""}
                helperText={errors.rateType}
              >
                <option value={'monthly'}>Monthly</option>
                <option value={'year'}>1 Year</option>
                <option value={'year2'}>2 Years</option>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <FormControl component="fieldset">
                    <FormLabel>Free</FormLabel>
                    <RadioGroup name="purpose" value={state.freeType} onChange={handleFreeTypeChange} row>
                      <FormControlLabel value="days" control={<Radio />} label="Days" />
                      <FormControlLabel value="months" control={<Radio />} label="Months" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    required
                    type="number"
                    variant="outlined"
                    id="freeCount"
                    name="freeCount"
                    label={"No. of " + state.freeType + " free"}
                    fullWidth
                    autoComplete="off"
                    value={state.freeCount}
                    onChange={handleInputChange}
                    error={errors.freeCount != ""}
                    helperText={errors.freeCount}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} >
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    required
                    fullWidth
                    autoOk={true}
                    inputVariant="outlined"
                    variant="inline"
                    id="startDate"
                    name="startDate"
                    label="Start Date"
                    format="MM/dd/yyyy"
                    placeholder="mm/dd/yyyy"
                    value={state.startDate}
                    onChange={(value) => handleDatePickerChange("startDate", value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end"><EventIcon /></InputAdornment>,
                    }}
                    error={errors.startDate != ""}
                    helperText={errors.startDate}
                    disabled={saving || loadingVehicles}
                  />
                </MuiPickersUtilsProvider>
                <Box padding={2}>
                  <Typography component="span">&mdash;</Typography>
                </Box>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    required
                    fullWidth
                    autoOk={true}
                    inputVariant="outlined"
                    variant="inline"
                    id="endDate"
                    name="endDate"
                    label="End Date"
                    format="MM/dd/yyyy"
                    placeholder="mm/dd/yyyy"
                    value={state.endDate}
                    onChange={(value) => handleDatePickerChange("endDate", value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end"><EventIcon /></InputAdornment>,
                    }}
                    error={errors.endDate != ""}
                    helperText={errors.endDate}
                    disabled={saving || loadingVehicles}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Grid>
          </Grid>
          {loadingVehicles && (
            <React.Fragment>
              <hr />
              <Box className={classes.loadingWrapper}>
                <FacebookCircularProgress />
              </Box>
            </React.Fragment>
          )}
          {vehicleList.length > 0 && (
            <React.Fragment>
              <hr />
              <Box maxHeight={350} overflow="auto">
                {vehicleList.map((vehicle, index) => {
                  return <VehicleListItem key={index} vehicle={vehicle} onCheck={handleCheckVehicle} />
                })}
              </Box>
            </React.Fragment>
          )}
          {errors.vehicles != "" && (
            <Typography variant="body1" component="p" color="error">
              {errors.vehicles}
            </Typography>
          )}
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button disabled={saving} onClick={props.onClose}>
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleSaveButton} disabled={saving}>
          Save Promotion
        </Button>
      </DialogActions>
    </Dialog>
  );
}