import React from "react";
import axios from 'axios';
import { store } from 'react-notifications-component';
import { withStyles } from '@material-ui/core/styles';

import ContentLoader from "react-content-loader";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import ReactCards from 'react-credit-cards';
import CardsFormatter from 'payment';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PaymentIcon from 'react-payment-icons';
import { grey, orange } from '@material-ui/core/colors';
import NewCardForm from "../components/NewCardForm";

const useStyles = theme => ({
  cardImage: {
    maxWidth: 80,
  },
  cardType: {
    // color: grey[600],
  },
  cardNumber: {
    // color: grey[600]
  },
  cardExpiry: {
    color: grey[500],
  },
  cardPrimarylabel: {
    fontSize: '1rem',
    fontWeight: 600,
    textTransform: 'inherit',
  },
  dialogContentText: {
    fontSize: '1.25rem',
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
  actionButtons: {
    fontSize: '1rem',
  },
  newCard: {
    color: grey[600],
    cursor: 'pointer',
    '& .MuiCardContent-root': {
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 159,
      '& > *': {
        color: '#2F96B4',
        fontSize: '1.75rem',
        fontWeight: 500,
      },
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
      },
    },
  },
  newCardDialog: {
    '& .MuiButton-containedSecondary': {
      backgroundColor: '#2F96B4',
    },
  },
  newCardForm: {
    color: grey[800],
    maxWidth: 315,
    '& .MuiFormControl-root': {
      '& .MuiFormLabel-root': {
        fontSize: '1.2rem',
        '&.Mui-focused': {
          color: grey[800],
          backgroundColor: 'white',
          paddingRight: 5,
        },
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        '&:hover fieldset': {
          borderColor: '#B4B4B4',
        },
        '&.Mui-focused fieldset': {
          borderColor: orange[600],
        },
        '& input': {
          fontSize: '1.2rem',
        },
      },
    }
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Cards extends React.Component {
  constructor(props) {
    super(props);

    this.apiURL = "https://api.earental.com";
    this.apiKey = "72ffc1303c7c0286887a3276f0ab61b8";
    this.accessToken = localStorage.getItem('ctoken');
    this.jhamToken = localStorage.getItem('token');

    // this.apiURL = "http://api.earental.dev";
    // this.apiKey = "72ffc1303c7c0286887a3276f0ab61b8";
    // this.accessToken = localStorage.getItem('ctoken');
    // this.jhamToken = "5b03f00128e90e12005a6ce964e8bb0c";

    this.state = {
      cardsLoading: true,
      addCardLoading: false,
      stripePublishableKey: "",
      primaryCard: null,
      cardList: [],
      cardToDelete: null,
      openAddDialog: false,
      openDeleteDialog: false,
    };
  }

  componentDidMount() {
    if(this.accessToken){
        let bodyFormData = new FormData();
            bodyFormData.append('api_key', this.apiKey);
            bodyFormData.append('access_token', this.accessToken);
            bodyFormData.append('jham_token', this.jhamToken);

        axios({
          method: 'post',
          url: this.apiURL + '/account/saved_cards',
          data: bodyFormData,
          withCredentials: true,
          headers: {'Content-Type': 'multipart/form-data'}
        })
        .then((response) => {
          if (response.data.error == 0) {
            var cards = [];
            var primaryCard = 0;
            response.data.cards.map((card) => {
              cards.push({
                id: card.stripe_pm_id,
                number: card.stripe_card_last4,
                type: card.stripe_card_type,
                primary: parseInt(card.stripe_card_primary),
                expiry: ("0" + card.stripe_card_exp_month).slice(-2) + " / " + card.stripe_card_exp_year.slice(-2),
              });
              if (card.stripe_card_primary == 1) {
                primaryCard = card.stripe_pm_id;
              }
            });
            this.setState({
              cardsLoading: false,
              card: primaryCard,
              cardList: cards,
              stripePublishableKey: response.data.publishable_key,
            });
          } else {
            var reload = false;
            var redirect = false;
            var redirectURL = "";
            var errorTitle = "";
            var errorMsg = "";
            switch (response.data.error) {
              case 1:
              case 2:
                reload = true;
                redirectURL = "/sign-in";
                errorTitle = "Your session has expired";
                errorMsg = "Please login again. This page will automatically reload.";
                break;
              default:
                break;
            }

            // Show error notification
            store.addNotification({
              title: errorTitle,
              message: errorMsg,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: false
              }
            });
            // Redirect
            if (redirect == true) {
              setTimeout(function() {
                this.props.history.push(redirectURL);
              }.bind(this), 3000);
            }
            if (reload == true) {
              setTimeout(function() {
                window.location.href = redirectURL;
              }.bind(this), 3000);
            }
            return false;
          }
        })
        .catch(error => {
            // handle error
            store.addNotification({
              title: "Session expired",
              message: "Kindly reload/refresh the page and sign-in again.",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: false
              }
            });
        });
    }else{
        store.addNotification({
          title: "Session expired",
          message: "Kindly reload/refresh the page and sign-in again.",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: false
          }
        });
    }

  }

  handleAddDialogOpen = (card) => {
    this.setState({ openAddDialog: true });
  }

  handleAddDialogClose = (card) => {
    this.setState({ openAddDialog: false });
  }

  handleSetupSuccess = (setupIntent) => {
    let bodyFormData = new FormData();
    bodyFormData.append('api_key', this.apiKey);
    bodyFormData.append('access_token', this.accessToken);
    bodyFormData.append('jham_token', this.jhamToken);
    bodyFormData.append('stripe_card_id', setupIntent.payment_method,);

    axios({
      method: 'post',
      url: this.apiURL + '/account/add_card',
      data: bodyFormData,
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => {
        if (response.data.error == 0) {
          this.reloadCards();
        } else {
          var reload = false;
          var redirect = false;
          var redirectURL = "";
          var errorTitle = "";
          var errorMsg = "";
          switch (response.data.error) {
            case 1:
              errorTitle = "Oooops";
              errorMsg = response.data.error_msg;
            case 2:
              reload = true;
              redirectURL = "/sign-in";
              errorTitle = "Your session has expired";
              errorMsg = "Please login again. This page will automatically reload.";
              break;
            case 3:
              errorTitle = "An error has occurred.";
              errorMsg = response.data.error_msg;
              break;
            default:
              errorMsg = response.data.error_msg;
              break;
          }

          // Show error notification
          store.addNotification({
            title: errorTitle,
            message: errorMsg,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: false
            }
          });
          // Redirect
          if (redirect == true) {
            setTimeout(function () {
              this.props.history.push(redirectURL);
            }.bind(this), 3000);
          }
          if (reload == true) {
            setTimeout(function () {
              window.location.href = redirectURL;
            }.bind(this), 3000);
          }
          return false;
        }
      })
      .catch(error => {
        // handle error
        store.addNotification({
          title: "Session expired",
          message: "Kindly reload/refresh the page and sign-in again.",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: false
          }
        });
      });
    this.reloadCards();
  }

  handleSetupFailed = () => {

  }

  handleDeleteDialogOpen = (card) => {
    this.setState({
      cardToDelete: card,
      openDeleteDialog: true,
    });
  }

  handleDeleteDialogClose = (event) => {
    this.setState({
      cardToDelete: null,
      openDeleteDialog: false,
    });
  }

  handleDeleteCard = (event) => {
    let bodyFormData = new FormData();
        bodyFormData.append('api_key', this.apiKey);
        bodyFormData.append('access_token', this.accessToken);
        bodyFormData.append('jham_token', this.jhamToken);
        bodyFormData.append('stripe_card_id', this.state.cardToDelete.id);

    axios({
      method: 'post',
      url: this.apiURL + '/account/delete_card',
      data: bodyFormData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      console.log(response.data)
      if (response.data.error == 0) {
        this.reloadCards();
      } else {
        var reload = false;
        var redirect = false;
        var redirectURL = "";
        var errorTitle = "";
        var errorMsg = "";
        switch (response.data.error) {
          case 1:
            errorTitle = "Oooops";
            errorMsg = response.data.error_msg;
            break;
          case 2:
            reload = true;
            redirectURL = "/sign-in";
            errorTitle = "Your session has expired";
            errorMsg = "Please login again. This page will automatically reload.";
            break;
          case 3:
              errorTitle = "Oooops";
              errorMsg = response.data.error_msg;
              break;
          default:
            break;
        }

        // Show error notification
        store.addNotification({
          title: errorTitle,
          message: errorMsg,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: false
          }
        });
        // Redirect
        if (reload == true) {
          setTimeout(() => {
            // this.props.history.push(redirectURL);
            window.location.href = redirectURL;
          }, 3000);
        }
      }
      this.setState({ openDeleteDialog: false });
    })
    .catch((error) => {
      // handle error
      console.log(error);
      store.addNotification({
        title: "Oops Something went wrong",
        message: "The action you have requested is not allowed. Please reload the page",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: false
        }
      });
    });
  }

  handleSetPrimarycard = (card) => {
    let bodyFormData = new FormData();
        bodyFormData.append('api_key', this.apiKey);
        bodyFormData.append('access_token', this.accessToken);
        bodyFormData.append('jham_token', this.jhamToken);
        bodyFormData.append('stripe_card_id', card.id);

    axios({
      method: 'post',
      url: this.apiURL + '/account/set_primary_card',
      data: bodyFormData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      if (response.data.error == 0 && response.data.status == 1) {
        this.reloadCards();
      } else {
        var redirect = false;
        var redirectURL = "";
        var errorTitle = "";
        var errorMsg = "";
        switch (response.data.error) {
          case 1:
          case 2:
            redirect = true;
            redirectURL = "/sign-in";
            errorTitle = "Your session has expired";
            errorMsg = "Please login again. This page will automatically reload.";
            break;
          default:
            break;
        }

        // Show error notification
        store.addNotification({
          title: errorTitle,
          message: errorMsg,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: false
          }
        });
        // Redirect
        if (redirect == true) {
          setTimeout(function() {
            this.props.history.push(redirectURL);
          }.bind(this), 3000);
        }
      }
    })
    .catch((error) => {
      // handle error
      console.log(error);
      store.addNotification({
        title: "Oops Something went wrong",
        message: "The action you have requested is not allowed. Please reload the page",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: false
        }
      });
    });
  }

  reloadCards = () => {
    let bodyFormData = new FormData();
        bodyFormData.append('api_key', this.apiKey);
        bodyFormData.append('access_token', this.accessToken);
        bodyFormData.append('jham_token', this.jhamToken);

    axios({
      method: 'post',
      url: this.apiURL + '/account/saved_cards',
      data: bodyFormData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      if (response.data.error == 0) {
        var cards = [];
        var primaryCard = 0;
        response.data.cards.map((card) => {
          cards.push({
            id: card.stripe_pm_id,
            number: card.stripe_card_last4,
            type: card.stripe_card_type,
            primary: parseInt(card.stripe_card_primary),
            expiry: ("0" + card.stripe_card_exp_month).slice(-2) + " / " + card.stripe_card_exp_year.slice(-2),
          });
          if (card.stripe_card_primary == 1) {
            primaryCard = card.stripe_pm_id;
          }
        });
        this.setState({
          card: primaryCard,
          cardList: cards,
          openAddDialog: false,
          openDeleteDialog: false,
        });
      }
    });
  }

  render() {
    const { classes } = this.props;

    function CardListLoader() {
      return (
        <ContentLoader
          height={156}
          speed={1}
          style={{
            width: '100%',
          }}
        >
          <rect x="0" y="0" rx="0" ry="0" width="411" height="156" />
        </ContentLoader>
      )
    }

    function CustomerCard(props) {
      return (
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={4} sm={4} align="center">
                  <PaymentIcon id={props.card.type.toLowerCase()} className={classes.cardImage} />
                </Grid>
                <Grid item xs={8} sm={8}>
                  <Typography variant="h4" className={classes.cardType}>
                    {props.card.type.toUpperCase()}
                  </Typography>
                  <Typography variant="h5" className={classes.cardNumber}>
                    &#9679;&#9679;{props.card.number}
                  </Typography>
                  <Typography variant="caption" className={classes.cardExpiry}>
                    Expires: {props.card.expiry}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Grid container justify="space-between" spacing={2}>
                <Grid item>
                  {props.card.primary ? (
                    <Button color="default" className={classes.cardPrimarylabel} >
                      Primary
                    </Button>
                  ) : (
                    <Button variant="outlined" color="primary" size="small" value={props.card} className={classes.actionButtons} onClick={() => props.onSetPrimaryClick(props.card)}>
                      Set as primary
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Button color="secondary" onClick={() => props.onDeleteClick(props.card)} className={classes.actionButtons}>
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      );
    }
    
    function NewCustomerCard(props) {
      return (
        <Grid item xs={12} sm={6}>
          <Card className={classes.newCard} onClick={props.onClick}>
            <CardContent>
              <AddCircleIcon fontSize="large" color="primary" />
              <Typography variant="h5" component="span" align="center" color="primary">
                Add new card
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      );
    }

    return (
      <React.Fragment>
        <Grid container spacing={3}>
          {this.state.cardsLoading ? (
            <React.Fragment>
              <Grid item xs={12} sm={6}>
                <CardListLoader />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CardListLoader />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CardListLoader />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CardListLoader />
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {this.state.cardList.map((card, index) =>
                <CustomerCard key={index} card={card} onSetPrimaryClick={this.handleSetPrimarycard} onDeleteClick={this.handleDeleteDialogOpen} />
              )}
              <NewCustomerCard onClick={this.handleAddDialogOpen} />
            </React.Fragment>
          )}
        </Grid>

        <Dialog
          maxWidth="sm"
          open={this.state.openDeleteDialog}
          onClose={this.handleDeleteDialogClose}
          TransitionComponent={Transition}
        >
          <DialogTitle>
            Delete this card?
          </DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.dialogContentText}>
              Are you sure you wish to delete your card ending in <strong>&#9679;&#9679;{this.state.cardToDelete ? this.state.cardToDelete.number.slice(-4) : ""}</strong>?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button onClick={this.handleDeleteDialogClose} className={classes.actionButtons}>
              Cancel
            </Button>
            <Button variant="contained" color="secondary" className={classes.actionButtons} onClick={this.handleDeleteCard}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          maxWidth="sm"
          open={this.state.openAddDialog}
          onClose={this.handleAddDialogClose}
          onEntered={this.handleAddDialogEntered}
          TransitionComponent={Transition}
          className={classes.newCardDialog}
        >
          <DialogTitle>
            New Card
          </DialogTitle>
          <DialogContent style={{ overflowY: "hidden"}}>
            <NewCardForm publishableKey={this.state.stripePublishableKey} onSuccess={this.handleSetupSuccess} onFailed={this.handleSetupFailed} />
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button onClick={this.handleAddDialogClose} className={classes.actionButtons}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default withStyles(useStyles)(Cards)
