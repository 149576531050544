import React from "react";
import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider
} from '@material-ui/core/styles';
import axios from 'axios';
import ReactGA from 'react-ga';
import { store } from 'react-notifications-component';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ExtensionDetails from './ExtensionDetails';
import CircularProgress from '@material-ui/core/CircularProgress';
import PaymentIcon from 'react-payment-icons';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelIcon from '@material-ui/icons/Cancel';
import { orange, grey, green, red } from '@material-ui/core/colors';
import 'react-credit-cards/es/styles-compiled.css';
import "../assets/css/checkout.css";
import CheckoutForm from "./CheckoutForm";
import NewCardForm from "../components/NewCardForm";

const useStyles = theme => ({
  layout: {
    padding: theme.spacing(5, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
    }
  },
  title: {
    fontWeight: 300,
    marginBottom: theme.spacing(3),
  },
  cardsDropdown: {
    color: grey[800],
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '& .MuiFormLabel-root': {
      fontSize: '1.7rem',
      marginBottom: theme.spacing(-2),
      backgroundColor: '#FFF',
      paddingRight: 5,
      '&.Mui-focused': {
        color: grey[800],
      },
    },
    '& .MuiFormControl-root': {
      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        '&:hover fieldset': {
          borderColor: '#B4B4B4',
        },
        '&.Mui-focused fieldset': {
          borderColor: orange[600],
        },
      },
    },
  },
  cardsDropdownMenuItem: {
    padding: theme.spacing(2),
    '& span': {
      fontSize: '1.25rem',
    }
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  cardDetails: {
    color: grey[800],
    '& .MuiFormControlLabel-root': {
      marginBottom: 0,
    },
    '& .MuiFormControl-root': {
      '& .MuiFormLabel-root': {
        backgroundColor: 'white',
        '&.Mui-focused': {
          color: orange[600],
        },
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        '&:hover fieldset': {
          borderColor: '#B4B4B4',
        },
        '&.Mui-focused fieldset': {
          borderColor: orange[600],
        },
      },
    },
  },
  gridItem1: {
    order: 2,
    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  gridItem2: {
    order: 1,
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
  },
  completedStatusIcon: {
    fontSize: '10rem',
    color: green[600],
  },
  failedStatusIcon: {
    fontSize: '10rem',
    color: red[600],
  },
  statusMessage: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '3.5rem',
    },
  },
  statusCaption: {
    fontSize: '2rem !important',
    margin: '0 auto',
    marginTop: theme.spacing(4),
    maxWidth: 800,
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.5rem !important',
    },
  },
  icon: {
    fontSize: '10rem',
    color: red[600],
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: grey[200] + '!important',
    height: 3,
  },
});

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#2F96B4',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

function FacebookCircularProgress(props) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}

const theme = createMuiTheme({
  typography: {
    fontSize: 18,
  },
});

class ExtensionPayment extends React.Component {
  constructor(props) {
    super(props);

    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);

    this.api = {
      url: props.mainState.apiURL,
      key: props.mainState.apiKey,
    };

    this.state = {
      loading: true,
      cardsLoading: true,
      stripePublishableKey: "",
      stripeClientSecret: "",
      paymentType: "one-time",
      billingAddress: null,
      schedule: null,
      vehicle: null,
      extension: null,
      paymentStatus: "pending",
      availabilityCheckerInterval: null,
      card: 0,
      cardList: [],
      newCard: "",
      useNewCard: false,
    };
  };

  componentDidMount() {
    let formData = new FormData();
        formData.append('api_key', this.api.key);
        formData.append('access_token', localStorage.getItem('ctoken'));
        formData.append('jham_token', localStorage.getItem('token'));

    // Check for pending payment
    axios({
      method: 'post',
      url: this.api.url + '/transaction/pending_extension',
      data: formData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      if (response.data.error == 0) {
        if (response.data.extension) {
          let extensionType = "";
          if (response.data.extension.es_type == "days") {
            extensionType = "day";
          } else if (response.data.extension.es_type == "weeks") {
            extensionType = "week";
          } else if (response.data.extension.es_type == "months") {
            extensionType = "month";
          }

          var extension = {
            id: response.data.extension.es_id,
            amount: response.data.extension.es_amount,
            qty: response.data.extension.es_count,
            es_lease_rate: response.data.extension.es_lease_rate,
            es_type: response.data.extension.es_type,
            type: extensionType,
          };
          var schedule = {
            id: response.data.extension.s_id,
            amount: response.data.extension.s_payable_amount,
            leaseType: response.data.extension.s_payment_mode,
            startDate: response.data.extension.es_start_date,
            endDate: response.data.extension.es_end_date,
            monthlyCharge: response.data.extension.monthly_charge,
            billingFname: response.data.extension.billing_fname,
            billingLname: response.data.extension.billing_lname,
          };
          var vehicle = {
            id: response.data.extension.product_id,
            name: response.data.extension.product_name,
            image: response.data.extension.product_image,
            type: response.data.extension.product_type,
            model: response.data.extension.product_model,
            brand: response.data.extension.product_brand,
            plateNumber: response.data.extension.product_platenumber,
          };

          this.setState({
            loading: false,
            stripePublishableKey: response.data.payment_params.publishable_key,
            extension: extension,
            schedule: schedule,
            vehicle: vehicle,
            paymentType: response.data.extension.payment_mode,
          });
          if (response.data.extension.payment_mode == "one-time") {
            this.setState({
              stripeClientSecret: response.data.payment_params.client_secret,
            });
            this.startExtensionAvailabilityChecker();
          } else {
            // Get saved cards
            axios({
              method: 'post',
              url: this.api.url + '/account/saved_cards',
              data: formData,
              withCredentials: true,
              headers: { 'Content-Type': 'multipart/form-data' }
            })
              .then((response) => {
                let reload = false;
                let redirect = false;
                let redirectURL = "";
                let notifTitle = "";
                let notifMessage = "";
                let notifType = "danger";

                if (response.data.error == 0) {
                  var cards = [];
                  var primaryCard = 0;
                  response.data.cards.map((card) => {
                    cards.push({
                      id: card.stripe_pm_id,
                      number: card.stripe_card_last4,
                      type: card.stripe_card_type,
                      primary: parseInt(card.stripe_card_primary)
                    });
                    if (card.stripe_card_primary == 1) {
                      primaryCard = card.stripe_pm_id;
                    }
                  });
                  this.setState({
                    cardsLoading: false,
                    card: primaryCard,
                    cardList: cards,
                  });
                  this.startExtensionAvailabilityChecker();
                } else {
                  switch (response.data.error_type) {
                    case "validation_error":
                      function ErrorMsg(props) {
                        return (
                          props.msgs.map((item, i) =>
                            <li key={"err-" + item.i}>{item.value}</li>
                          )
                        );
                      }
                      notifTitle = "Invalid Request";
                      notifMessage = <ErrorMsg msgs={response.data.error_msg} />;
                      notifType = "danger";
                      break;
                    case "authorization_error":
                      redirect = true;
                      redirectURL = "/signin";
                      notifTitle = "Your session has expired";
                      notifMessage = "Please login again. This page will automatically reload.";
                      notifType = "danger";
                      break;
                    default:
                      notifTitle = "Something went wrong";
                      notifMessage = response.data.error_msg;
                      notifType = "danger";
                      break;
                  }
                  if (reload == true) {
                    setTimeout(function () {
                      window.location.href = redirectURL;
                    }.bind(this), 3000);
                  }
                  if (redirect == true) {
                    setTimeout(function () {
                      this.props.history.push(redirectURL);
                    }.bind(this), 3000);
                  }
                  showNotification(notifTitle, notifMessage, notifType); // Show error notification
                }
              })
              .catch(error => {
                if (error.response && error.response.status == 403) {
                  let errorTitle = "Oops Something went wrong";
                  let errorMessage = "The action you have requested is not allowed. Please reload the page";
                  showNotification(errorTitle, errorMessage, "danger"); // Show error notification
                }
              });
          }
        } else {
          // Show error notification
          store.addNotification({
            title: "No pending payment",
            message: "This page will reload automatically",
            type: "info",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: false
            }
          });
          setTimeout(() => {
            window.location.href = "/user-profile";
          }, 3000);
        }
      } else {
        var redirect = false;
        var redirectURL = "";
        var errorTitle = "";
        var errorMsg = "";
        switch (response.data.error) {
          case 1:
          case 2:
            redirect = true;
            redirectURL = "/sign-in";
            errorTitle = "Your session has expired";
            errorMsg = "Please login again. This page will automatically reload.";
            break;
          case 3:
            // Failed to create PayPal subscription
            errorTitle = "An error has occurred";
            errorMsg = "Please reload this page and try again.";
            break;
          default:
            break;
        }
        // Show error notification
        store.addNotification({
          title: errorTitle,
          message: errorMsg,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: false
          }
        });
        // Redirect
        if (redirect == true) {
          setTimeout(function() {
            this.props.history.push(redirectURL);
          }.bind(this), 3000);
        }
        return false;
      }
    })
    .catch(error => {
        // handle error
        if (error.response && error.response.status == 403) {
          store.addNotification({
            title: "Something went wrong",
            message: "The action you have requested is not allowed. Please reload the page",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false
            }
          });
        }
    });
  }

  startExtensionAvailabilityChecker = () => {
    let formData = new FormData();
    formData.append('api_key', this.api.key);
    formData.append('access_token', localStorage.getItem('ctoken'));
    formData.append('jham_token', localStorage.getItem('token'));

    this.state.availabilityCheckerInterval = setInterval(() => {
      axios({
        method: 'post',
        url: this.api.url + '/transaction/check_pending_extension_availability',
        data: formData,
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then((response) => {
          if (response.data.status == 1) {

          } else {
            var redirect = false;
            var redirectURL = "";
            var errorTitle = "";
            var errorMsg = "";
            switch (response.data.error) {
              case 1:
              case 2:
                redirect = true;
                redirectURL = "/sign-in";
                errorTitle = "Your session has expired";
                errorMsg = "Please login again. This page will automatically reload.";
                break;
              case 3:
              case 4:
                clearInterval(this.state.availabilityCheckerInterval);
                this.setState({
                  paymentStatus: "unavailable",
                  availabilityCheckerInterval: null,
                });
                break;
              default:
                break;
            }
            // Show error notification
            store.addNotification({
              title: errorTitle,
              message: errorMsg,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: false
              }
            });
            // Redirect
            if (redirect == true) {
              setTimeout(function () {
                this.props.history.push(redirectURL);
              }.bind(this), 3000);
            }
            return false;
          }
        })
        .catch(error => {
          console.log(error);
        });
    }, 10000);
  }

  handleCardChange = (event) => {
    const target = event.target;
    this.setState({ card: target.value });
  }

  handlePayment = (event) => {
    event.preventDefault();
    this.setState({
      loading: true
    });

    let formData = new FormData();
        formData.append('api_key', this.api.key);
        formData.append('access_token', localStorage.getItem('ctoken'));
        formData.append('jham_token', localStorage.getItem('token'));
        formData.append('card_holder_name', this.state.cardDetails.name);
        formData.append('card_number', this.state.cardDetails.number);
        formData.append('card_expiry', this.state.cardDetails.expiry);
        formData.append('card_cvc', this.state.cardDetails.cvc);
        formData.append('save_card', this.state.cardDetails.save === true ? 1 : 0);

    axios({
      method: 'post',
      url: this.api.url + '/transaction/payment',
      data: formData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      if (response.data.status == 1 && response.data.error == 0) {
        ReactGA.event({
          category: 'Checkout',
          action: 'Payment',
        });
        localStorage.setItem('paymentSuccessful', 1);
        localStorage.setItem('paymentType', this.state.paymentType);
        localStorage.setItem('paymentMethod', "windcave");
        localStorage.setItem('paymentAmount', response.data.transaction.s_payable_amount);
        localStorage.setItem('cancellationFee', this.state.cancellationFee);
        localStorage.removeItem('checkout');
        localStorage.removeItem('currrent_vehicle');
        localStorage.removeItem('checkoutProgress');
        localStorage.removeItem('checkoutCompleted');
        localStorage.removeItem('hasPendingPayment');
        this.props.handlerState('checkoutProgress', null);
        this.props.history.push('/checkout/payment/success');
      } else if (response.data.status == 0 && response.data.error == 6) {
        localStorage.setItem('paymentFailed', 1);
        this.props.history.push('/checkout/payment/failed');
      } else {
        var redirect = false;
        var redirectURL = "";
        var errorTitle = "";
        var errorMsg = "";
        switch (response.data.error) {
          case 1:
          case 2:
            redirect = true;
            redirectURL = "/signin";
            errorTitle = "Your session has expired";
            errorMsg = "Please login again. This page will automatically reload.";
            break;
          case 3:
          case 4:
            redirect = true;
            redirectURL = "/";
            errorTitle = "Something went wrong";
            errorMsg = response.data.error_msg + " This page will automatically reload.";
            break;
          case 5:
            redirect = true;
            redirectURL = "/checkout/payment";
            errorTitle = "An error has occurred when processing your payment";
            errorMsg = "Please try again. This page will automatically reload.";
            break;
          default:
            break;
        }

        // Show error notification
        store.addNotification({
          title: errorTitle,
          message: errorMsg,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: false
          }
        });
        // Redirect
        if (redirect == true) {
          setTimeout(function() {
            // this.props.history.push(redirectURL);
            window.location.href = redirectURL;
          }.bind(this), 3000);
        }
      }

      this.setState({ loading: false });
    })
    .catch((error) => {
        console.log(error);
        // handle error
        this.setState({ loading: false });
        if (error.response && error.response.status == 403) {
          store.addNotification({
            title: "Oops Something went wrong",
            message: "The action you have requested is not allowed. please re-load the page",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false
            }
          });
        }
    });
  }

  handlePaymentSuccess = (paymentIntentId) => {
    clearInterval(this.state.availabilityCheckerInterval);
    this.setState({
      loading: true,
      availabilityCheckerInterval: null,
    });

    let formData = new FormData();
    formData.append('api_key', this.api.key);
    formData.append('access_token', localStorage.getItem('ctoken'));
    formData.append('jham_token', localStorage.getItem('token'));
    formData.append('payment_intent_id', paymentIntentId);
    formData.append('remember_card', localStorage.getItem('rememberCard'));

    axios({
      method: 'post',
      url: this.api.url + '/transaction/extension_payment',
      data: formData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      if (response.data.status == 1 && response.data.error == 0) {
        this.setState({
          paymentStatus: "completed",
        });
      } else if (response.data.status == 0 && response.data.error == 5) {
        this.setState({
          paymentStatus: "unavailable",
        });
        // localStorage.setItem('paymentFailed', 1);
        // this.props.history.push('/checkout/payment/failed');
      } else {
        var reload = false;
        var redirect = false;
        var redirectURL = "";
        var errorTitle = "";
        var errorMsg = "";
        switch (response.data.error) {
          case 1:
          case 2:
            reload = true;
            redirect = true;
            redirectURL = "/signin";
            errorTitle = "Your session has expired";
            errorMsg = "Please login again. This page will automatically reload.";
            break;
          case 3:
            reload = true;
            redirect = true;
            redirectURL = "/";
            errorTitle = "Something went wrong";
            errorMsg = response.data.error_msg + " This page will automatically reload.";
            break;
          case 4:
            reload = true;
            redirect = true;
            redirectURL = "/payment";
            errorTitle = "Something went wrong";
            errorMsg = response.data.error_msg + " This page will automatically reload.";
            break;
          default:
            break;
        }

        // Show error notification
        store.addNotification({
          title: errorTitle,
          message: errorMsg,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: false
          }
        });
        // Redirect
        if (redirect == true) {
          setTimeout(function() {
            if (reload == true) {
              window.location.href = redirectURL;
            } else {
              this.props.history.push(redirectURL);
            }
          }.bind(this), 3000);
        } else {
          if (reload == true) {
            window.location.reload();
          }
        }
      }

      this.setState({ loading: false });
    })
    .catch((error) => {
        console.log(error);
        // handle error
        this.setState({ loading: false });
        if (error.response && error.response.status == 403) {
          store.addNotification({
            title: "Oops Something went wrong",
            message: "The action you have requested is not allowed. please re-load the page",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false
            }
          });
        }
    });
  }

  handlePaymentFailed = (error) => {
    this.setState({
      paymentStatus: "failed",
    });
  }

  handleConfirmExtensionRecurring = () => {
    clearInterval(this.state.availabilityCheckerInterval);
    this.setState({
      loading: true,
      availabilityCheckerInterval: null,
    });

    let formData = new FormData();
    formData.append('api_key', this.api.key);
    formData.append('access_token', localStorage.getItem('ctoken'));
    formData.append('jham_token', localStorage.getItem('token'));
    if (this.state.useNewCard == false) {
      formData.append('stripe_card_id', this.state.card);
    } else {
      formData.append('stripe_card_id', this.state.newCard);
    }
    formData.append('use_new_card', this.state.useNewCard);

    axios({
      method: 'post',
      url: this.api.url + '/transaction/confirm_extension_recurring',
      data: formData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      if (response.data.status == 1 && response.data.error == 0) {
        this.setState({
          paymentStatus: "completed",
        });
      } else if (response.data.status == 0 && response.data.error == 5) {
        // Payment declined
        // ReactGA.event({
        //   category: 'User',
        //   action: 'Payment failed'
        // });
        localStorage.setItem('paymentFailed', 1);
        this.props.history.push('/checkout/payment/failed');
      } else {
        var reload = false;
        var redirect = false;
        var redirectURL = "";
        var errorTitle = "";
        var errorMsg = "";
        switch (response.data.error) {
          case 1:
          case 2:
            reload = true;
            redirect = true;
            redirectURL = "/signin";
            errorTitle = "Your session has expired";
            errorMsg = "Please login again. This page will automatically reload.";
            break;
          case 3:
            reload = true;
            redirect = true;
            redirectURL = "/";
            errorTitle = "Something went wrong";
            errorMsg = response.data.error_msg + " This page will automatically reload.";
            break;
          case 4:
            reload = true;
            redirect = true;
            redirectURL = "/payment";
            errorTitle = "Something went wrong";
            errorMsg = response.data.error_msg + " This page will automatically reload.";
            break;
          default:
            break;
        }

        // Show error notification
        store.addNotification({
          title: errorTitle,
          message: errorMsg,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: false
          }
        });
        // Redirect
        if (redirect == true) {
          setTimeout(function() {
            if (reload == true) {
              window.location.href = redirectURL;
            } else {
              this.props.history.push(redirectURL);
            }
          }.bind(this), 3000);
        } else {
          if (reload == true) {
            window.location.reload();
          }
        }
      }

      this.setState({ loading: false });
    })
    .catch((error) => {
        console.log(error);
        // handle error
        this.setState({ loading: false });
        if (error.response && error.response.status == 403) {
          store.addNotification({
            title: "Oops Something went wrong",
            message: "The action you have requested is not allowed. please re-load the page",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false
            }
          });
        }
    });
  }

  handleSetupSuccess = (setupIntent) => {
    this.setState({
      newCard: setupIntent.payment_method,
      useNewCard: true,
    });
    this.handleConfirmExtensionRecurring();
  }

  handleSetupFailed = () => {
    alert();
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          {this.state.paymentStatus == "pending" &&
            <Container component="main" maxWidth="lg" className="checkout" classes={{ root: classes.layout }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={7} md={8} className={classes.gridItem1}>
                  {this.state.loading && (
                    <Box className={classes.loadingWrapper}>
                      <FacebookCircularProgress />
                    </Box>
                  )}

                  {!this.state.loading &&
                    <Paper elevation={0}>
                      {this.state.paymentType == "one-time" &&
                        <CheckoutForm publishableKey={this.state.stripePublishableKey} clientSecret={this.state.stripeClientSecret} onSuccess={this.handlePaymentSuccess} onFailed={this.handlePaymentFailed} expectedPaymentStatus="succeeded" paymentType={this.state.paymentType} />
                      }
                      {this.state.paymentType == "recurring" &&
                        <React.Fragment>
                          <Typography variant="h6" className={classes.title} gutterBottom>
                            Confirm your extension by selecting a card to be used for the recurring payments.
                          </Typography>
                          {(this.state.cardList.length > 0 && !this.state.cardsLoading) && (
                            <React.Fragment>
                              <Grid container spacing={3} className={classes.cardsDropdown} alignItems="center">
                                <Grid item xs={12}>
                                  <FormControl variant="outlined" fullWidth={true}>
                                    <InputLabel id="card-list">Select a card to use</InputLabel>
                                    <Select
                                      labelId="card-list"
                                      id="card-list"
                                      value={this.state.card}
                                      onChange={this.handleCardChange}
                                      label="Select a card to use"
                                    >
                                      {this.state.cardList.map((item, i) => {
                                        return (
                                          <MenuItem key={i} value={item.id} className={classes.cardsDropdownMenuItem}>
                                            <PaymentIcon id={item.type.toLowerCase()} style={{ marginRight: 10, width: 50 }} className="payment-icon" />
                                            <Typography component="span">
                                              {item.type.toUpperCase()} ending in <strong>&#9679;&#9679;{item.number}</strong> {item.primary ? <Typography component="span">(<em>Primary</em>)</Typography> : ""}
                                            </Typography>
                                          </MenuItem>
                                        )
                                      })}
                                      <MenuItem value={0}>
                                        <CreditCardIcon className="payment-icon" style={{ marginRight: 10, width: 50, verticalAlign: 'middle' }} />
                                        <Typography component="span">
                                          <em>Enter new card</em>
                                        </Typography>
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <Divider className={classes.divider} variant="middle" />
                              {this.state.card != 0 && (
                                <Grid container spacing={3} alignItems="center">
                                  <Grid item xs={12}>
                                    <Box textAlign="right">
                                      <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleConfirmExtensionRecurring}
                                        disabled={this.state.loading}
                                        size="large"
                                      >
                                        {!this.state.loading ? (
                                          <span>Continue</span>
                                        ) : (
                                          <span>Please wait</span>
                                        )}
                                      </Button>
                                    </Box>
                                  </Grid>
                                </Grid>
                              )}
                            </React.Fragment>
                          )}
                          {this.state.card == 0 && (
                            <NewCardForm publishableKey={this.state.stripePublishableKey} onSuccess={this.handleSetupSuccess} onFailed={this.handleSetupFailed} />
                          )}
                        </React.Fragment>
                      }
                    </Paper>
                  }
                </Grid>

                <Grid item xs={12} sm={5} md={4} className={classes.gridItem2}>
                  <ExtensionDetails extension={this.state.extension} schedule={this.state.schedule} vehicle={this.state.vehicle} />
                </Grid>
              </Grid>
            </Container>
          }
          {this.state.loading == false && this.state.paymentStatus == "completed" &&
            <Container component="main" maxWidth="lg" className={classes.layout}>
              <Typography variant="h3" align="center" gutterBottom>
                <CheckCircleRoundedIcon className={classes.completedStatusIcon} />
              </Typography>
              <Typography variant="h3" component="h1" align="center" gutterBottom className={classes.statusMessage}>
                Transaction Completed
              </Typography>
              <Typography variant="caption" component="h1" align="center" className={classes.statusCaption}>
                Thank you for leasing with EARental. Your lease with schedule no.: {this.state.schedule.id} has been extended for {this.state.extension.qty} {this.state.extension.type}(s).
              </Typography>
            </Container>
          }
          {this.state.loading == false && this.state.paymentStatus == "unavailable" &&
            <Container component="main" maxWidth="lg" className={classes.layout}>
              <Typography variant="h3" align="center" gutterBottom>
                <CancelIcon className={classes.failedStatusIcon} />
              </Typography>
              <Typography variant="h3" component="h1" align="center" gutterBottom className={classes.statusMessage}>
                Extension Not Available
              </Typography>
              <Typography variant="caption" component="h1" align="center" className={classes.statusCaption}>
                We're sorry the extension period you selected is no longer available. Click <a href="/user-profile" target="_blank">here</a> to manage your ongoing rentals and select a different extension period.
              </Typography>
            </Container>
          }
          {this.state.loading == false && this.state.paymentStatus == "failed" &&
            <Container component="main" maxWidth="sm" className={classes.layout}>
              <Typography variant="h3" align="center">
                <CancelIcon className={classes.icon} />
              </Typography>
              <Typography variant="h3" component="h1" align="center" gutterBottom className={classes.title}>
                Your payment has failed
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Here are some of the reasons why this transaction was declined.
              </Typography>
              <Typography variant="body1" component="ul" className={classes.list} gutterBottom>
                <li>Invalid card details was entered on the payment form;</li>
                <li>You have insufficient funds in your account;</li>
                <li>Your card may have a temporary hold;</li>
                <li>The card you used may have been designated as lost or stolen;</li>
              </Typography>
              <Typography variant="body1">
                Please <a href="/extension/payment">try again with a valid card details</a> and contact your bank if necessary.
              </Typography>
            </Container>
          }
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(ExtensionPayment);

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const showNotification = (title, message, type) => {
  store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: false
    }
  });
}
