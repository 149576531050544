import React from "react";
import {
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import axios from 'axios';
import { store } from 'react-notifications-component';
import NumberFormat from 'react-number-format';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { orange, grey } from '@material-ui/core/colors';
import CheckoutForm from "../../checkout/CheckoutForm";
import CustomCircularProgress from "../../widgets/CircularProgress";

const styles = makeStyles(theme => ({
  dialog: {
    [theme.breakpoints.only('xs')]: {
      '& .MuiDialog-paper': {
        margin: 0,
        width: 'calc(100% - ' + theme.spacing(3) + 'px)',
      },
    }
  },
  dialogContent: {
    // minHeight: 250,
  },
  dialogContentText: {
    fontSize: '1.2rem',
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RejectPayment(props) {
  const theme = useTheme();
  const classes = styles();
  const api = {
    url: "https://api.earental.com",
    key: "72ffc1303c7c0286887a3276f0ab61b8",
  };
  const refURL = localStorage.getItem('ref');
  const [isLoading, setLoading] = React.useState(true);
  const [isPaymentProcessing, setPaymentProcessing] = React.useState(false);
  const [paymentMethod, setPaymentMethod] = React.useState("deposit");
  const [cancellationFee, setCancellationFee] = React.useState(0);
  const [deposit, setDeposit] = React.useState(0);
  const [stripePublishableKey, setStripePublishableKey] = React.useState("");
  const [stripeClientSecret, setStripeClientSecret] = React.useState("");
  const [stripeError, setStripeError] = React.useState("");

  React.useEffect(() => {
    if (props.open) {
      let formData = new FormData();
      formData.append('api_key', api.key);
      formData.append('access_token', localStorage.getItem('ctoken'));
      formData.append('jham_token', localStorage.getItem('token'));
      formData.append('schedule_id', props.sid);
      axios({
        method: 'post',
        url: api.url + '/lessor/initialize_cancel_schedule_transaction',
        data: formData,
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then((response) => {
          if (response.data.error == 0) {
            let deposit = parseFloat(response.data.deposit);
            let cancellationFee = parseFloat(response.data.cancellation_fee);

            setLoading(false);
            setDeposit(deposit);
            setCancellationFee(cancellationFee);
            if (deposit < cancellationFee) {
              setPaymentMethod("card");
            }
            setStripePublishableKey(response.data.payment_params.publishable_key);
            setStripeClientSecret(response.data.payment_params.client_secret);
          } else {
            let reload = false;
            let redirect = false;
            let redirectURL = "";
            let title = "";
            let message = "";
            let type = "danger";
            switch (response.data.error) {
              case 1:
                function ErrorMsg(props) {
                  return (
                    props.msgs.map((item, i) =>
                      <li key={"err-" + item.i}>{item.value}</li>
                    )
                  );
                }
                title = "Invalid Request";
                message = <ErrorMsg msgs={response.data.error_message} />;
                type = "danger";
                break;
              case 2:
                redirect = true;
                redirectURL = refURL + "/signin";
                title = "Your session has expired";
                message = "Please login again. This page will automatically reload.";
                type = "danger";
                break;
              default:
                title = "Something went wrong";
                message = response.data.error_msg;
                type = "danger";
                break;
            }
            if (reload == true) {
              setTimeout(function () {
                window.location.href = redirectURL;
              }.bind(this), 3000);
            }
            if (redirect == true) {
              setTimeout(function () {
                props.history.push(redirectURL);
              }.bind(this), 3000);
            }

            showNotification(title, message, type); // Show error notification
            props.onError();
          }
        })
        .catch((error) => {
          // Handle error\
          if (error.response && error.response.status == 403) {
            let errorTitle = "Oops Something went wrong";
            let errorMessage = "The action you have requested is not allowed. Please reload the page";
            showNotification(errorTitle, errorMessage, "danger"); // Show error notification
          }
        });
    } else {
      setLoading(true);
      setDeposit(0);
      setCancellationFee(0);
      setPaymentMethod("deposit");
      setStripePublishableKey("");
      setStripeClientSecret("");
    }
  }, [props.open]);

  const handleDepositPayment = (event) => {
    event.preventDefault();
    setLoading(true);

    let formData = new FormData();
    formData.append('api_key', api.key);
    formData.append('access_token', localStorage.getItem('ctoken'));
    formData.append('jham_token', localStorage.getItem('token'));
    formData.append('s_id', props.sid);
    formData.append('reject_reason', props.rejectReason);
    formData.append('reject_document', props.rejectDocument);
    formData.append('cancel_reason', props.cancellationReason);
    axios({
      method: 'post',
      url: api.url + '/lessor/cancel_schedule_via_deposit',
      data: formData,
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => {
        let reload = false;
        let redirect = false;
        let redirectURL = "";
        let notifTitle = "";
        let notifMessage = "";
        let notifType = "danger";

        if (response.data.error == 0) {
          // Payment successful
          notifTitle = "Successful";
          notifMessage = "Schedule: " + props.sid + " was canceled.";
          notifType = "success";
          setLoading(true);
          setPaymentProcessing(false);
          setPaymentMethod("deposit");
          setCancellationFee(0);
          setDeposit(0);
          setStripePublishableKey("");
          setStripeClientSecret("");
          props.onScheduleCancelled();
        } else {
          switch (response.data.error_type) {
            case "validation_error":
              function ErrorMsg(props) {
                return (
                  props.msgs.map((item, i) =>
                    <li key={"err-" + item.i}>{item.value}</li>
                  )
                );
              }
              notifTitle = "Invalid Request";
              notifMessage = <ErrorMsg msgs={response.data.error_msg} />;
              notifType = "danger";
              setLoading(false);
              break;
            case "cancel_error":
              notifTitle = "An error has occurred";
              notifMessage = response.data.error_msg;
              notifType = "danger";
              setLoading(false);
              break;
            case "authorization_error":
              redirect = true;
              redirectURL = refURL + "/signin";
              notifTitle = "Your session has expired";
              notifMessage = "Please login again. This page will automatically reload.";
              notifType = "danger";
              break;
            default:
              notifTitle = "Something went wrong";
              notifMessage = response.data.error_msg;
              notifType = "danger";
              break;
          }
        }
        if (reload == true) {
          setTimeout(function () {
            window.location.href = redirectURL;
          }.bind(this), 3000);
        }
        if (redirect == true) {
          setTimeout(function () {
            props.history.push(redirectURL);
          }.bind(this), 3000);
        }

        showNotification(notifTitle, notifMessage, notifType); // Show error notification
      })
      .catch((error) => {
        if (error.response && error.response.status == 403) {
          let errorTitle = "Oops Something went wrong";
          let errorMessage = "The action you have requested is not allowed. Please reload the page";
          showNotification(errorTitle, errorMessage, "danger"); // Show error notification
        }
      });
  };

  const handlePaymentSuccess = (paymentIntentID) => {
    setStripeError("");

    let formData = new FormData();
    formData.append('api_key', api.key);
    formData.append('access_token', localStorage.getItem('ctoken'));
    formData.append('jham_token', localStorage.getItem('token'));
    formData.append('s_id', props.sid);
    formData.append('reject_reason', props.rejectReason);
    formData.append('reject_document', props.rejectDocument);
    formData.append('cancel_reason', props.cancellationReason);
    formData.append('payment_intent_id', paymentIntentID);
    axios({
      method: 'post',
      url: api.url + '/lessor/cancel_schedule',
      data: formData,
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => {
        let reload = false;
        let redirect = false;
        let redirectURL = "";
        let notifTitle = "";
        let notifMessage = "";
        let notifType = "danger";

        if (response.data.error == 0) {
          // Payment successful
          notifTitle = "Successful";
          notifMessage = "Schedule: " + props.sid + " was rejected.";
          notifType = "success";
          setLoading(true);
          setPaymentProcessing(false);
          setPaymentMethod("deposit");
          setCancellationFee(0);
          setDeposit(0);
          setStripePublishableKey("");
          setStripeClientSecret("");
          props.onScheduleCancelled();
        } else {
          switch (response.data.error_type) {
            case "validation_error":
              function ErrorMsg(props) {
                return (
                  props.msgs.map((item, i) =>
                    <li key={"err-" + item.i}>{item.value}</li>
                  )
                );
              }
              notifTitle = "Invalid Request";
              notifMessage = <ErrorMsg msgs={response.data.error_message} />;
              notifType = "danger";
              break;
            case "authorization_error":
              redirect = true;
              redirectURL = refURL + "/signin";
              notifTitle = "Your session has expired";
              notifMessage = "Please login again. This page will automatically reload.";
              notifType = "danger";
              break;
            default:
              notifTitle = "Something went wrong";
              notifMessage = response.data.error_message;
              notifType = "danger";
              break;
          }
        }
        if (reload == true) {
          setTimeout(function () {
            window.location.href = redirectURL;
          }.bind(this), 3000);
        }
        if (redirect == true) {
          setTimeout(function () {
            props.history.push(redirectURL);
          }.bind(this), 3000);
        }

        showNotification(notifTitle, notifMessage, notifType); // Show error notification
      })
      .catch((error) => {
        if (error.response && error.response.status == 403) {
          let errorTitle = "Oops Something went wrong";
          let errorMessage = "The action you have requested is not allowed. Please reload the page";
          showNotification(errorTitle, errorMessage, "danger"); // Show error notification
        }
      });
  };

  const handlePaymentFailed = (error) => {
    setStripeError(error.message);
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={props.open}
      TransitionComponent={Transition}
      onClose={props.onClose}
      className={classes.dialog}
      disableBackdropClick
    >
      <DialogTitle>
        Cancellation Payment
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {isLoading && (
          <Box className={classes.loadingWrapper}>
            <CustomCircularProgress />
          </Box>
        )}
        {isLoading == false && paymentMethod == "card" && (
          <React.Fragment>
            <DialogContentText className={classes.dialogContentText}>
              To process this cancellation, please settle the cancellation fee amounting to <NumberFormat value={cancellationFee} displayType="text" thousandSeparator={true} prefix="S$" />.
            </DialogContentText>
            <Grid container spacing={4}>
              {stripeError != "" && (
                <Grid item xs={12}>
                  <Alert variant="outlined" severity="error">
                    {stripeError}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <CheckoutForm publishableKey={stripePublishableKey} clientSecret={stripeClientSecret} onSuccess={handlePaymentSuccess} onFailed={handlePaymentFailed} expectedPaymentStatus="succeeded" />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {isLoading == false && paymentMethod == "deposit" && (
          <DialogContentText className={classes.dialogContentText}>
            To process this cancellation, an amount of <NumberFormat value={cancellationFee} displayType="text" thousandSeparator={true} prefix="S$" /> will be deducted from your deposit (current deposit: <NumberFormat value={deposit} decimalScale={0} displayType="text" thousandSeparator={true} prefix="S$" />). Click on PROCEED to continue.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {paymentMethod == "card" && (
          <React.Fragment>
            <React.Fragment>
              <Button
                type="button"
                color="default"
                size="large"
                onClick={props.onClose}
              >
                Cancel
              </Button>
            </React.Fragment>
          </React.Fragment>
        )}
        {paymentMethod == "deposit" && (
          <React.Fragment>
            <Button
              type="button"
              color="default"
              size="large"
              onClick={props.onClose}
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleDepositPayment}
              disabled={isLoading || isPaymentProcessing}
              size="large"
            >
              Proceed
            </Button>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
}

const showNotification = (title, message, type) => {
  store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: false
    }
  });
}