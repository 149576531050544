import React, { Component } from "react";
import serviceTopBg from './../assets/img/service.jpg';
import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";
import "./../assets/css/services.css";


var serviceTopImg = {
  backgroundImage: "url(" + serviceTopBg + ")"
};

class Services extends Component {

    constructor (props) {
        ReactGA.set({ page: window.location.pathname });
        ReactGA.pageview(window.location.pathname);

        super(props)
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            MetaTitle:"EARental Singapore Online Van, Truck, Lorry Rental/Hire/Lease",
            MetaDescription:"EARental is Singapore No.1 Online Van, Lorry & Truck commercial vehicles for lease for businesses and individuals. Hassle-Free, book and reserve your vehicle Online Now!",
            MetaKeywords:"online rental, van rental, lorry rental, truck rental, commercial vehicles for rent, leasing, daily rental, weekly rental, weekend rental, monthly rental, yearly rental, short term rental, long term rental, cargo van rental singapore, cheap van rental, van rental price, Toyota hiace van rental singapore, van rental daily, auto van rental singapore, commercial van for rent, van rental near me, daily van rental singapore",
            Canonical:"https://www.earental.com/services",
        };
    }




  render() {
    return (
        <div className="services-cont">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="title" content={this.state.MetaTitle} />
                <meta content={this.state.MetaTitle} property="og:title" />
                <meta name="description" content={this.state.MetaDescription} />
                <meta content={this.state.MetaDescription} property="og:description" />
                <meta name="keywords" content={this.state.MetaKeywords} />
                <meta name="robots" content="index, follow" />
                <title>{this.state.MetaTitle}</title>
                <link rel="canonical" href={this.state.Canonical} />
            </Helmet>
            <div className="bg-holder">
                <div className="bg-mask"></div>
                <div className="bg-blur" style={serviceTopImg}></div>
                <div className="bg-content">
                    <div className="container">
                        <div className="gap gap-big text-center text-white">
                            <h2 className="text-uc mb20">Our Services</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gap"></div>
            <div className="container text-center">
                <div className="row">
                    <div className="col-md-12">
                        <p className="text-bigger">As long as your job or project requires a commercial vehicle, we will be able to provide that service for you.  We offer flexible packages that suits your needs. Rest assured that the safety of those who drive our vehicles is a top priority with us at all times.</p>
                    </div>
                </div>
            </div>
            <div className="container text-center">
                <div className="gap"></div>
                <h2>Short Term Rental</h2>
                <div className="gap"></div>
                <div className="col-md-12">
                    <div className="col-md-4">
                        <h5>Weekend Rental</h5>
                        <a className="hover-img" href="#">
                            <img src="img/service-hourly.jpg" alt="" title="" />
                            <div className="hover-inner">
                                <h3>As low as</h3><h1> S$250/<br/>weekend</h1>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <h5>Daily Rental</h5>
                        <a className="hover-img" href="#">
                            <img src="img/service-daily.jpg" alt="" title="" />
                            <div className="hover-inner">
                                <h3>As low as</h3><h1> S$65/day</h1>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <h5>Weekly Rental</h5>
                        <a className="hover-img" href="#">
                            <img src="img/service-weekly.jpg" alt="" title="" />
                            <div className="hover-inner">
                                <h3>As low as</h3><h1> S$450/week</h1>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div className="container text-center">
                <div className="gap"></div>
                <h2>Long Term Leasing</h2>
                <div className="gap"></div>
                <div className="col-md-12">
                    <div className="col-md-4">
                        <h5>Monthly Rental</h5>
                        <a className="hover-img" href="#">
                            <img src="img/service-monthly.jpg" alt="" title="" />
                            <div className="hover-inner">
                                <h3>As low as</h3><h1> S$1,350/<br />month</h1>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <h5>Long-Term Rental</h5>
                        <a className="hover-img" href="#">
                            <img src="img/service-6month.jpg" alt="" title="" />
                            <div className="hover-inner">
                                <h3>As low as</h3><h1> S$1,200/6 month</h1>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <h5>Yearly Rental</h5>
                        <a className="hover-img" href="#">
                            <img src="img/service-yearly.jpg" alt="" title="" />
                            <div className="hover-inner">
                                <h3>As low as</h3><h1> S$1,300/<br />month</h1>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div className="gap"></div>
        </div>
    );
  }
}

export default Services;
