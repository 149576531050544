import React from "react";
import {
  withStyles,
  styled,
  withTheme,
} from '@material-ui/core/styles';
import axios from 'axios';
import { store } from 'react-notifications-component';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from '@material-ui/core/Container';
import Zoom from '@material-ui/core/Zoom';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import OtpInput from 'react-otp-input';
import { orange, grey, green, red, blue } from '@material-ui/core/colors';

import "../assets/css/checkout.css";

const DashedDivider = styled(withTheme(Divider))(props => ({
  borderTop: '2px dashed',
  borderColor: grey[300],
  height: 0,
  backgroundColor: 'transparent',
  margin: props.theme.spacing(2, 0),
}));

const useStyles = theme => ({
  layout: {
    padding: 0,
  },
  paper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
      maxWidth: 900,
      margin: '0 auto',
    },
  },
  title: {
    fontSize: '2.732142857142857rem',
    fontWeight: 300,
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(5),
  },
  body1: {
    color: grey[700],
    fontSize: '1.3rem',
  },
  rentBreakdown: {
    fontSize: '1.25rem',
    marginBottom: theme.spacing(1) - 6,
    display: 'flex',
    justifyContent: 'space-between',
  },
  rentBreakdown2: {
    fontSize: '1.2rem',
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(1) - 6,
    display: 'flex',
    justifyContent: 'space-between',
  },
  breakdownAmount: {
    fontWeight: 600,
    fontSize: '1.20em',
  },
  button: {
    fontSize: '1.1rem',
  },
  securityMessage: {
    color: grey[700],
    fontSize: '1.1rem',
    letterSpacing: '0.1em',
    textAlign: 'center',
    maxWidth: 600,
    margin: '0 auto',
    marginTop: theme.spacing(4),
  },
  refundMessage: {
    fontSize: '1.2rem',
    fontWeight: 600,
    marginTop: theme.spacing(1),
  },
  otpContainer: {
    margin: theme.spacing(3),
    marginBottom: theme.spacing(1),
    justifyContent: 'center',
  },
  otp: {
    width: '3rem !important',
    margin: '0 0.5rem',
    fontSize: '3rem',
    borderRadius: 4,
    border: '1px solid rgba(0,0,0,0.3)',
    [theme.breakpoints.up('sm')]: {
      width: '4rem !important',
      margin: '0 1rem',
    },
  },
  otpFocused: {
    borderColor: orange[400],
  },
  cancellSuccess: {
    '& h4': {
      textAlign: 'center',
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up('sm')]: {
        fontSize: '3rem',
      },
    },
    '& p': {
      maxWidth: 600,
      fontSize: '1.5rem',
      margin: '0 auto',
    },
  },
  textGreen: {
    color: green[800],
  },
  textLightGreen: {
    color: green[500],
  },
  textRed: {
    color: red[600],
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

class Cancellation extends React.Component {
  constructor(props) {
    super(props);

    this.apiURL = "https://api.earental.com";
    this.apiKey = "72ffc1303c7c0286887a3276f0ab61b8";
    this.accessToken = localStorage.getItem('ctoken');
    this.jhamToken = localStorage.getItem('token');

    // this.apiURL = "http://api.earental.dev";
    // this.apiKey = "72ffc1303c7c0286887a3276f0ab61b8";
    // this.accessToken = localStorage.getItem('ctoken');
    // this.jhamToken = "1058c4e6d09d600e32128fbf6fe5b510";

    this.resendOtpTimer = null;

    this.state = {
      init: true,
      otpLoading: false,
      refundLoading: false,
      refundAmount: 0,
      cardUsed: {
        number: "",
        type: "",
      },
      schedule: {
        id: "",
        rentAmount: 0,
        depositAmount: 0,
        payableAmount: 0,
        startDate: null,
        endDate: null,
      },
      order: {
        invoiceNo: "",
        payableAmount: 0,
        processingFee: 0,
        cancellationFee: 0,
      },
      otpSent: false,
      otp: "",
      resendCounter: 0,
      refundSuccess: false,
      refundFailed: false,
    };
  };

  componentDidMount() {
    let sid = localStorage.getItem('scheduleToCancel');
    // let sid = this.props.mainState.cancelSchedule;
    // let sid = "4EHZP-2020-00001";
    let bodyFormData = new FormData();
        bodyFormData.append('api_key', this.apiKey);
        bodyFormData.append('access_token', this.accessToken);
        bodyFormData.append('jham_token', this.jhamToken);
        bodyFormData.append('schedule_id', sid);

    axios({
      method: 'post',
      url: this.apiURL + '/transaction/get_schedule_cancel_details',
      data: bodyFormData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then(response => {
      if (response.data.error == 0) {
        let cancellation = response.data.cancellation;
        this.setState({
          cardUsed: {
            number: cancellation.card_used.card_number,
            type: cancellation.card_used.card_type,
          },
          refundAmount: cancellation.refund_amount,
          schedule: {
            id: cancellation.schedule.id,
            rentAmount: cancellation.schedule.rent_amount,
            depositAmount: cancellation.schedule.deposit_amount,
            payableAmount: cancellation.schedule.payable_amount,
            startDate: cancellation.schedule.start_date,
            endDate: cancellation.schedule.end_date,
          },
          order: {
            invoiceNo: cancellation.order.invoice_number,
            payableAmount: cancellation.order.payable_amount,
            processingFee: cancellation.order.processing_fee,
            cancellationFee: cancellation.order.cancellation_fee,
          },
        })
      } else {
        var reload = false;
        var redirect = false;
        var redirectURL = "";
        var errorTitle = "";
        var errorMsg = "";
        switch (response.data.error) {
          case 1:
          case 2:
            redirect = true;
            reload = true;
            redirectURL = "/sign-in";
            errorTitle = "Your session has expired";
            errorMsg = "Please login again. This page will automatically reload.";
            break;
          case 3:
            redirect = true;
            reload = true;
            redirectURL = "/user-profile";
            errorTitle = "Something went wrong";
            errorMsg = response.data.error_msg + " This page will automatically reload.";
            break;
          default:
            break;
        }

        // Show error notification
        store.addNotification({
          title: errorTitle,
          message: errorMsg,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: false
          }
        });
        // Redirect
        if (redirect == true) {
          if (reload == false) {
            setTimeout(function() {
              this.props.history.push(redirectURL);
            }.bind(this), 3000);
          } else {
            setTimeout(function() {
              window.location.href = redirectURL;
            }.bind(this), 3000);
          }
        }
        return false;
      }
      this.setState({ init: false });
    })
    .catch(error => {
        // handle error
        this.setState({ init: false });
        if (error.response && error.response.status == 403) {
          store.addNotification({
            title: "Oops Something went wrong",
            message: "The action you have requested is not allowed. please re-load the page",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false
            }
          });
        }
    });
  }

  handleOTPChange = (otp) => {
    this.setState({ otp });
  }

  handleSendOTP = (event) => {
    event.preventDefault();

    this.setState({ otpLoading: true });
    let bodyFormData = new FormData();
        bodyFormData.append('api_key', this.apiKey);
        bodyFormData.append('access_token', this.accessToken);
        bodyFormData.append('jham_token', this.jhamToken);
        bodyFormData.append('schedule_id', this.state.schedule.id);
        bodyFormData.append('type', "cancel");
    axios({
      method: 'post',
      url: this.apiURL + '/transaction/send_otp',
      data: bodyFormData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      if (response.data.status == 1 && response.data.error == 0) {
        // OTP successful
        this.setState({ 
          otpSent: true,
          resendCounter: 5,
        });

        this.resendOtpTimer = setInterval(() => {
          var resendCounter = this.state.resendCounter - 1;
          this.setState({
            resendCounter: resendCounter,
          });
          if (resendCounter <= 0) {
            clearInterval(this.resendOtpTimer);
          } 
        }, 1000)
        // this.otpInput.focus();
      } else {
        var reload = false;
        var redirect = false;
        var redirectURL = "";
        var errorTitle = "";
        var errorMsg = "";
        switch (response.data.error) {
          case 1:
          case 2:
            redirect = true;
            redirectURL = "/signin";
            errorTitle = "Your session has expired";
            errorMsg = "Please login again. This page will automatically reload.";
            break;
          case 3:
            redirect = true;
            redirectURL = "/";
            errorTitle = "Something went wrong";
            errorMsg = response.data.error_msg + " This page will automatically reload.";
            break;
          case 4:
            reload = true;
            errorTitle = "Something went wrong";
            errorMsg = "Please try again. This page will automatically reload.";
            break;
          default:
            break;
        }

        // Show error notification
        store.addNotification({
          title: errorTitle,
          message: errorMsg,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: false
          }
        });

        // Reload
        if (reload == true) {
          setTimeout(function() {
            window.location.reload(false);
          }.bind(this), 3000);
        }
        // Redirect
        if (redirect == true) {
          setTimeout(function() {
            this.props.history.push(redirectURL);
          }.bind(this), 3000);
        }
      }

      this.setState({ otpLoading: false });
    })
    .catch((error) => {
        console.log(error);
        // handle error
        this.setState({ otpLoading: false });
        if (error.response && error.response.status == 403) {
          store.addNotification({
            title: "Oops Something went wrong",
            message: "The action you have requested is not allowed. please re-load the page",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false
            }
          });
        }
    });
  }

  handleRefund = (event) => {
    event.preventDefault();

    this.setState({ refundLoading: true });
    let bodyFormData = new FormData();
        bodyFormData.append('api_key', this.apiKey);
        bodyFormData.append('access_token', this.accessToken);
        bodyFormData.append('jham_token', this.jhamToken);
        bodyFormData.append('otp', this.state.otp);
        bodyFormData.append('schedule_id', this.state.schedule.id);

    axios({
      method: 'post',
      url: this.apiURL + '/transaction/cancel_paid',
      data: bodyFormData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      // console.log(response);
      // this.setState({ refundLoading: false });
      // return false;
      if (response.data.status == 1 && response.data.error == 0) {
        // Refund successful
        this.setState({
          refundLoading: false,
          refundFailed: false,
          refundSuccess: true,
        });
        localStorage.removeItem('scheduleToCancel');
      } else if (response.data.status == 0 && response.data.error == 5) {
        // Refund declined
        this.setState({
          refundLoading: false,
          refundFailed: true,
        });
      } else {
        var reload = false;
        var redirect = false;
        var redirectURL = "";
        var errorTitle = "";
        var errorMsg = "";
        switch (response.data.error) {
          case 1:
          case 2:
            redirect = true;
            redirectURL = "/signin";
            errorTitle = "Your session has expired";
            errorMsg = "Please login again. This page will automatically reload.";
            break;
          case 3:
            redirect = true;
            redirectURL = "/";
            errorTitle = "Something went wrong";
            errorMsg = response.data.error_msg + " This page will automatically reload.";
            break;
          case 4:
            reload = false;
            errorTitle = "Incorrect or invalid OTP";
            errorMsg = "Please try again. If this keeps on occurring, please reload the page.";
            break;
          default:
            break;
        }

        // Show error notification
        store.addNotification({
          title: errorTitle,
          message: errorMsg,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: false
          }
        });

        // Reload
        if (reload == true) {
          setTimeout(function() {
            window.location.reload(false);
          }.bind(this), 3000);
        }
        // Redirect
        if (redirect == true) {
          setTimeout(function() {
            this.props.history.push(redirectURL);
          }.bind(this), 3000);
        }
      }

      this.setState({ refundLoading: false });
    })
    .catch((error) => {
        // handle error
        if (error.response && error.response.status == 403) {
          store.addNotification({
            title: "Oops Something went wrong",
            message: "The action you have requested is not allowed. please re-load the page",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false
            }
          });
        }
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <Container component="main" maxWidth="lg" className="checkout" className={classes.layout}>
          <Paper elevation={0} classes={{root: classes.paper}}>
            {!this.state.init && !this.state.refundSuccess && !this.state.refundFailed && (
              <React.Fragment>
                <Typography variant="h4" className={classes.title} gutterBottom>
                  Cancellation: {this.state.schedule.id}
                </Typography>
                <Typography variant="body1" component="p" className={classes.body1} style={{ marginBottom: 16 }}>
                  This cannot be undone, are you sure you want to cancel this schedule?
                </Typography>
                <Typography variant="body1" component="p" className={classes.body1} style={{ marginBottom: 16 }}>
                  Cancelling this schedule will refund an amount of <strong className={classes.textGreen}>S${this.state.refundAmount.toLocaleString()}</strong> to your credit card, <strong>{this.state.cardUsed.type} ending in &#9679;&#9679;{this.state.cardUsed.number.slice(this.state.cardUsed.number.length - 4)}</strong>. See details below.
                </Typography>
                <Box 
                  style={{
                    maxWidth: 400,
                    margin: '0 auto',
                  }}
                >
                  <Typography variant="body1" component="p" className={[classes.body1, classes.rentBreakdown].join(' ')}>
                    Paid Amount:
                    <span className={[classes.breakdownAmount, classes.textGreen].join(' ')}>S${this.state.schedule.payableAmount}</span>
                  </Typography>
                  <Typography variant="body1" component="p" className={[classes.body1, classes.rentBreakdown2].join(' ')}>
                    Rent Amount: 
                    <span className={[classes.breakdownAmount, classes.textLightGreen].join(' ')}>S${this.state.schedule.rentAmount}</span>
                  </Typography>
                  <Typography variant="body1" component="p" className={[classes.body1, classes.rentBreakdown2].join(' ')}>
                    Deposit Amount: 
                    <span className={[classes.breakdownAmount, classes.textLightGreen].join(' ')}>S${this.state.schedule.rentAmount}</span>
                  </Typography>
                  <Typography variant="body1" component="p" className={[classes.body1, classes.rentBreakdown].join(' ')}>
                    Processing Fee: 
                    <span className={[classes.breakdownAmount, classes.textRed].join(' ')}>-S${this.state.order.processingFee}</span>
                  </Typography>
                  <Typography variant="body1" component="p" className={[classes.body1, classes.rentBreakdown].join(' ')}>
                    Cancellation Fee: 
                    <span className={[classes.breakdownAmount, classes.textRed].join(' ')}>-S${this.state.order.cancellationFee}</span>
                  </Typography>
                  <DashedDivider />
                  <Typography
                    variant="body1"
                    component="p"
                    className={[classes.body1, classes.rentBreakdown].join(' ')}
                    style={{
                      fontSize: '1.25em',
                      fontWeight: 600,
                    }}
                  >
                    Refund Amount: 
                    <span className={[classes.breakdownAmount, classes.textGreen].join(' ')}>S${this.state.refundAmount.toLocaleString()}</span>
                  </Typography>
                </Box>
                {!this.state.otpSent && (
                  <Box 
                    align="right"
                    style={{
                      margin: '0 auto',
                      marginTop: 32,
                      maxWidth: 400,
                    }}
                  >
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={this.handleSendOTP}
                      disabled={this.state.otpLoading}
                      className={classes.button}
                    >
                      {!this.state.otpLoading ? (
                        <span>Continue</span>
                      ) : (
                        <span>Please wait</span>
                      )}
                    </Button>
                  </Box>
                )}
                {this.state.otpSent && (
                  <React.Fragment>
                    <Typography variant="subtitle1" component="p" className={classes.securityMessage}>
                      To secure and verify this transaction, a one-time password was sent to your mobile number. Enter it below then click on PROCEED to continue.
                    </Typography>
                    <OtpInput
                      ref={(otp) => this.otpInput = otp}
                      value={this.state.otp}
                      onChange={this.handleOTPChange}
                      numInputs={6}
                      separator={<span>&ndash;</span>}
                      isInputNum={true}
                      containerStyle={classes.otpContainer}
                      inputStyle={classes.otp}
                      focusStyle={classes.otpFocused}
                      isDisabled={this.state.otpLoading}
                    />
                    <Box align="center" style={{ marginBottom: 16 }}>
                      <Button
                        type="button"
                        color="secondary"
                        onClick={this.handleSendOTP}
                        disabled={this.state.resendCounter > 0 || this.state.refundLoading}
                        className={classes.button}
                        style={{ textTransform: 'inherit' }}
                      >
                        {this.state.resendCounter > 0 ? "Resend (" + this.state.resendCounter + "s)" : "Resend"}
                      </Button>
                    </Box>
                    <Box align="center">
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={this.handleRefund}
                        disabled={this.state.refundLoading || this.state.otp.length < 6}
                        className={classes.button}
                      >
                        {!this.state.refundLoading ? (
                          <span>Proceed</span>
                        ) : (
                          <span>Please wait</span>
                        )}
                      </Button>
                      {this.state.refundLoading && (
                        <Typography variant="caption" component="p" className={classes.refundMessage}>
                          Your refund is being processed. DO NOT close or reload this page.
                        </Typography>
                      )}
                    </Box>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {this.state.refundSuccess && (
              <React.Fragment>
                <Box className={classes.cancellSuccess}>
                  <Typography variant="h4" className={classes.title} gutterBottom>
                    Booking Cancelled!
                  </Typography>
                  <Typography variant="body1" component="p" className={classes.body1} style={{ marginBottom: 16 }}>
                    Your booking, {this.state.schedule.id}, has been cancelled. The amount S${this.state.refundAmount.toLocaleString()} will be refunded to your credit card.
                  </Typography>
                  <Typography variant="body1" component="p" className={classes.body1} style={{ marginBottom: 16 }}>
                    Click <a href='/user-profile'>here</a> to return to your account page.
                  </Typography>
                </Box>
              </React.Fragment>
            )}
            {this.state.refundFailed && (
              <React.Fragment>
                <Box className={classes.cancellSuccess}>
                  <Typography variant="h4" className={classes.title} gutterBottom>
                    Cancellation Failed
                  </Typography>
                  <Typography variant="body1" component="p" className={classes.body1} style={{ marginBottom: 16 }}>
                    Failed to cancel your booking {this.state.schedule.id}. Please reload the page or click <a href='/cancellation'>here</a> to try again.
                  </Typography>
                </Box>
              </React.Fragment>
            )}
          </Paper>
        </Container>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(Cancellation);