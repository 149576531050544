import React from 'react';
import {
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from 'axios';
import moment from 'moment';
import { store } from 'react-notifications-component';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import { orange, grey } from '@material-ui/core/colors';

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#1a90ff',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

const FacebookCircularProgress = (props) => {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: 'none',
  },
  dialogContent: {
    minHeight: 150,
    paddingTop: theme.spacing(4),
    '& p': {
      fontSize: '1.25rem',
    },
  },
  dialogActions: {
    justifyContent: 'space-between',
    '& button': {
      fontSize: '1rem',
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteRentalPromotion(props) {
  const theme = useTheme();
  const classes = useStyles();
  const api = {
    url: props.api.url,
    key: props.api.key,
    accessToken: localStorage.getItem('ctoken'),
    jhamToken: localStorage.getItem('token'),
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [saving, setSaving] = React.useState(false);

  const handleDeleteButton = (event) => {
    event.preventDefault();
    setSaving(true);
    let formData = new FormData();
    formData.append('api_key', api.key);
    formData.append('access_token', api.accessToken);
    formData.append('jham_token', api.jhamToken);
    formData.append('id', props.promotion.id);

    axios({
      method: 'post',
      url: api.url + '/lessor/delete_rental_promotion',
      data: formData,
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => {
        if (response.data.error == 2) {
          store.addNotification({
            title: "Oops Something went wrong",
            message: "The action you have requested is not allowed. Please reload the page",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false
            }
          });
        } else {
          resetFields();
          props.onDelete();
        }
        setSaving(false);
      })
      .catch((error) => {
        // Handle error
        if (error.response && error.response.status == 403) {
          store.addNotification({
            title: "Oops Something went wrong",
            message: "The action you have requested is not allowed. Please reload the page",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false
            }
          });
        }
      });

  };

  const resetFields = () => {
    setSaving(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={props.open}
      TransitionComponent={Transition}
      onClose={props.onClose}
      className={classes.root}
      fullScreen={fullScreen}
    >
      <DialogTitle>
        Delete Rental Promotion
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body1">
          You are about to delete promotion <strong>{props.promotion && props.promotion.title}</strong>. Do you want to continue?
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button disabled={saving} onClick={props.onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleDeleteButton} disabled={saving}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}