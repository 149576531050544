import React from "react";
import {
  NavLink,
  HashRouter,
  Redirect
} from "react-router-dom";
import axios from 'axios';
import { store } from 'react-notifications-component';
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import ScriptTag from 'react-script-tag';
import queryString from 'query-string';
import TextTransition, { presets } from "react-text-transition";
import momentPrecise from 'moment-precise-range-plugin';
import NumberFormat from 'react-number-format';
import SliderImage from 'react-zoom-slider';
// import parallaxBg1 from './assets/img/196_365_2048x1365.jpg';
// import DatePicker from "react-datepicker";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import noImage from './../assets/img/no-image.jpg';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Rating from '@material-ui/lab/Rating';
import Badge from '@material-ui/core/Badge';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import PropTypes from 'prop-types';
import RadioMu from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import SearchIcon from '@material-ui/icons/Search';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EmojiTransportationOutlinedIcon from '@material-ui/icons/EmojiTransportationOutlined';
import Chip from '@material-ui/core/Chip';
import { red,grey, orange } from '@material-ui/core/colors';
import EllipsisText from "react-ellipsis-text"
import CheckIcon from '@material-ui/icons/Check';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import InputAdornment from '@material-ui/core/InputAdornment';
import Alert from '@material-ui/lab/Alert';
import Calendar from 'react-calendar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Hidden from '@material-ui/core/Hidden';
import Fab from '@material-ui/core/Fab';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';

import 'react-calendar/dist/Calendar.css';


// var parallaxBgStyle = {
//   backgroundImage: "url(" + parallaxBg1 + ")"
// };
import "../assets/css/vehicles.css";
import EnhancedSwitch from 'react-icheck/lib/EnhancedSwitch'
import {Checkbox, Radio} from 'react-icheck';
EnhancedSwitch.propTypes = {
  ...EnhancedSwitch.propTypes,
  cursor: PropTypes.string
}

const moment = extendMoment(originalMoment);

const useStyles = makeStyles((theme) => ({
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#d40029',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#d40029',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#d40029',
        },
    },
    newCardForm: {
    color: grey[800],
    '& .MuiFormControl-root': {
    '& .MuiFormLabel-root': {
      fontSize: '1.2rem',
      '&.Mui-focused': {
        color: grey[800],
        backgroundColor: 'white',
        paddingRight: 5,
      },
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      '&:hover fieldset': {
        borderColor: '#B4B4B4',
      },
      '&.Mui-focused fieldset': {
        borderColor: orange[600],
      },
      '& input': {
        fontSize: 20,
        font:'auto'
      },
    },
    },
}
}));

export default class VehicleDetails extends React.Component {

    constructor(props) {
        super(props);

        ReactGA.set({ page: window.location.pathname });
        ReactGA.pageview(window.location.pathname);

        this.apiURL = "https://api.earental.com";
        this.apiKey = "72ffc1303c7c0286887a3276f0ab61b8";
        this.accessToken = localStorage.getItem('ctoken');
        this.jhamToken = localStorage.getItem('token');
        this.currentDate = props.mainState.currentDate;

        var currentDate = props.mainState.currentDate;
        var ctoken = localStorage.getItem('ctoken');
        var currentVehicle = localStorage.getItem('currrent_vehicle');
        if(!ctoken){
            localStorage.setItem('back_continue', window.location.href);
        }else{
            localStorage.removeItem("back_continue");
        }


        currentDate = moment(currentDate).add(1, 'days');

        if(this.props.location.state && !currentVehicle){
            localStorage.removeItem("currrent_vehicle");

            const startDate = moment(this.props.location.state.start).subtract(1, 'days');
            const timeEnd = moment(this.props.location.state.end);
            const diff = timeEnd.diff(startDate);
            const breakDownDiff = moment.preciseDiff(startDate, timeEnd, true);

            var weeks = 0;
            var days = breakDownDiff.days;
            if(days > 6){
                weeks =  breakDownDiff.days / 7;
                weeks = parseInt(weeks);
                days = (breakDownDiff.days - (weeks * 7) );
            }



            var additionalsArr = [];
            var additionalsAddedArr = [];

            if(this.props.location.state.detail.xve_equipment){
                var equipments = this.props.location.state.detail.xve_equipment.split(',');
                var price = this.props.location.state.detail.xve_price.split(',');
                var paAddOnsQty = this.props.location.state.detail.xve_max_qty.split(",");
                equipments.map((item,i) => {

                    if(parseInt(price[i]) > 0 && item.trim() != ""){
                        additionalsArr.push({
                            "id":i,
                            "name":item,
                            "price":price[i].trim(),
                            "qtyPrice":price[i].trim(),
                            "maxqty":paAddOnsQty[i].trim(),
                            "qty":1,
                            'disabled':true,
                            'checked':false
                        });
                        additionalsAddedArr.push(0);
                    }
                })
            }


            const images = [];
            if(this.props.location.state.detail.images){
                for (let i = 0; i < this.props.location.state.detail.images.length; i++) {
                    images.push(
                        {
                            original: "https://api.earental.com/uploads/vehicles/"+this.props.location.state.detail.images[i].pi_image,
                            thumbnail: "https://api.earental.com/uploads/vehicles/"+this.props.location.state.detail.images[i].pi_image,
                        }
                    );

                }

            }else{
                images.push(
                    {
                        original: noImage,
                        thumbnail: noImage,
                    }
                );
            }

            this.state = {
                passToken:localStorage.getItem('token'),
                startDate: new Date(),
                endDate: new Date(),
                MetaTitle:"EARental: "+this.props.location.state.detail.product_name,
                MetaDescription:this.props.location.state.detail.product_description,
                MetaKeywords:"van rental, lorry rental, truck rental, commercial vehicles for rent, leasing, daily rental, weekly rental, weekend rental, monthly rental, yearly rental,"+this.props.location.state.detail.product_name,
                details: this.props.location.state.detail,
                vehicles: [],
                images:images,
                additionalsAdded: additionalsAddedArr,
                additionals: additionalsArr,
                rentalPriceTotal: 0,
                equipmentTotal: 0,
                rentalTotal: 0,
                rentalTotalIntial: 0,
                yearRate: this.props.location.state.detail.product_price_year,
                biAnnualRate: this.props.location.state.detail.product_price_biannually,
                monthRate: this.props.location.state.detail.product_price_month,
                weekRate:this.props.location.state.detail.product_price_week,
                weekendRate:this.props.location.state.detail.product_price_weekend,
                dayRate:this.props.location.state.detail.product_price_day,
                oneYearRate:this.props.location.state.detail.product_price_year,
                twoYearRate:this.props.location.state.detail.product_price_year_2,
                minDate: new Date(currentDate),
                startDate:this.props.location.state.start,
                endDate:this.props.location.state.end,
                startDateQuery:moment(this.props.location.state.start).format("YYYY-MM-DD"),
                endDateQuery: moment(this.props.location.state.end).format("YYYY-MM-DD"),
                years: breakDownDiff.years,
                months: breakDownDiff.months,
                rating: this.props.location.state.detail.product_review_rate,
                dailyDeposit:this.props.location.state.detail.product_price_day_min_deposit,
                weekendDeposit:this.props.location.state.detail.product_price_weekend_min_deposit,
                weeklyDeposit:this.props.location.state.detail.product_price_week_min_deposit,
                monthlyDeposit:this.props.location.state.detail.product_price_month_min_deposit,
                oneYearDeposit:this.props.location.state.detail.product_price_year_min_deposit,
                twoYearDeposit:this.props.location.state.detail.product_price_year_2_min_deposit,
                days: days,
                weeks: weeks,
                biAnnualPriceTotal: 0.00,
                yearPriceTotal: 0.00,
                monthPriceTotal: 0.00,
                weekPriceTotal: 0.00,
                dayPriceTotal: 0.00,
                redirect: false,
                photoIndex: 0,
                isOpen: false,
                valueRangeListing: [new Date(this.props.location.state.start),new Date(this.props.location.state.end)],
                restrictedDate: [],
                isLogin: (ctoken ? true : false),
                lType:this.props.location.state.lType,
                rateCount:this.props.location.state.lCount,
                onProcess:false,
                openDateDialog:false,
                checkingPromoCode: false,
                currentPromoDiscount: 0,
                creditBalance:0,
                creditBalanceTemp:0,
                creditBalanceUse:0,
                loyaltyMiles:0,
                openPromoCode: false,
                profileComplete: null
            };
        }else{

            if(currentVehicle){
                currentVehicle = JSON.parse(currentVehicle);
                console.log(currentVehicle);

                var startQuery = moment(currentVehicle.date_from).format("YYYY-MM-DD");
                var endQuery  = moment(currentVehicle.date_to).format("YYYY-MM-DD");


                const startDate = moment(startQuery).subtract(1, 'days');
                const timeEnd = moment(endQuery);
                const diff = timeEnd.diff(startDate);
                const breakDownDiff = moment.preciseDiff(startDate, timeEnd, true);
                var weeks = 0;
                var days = breakDownDiff.days;
                if(days > 6){
                    weeks =  breakDownDiff.days / 7;
                    weeks = parseInt(weeks);
                    days = (breakDownDiff.days - (weeks * 7) );
                }

                var additionalsArr = [];
                var additionalsAddedArr = [];
                var addonsTotal = 0;

                if (currentVehicle.data[0].addons && currentVehicle.data[0].addons.length > 0) {
                  additionalsArr = currentVehicle.data[0].addons;
                  additionalsArr.map((addon) => {
                    if (addon.checked && addon.qty > 0) {
                      addonsTotal += parseFloat(addon.price) * addon.qty;
                    }
                  });
                } else if(currentVehicle.data[0].xve_equipment){
                    var equipments = currentVehicle.data[0].xve_equipment.split(',');
                    var price = currentVehicle.data[0].xve_price.split(',');
                    var paAddOnsQty = currentVehicle.data[0].xve_max_qty.split(",");
                    equipments.map((item,i) => {

                        if(parseInt(price[i]) > 0){
                            additionalsArr.push({
                                "id":i,
                                "name":item,
                                "price":price[i].trim(),
                                "qtyPrice":price[i].trim(),
                                "maxqty":paAddOnsQty[i].trim(),
                                "qty":1,
                                'disabled':true,
                                'checked':false
                            });
                            additionalsAddedArr.push(0);
                        }
                    })
                }

                const images = [];
                if(currentVehicle.data[0].images){
                    for (let i = 0; i < currentVehicle.data[0].images.length; i++) {
                        images.push(
                            {
                                original: "https://api.earental.com/uploads/vehicles/"+currentVehicle.data[0].images[i].pi_image,
                                thumbnail: "https://api.earental.com/uploads/vehicles/"+currentVehicle.data[0].images[i].pi_image,
                            }
                        );

                    }

                }else{
                    images.push(
                        {
                            original: noImage,
                            thumbnail: noImage,
                        }
                    );
                }



                this.state = {
                    passToken:localStorage.getItem('token'),
                    MetaTitle:"EARental: "+currentVehicle.data[0].product_name,
                    MetaDescription:currentVehicle.data[0].product_description,
                    MetaKeywords:"van rental, lorry rental, truck rental, commercial vehicles for rent, leasing, daily rental, weekly rental, weekend rental, monthly rental, yearly rental,"+currentVehicle.data[0].product_name,
                    details: currentVehicle.data[0],
                    vehicles: [],
                    images:images,
                    additionalsAdded: additionalsAddedArr,
                    additionals: additionalsArr,
                    rentalPriceTotal: 0,
                    equipmentTotal: addonsTotal,
                    rentalTotal: 0,
                    rentalTotalIntial: 0,
                    yearRate: currentVehicle.data[0].product_price_year,
                    biAnnualRate: currentVehicle.data[0].product_price_biannually,
                    monthRate: currentVehicle.data[0].product_price_month,
                    weekRate:currentVehicle.data[0].product_price_week,
                    weekendRate:currentVehicle.data[0].product_price_weekend,
                    dayRate:currentVehicle.data[0].product_price_day,
                    oneYearRate:currentVehicle.data[0].product_price_month,
                    twoYearRate:currentVehicle.data[0].product_price_month,
                    rating: currentVehicle.data[0].product_review_rate,
                    dailyDeposit:currentVehicle.data[0].product_price_day_min_deposit,
                    weekendDeposit:currentVehicle.data[0].product_price_weekend_min_deposit,
                    weeklyDeposit:currentVehicle.data[0].product_price_week_min_deposit,
                    monthlyDeposit:currentVehicle.data[0].product_price_month_min_deposit,
                    OneYearDeposit:currentVehicle.data[0].product_price_year,
                    TwoYearDeposit:currentVehicle.data[0].product_price_month_min_deposit,
                    minDate: new Date(currentDate),
                    startDate:new Date(startQuery),
                    endDate:new Date(endQuery),
                    startDateQuery:startQuery,
                    endDateQuery: endQuery,
                    years: breakDownDiff.years,
                    months: breakDownDiff.months,
                    days: days,
                    weeks: weeks,
                    yearPriceTotal: 0.00,
                    biAnnualPriceTotal: 0.00,
                    monthPriceTotal: 0.00,
                    weekPriceTotal: 0.00,
                    dayPriceTotal: 0.00,
                    redirect: false,
                    valueRangeListing: [new Date(startQuery),new Date(endQuery)],
                    restrictedDate: [],
                    isLogin: (ctoken ? true : false),
                    lType:currentVehicle.lType,
                    rateCount:currentVehicle.lCount,
                    onProcess:false,
                    openDateDialog:false,
                    equipmentTotal: 0,
                    checkingPromoCode: false,
                    currentPromoDiscount: 0,
                    creditBalance:0,
                    creditBalanceTemp:0,
                    creditBalanceUse:0,
                    loyaltyMiles:0,
                    openPromoCode: false,
                    profileComplete: null
                };
            }else{
              var startQuery = moment(new Date()).format("YYYY-MM-DD");
              var endQuery  = moment(new Date()).format("YYYY-MM-DD");
              var prevFilters = localStorage.getItem('listing_filters');
              if(prevFilters){
                  prevFilters = JSON.parse(prevFilters);
                  startQuery = prevFilters.startDateQuery;
                  endQuery = prevFilters.endDateQuery;
              }
              this.state = {
                  passToken:localStorage.getItem('token'),
                   details: false,
                   vehicles: [],
                   additionalsAdded: [],
                   additionals:  [
                            { },
                        ],
                    rentalPriceTotal: 0,
                    equipmentTotal: 0,
                    rentalTotal: 0,
                    rentalTotalIntial: 0,
                    biAnnualRate: 0,
                    yearRate: 0,
                    monthRate: 0,
                    weekRate:0,
                    dayRate:0,
                    startDate:new Date(),
                    endDate:new Date(),
                    startDateQuery:startQuery,
                    endDateQuery: endQuery,
                    days:1,
                    redirect: true,
                    valueRangeListing: [new Date(startQuery),new Date(endQuery)],
                    restrictedDate: [],
                    isLogin: (ctoken ? true : false),
                    onProcess:false,
                    openDateDialog:false,
                    checkingPromoCode: false,
                    equipmentTotal: 0,
                    currentPromoDiscount: 0,
                    creditBalance:0,
                    creditBalanceTemp:0,
                    creditBalanceUse:0,
                    loyaltyMiles:0,
                    openPromoCode: false,
                    profileComplete: null
              };
            }


        }


        this.handleInputChangeAdditional = this.handleInputChangeAdditional.bind(this);
        this.handleChangeDateSubmit = this.handleChangeDateSubmit.bind(this);
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
        this.handleInputChangeQty = this.handleInputChangeQty.bind(this);
        this.toggleDateDialog = this.toggleDateDialog.bind(this);
        this.handlePromoCode = this.handlePromoCode.bind(this);
        this.handleChangeCredit = this.handleChangeCredit.bind(this);
        this.handleConvertMiles = this.handleConvertMiles.bind(this);
        this.handleOpenPromoCode = this.handleOpenPromoCode.bind(this);

  }


  componentDidMount() {
        window.scrollTo(0, 0);
        axios.get('https://api.earental.com/listing/getPass?c='+moment().unix(), { withCredentials: true })
        .then((response) => {
            localStorage.setItem('token', response.data.token);
            this.setState({ passToken:response.data.token});

            var ctoken = localStorage.getItem('ctoken');
            var token = response.data.token;
            var currentOffset = 0;
            var bodyFormData = new FormData();

            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('jham_token', token);
            bodyFormData.append('limit', 1);
            bodyFormData.append('offset', currentOffset);
            bodyFormData.append('date_from', this.state.startDate);
            bodyFormData.append('date_to', this.state.endDate);
            bodyFormData.append('product_id', this.state.details.product_id);
            bodyFormData.append('search_type', this.state.lType);


            axios({
                method: 'post',
                url: 'https://api.earental.com/listing/search',
                data: bodyFormData,
                withCredentials: true,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then((response) => {
                if(response.data.error > 0){
                    store.addNotification({
                      title: "Vehicle not avaible for this date",
                      message: response.data.error_msg,
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: false
                      }
                    });
                }else{
                    let response2 = response.data;
                    response2.lType = this.state.lType;
                    response2.lCount = this.state.rateCount;
                    localStorage.setItem('currrent_vehicle', JSON.stringify(response2));

                    var currentDate = this.currentDate;
                    currentDate = moment(currentDate).add(1, 'days');

                    var currentVehicle = response.data;
                    var startQuery = moment(currentVehicle.date_from).format("YYYY-MM-DD");
                    var endQuery  = moment(currentVehicle.date_to).format("YYYY-MM-DD");


                    const startDate = moment(startQuery).subtract(1, 'days');
                    const timeEnd = moment(endQuery);
                    const diff = timeEnd.diff(startDate);
                    const breakDownDiff = moment.preciseDiff(startDate, timeEnd, true);
                    var weeks = 0;
                    var days = breakDownDiff.days;
                    if(days > 6){
                        weeks =  breakDownDiff.days / 7;
                        weeks = parseInt(weeks);
                        days = (breakDownDiff.days - (weeks * 7) );
                    }

                    var additionalsArr = [];
                    var additionalsAddedArr = [];
                    var addonsTotal = 0;

                    if (currentVehicle.data[0].addons && currentVehicle.data[0].addons.length > 0) {
                      additionalsArr = currentVehicle.data[0].addons;
                      additionalsArr.map((addon) => {
                        if (addon.checked && addon.qty > 0) {
                          addonsTotal += parseFloat(addon.price) * addon.qty;
                        }
                      });
                    } else if(currentVehicle.data[0].xve_equipment){
                        var equipments = currentVehicle.data[0].xve_equipment.split(',');
                        var price = currentVehicle.data[0].xve_price.split(',');
                        var paAddOnsQty = currentVehicle.data[0].xve_max_qty.split(",");
                        equipments.map((item,i) => {

                            if(parseInt(price[i]) > 0){
                                additionalsArr.push({
                                    "id":i,
                                    "name":item,
                                    "price":price[i].trim(),
                                    "qtyPrice":price[i].trim(),
                                    "maxqty":paAddOnsQty[i].trim(),
                                    "qty":1,
                                    'disabled':true,
                                    'checked':false
                                });
                                additionalsAddedArr.push(0);
                            }
                        })
                    }

                    const images = [];
                    if(currentVehicle.data[0].images){
                        for (let i = 0; i < currentVehicle.data[0].images.length; i++) {
                            images.push(
                                {
                                    original: "https://api.earental.com/uploads/vehicles/"+currentVehicle.data[0].images[i].pi_image,
                                    thumbnail: "https://api.earental.com/uploads/vehicles/"+currentVehicle.data[0].images[i].pi_image,
                                }
                            );

                        }

                    }else{
                        images.push(
                            {
                                original: noImage,
                                thumbnail: noImage,
                            }
                        );
                    }


                    this.setState({
                        details: currentVehicle.data[0],
                        vehicles: [],
                        images:images,
                        additionalsAdded: additionalsAddedArr,
                        additionals: additionalsArr,
                        rentalPriceTotal: 0,
                        equipmentTotal: addonsTotal,
                        rentalTotal: 0,
                        rentalTotalIntial: 0,
                        yearRate: currentVehicle.data[0].product_price_year,
                        biAnnualRate: currentVehicle.data[0].product_price_biannually,
                        monthRate: currentVehicle.data[0].product_price_month,
                        weekRate:currentVehicle.data[0].product_price_week,
                        weekendRate:currentVehicle.data[0].product_price_weekend,
                        dayRate:currentVehicle.data[0].product_price_day,
                        oneYearRate:currentVehicle.data[0].product_price_year,
                        twoYearRate:currentVehicle.data[0].product_price_year_2,
                        rating: currentVehicle.data[0].product_review_rate,
                        dailyDeposit:currentVehicle.data[0].product_price_day_min_deposit,
                        weekendDeposit:currentVehicle.data[0].product_price_weekend_min_deposit,
                        weeklyDeposit:currentVehicle.data[0].product_price_week_min_deposit,
                        monthlyDeposit:currentVehicle.data[0].product_price_month_min_deposit,
                        oneYearDeposit:currentVehicle.data[0].product_price_year_min_deposit,
                        twoYearDeposit:currentVehicle.data[0].product_price_year_2_min_deposit,
                        minDate: new Date(currentDate),
                        startDate:new Date(startQuery),
                        endDate:new Date(endQuery),
                        startDateQuery:startQuery,
                        endDateQuery: endQuery,
                        years: breakDownDiff.years,
                        months: breakDownDiff.months,
                        days: days,
                        weeks: weeks,
                        yearPriceTotal: 0.00,
                        biAnnualPriceTotal: 0.00,
                        monthPriceTotal: 0.00,
                        weekPriceTotal: 0.00,
                        dayPriceTotal: 0.00,
                        redirect: false,
                        valueRangeListing: [new Date(startQuery),new Date(endQuery)],
                        isLogin: (ctoken ? true : false),
                        lType:this.state.lType,
                        rateCount:this.state.rateCount,
                        onProcess:false,
                        openDateDialog:false,
                        loyaltyMiles:response.data.loyalty_miles,
                        creditBalance:response.data.credit_balance,
                        creditBalanceTemp:response.data.credit_balance,
                        creditBalanceUse:0,
                        currentPromoDiscount: 0,
                        profileComplete:response.data.profile_complete,
                        bonus_months: response.data.bonus_month,
                        bonus_days:response.data.bonus_day
                    });

                    ReactGA.plugin.execute('ec', 'ec:addImpression', {
                        'id': currentVehicle.data[0].product_id,
                        'name': currentVehicle.data[0].product_name,
                        'category': "Vehicle",
                        'brand': currentVehicle.data[0].product_brand,
                        'variant': currentVehicle.data[0].product_colour,
                    });


                    setTimeout(() => {

                        if(this.state.lType == "daily"){
                            this.setState({
                                currentRateTitle: "Total Rate",
                                currentRateType:"Daily Rate",
                                currentRateSubTitle: "("+this.state.rateCount+" x daily rate "+this.state.dayRate+")",
                                currentRateValue: this.state.dayRate,
                                currentTotalRateOrig: (parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)),
                                currentTotalRate: (parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) - parseFloat(this.state.currentPromoDiscount),
                                currentDeposit: this.state.dailyDeposit,
                                currentRateCount: this.state.rateCount+" day(s)",
                                currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) + parseFloat(this.state.dailyDeposit),
                                rentalTotal:(parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) + parseFloat(this.state.dailyDeposit) + this.state.equipmentTotal - parseFloat(this.state.currentPromoDiscount),
                                rentalTotalIntial: (parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) + parseFloat(this.state.dailyDeposit),
                            });
                        }else if(this.state.lType == "weekend"){
                            this.setState({
                                currentRateTitle: "Total Rate",
                                currentRateType:"Weekend Rate",
                                currentRateSubTitle: "per Weekend",
                                currentRateValue: this.state.weekendRate,
                                currentTotalRateOrig: (parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)),
                                currentTotalRate: (parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) - parseFloat(this.state.currentPromoDiscount),
                                currentDeposit: this.state.weekendDeposit,
                                currentRateCount: this.state.rateCount+" weekend",
                                currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) + parseFloat(this.state.weekendDeposit),
                                rentalTotal:(parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) + parseFloat(this.state.weekendDeposit) + this.state.equipmentTotal - parseFloat(this.state.currentPromoDiscount),
                                rentalTotalIntial:(parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) + parseFloat(this.state.weekendDeposit) - parseFloat(this.state.currentPromoDiscount)
                            });
                        }else if(this.state.lType == "weekly"){
                            this.setState({
                                currentRateTitle: "Total Rate",
                                currentRateType:"Weekly Rate",
                                currentRateSubTitle: "per Week",
                                currentRateValue: this.state.weekRate,
                                currentTotalRateOrig: (parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)),
                                currentTotalRate: (parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) - parseFloat(this.state.currentPromoDiscount),
                                currentDeposit: this.state.weeklyDeposit,
                                currentRateCount: this.state.rateCount+" week(s)",
                                currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.weeklyDeposit),
                                rentalTotal:(parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.weeklyDeposit) + this.state.equipmentTotal - parseFloat(this.state.currentPromoDiscount),
                                rentalTotalIntial:(parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.weeklyDeposit) - parseFloat(this.state.currentPromoDiscount)
                            });
                        }else if(this.state.lType == "monthly"){
                            var monthlyDepositTemp = 0;
                            if(this.state.rateCount > 1){
                                monthlyDepositTemp = this.state.monthlyDeposit;
                            }
                            monthlyDepositTemp = this.state.monthlyDeposit;
                            this.setState({
                                currentRateTitle: "Monthly Rate",
                                currentRateType:"Monthly Rate",
                                currentRateSubTitle: "per Month",
                                currentRateValue: this.state.monthRate,
                                currentTotalRateOrig: parseInt(this.state.monthRate),
                                currentTotalRate: parseFloat(this.state.monthRate) - parseFloat(this.state.currentPromoDiscount),
                                currentDeposit: monthlyDepositTemp,
                                currentRateCount: this.state.rateCount,
                                currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.monthRate)) + parseFloat(monthlyDepositTemp),
                                rentalTotal:(parseFloat(this.state.monthRate)) + parseFloat(monthlyDepositTemp) + this.state.equipmentTotal - parseFloat(this.state.currentPromoDiscount),
                                rentalTotalIntial:(parseFloat(this.state.monthRate)) + parseFloat(monthlyDepositTemp) - parseFloat(this.state.currentPromoDiscount)
                            });
                        }else if(this.state.lType == "1-year"){
                            this.setState({
                                currentRateTitle: "1-Yr Lease Rate",
                                currentRateType:"1-Yr Lease Rate",
                                currentRateSubTitle: "per Month",
                                currentRateValue: this.state.oneYearRate,
                                currentTotalRateOrig: parseInt(this.state.oneYearRate),
                                currentTotalRate: parseFloat(this.state.oneYearRate) - parseFloat(this.state.currentPromoDiscount),
                                currentDeposit: this.state.oneYearDeposit,
                                currentRateCount: "12",
                                currentTotalDeposit: (12 * parseFloat(this.state.oneYearRate)) + parseFloat(this.state.oneYearDeposit),
                                rentalTotal:(parseFloat(this.state.oneYearRate)) + parseFloat(this.state.oneYearDeposit) + this.state.equipmentTotal - parseFloat(this.state.currentPromoDiscount),
                                rentalTotalIntial:(parseFloat(this.state.oneYearRate)) + parseFloat(this.state.oneYearDeposit) - parseFloat(this.state.currentPromoDiscount)
                            });
                        }else if(this.state.lType == "2-years"){
                            this.setState({
                                currentRateTitle: "2-Yr Lease Rate",
                                currentRateType:"2-Yr Lease Rate",
                                currentRateSubTitle: "per Month",
                                currentRateValue: this.state.twoYearRate,
                                currentTotalRateOrig: parseInt(this.state.twoYearRate),
                                currentTotalRate: parseFloat(this.state.twoYearRate) - parseFloat(this.state.currentPromoDiscount),
                                currentDeposit: this.state.twoYearDeposit,
                                currentRateCount: "24",
                                currentTotalDeposit: (24 * parseFloat(this.state.twoYearRate)) + parseFloat(this.state.twoYearDeposit),
                                rentalTotal:(parseFloat(this.state.twoYearRate)) + parseFloat(this.state.twoYearDeposit) + this.state.equipmentTotal - parseFloat(this.state.currentPromoDiscount),
                                rentalTotalIntial:(parseFloat(this.state.twoYearRate)) + parseFloat(this.state.twoYearDeposit) - parseFloat(this.state.currentPromoDiscount)
                            });
                        }
                    }, 500);

                }

            })
            .catch((error) => {


            });


            var bodyFormData = new FormData();

            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
            bodyFormData.append('jham_token', token);
            bodyFormData.append('product_id', this.state.details.product_id);

            axios({
                    method: 'post',
                    url: 'https://api.earental.com/listing/restricted_date',
                    data: bodyFormData,
                    withCredentials: true,
                    headers: {'Content-Type': 'multipart/form-data' }
                })
                .then((response) => {
                    if(response.data.data)
                        this.setState({ restrictedDate: response.data.data});
                })
                .catch((error) => {


                });
        })
        .catch((error) => {
        // handle error
            console.log(error);
        });





  }

  handleOpenPromoCode(e) {
      this.setState({
          openPromoCode:!this.state.openPromoCode
      });
  };

  onSelectRangeProduct = (product, start, end,type,count) => {

    var tempVals = {
        tempValueRangeListing:[new Date(start),new Date(end)],
        tempStartDate:start,
        tempStartDateQuery:start,
        tempEndDate:end,
        tempEndDateQuery:end,
        tempLType:type,
        tempRateCount:count,
        onProcess:true
    };


    this.handleChangeDateSubmit(tempVals);

  };

  handleChangeCredit(e) {
      var creditToUse = e.target.value;
          creditToUse = parseFloat(creditToUse);
      if(isNaN(parseFloat(creditToUse))){
          creditToUse = 0;
      }
      if(creditToUse > this.state.creditBalance)
          creditToUse = this.state.creditBalance;
      this.setState({
          creditBalanceUse:creditToUse,
          creditBalanceTemp:(this.state.creditBalance - creditToUse)
      });

      if(this.state.lType == "daily"){
          this.setState({
              currentRateTitle: "Total Rate",
              currentRateType:"Daily Rate",
              currentRateSubTitle: "("+this.state.rateCount+" x daily rate "+this.state.dayRate+")",
              currentRateValue: this.state.dayRate,
              currentTotalRateOrig: (parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)),
              currentTotalRate: (parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) - parseFloat(this.state.currentPromoDiscount) - creditToUse,
              currentDeposit: this.state.dailyDeposit,
              currentRateCount: this.state.rateCount+" day(s)",
              currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) + parseFloat(this.state.dailyDeposit),
              rentalTotal:(parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) + parseFloat(this.state.dailyDeposit)  + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.currentPromoDiscount) - creditToUse,
              rentalTotalIntial:(parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) + parseFloat(this.state.dailyDeposit)  + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.currentPromoDiscount) - creditToUse
          });
      }else if(this.state.lType == "weekend"){
          this.setState({
              currentRateTitle: "Total Rate",
              currentRateType:"Weekend Rate",
              currentRateSubTitle: "per Weekend",
              currentRateValue: this.state.weekendRate,
              currentTotalRateOrig: (parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)),
              currentTotalRate: (parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) - parseFloat(this.state.currentPromoDiscount) - creditToUse,
              currentDeposit: this.state.weekendDeposit,
              currentRateCount: this.state.rateCount+" weekend",
              currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) + parseFloat(this.state.weekendDeposit),
              rentalTotal:(parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) + parseFloat(this.state.weekendDeposit)  + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.currentPromoDiscount) - creditToUse,
              rentalTotalIntial:(parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) + parseFloat(this.state.weekendDeposit)  + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.currentPromoDiscount) - creditToUse
          });
      }else if(this.state.lType == "weekly"){
          this.setState({
              currentRateTitle: "Total Rate",
              currentRateType:"Weekly Rate",
              currentRateSubTitle: "per Week",
              currentRateValue: this.state.weekRate,
              currentTotalRateOrig: (parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)),
              currentTotalRate: (parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) - parseFloat(this.state.currentPromoDiscount) - creditToUse,
              currentDeposit: this.state.weeklyDeposit,
              currentRateCount: this.state.rateCount+" week(s)",
              currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.weeklyDeposit),
              rentalTotal:(parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.weeklyDeposit)  + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.currentPromoDiscount) - creditToUse,
              rentalTotalIntial:(parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.weeklyDeposit)  + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.currentPromoDiscount) - creditToUse
          });
      }else if(this.state.lType == "monthly"){
          var monthlyDepositTemp = 0;
          if(this.state.rateCount > 1){
              monthlyDepositTemp = this.state.monthlyDeposit;
          }
          monthlyDepositTemp = this.state.monthlyDeposit;
          this.setState({
              currentRateTitle: "Monthly Rate",
              currentRateType:"Monthly Rate",
              currentRateSubTitle: "per Month",
              currentRateValue: this.state.monthRate,
              currentTotalRateOrig: parseFloat(this.state.monthRate),
              currentTotalRate: parseFloat(this.state.monthRate) - parseFloat(this.state.currentPromoDiscount) - creditToUse,
              currentDeposit: monthlyDepositTemp,
              currentRateCount: this.state.rateCount,
              currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.monthRate)) + parseFloat(monthlyDepositTemp),
              rentalTotal:(parseFloat(this.state.monthRate)) + parseFloat(monthlyDepositTemp)  + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.currentPromoDiscount) - creditToUse,
              rentalTotalIntial:(parseFloat(this.state.monthRate)) + parseFloat(monthlyDepositTemp)  + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.currentPromoDiscount) - creditToUse
          });
      }else if(this.state.lType == "1-year"){
          this.setState({
              currentRateTitle: "1-Yr Lease Rate",
              currentRateType:"1-Yr Lease Rate",
              currentRateSubTitle: "per Month",
              currentRateValue: this.state.oneYearRate,
              currentTotalRateOrig: parseFloat(this.state.oneYearRate),
              currentTotalRate: parseFloat(this.state.oneYearRate) - parseFloat(this.state.currentPromoDiscount) - creditToUse,
              currentDeposit: this.state.oneYearDeposit,
              currentRateCount: "12",
              currentTotalDeposit: (12 * parseFloat(this.state.oneYearRate)) + parseFloat(this.state.oneYearDeposit),
              rentalTotal:(parseFloat(this.state.oneYearRate)) + parseFloat(this.state.oneYearDeposit)  + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.currentPromoDiscount) - creditToUse,
              rentalTotalIntial:(parseFloat(this.state.oneYearRate)) + parseFloat(this.state.oneYearDeposit)  + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.currentPromoDiscount) - creditToUse
          });
      }else if(this.state.lType == "2-years"){
          this.setState({
              currentRateTitle: "2-Yr Lease Rate",
              currentRateType:"2-Yr Lease Rate",
              currentRateSubTitle: "per Month",
              currentRateValue: this.state.twoYearRate,
              currentTotalRateOrig: parseFloat(this.state.twoYearRate),
              currentTotalRate: parseFloat(this.state.twoYearRate) - parseFloat(this.state.currentPromoDiscount) - creditToUse,
              currentDeposit: this.state.twoYearDeposit,
              currentRateCount: "24",
              currentTotalDeposit: (24 * parseFloat(this.state.twoYearRate)) + parseFloat(this.state.twoYearDeposit),
              rentalTotal:(parseFloat(this.state.twoYearRate)) + parseFloat(this.state.twoYearDeposit)  + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.currentPromoDiscount) - creditToUse,
              rentalTotalIntial:(parseFloat(this.state.twoYearRate)) + parseFloat(this.state.twoYearDeposit)  + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.currentPromoDiscount) - creditToUse
          });
      }
  }

  handlePromoCode(e) {
      e.preventDefault();
      this.setState({
          checkingPromoCode:true
      });
      var promoCode = e.target.elements.promoCode.value;
      var ctoken = localStorage.getItem('ctoken');
      var token = this.state.passToken;
      var currentOffset = 0;
      var bodyFormData = new FormData();

      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('access_token', this.accessToken);
      bodyFormData.append('type', this.state.lType);
      bodyFormData.append('promo_code', promoCode);
      bodyFormData.append('rate', this.state.currentTotalRateOrig);



      axios({
              method: 'post',
              url: 'https://api.earental.com/transaction/validate_promo_code',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {

              if(response.data.error > 0){


                  store.addNotification({
                    title: "Invalid",
                    message: "Promo code invalid or already used",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                     onScreen: false
                    }
                  });

                  this.setState({
                      checkingPromoCode:false,
                      currentPromoDiscount:0,
                      currentPromoCode:"",
                  });

                  setTimeout(() => {
                      if(this.state.lType == "daily"){
                          this.setState({
                              currentRateTitle: "Total Rate",
                              currentRateType:"Daily Rate",
                              currentRateSubTitle: "("+this.state.rateCount+" x daily rate "+this.state.dayRate+")",
                              currentRateValue: this.state.dayRate,
                              currentTotalRateOrig: (parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)),
                              currentTotalRate: (parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                              currentDeposit: this.state.dailyDeposit,
                              currentRateCount: this.state.rateCount+" day(s)",
                              currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) + parseFloat(this.state.dailyDeposit),
                              rentalTotal:(parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) + parseFloat(this.state.dailyDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse)- parseFloat(this.state.currentPromoDiscount),
                              rentalTotalIntial:(parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) + parseFloat(this.state.dailyDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount)
                          });
                      }else if(this.state.lType == "weekend"){
                          this.setState({
                              currentRateTitle: "Total Rate",
                              currentRateType:"Weekend Rate",
                              currentRateSubTitle: "per Weekend",
                              currentRateValue: this.state.weekendRate,
                              currentTotalRateOrig: (parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)),
                              currentTotalRate: (parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                              currentDeposit: this.state.weekendDeposit,
                              currentRateCount: this.state.rateCount+" weekend",
                              currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) + parseFloat(this.state.weekendDeposit),
                              rentalTotal:(parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) + parseFloat(this.state.weekendDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                              rentalTotalIntial:(parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) + parseFloat(this.state.weekendDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount)
                          });
                      }else if(this.state.lType == "weekly"){
                          this.setState({
                              currentRateTitle: "Total Rate",
                              currentRateType:"Weekly Rate",
                              currentRateSubTitle: "per Week",
                              currentRateValue: this.state.weekRate,
                              currentTotalRateOrig: (parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)),
                              currentTotalRate: (parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                              currentDeposit: this.state.weeklyDeposit,
                              currentRateCount: this.state.rateCount+" week(s)",
                              currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.weeklyDeposit),
                              rentalTotal:(parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.weeklyDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                              rentalTotalIntial:(parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.weeklyDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount)
                          });
                      }else if(this.state.lType == "monthly"){
                          var monthlyDepositTemp = 0;
                          if(this.state.rateCount > 1){
                              monthlyDepositTemp = this.state.monthlyDeposit;
                          }
                          monthlyDepositTemp = this.state.monthlyDeposit;
                          this.setState({
                              currentRateTitle: "Monthly Rate",
                              currentRateType:"Monthly Rate",
                              currentRateSubTitle: "per Month",
                              currentRateValue: this.state.monthRate,
                              currentTotalRateOrig: parseFloat(this.state.monthRate),
                              currentTotalRate: parseFloat(this.state.monthRate) - parseFloat(this.state.currentPromoDiscount) - parseFloat(this.state.creditBalanceUse),
                              currentDeposit: monthlyDepositTemp,
                              currentRateCount: this.state.rateCount,
                              currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.monthRate)) + parseFloat(monthlyDepositTemp),
                              rentalTotal:(parseFloat(this.state.monthRate)) + parseFloat(monthlyDepositTemp) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                              rentalTotalIntial:(parseFloat(this.state.monthRate)) + parseFloat(monthlyDepositTemp)  - parseFloat(this.state.currentPromoDiscount)
                          });
                      }else if(this.state.lType == "1-year"){
                          this.setState({
                              currentRateTitle: "1-Yr Lease Rate",
                              currentRateType:"1-Yr Lease Rate",
                              currentRateSubTitle: "per Month",
                              currentRateValue: this.state.oneYearRate,
                              currentTotalRateOrig: parseFloat(this.state.oneYearRate),
                              currentTotalRate: parseFloat(this.state.oneYearRate) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                              currentDeposit: this.state.oneYearDeposit,
                              currentRateCount: "12",
                              currentTotalDeposit: (12 * parseFloat(this.state.oneYearRate)) + parseFloat(this.state.oneYearDeposit),
                              rentalTotal:(parseFloat(this.state.oneYearRate)) + parseFloat(this.state.oneYearDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                              rentalTotalIntial:(parseFloat(this.state.oneYearRate)) + parseFloat(this.state.oneYearDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount)
                          });
                      }else if(this.state.lType == "2-years"){
                          this.setState({
                              currentRateTitle: "2-Yr Lease Rate",
                              currentRateType:"2-Yr Lease Rate",
                              currentRateSubTitle: "per Month",
                              currentRateValue: this.state.twoYearRate,
                              currentTotalRateOrig: parseFloat(this.state.twoYearRate),
                              currentTotalRate: parseFloat(this.state.twoYearRate) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                              currentDeposit: this.state.twoYearDeposit,
                              currentRateCount: "24",
                              currentTotalDeposit: (24 * parseFloat(this.state.twoYearRate)) + parseFloat(this.state.twoYearDeposit),
                              rentalTotal:(parseFloat(this.state.twoYearRate)) + parseFloat(this.state.twoYearDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                              rentalTotalIntial:(parseFloat(this.state.twoYearRate)) + parseFloat(this.state.twoYearDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount)
                          });
                      }
                  }, 150);



              }else{

                  this.setState({
                      checkingPromoCode:false,
                      currentPromoDiscount:response.data.data.total_discount,
                      currentPromoCode:promoCode,
                  });


                  setTimeout(() => {
                      if(this.state.lType == "daily"){
                          this.setState({
                              currentRateTitle: "Total Rate",
                              currentRateType:"Daily Rate",
                              currentRateSubTitle: "("+this.state.rateCount+" x daily rate "+this.state.dayRate+")",
                              currentRateValue: this.state.dayRate,
                              currentTotalRateOrig: (parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)),
                              currentTotalRate: (parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                              currentDeposit: this.state.dailyDeposit,
                              currentRateCount: this.state.rateCount+" day(s)",
                              currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) + parseFloat(this.state.dailyDeposit),
                              rentalTotal:(parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) + parseFloat(this.state.dailyDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse)- parseFloat(this.state.currentPromoDiscount),
                              rentalTotalIntial:(parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) + parseFloat(this.state.dailyDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount)
                          });
                      }else if(this.state.lType == "weekend"){
                          this.setState({
                              currentRateTitle: "Total Rate",
                              currentRateType:"Weekend Rate",
                              currentRateSubTitle: "per Weekend",
                              currentRateValue: this.state.weekendRate,
                              currentTotalRateOrig: (parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)),
                              currentTotalRate: (parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                              currentDeposit: this.state.weekendDeposit,
                              currentRateCount: this.state.rateCount+" weekend",
                              currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) + parseFloat(this.state.weekendDeposit),
                              rentalTotal:(parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) + parseFloat(this.state.weekendDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                              rentalTotalIntial:(parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) + parseFloat(this.state.weekendDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount)
                          });
                      }else if(this.state.lType == "weekly"){
                          this.setState({
                              currentRateTitle: "Total Rate",
                              currentRateType:"Weekly Rate",
                              currentRateSubTitle: "per Week",
                              currentRateValue: this.state.weekRate,
                              currentTotalRateOrig: (parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)),
                              currentTotalRate: (parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                              currentDeposit: this.state.weeklyDeposit,
                              currentRateCount: this.state.rateCount+" week(s)",
                              currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.weeklyDeposit),
                              rentalTotal:(parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.weeklyDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                              rentalTotalIntial:(parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.weeklyDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount)
                          });
                      }else if(this.state.lType == "monthly"){
                          var monthlyDepositTemp = 0;
                          if(this.state.rateCount > 1){
                              monthlyDepositTemp = this.state.monthlyDeposit;
                          }
                          monthlyDepositTemp = this.state.monthlyDeposit;
                          this.setState({
                              currentRateTitle: "Monthly Rate",
                              currentRateType:"Monthly Rate",
                              currentRateSubTitle: "per Month",
                              currentRateValue: this.state.monthRate,
                              currentTotalRateOrig: parseFloat(this.state.monthRate),
                              currentTotalRate: parseFloat(this.state.monthRate) - parseFloat(this.state.currentPromoDiscount) - parseFloat(this.state.creditBalanceUse),
                              currentDeposit: monthlyDepositTemp,
                              currentRateCount: this.state.rateCount,
                              currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.monthRate)) + parseFloat(monthlyDepositTemp),
                              rentalTotal:(parseFloat(this.state.monthRate)) + parseFloat(monthlyDepositTemp) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                              rentalTotalIntial:(parseFloat(this.state.monthRate)) + parseFloat(monthlyDepositTemp)  - parseFloat(this.state.currentPromoDiscount)
                          });
                      }else if(this.state.lType == "1-year"){
                          this.setState({
                              currentRateTitle: "1-Yr Lease Rate",
                              currentRateType:"1-Yr Lease Rate",
                              currentRateSubTitle: "per Month",
                              currentRateValue: this.state.oneYearRate,
                              currentTotalRateOrig: parseFloat(this.state.oneYearRate),
                              currentTotalRate: parseFloat(this.state.oneYearRate) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                              currentDeposit: this.state.oneYearDeposit,
                              currentRateCount: "12",
                              currentTotalDeposit: (12 * parseFloat(this.state.oneYearRate)) + parseFloat(this.state.oneYearDeposit),
                              rentalTotal:(parseFloat(this.state.oneYearRate)) + parseFloat(this.state.oneYearDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                              rentalTotalIntial:(parseFloat(this.state.oneYearRate)) + parseFloat(this.state.oneYearDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount)
                          });
                      }else if(this.state.lType == "2-years"){
                          this.setState({
                              currentRateTitle: "2-Yr Lease Rate",
                              currentRateType:"2-Yr Lease Rate",
                              currentRateSubTitle: "per Month",
                              currentRateValue: this.state.twoYearRate,
                              currentTotalRateOrig: parseFloat(this.state.twoYearRate),
                              currentTotalRate: parseFloat(this.state.twoYearRate) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                              currentDeposit: this.state.twoYearDeposit,
                              currentRateCount: "24",
                              currentTotalDeposit: (24 * parseFloat(this.state.twoYearRate)) + parseFloat(this.state.twoYearDeposit),
                              rentalTotal:(parseFloat(this.state.twoYearRate)) + parseFloat(this.state.twoYearDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                              rentalTotalIntial:(parseFloat(this.state.twoYearRate)) + parseFloat(this.state.twoYearDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount)
                          });
                      }
                  }, 150);

              }

          })
          .catch((error) => {
              this.setState({
                  checkingPromoCode:false,
                  currentPromoDiscount:0,
                  currentPromoCode:"",
              });

              setTimeout(() => {
                  if(this.state.lType == "daily"){
                      this.setState({
                          currentRateTitle: "Total Rate",
                          currentRateType:"Daily Rate",
                          currentRateSubTitle: "("+this.state.rateCount+" x daily rate "+this.state.dayRate+")",
                          currentRateValue: this.state.dayRate,
                          currentTotalRateOrig: (parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)),
                          currentTotalRate: (parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                          currentDeposit: this.state.dailyDeposit,
                          currentRateCount: this.state.rateCount+" day(s)",
                          currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) + parseFloat(this.state.dailyDeposit),
                          rentalTotal:(parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) + parseFloat(this.state.dailyDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse)- parseFloat(this.state.currentPromoDiscount),
                          rentalTotalIntial:(parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) + parseFloat(this.state.dailyDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount)
                      });
                  }else if(this.state.lType == "weekend"){
                      this.setState({
                          currentRateTitle: "Total Rate",
                          currentRateType:"Weekend Rate",
                          currentRateSubTitle: "per Weekend",
                          currentRateValue: this.state.weekendRate,
                          currentTotalRateOrig: (parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)),
                          currentTotalRate: (parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                          currentDeposit: this.state.weekendDeposit,
                          currentRateCount: this.state.rateCount+" weekend",
                          currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) + parseFloat(this.state.weekendDeposit),
                          rentalTotal:(parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) + parseFloat(this.state.weekendDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                          rentalTotalIntial:(parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) + parseFloat(this.state.weekendDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount)
                      });
                  }else if(this.state.lType == "weekly"){
                      this.setState({
                          currentRateTitle: "Total Rate",
                          currentRateType:"Weekly Rate",
                          currentRateSubTitle: "per Week",
                          currentRateValue: this.state.weekRate,
                          currentTotalRateOrig: (parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)),
                          currentTotalRate: (parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                          currentDeposit: this.state.weeklyDeposit,
                          currentRateCount: this.state.rateCount+" week(s)",
                          currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.weeklyDeposit),
                          rentalTotal:(parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.weeklyDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                          rentalTotalIntial:(parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.weeklyDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount)
                      });
                  }else if(this.state.lType == "monthly"){
                      var monthlyDepositTemp = 0;
                      if(this.state.rateCount > 1){
                          monthlyDepositTemp = this.state.monthlyDeposit;
                      }
                      monthlyDepositTemp = this.state.monthlyDeposit;
                      this.setState({
                          currentRateTitle: "Monthly Rate",
                          currentRateType:"Monthly Rate",
                          currentRateSubTitle: "per Month",
                          currentRateValue: this.state.monthRate,
                          currentTotalRateOrig: parseFloat(this.state.monthRate),
                          currentTotalRate: parseFloat(this.state.monthRate) - parseFloat(this.state.currentPromoDiscount) - parseFloat(this.state.creditBalanceUse),
                          currentDeposit: monthlyDepositTemp,
                          currentRateCount: this.state.rateCount,
                          currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.monthRate)) + parseFloat(monthlyDepositTemp),
                          rentalTotal:(parseFloat(this.state.monthRate)) + parseFloat(monthlyDepositTemp) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                          rentalTotalIntial:(parseFloat(this.state.monthRate)) + parseFloat(monthlyDepositTemp)  - parseFloat(this.state.currentPromoDiscount)
                      });
                  }else if(this.state.lType == "1-year"){
                      this.setState({
                          currentRateTitle: "1-Yr Lease Rate",
                          currentRateType:"1-Yr Lease Rate",
                          currentRateSubTitle: "per Month",
                          currentRateValue: this.state.oneYearRate,
                          currentTotalRateOrig: parseFloat(this.state.oneYearRate),
                          currentTotalRate: parseFloat(this.state.oneYearRate) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                          currentDeposit: this.state.oneYearDeposit,
                          currentRateCount: "12",
                          currentTotalDeposit: (12 * parseFloat(this.state.oneYearRate)) + parseFloat(this.state.oneYearDeposit),
                          rentalTotal:(parseFloat(this.state.oneYearRate)) + parseFloat(this.state.oneYearDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                          rentalTotalIntial:(parseFloat(this.state.oneYearRate)) + parseFloat(this.state.oneYearDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount)
                      });
                  }else if(this.state.lType == "2-years"){
                      this.setState({
                          currentRateTitle: "2-Yr Lease Rate",
                          currentRateType:"2-Yr Lease Rate",
                          currentRateSubTitle: "per Month",
                          currentRateValue: this.state.twoYearRate,
                          currentTotalRateOrig: parseFloat(this.state.twoYearRate),
                          currentTotalRate: parseFloat(this.state.twoYearRate) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                          currentDeposit: this.state.twoYearDeposit,
                          currentRateCount: "24",
                          currentTotalDeposit: (24 * parseFloat(this.state.twoYearRate)) + parseFloat(this.state.twoYearDeposit),
                          rentalTotal:(parseFloat(this.state.twoYearRate)) + parseFloat(this.state.twoYearDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount),
                          rentalTotalIntial:(parseFloat(this.state.twoYearRate)) + parseFloat(this.state.twoYearDeposit) + parseFloat(this.state.equipmentTotal) - parseFloat(this.state.creditBalanceUse) - parseFloat(this.state.currentPromoDiscount)
                      });
                  }
              }, 150);

          });

  }

  handleConvertMiles(e) {

      this.setState({
          convertingMiles:true
      });

      var ctoken = localStorage.getItem('ctoken');
      var token = this.state.passToken;
      var currentOffset = 0;
      var bodyFormData = new FormData();

      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('access_token', this.accessToken);

      axios({
              method: 'post',
              url: 'https://api.earental.com/transaction/convert_loyalty_miles',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {

              if(response.data.error > 0){

                  store.addNotification({
                    title: "Insufficient loyalty miles",
                    message: "You should have at least 100 miles to convert it to credit",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                     onScreen: false
                    }
                  });

                  this.setState({
                      convertingMiles:false
                  });

              }else{
                  store.addNotification({
                    title: "Congratulations!",
                    message: "You have successully converted "+response.data.converted_miles+" loyalty miles into "+response.data.credit+ " credit",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                     onScreen: false
                    }
                  });



                  this.setState({
                      convertingMiles:false,
                      loyaltyMiles:parseInt(response.data.remaining_miles),
                      creditBalance:parseFloat(this.state.creditBalance) + parseInt(response.data.credit),
                      creditBalanceTemp: parseFloat(this.state.creditBalanceTemp) + parseInt(response.data.credit)
                  });

              }

          })
          .catch((error) => {
              this.setState({
                  convertingMiles:false,
              });

          });

  }

  handleChangeStart(date) {
    this.setState({
      startDate:date,
      startDateQuery:moment(date).format("YYYY-MM-DD")
    })

    var now = moment(date).format("YYYY-MM-DD");
    var dateCheck = moment(this.state.endDateQuery).unix();
    now = moment(now).unix();
    if (now > dateCheck) {
        this.setState({
            endDate:date,
            endDateQuery:moment(date).format("YYYY-MM-DD")
        })
    }
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date,
      endDateQuery:moment(date).format("YYYY-MM-DD")
    })
  }

  handleChangeDateSubmit(tempVals) {

    //   this.props.history.push("/vehicles/all-vehicles?start="+this.state.startDateQuery+"&end="+this.state.endDateQuery);

              var ctoken = localStorage.getItem('ctoken');
              var token = this.state.passToken;
              var currentOffset = 0;
              var bodyFormData = new FormData();

              bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
              bodyFormData.append('jham_token', token);
              bodyFormData.append('access_token', ctoken);
              bodyFormData.append('limit', 1);
              bodyFormData.append('offset', currentOffset);
              bodyFormData.append('date_from', tempVals.tempStartDate);
              bodyFormData.append('date_to', tempVals.tempEndDate);
              bodyFormData.append('product_id', this.state.details.product_id);
              bodyFormData.append('search_type', tempVals.tempLType);



              axios({
                      method: 'post',
                      url: 'https://api.earental.com/listing/search',
                      data: bodyFormData,
                      withCredentials: true,
                      headers: {'Content-Type': 'multipart/form-data' }
                  })
                  .then((response) => {

                      if(response.data.error > 0){


                          this.props.history.push({
                              pathname: '/vehicles/van-rental',
                              search: '?start='+this.state.startDateQuery+'&end='+this.state.endDateQuery,
                          })

                      }else{

                          if(response.data.data[0].available == 0){
                              store.addNotification({
                                title: "Not Available",
                                message: "Product already rented for this date.",
                                type: "warning",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                  duration: 5000,
                                 onScreen: false
                                }
                              });

                          }else{
                              let response2 = response.data;
                              response2.lType = tempVals.tempLType;
                              response2.lCount = tempVals.tempRateCount;
                              localStorage.setItem('currrent_vehicle', JSON.stringify(response2));



                              var startQuery = moment(tempVals.tempStartDateQuery).format("YYYY-MM-DD");
                              var endQuery  = moment(tempVals.tempEndDateQuery).format("YYYY-MM-DD");


                              const startDate = moment(startQuery).subtract(1, 'days');
                              const timeEnd = moment(endQuery);
                              const diff = timeEnd.diff(startDate);
                              const breakDownDiff = moment.preciseDiff(startDate, timeEnd, true);
                              var weeks = 0;
                              var days = breakDownDiff.days;
                              if(days > 6){
                                  weeks =  breakDownDiff.days / 7;
                                  weeks = parseInt(weeks);
                                  days = (breakDownDiff.days - (weeks * 7) );
                              }

                              this.setState({
                                  valueRangeListing: tempVals.tempValueRangeListing,
                                  startDate:tempVals.tempStartDate,
                                  startDateQuery:tempVals.tempStartDateQuery,
                                  endDate:tempVals.tempEndDate,
                                  endDateQuery:tempVals.tempEndDateQuery,
                                  lType:tempVals.tempLType,
                                  rateCount:tempVals.tempRateCount,
                                  details: response.data.data[0],
                                  vehicles: [],
                                  yearRate: response.data.data[0].product_price_year,
                                  biAnnualRate: response.data.data[0].product_price_biannually,
                                  monthRate: response.data.data[0].product_price_month,
                                  weekRate:response.data.data[0].product_price_week,
                                  weekendRate:response.data.data[0].product_price_weekend,
                                  dayRate:response.data.data[0].product_price_day,
                                  oneYearRate:response.data.data[0].product_price_year,
                                  twoYearRate:response.data.data[0].product_price_year_2,
                                  dailyDeposit:response.data.data[0].product_price_day_min_deposit,
                                  weekendDeposit:response.data.data[0].product_price_weekend_min_deposit,
                                  weeklyDeposit:response.data.data[0].product_price_week_min_deposit,
                                  monthlyDeposit:response.data.data[0].product_price_month_min_deposit,
                                  oneYearDeposit:response.data.data[0].product_price_year_min_deposit,
                                  twoYearDeposit:response.data.data[0].product_price_year_2_min_deposit,
                                  years: breakDownDiff.years,
                                  months: breakDownDiff.months,
                                  days: days,
                                  weeks: weeks,
                                  yearPriceTotal: 0.00,
                                  monthPriceTotal: 0.00,
                                  weekPriceTotal: 0.00,
                                  dayPriceTotal: 0.00,
                                  redirect: false,
                                  openDateDialog: false,
                                  loyaltyMiles:response.data.loyalty_miles,
                                  creditBalance:response.data.credit_balance,
                                  creditBalanceTemp:response.data.credit_balance,
                                  creditBalanceUse:0,
                                  currentPromoDiscount: 0,
                                  bonus_months: response.data.bonus_month,
                                  bonus_days:response.data.bonus_day
                              })


                            setTimeout(() => {
                                this.setState({
                                    onProcess:false
                                });
                            }, 300);

                              if(this.state.lType == "daily"){
                                  this.setState({
                                      currentRateTitle: "Total Rate",
                                      currentRateType:"Daily Rate",
                                      currentRateSubTitle: "("+this.state.rateCount+" x daily rate "+this.state.dayRate+")",
                                      currentRateValue: this.state.dayRate,
                                      currentTotalRateOrig: (parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)),
                                      currentTotalRate: (parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) - parseFloat(this.state.currentPromoDiscount),
                                      currentDeposit: this.state.dailyDeposit,
                                      currentRateCount: this.state.rateCount+" day(s)",
                                      currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) + parseFloat(this.state.dailyDeposit),
                                      rentalTotal:(parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) + parseFloat(this.state.dailyDeposit) - parseFloat(this.state.currentPromoDiscount),
                                      rentalTotalIntial:(parseInt(this.state.rateCount) * parseFloat(this.state.dayRate)) + parseFloat(this.state.dailyDeposit) - parseFloat(this.state.currentPromoDiscount)
                                  });
                              }else if(this.state.lType == "weekend"){
                                  this.setState({
                                      currentRateTitle: "Total Rate",
                                      currentRateType:"Weekend Rate",
                                      currentRateSubTitle: "per Weekend",
                                      currentRateValue: this.state.weekendRate,
                                      currentTotalRateOrig: (parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)),
                                      currentTotalRate: (parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) - parseFloat(this.state.currentPromoDiscount),
                                      currentDeposit: this.state.weekendDeposit,
                                      currentRateCount: this.state.rateCount+" weekend",
                                      currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) + parseFloat(this.state.weekendDeposit),
                                      rentalTotal:(parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) + parseFloat(this.state.weekendDeposit) - parseFloat(this.state.currentPromoDiscount),
                                      rentalTotalIntial:(parseInt(this.state.rateCount) * parseFloat(this.state.weekendRate)) + parseFloat(this.state.weekendDeposit) - parseFloat(this.state.currentPromoDiscount)
                                  });
                              }else if(this.state.lType == "weekly"){
                                  this.setState({
                                      currentRateTitle: "Total Rate",
                                      currentRateType:"Weekly Rate",
                                      currentRateSubTitle: "per Week",
                                      currentRateValue: this.state.weekRate,
                                      currentTotalRateOrig: (parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)),
                                      currentTotalRate: (parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) - parseFloat(this.state.currentPromoDiscount),
                                      currentDeposit: this.state.weeklyDeposit,
                                      currentRateCount: this.state.rateCount+" week(s)",
                                      currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.weeklyDeposit),
                                      rentalTotal:(parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.weeklyDeposit) - parseFloat(this.state.currentPromoDiscount),
                                      rentalTotalIntial:(parseInt(this.state.rateCount) * parseFloat(this.state.weekRate)) + parseFloat(this.state.weeklyDeposit) - parseFloat(this.state.currentPromoDiscount)
                                  });
                              }else if(this.state.lType == "monthly"){
                                  var monthlyDepositTemp = 0;
                                  if(this.state.rateCount > 1){
                                      monthlyDepositTemp = this.state.monthlyDeposit;
                                  }
                                  monthlyDepositTemp = this.state.monthlyDeposit;
                                  this.setState({
                                      currentRateTitle: "Monthly Rate",
                                      currentRateType:"Monthly Rate",
                                      currentRateSubTitle: "per Month",
                                      currentRateValue: this.state.monthRate,
                                      currentTotalRateOrig: parseFloat(this.state.monthRate),
                                      currentTotalRate: parseFloat(this.state.monthRate) - parseFloat(this.state.currentPromoDiscount),
                                      currentDeposit: monthlyDepositTemp,
                                      currentRateCount: this.state.rateCount,
                                      currentTotalDeposit: (parseInt(this.state.rateCount) * parseFloat(this.state.monthRate)) + parseFloat(monthlyDepositTemp),
                                      rentalTotal:(parseFloat(this.state.monthRate)) + parseFloat(monthlyDepositTemp) - parseFloat(this.state.currentPromoDiscount),
                                      rentalTotalIntial:(parseFloat(this.state.monthRate)) + parseFloat(monthlyDepositTemp) - parseFloat(this.state.currentPromoDiscount)
                                  });
                              }else if(this.state.lType == "1-year"){
                                  this.setState({
                                      currentRateTitle: "1-Yr Lease Rate",
                                      currentRateType:"1-Yr Lease Rate",
                                      currentRateSubTitle: "per Month",
                                      currentRateValue: this.state.oneYearRate,
                                      currentTotalRateOrig: parseFloat(this.state.oneYearRate),
                                      currentTotalRate: parseFloat(this.state.oneYearRate) - parseFloat(this.state.currentPromoDiscount),
                                      currentDeposit: this.state.oneYearDeposit,
                                      currentRateCount: "12",
                                      currentTotalDeposit: (12 * parseFloat(this.state.oneYearRate)) + parseFloat(this.state.oneYearDeposit),
                                      rentalTotal:(parseFloat(this.state.oneYearRate)) + parseFloat(this.state.oneYearDeposit) - parseFloat(this.state.currentPromoDiscount),
                                      rentalTotalIntial:(parseFloat(this.state.oneYearRate)) + parseFloat(this.state.oneYearDeposit) - parseFloat(this.state.currentPromoDiscount)
                                  });
                              }else if(this.state.lType == "2-years"){
                                  this.setState({
                                      currentRateTitle: "2-Yr Lease Rate",
                                      currentRateType:"2-Yr Lease Rate",
                                      currentRateSubTitle: "per Month",
                                      currentRateValue: this.state.twoYearRate,
                                      currentTotalRateOrig: parseFloat(this.state.twoYearRate),
                                      currentTotalRate: parseFloat(this.state.twoYearRate) - parseFloat(this.state.currentPromoDiscount),
                                      currentDeposit: this.state.twoYearDeposit,
                                      currentRateCount: "24",
                                      currentTotalDeposit: (24 * parseFloat(this.state.twoYearRate)) + parseFloat(this.state.twoYearDeposit),
                                      rentalTotal:(parseFloat(this.state.twoYearRate)) + parseFloat(this.state.twoYearDeposit) - parseFloat(this.state.currentPromoDiscount),
                                      rentalTotalIntial:(parseFloat(this.state.twoYearRate)) + parseFloat(this.state.twoYearDeposit) - parseFloat(this.state.currentPromoDiscount)
                                  });
                              }

                          }


                      }

                  })
                  .catch((error) => {
                    //
                    // $.magnificPopup.close();
                    //
                    // this.props.history.push({
                    //     pathname: '/vehicles/all-vehicles',
                    //     search: '?start='+this.state.startDateQuery+'&end='+this.state.endDateQuery,
                    // })

              });



  }
  toggleDateDialog() {

    this.setState({ openDateDialog: !this.state.openDateDialog });

  }

  handleInputChangeAdditional(index,e) {
    const item = e.target.name;
    const isChecked = e.target.checked;
    const inputVal = parseFloat(e.target.value);

    var tempVal = this.state.additionalsAdded;

    const newAdditionals = this.state.additionals.slice();
    newAdditionals[index].checked = isChecked;

    if(isChecked){
        newAdditionals[index].disabled = false;
        tempVal[index] = inputVal;
        this.setState({ additionalsAdded: tempVal });
        var rentalTotal = this.state.rentalTotal + inputVal;
    }else{
        newAdditionals[index].disabled = true;
        tempVal[index] = 0;
        this.setState({ additionalsAdded: tempVal });
        var rentalTotal = this.state.rentalTotal - inputVal;

    }

    this.setState({additionals: newAdditionals})


    var additionals = this.state.additionalsAdded;
    var equipmentTotal = 0;
    for (var i = 0; i < additionals.length; i++) {
        if(additionals[i]){
            equipmentTotal = equipmentTotal + parseInt(additionals[i]);
        }

    }


    this.setState({ equipmentTotal: equipmentTotal });
    this.setState({ rentalTotal: rentalTotal });



  }

  handleInputChangeQty(index,e) {

        const item = e.target.name;
        var inputVal = e.target.value;

        var tempVal = this.state.additionalsAdded;

        const newAdditionals = this.state.additionals.slice();
        const isChecked = e.target.checked;
        if(inputVal == "" || parseInt(inputVal) <= 0 ){
            inputVal = 1;
        }else{
            if(parseInt(e.target.value) > parseInt(newAdditionals[index].maxqty)){
                inputVal = parseInt(newAdditionals[index].maxqty);
            }else{
                inputVal = parseInt(e.target.value);
            }
        }

        newAdditionals[index].qty = inputVal;
        var newPrice = parseFloat(newAdditionals[index].price) *  inputVal;
        newPrice = newPrice.toFixed(2);
        newAdditionals[index].qtyPrice = newPrice;

        this.setState({additionals: newAdditionals})
        this.setState({ additionalsAdded: tempVal });
        var rentalTotal = parseFloat(this.state.rentalTotal) - parseFloat(tempVal[index]);
        rentalTotal = parseFloat(rentalTotal) + parseFloat(newPrice);
        rentalTotal = rentalTotal.toFixed(2);
        tempVal[index] = newPrice;
        this.setState({ additionalsAdded: tempVal });

        this.setState({ rentalTotal: rentalTotal });

  }


    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }


    handleSubmit = event => {
        event.preventDefault();

        const request = {
            pickup: this.state.pickup,
            checkin: this.state.checkin,
            timein: this.state.timein,
            dropoff: this.state.dropoff,
            checkout: this.state.checkout,
            timeout: this.state.timeout,
        };

        axios.post(`https://jsonplaceholder.typicode.com/users`, { request })
        .then(res => {

        })
    }

  handleCheckout = event => {
    event.preventDefault();
    const currentVehicle = localStorage.getItem('currrent_vehicle');
    let addonsTotal = 0;
    if (this.state.additionals) {
      var addons = this.state.additionals;
      addons.map((item, i) => {
        if (item.checked == true) {
          addonsTotal += parseFloat(item.price);
        }
      });
    }
    let checkoutVehicle = JSON.parse(currentVehicle);
    checkoutVehicle.data[0].addons = this.state.additionals;
    checkoutVehicle.data[0].lType = this.state.lType;
    checkoutVehicle.summary = {
      currentRateTitle: this.state.currentRateTitle,
      currentRateType: this.state.currentRateType,
      currentRateSubTitle: this.state.currentRateSubTitle,
      currentRateValue: this.state.currentRateValue,
      currentTotalRate: this.state.currentTotalRate,
      currentDeposit: this.state.currentDeposit,
      currentRateCount: this.state.currentRateCount,
      currentTotalDeposit: this.state.currentTotalDeposit,
      currentTotalAddons: addonsTotal,
      currentAddOns:this.state.additionals,
      rentalTotal: this.state.rentalTotal,
      currentPromoCode: this.state.currentPromoCode,
      currentPromoDiscount: this.state.currentPromoDiscount,
      creditBalanceUsed: this.state.creditBalanceUse,
    };
    checkoutVehicle.date_from = moment(checkoutVehicle.date_from).format("YYYY-MM-DD");
    checkoutVehicle.date_to = moment(checkoutVehicle.date_to).format("YYYY-MM-DD");
    localStorage.setItem('currrent_vehicle', JSON.stringify(checkoutVehicle));
    // Save current checkout
    let formData = new FormData();
        formData.append('api_key', this.apiKey);
        formData.append('access_token', this.accessToken);
        formData.append('jham_token', this.jhamToken);
        formData.append('checkout_content', JSON.stringify(checkoutVehicle));


    ReactGA.plugin.execute('ec', 'addProduct', {
        'id': checkoutVehicle.data[0].product_id,
        'name': checkoutVehicle.data[0].product_name,
        'price': this.state.rentalTotal,
        'quantity': 1,
        'category': "Vehicle",
        'brand': checkoutVehicle.data[0].product_brand,
        'variant': checkoutVehicle.data[0].product_colour
    });

    ReactGA.plugin.execute('ec', 'setAction', 'click', { list: "Product Details" })
    ReactGA.plugin.execute('ec', 'setAction', 'checkout', { step: 1 })


    axios({
      method: 'post',
      url: this.apiURL + '/transaction/save_current_checkout',
      data: formData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      if (response.data.error == 0) {
        this.props.history.push('/checkout');
        // window.location.href = "/checkout";
        var checkout = localStorage.getItem('checkout');
            checkout = JSON.parse(checkout);
        if (checkout) {
          delete checkout.leasingAgreement;
          delete checkout.insuranceAgreement;
          localStorage.setItem('checkout', JSON.stringify(checkout));
        }
      } else {
        var redirect = false;
        var redirectURL = "";
        var errorTitle = "";
        var errorMsg = "";
        switch (response.data.error) {
          case 1:
          case 2:
            redirect = true;
            redirectURL = "/sign-in";
            errorTitle = "Your session has expired";
            errorMsg = "Please login again. This page will automatically reload.";
            break;
            case 3:
                errorTitle = "Something went wrong.";
                errorMsg = response.data.error_msg;
                break;
          default:
            break;
        }
        // Show error notification
        store.addNotification({
          title: errorTitle,
          message: errorMsg,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: false
          }
        });
        // Redirect
        if (redirect == true) {
          setTimeout(function() {
            this.props.history.push(redirectURL);
          }.bind(this), 3000);
        }
      }
    })
    .catch((error) => {
      // Handle error
      if (error.response && error.response.status == 403) {
        store.addNotification({
          title: "Oops Something went wrong",
          message: "The action you have requested is not allowed. Please reload the page",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: false
          }
        });
      }
    });
  }

    renderPopup() {
        function DateRangeProduct(props){
            const stateDefinitions = {
                available: {
                  color: null,
                  label: 'Available',
                },
                unavailable: {
                  selectable: false,
                  color: '#78818b',
                  label: 'Unavailable',
                },
              };

              const unavailableDates = [];
              for (let i = 0; i < props.restrictedDate.length; i++) {
                  var startDate = moment(props.restrictedDate[i].s_start_date).subtract(1, 'day').format("YYYY-MM-DD");
                  var endDate = moment(props.restrictedDate[i].s_end_date).add(2, 'day').format("YYYY-MM-DD");

                  unavailableDates.push(
                      {
                      state: 'unavailable',
                      range: moment.range(
                          startDate,
                          endDate,
                      ),
                      },
                  );

              }

              if(unavailableDates.length > 0)
                  unavailableDates.sort((a, b) => (moment(a.range.start).isBefore(moment(b.range.start)) ? -1 : 1));

              return(
                  <DateRangePicker
                    numberOfCalendars={1}
                    disabled={true}
                    selectionType={false}
                    minimumDate={props.minDate}
                    stateDefinitions={stateDefinitions}
                    dateStates={unavailableDates}
                    defaultState="available"
                    fullDayStates={false}
                    halfDayStates={false}
                    value={props.value}
                    showLegend={true}
                    className="text-center"
                    singleDateRange={true}
                  />
              );
        }

        function ListTitle(props){
            return (
                <span className="list-title-price">{props.title}</span>
            )
        }

        function disableWeekDays(restrictedDates,date) {

            if(restrictedDates && restrictedDates.length > 0){

                    for (let i = 0; i < restrictedDates.length; i++) {
                        var endDate = moment(restrictedDates[i].s_end_date).add(1, 'day').format("YYYY-MM-DD");
                        var startDate = moment(restrictedDates[i].s_start_date).subtract(1, 'day').format("YYYY-MM-DD");
                        var checkBetween = moment(date).isBetween(startDate, endDate);
                        if(!checkBetween){
                            if((date.getDay() === 6 || date.getDay() === 0)){
                                return true;
                            }
                        }else{
                            return true;
                        }
                    }

            }else{
               return  date.getDay() === 6 || date.getDay() === 0;
            }

        }

        function disableWeekEnd(restrictedDates,date) {

            if(restrictedDates && restrictedDates.length > 0){

                    for (let i = 0; i < restrictedDates.length; i++) {
                        var endDate = moment(restrictedDates[i].s_end_date).add(1, 'day').format("YYYY-MM-DD");
                        var startDate = moment(restrictedDates[i].s_start_date).subtract(1, 'day').format("YYYY-MM-DD");
                        var checkBetween = moment(date).isBetween(startDate, endDate);
                        if(!checkBetween){
                            if((date.getDay() === 1 || date.getDay() === 2 || date.getDay() === 3 || date.getDay() === 4 || date.getDay() === 6 || date.getDay() === 0)){
                                return true;
                            }
                        }else{
                            return true;
                        }
                    }

            }else{
               return date.getDay() === 1 || date.getDay() === 2 || date.getDay() === 3 || date.getDay() === 4 || date.getDay() === 6 || date.getDay() === 0;
            }

        }

        function disableRestrictedOnly(restrictedDates,date) {
            if(restrictedDates){

                    for (let i = 0; i < restrictedDates.length; i++) {
                        var endDate = moment(restrictedDates[i].s_end_date).add(1, 'day').format("YYYY-MM-DD");
                        var startDate = moment(restrictedDates[i].s_start_date).subtract(1, 'day').format("YYYY-MM-DD");
                        return moment(date).isBetween(startDate, endDate);
                    }

            }else{
                return false;
            }
        }

        function disableCalendarRestrictedOnly(restrictedDates,weekdays,date) {

            // if(weekdays){
            //     if((date.date.getDay() === 5 || date.date.getDay() === 6 || date.date.getDay() === 0)){
            //         return true;
            //     }
            // }


            if(restrictedDates){

                    for (let i = 0; i < restrictedDates.length; i++) {
                        var endDate = moment(restrictedDates[i].s_end_date).add(1, 'day').format("YYYY-MM-DD");
                        var startDate = moment(restrictedDates[i].s_start_date).subtract(1, 'day').format("YYYY-MM-DD");
                        if(moment(date.date).isBetween(startDate, endDate)){
                            return true;
                        }
                    }

            }else{
                return false;
            }
        }





        function SearchForm(props){
            const classes = useStyles();
            const [isOpen, setIsOpen] = React.useState(false);
            var currentWeekEndDate = null;
            var currentLtype = props.lType;
            var currentStartDate = props.currentStartDate;
            var currentRangeValue = props.rangeValue;
            if(currentLtype == ""){
                currentStartDate = null;
                currentRangeValue = null;
                if(parseFloat(props.product.product_price_month) > 0){
                    currentLtype = "monthly";
                }else if(parseFloat(props.product.product_price_day) > 0){
                    currentLtype = "daily";
                }else if(parseFloat(props.product.product_price_weekend) > 0){
                    currentLtype = "weekend";
                }else if(parseFloat(props.product.product_price_week) > 0){
                    currentLtype = "weekly";
                }else if(parseFloat(props.product.product_price_year) > 0){
                    currentLtype = "1-year";
                }else if(parseFloat(props.product.product_price_year_2) > 0){
                    currentLtype = "2-years";
                }

            }
            if(currentLtype == "weekend"){
                currentWeekEndDate = props.currentStartDate;
            }

            const [selectedDate, setSelectedDate] =  React.useState(currentStartDate);
            const [selectedDateWeekEnd, setSelectedDateWeekEnd] =  React.useState(currentWeekEndDate);
            const [selectedDateWeekly, setSelectedDateWeekly] =  React.useState(currentStartDate);
            const [selectedDateMonthly, setSelectedDateMonthly] =  React.useState(currentStartDate);
            const [selectedRangeDate, setSelectedRangeDate] = React.useState(currentRangeValue);
            const [noDays, setNoDays] =  React.useState("");
            const [btnLoading, setBtnLoading] = React.useState(false);
            const [withWeekdays, setWithWeekdays] = React.useState(true);
            const [useDate, setUseDate] = React.useState(props.onProcess);
            const [minDate, setMinDate] = React.useState(props.minDate);
            const [calendarRange, setCalendarRange] = React.useState(true);
            const [calendarNote, setCalendarNote] = React.useState("Note: Saturday/Sunday not available as start/end date");
            const [toggleRange, setToggleRange] = React.useState(true);


            const [type, setType] = React.useState(currentLtype);

            const handleDateChange = (date) => {
              setUseDate(false);
              setMinDate(props.minDate);
              setToggleRange(false);
              if(selectedDate !== null){
                  setIsOpen(false);
              }
              setSelectedDate(date);
              setSelectedRangeDate([new Date(date),new Date(date)]);

            };

          const handleDateChangeWeekEnd = (date) => {
              setUseDate(false);
              if(selectedDateWeekEnd !== null){
                  setIsOpen(false);
              }
              setSelectedDateWeekEnd(date);
              var endDate = date;

              var checkCurrentDay = moment(date);
              if(checkCurrentDay.day() == 5)
                  endDate = moment(date).add(3, 'days').format("YYYY-MM-DD");

              setSelectedRangeDate([new Date(date),new Date(endDate)]);
          };

          const handleDateChangeWeekly = (date) => {
              setUseDate(false);
              if(selectedDateWeekly !== null){
                  setIsOpen(false);
              }
              setSelectedDateWeekly(date);
              setSelectedRangeDate([new Date(date),new Date(date)]);
          };

          const handleDateChangeMonthly = (date) => {
              setUseDate(false);
              if(selectedDateMonthly !== null){
                  setIsOpen(false);
              }
              setSelectedDateMonthly(date);
              setSelectedRangeDate([new Date(date),new Date(date)]);
          };

          const handlePasteChange = (e) => {
              e.preventDefault();
          }

          const handleDaysChange = (e) => {
              setUseDate(false);
              e.preventDefault();
              if(e.target.value == ""){
                  setNoDays("");
              }else{
                  if(parseInt(e.target.value) <= 0 || isNaN(parseInt(e.target.value))){
                      setNoDays(1);
                  }else{
                      setNoDays(e.target.value);
                  }

              }
          }

          const handleWeeklyChange = (e) => {
              setUseDate(false);
              e.preventDefault();
              if(e.target.value == ""){
                  setNoDays("");
              }else{
                  if(parseInt(e.target.value) <= 0 || isNaN(parseInt(e.target.value))){
                      setNoDays(1);
                  }else{
                      setNoDays(e.target.value);
                  }
              }

          }

          const handleMonthlyChange = (e) => {
              setUseDate(false);

          }


          const handleStartDate = (value, event) => {
              setUseDate(false);
                console.log(value);

              if(type == "monthly" || type == "weekly" || type == "1-year" || type == "2-years"){
                  var checkCurrentDay = moment(new Date(value));
                  if(checkCurrentDay.day() == 6 || checkCurrentDay.day() == 0) {
                      setSelectedRangeDate(selectedRangeDate);
                      setMinDate(props.minDate);
                      store.addNotification({
                          title: "Oops!",
                          message: "Saturday/Sunday is not available as starting date",
                          type: "warning",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animated", "fadeIn"],
                          animationOut: ["animated", "fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: false
                          }
                      });
                  }else{
                      setSelectedDateWeekly(value);
                      setSelectedDateMonthly(value);
                  }
              }else if(type == "weekend"){
                  var endDate = value;

                  var checkCurrentDay = moment(value);
                  if(checkCurrentDay.day() == 5){
                        setSelectedDateWeekEnd(value);
                        endDate = moment(value).add(3, 'days').format("YYYY-MM-DD");
                        setSelectedRangeDate([new Date(value),new Date(endDate)]);
                        setMinDate(props.minDate);
                  }else{
                     setWithWeekdays(false);
                     setMinDate(new Date(value));
                  }

              }else{
                  setToggleRange(true);
                  setWithWeekdays(false)
                  setMinDate(new Date(value));
              }
          }

          const handleRangeSelect = (value, event) => {

                var startDate = moment(value[0]).format("YYYY-MM-DD");
                var endDate = moment(value[1]).format("YYYY-MM-DD");

                if(startDate == endDate){
                    endDate = moment(startDate).add(1, 'days').format("YYYY-MM-DD");
                }

                if(type == "weekend"){
                    startDate = moment(value).format("YYYY-MM-DD");
                    endDate = moment(value).format("YYYY-MM-DD");
                }
                if(type == "daily"){
                    var checkCurrentDay = moment(startDate);
                    if(!value[1]){
                        if(checkCurrentDay.day() == 6 || checkCurrentDay.day() == 0) {
                            setMinDate(props.minDate);
                            store.addNotification({
                                title: "Oops!",
                                message: "Saturday/Sunday is not available as start/end date",
                                type: "warning",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 5000,
                                    onScreen: false
                                }
                            });
                        }else{
                            setSelectedDate(startDate);
                        }
                        return false;
                    }

                    var checkCurrentEndDay = moment(endDate);
                    if(checkCurrentDay.day() != 5 && (checkCurrentDay.day() == 6 || checkCurrentDay.day() == 0 || checkCurrentEndDay.day() == 6 || checkCurrentEndDay.day() == 0)) {
                        setSelectedRangeDate(selectedRangeDate);
                        setMinDate(props.minDate);
                        store.addNotification({
                            title: "Oops!",
                            message: "Saturday/Sunday is not available as start/end date",
                            type: "warning",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: false
                            }
                        });
                    }else{

                        setWithWeekdays(true);
                        setMinDate(props.minDate);
                        var a = moment(endDate);
                        var b = moment(startDate);
                        var dayCount = a.diff(b, 'days');
                        var count = dayCount + 1;
                        var checkCurrentDay = moment(startDate);

                        if(checkCurrentDay.day() == 5 && count < 5) {
                            var noDays = 3;
                            count = 1;
                            endDate = moment(startDate).add(noDays, 'days').format("YYYY-MM-DD");
                            setType("weekend");
                            setSelectedDateWeekEnd(startDate);
                            setCalendarNote("Note: Weekend Fri – Mon (3 days)");
                        }else{
                            setType("daily");
                            setCalendarNote("Note: Saturday/Sunday not available as start/end date");

                            if(checkCurrentDay.day() == 6 || checkCurrentDay.day() == 0 || checkCurrentEndDay.day() == 6 || checkCurrentEndDay.day() == 0) {
                                setSelectedRangeDate(selectedRangeDate);
                                setMinDate(props.minDate);
                                store.addNotification({
                                    title: "Oops!",
                                    message: "Saturday/Sunday is not available as start/end date",
                                    type: "warning",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 5000,
                                        onScreen: false
                                    }
                                });
                                return false;
                            }

                            if(count < parseInt(props.product.product_min_days)){
                                store.addNotification({
                                  title: "Oops!",
                                  message: "This product can be lease with minimum of "+props.product.product_min_days+" days",
                                  type: "warning",
                                  insert: "top",
                                  container: "top-right",
                                  animationIn: ["animated", "fadeIn"],
                                  animationOut: ["animated", "fadeOut"],
                                  dismiss: {
                                    duration: 5000,
                                    onScreen: false
                                  }
                                });

                                return false;
                            }
                        }


                        // var daysDiff = getDaysDiff('10-01-2019', '10-30-2019'));
                        setSelectedDate(startDate);
                        setNoDays(dayCount);
                        setSelectedRangeDate([new Date(startDate),new Date(endDate)]);

                        setBtnLoading(true);


                        var ctoken = localStorage.getItem('ctoken');
                        var token = props.token;
                        var bodyFormData = new FormData();
                        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
                        bodyFormData.append('access_token', ctoken);
                        bodyFormData.append('jham_token', token);
                        bodyFormData.append('limit', 1);
                        bodyFormData.append('offset', 0);
                        bodyFormData.append('date_from',startDate);
                        bodyFormData.append('date_to',endDate);
                        bodyFormData.append('product_id', props.product.product_id);
                        bodyFormData.append('search_type', type);


                        axios({
                        method: 'post',
                        url: 'https://api.earental.com/listing/search',
                        data: bodyFormData,
                        withCredentials: true,
                        headers: {'Content-Type': 'multipart/form-data' }
                        })
                        .then((response) => {
                           if(response.data.data[0].available == 0){
                               store.addNotification({
                                 title: "Not Available",
                                 message: "Product already rented for this date.",
                                 type: "warning",
                                 insert: "top",
                                 container: "top-right",
                                 animationIn: ["animated", "fadeIn"],
                                 animationOut: ["animated", "fadeOut"],
                                 dismiss: {
                                   duration: 5000,
                                   onScreen: false
                                 }
                               });
                               setBtnLoading(false);
                               setUseDate(false);
                           }else{

                               store.addNotification({
                                 title: "Great!",
                                 message: "This date is available for booking.",
                                 type: "success",
                                 insert: "top",
                                 container: "top-right",
                                 animationIn: ["animated", "fadeIn"],
                                 animationOut: ["animated", "fadeOut"],
                                 dismiss: {
                                   duration: 5000,
                                   onScreen: false
                                 }
                               });
                               setUseDate(true);
                               setBtnLoading(false);


                           }



                        })
                        .catch((error) => {
                            setBtnLoading(false);

                        });


                    }
                }else{

                    if(type == "weekend"){
                        var checkCurrentDay = moment(value);
                        setMinDate(props.minDate);
                        if(checkCurrentDay.day() != 5) {
                            store.addNotification({
                                title: "Oops!",
                                message: "Please select friday as starting date",
                                type: "warning",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 5000,
                                    onScreen: false
                                }
                            });
                        }else{
                            var noDays = 3;
                            count = 1;
                            endDate = moment(startDate).add(noDays, 'days').format("YYYY-MM-DD");
                            setSelectedRangeDate([new Date(startDate),new Date(endDate)]);
                        }
                    }else{
                        var checkCurrentDay = moment(value);
                        if(checkCurrentDay.day() == 6 || checkCurrentDay.day() == 0) {
                            setSelectedRangeDate(selectedRangeDate);
                        }else{
                            setSelectedRangeDate([new Date(value),new Date(value)]);
                        }
                        setMinDate(props.minDate);
                    }


                }




          };



           const days = [
           ];

           const months = [
               { title: "1" },
               { title: "2" },
               { title: "3" },
               { title: "4" },
               { title: "5" },
               { title: "6" },
               { title: "7" },
               { title: "8" },
               { title: "9" },
               { title: "10" },
               { title: "11" },
           ];

           const handleTypeChange = (e) => {
               setUseDate(false);
               setNoDays("");
               setType(e.target.value);
               setSelectedRangeDate(props.currentRange);
                if(e.target.value == "daily"){
                    setSelectedRangeDate([new Date(selectedDate),new Date(selectedDate)]);
                    setCalendarNote("Note: Saturday/Sunday not available as start/end date");
                }else if(e.target.value == "weekend"){
                    setCalendarNote("Note: Weekend Fri – Mon (3 days)");
                    setSelectedRangeDate([new Date(selectedDateWeekEnd),new Date(selectedDateWeekEnd)]);
                }else if(e.target.value == "weekly"){
                    setSelectedRangeDate([new Date(selectedDateWeekly),new Date(selectedDateWeekly)]);
                    setCalendarNote("Note: Saturday/Sunday not available as start/end date");
                }else if(e.target.value == "monthly"){
                    setSelectedRangeDate([new Date(selectedDateMonthly),new Date(selectedDateMonthly)]);
                    setCalendarNote("Note: Saturday/Sunday not available as start/end date");
                }else if(e.target.value == "1-year"){
                    setSelectedRangeDate([new Date(selectedDateMonthly),new Date(selectedDateMonthly)]);
                    setCalendarNote("Note: Saturday/Sunday not available as start/end date");
                }else if(e.target.value == "2-years"){
                    setSelectedRangeDate([new Date(selectedDateMonthly),new Date(selectedDateMonthly)]);
                    setCalendarNote("Note: Saturday/Sunday not available as start/end date");
                }

           };

           function StyledRadio(props) {
               const classes = useStyles();

               return (
                   <RadioMu
                     className={classes.root}
                     disableRipple
                     color="default"
                     checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                     icon={<span className={classes.icon} />}
                     {...props}
                   />
               );
           }




            const handleCheckDate = (e) => {
                e.preventDefault();
                setToggleRange(false);
                var type = e.target.leasingType.value;
                var endDate = "";
                var count = 1;
                var checkCurrentDay = moment(e.target.startDate.value);
                if(type == "daily"){
                    count = e.target.noDays.value;
                    if(checkCurrentDay.day() == 5 && count < 5) {
                        var noDays = 3;
                        count = "";
                        endDate = moment(e.target.startDate.value).add(noDays, 'days').format("YYYY-MM-DD");
                        type = "weekend";
                        setType("weekend");
                        setSelectedDateWeekEnd(e.target.startDate.value);
                    }else{
                        var noDays = e.target.noDays.value;
                            noDays = parseInt(noDays);
                        endDate = moment(e.target.startDate.value).add(noDays, 'days').format("YYYY-MM-DD");
                    }
                }else if(type == "weekend"){
                   var noDays = 3;

                   endDate = moment(e.target.startDate.value).add(noDays, 'days').format("YYYY-MM-DD");
                }else if(type == "weekly"){
                   var noWeeks = e.target.noWeeks.value;
                   count = e.target.noWeeks.value;
                       noWeeks = parseInt(noWeeks) * 7;
                       noWeeks = noWeeks;
                   endDate = moment(e.target.startDate.value).add(noWeeks, 'days').format("YYYY-MM-DD");
                }else if(type == "monthly"){
                   var noMonths = e.target.noMonths.value;
                   count = e.target.noMonths.value;
                   endDate = moment(e.target.startDate.value).add(count, 'months').format("YYYY-MM-DD");
                   endDate = moment(endDate).subtract(1, 'days').format("YYYY-MM-DD");
                   if(props.product.month_bonus == "1" && props.product.product_month_bonus_rental_fee == "1"){
                       if(props.product.product_month_bonus_type == "days"){
                           endDate = moment(endDate).add(parseInt(props.product.product_month_bonus_days_months_count), 'days').format("YYYY-MM-DD");
                       }else{
                           endDate = moment(endDate).add(parseInt(props.product.product_month_bonus_days_months_count), 'months').format("YYYY-MM-DD");
                       }
                   }
                }else if(type == "1-year"){
                    endDate = moment(e.target.startDate.value).add(1, 'years').format("YYYY-MM-DD");
                    endDate = moment(endDate).subtract(1, 'days').format("YYYY-MM-DD");
                    if(props.product.year_bonus == "1" && props.product.product_year_bonus_rental_fee == "1"){
                        if(props.product.product_year_bonus_type == "days"){
                            endDate = moment(endDate).add(parseInt(props.product.product_year_bonus_days_months_count), 'days').format("YYYY-MM-DD");
                        }else{
                            endDate = moment(endDate).add(parseInt(props.product.product_year_bonus_days_months_count), 'months').format("YYYY-MM-DD");
                        }
                    }
                }else if(type == "2-years"){
                    endDate = moment(e.target.startDate.value).add(2, 'years').format("YYYY-MM-DD");
                    endDate = moment(endDate).subtract(1, 'days').format("YYYY-MM-DD");
                    if(props.product.year2_bonus == "1" && props.product.product_year2_bonus_rental_fee == "1"){
                        if(props.product.product_year2_bonus_type == "days"){
                            endDate = moment(endDate).add(parseInt(props.product.product_year2_bonus_days_months_count), 'days').format("YYYY-MM-DD");
                        }else{
                            endDate = moment(endDate).add(parseInt(props.product.product_year2_bonus_days_months_count), 'months').format("YYYY-MM-DD");
                        }
                    }
                }
                var startDate = moment(e.target.startDate.value).format("YYYY-MM-DD");
                var checkCurrentEndDay = moment(endDate);
                var rangeDate = moment.range(startDate,endDate);

                if(type == "daily" && (checkCurrentDay.day() == 6 || checkCurrentDay.day() == 0 || checkCurrentEndDay.day() == 6 || checkCurrentEndDay.day() == 0)) {
                    setSelectedRangeDate(selectedRangeDate);
                    setMinDate(props.minDate);
                    store.addNotification({
                        title: "Oops!",
                        message: "Saturday/Sunday is not available as start/end date",
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: false
                        }
                    });
                    return false;
                }


                if(type == "daily" && count < parseInt(props.product.product_min_days)){
                    store.addNotification({
                      title: "Oops!",
                      message: "This product can be lease with minimum of "+props.product.product_min_days+" days",
                      type: "warning",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: false
                      }
                    });
                }else{
                    if(useDate){
                        props.onSelect(startDate,endDate,type,count);
                    }else{
                        setBtnLoading(true);
                        setSelectedRangeDate([new Date(startDate),new Date(endDate)]);
                        var ctoken = localStorage.getItem('ctoken');
                        var token = props.token;
                        var bodyFormData = new FormData();
                        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
                        bodyFormData.append('access_token', ctoken);
                        bodyFormData.append('jham_token', token);
                        bodyFormData.append('limit', 1);
                        bodyFormData.append('offset', 0);
                        bodyFormData.append('date_from',startDate);
                        bodyFormData.append('date_to',endDate);
                        bodyFormData.append('product_id', props.product.product_id);
                        bodyFormData.append('search_type', type);


                        axios({
                            method: 'post',
                            url: 'https://api.earental.com/listing/search',
                            data: bodyFormData,
                            withCredentials: true,
                            headers: {'Content-Type': 'multipart/form-data' }
                        })
                        .then((response) => {
                           if(response.data.data[0].available == 0){
                               store.addNotification({
                                 title: "Not Available",
                                 message: "Product already rented for this date.",
                                 type: "warning",
                                 insert: "top",
                                 container: "top-right",
                                 animationIn: ["animated", "fadeIn"],
                                 animationOut: ["animated", "fadeOut"],
                                 dismiss: {
                                   duration: 5000,
                                   onScreen: false
                                 }
                               });
                               setBtnLoading(false);
                               setUseDate(false);
                           }else{

                               if(useDate){
                                   props.onSelect(startDate,endDate,type,count);
                               }else{
                                   store.addNotification({
                                     title: "Great!",
                                     message: "This date is available for booking.",
                                     type: "success",
                                     insert: "top",
                                     container: "top-right",
                                     animationIn: ["animated", "fadeIn"],
                                     animationOut: ["animated", "fadeOut"],
                                     dismiss: {
                                       duration: 5000,
                                       onScreen: false
                                     }
                                   });
                                   setUseDate(true);
                                   setBtnLoading(false);
                               }

                           }



                        })
                        .catch((error) => {
                            setBtnLoading(false);

                        });
                    }

                }



            }


          return(
              <Grid container spacing={1} className={classes.newCardForm}>
                  <Grid item md={6} sm={12} xs={12}>
                      {parseInt(props.product.product_min_days) > 1 && (
                          <>
                            <Alert severity="info" style={{fontSize:"15px"}}>Minimum of {props.product.product_min_days} days to lease this product.</Alert>
                          </>
                      )}
                      <form onSubmit={handleCheckDate }>
                          <Grid container spacing={1} className={classes.newCardForm}>
                            <Grid item md={4} sm={4} xs={5}>
                                <br />
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Leasing Type</FormLabel>
                                    <RadioGroup value={type} aria-label="leasing-type" onChange={handleTypeChange} name="leasingType">
                                        {parseFloat(props.product.product_price_day) > 0 && (
                                            <FormControlLabel value="daily" control={<StyledRadio />} label="Daily" />
                                        )}

                                        {parseFloat(props.product.product_price_weekend) > 0 && (
                                            <FormControlLabel value="weekend" control={<StyledRadio />} label="Weekend" />
                                        )}

                                        {parseFloat(props.product.product_price_week) > 0 && (
                                            <FormControlLabel value="weekly" control={<StyledRadio />} label="Weekly" />
                                        )}

                                        {parseFloat(props.product.product_price_month) > 0 && (
                                            <FormControlLabel value="monthly" control={<StyledRadio />} label="Monthly" />
                                        )}

                                        {parseFloat(props.product.product_price_year) > 0 && (
                                            <FormControlLabel value="1-year" control={<StyledRadio />} label="1-Year Lease" />
                                        )}

                                        {parseFloat(props.product.product_price_year_2) > 0 && (
                                            <FormControlLabel value="2-years" control={<StyledRadio />} label="2-Year Lease" />
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item container md={8} sm={8} xs={7} spacing={1} className="home-search-right">
                                <Grid item md={12} sm={12} xs={12}>
                                        <br/>
                                        <br />
                                        {type == "weekend" && (
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                                                <KeyboardDatePicker
                                                    required
                                                    autoOk={true}
                                                    disablePast
                                                    variant="inline"
                                                    label="Start Date"
                                                    format="MMM d, yyyy"
                                                    name="startDate"
                                                    className="startDate"
                                                    minDate={props.minDate}
                                                    shouldDisableDate={disableWeekEnd.bind(this,props.restrictedDate)}
                                                    value={selectedDateWeekEnd}
                                                    onChange={handleDateChangeWeekEnd}
                                                    fullWidth
                                                    className="details-date-picker"
                                                    KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                    }}
                                                    KeyboardButtonProps={{
                                                        onFocus: e => {
                                                        setIsOpen(true);
                                                        }
                                                    }}
                                                    PopoverProps={{
                                                        disableRestoreFocus: true,
                                                        onClose: () => {
                                                            setIsOpen(false);
                                                        }
                                                    }}
                                                    InputProps={{
                                                    onFocus: () => {
                                                      setIsOpen(true);
                                                    }
                                                    }}
                                                    open={isOpen}

                                                />
                                            </MuiPickersUtilsProvider>
                                     )}

                                     {type == "daily" && (
                                         <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                                             <KeyboardDatePicker
                                                 required
                                                 autoOk={true}
                                                 disablePast
                                                 variant="inline"
                                                 label="Start Date"
                                                 format="MMM d, yyyy"
                                                 name="startDate"
                                                 minDate={props.minDate}
                                                 shouldDisableDate={disableWeekDays.bind(this,props.restrictedDate)}
                                                 className="startDate"
                                                 value={selectedDate}
                                                 onChange={handleDateChange}
                                                 fullWidth
                                                 className="details-date-picker"
                                                 KeyboardButtonProps={{
                                                 'aria-label': 'change date',
                                                 }}
                                                 KeyboardButtonProps={{
                                                     onFocus: e => {
                                                     setIsOpen(true);
                                                     }
                                                 }}
                                                 PopoverProps={{
                                                     disableRestoreFocus: true,
                                                     onClose: () => {
                                                     setIsOpen(false);
                                                     }
                                                 }}
                                                 InputProps={{
                                                     onFocus: () => {
                                                       setIsOpen(true);
                                                     }
                                                 }}
                                                 open={isOpen}

                                             />
                                         </MuiPickersUtilsProvider>
                                  )}
                                  {type == "weekly" && (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                                        <KeyboardDatePicker
                                            required
                                            autoOk={true}
                                            disablePast
                                            variant="inline"
                                            label="Start Date"
                                            format="MMM d, yyyy"
                                            name="startDate"
                                            minDate={props.minDate}
                                            shouldDisableDate={disableWeekDays.bind(this,props.restrictedDate)}
                                            className="startDate"
                                            value={selectedDateWeekly}
                                            onChange={handleDateChangeWeekly}
                                            fullWidth
                                            className="details-date-picker"
                                            KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                            }}
                                            KeyboardButtonProps={{
                                                onFocus: e => {
                                                    setIsOpen(true);
                                                }
                                            }}
                                            PopoverProps={{
                                                disableRestoreFocus: true,
                                                onClose: () => {
                                                    setIsOpen(false);
                                                }
                                            }}
                                            InputProps={{
                                                onFocus: () => {
                                                  setIsOpen(true);
                                                }
                                            }}
                                            open={isOpen}

                                        />
                                    </MuiPickersUtilsProvider>
                                  )}

                                  {(type == "monthly" || type == "1-year" || type == "2-years" ) && (
                                      <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                                         <KeyboardDatePicker
                                             required
                                             autoOk={true}
                                             disablePast
                                             variant="inline"
                                             label="Start Date"
                                             format="MMM d, yyyy"
                                             name="startDate"
                                             minDate={props.minDate}
                                             shouldDisableDate={disableWeekDays.bind(this,props.restrictedDate)}
                                             className="startDate"
                                             value={selectedDateMonthly}
                                             onChange={handleDateChangeMonthly}
                                             fullWidth
                                             className="details-date-picker"
                                             KeyboardButtonProps={{
                                             'aria-label': 'change date',
                                             }}
                                             KeyboardButtonProps={{
                                                 onFocus: e => {
                                                     setIsOpen(true);
                                                 }
                                             }}
                                             PopoverProps={{
                                                 disableRestoreFocus: true,
                                                 onClose: () => {
                                                     setIsOpen(false);
                                                 }
                                             }}
                                             InputProps={{
                                                 onFocus: () => {
                                                   setIsOpen(true);
                                                 }
                                             }}
                                             open={isOpen}

                                         />
                                      </MuiPickersUtilsProvider>
                                  )}
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    {type == "daily" && (
                                        <TextField
                                            label="No. of days"
                                            type="number"
                                            name="noDays"
                                            value={noDays}
                                            className="home-no-days"
                                            InputProps={{
                                             inputProps: { min: props.product.product_min_days},
                                           }}
                                           onChange={handleDaysChange}
                                           onPaste={handlePasteChange}
                                            fullWidth
                                            required
                                        />
                                    )}

                                    {type == "weekly" && (
                                        <TextField
                                            label="No. of weeks"
                                            type="number"
                                            name="noWeeks"
                                            value={noDays}
                                            className="home-no-days"
                                            InputProps={{
                                             inputProps: { min: 1 }
                                           }}
                                           onChange={handleWeeklyChange}
                                           onPaste={handlePasteChange}
                                            fullWidth
                                            required
                                        />
                                    )}

                                    {type == "monthly" && (
                                        <Autocomplete
                                           freeSolo={false}
                                           options={months.map((option) => option.title)}
                                           onChange={handleMonthlyChange}
                                           renderInput={(params) => (
                                             <TextField {...params} label="No. of months" name="noMonths" type="number" className="home-no-days" required />
                                           )}
                                         />
                                    )}

                                </Grid>
                                <Grid item md={12} sm={12} xs={12} align="center">
                                    <br />

                                        {btnLoading && (
                                            <>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    fullWidth
                                                    disabled={btnLoading}
                                                    endIcon={<EventAvailableIcon>Use this date</EventAvailableIcon>}
                                                    >
                                                    Checking...
                                                </Button>
                                            </>
                                        )}

                                        {(!btnLoading && !useDate) && (
                                            <>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    fullWidth
                                                    disabled={btnLoading}
                                                    endIcon={<EventAvailableIcon>Use this date</EventAvailableIcon>}
                                                    >
                                                    Check availability
                                                </Button>
                                            </>
                                        )}

                                        {(!btnLoading && useDate) && (
                                            <>
                                                <Button
                                                    type="submit"
                                                    variant=""
                                                    color="primary"
                                                    size="large"
                                                    fullWidth
                                                    className="proceed-green-btn"
                                                    disabled={btnLoading}
                                                    endIcon={<EventAvailableIcon>Use this date</EventAvailableIcon>}
                                                    >
                                                    Proceed Booking
                                                </Button>
                                            </>
                                        )}



                                </Grid>
                            </Grid>
                          </Grid>
                      </form>

                  </Grid>
                <Grid item md={6} sm={12} xs={12} align="center">
                    <br/>
                    <i>{calendarNote}</i>
                    {(type == "daily" && !toggleRange) && (
                        <Calendar
                            selectRange={false}
                            minDate={minDate}
                            calendarType={"US"}
                            value={selectedRangeDate}
                            onChange={handleRangeSelect}
                            onClickDay={handleStartDate}
                            allowPartialRange={true}
                            tileDisabled={disableCalendarRestrictedOnly.bind(this,props.restrictedDate,withWeekdays)}
                            tileClassName="cal-date-listing"
                        />
                    )}

                    {(type == "daily" && toggleRange) && (
                        <Calendar
                            selectRange={true}
                            minDate={minDate}
                            calendarType={"US"}
                            value={selectedRangeDate}
                            onChange={handleRangeSelect}
                            onClickDay={handleStartDate}
                            allowPartialRange={true}
                            tileDisabled={disableCalendarRestrictedOnly.bind(this,props.restrictedDate,withWeekdays)}
                            tileClassName="cal-date-listing"
                        />
                    )}

                    {(type == "weekend") && (
                        <Calendar
                            selectRange={false}
                            minDate={minDate}
                            calendarType={"US"}
                            value={selectedRangeDate}
                            onChange={handleRangeSelect}
                            onClickDay={handleStartDate}
                            tileDisabled={disableCalendarRestrictedOnly.bind(this,props.restrictedDate,withWeekdays)}
                            tileClassName="cal-date-listing"
                        />
                    )}

                    {(type == "weekly" || type == "monthly" || type == "1-year" || type == "2-years") && (
                        <Calendar
                            selectRange={false}
                            minDate={minDate}
                            calendarType={"US"}
                            value={selectedRangeDate}
                            onChange={handleRangeSelect}
                            onClickDay={handleStartDate}
                            tileDisabled={disableCalendarRestrictedOnly.bind(this,props.restrictedDate,withWeekdays)}
                            tileClassName="cal-date-listing"
                        />
                    )}



                    <br />
                    <Chip style={{background:"#3CB371",color:"#fff",marginRight:"5px",fontSize:"12px"}} label="Selected" />
                    <Chip style={{background:"#fff",color:"#111",marginRight:"5px",fontSize:"12px"}} label="Available" />
                    <Chip style={{background:"#f0f0f0",fontSize:"12px"}} label="Unavailable" />
                </Grid>

              </Grid>

          );
        }

        return (
            <Dialog
              open={this.state.openDateDialog}
              fullWidth={true}
              maxWidth = {'md'}
            >
              <DialogContent>
                  <h3>Change your date
                      <IconButton aria-label="close" onClick={this.toggleDateDialog} style={{float:"right"}}>
                          <CloseIcon fontSize="large"	/>
                      </IconButton>
                  </h3>
                  <form>
                      <SearchForm
                          product={this.state.details}
                          lType={this.state.lType}
                          rangeValue={this.state.valueRangeListing}
                          onSelect={this.onSelectRangeProduct.bind(this,this.state.details)}
                          minDate={this.state.minDate}
                          restrictedDate={this.state.restrictedDate}
                          currentStartDate={this.state.startDate}
                          onProcess={this.state.onProcess}
                          token={this.state.passToken}
                          />

                  </form>
                    <br />
              </DialogContent>
            </Dialog>
        );
    }

  render() {
      if(this.state.redirect){
        var redirectLink = "/vehicles/van-rental?start="+this.state.startDateQuery+"&end="+this.state.endDateQuery;
        return <Redirect to={redirectLink} />;
      }else{
          function TermIcons(props) {
              var terms = props.terms.split(',');
              return (
                    terms.map((item,i) =>
                      (item == "Short Term") ?
                      (<li title={item} key={'t-'+i+'-'+props.vid}><i className="fa fa-clock-o"></i><span className="booking-item-feature-title">{item}</span></li>) :
                      (<li rel="tooltip" data-placement="top" title={item} key={'t-'+i+'-'+props.vid}><i className="fa fa-calendar-o"></i><span className="booking-item-feature-title">{item}</span></li>)
                    )
              );



          }
          function Equipments(props) {

              if(props.equipments == null){
                  return false;
              }else{
                  var equipments = props.equipments.split(',');
                  var price = props.price.split(',');
                  return (
                        equipments.map((item,i) => {
                              switch (item.trim()) {
                                  case "Air Conditioner":
                                      if(parseInt(price[i]) == 0){
                                          return (<li title={item.trim()} key={'xve-'+i+'-'+props.vid}><i className="im im-air"></i><span className="booking-item-feature-title">{item.trim()}</span></li>)
                                      }
                                  break;
                                  case "Stereo CD/MP3":
                                      if(parseInt(price[i]) == 0){
                                          return (<li title={item.trim()} key={'xve-'+i+'-'+props.vid}><i className="im im-stereo"></i><span className="booking-item-feature-title">{item.trim()}</span></li>)
                                      }
                                  break;
                                  case "FM Radio":
                                      if(parseInt(price[i]) == 0){
                                          return (<li title={item.trim()} key={'xve-'+i+'-'+props.vid}><i className="im im-fm"></i><span className="booking-item-feature-title">{item.trim()}</span></li>)
                                      }

                                  break;
                                  case "Power Door Locks":
                                      if(parseInt(price[i]) == 0){
                                          return (<li  title={item.trim()} key={'xve-'+i+'-'+props.vid}><i className="im im-lock"></i><span className="booking-item-feature-title">{item.trim()}</span></li>)
                                      }

                                  break;
                                  case "Satellite Navigation":
                                      if(parseInt(price[i]) == 0){
                                          return (<li title={item.trim()} key={'xve-'+i+'-'+props.vid}><i className="im im-satellite"></i><span className="booking-item-feature-title">{item.trim()}</span></li>)
                                      }

                                  break;
                                  case "Universal power adapter":
                                      if(parseInt(price[i]) == 0){
                                          return (<li title={item.trim()} key={'xve-'+i+'-'+props.vid}><i className="im im-air"></i><span className="booking-item-feature-title">{item.trim()}</span></li>)
                                      }

                                  break;
                                  case "Power Windows":
                                      if(parseInt(price[i]) == 0){
                                          return (<li title={item.trim()} key={'xve-'+i+'-'+props.vid}><i className="im im-car-window"></i><span className="booking-item-feature-title">{item.trim()}</span></li>)
                                      }

                                  break;
                                  case "Tilt Steering Wheel":
                                      if(parseInt(price[i]) == 0){
                                          return (<li title={item.trim()} key={'xve-'+i+'-'+props.vid}><i className="im im-car-wheel"></i><span className="booking-item-feature-title">{item.trim()}</span></li>)
                                      }
                                  break;
                                  case "Climate Control":
                                      if(parseInt(price[i]) == 0){
                                          return (<li title={item.trim()} key={'xve-'+i+'-'+props.vid}><i className="im im-climate-control"></i><span className="booking-item-feature-title">{item.trim()}</span></li>)
                                      }

                                  break;
                                  default:
                                      if(parseInt(price[i]) == 0 && item.trim() != ""){
                                          return (<li title={item.trim()} key={'xve-'+i+'-'+props.vid}><i><CheckIcon style={{fill: "green"}} /></i><span className="booking-item-feature-title">{item.trim()}</span></li>)
                                      }


                              }
                        })
                  );



              }

          }

          function ListTitle(props){
              return (
                  <span className="list-title-price">{props.title}</span>
              )
          }
        const { photoIndex, isOpen } = this.state;

          return (
              <div>
                  <Helmet>
                      <meta charSet="utf-8" />
                      <meta name="title" content={this.state.MetaTitle} />
                      <meta content={this.state.MetaTitle} property="og:title" />
                      <meta name="description" content={this.state.MetaDescription} />
                      <meta content={this.state.MetaDescription} property="og:description" />
                      <meta name="keywords" content={this.state.MetaKeywords} />
                      <meta name="robots" content="index, follow" />
                      <title>{this.state.MetaTitle}</title>
                      <link rel="canonical" href={this.state.Canonical} />
                  </Helmet>
                  <Hidden mdUp>
                      <Fab aria-label="add"
                          style={{position:"fixed",bottom:"10px",right:"10px",zIndex:"10",background:"#2f96b4",color:"#fff" }}
                          onClick={this.toggleDateDialog}>
                            <DateRangeOutlinedIcon />
                      </Fab>
                  </Hidden>
                  {this.renderPopup()}
                  <div className="container">
                      <div className="booking-item-details">
                          <header className="booking-item-header">
                              <div className="row">
                                  <div className="col-md-9">
                                      <h2 className="lh1em">{this.state.details.product_name}</h2>
                                  </div>
                                  <div className="col-md-3 hidden">
                                      <p className="booking-item-header-price"><small><Rating  value={0} precision={0.5} readOnly /></small>  <span className="text-lg">{this.state.rating}</span>/rating</p>

                                  </div>
                              </div>
                          </header>
                          <div className="gap gap-small"></div>
                          <div className="row row-wrap">
                              <div className="col-md-8">
                                  <div className="row">
                                      <div className="col-md-12">
                                            <ImageGallery
                                                lazyLoad={true}
                                                showNav={false}
                                                thumbnailPosition={"bottom"}
                                                items={this.state.images}
                                                useBrowserFullscreen={false}
                                                showPlayButton={false}
                                            />
                                      </div>

                                  </div>
                                  <br />

                                  <Card>
                                      <CardContent>

                                          <Typography variant="h5" style={{color:"#2f96b4",fontWeight:"300",fontSize:"20px",borderBottom:"1px dashed #eee",paddingBottom:"5px"}}>
                                            <EmojiTransportationOutlinedIcon fontSize="medium" /> Vehicle Specs
                                          </Typography>

                                          <Grid container spacing={0} alignItems="center">
                                            <Grid item md={3} sm={12} xs={12} align="center">
                                                <List disablePadding className="product-info-list">
                                                    <ListItem >
                                                        <ListItemText
                                                        primary="Brand"
                                                        secondary={this.state.details.product_brand}
                                                        />
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid item md={4} sm={12} xs={12} align="center">
                                                <List disablePadding className="product-info-list">
                                                    <ListItem >
                                                        <ListItemText
                                                        primary="Model"
                                                        secondary={this.state.details.product_model}
                                                        />
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid item md={2} sm={12} xs={12} align="center">
                                                <List disablePadding className="product-info-list">
                                                    <ListItem >
                                                        <ListItemText
                                                        primary="Year"
                                                        secondary={this.state.details.product_registration_year}
                                                        />
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid item md={3} sm={12} xs={12} align="center">
                                                <List disablePadding className="product-info-list">
                                                    <ListItem >
                                                        <ListItemText
                                                        primary="Colour"
                                                        secondary={this.state.details.product_colour}
                                                        />
                                                    </ListItem>
                                                </List>
                                            </Grid>

                                           </Grid>
                                           <br/>
                                           <Typography variant="h5" style={{color:"#2f96b4",fontWeight:"300",fontSize:"20px",borderBottom:"1px dashed #eee",paddingBottom:"5px"}}>
                                             <InfoOutlinedIcon fontSize="medium" /> Insurance Excess
                                           </Typography>

                                           <Grid container spacing={0} alignItems="center" >
                                               <Grid item md={4} sm={12} xs={12} align="center">
                                                   <List disablePadding className="product-info-list">
                                                       <ListItem >
                                                           <ListItemText
                                                           primary="Age Between 24 and 60"
                                                           secondary={<NumberFormat value={this.state.details.product_insurance_excess_1} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'S$'} style={{fontSize:"20px"}} />}
                                                           />
                                                       </ListItem>
                                                   </List>
                                               </Grid>
                                               <Grid item md={4} sm={12} xs={12} align="center">
                                                   <List disablePadding className="product-info-list">
                                                       <ListItem >
                                                           <ListItemText
                                                           primary="Age < 24 or > 60"
                                                           secondary={<NumberFormat value={this.state.details.product_insurance_excess_2} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'S$'} style={{fontSize:"20px"}} />}
                                                           />
                                                       </ListItem>
                                                   </List>
                                               </Grid>
                                               <Grid item md={4} sm={12} xs={12} align="center">
                                                   <List disablePadding className="product-info-list">
                                                       <ListItem >
                                                           <ListItemText
                                                           primary="Own Damage"
                                                           secondary={<NumberFormat value={this.state.details.product_insurance_excess_3} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'S$'} style={{fontSize:"20px"}} />}
                                                           />
                                                       </ListItem>
                                                   </List>
                                               </Grid>


                                            </Grid>
                                           <br/>
                                           <Typography variant="h5" style={{color:"#2f96b4",fontWeight:"300",fontSize:"20px",borderBottom:"1px dashed #eee",paddingBottom:"5px"}}>
                                             <AssignmentIndOutlinedIcon fontSize="medium" /> Driver’s Requirements
                                           </Typography>
                                           <Grid container spacing={0} alignItems="center" >
                                               <Grid item md={6} sm={12} xs={12} align="center">
                                                   <List disablePadding className="product-info-list">
                                                       <ListItem >
                                                           <ListItemText
                                                           primary="Minimum Age"
                                                           secondary={this.state.details.product_min_age+" yr(s) old"}
                                                           />
                                                       </ListItem>
                                                   </List>
                                               </Grid>
                                               <Grid item md={6} sm={12} xs={12} align="center">
                                                   <List disablePadding className="product-info-list">
                                                       <ListItem >
                                                           <ListItemText
                                                           primary="Minimum Exp"
                                                           secondary={this.state.details.product_experience+" yr(s) of driving"}
                                                           />
                                                       </ListItem>
                                                   </List>
                                               </Grid>


                                            </Grid>
                                            <br/>
                                            <Typography variant="h5" style={{color:"#2f96b4",fontWeight:"300",fontSize:"20px",borderBottom:"1px dashed #eee",paddingBottom:"5px"}}>
                                              <AccountBalanceWalletOutlinedIcon fontSize="medium" /> Deposit Amount
                                            </Typography>
                                            <Grid container spacing={0} alignItems="center" >

                                                {parseFloat(this.state.dailyDeposit) > 0 && (
                                                    <Grid item md={4} sm={6} xs={6} align="center">
                                                        <List disablePadding className="product-info-list">
                                                            <ListItem >
                                                                <ListItemText
                                                                primary="Daily"
                                                                secondary=<NumberFormat style={{fontSize:"20px"}} value={this.state.dailyDeposit} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Grid>
                                                )}

                                                {parseFloat(this.state.weekendDeposit) > 0 && (
                                                    <Grid item md={4} sm={6} xs={6} align="center">
                                                        <List disablePadding className="product-info-list">
                                                            <ListItem >
                                                                <ListItemText
                                                                primary="Weekend"
                                                                secondary=<NumberFormat  style={{fontSize:"20px"}} value={this.state.weekendDeposit} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Grid>
                                                )}

                                                {parseFloat(this.state.weeklyDeposit) > 0 && (
                                                    <Grid item md={4} sm={6} xs={6} align="center">
                                                        <List disablePadding className="product-info-list">
                                                            <ListItem >
                                                                <ListItemText
                                                                primary="Weekly"
                                                                secondary=<NumberFormat style={{fontSize:"20px"}} value={this.state.weeklyDeposit} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Grid>
                                                )}

                                                {parseFloat(this.state.monthlyDeposit) > 0 && (
                                                    <Grid item md={4} sm={6} xs={6} align="center">
                                                        <List disablePadding className="product-info-list">
                                                            <ListItem >
                                                                <ListItemText
                                                                primary="Monthly"
                                                                secondary=<NumberFormat style={{fontSize:"20px"}} value={this.state.monthlyDeposit} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Grid>
                                                )}

                                                {parseFloat(this.state.oneYearDeposit) > 0 && (
                                                    <Grid item md={4} sm={6} xs={6} align="center">
                                                        <List disablePadding className="product-info-list">
                                                            <ListItem >
                                                                <ListItemText
                                                                primary="1 Year"
                                                                secondary=<NumberFormat style={{fontSize:"20px"}} value={this.state.oneYearDeposit} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Grid>
                                                )}

                                                {parseFloat(this.state.twoYearDeposit) > 0 && (
                                                    <Grid item md={4} sm={6} xs={6} align="center">
                                                        <List disablePadding className="product-info-list">
                                                            <ListItem >
                                                                <ListItemText
                                                                primary="2 Years"
                                                                secondary=<NumberFormat style={{fontSize:"20px"}} value={this.state.twoYearDeposit} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Grid>
                                                )}

                                             </Grid>

                                      </CardContent>
                                  </Card>

                                  <br />

                                  <hr />


                                  <div className="row row-wrap">
                                      <div className="col-md-3">
                                          <h5>Features</h5>
                                          <ul className="booking-item-features booking-item-features-expand clearfix">
                                              <li title="Passengers"><i className="fa fa-male"></i><span className="booking-item-feature-title">x {this.state.details.product_passengers}</span>
                                              </li>
                                              <li title="Doors"><i className="im im-car-doors"></i><span className="booking-item-feature-title">x {this.state.details.product_doors}</span>
                                              </li>
                                              {this.state.details.product_baggage > 0 &&
                                                  <li title="Baggage Quantity"><i className="fa fa-briefcase"></i><span className="booking-item-feature-title">x {this.state.details.product_baggage}</span>
                                                  </li>
                                              }

                                              <li title={this.state.details.product_transmission}><i className="im im-shift"></i><span className="booking-item-feature-title">{this.state.details.product_transmission}</span>
                                              </li>
                                              <li title={this.state.details.product_gas}><i className="im im-diesel"></i>
                                                  <span className="booking-item-feature-title">{this.state.details.product_gas}</span>
                                              </li>
                                          </ul>
                                      </div>
                                      <div className="col-md-5">
                                          <h5>Default Equipment</h5>
                                          <ul className="booking-item-features booking-item-features-expand clearfix">
                                              <Equipments equipments={this.state.details.xve_equipment} price={this.state.details.xve_price} vid={this.state.details.product_id} />
                                          </ul>
                                      </div>
                                      <div className="col-md-4">
                                          <h5>Available Rental</h5>
                                          <ul className="booking-item-features booking-item-features-expand booking-item-features-dark clearfix">
                                              <TermIcons terms={this.state.details.product_rent_terms} vid={this.state.details.product_id} />
                                          </ul>
                                      </div>
                                      <div className="col-md-12">
                                          <br />
                                          <Typography style={{whiteSpace:"pre-line",fontSize:"16px"}}>
                                            {this.state.details.product_description}
                                          </Typography>
                                      </div>

                                  </div>
                              </div>
                              <div className="col-md-4">
                                  <div className="booking-item-price-calc-cont booking-item-price-calc">
                                      {this.state.lType &&
                                          <>
                                              <Typography variant="h5" >
                                                Rental Period
                                                 <a className="popup-text pull-right" onClick={this.toggleDateDialog}>Change Date</a>
                                              </Typography>
                                              <Grid container spacing={3} alignItems="center" >
                                                <Grid item xs={5} align="center">
                                                  <Typography variant="h4">
                                                    <b>{ moment(this.state.startDate).format('MMM D, YYYY') }</b>
                                                  </Typography>
                                                  <Typography variant="body1" >
                                                    { moment(this.state.startDate).format('dddd') }
                                                  </Typography>
                                                </Grid>
                                                <Grid item xs={2} align="center">
                                                  <ArrowForwardIcon fontSize="large" />
                                                </Grid>
                                                <Grid item xs={5} align="center" >
                                                    <Typography variant="h4">
                                                    <b>{ moment(this.state.endDate).format('MMM D, YYYY') }</b>
                                                    </Typography>
                                                  <Typography variant="body1" >
                                                    { moment(this.state.endDate).format('dddd') }
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                              <br/>
                                              <Divider />
                                              <br/>
                                              {this.state.isLogin &&
                                                  <>
                                                      <List
                                                      onClick={this.handleOpenPromoCode}
                                                      style={{padding:"0px"}}
                                                    >
                                                      <ListItem  style={{padding:"0"}}>
                                                        <ListItemText >
                                                            <p style={{fontSize:"17px",padding:"0px"}}>Promo Code</p>
                                                        </ListItemText >
                                                        <ListItemSecondaryAction>
                                                            <IconButton edge="end" aria-label="expand" style={{marginTop:"-10px"}}>
                                                              {this.state.openPromoCode ? <ExpandLess style={{fontSize:"20px"}} /> : <ExpandMore style={{fontSize:"20px"}} />}
                                                            </IconButton>
                                                          </ListItemSecondaryAction>

                                                      </ListItem>

                                                    </List>
                                                      <Collapse in={this.state.openPromoCode} timeout="auto" unmountOnExit>
                                                          <form onSubmit={this.handlePromoCode }>
                                                              <Grid container alignItems="center" >
                                                                  <Grid item xs={7} align="center">
                                                                        <TextField
                                                                            name="promoCode"
                                                                            variant="outlined"
                                                                            inputProps={{style: {height: 0,textTransform:"uppercase"}}}
                                                                        />
                                                                  </Grid>

                                                                  <Grid item xs={5} align="center">
                                                                  {!this.state.checkingPromoCode &&
                                                                      <Button
                                                                      type="submit"
                                                                      variant="contained"
                                                                      color="primary"
                                                                      >
                                                                          Apply Code

                                                                      </Button>
                                                                  }
                                                                  {this.state.checkingPromoCode &&
                                                                      <Button
                                                                      type="submit"
                                                                      variant="contained"
                                                                      color="primary"
                                                                      endIcon={<CircularProgress size="1.5rem" />}
                                                                      >
                                                                          Apply Code

                                                                      </Button>
                                                                  }
                                                                  </Grid>

                                                                  {(this.state.currentPromoDiscount > 0) &&
                                                                      <Grid item xs={12}>
                                                                          <br />
                                                                          <Alert severity="success" style={{fontSize:"17px"}}>Promo Discount — <b><NumberFormat value={this.state.currentPromoDiscount} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'S$'} /></b></Alert>
                                                                      </Grid>
                                                                  }
                                                              </Grid>

                                                          </form>
                                                      </Collapse>
                                                       <br/>
                                                      <Divider />

                                                      <br/>
                                                  </>
                                              }

                                              <>
                                                  <Grid container alignItems="center" >
                                                      <Grid item xs={5} align="center">
                                                            <TextField
                                                                type="text"
                                                                value={this.state.loyaltyMiles}
                                                                readOnly
                                                                inputProps={{style: {fontSize: 22}}}
                                                                InputLabelProps={{style: {fontSize: 18}}}
                                                                label="Loyalty Miles"
                                                            />
                                                      </Grid>

                                                      <Grid item xs={7} align="center">
                                                          <Button
                                                          type="submit"
                                                          variant="contained"
                                                          color="primary"
                                                          onClick={this.handleConvertMiles}
                                                          >
                                                              Convert to credit

                                                          </Button>
                                                      </Grid>

                                                      <Grid item xs={12} align="left">
                                                          <p>You can earn 1 Mile Point per $1 on with every successful rental transaction</p>
                                                      </Grid>



                                                  </Grid>

                                                  <br/>
                                                  <Divider />
                                                  <br/>
                                              </>

                                              {(this.state.isLogin && this.state.creditBalance > 0) &&
                                                  <>
                                                      <Grid container alignItems="center" >
                                                          <Grid item xs={5} align="center">
                                                                <TextField
                                                                    name="promoCode"
                                                                    type="text"
                                                                    value={this.state.creditBalanceTemp}
                                                                    readOnly
                                                                    inputProps={{style: {fontSize: 22}}}
                                                                    InputLabelProps={{style: {fontSize: 18}}}
                                                                    label="Credit Balance"
                                                                />
                                                          </Grid>

                                                          <Grid item xs={2} align="center">
                                                                <ArrowForwardIcon fontSize="small" />
                                                          </Grid>


                                                          <Grid item xs={5} align="center">
                                                              <TextField
                                                                  type="number"
                                                                  value={this.state.creditBalanceUse}
                                                                  onChange={this.handleChangeCredit}
                                                                  inputProps={{style: {fontSize: 22,color:"green"}}}
                                                                  InputLabelProps={{style: {fontSize: 18}}}
                                                                  label="Credit To Use"
                                                              />
                                                          </Grid>


                                                      </Grid>

                                                      <br/>
                                                      <Divider />
                                                      <br/>
                                                  </>
                                              }

                                              <ul className="list">

                                                  {!this.state.currentRateTitle &&
                                                       <CircularProgress size="2rem" />
                                                  }

                                                  <li className="text-right">
                                                      <Typography variant="h5" >
                                                      {(this.state.currentPromoDiscount > 0 && (this.state.lType == "monthly" || this.state.lType == "1-year" || this.state.lType == "2-years"))
                                                          ?
                                                          <>
                                                              1st Month Rate
                                                          </>
                                                          :
                                                          <>
                                                              {this.state.currentRateTitle}
                                                          </>

                                                      }

                                                      </Typography>
                                                      <p>
                                                          {(this.state.currentPromoDiscount > 0) &&
                                                              <>
                                                                  <NumberFormat style={{fontSize:"20px",textDecoration: "line-through"}} className="list-price" value={this.state.currentTotalRateOrig} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'S$'} />
                                                                  <ArrowForwardIcon fontSize="small" />
                                                              </>
                                                          }

                                                        <NumberFormat style={{fontSize:"30px"}} className="list-price" value={this.state.currentTotalRate} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'S$'} />
                                                      </p>
                                                      {this.state.currentRateType == "Daily Rate" &&
                                                          <>
                                                              <small style={{fontSize:"15px"}}>({this.state.currentRateCount} x  {this.state.currentRateType}  <NumberFormat className="list-price" value={this.state.currentRateValue} displayType={'text'} thousandSeparator={true} prefix={'S$'} />)</small>
                                                          </>
                                                      }

                                                      {this.state.currentRateType == "Weekend Rate" &&
                                                          <>
                                                              <small style={{fontSize:"15px"}}>({this.state.currentRateCount} x  {this.state.currentRateType}  <NumberFormat className="list-price" value={this.state.currentRateValue} displayType={'text'} thousandSeparator={true} prefix={'S$'} />)</small>
                                                          </>
                                                      }

                                                      {this.state.currentRateType == "Weekly Rate" &&
                                                          <>
                                                              <small style={{fontSize:"15px"}}>({this.state.currentRateCount} x  {this.state.currentRateType}  <NumberFormat className="list-price" value={this.state.currentRateValue} displayType={'text'} thousandSeparator={true} prefix={'S$'} />)</small>
                                                          </>
                                                      }

                                                      {this.state.currentRateType == "Monthly Rate" &&
                                                          <>
                                                              <small style={{fontSize:"15px"}}>(Contract: {this.state.currentRateCount} x monthly rate  <NumberFormat className="list-price" value={this.state.currentRateValue} displayType={'text'} thousandSeparator={true} prefix={'S$'} />)</small>
                                                          </>
                                                      }

                                                      {this.state.currentRateType == "1-Yr Lease Rate" &&
                                                          <>
                                                              <small style={{fontSize:"15px"}}>(Contract: {this.state.currentRateCount} x monthly rate  <NumberFormat className="list-price" value={this.state.currentRateValue} displayType={'text'} thousandSeparator={true} prefix={'S$'} />)</small>
                                                          </>
                                                      }

                                                      {this.state.currentRateType == "2-Yr Lease Rate" &&
                                                          <>
                                                              <small style={{fontSize:"15px"}}>(Contract: {this.state.currentRateCount} x monthly rate  <NumberFormat className="list-price" value={this.state.currentRateValue} displayType={'text'} thousandSeparator={true} prefix={'S$'} />)</small>
                                                          </>
                                                      }

                                                      {parseInt(this.state.bonus_months) > 0 &&
                                                          <>
                                                              <Typography variant="h5" style={{textTransform:"capitalize"}}>
                                                                + {this.state.bonus_months} Month(s)
                                                              </Typography>
                                                              <p>
                                                                  <b style={{color:"#d40029"}}>
                                                                    FREE
                                                                  </b>
                                                              </p>
                                                          </>

                                                      }

                                                      {parseInt(this.state.bonus_days) > 0 &&
                                                          <>
                                                              <Typography variant="h5" style={{textTransform:"capitalize"}}>
                                                                + {this.state.bonus_days} Day(s)
                                                              </Typography>
                                                              <p>
                                                                  <b style={{color:"#d40029"}}>
                                                                    FREE
                                                                  </b>
                                                              </p>
                                                          </>

                                                      }


                                                      {this.state.currentDeposit > 0 &&
                                                          <>
                                                              <Typography variant="h6" >
                                                                Deposit
                                                              </Typography>
                                                              <p>
                                                                  <b>
                                                                    <NumberFormat style={{fontSize:"22px",color:"#2f96b4"}} className="list-price" value={this.state.currentDeposit} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'S$'} />
                                                                  </b>
                                                              </p>
                                                          </>
                                                      }



                                                  </li>
                                                  <li>
                                                      <p>
                                                          <span>
                                                              <AddShoppingCartIcon style={{fill: "grey"}} /> Add Ons
                                                          </span>

                                                      </p>
                                                      <Grid container spacing={0}>

                                                              { this.state.additionals.map((additional,i) =>
                                                                  <>
                                                                      <Grid item md={6} sm={6} xs={6}>
                                                                          <div className="checkbox" key={'aes-'+additional.id}>
                                                                              <label>
                                                                              <Checkbox
                                                                                 checkboxClass="i-check"
                                                                                 increaseArea="20%"
                                                                                 onChange={this.handleInputChangeAdditional.bind(this,i)}
                                                                                 name={additional.name}
                                                                                 checked={additional.checked}
                                                                                 label={<EllipsisText text={additional.name} length={21} />}
                                                                                 value={additional.qtyPrice}
                                                                                 cursor="pointer"
                                                                               />
                                                                               </label>
                                                                          </div>
                                                                      </Grid>
                                                                      <Grid item md={2} sm={3} xs={3}>

                                                                               <NumberFormat  value={additional.price} decimalScale={2} isNumericString={true} displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'S$'} />

                                                                      </Grid>
                                                                      <Grid item md={2} sm={3} xs={3}>

                                                                               <TextField
                                                                                   style={{marginTop:"-4px"}}
                                                                                   type="number"
                                                                                   value={additional.qty}
                                                                                   onChange={this.handleInputChangeQty.bind(this,i)}
                                                                                   InputProps={{
                                                                                    startAdornment: <InputAdornment position="start">x</InputAdornment>,
                                                                                    inputProps: { max: additional.maxqty, min: 1 }
                                                                                  }}
                                                                                   fullWidth
                                                                                   disabled={additional.disabled}
                                                                               />

                                                                      </Grid>

                                                                      <Grid item md={2} sm={12} xs={12} align="right">
                                                                          {additional.disabled &&
                                                                              <NumberFormat  value={additional.qtyPrice} decimalScale={2} isNumericString={true} displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'S$'} />
                                                                          }

                                                                          {!additional.disabled &&
                                                                              <NumberFormat  style={{fontWeight:"700",color:"#ed8323"}} value={additional.qtyPrice} decimalScale={2} isNumericString={true} displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'S$'} />
                                                                          }



                                                                      </Grid>
                                                                      <br />
                                                                      <br />
                                                                  </>
                                                                  )
                                                              }


                                                      </Grid>

                                                  </li>

                                                  <li className="text-right">
                                                      <Typography variant="h5" >
                                                        Total Rental
                                                      </Typography>
                                                      <p><b>
                                                            <NumberFormat style={{fontSize:"30px",color:"#d40029"}} className="list-price" value={this.state.rentalTotal} isNumericString={true} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'S$'} />
                                                      </b></p>

                                                  </li>
                                              </ul>
                                                {(this.state.currentRateType == "Monthly Rate" && this.state.currentRateCount > 1) &&
                                                    <>
                                                      <p>You will automatically charged <NumberFormat style={{color:"#d40029",fontWeight:"700"}} value={this.state.currentRateValue} displayType={'text'} thousandSeparator={true} prefix={'S$'} /> before the next month cycle</p>
                                                    </>
                                                }


                                                {this.state.currentRateType == "1-Yr Lease Rate" &&
                                                    <>
                                                        <p>You will automatically charged <NumberFormat style={{color:"#d40029",fontWeight:"700"}} value={this.state.currentRateValue} displayType={'text'} thousandSeparator={true} prefix={'S$'} /> before the next month cycle</p>
                                                    </>
                                                }


                                                {this.state.currentRateType == "2-Yr Lease Rate" &&
                                                    <>
                                                      <p>You will automatically charged <NumberFormat style={{color:"#d40029",fontWeight:"700"}} value={this.state.currentRateValue} displayType={'text'} thousandSeparator={true} prefix={'S$'} /> before the next month cycle</p>
                                                    </>
                                                }
                                            {(this.state.isLogin && this.state.profileComplete == "1") &&
                                                <NavLink to="/checkout"  className="btn btn-block btn-primary" onClick={this.handleCheckout}>Book Now</NavLink>
                                            }

                                            {(this.state.isLogin && this.state.profileComplete == "0") &&
                                                <NavLink to={"/user-settings?from="+this.state.details.product_name.replace(/\s+/g, '-').toLowerCase()} >
                                                    <Alert variant="filled" severity="warning" style={{fontSize:"15px"}}>
                                                      Kindly complete youre profile to proceed checkout
                                                    </Alert>
                                                </NavLink>
                                            }

                                            {!this.state.isLogin &&
                                                <NavLink to="/sign-in"  className="btn btn-block btn-primary">Book Now</NavLink>
                                            }
                                        </>
                                    }
                                    {!this.state.lType &&
                                        <>
                                            <Typography variant="h6">
                                              Leasing Rates
                                            </Typography>
                                            <List>
                                                {parseFloat(this.state.details.product_price_day) > 0 && (
                                                    <ListItem className="price-list">
                                                        <ListItemText edge="start"
                                                            primary={<ListTitle title="Daily Rate" />}
                                                        />
                                                        <ListItemSecondaryAction>
                                                            <NumberFormat className="list-price" value={this.state.details.product_price_day} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )}

                                                {parseFloat(this.state.details.product_price_weekend) > 0 && (
                                                    <ListItem className="price-list">
                                                        <ListItemText edge="start" primary={<ListTitle title="Weekend Rate" />} />
                                                        <ListItemSecondaryAction>
                                                            <NumberFormat className="list-price" value={this.state.details.product_price_weekend} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )}

                                                {parseFloat(this.state.details.product_price_week) > 0 && (
                                                    <ListItem className="price-list">
                                                        <ListItemText edge="start" primary={<ListTitle title="Weekly Rate" />} />
                                                        <ListItemSecondaryAction>
                                                            <NumberFormat className="list-price" value={this.state.details.product_price_week} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )}

                                                {parseFloat(this.state.details.product_price_month) > 0 && (

                                                    <>
                                                        {(this.state.details.month_bonus == "1" && this.state.details.product_month_bonus_rental_fee == "1")
                                                          ? <ListItem className="price-list">
                                                              <ListItemText edge="start" primary={<ListTitle title="Monthly Rate" />}secondary={
                                                                  <React.Fragment>
                                                                    <Typography
                                                                      component="span"
                                                                      variant="body2"
                                                                      style={{color:"#d40029",textTransform:"capitalize"}}
                                                                    >
                                                                     + Free {this.state.details.product_month_bonus_days_months_count} {this.state.details.product_month_bonus_type.replace("s", "(s)")} Rent
                                                                    </Typography>
                                                                  </React.Fragment>
                                                                } />
                                                              <ListItemSecondaryAction>
                                                                  <NumberFormat className="list-price" value={this.state.details.product_price_month} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                                              </ListItemSecondaryAction>
                                                          </ListItem>
                                                          :<ListItem className="price-list">
                                                              <ListItemText edge="start" primary={<ListTitle title="Monthly Rate" />} />
                                                              <ListItemSecondaryAction>
                                                                  <NumberFormat className="list-price" value={this.state.details.product_price_month} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                                              </ListItemSecondaryAction>
                                                          </ListItem>
                                                      }
                                                    </>
                                                )}

                                                {parseFloat(this.state.details.product_price_year) > 0 && (

                                                    <>
                                                        {(this.state.details.year_bonus == "1" && this.state.details.product_year_bonus_rental_fee == "1")
                                                          ? <ListItem className="price-list">
                                                              <ListItemText edge="start" primary={<ListTitle title="1-Yr Monthly Rate" />}secondary={
                                                                  <React.Fragment>
                                                                    <Typography
                                                                      component="span"
                                                                      variant="body2"
                                                                      style={{color:"#d40029",textTransform:"capitalize"}}
                                                                    >
                                                                     + Free {this.state.details.product_year_bonus_days_months_count} {this.state.details.product_year_bonus_type.replace("s", "(s)")} Rent
                                                                    </Typography>
                                                                  </React.Fragment>
                                                                } />
                                                              <ListItemSecondaryAction>
                                                                  <NumberFormat className="list-price" value={this.state.details.product_price_year} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                                              </ListItemSecondaryAction>
                                                          </ListItem>
                                                          :<ListItem className="price-list">
                                                              <ListItemText edge="start" primary={<ListTitle title="1-Yr Monthly Rate" />} />
                                                              <ListItemSecondaryAction>
                                                                  <NumberFormat className="list-price" value={this.state.details.product_price_year} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                                              </ListItemSecondaryAction>
                                                          </ListItem>
                                                      }
                                                    </>


                                                )}

                                                {parseFloat(this.state.details.product_price_year_2) > 0 && (
                                                    <>
                                                        {(this.state.details.year2_bonus == "1" && this.state.details.product_year2_bonus_rental_fee == "1")
                                                          ? <ListItem className="price-list">
                                                              <ListItemText edge="start" primary={<ListTitle title="2-Yr Monthly Rate" />}secondary={
                                                                  <React.Fragment>
                                                                    <Typography
                                                                      component="span"
                                                                      variant="body2"
                                                                      style={{color:"#d40029",textTransform:"capitalize"}}
                                                                    >
                                                                     + Free {this.state.details.product_year2_bonus_days_months_count} {this.state.details.product_year2_bonus_type.replace("s", "(s)")} Rent
                                                                    </Typography>
                                                                  </React.Fragment>
                                                                } />
                                                              <ListItemSecondaryAction>
                                                                  <NumberFormat className="list-price" value={this.state.details.product_price_year_2} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                                              </ListItemSecondaryAction>
                                                          </ListItem>
                                                          :<ListItem className="price-list">
                                                              <ListItemText edge="start" primary={<ListTitle title="2-Yr Monthly Rate" />} />
                                                              <ListItemSecondaryAction>
                                                                  <NumberFormat className="list-price" value={this.state.details.product_price_year_2} displayType={'text'} thousandSeparator={true} prefix={'S$'} />
                                                              </ListItemSecondaryAction>
                                                          </ListItem>
                                                      }
                                                    </>
                                                )}


                                            </List>
                                            <br />
                                            <Button variant="contained" size="large" color="primary" fullWidth onClick={this.toggleDateDialog}>
                                              Select a date
                                            </Button>
                                        </>
                                    }





                                  </div>
                                  <div className="gap gap-small"></div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <ScriptTag isHydrating={true} type="text/javascript" src="js/vehicle/vehicle.js" />
              </div>
          )
      }

  }
}
