import React from "react";
import {
  createMuiTheme,
  makeStyles,
} from '@material-ui/core/styles';
import { store } from 'react-notifications-component';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import StripeInput from '../checkout/StripeInput';

import { grey } from '@material-ui/core/colors';
import CustomCircularProgress from "../widgets/CircularProgress";

const theme = createMuiTheme({
  typography: {
    fontSize: 18,
  },
});

const componentStyles = makeStyles((theme) => ({
  layout: {
    padding: theme.spacing(5, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
    }
  },
  title: {
    fontWeight: 300,
    marginBottom: theme.spacing(3),
  },
  cardsDropdown: {
    color: grey[800],
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '& .MuiFormLabel-root': {
      fontSize: '1.7rem',
      marginBottom: theme.spacing(-2),
      '&.Mui-focused': {
        color: grey[800],
      },
    },
    '& .MuiFormControl-root': {
      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        '&:hover fieldset': {
          borderColor: '#B4B4B4',
        },
        '&.Mui-focused fieldset': {
          borderColor: "#2F96B4",
        },
      },
    },
  },
  cardsDropdownMenuItem: {
    padding: theme.spacing(2),
    '& span': {
      fontSize: '1.25rem',
    }
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  paymentForm: {
    color: grey[800],
    '& .MuiFormControlLabel-root': {
      marginBottom: 0,
    },
    '& .MuiFormControl-root': {
      '& .MuiFormLabel-root': {
        backgroundColor: 'white',
        '&.Mui-focused': {
          color: "#2F96B4",
        },
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        '&:hover fieldset': {
          borderColor: '#B4B4B4',
        },
        '&.Mui-focused fieldset': {
          borderColor: "#2F96B4",
        },
        '& input': {
          fontFamily: "sans-serif",
          fontSize: "14px",
        },
      },
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    color: '#FFF',
    backgroundColor: '#262626',
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  gridItem1: {
    order: 2,
    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  gridItem2: {
    order: 1,
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
  },
  paymentProcessing: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: grey[200] + '!important',
    height: 3,
  },
}));

const SetupCard = (props) => {
  const api = {
    siteUrl: "https://earental.com",
    url: "https://api.earental.com",
    key: "72ffc1303c7c0286887a3276f0ab61b8",
  };
  const styles = componentStyles();
  const stripe = useStripe();
  const elements = useElements();
  const inputStyle = {
    base: {
      // fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
  };
  const [isInitializing, setInitializing] = React.useState(true);
  const [isSetupProcessing, setSetupProcessing] = React.useState(false);
  const [cardholdersName, setCardholdersName] = React.useState("");
  const [setupError, setSetupError] = React.useState("");

  React.useEffect(() => {
    if (stripe && isInitializing) {
      setInitializing(false);
    }
  });

  const handleSetCardholdersName = (event) => {
    const target = event.target;
    setCardholdersName(target.value);
  }

  const handleSetup = async (e) => {
    e.preventDefault();

    setSetupError("");
    setSetupProcessing(true);

    let formData = new FormData();
    formData.append('api_key', api.key);
    formData.append('access_token', localStorage.getItem('ctoken'));
    formData.append('jham_token', localStorage.getItem('token'));

    axios({
      method: 'post',
      url: api.url + '/transaction/initiate_card_setup',
      data: formData,
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(async (response) => {
        let reload = false;
        let redirect = false;
        let redirectURL = "";
        let notifTitle = "";
        let notifMessage = "";
        let notifType = "danger";

        if (response.data.error == 0) {
          // Use new card
          if (cardholdersName == "") {
            setSetupError("Enter cardholder's name.");
            setSetupProcessing(false);
            return false;
          }
          const cardElement = elements.getElement(CardNumberElement);
          const stripeDataObject = {
            payment_method: {
              card: cardElement,
              billing_details: {
                name: cardholdersName,
              }
            }
          };

          const { setupIntent, error } = await stripe.confirmCardSetup(response.data.client_secret, stripeDataObject);
          if (error && error.type == "validation_error") {
            setSetupError(error.message);
            setSetupProcessing(false);
          } else if (error && error.type === "card_error") {
            // props.onFailed(error);
            setSetupError(error.message);
            setSetupProcessing(false);
          } else if (setupIntent && setupIntent.status === "succeeded") {
            props.onSuccess(setupIntent);
          }
        } else {
          switch (response.data.error_type) {
            case "validation_error":
              function ErrorMsg(props) {
                return (
                  props.msgs.map((item, i) =>
                    <li key={"err-" + item.i}>{item.value}</li>
                  )
                );
              }
              notifTitle = "Invalid Request";
              notifMessage = <ErrorMsg msgs={response.data.error_msg} />;
              notifType = "danger";
              setSetupProcessing(false);
              break;
            case "card_setup_error":
              notifTitle = "An error has occurred";
              notifMessage = response.data.error_msg;
              notifType = "danger";
              setSetupProcessing(false);
              break;
            case "authorization_error":
              redirect = true;
              redirectURL = "/signin";
              notifTitle = "Your session has expired";
              notifMessage = "Please login again. This page will automatically reload.";
              notifType = "danger";
              break;
            default:
              notifTitle = "Something went wrong";
              notifMessage = response.data.error_msg;
              notifType = "danger";
              break;
          }
          if (reload == true) {
            setTimeout(function () {
              window.location.href = redirectURL;
            }.bind(this), 3000);
          }
          if (redirect == true) {
            setTimeout(function () {
              props.history.push(redirectURL);
            }.bind(this), 3000);
          }
        }

        showNotification(notifTitle, notifMessage, notifType); // Show error notification
      })
      .catch(error => {
        if (error.response && error.response.status == 403) {
          let errorTitle = "Oops Something went wrong";
          let errorMessage = "The action you have requested is not allowed. Please reload the page";
          showNotification(errorTitle, errorMessage, "danger"); // Show error notification
        }
      });
  }

  return (
    <React.Fragment>
      {isSetupProcessing && (
        <Box className={styles.paymentProcessing}>
          <Box className={styles.loadingWrapper} style={{ height: '100%' }}>
            <CustomCircularProgress />
          </Box>
        </Box>
      )}
      {isInitializing &&
        <Box className={styles.loadingWrapper} style={{ height: '100%' }}>
          <CustomCircularProgress />
        </Box>
      }
      {!isInitializing &&
        <form onSubmit={handleSetup} className={styles.paymentForm}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                id="cardholders-name"
                name="cardholders-name"
                label="Cardholder's Name"
                autoComplete="off"
                size="small"
                value={cardholdersName}
                onChange={handleSetCardholdersName}
                InputLabelProps={{ shrink: true }}
                placeholder="Name on card"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                id="credit-card-number"
                name="credit-card-number"
                label="Credit Card Number"
                autoComplete="off"
                size="small"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardNumberElement,
                    options: {
                      placeholder: "4242 4242 4242 4242",
                      style: inputStyle
                    },
                  },
                }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                id="expiry-date"
                name="expiry-date"
                label="Expiry Date"
                autoComplete="off"
                size="small"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardExpiryElement,
                    options: { style: inputStyle },
                  },
                }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                id="cvc"
                name="cvc"
                label="Verification Code"
                autoComplete="off"
                size="small"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardCvcElement,
                    options: { style: inputStyle },
                  },
                }}
                fullWidth
                required
              />
            </Grid>
            {setupError != "" && (
              <Grid item xs={12}>
                <Alert variant="outlined" severity="error">
                  {setupError}
                </Alert>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box textAlign="right">
                <Button type="submit" variant="contained" color="primary" disabled={isSetupProcessing}>
                  {!isSetupProcessing ? "Save" : "Please wait"}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      }
    </React.Fragment>
  )
}

export default function NewCardForm(props) {
  const styles = componentStyles();
  const [isLoading, setLoading] = React.useState(true);
  const [stripePromise, setStripePromise] = React.useState(null);

  React.useEffect(() => {
    if (props.publishableKey != "") {
      const stripe = loadStripe(props.publishableKey);
      setStripePromise(stripe);
      setLoading(false);
    }
  }, [props.publishableKey]);

  return (
    <Paper elevation={0}>
      {isLoading && (
        <Box className={styles.loadingWrapper}>
          <CustomCircularProgress />
        </Box>
      )}
      {!isLoading && stripePromise && (
        <Elements stripe={stripePromise}>
          <SetupCard onSuccess={props.onSuccess} onFailed={props.onFailed} />
        </Elements>
      )}
    </Paper>
  )
}

const showNotification = (title, message, type) => {
  store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: false
    }
  });
}