import React, { Component } from "react";
import GoogleMapReact from 'google-map-react';

import parallaxBg1 from './../assets/img/contact-us.jpg';
import ScriptTag from 'react-script-tag';

import axios, { post } from 'axios';
import { store } from 'react-notifications-component';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { ReCaptcha } from 'react-recaptcha-google';
import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";
import "./../assets/css/contact.css";


var parallaxBgStyle = {
  backgroundImage: "url(" + parallaxBg1 + ")"
};

const MapComponent = ({ text }) => <div>{text}</div>;
const renderMarkers = (map, maps) => {
     let marker = new maps.Marker({
         position: { lat: 1.4382, lng: 103.7890 },
         map
     });
     return marker;
};

class Contact extends Component {

    constructor (props) {
        ReactGA.set({ page: window.location.pathname });
        ReactGA.pageview(window.location.pathname);

        super(props)
        this.state = {
            openSb: false,
            sendDisabled:false,
            captchaToken: false,
            MetaTitle:"EARental Singapore Online Van, Truck, Lorry Rental/Hire/Lease",
            MetaDescription:"EARental is Singapore No.1 Online Van, Lorry & Truck commercial vehicles for lease for businesses and individuals. Hassle-Free, book and reserve your vehicle Online Now!",
            MetaKeywords:"online rental, van rental, lorry rental, truck rental, commercial vehicles for rent, leasing, daily rental, weekly rental, weekend rental, monthly rental, yearly rental, short term rental, long term rental, cargo van rental singapore, cheap van rental, van rental price, Toyota hiace van rental singapore, van rental daily, auto van rental singapore, commercial van for rent, van rental near me, daily van rental singapore",
            Canonical:"https://www.earental.com/contact",
        };
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.onContactSubmit = this.onContactSubmit.bind(this);
        this.handleCloseSb = this.handleCloseSb.bind(this);
    }


    handleCloseSb(){
        this.setState({
          openSb:false,
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        let main = this.state.startDate
        console.log(main.format('L'));
    }

    onLoadRecaptcha() {
        if (this.captchaForgot) {
            this.captchaForgot.reset();
            this.setState({ captchaToken : false });
        }
    }

    verifyCallback(recaptchaToken) {
      this.setState({ captchaToken : recaptchaToken });

    }

    onContactSubmit(event){
        event.preventDefault();
        if(this.state.captchaToken){
            this.setState({
              sendDisabled:true,
            })

            var bodyFormData = new FormData();
            var token = localStorage.getItem('token');
            var ctoken = localStorage.getItem('ctoken');


            bodyFormData.append('cm_subject', event.target.subject.value);
            bodyFormData.append('cm_name', event.target.name.value);
            bodyFormData.append('cm_phone_number', event.target.mobile.value);
            bodyFormData.append('cm_email', event.target.email.value);
            bodyFormData.append('cm_message', event.target.message.value);
            bodyFormData.append('jham_token', token);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


            axios({
                  method: 'post',
                  url: 'https://api.earental.com/contact_us/contact_us',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {

                  if(response.data.error > 0){
                      function ErrorMsg(props) {
                        return (
                            props.msgs.map((item,i) =>
                                <li key={"err-"+item.i}>{item.value}</li>
                            )

                        );
                      }
                      store.addNotification({
                        title: "Invalid information",
                        message: <ErrorMsg msgs={response.data.error_message} />,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 20000,
                          onScreen: false
                        }
                      });
                  }else{
                     this.setState({
                         openSb: true,
                         captchaToken: false
                     });
                     this.captchaForgot.reset();
                     document.getElementById("contact-form").reset();


                  }

                  this.setState({
                    sendDisabled:false,

                  })




              })
              .catch((error) => {
                  //handle error
                  console.log(error);
                  this.setState({
                    sendDisabled:false,
                    captchaToken: false
                  })

                  this.captchaForgot.reset();

            });

        }else{
            store.addNotification({
              title: "Prove your not a robot",
              message: "Kindly check the captcha",
              type: "warning",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: false
              }
            });
        }


    }

    static defaultProps = {
        center: {
        lat: 1.4382,
        lng: 103.7890
        },
        zoom: 16
    };

  render() {

      function Alert(props) {
          return <MuiAlert elevation={6} variant="filled" {...props} />;
      }

    return (
        <div className="contact-cont">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="title" content={this.state.MetaTitle} />
                <meta content={this.state.MetaTitle} property="og:title" />
                <meta name="description" content={this.state.MetaDescription} />
                <meta content={this.state.MetaDescription} property="og:description" />
                <meta name="keywords" content={this.state.MetaKeywords} />
                <meta name="robots" content="index, follow" />
                <title>{this.state.MetaTitle}</title>
                <link rel="canonical" href={this.state.Canonical} />
            </Helmet>
            <div className="bg-holder">
                <div className="bg-mask"></div>
                <div className="bg-blur" style={parallaxBgStyle}></div>
                <div className="bg-content">
                    <div className="container">
                        <div className="gap gap-big text-center text-white">
                            <h2 className="text-uc mb20">Contact Us</h2>
                        </div>
                    </div>
                </div>
            </div>

    		<div className="gap"></div>
            <div className="container text-center">
                <div className="row">
                    <div className="col-md-12">
    					<p className="text-bigger">Interested in learning more about our services? <br/>We are here to help you make smart decisions that best meet your needs!</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="gap"></div>
                <div className="row">
                    <div className="col-md-7">
    					<div id="map-canvas">
                            <GoogleMapReact
                              bootstrapURLKeys={{ key:"AIzaSyClYtga-i9DLdSi6S3LtlD1hhrJteAx41Y&v=quarterly" }}
                              defaultCenter={this.props.center}
                              defaultZoom={this.props.zoom}
                              onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                            >
                              <MapComponent
                                lat={1.4382}
                                lng={103.7890}
                              />
                            </GoogleMapReact>
                        </div>
                    </div>
                    <div className="col-md-4">
    						<form className="mt30" id="contact-form" onSubmit={this.onContactSubmit}>
    							<div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Subject</label>
                                            <select className="form-control" name="subject" required>
                                                <option value="General Inquiry">General Inquiry</option>
                                                <option value="Feedback">Feedback</option>
                                                <option value="Complaint">Complaint</option>
                                            </select>
                                        </div>
                                    </div>
    								<div className="col-md-12">
    									<div className="form-group">
    										<label>Name</label>
    										<input className="form-control" type="text" name="name" required />
    									</div>
    								</div>
    								<div className="col-md-6">
    									<div className="form-group">
    										<label>Phone Number</label>
    										<input className="form-control" type="text" name="mobile" required />
    									</div>
    								</div>
    								<div className="col-md-6">
    									<div className="form-group">
    										<label>E-mail</label>
    										<input className="form-control" type="text" name="email" required />
    									</div>
    								</div>
    								<div className="col-md-12">
    									<div className="input-daterange" data-date-format="M d, D">
    										<div className="form-group">
    											<label>Message</label>
    											<textarea className="form-control" name="message" required ></textarea>
    										</div>
    									</div>
    								</div>
    							</div>
                                <ReCaptcha
                                    ref={(el) => {this.captchaForgot = el;}}
                                    size="normal"
                                    data-theme="dark"
                                    render="explicit"
                                    sitekey="6LcfD8cZAAAAADNcsNB5_Cs65fhs20WhBmuY7-hD"
                                    onloadCallback={this.onLoadRecaptcha}
                                    verifyCallback={this.verifyCallback}
                                />
                                <br />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={this.state.sendDisabled}
                                    >

                                    {!this.state.sendDisabled && (
                                        <>
                                            Send Message
                                        </>

                                    )}

                                    {this.state.sendDisabled && (
                                        <>
                                            Sending...
                                        </>

                                    )}


                                </Button>
    						</form>
                    </div>
                </div>
                <div className="gap"></div>
    			<div className="row">
    				<div className="col-md-4">
    					<p className="text-bigger">Email</p>
    					<span><a href="#">support@EARental.com</a></span>
    					<br/><br/>
    				</div>
    				<div className="col-md-4">
    					<p className="text-bigger">Phone Number</p>
    					<span><a href="#">+65 6235 1780</a></span>
    					<br/><br/>
    				</div>
    				<div className="col-md-4">
    					<p className="text-bigger">Address</p>
    					<span>207 Woodlands Avenue 9, #06-56 Woodlands Spectrum 2, Singapore 738958<br /></span>
    					<br/><br/>
    				</div>

    			</div>

            </div>
            <Snackbar open={this.state.openSb} autoHideDuration={6000} onClose={this.handleCloseSb}>
                <Alert onClose={this.handleCloseSb} severity="success" className="sb-msg">
                    Inquiry successfully sent...
                </Alert>
            </Snackbar>
        </div>
    );
  }
}

export default Contact;
