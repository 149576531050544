import React, { Component } from 'react';
import {
  NavLink,
  HashRouter
} from "react-router-dom";
import axios, { post } from 'axios';
import { store } from 'react-notifications-component';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import { ReCaptcha } from 'react-recaptcha-google';
import { loadProgressBar } from 'axios-progress-bar'
import ReactGA from 'react-ga';
import "./assets/css/login.css";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const styles = theme => ({
    paper: {
      marginTop: '64px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: '8px',
    },
    submit: {
      margin: "24px 0px 16px",
    },
});

class Forgotpassword extends React.Component {
  constructor(props, context) {
    super(props, context);
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);

    this.state = {
        captchaToken: false,

    };

    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);

  }

  onFormSubmit(event){
    event.preventDefault();

    if(this.state.captchaToken){
        loadProgressBar();

        var bodyFormData = new FormData();
        var token = localStorage.getItem('token');
        this.setState({ loading: true });
        bodyFormData.append('email', event.target.email.value);
        bodyFormData.append('jham_token', token);
        var email = event.target.email.value;

        axios({
                method: 'post',
                url: 'https://api.earental.com/account/forgotpass_api',
                data: bodyFormData,
                withCredentials: true,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then((response) => {
               if(response.data.status > 0){
                   store.addNotification({
                     title: "Email Sent",
                     message: "Kindly check your email, "+email,
                     type: "success",
                     insert: "top",
                     container: "top-right",
                     animationIn: ["animated", "fadeIn"],
                     animationOut: ["animated", "fadeOut"],
                     dismiss: {
                       duration: 5000,
                       onScreen: false
                     }
                   });
                   document.getElementById("forgot-form").reset();
                   this.captchaForgot.reset();
               }else{
                   store.addNotification({
                     title: "Error",
                     message: response.data.data,
                     type: "danger",
                     insert: "top",
                     container: "top-right",
                     animationIn: ["animated", "fadeIn"],
                     animationOut: ["animated", "fadeOut"],
                     dismiss: {
                       duration: 5000,
                       onScreen: false
                     }
                   });
                  //  this.setState({ redirect: true })

               }

            })
            .catch((error) => {

                if (error.response.status == 403) {

                    store.addNotification({
                      title: "Oops Something went wrong",
                      message: "The action you have requested is not allowed. please re-load the page",
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: false
                      }
                    });

               }

        });
    }else{
        store.addNotification({
          title: "Prove your not a robot",
          message: "Kindly check the captcha",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: false
          }
        });
    }


  }

  componentDidMount() {
    if (this.captchaForgot) {
        console.log("started, just a second...")
        this.captchaForgot.reset();
    }
  }
  onLoadRecaptcha() {
      if (this.captchaForgot) {
          this.captchaForgot.reset();
          this.setState({ captchaToken : false });
      }
  }

  verifyCallback(recaptchaToken) {
    this.setState({ captchaToken : recaptchaToken });

  }
  render() {
      const { classes } = this.props;
    return (
      <Container component="main" maxWidth="xs" className="login-cont">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h3">
            Recover Password
          </Typography>
          <Typography component="h1" variant="h6">
            Please enter the e-mail you've used to sign up.
          </Typography>
          <form id="forgot-form" className={classes.form} noValidate onSubmit={this.onFormSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <ReCaptcha
                ref={(el) => {this.captchaForgot = el;}}
                size="normal"
                data-theme="dark"
                render="explicit"
                sitekey="6LcfD8cZAAAAADNcsNB5_Cs65fhs20WhBmuY7-hD"
                onloadCallback={this.onLoadRecaptcha}
                verifyCallback={this.verifyCallback}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Send Request
            </Button>

          </form>
        </div>
        <div className="gap"></div>

      </Container>
    );
  };
};

Forgotpassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Forgotpassword);
