import React from "react";
import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider
} from '@material-ui/core/styles';
import axios from 'axios';
import ReactGA from 'react-ga';
import { store } from 'react-notifications-component';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CheckoutDetails from './CheckoutDetails';
import CheckoutStepper from './CheckoutStepper';
import CheckoutForm from "./CheckoutForm";
import CustomCircularProgress from "../widgets/CircularProgress";
import { orange, grey } from '@material-ui/core/colors';

import "../assets/css/checkout.css";

const useStyles = theme => ({
  layout: {
    padding: theme.spacing(5, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
    }
  },
  title: {
    fontWeight: 300,
    marginBottom: theme.spacing(3),
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    color: "#FFF",
    backgroundColor: "#262626",
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  gridItem1: {
    order: 2,
    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  gridItem2: {
    order: 1,
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
  },
});

const theme = createMuiTheme({
  typography: {
    fontSize: 18,
  },
});

class Payment extends React.Component {
  constructor(props) {
    super(props);

    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);

    this.api = {
      url: props.mainState.apiURL,
      key: props.mainState.apiKey,
      accessToken: localStorage.getItem('ctoken'),
      jhamToken: localStorage.getItem('token'),
      // url: "https://api.earental.com",
      // jhamToken: "7753531b4957b2ebf2c9f1cb346a0b34",
    };

    this.state = {
      loading: true,
      stripePublishableKey: "",
      stripeClientSecret: "",
      paymentType: "one-time",
      billingAddress: null,
      vehicle: null,
      schedule: null,
      popover: null,
      pending: null,
      rawCheckout: null,
      cancellationFee: 0,
      checkoutStepsCompleted: [],
    };
  };

  componentDidMount() {
    // Unset checkout progress
    localStorage.removeItem('checkoutProgress');
    this.props.handlerState('checkoutProgress', null);

    let formData = new FormData();
    formData.append('api_key', this.api.key);
    formData.append('access_token', localStorage.getItem('ctoken'));
    formData.append('jham_token', localStorage.getItem('token'));
    formData.append('for_payment', true);

    // Check for pending payment
    axios({
      method: 'post',
      url: this.api.url + '/transaction/pending',
      data: formData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
      .then((response) => {
        if (response.data.error == 0) {
          if (response.data.transaction) {
            let transaction = response.data.transaction;
            let cancellationFee = response.data.cancellation_fee;
            let paymentType = "one-time";
            if (transaction.s_payment_mode == "monthly" || transaction.s_payment_mode == "1-year" || transaction.s_payment_mode == "2-years") {
              paymentType = "recurring";
            }
            this.setState({
              stripePublishableKey: response.data.payment_params.publishable_key,
              stripeClientSecret: response.data.payment_params.client_secret,
              pending: transaction,
              cancellationFee: cancellationFee,
              paymentType: paymentType,
            });

            // Get current checkout data
            axios({
              method: 'post',
              url: this.api.url + '/transaction/current_checkout',
              data: formData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data'}
            })
              .then((response) => {
                if (response.data.status == 1) {
                  var checkout = response.data.checkout;
                  var vehicleData = {
                    name: checkout.data[0].product_name,
                    image: checkout.data[0].product_image,
                  };
                  var scheduleData = {
                    startDate: checkout.date_from,
                    endDate: checkout.date_to,
                    rateTitle: checkout.summary.currentRateTitle,
                    rateSubTitle: checkout.summary.currentRateSubTitle,
                    rateCount: checkout.summary.currentRateCount,
                    rateType: checkout.summary.currentRateType,
                    rate: parseFloat(transaction.s_lease_rate),
                    total: parseFloat(checkout.summary.currentTotalRate),
                    deposit: parseFloat(transaction.s_deposit_amount),
                    totalAddons: parseFloat(transaction.s_addons_price),
                    rentalTotal: parseFloat(transaction.s_payable_amount),
                    discountAmount: parseFloat(transaction.s_discount) + parseFloat(transaction.s_credit_balance_use),
                    pay1stMonth: transaction.pay_1st_month == true ? true : false,
                    freeDays: parseInt(checkout.bonus_day),
                    freeMonths: parseInt(checkout.bonus_month),
                  };
                  var billingAddressData = checkout.billingAddress;
                  billingAddressData.addressLine1 = this.buildAddressLine1(billingAddressData);
                  billingAddressData.addressLine2 = this.buildAddressLine2(billingAddressData);
                  // Get completed checkout steps
                  var checkoutStepsCompleted = [];
                  if (checkout.billingAddress) {
                    checkoutStepsCompleted.push(1);
                  } else {
                    this.props.history.push('/checkout');
                  }
                  if (checkout.selectedDrivers) {
                    checkoutStepsCompleted.push(2);
                  } else {
                    this.props.history.push('/checkout/driver');
                  }
                  if (checkout.leasingAgreement) {
                    checkoutStepsCompleted.push(3);
                  } else {
                    this.props.history.push('/checkout/agreement');
                  }
                  if (checkout.insuranceAgreement) {
                    checkoutStepsCompleted.push(4);
                  } else {
                    this.props.history.push('/checkout/insurance');
                  }
                  this.setState({
                    loading: false,
                    billingAddress: billingAddressData,
                    vehicle: vehicleData,
                    schedule: scheduleData,
                    checkoutStepsCompleted: checkoutStepsCompleted,
                    rawCheckout: checkout,
                  });
                } else {
                  var redirect = false;
                  var redirectURL = "";
                  var errorTitle = "";
                  var errorMsg = "";
                  switch (response.data.error) {
                    case 1:
                    case 2:
                      redirect = true;
                      redirectURL = "/sign-in";
                      errorTitle = "Your session has expired";
                      errorMsg = "Please login again. This page will automatically reload.";
                      break;
                    case 3:
                      redirect = true;
                      redirectURL = "/checkout";
                      errorTitle = "Something went wrong";
                      errorMsg = response.data.error_msg + " This page will automatically reload.";
                      break;
                    default:
                      break;
                  }

                  // Show error notification
                  store.addNotification({
                    title: errorTitle,
                    message: errorMsg,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 3000,
                      onScreen: false
                    }
                  });
                  // Redirect
                  if (redirect == true) {
                    setTimeout(function() {
                      this.props.history.push(redirectURL);
                    }.bind(this), 3000);
                  }
                  return false;
                }
              })
              .catch(error => {
                  // handle error
                  if (error.response && error.response.status == 403) {
                    store.addNotification({
                      title: "Oops Something went wrong",
                      message: "The action you have requested is not allowed. please reload the page",
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: false
                      }
                    });
                  }
              });
          } else {
            let notifTitle = "No pending payment";
            let notifMessage = "This page will automatically redirect.";
            let notifType = "warning";
            showNotification(notifTitle, notifMessage, notifType);
            setTimeout(() => {
              localStorage.removeItem('checkoutProgress');
              this.props.handlerState('checkoutProgress', null);
              window.location.href = "/";
            }, 3000);
          }
        } else {
          var redirect = false;
          var redirectURL = "";
          var errorTitle = "";
          var errorMsg = "";
          switch (response.data.error) {
            case 1:
            case 2:
              redirect = true;
              redirectURL = "/sign-in";
              errorTitle = "Your session has expired";
              errorMsg = "Please login again. This page will automatically reload.";
              break;
            case 3:
              redirect = true;
              redirectURL = "/checkout";
              errorTitle = "Something went wrong";
              errorMsg = response.data.error_msg + " This page will automatically reload.";
              break;
            default:
              break;
          }
          showNotification(errorTitle, errorMsg, "danger");
          // Redirect
          if (redirect == true) {
            setTimeout(function() {
              this.props.history.push(redirectURL);
            }.bind(this), 3000);
          }
          return false;
        }
      })
      .catch(error => {
          // handle error
          if (error.response && error.response.status == 403) {
            let notifTitle = "Something went wrong";
            let notifMessage = "The action you have requested is not allowed. Please reload the page";
            let notifType = "danger";
            showNotification(notifTitle, notifMessage, notifType);
          }
      });

    // Set checkout progress
    localStorage.setItem('checkoutProgress', 5);
    this.props.handlerState('checkoutProgress', 5);
  }

  handlePaymentSuccess = (paymentIntentID) => {
    this.setState({
      loading: true,
    });
    let billingAddress = this.buildAddress(this.state.billingAddress);
    let formData = new FormData();
    formData.append('api_key', this.api.key);
    formData.append('access_token', this.api.accessToken);
    formData.append('jham_token', this.api.jhamToken);
    formData.append('payment_intent_id', paymentIntentID);
    formData.append('billing_purpose', this.state.billingAddress.purpose);
    formData.append('billing_fname', this.state.billingAddress.firstName);
    formData.append('billing_lname', this.state.billingAddress.lastName);
    formData.append('billing_address', billingAddress);
    formData.append('billing_company', this.state.billingAddress.company);
    formData.append('billing_roc', this.state.billingAddress.roc);
    formData.append('billing_telephone', this.state.billingAddress.telephone);
    formData.append('billing_mobile', this.state.billingAddress.mobile);
    formData.append('remember_card', localStorage.getItem('rememberCard'));

    axios({
      method: 'post',
      url: this.api.url + '/transaction/stripe_payment',
      data: formData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      if (response.data.status == 1 && response.data.error == 0) {
        ReactGA.event({
          category: 'Checkout',
          action: 'Payment',
        });
        localStorage.setItem('paymentSuccessful', 1);
        localStorage.setItem('paymentType', this.state.paymentType);
        localStorage.setItem('paymentMethod', "stripe");
        localStorage.setItem('paymentAmount', response.data.transaction.s_payable_amount);
        localStorage.setItem('cancellationFee', this.state.cancellationFee);
        localStorage.removeItem('checkout');
        localStorage.removeItem('currrent_vehicle');
        localStorage.removeItem('checkoutProgress');
        localStorage.removeItem('checkoutCompleted');
        localStorage.removeItem('hasPendingPayment');
        this.props.handlerState('checkoutProgress', null);
        // this.props.history.push('/checkout/payment/success');
        window.location.href = "/checkout/payment/success";
      } else if (response.data.status == 0 && response.data.error == 4) {
        localStorage.setItem('paymentFailed', 1);
        this.props.history.push('/checkout/payment/failed');
      } else {
        var reload = false;
        var redirect = false;
        var redirectURL = "";
        var errorTitle = "";
        var errorMsg = "";
        switch (response.data.error) {
          case 1:
          case 2:
            reload = true;
            redirect = true;
            redirectURL = "/signin";
            errorTitle = "Your session has expired";
            errorMsg = "Please login again. This page will automatically reload.";
            break;
          case 3:
            reload = true;
            redirect = true;
            redirectURL = "/";
            errorTitle = "Something went wrong";
            errorMsg = response.data.error_msg + " This page will automatically reload.";
            break;
          default:
            break;
        }

        // Redirect
        if (redirect == true) {
          setTimeout(function() {
            if (reload == true) {
              window.location.href = redirectURL;
            } else {
              this.props.history.push(redirectURL);
            }
          }.bind(this), 3000);
        } else {
          if (reload == true) {
            window.location.reload();
          }
        }
        showNotification(errorTitle, errorMsg, "danger"); // Show error notification
      }
    })
    .catch((error) => {
      // handle error
      if (error.response && error.response.status == 403) {
        let errorTitle = "Oops Something went wrong";
        let errorMsg = "The action you have requested is not allowed. Please reload the page";
        showNotification(errorTitle, errorMsg, "danger"); // Show error notification
      }
      this.setState({ loading: false });
    });
  }

  handlePaymentFailed = (error) => {
    localStorage.setItem('paymentFailed', 1);
    this.props.history.push('/checkout/payment/failed');
  }

  buildAddress = (billingAddress) => {
    var address = capitalize(billingAddress.type) + " " + billingAddress.block + " " + billingAddress.street;
    if (billingAddress.floor && billingAddress.unit) {
      address += ", #" + billingAddress.floor + "-" + billingAddress.unit;
    }
    if (billingAddress.building) {
      if (!billingAddress.floor && !billingAddress.unit) {
        address += ", " + billingAddress.building;
      } else {
        address += " " + billingAddress.building;
      }
    }
    address += ", " + billingAddress.country + " " + billingAddress.postal;
    return address;
  }

  buildAddressLine1 = (billingAddress) => {
    return billingAddress.block + " " + billingAddress.street;
  }

  buildAddressLine2 = (billingAddress) => {
    var addressLine2 = "";
    if (billingAddress.floor && billingAddress.unit) {
      addressLine2 = "#" + billingAddress.floor + "-" + billingAddress.unit;
    }
    if (billingAddress.building) {
      if (!billingAddress.floor && !billingAddress.unit) {
        addressLine2 += "" + billingAddress.building + "";
      } else {
        addressLine2 += " " + billingAddress.building;
      }
    }
    return addressLine2;
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="lg" className="checkout" classes={{root: classes.layout}}>
            <CheckoutStepper active={4} history={this.props.history} completed={this.state.checkoutStepsCompleted} />
            <Grid container spacing={6}>
              <Grid item xs={12} sm={7} md={8} className={classes.gridItem1}>
                {this.state.loading && (
                  <Box className={classes.loadingWrapper}>
                    <CustomCircularProgress />
                  </Box>
                )}

                {!this.state.loading && (
                  <CheckoutForm publishableKey={this.state.stripePublishableKey} clientSecret={this.state.stripeClientSecret} onSuccess={this.handlePaymentSuccess} onFailed={this.handlePaymentFailed} expectedPaymentStatus={this.state.paymentType == "one-time" ? "requires_capture" : "succeeded"} paymentType={this.state.paymentType} />
                )}
              </Grid>

              <Grid item xs={12} sm={5} md={4} className={classes.gridItem2}>
                <CheckoutDetails vehicle={this.state.vehicle} schedule={this.state.schedule} type={this.state.paymentType}  />
              </Grid>
            </Grid>
          </Container>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(Payment);

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const showNotification = (title, message, type) => {
  store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: false
    }
  });
}