import React from "react";
import {
  createMuiTheme,
  withStyles,
  ThemeProvider
} from '@material-ui/core/styles';
import axios from 'axios';
import moment from 'moment';
import ContentLoader from "react-content-loader";
import { store } from 'react-notifications-component';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import { grey, red, orange } from '@material-ui/core/colors';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ReactGA from 'react-ga';

import CheckoutStepper from './CheckoutStepper';
import NewDriver from './NewDriver';
import "../assets/css/checkout.css";

const useStyles = theme => ({
  layout: {
    padding: theme.spacing(5),
    '& .MuiButtonBase-root': {
      fontSize: '1.1rem',
    },
    '& .MuiTypography-body1': {
      fontSize: '1.25rem',
    },
  },
  title: {
    fontWeight: 300,
    marginBottom: theme.spacing(3),
  },
  drivers: {
    color: grey[800],
    '& .MuiCardContent-root': {
      '& .MuiBox-root': {
        verticalAlign: 'top',
      },
    },
  },
  driverCard: {
    '& .MuiCardContent-root': {
      padding: theme.spacing(2, 2, 0),
      whiteSpace: 'nowrap',
      '& h5': {
        fontSize: '1.75rem',
      },
      '& .MuiTypography-body1': {
        fontSize: '1.25rem',
      },
      '& .MuiTypography-body2': {
        fontSize: '1.25rem',
      },
    },
    '& button': {
      fontSize: '1.1rem !important',
      fontWeight: '600',
    },
    '& button.MuiButton-textPrimary': {
      color: '#D40029',
    },
    '& button.MuiButton-textPrimary:hover': {
      backgroundColor: 'rgba(181, 129, 63, 0.04)',
    },
    '& button.MuiButton-textSecondary': {
      color: red[600],
    },
    '& button.MuiButton-textSecondary:hover': {
      backgroundColor: 'rgba(245, 0, 0, 0.04)',
    },
  },
  driverSelected: {
    border: '3px solid #D40029',
  },
  newDriver: {
    color: grey[600],
    cursor: 'pointer',
    '& .MuiCardContent-root': {
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 174,
      '& > *': {
        color: '#2F96B4',
        fontSize: '1.5rem',
        fontWeight: 500,
      },
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
      },
    },
  },
  avatar: {
    // backgroundColor: red[500],
    fontSize: 20,
    textTransform: "uppercase"
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    color: "#FFF",
    backgroundColor: "#262626",
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  driverSelectButton: {
    textTransform: 'none',
  },
});

const theme = createMuiTheme({
  // palette: {
  //   primary: {
  //     main: "#2F96B4",
  //   },
  //   secondary: {
  //     main: red[800],
  //   },
  // },
  // typography: {
  //   fontSize: 18,
  // }
});

class Driver extends React.Component {
  constructor(props) {
    super(props);

    ReactGA.plugin.execute('ec', 'setAction', 'driver', { step: 2 })
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);

    this.api = {
      url: props.mainState.apiURL,
      key: props.mainState.apiKey,
      accessToken: localStorage.getItem('ctoken'),
      jhamToken: localStorage.getItem('token'),
      // jhamToken: "8862828b9645d204ed6c070cfa043644",
    };

    this.state = {
      loading: true,
      selectedDrivers: {},
      drivers: [],
      requirement: {
        age: 0,
        experience: 0,
      },
      popover: null,
      openAddDialog: false,
      rawCheckout: null,
      checkoutStepsCompleted: [],
    };
  };

  componentDidMount() {
    // Unset checkout progress
    localStorage.removeItem('checkoutProgress');
    this.props.handlerState('checkoutProgress', null);

    // Get current checkout data
    let formData = new FormData();
        formData.append('api_key', this.api.key);
        formData.append('access_token', this.api.accessToken);
        formData.append('jham_token', this.api.jhamToken);
    axios({
      method: 'post',
      url: this.api.url + '/transaction/current_checkout',
      data: formData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      if (response.data.status == 1) {
        var checkout = response.data.checkout;
        // Get completed checkout steps
        var checkoutStepsCompleted = [];
        if (checkout.billingAddress) {
          checkoutStepsCompleted.push(1);
        } else {
          this.props.history.push('/checkout');
        }
        if (checkout.selectedDrivers)
          checkoutStepsCompleted.push(2);
        if (checkout.leasingAgreement)
          checkoutStepsCompleted.push(3);
        if (checkout.insuranceAgreement)
          checkoutStepsCompleted.push(4);

        // Get customer drivers
        axios({
          method: 'post',
          url: this.api.url + '/account/driver_list',
          data: formData,
          withCredentials: true,
          headers: {'Content-Type': 'multipart/form-data'}
        })
        .then((response) => {
          let drivers = [];
          let driverIds = [];
          let selectedDrivers = {};
          if(response.data.data != null){
              response.data.data.map((driver, i) => {
                let driverWithAvatar = {
                  ...driver,
                  avatarColor: randDarkColor(),
                };
                drivers.push(driverWithAvatar);
                driverIds.push(driverWithAvatar.cd_id);
              });
          }

          if (checkout.selectedDrivers && checkout.selectedDrivers[0]) {
            if (driverIds.indexOf(checkout.selectedDrivers[0].cd_id) > -1) {
              selectedDrivers[checkout.selectedDrivers[0].cd_id] = checkout.selectedDrivers[0];
            }
          }
          if (checkout.selectedDrivers && checkout.selectedDrivers[1]) {
            if (driverIds.indexOf(checkout.selectedDrivers[1].cd_id) > -1) {
              selectedDrivers[checkout.selectedDrivers[1].cd_id] = checkout.selectedDrivers[1];
            }
          }
          this.setState({
            loading: false,
            requirement: {
              age: parseInt(checkout.data[0].product_min_age),
              experience: parseInt(checkout.data[0].product_experience),
            },
            drivers: drivers,
            selectedDrivers: selectedDrivers,
            rawCheckout: checkout,
            checkoutStepsCompleted: checkoutStepsCompleted,
          });

          // Set checkout progress
          localStorage.setItem('checkoutProgress', 2);
          this.props.handlerState('checkoutProgress', 2);
        })
      } else {
        var reload = false;
        var redirect = false;
        var redirectURL = "";
        var errorTitle = "";
        var errorMsg = "";
        switch (response.data.error) {
          case 1:
          case 2:
            reload = true;
            redirect = true;
            redirectURL = "/sign-in";
            errorTitle = "Your session has expired";
            errorMsg = "Please login again. This page will automatically reload.";
            break;
          case 3:
            let listingFilters = localStorage.getItem('listing_filters');
                listingFilters = JSON.parse(listingFilters);
            redirectURL = "/vehicles/van-rental";
            if (listingFilters) {
              redirectURL = "/vehicles/van-rental?start=" + listingFilters.startDate2 + "&end=" + listingFilters.endDate2 + "&type=" + listingFilters.lType + "&count=" + listingFilters.lCount;
            }
            reload = true;
            redirect = true;
            errorTitle = "Something went wrong";
            errorMsg = response.data.error_msg + " This page will automatically reload.";
            break;
          default:
            break;
        }

        // Show error notification
        store.addNotification({
          title: errorTitle,
          message: errorMsg,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: false
          }
        });
        // Redirect
        if (redirect == true) {
          setTimeout(function() {
            if (reload == true) {
              window.location.href = redirectURL;
            } else {
              this.props.history.push(redirectURL);
            }
          }.bind(this), 3000);
        } else {
          if (reload == true) {
            window.location.reload();
          }
        }
        return false;
      }
    })
    .catch(error => {
      // handle error
      // if (error.response && error.response.status == 403) {
      // }
      store.addNotification({
        title: "Something went wrong",
        message: "The action you have requested is not allowed. Please reload the page",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
            onScreen: false
        }
      });
    });
  };

  handleSelectDriver = (driver) => {
    setTimeout(() => {
      if (typeof this.state.selectedDrivers[driver.cd_id] == "undefined" && Object.keys(this.state.selectedDrivers).length < 2) {
          var selectedDrivers = this.state.selectedDrivers;
          selectedDrivers[driver.cd_id] = driver;
          this.setState({
            selectedDrivers: selectedDrivers,
          });
      } else if (typeof this.state.selectedDrivers[driver.cd_id] != "undefined") {
        var selectedDrivers = this.state.selectedDrivers;
        delete selectedDrivers[driver.cd_id];
        this.setState({
          selectedDrivers: selectedDrivers,
        });
      }
    }, 50);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });

    let rawCheckout = this.state.rawCheckout;
    rawCheckout.selectedDrivers = Object.values(this.state.selectedDrivers);
    let updateData = {
      cd_id: parseInt(rawCheckout.selectedDrivers[0].cd_id),
      cd_id2: (typeof rawCheckout.selectedDrivers[1] != "undefined") ? parseInt(rawCheckout.selectedDrivers[1].cd_id) : 0,
    };
    let formData = new FormData();
        formData.append('api_key', this.api.key);
        formData.append('access_token', this.api.accessToken);
        formData.append('jham_token', this.api.jhamToken);
        formData.append('lessor_id', rawCheckout.data[0].vo_id);
        formData.append('update_data', JSON.stringify(updateData));
        formData.append('raw_current_checkout', JSON.stringify(this.state.rawCheckout));
    axios({
      method: 'post',
      url: this.api.url + '/transaction/update_schedule',
      data: formData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      if (response.data.error == 0 || response.data.error == 3) {
        this.props.history.push('/checkout/agreement');
      } else {
        var reload = false;
        var redirect = false;
        var redirectURL = "";
        var errorTitle = "";
        var errorMsg = "";
        switch (response.data.error) {
          case 1:
          case 2:
            reload = true;
            redirect = true;
            redirectURL = "/sign-in";
            errorTitle = "Your session has expired";
            errorMsg = "Please login again. This page will automatically reload.";
            break;
          case 4:
            reload = true;
            errorTitle = "Something went wrong";
            errorMsg = "This page will automatically reload. Please try again after reload.";
            break;
          default:
            break;
        }

        // Show error notification
        store.addNotification({
          title: errorTitle,
          message: errorMsg,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: false
          }
        });
        // Redirect
        if (redirect == true) {
          setTimeout(function() {
            if (reload == true) {
              window.location.href = redirectURL;
            } else {
              this.props.history.push(redirectURL);
            }
          }.bind(this), 3000);
        } else {
          if (reload == true) {
            window.location.reload();
          }
        }
        this.setState({ loading: false });
      }
    })
    .catch((error) => {
      console.log(error);
      // handle error
      if (error.response && error.response.status == 403) {

      }
      store.addNotification({
        title: "Oops Something went wrong",
        message: "The action you have requested is not allowed. Please reload the page",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: false
        }
      });

      this.setState({ loading: false });
    });

  };

  handlePopoverOpen = (event) => {
    if (Object.keys(this.state.selectedDrivers).length == 0) {
      this.setState({popover: event.currentTarget});
    }
  };

  handlePopoverClose = () => {
    this.setState({popover: null});
  };

  handleDialogClose = () => {
    this.setState({ openAddDialog: false });
  }

  handleDialogOpen = () => {
    this.setState({ openAddDialog: true });
  }

  handleDriverAdded = () => {
    this.setState({
      loading: true,
      openAddDialog: false,
    });
    let bodyFormData = new FormData();
        bodyFormData.append('api_key', this.api.key);
        bodyFormData.append('access_token', this.api.accessToken);
        bodyFormData.append('jham_token', this.api.jhamToken);
    axios({
      method: 'post',
      url: this.api.url + '/account/driver_list',
      data: bodyFormData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      let drivers = [];
      if (response.data.data != null) {
        response.data.data.map((driver, i) => {
          let driverWithAvatar = {
            ...driver,
            avatarColor: randDarkColor(),
          };
          drivers.push(driverWithAvatar);
        });
      }
      this.setState({
        loading: false,
        drivers: drivers,
      });
    })
    .catch((error) => {
      // Handle error
      if (error.response && error.response.status == 403) {
        store.addNotification({
          title: "Something went wrong",
          message: "Please reload the page",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: false
          }
        });
      }
    });
  }

  render() {
    const { classes } = this.props;
    const requirements = this.state.requirement;
    const currentDate = this.props.currentDate;

    function NewDriverCard(props) {
      return (
        <Grid item xs={12} sm={4}>
          <Card className={classes.newDriver} onClick={props.onClick}>
            <CardContent>
              <AddCircleIcon fontSize="large" color="primary" />
              <Typography variant="h5" component="span" align="center" color="primary">
                Add new driver
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      );
    }

    function DriverCard(props) {
      var experience = parseInt(props.driverDetails.cd_driver_yearexp);
      var birthDate = moment(props.driverDetails.cd_birthdate);
      var driverDetails = props.driverDetails;
      var diff = moment.preciseDiff(currentDate, birthDate, true);
      var age = diff.years;
      var disableMessage = "";
      var qualified =  false;
      if (experience >= requirements.experience && age >= requirements.age) {
        qualified = true;
      } else {
        disableMessage = "Minimum age/experience requirement was not met";
      }
      if (qualified) {
        if (driverDetails.cd_driver_name != "" &&
            driverDetails.cd_mobile_no != "" &&
            driverDetails.cd_nric != "" &&
            driverDetails.cd_driver_licenseno != "" &&
            driverDetails.cd_address_type != "" &&
            driverDetails.cd_address_block != "" &&
            driverDetails.cd_address_street != "" &&
            driverDetails.cd_address_country != ""
           ) {
          qualified = true;
        } else {
          qualified = false;
          disableMessage = "Incomplete driver details. Update this driver.";
        }
      }

      return (
        <Grid item xs={12} sm={4} key={driverDetails.cd_id} className={classes.driverCard}>
          <Card className={props.selected ? classes.driverSelected : ""}>
            <CardContent>
              <Box display="inline-block" style={{ marginRight: 15 }}>
                <Avatar className={classes.avatar} style={{ backgroundColor: driverDetails.avatarColor }}>
                  {driverDetails.cd_driver_name.charAt(0)}
                </Avatar>
              </Box>
              <Box display="inline-block">
                <Typography variant="h5" component="h5" gutterBottom>
                  {driverDetails.cd_driver_name}
                </Typography>
                <Typography variant="body2" component="p" color="textSecondary">
                  Mobile No: +65{driverDetails.cd_mobile_no}
                </Typography>
                <Typography variant="body2" component="p" color="textSecondary">
                  License No: {driverDetails.cd_driver_licenseno}
                </Typography>
                <Typography variant="body2" component="p" color="textSecondary">
                  NRIC: {driverDetails.cd_nric_masked}
                </Typography>
              </Box>
            </CardContent>
            <CardActions>
              {qualified ? (
                <Button color={props.selected ? "secondary" : "primary"} fullWidth onClick={() => {props.handleSelectDriver(driverDetails)}}>
                  {props.selected
                    ? "Deselect"
                    : "Select"
                  }
                </Button>
              ) : (
                <Button fullWidth disabled className={classes.driverSelectButton}>
                  {disableMessage}
                </Button>
              )}
            </CardActions>
          </Card>
        </Grid>
      );
    }

    function DriverListLoader() {
      return (
        <ContentLoader
          height={120}
          width={900}
          speed={1}
        >
          <circle cx="40" cy="40" r="25" width="50" height="50" />
          <rect x="80" y="15" rx="4" ry="5" width="180" height="20" />
          <rect x="80" y="45" rx="4" ry="4" width="160" height="13" />
          <rect x="80" y="62" rx="4" ry="4" width="160" height="13" />
          <rect x="80" y="79" rx="4" ry="4" width="140" height="13" />
        </ContentLoader>
      )
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <Container component="main" maxWidth="lg" className="checkout" classes={{root: classes.layout}}>
          <CheckoutStepper active={1} history={this.props.history} completed={this.state.checkoutStepsCompleted} />
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Paper elevation={0}>
                <Typography variant="h3" className={classes.title} gutterBottom>
                  Choose your driver (max: 2)
                </Typography>
                <Grid container spacing={3} alignItems="center" className={classes.drivers}>
                  {this.state.loading && (
                    <React.Fragment>
                      <Grid item xs={12} sm={4}>
                        <Card>
                          <CardContent>
                            <DriverListLoader />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Card>
                          <CardContent>
                            <DriverListLoader />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Card>
                          <CardContent>
                            <DriverListLoader />
                          </CardContent>
                        </Card>
                      </Grid>
                    </React.Fragment>
                  )}
                  {!this.state.loading && (
                    <React.Fragment>
                      {this.state.drivers.map((driver, index) =>
                        <DriverCard key={driver.cd_id} driverDetails={driver} handleSelectDriver={this.handleSelectDriver} selected={typeof this.state.selectedDrivers[driver.cd_id] != "undefined"} />
                      )}
                      <NewDriverCard onClick={this.handleDialogOpen} />
                    </React.Fragment>
                  )}
                  <Grid item xs={12} align="right">
                    <Box
                      display="inline-block"
                      aria-haspopup="true"
                      onMouseEnter={this.handlePopoverOpen}
                      onMouseLeave={this.handlePopoverClose}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={Object.keys(this.state.selectedDrivers).length == 0 || this.state.loading}
                        onClick={this.handleSubmit}
                      >
                        Next: Leasing Agreement
                      </Button>
                    </Box>
                    <Popover
                      id="mouse-over-popover"
                      className={classes.popover}
                      classes={{
                        paper: classes.popoverPaper,
                      }}
                      open={Boolean(this.state.popover)}
                      anchorEl={this.state.popover}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                      onClose={this.handlePopoverClose}
                      // disableRestoreFocus
                    >
                      <Typography>Select your driver first</Typography>
                    </Popover>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <NewDriver open={this.state.openAddDialog} onClose={this.handleDialogClose} onDriverAdded={this.handleDriverAdded} />
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(Driver);

function randomAvatarBgcolor() {
  let hex = Math.floor(Math.random() * 0xFFFFFF);
  let bgcolor = "#" + hex.toString(16);
  return bgcolor;
}

function randDarkColor() {
  var lum = -0.10;
  var hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  var rgb = "#",
      c, i;
  for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
  }
  return rgb;
}
