import React, {forwardRef,Component,useState } from "react";
import {
  NavLink,
  HashRouter
} from "react-router-dom";

import axios, { post } from 'axios';
import { store } from 'react-notifications-component';
import Pager from 'react-pager';

import Typography from '@material-ui/core/Typography';
import { DataGrid } from '@material-ui/data-grid';
import { createMuiTheme } from '@material-ui/core/styles';
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@material-ui/icons/EventBusyOutlined';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';

import TextField from '@material-ui/core/TextField';


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Alert, AlertTitle } from '@material-ui/lab';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';

import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import BackspaceRoundedIcon from '@material-ui/icons/BackspaceRounded';
import CircularProgress from '@material-ui/core/CircularProgress'

import CancelPayment from './CancelPayment';
import RejectPayment from './RejectPayment';

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import NumberFormat from 'react-number-format';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { red,grey, orange } from '@material-ui/core/colors';
import TimeKeeper from 'react-timekeeper';
import Fab from '@material-ui/core/Fab';
import SyncIcon from '@material-ui/icons/Sync';
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
import Tooltip from '@material-ui/core/Tooltip';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#2F96B4',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

function FacebookCircularProgress(props) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}

const useStyles = theme => ({
    root: {
        backgroundColor: "blue",
        color: "green"
    },
    toolbar: {
        backgroundColor: "white"
    },
    caption: {
        color: "red",
        fontSize: "20px"
    },
    selectIcon: {
        color: "green"
    },
    select: {
        color: "green",
        fontSize: "20px"
    },
    actions: {
        color: "blue"
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        outline: 'none'
    },
    newCardForm: {
      color: grey[800],
      '& .MuiFormControl-root': {
        '& .MuiFormLabel-root': {
          fontSize: '1.2rem',
          '&.Mui-focused': {
            color: grey[800],
            backgroundColor: 'white',
            paddingRight: 5,
          },
        },
        '& .MuiInputBase-root': {
          borderRadius: 0,
          '&:hover fieldset': {
            borderColor: '#B4B4B4',
          },
          '&.Mui-focused fieldset': {
            borderColor: orange[600],
          },
          '& input': {
            fontSize: 15,
            font:'auto'
          },
        },
      }
    },
});

let renderFlag = true;
let tableFields = [
        's.s_id',
        'o.order_invoice_no',
        'c.customer_firstname',
        'p.category_name',
        'p.product_name',
        'p.product_platenumber',
        'p.product_rent_terms',
        's.s_start_date',
        's.s_end_date',
        's.s_lease_rate',
        's.s_payable_amount'
    ]

class Schedule extends Component {

    constructor (props) {
        super(props)
        window.scrollTo(0, 0);
        this.rejectReason = React.createRef();
        this.cancelReason = React.createRef();
        this.currentTabList = React.createRef();
        this.currentVehicleToChange = "";
        this.fileInputUrgentMaintenance = React.createRef();
        this.state = {
            ongoingRentalActive: [],
            ongoingRentalPending: [],
            totalRentsActive:0,
            totalRentsPending:0,
            ongoingRentalOrder:[],
            ongoingRentalIn:[],
            ongoingRentalOut:[],
            totalPagesOrder:0,
            totalRentsOrder:0,
            totalPagesIn:0,
            totalPagesOut:0,
            totalRentsIn:0,
            totalRentsOut:0,
            totalRentsOrder:0,
            limit:10,
            offsetActive:0,
            offsetPending: 0,
            offsetOrder:0,
            offsetIn: 0,
            offsetOut: 0,
            visiblePage: 5,
            currentPageActive:0,
            currentPagePending:0,
            currentPageOrder:0,
            currentPageIn:0,
            currentPageOut:0,
            totalPagesActive: 0,
            totalPagesPending: 0,
            totalPagesIn: 0,
            totalPagesOut: 0,
            noResult: false,
            openCancelConfirm: false,
            currentTransaction:false,
            openSb: false,
            lessorCode:false,
            openApproveConfirm: false,
            openRejectConfirm: false,
            openSuccessulConfirm:false,
            openFailedConfirm:false,
            openChangeVehicle: false,
            openCancelOrderConfirm: false,
            openCancelReason: false,
            openCancelPayment: false,
            openRejectPayment: false,
            sbstatus:'success',
            sbMessage:'',
            keyword:'',
            timeout:0,
            currentTab: 1,
            currentSort: 'desc',
            currentSortField: 's.s_id',
            tableLoading:false,
            currentCancelReason:"1",
            cancelFaultMsg:"Cancellation fee will be charge to Lessor’s Account",
            cancelDetailedReason: "",
            currentChangeVehicle:false,
            openReturnConfirm: false,
            products:[],
            returnType: 1,
            actionProcessing: false,
            lateCharges:[
                { title: "Product Damages", amount: "0.00" },
                { title: "Traffic Offense", amount: "0.00" },
                { title: "Diesel", amount: "0.00" }
            ],
            transactionFee: 0,
            cancellationFee: 0,
            cancellationProcessingFee: 0,
            deposit: 0,
            selectedStartTime:"9:00",
            lateReturn: false,
            currentEndTime: false,
            selectedReturnDate:null,
            rejectReason: "urgent_maintenance",
            rejectDocument: null,
            chargeRemainingAmount: true,
        };
        this.handlePageChangedActive = this.handlePageChangedActive.bind(this);
        this.handlePageChangedPending = this.handlePageChangedPending.bind(this);
        this.handleCloseCancel = this.handleCloseCancel.bind(this);
        this.handleClickCancel = this.handleClickCancel.bind(this);
        this.handleCloseReject = this.handleCloseReject.bind(this);
        this.handleCloseSb = this.handleCloseSb.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleClickApprove = this.handleClickApprove.bind(this);
        this.handleCloseApprove = this.handleCloseApprove.bind(this);
        this.handleClickCancelPending = this.handleClickCancelPending.bind(this);
        this.handleSortChangeActive = this.handleSortChangeActive.bind(this);
        this.handleSortChangePending = this.handleSortChangePending.bind(this);
        this.handleClickRejectPending = this.handleClickRejectPending.bind(this);
        this.handleClickSuccessul = this.handleClickSuccessul.bind(this);
        this.handleChangeReason = this.handleChangeReason.bind(this);
        this.handleChangeVehicle = this.handleChangeVehicle.bind(this);
        this.handlePageChangedOrder = this.handlePageChangedOrder.bind(this);
        this.handlePageChangedIn = this.handlePageChangedIn.bind(this);
        this.handlePageChangedOut = this.handlePageChangedOut.bind(this);
        this.handleClickOutReturn = this.handleClickOutReturn.bind(this);
        this.handleCloseReturn = this.handleCloseReturn.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.handleReloadTable = this.handleReloadTable.bind(this);
        this.handleReturnDateChange = this.handleReturnDateChange.bind(this);
        this.handleCurrentTab = this.handleCurrentTab.bind(this);

    }
    handleCurrentTab(x){
        this.setState({
          currentTab: x,
        })
    }
    handleReloadTable(){
        this.setState({
          tableLoading: true,
        })

        this.componentDidMount();
        // alert(this.state.currentTab);
        // this.setState({
        //   currentTab: 2,
        // })
    }
    handleCloseSb(){
        this.setState({
          openSb:false,
        })
    }

    handleTimeChange(data){
        this.setState({
          selectedStartTime:data.formatted24
        })
    }

    handleReturnDateChange(date){
        if(this.state.currentEndTime){
            var tempDate1 = this.state.currentEndTime;
            var tempDate2 = moment(date).format('yyyy-MM-DD HH:mm');
            if(moment(tempDate1).unix() < moment(tempDate2).unix()){
                // Late return
                this.setState({
                  selectedReturnDate:tempDate2,
                  earlyReturn: false,
                  lateReturn: true,
                })
            } else if (moment(tempDate1).unix() > moment(tempDate2).unix()) {
                // Early return
                if (this.state.currentSchedule.s_payment_mode == "daily" || this.state.currentSchedule.s_payment_mode == "weekend" || this.state.currentSchedule.s_payment_mode == "weekly") {
                  var earlyReturn = true;
                  var returnDate = moment(this.state.currentEndTime).startOf('day');
                  var selectedReturnDate = moment(date).startOf('day');
                  if (selectedReturnDate >= returnDate) {
                    earlyReturn = false;
                  }
                  this.setState({
                    selectedReturnDate: tempDate2,
                    earlyReturn: earlyReturn,
                    lateReturn: false,
                  });
                } else {
                  var daysDiff = moment(tempDate1).diff(tempDate2, 'days');
                  var paidMonthsCount = parseInt(this.state.currentSchedule.installment_paid);
                  var totalMonthsCount = parseInt(this.state.currentSchedule.installment_count);
                  var unpaidMonthsCount = totalMonthsCount - paidMonthsCount;
                  var monthlyRate = parseFloat(this.state.currentSchedule.s_lease_rate) + parseFloat(this.state.currentSchedule.s_addons_price);
                  var earlyReturn = daysDiff >= 30 && unpaidMonthsCount > 0 ? true : false;
                  var lateCharges = this.state.lateCharges;
                  this.setState({
                    selectedReturnDate: tempDate2,
                    earlyReturn: earlyReturn,
                    lateReturn: false,
                    unpaidMonthsCount: unpaidMonthsCount,
                    monthlyRate: monthlyRate,
                    lateCharges: lateCharges,
                  });
                }
            }else{
                this.setState({
                  selectedReturnDate:tempDate2,
                  earlyReturn: false,
                  lateReturn: false
                })
            }

        }else{
            var tempDate2 = moment(date).format('yyyy-MM-DD HH:mm');
            this.setState({
              selectedReturnDate:tempDate2,
              earlyReturn: false,
              lateReturn: false
            })
        }


    }


    handleChangeReason(e){
        if(e.target.value == "1"){
            this.setState({
              currentCancelReason:e.target.value,
              cancelFaultMsg:"Cancellation fee will be charge to Lessor’s Account"
            })
        }else{
            this.setState({
              currentCancelReason:e.target.value,
              cancelFaultMsg:"Cancellation fee will be charge to Lessee’s Deposit"
            })
        }

    }

    handleChangeVehicle(val){
        this.currentVehicleToChange = val;
        // this.setState({
        //   currentChangeVehicle:val,
        // })
    }



    handleClickSuccessul(rowData,type){

        if(type == 1){
            this.setState({
              openSuccessulConfirm:true,
              currentSchduleId:rowData.s_id,
              currentRow:rowData,
              currentTab: 2
            })
        }else{
            this.setState({
              openFailedConfirm:true,
              currentSchduleId:rowData.s_id,
              currentRow:rowData,
              currentTab: 2
            })
        }

    }


    handleClickOutReturn(rowData){
        this.setState({
          openReturnConfirm:true,
          currentSchedule:rowData,
          currentSchduleId:rowData.s_id,
          currentRow:rowData,
          currentTab: 3,
          currentEndTime: moment(rowData.s_end_date).format('yyyy-MM-DD HH:mm'),
          selectedReturnDate:moment(rowData.s_end_date).format('yyyy-MM-DD HH:mm'),
        })


    }


    handleSortChangeActive = (orderedColumnId, orderDirection) => {

      var currentDirection = this.state.currentSort;
      if(currentDirection == "asc"){
          currentDirection = "desc";
      }else{
          currentDirection = "asc";
      }

      this.setState({
        currentSort:currentDirection,
        tableLoading: true,
        currentSortField: tableFields[orderedColumnId],
        currentTab: 0
      })
      setTimeout(() => {
            this.componentDidMount();
      }, 150);

    };

    handleSortChangePending = (orderedColumnId, orderDirection) => {

      var currentDirection = this.state.currentSort;
      if(currentDirection == "asc"){
          currentDirection = "desc";
      }else{
          currentDirection = "asc";
      }

      this.setState({
        currentSort:currentDirection,
        tableLoading: true,
        currentSortField: tableFields[orderedColumnId],
        currentTab: 1
      })
      setTimeout(() => {
            this.componentDidMount();
      }, 150);

    };


    shouldComponentUpdate(nextProps, nextState) {

        if (!renderFlag) {
            return false;
        } else {
            return true;
        }
    }

    handleCloseReturn(confirmation,lateCharges,e){
        if(confirmation == 1){
            this.setState({
                actionProcessing: true
            })
            var bodyFormData = new FormData();
            var token = localStorage.getItem('token');
            var ctoken = localStorage.getItem('ctoken');

            bodyFormData.append('s_id', this.state.currentSchduleId);
            bodyFormData.append('jham_token', token);
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('return_datetime', this.state.selectedReturnDate);
            bodyFormData.append('charge_remaining_months', this.state.chargeRemainingAmount);
            bodyFormData.append('type', 1);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


            axios({
                  method: 'post',
                  url: 'https://api.earental.com/lessor/return_product',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {
                if(response.data.error > 0){
                    if (response.data.error_message.length > 1) {
                        function ErrorMsg(props) {
                          return (
                              props.msgs.map((item,i) =>
                                  <li key={"err-"+i}>{item.value}</li>
                              )
                          );
                        }
                        store.addNotification({
                          title: "Error",
                          message: <ErrorMsg msgs={response.data.error_message} />,
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animated", "fadeIn"],
                          animationOut: ["animated", "fadeOut"],
                          dismiss: {
                            duration: 20000,
                            onScreen: false
                          }
                        });
                    } else {
                      this.setState({
                        openReturnConfirm: false,
                        currentSchduleId: false,
                        sbMessage: response.data.error_message[0].value,
                        sbstatus: 'error',
                        openSb: true,
                        returnType: 1,
                        actionProcessing: false,
                        selectedReturnDate: null,
                        lateReturn: false,
                        currentEndTime: false,
                        earlyReturn: false,
                        unpaidMonthsCount: 0,
                        monthlyRate: 0,
                        chargeRemainingAmount: true,
                      })
                    }

                 }else{

                     this.componentDidMount();

                     this.setState({
                        openReturnConfirm:false,
                        currentSchduleId: false,
                        sbMessage:"Schedule successfully returned.",
                        sbstatus:'success',
                        openSb: true,
                        returnType: 1,
                        actionProcessing: false,
                        selectedReturnDate:null,
                        lateReturn: false,
                        currentEndTime: false,
                        earlyReturn: false,
                        unpaidMonthsCount: 0,
                        monthlyRate: 0,
                        chargeRemainingAmount: true,
                     })

                 }

              })
              .catch((error) => {
                  //handle error
                  this.setState({
                      openReturnConfirm:false,
                      currentSchduleId: false,
                      sbMessage: "Something went wrong, please try again.",
                      sbstatus:'error',
                      openSb: true,
                      returnType: 1,
                      actionProcessing: false,
                      selectedReturnDate:null,
                      lateReturn: false,
                      currentEndTime: false,
                      earlyReturn: false,
                      unpaidMonthsCount: 0,
                      monthlyRate: 0,
                      chargeRemainingAmount: true,
                  })

            });

        }else if(confirmation == 3){
            this.setState({
                returnType:2,
            })
        }else if(confirmation == 4){
            this.setState({
                returnType:1,
            })
        }else if(confirmation == 5){
            var checkSure = window.confirm("Are you sure you want to proceed?");
            if (checkSure == true) {
                this.setState({
                    lateCharges: lateCharges,
                    actionProcessing: true
                })

                var bodyFormData = new FormData();
                var token = localStorage.getItem('token');
                var ctoken = localStorage.getItem('ctoken');

                var currentCharges = [];
                for (var i = 0; i < lateCharges.length; i++) {
                  if(lateCharges[i].title != "" && parseFloat(lateCharges[i].amount) > 0){
                    currentCharges.push(lateCharges[i]);
                  }

                }
                if(currentCharges.length > 0){
                    var lateCharges = JSON.stringify(currentCharges);
                    lateCharges = lateCharges.replace(",{}", "");
                    bodyFormData.append('late_charges',lateCharges);
                }else{
                    store.addNotification({
                      title: "Missing field",
                      message: "No late charges set yet",
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 20000,
                        onScreen: false
                      }
                    });
                    this.setState({
                      actionProcessing: false,
                    });
                    return false;
                }




                bodyFormData.append('s_id', this.state.currentSchduleId);
                bodyFormData.append('jham_token', token);
                bodyFormData.append('access_token', ctoken);
                bodyFormData.append('return_datetime', this.state.selectedReturnDate);
                bodyFormData.append('charge_remaining_months', this.state.chargeRemainingAmount);
                bodyFormData.append('type', 2);
                bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


                axios({
                      method: 'post',
                      url: 'https://api.earental.com/lessor/return_product',
                      data: bodyFormData,
                      withCredentials: true,
                      headers: {'Content-Type': 'multipart/form-data' }
                  })
                  .then((response) => {
                     if(response.data.error > 0){
                         function ErrorMsg(props) {
                            return props.msgs.map((item,i) => (
                                <li key={"err-"+i}>{item.value}</li>
                            ))

                         }
                         store.addNotification({
                           title: "Error",
                           message: <ErrorMsg msgs={response.data.error_message} />,
                           type: "danger",
                           insert: "top",
                           container: "top-right",
                           animationIn: ["animated", "fadeIn"],
                           animationOut: ["animated", "fadeOut"],
                           dismiss: {
                             duration: 2000,
                             onScreen: false
                           }
                         });

                     }else{

                         this.componentDidMount();

                         this.setState({
                            openReturnConfirm:false,
                            currentSchduleId: false,
                            sbMessage:"Schedule successfully returned.",
                            sbstatus:'success',
                            openSb: true,
                            returnType: 1,
                            lateCharges: [
                                { title: "Product Damages", amount: "0.00" },
                                { title: "Traffic Offense", amount: "0.00" },
                                { title: "Diesel", amount: "0.00" }
                            ],
                            actionProcessing: false,
                            selectedReturnDate:null,
                            lateReturn: false,
                            currentEndTime: false,
                            earlyReturn: false,
                            unpaidMonthsCount: 0,
                            monthlyRate: 0,
                            chargeRemainingAmount: true,
                         })

                     }

                  })
                  .catch((error) => {

                      this.setState({
                          openReturnConfirm:false,
                          currentSchduleId: false,
                          sbMessage: "Something went wrong, please try again.",
                          sbstatus:'error',
                          openSb: true,
                          returnType: 1,
                          lateCharges: [
                              { title: "Product Damages", amount: "0.00" },
                              { title: "Traffic Offense", amount: "0.00" },
                              { title: "Diesel", amount: "0.00" }
                          ],
                          actionProcessing: false,
                          selectedReturnDate:null,
                          lateReturn: false,
                          currentEndTime: false,
                          earlyReturn: false,
                          unpaidMonthsCount: 0,
                          monthlyRate: 0,
                          chargeRemainingAmount: true,
                      })

                });
            }else{
              return false;
            }


        }else{
            this.setState({
                sbstatus:'success',
                openReturnConfirm:false,
                currentSchduleId: false,
                returnType: 1,
                lateCharges: [
                    { title: "Product Damages", amount: "0.00" },
                    { title: "Traffic Offense", amount: "0.00" },
                    { title: "Diesel", amount: "0.00" }
                ],
                actionProcessing: false,
                selectedStartTime:"9:00",
                lateReturn: false,
                currentEndTime: false,
                earlyReturn: false,
                unpaidMonthsCount: 0,
                monthlyRate: 0,
                chargeRemainingAmount: true,
            })
        }
    }

    handleChargeRemainingAmount = (event) => {
      event.persist();
      this.setState({
        chargeRemainingAmount: event.target.checked,
      });
    }

    handleCloseCancelOrder(confirmation,e){


        if(confirmation == 0){
            this.setState({
                openFailedConfirm:false,
                openCancelReason:false,
                currentSchduleId: false,
            })
        }else if(confirmation == 1){
            this.setState({
                openChangeVehicle:true,
                openFailedConfirm:false
            })
            this.searchProduct();

        }else if(confirmation == 2){
            this.setState({
                openCancelReason:true,
                openFailedConfirm:false,
            })
        }else if(confirmation == 3){
            // this.setState({
            //     openCancelReason:false,
            //     openFailedConfirm:false,
            //     currentSchduleId: false,
            // })
            var cancelApi = "";
            if(this.state.currentCancelReason == 1){
                this.setState({
                  cancelDetailedReason: this.cancelReason.current.value,
                  openCancelReason:false,
                  openFailedConfirm:false,
                  // currentSchduleId: false,
                  openCancelPayment: true,
                });
                return false;
                // cancelApi = "https://api.earental.com/lessor/cancel_schedule_not_asperlisting";

            } else {
              this.setState({
                actionProcessing: true,
              });
              cancelApi = "https://api.earental.com/lessor/cancel_schedule_lesseechangedmind";
            }

            var bodyFormData = new FormData();
            var token = localStorage.getItem('token');
            var ctoken = localStorage.getItem('ctoken');

            bodyFormData.append('s_id', this.state.currentSchduleId);
            bodyFormData.append('jham_token', token);
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
            bodyFormData.append('s_cancelled_reason', this.cancelReason.current.value);


            axios({
                  method: 'post',
                  url: cancelApi,
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {
                 if(response.data.error > 0){
                     function ErrorMsg(props) {
                       return (
                           props.msgs.map((item,i) =>
                               <li key={"err-"+item.id}>{item.value}</li>
                           )
                       );
                     }
                     store.addNotification({
                       title: "Error",
                       message: response.data.error_message,
                       type: "danger",
                       insert: "top",
                       container: "top-right",
                       animationIn: ["animated", "fadeIn"],
                       animationOut: ["animated", "fadeOut"],
                       dismiss: {
                         duration: 20000,
                         onScreen: false
                       }
                     });

                     this.setState({
                         openCancelReason:false,
                         openFailedConfirm:false,
                         currentSchduleId: false,
                         sbMessage: response.data.error_message,
                         sbstatus:'error',
                         openSb: true,
                         actionProcessing: false,
                     })
                 }else{

                     this.componentDidMount();

                     this.setState({
                        openCancelReason:false,
                        openFailedConfirm:false,
                        currentSchduleId: false,
                        sbMessage:"Schedule successfully cancelled.",
                        sbstatus:'success',
                        openSb: true,
                        actionProcessing: false,
                     })

                 }


              })
              .catch((error) => {
                  //handle error
                  this.setState({
                     openCancelReason:false,
                     openFailedConfirm:false,
                     currentSchduleId: false,
                     actionProcessing: false,
                  })
            });
        }
    }

    handleCloseSuccessful(confirmation,e){
        if(confirmation == 0){
            this.setState({
                openSuccessulConfirm:false,
                currentSchduleId: false,
                selectedStartTime:"9:00"
            })
        }else if(confirmation == 1){
            this.setState({
                openChangeVehicle:true,
                openSuccessulConfirm:false,
            })

            this.searchProduct();

        }else if(confirmation == 2){
            this.setState({
                actionProcessing: true
            })

            //Call api confirm without change vehicle

            var bodyFormData = new FormData();
            var token = localStorage.getItem('token');
            var ctoken = localStorage.getItem('ctoken');

            bodyFormData.append('s_id', this.state.currentSchduleId);
            bodyFormData.append('product_id', this.state.currentRow.product_id);
            bodyFormData.append('jham_token', token);
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('start_rent_time',this.state.selectedStartTime);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


            axios({
                  method: 'post',
                  url: 'https://api.earental.com/lessor/collect_product',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {
                 if(response.data.error > 0){
                     function ErrorMsg(props) {
                       return (
                           props.msgs.map((item,i) =>
                               <li key={"err-"+item.id}>{item.value}</li>
                           )
                       );
                     }
                     store.addNotification({
                       title: "Error",
                       message: response.data.error_message,
                       type: "danger",
                       insert: "top",
                       container: "top-right",
                       animationIn: ["animated", "fadeIn"],
                       animationOut: ["animated", "fadeOut"],
                       dismiss: {
                         duration: 20000,
                         onScreen: false
                       }
                     });

                     this.setState({
                         openSuccessulConfirm:false,
                         currentSchduleId: false,
                         sbMessage: response.data.error_message,
                         sbstatus:'error',
                         openSb: true,
                         actionProcessing:false,
                         selectedStartTime:"9:00"
                     })

                 }else{

                     this.componentDidMount();

                     this.setState({
                        openSuccessulConfirm:false,
                        currentSchduleId: false,
                        sbMessage:"Schedule successfully confirmed.",
                        sbstatus:'success',
                        openSb: true,
                        actionProcessing: false,
                        selectedStartTime:"9:00"
                     })

                 }


              })
              .catch((error) => {
                  //handle error
                  this.setState({
                      openSuccessulConfirm:false,
                      currentSchduleId: false,
                      sbMessage: "Something went wrong, please try again",
                      sbstatus:'error',
                      openSb: true,
                      actionProcessing:false,
                      selectedStartTime:"9:00"
                  })

            });
        }


    }

    handleCancelPaymentDialogClose = () => {
      this.setState({
        openCancelPayment: false,
      });
    };

    handleRejectPaymentDialogClose = () => {
      this.setState({
        openRejectPayment: false,
        rejectReason: "urgent_maintenance",
        rejectDocument: null,
      });
    };

    handleScheduleCancelled = () => {
      this.setState({
        openCancelPayment: false,
        openRejectPayment: false,
        rejectReason: "urgent_maintenance",
        rejectDocument: null,
      });
      this.componentDidMount();
    };

    handleRejectPaymentError = () => {
      this.setState({
        openRejectPayment: false,
        openRejectConfirm: true,
      });
    }

    handleCancelPaymentError = () => {
      this.setState({
        openCancelPayment: false,
        openRejectConfirm: true,
      });
    }


    searchProduct() {
        var ctoken = localStorage.getItem('ctoken');
        var token = localStorage.getItem('token');
        var bodyFormData = new FormData();

        bodyFormData.append('access_token', ctoken);
        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
        bodyFormData.append('jham_token', token);
        bodyFormData.append('limit', 100);
        bodyFormData.append('offset', 0);
        bodyFormData.append('date_from', this.state.currentRow.s_start_date);
        bodyFormData.append('date_to',this.state.currentRow.s_end_date);
        bodyFormData.append('vo_id', this.state.currentRow.vo_id);
        bodyFormData.append('search_type', "");



        axios({
                method: 'post',
                url: 'https://api.earental.com/listing/search',
                data: bodyFormData,
                withCredentials: true,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then((response) => {

                if(response.data.error > 0){
                    this.setState({ products: [] })
                }else{
                    if(response.data.data == null){
                        this.setState({ products:[] });
                    }else{
                        this.setState({ products:response.data.data });
                    }

                }
                // window.scrollTo(0, 0);
            })
            .catch((error) => {

            this.setState({ products:[] });

        });

    }

    handleCloseChangeVehicle(confirmation,e){
        if(confirmation == 0){
            this.setState({
                openChangeVehicle:false,
                currentSchduleId: false,
                selectedStartTime:"9:00"
            })
        }else if(confirmation == 1){
            this.setState({
                openSuccessulConfirm:true,
                openChangeVehicle:false,
            })
        }else if(confirmation == 2){

            if(this.currentVehicleToChange == 0 || this.currentVehicleToChange == ""){
                store.addNotification({
                  title: "Error",
                  message: "Please select a product to proceed.",
                  type: "warning",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                    duration: 5000,
                    onScreen: false
                  }
                });

            }else{
                this.setState({
                   actionProcessing:true
                })
                var bodyFormData = new FormData();
                var token = localStorage.getItem('token');
                var ctoken = localStorage.getItem('ctoken');

                bodyFormData.append('s_id', this.state.currentSchduleId);
                bodyFormData.append('product_id', this.currentVehicleToChange);
                bodyFormData.append('start_rent_time',this.state.selectedStartTime);
                bodyFormData.append('jham_token', token);
                bodyFormData.append('access_token', ctoken);
                bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


                axios({
                      method: 'post',
                      url: 'https://api.earental.com/lessor/collect_product',
                      data: bodyFormData,
                      withCredentials: true,
                      headers: {'Content-Type': 'multipart/form-data' }
                  })
                  .then((response) => {
                     if(response.data.error > 0){
                         function ErrorMsg(props) {
                           return (
                               props.msgs.map((item,i) =>
                                   <li key={"err-"+item.id}>{item.value}</li>
                               )
                           );
                         }
                         store.addNotification({
                           title: "Error",
                           message: response.data.error_message,
                           type: "danger",
                           insert: "top",
                           container: "top-right",
                           animationIn: ["animated", "fadeIn"],
                           animationOut: ["animated", "fadeOut"],
                           dismiss: {
                             duration: 20000,
                             onScreen: false
                           }
                         });

                         this.setState({
                             openChangeVehicle:false,
                             currentSchduleId: false,
                             sbMessage: response.data.error_message,
                             sbstatus:'error',
                             openSb: true,
                             actionProcessing:false,
                             selectedStartTime:"9:00"

                         })
                         this.currentVehicleToChange = 0;

                     }else{

                        this.componentDidMount();

                        this.setState({
                            openChangeVehicle:false,
                            currentSchduleId: false,
                            sbMessage:"Schedule successfully confirmed and changed product.",
                            sbstatus:'success',
                            openSb: true,
                            actionProcessing:false,
                            selectedStartTime:"9:00"
                        })
                        this.currentVehicleToChange = 0;

                     }


                  })
                  .catch((error) => {
                      //handle error
                      console.log(error);

                });
            }

        }

    }

    handleCloseCancel(confirmation,e){

        if(confirmation){
            this.setState({
               actionProcessing:true
            })
            var bodyFormData = new FormData();
            var token = localStorage.getItem('token');
            var ctoken = localStorage.getItem('ctoken');

            bodyFormData.append('s_id', this.state.currentSchduleId);
            bodyFormData.append('jham_token', token);
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


            axios({
                  method: 'post',
                  url: 'https://api.earental.com/lessor/cancel_schedule',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {
                console.log(response.data.error);
                 if(response.data.error > 0){
                     function ErrorMsg(props) {
                       return (
                           props.msgs.map((item,i) =>
                               <li key={"err-"+item.id}>{item.value}</li>
                           )
                       );
                     }
                     store.addNotification({
                       title: "Error",
                       message: response.data.error_message,
                       type: "danger",
                       insert: "top",
                       container: "top-right",
                       animationIn: ["animated", "fadeIn"],
                       animationOut: ["animated", "fadeOut"],
                       dismiss: {
                         duration: 20000,
                         onScreen: false
                       }
                     });

                     this.setState({
                         openCancelConfirm:false,
                         currentSchduleId: false,
                         sbMessage: response.data.error_message,
                         sbstatus:'error',
                         openSb: true,
                         actionProcessing:false
                     })
                 }else{

                     this.componentDidMount();

                     this.setState({
                        openCancelConfirm:false,
                        currentSchduleId: false,
                        sbMessage:"Schedule successfully cancelled.",
                        sbstatus:'success',
                        openSb: true,
                        actionProcessing:false
                     })

                 }

                 renderFlag = true;



              })
              .catch((error) => {
                  //handle error
                  console.log(error);

            });

        }else{
            this.setState({
                sbstatus:'success',
                openCancelConfirm:false,
                currentSchduleId: false,
                actionProcessing:false
            })
        }
    }

    handleCloseReject(confirmation,e){
        if(confirmation){
            if(this.rejectReason.current.value == "" || this.rejectReason.current.value.length < 10){
                store.addNotification({
                  title: "Error",
                  message: "Your message is too short, kindly specify your message to avoid confusion.",
                  type: "warning",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                    duration: 20000,
                    onScreen: false
                  }
                });

            } else {
              if (this.state.rejectReason == "lessee_request") {
                this.setState({
                  actionProcessing: true
                })

                var bodyFormData = new FormData();
                var token = localStorage.getItem('token');
                var ctoken = localStorage.getItem('ctoken');

                bodyFormData.append('s_id', this.state.currentSchduleId);
                bodyFormData.append('cancel_reason', this.rejectReason.current.value);
                bodyFormData.append('reject_reason', this.state.rejectReason);
                bodyFormData.append('reject_document', this.state.rejectDocument);
                bodyFormData.append('jham_token', token);
                bodyFormData.append('access_token', ctoken);
                bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


                axios({
                  method: 'post',
                  url: 'https://api.earental.com/lessor/cancel_schedule',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: { 'Content-Type': 'multipart/form-data' }
                })
                  .then((response) => {
                    console.log(response.data.error);
                    if (response.data.error > 0) {
                      function ErrorMsg(props) {
                        return (
                          props.msgs.map((item, i) =>
                            <li key={"err-" + item.id}>{item.value}</li>
                          )
                        );
                      }
                      store.addNotification({
                        title: "Error",
                        message: response.data.error_message,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 20000,
                          onScreen: false
                        }
                      });

                      this.setState({
                        openRejectConfirm: false,
                        currentSchduleId: false,
                        sbMessage: response.data.error_message,
                        sbstatus: 'error',
                        openSb: true,
                        actionProcessing: false
                      })
                    } else {

                      this.componentDidMount();

                      this.setState({
                        openRejectConfirm: false,
                        currentSchduleId: false,
                        sbMessage: "Schedule successfully rejected.",
                        sbstatus: 'success',
                        openSb: true,
                        actionProcessing: false
                      })

                    }

                    renderFlag = true;
                  })
                  .catch((error) => {
                    //handle error
                    console.log(error);

                  });
              } else {
                this.setState({
                  actionProcessing: false,
                  openRejectPayment: true,
                  openRejectConfirm: false,
                });
              }
            }
        }else{
            this.setState({
                sbstatus:'success',
                openRejectConfirm:false,
                currentSchduleId: false,
                actionProcessing: false,
            })
        }
    }

    handleCloseApprove(confirmation,e){

        if(confirmation){
            this.setState({
                actionProcessing:true,
            })
            var bodyFormData = new FormData();
            var token = localStorage.getItem('token');
            var ctoken = localStorage.getItem('ctoken');

            bodyFormData.append('s_id', this.state.currentSchduleId);
            bodyFormData.append('jham_token', token);
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


          axios({
            method: 'post',
            url: 'https://api.earental.com/lessor/approve_schedule',
            data: bodyFormData,
            withCredentials: true,
            headers: { 'Content-Type': 'multipart/form-data' }
          })
            .then((response) => {
              if (response.data.error > 0) {
                if (response.data.error_type == "validation_error") {
                  function ErrorMsg(props) {
                    return (
                      props.msgs.map((item, i) =>
                        <li key={"err-" + item.name}>{item.value}</li>
                      )
                    );
                  }
                  store.addNotification({
                    title: "Error",
                    message: <ErrorMsg msgs={response.data.error_message} />,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 20000,
                      onScreen: false
                    }
                  });
                } else if (response.data.error_type == "payment_error") {
                  store.addNotification({
                    title: "Error",
                    message: response.data.error_message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 20000,
                      onScreen: false
                    }
                  });
                }
                this.setState({
                  openApproveConfirm: false,
                  currentSchduleId: false,
                  // sbMessage: response.data.error_message,
                  // sbstatus: 'error',
                  // openSb: true,
                  actionProcessing: false,
                });
              } else {
                this.componentDidMount();
                this.setState({
                  openApproveConfirm: false,
                  currentSchduleId: false,
                  sbMessage: "Schedule successfully approved.",
                  sbstatus: 'success',
                  openSb: true,
                  actionProcessing: false,
                })
              }
              renderFlag = true;
            })
            .catch((error) => {
              //handle error
              console.log(error);
            });

        }else{
            this.setState({
                openApproveConfirm:false,
                sbstatus:'success',
                currentSchduleId: false,
                actionProcessing:false,
            })
        }
    }


    handleClickCancel(rowData){
        this.setState({
          openCancelConfirm:true,
          currentSchduleId:rowData.s_id,
          currentRow:rowData,
          currentTab: 0
        })
    }

    handleClickCancelPending(rowData){
        this.setState({
          openCancelConfirm:true,
          currentSchduleId:rowData.s_id,
          currentRow:rowData,
          currentTab: 1
        })
    }

    handleClickRejectPending(rowData){
        this.setState({
          openRejectConfirm:true,
          currentSchduleId:rowData.s_id,
          currentRow:rowData,
          currentTab: 1
        })
    }

    handleClickApprove(rowData){

        this.setState({
          openApproveConfirm:true,
          currentSchduleId:rowData.s_id,
          currentRow:rowData,
          currentTab:1
        })
    }


  componentDidMount() {

      var ctoken = localStorage.getItem('ctoken');
      var token = localStorage.getItem('token');
      var bodyFormData = new FormData();
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', this.state.offsetActive);
      bodyFormData.append('active', "Active");
      bodyFormData.append('include_past', "");
      bodyFormData.append('pending_only', "Approve");
      bodyFormData.append('order', this.state.currentSortField);
      bodyFormData.append('orderby', this.state.currentSort);



      axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/lessor_schedule',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
              var ongoingRental = [];
              if(response.data.ongoingrental !== null){
                  ongoingRental = response.data.ongoingrental;
              }else{
                  this.setState({noResult:true});
              }

              this.setState({
                totalOngoinRentalActive:response.data.lessor_ongoing_rental_total,
                ongoingRentalActive:ongoingRental,
                totalPagesActive:response.data.total_pages,
                totalRentsActive:response.data.lessor_ongoing_rental_total,
                tableLoading: false,
                cancellationProcessingFee: response.data.company_cancellation_processing_fee,
                cancellationFee: response.data.company_cancellation_fee,
                deposit: response.data.lessor_deposit,
              })
              console.log(response);
              renderFlag = true;


          })
          .catch((error) => {


      });

      var bodyFormData = new FormData();
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', this.state.offsetPending);
      bodyFormData.append('active', "Active");
      bodyFormData.append('pending_only', "1");
      bodyFormData.append('order', this.state.currentSortField);
      bodyFormData.append('orderby', this.state.currentSort);


      axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/lessor_schedule',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {

              var ongoingRental = [];
              if(response.data.ongoingrental !== null){
                  ongoingRental = response.data.ongoingrental;
              }else{
                  this.setState({noResult:true});
              }

              this.setState({
                totalOngoinRentalPending:response.data.lessor_ongoing_rental_total,
                ongoingRentalPending:ongoingRental,
                totalPagesPending:response.data.total_pages,
                totalRentsPending:response.data.lessor_ongoing_rental_total,
                cancellationProcessingFee: response.data.company_cancellation_processing_fee,
                cancellationFee: response.data.company_cancellation_fee,
                deposit: response.data.lessor_deposit,
              })

          })
          .catch((error) => {

      });

      var bodyFormData = new FormData();
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', this.state.offsetPending);
      bodyFormData.append('active', "Active");
      bodyFormData.append('pending_only', "Approve");
      bodyFormData.append('s_release', 0);
      bodyFormData.append('include_past', 1);
      bodyFormData.append('order', this.state.currentSortField);
      bodyFormData.append('orderby', this.state.currentSort);


      axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/lessor_schedule',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {

              var ongoingRental = [];
              if(response.data.ongoingrental !== null){
                  ongoingRental = response.data.ongoingrental;
              }else{
                  this.setState({noResult:true});
              }

              this.setState({
                ongoingRentalOrder:ongoingRental,
                totalPagesOrder:response.data.total_pages,
                totalRentsOrder:response.data.lessor_ongoing_rental_total,
                cancellationProcessingFee: response.data.company_cancellation_processing_fee,
                cancellationFee: response.data.company_cancellation_fee,
                deposit: response.data.lessor_deposit,
              })

          })
          .catch((error) => {

      });

      var bodyFormData = new FormData();
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', this.state.offsetPending);
      bodyFormData.append('active', "Active");
      bodyFormData.append('s_release', 2);
      bodyFormData.append('pending_only', "Approve");
      bodyFormData.append('order', this.state.currentSortField);
      bodyFormData.append('orderby', this.state.currentSort);


      axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/lessor_schedule',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {

              var ongoingRental = [];
              if(response.data.ongoingrental !== null){
                  ongoingRental = response.data.ongoingrental;
              }else{
                  this.setState({noResult:true});
              }

              this.setState({
                ongoingRentalIn:ongoingRental,
                totalPagesIn:response.data.total_pages,
                totalRentsIn:response.data.lessor_ongoing_rental_total,
                cancellationProcessingFee: response.data.company_cancellation_processing_fee,
                cancellationFee: response.data.company_cancellation_fee,
                deposit: response.data.lessor_deposit,
              })

          })
          .catch((error) => {

      });

      var bodyFormData = new FormData();
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', this.state.offsetPending);
      bodyFormData.append('active', "Active");
      bodyFormData.append('s_release', 1);
      bodyFormData.append('pending_only', "Approve");
      bodyFormData.append('order', this.state.currentSortField);
      bodyFormData.append('orderby', this.state.currentSort);


      axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/lessor_schedule',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {

              var ongoingRental = [];
              if(response.data.ongoingrental !== null){
                  ongoingRental = response.data.ongoingrental;
              }else{
                  this.setState({noResult:true});
              }

              this.setState({
                  ongoingRentalOut:ongoingRental,
                  totalPagesOut:response.data.total_pages,
                  totalRentsOut:response.data.lessor_ongoing_rental_total,
                  transactionFee:response.data.company_lessor_transaction_fee,
                  cancellationProcessingFee: response.data.company_cancellation_processing_fee,
                  cancellationFee: response.data.company_cancellation_fee,
                  deposit: response.data.lessor_deposit,
              })

          })
          .catch((error) => {

      });


  }


  handleSearch(keyword,e) {
    this.setState({
        keyword:keyword,
    })
    if(this.state.timeout) clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(() => {
        this.searchList();
    }, 300);


  }

  searchList() {

      var ctoken = localStorage.getItem('ctoken');
      var token = localStorage.getItem('token');
      var bodyFormData = new FormData();
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', this.state.offsetActive);
      bodyFormData.append('keyword', this.state.keyword);
      bodyFormData.append('active', "Active");


      axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/lessor_schedule',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {

              var ongoingRental = [];
              if(response.data.ongoingrental !== null){
                  ongoingRental = response.data.ongoingrental;
              }else{
                  this.setState({noResult:true});
              }

              this.setState({
                totalOngoinRentalActive:response.data.lessor_ongoing_rental_total,
                ongoingRentalActive:ongoingRental,
                totalPagesActive:response.data.total_pages,
                offsetActive:0,
                currentPageActive:0,
                cancellationProcessingFee: response.data.company_cancellation_processing_fee,
                cancellationFee: response.data.company_cancellation_fee,
                deposit: response.data.lessor_deposit,
              })
          })
          .catch((error) => {


      });

  }

  handlePageChangedActive(newPage) {
      this.setState({ currentPageActive : newPage });

      var ctoken = localStorage.getItem('ctoken');
      var token = localStorage.getItem('token');
      var currentOffset = this.state.limit * newPage;
      var bodyFormData = new FormData();
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', currentOffset);
      bodyFormData.append('active', "Active");
      bodyFormData.append('order', this.state.currentSortField);
      bodyFormData.append('orderby', this.state.currentSort);


      axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/lessor_schedule',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
              if(response.data.error > 0){
                  store.addNotification({
                    title: "Session expire",
                    message: response.data.error_msg,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: false
                    }
                  });
              }else{

                  var ongoingRentalActive = [];
                  if(response.data.ongoingrental !== null){
                      ongoingRentalActive = response.data.ongoingrental;
                  }else{
                      this.setState({noResult:true});
                  }

                  this.setState({
                      totalOngoinRentalActive:response.data.lessor_ongoing_rental_total,
                      ongoingRentalActive:ongoingRentalActive,
                      offsetActive: currentOffset,
                      currentTab:0,
                      cancellationProcessingFee: response.data.company_cancellation_processing_fee,
                      cancellationFee: response.data.company_cancellation_fee,
                      deposit: response.data.lessor_deposit,
                  })

                  renderFlag = true;
              }
          })
          .catch((error) => {
              //handle error
              this.setState({ loading:false });
              if (error.response.status == 403) {

                  store.addNotification({
                    title: "Oops Something went wrong",
                    message: "The action you have requested is not Activeowed. please re-load the page",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: false
                    }
                  });

             }

      });
  }

  handlePageChangedPending(newPage) {
      this.setState({ currentPagePending : newPage });

      var ctoken = localStorage.getItem('ctoken');
      var token = localStorage.getItem('token');
      var currentOffset = this.state.limit * newPage;
      var bodyFormData = new FormData();
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', currentOffset);
      bodyFormData.append('active', "Active");
      bodyFormData.append('pending_only', "1");
      bodyFormData.append('order', this.state.currentSortField);
      bodyFormData.append('orderby', this.state.currentSort);


      axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/lessor_schedule',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
              if(response.data.error > 0){
                  store.addNotification({
                    title: "Session expire",
                    message: response.data.error_msg,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: false
                    }
                  });
              }else{

                  var ongoingRentalPending= [];
                  if(response.data.ongoingrental !== null){
                      ongoingRentalPending = response.data.ongoingrental;
                  }else{
                      this.setState({noResult:true});
                  }

                  this.setState({
                      totalOngoinRentalPending:response.data.lessor_ongoing_rental_total,
                      ongoingRentalPending:ongoingRentalPending,
                      offsetPending: currentOffset,
                      currentTab:1,
                      cancellationProcessingFee: response.data.company_cancellation_processing_fee,
                      cancellationFee: response.data.company_cancellation_fee,
                      deposit: response.data.lessor_deposit,
                  })

                  renderFlag = true;
              }
          })
          .catch((error) => {
              //handle error
              this.setState({ loading:false });
              if (error.response.status == 403) {

                  store.addNotification({
                    title: "Oops Something went wrong",
                    message: "The action you have requested is not Activeowed. please re-load the page",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: false
                    }
                  });

             }

      });
  }

  handlePageChangedOrder(newPage) {
      this.setState({ currentPageOrder : newPage });

      var ctoken = localStorage.getItem('ctoken');
      var token = localStorage.getItem('token');
      var currentOffset = this.state.limit * newPage;
      var bodyFormData = new FormData();
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', currentOffset);
      bodyFormData.append('active', "Active");
      bodyFormData.append('pending_only', "Approve");
      bodyFormData.append('s_release', 0);
      bodyFormData.append('include_past', 1);
      bodyFormData.append('order', this.state.currentSortField);
      bodyFormData.append('orderby', this.state.currentSort);


      axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/lessor_schedule',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {

              var ongoingRental = [];
              if(response.data.ongoingrental !== null){
                  ongoingRental = response.data.ongoingrental;
              }else{
                  this.setState({noResult:true});
              }

              this.setState({
                ongoingRentalOrder:ongoingRental,
                totalPagesOrder:response.data.total_pages,
                totalRentsOrder:response.data.lessor_ongoing_rental_total,
                offsetOrder: currentOffset,
                currentTab:2,
                cancellationProcessingFee: response.data.company_cancellation_processing_fee,
                cancellationFee: response.data.company_cancellation_fee,
                deposit: response.data.lessor_deposit,
              })

          })
          .catch((error) => {

      });

  }

  handlePageChangedIn(newPage) {
      this.setState({ currentPageIn : newPage });

      var ctoken = localStorage.getItem('ctoken');
      var token = localStorage.getItem('token');
      var currentOffset = this.state.limit * newPage;
      var bodyFormData = new FormData();
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', currentOffset);
      bodyFormData.append('active', "Active");
      bodyFormData.append('s_release', 2);
      bodyFormData.append('pending_only', "Approve");
      bodyFormData.append('order', this.state.currentSortField);
      bodyFormData.append('orderby', this.state.currentSort);


      axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/lessor_schedule',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {

              var ongoingRental = [];
              if(response.data.ongoingrental !== null){
                  ongoingRental = response.data.ongoingrental;
              }else{
                  this.setState({noResult:true});
              }

              this.setState({
                ongoingRentalIn:ongoingRental,
                totalPagesIn:response.data.total_pages,
                totalRentsIn:response.data.lessor_ongoing_rental_total,
                offsetIn: currentOffset,
                currentTab:4,
                cancellationProcessingFee: response.data.company_cancellation_processing_fee,
                cancellationFee: response.data.company_cancellation_fee,
                deposit: response.data.lessor_deposit,
              })

          })
          .catch((error) => {

      });

  }

  handlePageChangedOut(newPage) {
      this.setState({ currentPageOut : newPage });

      var ctoken = localStorage.getItem('ctoken');
      var token = localStorage.getItem('token');
      var currentOffset = this.state.limit * newPage;
      var bodyFormData = new FormData();
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', currentOffset);
      bodyFormData.append('active', "Active");
      bodyFormData.append('s_release', 1);
      bodyFormData.append('pending_only', "Approve");
      bodyFormData.append('order', this.state.currentSortField);
      bodyFormData.append('orderby', this.state.currentSort);


      axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/lessor_schedule',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {

              var ongoingRental = [];
              if(response.data.ongoingrental !== null){
                  ongoingRental = response.data.ongoingrental;
              }else{
                  this.setState({noResult:true});
              }

              this.setState({
                ongoingRentalOut:ongoingRental,
                totalPagesOut:response.data.total_pages,
                totalRentsOut:response.data.lessor_ongoing_rental_total,
                offsetOut: currentOffset,
                currentTab:3,
                cancellationProcessingFee: response.data.company_cancellation_processing_fee,
                cancellationFee: response.data.company_cancellation_fee,
                deposit: response.data.lessor_deposit,
              })

          })
          .catch((error) => {

      });

  }

  handleRejectReasonChange = (event) => {
    this.setState({
      rejectReason: event.target.value,
      rejectDocument: null,
    });
  }

  handleRejectDocUpload = (event) => {
    console.log(event.target.files[0]);
    this.setState({
      rejectDocument: event.target.files[0],
    });
  }

  render() {
      const { classes } = this.props;

      function GridTable(props) {
          // const classes = useStyles();
          const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)


          };

        //   const totalPage = props.totalPages;
          const onChangePage = props.onChangePage;
        //   const currentPage = props.currentPage;
          const handleClickCancel = props.handleClickCancel;
          const handleClickApprove = props.handleClickApprove;
          const handleClickReject = props.handleClickReject;
        //   const handleSearch = props.handleSearch;

          const [totalPage, setTotalPage] = useState(props.totalPages);
          const [currentPage, setCurrentPage] = useState(props.currentPage);
          const [currentRental, setCurrentRental] = useState(props.rentals);
          const [currentInvoice, setCurrentInvoice] = useState(null);
          const [openInvoice, setOpenInvoice] = useState(null);

        //   var action = [
        //        {
        //            icon: () =>  <DeleteOutline  style={{fill: "red"}} title="test" />,
        //            tooltip: <Typography>Cancel Schedule</Typography>,
        //            onClick: (event, rowData) => handleClickCancel(rowData.s_id)
        //        }
        //    ];

        const handleOpenInvoice = (e,row) => {
            console.log(row);
            setOpenInvoice(true);
            var bodyFormData = new FormData();
            var token = localStorage.getItem('token');
            var ctoken = localStorage.getItem('ctoken');
            // var ctoken = "e52fedd02acc588f4dd2a9e334903564";

            bodyFormData.append('s_id', row.s_id);
            bodyFormData.append('jham_token', token);
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


            axios({
                  method: 'post',
                  url: 'https://api.earental.com/lessor/lessor_schedule_details',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {
                 if(response.data.error > 0){

                     store.addNotification({
                       title: "Oops!",
                       message: "Something went wrong, please try again.",
                       type: "danger",
                       insert: "top",
                       container: "top-right",
                       animationIn: ["animated", "fadeIn"],
                       animationOut: ["animated", "fadeOut"],
                       dismiss: {
                         duration: 5000,
                         onScreen: false
                       }
                     });

                 }else{
                    setCurrentInvoice(response.data.data);

                 }

              })
              .catch((error) => {
                  //handle error
                  console.log(error);

            });
        };



        const handleCloseInvoice = (e) => {
            setCurrentInvoice(false)
            setOpenInvoice(false);
        };
          var action = [];
          var columns = [
            {
              title: 'ID',
              field: 's_id',
              render: row => <Chip onClick={() => handleOpenInvoice(this,row)} variant="outlined" label={row.s_id} />

            },
            { title: 'Invoice No.', field: 'order_invoice_no' },
            { title: 'Name', field: 'customer_fullname',
                render: row => <span>{row.billing_company || (row.billing_fname+" "+row.billing_lname)}</span>
             },
            { title: 'Category', field: 'category_name' },
            { title: 'Vehicle', field: 'product_name' },
            { title: 'Plate No.', field: 'product_platenumber'},
            { title: 'Term', field: 'product_rent_terms' },
            { title: 'Start Rent', field: 's_start_date'},
            { title: 'End Rent', field: 's_end_date'},
            {
              title: 'Lease Rate',
              type: 'numeric',
              field: 's_lease_rate'
            },
            {
              title: 'Deposit Amount',
              type: 'numeric',
              field: 's_deposit_amount'
            },
            {
              title: 'Receivable Amount',
              type: 'numeric',
              field: 's_payable_amount'
            }
          ];
           if(props.type == 1){
               action = [
                    {
                        icon: () =>  <CheckCircleOutlinedIcon style={{fill: "green"}} />,
                        tooltip: <Typography>Approve Schedule</Typography>,
                        onClick: (event, rowData) => handleClickApprove(rowData)
                    },
                    {
                        icon: () =>  <CancelOutlinedIcon style={{fill: "red"}}/>,
                        tooltip: <Typography>Reject Schedule</Typography>,
                        onClick: (event, rowData) => handleClickReject(rowData)
                    }
                ];
           }

           if(props.type == 2){
               action = [
                    {
                        icon: () =>  <EventAvailableOutlinedIcon style={{fill: "green"}} />,
                        tooltip: <Typography>Successful Delivery/Collection</Typography>,
                        onClick: (event, rowData) => handleClickApprove(rowData,1)
                    },
                    {
                        icon: () =>  <EventBusyOutlinedIcon style={{fill: "red"}}/>,
                        tooltip: <Typography>Change/Cancel Order</Typography>,
                        onClick: (event, rowData) => handleClickCancel(rowData,0)
                    }
                ];

                columns = [
                  {
                    title: 'ID', field: 's_id',
                    render: row => <Chip onClick={() => handleOpenInvoice(this,row)} variant="outlined" label={row.s_id} />
                  },
                  { title: 'Invoice No.', field: 'order_invoice_no' },
                  { title: 'Name', field: 'customer_fullname',
                    render: row => <span>{row.billing_company || (row.billing_fname+" "+row.billing_lname)}</span>
                   },
                  { title: 'Category', field: 'category_name' },
                  { title: 'Vehicle', field: 'product_name' },
                  { title: 'Plate No.', field: 'product_platenumber'},
                  { title: 'Term', field: 'product_rent_terms' },
                  { title: 'Start Rent', field: 's_start_date'},
                  { title: 'End Rent', field: 's_end_date'},
                  {
                    title: 'Lease Rate',
                    type: 'numeric',
                    field: 's_lease_rate'
                  },
                  {
                    title: 'Deposit Amount',
                    type: 'numeric',
                    field: 's_deposit_amount'
                  },
                  {
                    title: 'Receivable Amount',
                    type: 'numeric',
                    field: 's_payable_amount'
                  }
                ]
           }
           if(props.type == 3){
               action = [
                    {
                        icon: () =>  <AssignmentReturnedIcon style={{fill: "green"}}/>,
                        tooltip: <Typography>Return</Typography>,
                        onClick: (event, rowData) => handleClickCancel(rowData)
                    }
                ];

                columns = [
                  {
                    title: 'ID', field: 's_id',
                    render: row => <Chip onClick={() => handleOpenInvoice(this,row)} variant="outlined" label={row.s_id} />
                  },
                  { title: 'Invoice No.', field: 'order_invoice_no' },
                  { title: 'Name', field: 'customer_fullname',
                      render: row => <span>{row.billing_company || (row.billing_fname+" "+row.billing_lname)}</span>
                   },
                  { title: 'Category', field: 'category_name' },
                  { title: 'Vehicle', field: 'product_name' },
                  { title: 'Plate No.', field: 'product_platenumber'},
                  { title: 'Term', field: 'product_rent_terms' },
                  { title: 'Start Rent', field: 's_start_date'},
                  { title: 'End Rent', field: 's_end_date'},
                  {
                    title: 'Lease Rate',
                    type: 'numeric',
                    field: 's_lease_rate'
                  },
                  {
                    title: 'Deposit Amount',
                    type: 'numeric',
                    field: 's_deposit_amount'
                  },
                  {
                    title: 'Receivable Amount',
                    type: 'numeric',
                    field: 's_payable_amount'
                  }
                ]
           }
          const currentSort = props.currentSort;

          return (
                <>
                    {(currentInvoice) &&
                        <Dialog
                            fullWidth={true}
                            maxWidth = {'md'}
                            open={openInvoice}
                            onClose={handleCloseInvoice}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                          <Grid container >
                              <Grid item md={6} sm={6} xs={6}>
                                  {currentInvoice.s_id}
                              </Grid>
                              <Grid item md={6} sm={6} xs={6} align="right">
                                  <p style={{fontSize:"15px",fontWeight:"300"}}>Transaction Date: {moment(currentInvoice.s_date_added).format('lll')}</p>
                              </Grid>

                          </Grid>


                          </DialogTitle>
                          <DialogContent>

                              <Grid container spacing={3} className={classes.newCardForm}>
                                  <Grid item md={12} sm={12} xs={12}>
                                      <p><AccountCircleOutlinedIcon /> <b>Lessee Information</b></p>
                                  </Grid>
                              </Grid>

                              <Divider />
                              <br />
                              <Grid container spacing={3} className={classes.newCardForm}>

                                  {(currentInvoice.billing_company) &&
                                      <>
                                          <Grid item md={5} sm={12} xs={12}>
                                              <TextField
                                                  label="Company Name"
                                                  type="text"
                                                  value={currentInvoice.billing_company}
                                                  fullWidth


                                               />
                                          </Grid>
                                          <Grid item md={5} sm={12} xs={12}>
                                              <TextField
                                                  label="ROC"
                                                  type="text"
                                                  value={currentInvoice.billing_roc}
                                                  fullWidth


                                               />
                                          </Grid>
                                          <Grid item md={2} sm={12} xs={12} align="center">
                                              {(currentInvoice.billing_purpose == "business") &&
                                                  <Button startIcon={<PictureAsPdfIcon />} variant="outlined" color="primary"  size="large" href={"https://api.earental.com/uploads/customer-address/"+currentInvoice.billing_company_acra} target="_blank">
                                                  ACRA
                                                  </Button>
                                              }
                                          </Grid>
                                      </>

                                  }

                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="First Name"
                                          type="text"
                                          value={currentInvoice.billing_fname}
                                          fullWidth


                                       />
                                  </Grid>
                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="Last Name"
                                          type="text"
                                          value={currentInvoice.billing_lname}
                                          fullWidth


                                       />
                                  </Grid>
                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="NRIC"
                                          type="text"
                                          value={currentInvoice.billing_nric}
                                          fullWidth

                                       />
                                  </Grid>


                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="Email"
                                          type="text"
                                          value={currentInvoice.customer_email}
                                          fullWidth

                                       />
                                  </Grid>
                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="Phone No."
                                          type="text"
                                          value={currentInvoice.billing_telephone}
                                          fullWidth

                                       />
                                  </Grid>
                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="Mobile No."
                                          type="text"
                                          value={currentInvoice.billing_mobile}
                                          fullWidth

                                       />
                                  </Grid>
                                  <Grid item md={12} sm={12} xs={12}>
                                      <TextField
                                          label="Address"
                                          type="text"
                                          value={currentInvoice.billing_address}
                                          fullWidth

                                       />
                                  </Grid>
                                  {(currentInvoice.billing_purpose == "personal") &&
                                      <>
                                          <Grid item md={6} sm={6} xs={6} >
                                              <div className="clearfix">
                                                  <div className="">
                                                      <CardMedia
                                                        className={classes.media}
                                                        image={"https://api.earental.com/uploads/profile/"+currentInvoice.billing_nric_front_img}
                                                      />
                                                      <label>NRIC Front</label>
                                                  </div>
                                              </div>
                                          </Grid>

                                          <Grid item md={6} sm={6} xs={6} >
                                              <div className="clearfix">
                                                  <div className="">
                                                      <CardMedia
                                                        className={classes.media}
                                                        image={"https://api.earental.com/uploads/profile/"+currentInvoice.billing_nric_back_img}
                                                      />
                                                      <label>NRIC Back</label>
                                                  </div>
                                              </div>
                                          </Grid>
                                      </>
                                  }

                              </Grid>

                              <br />
                              <br />
                              <p><FaceOutlinedIcon /> <b>First Driver Information</b></p>
                              <Divider />
                              <br />

                              <Grid container spacing={3} className={classes.newCardForm}>
                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="Full Name"
                                          type="text"
                                          value={currentInvoice.cd_driver_name1}
                                          fullWidth


                                       />
                                  </Grid>
                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="NRIC"
                                          type="text"
                                          value={currentInvoice.cd_nric1}
                                          fullWidth


                                       />
                                  </Grid>
                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="License No."
                                          type="text"
                                          value={currentInvoice.cd_driver_licenseno1}
                                          fullWidth


                                       />
                                  </Grid>
                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="Contact No."
                                          type="text"
                                          value={currentInvoice.cd_mobile_no1}
                                          fullWidth


                                       />
                                  </Grid>
                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="Date of birth"
                                          type="text"
                                          value={currentInvoice.cd_birthdate1}
                                          fullWidth


                                       />
                                  </Grid>
                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="Driving Exp"
                                          type="text"
                                          value={currentInvoice.cd_driver_yearexp1}
                                          fullWidth


                                       />
                                  </Grid>
                                  <Grid item md={12} sm={12} xs={12}>
                                      <TextField
                                          label="Address"
                                          type="text"
                                          value={currentInvoice.cd_address1}
                                          fullWidth


                                       />
                                  </Grid>

                                  <Grid item md={6} sm={6} xs={6} >
                                      <div className="clearfix">
                                          <div className="">
                                              <CardMedia
                                                className={classes.media}
                                                image={"https://api.earental.com/uploads/profile/"+currentInvoice.cd_photo_nricfront1}
                                              />
                                              <label>NRIC Front</label>
                                          </div>
                                      </div>
                                  </Grid>

                                  <Grid item md={6} sm={6} xs={6} >
                                      <div className="clearfix">
                                          <div className="">
                                              <CardMedia
                                                className={classes.media}
                                                image={"https://api.earental.com/uploads/profile/"+currentInvoice.cd_photo_nricback1}
                                              />
                                              <label>NRIC Back</label>
                                          </div>
                                      </div>
                                  </Grid>

                                  <Grid item md={6} sm={6} xs={6} >
                                      <div className="clearfix">
                                          <div className="">
                                              <CardMedia
                                                className={classes.media}
                                                image={"https://api.earental.com/uploads/profile/"+currentInvoice.cd_photo_driverlicfront1}
                                              />
                                              <label>License Front</label>
                                          </div>
                                      </div>
                                  </Grid>

                                  <Grid item md={6} sm={6} xs={6} >
                                      <div className="clearfix">
                                          <div className="">
                                              <CardMedia
                                                className={classes.media}
                                                image={"https://api.earental.com/uploads/profile/"+currentInvoice.cd_photo_driverlicback1}
                                              />
                                              <label>License Back</label>
                                          </div>
                                      </div>
                                  </Grid>

                              </Grid>
                              {(currentInvoice.cd_driver_name2) &&
                                  <>
                                      <br />
                                      <br />
                                      <p><FaceOutlinedIcon />  <b>Second Driver Information</b></p>
                                      <Divider />
                                      <br />

                                      <Grid container spacing={3} className={classes.newCardForm}>
                                          <Grid item md={4} sm={12} xs={12}>
                                              <TextField
                                                  label="Full Name"
                                                  type="text"
                                                  value={currentInvoice.cd_driver_name2}
                                                  fullWidth


                                               />
                                          </Grid>
                                          <Grid item md={4} sm={12} xs={12}>
                                              <TextField
                                                  label="NRIC"
                                                  type="text"
                                                  value={currentInvoice.cd_nric2}
                                                  fullWidth


                                               />
                                          </Grid>
                                          <Grid item md={4} sm={12} xs={12}>
                                              <TextField
                                                  label="License No."
                                                  type="text"
                                                  value={currentInvoice.cd_driver_licenseno2}
                                                  fullWidth


                                               />
                                          </Grid>
                                          <Grid item md={4} sm={12} xs={12}>
                                              <TextField
                                                  label="Contact No."
                                                  type="text"
                                                  value={currentInvoice.cd_mobile_no2}
                                                  fullWidth


                                               />
                                          </Grid>
                                          <Grid item md={4} sm={12} xs={12}>
                                              <TextField
                                                  label="Date of birth"
                                                  type="text"
                                                  value={currentInvoice.cd_birthdate2}
                                                  fullWidth


                                               />
                                          </Grid>
                                          <Grid item md={4} sm={12} xs={12}>
                                              <TextField
                                                  label="Driving Exp"
                                                  type="text"
                                                  value={currentInvoice.cd_driver_yearexp2}
                                                  fullWidth


                                               />
                                          </Grid>
                                          <Grid item md={12} sm={12} xs={12}>
                                              <TextField
                                                  label="Address"
                                                  type="text"
                                                  value={currentInvoice.cd_address2}
                                                  fullWidth


                                               />
                                          </Grid>

                                          <Grid item md={6} sm={6} xs={6} >
                                              <div className="clearfix">
                                                  <div className="">
                                                      <CardMedia
                                                        className={classes.media}
                                                        image={"https://api.earental.com/uploads/profile/"+currentInvoice.cd_photo_nricfront2}
                                                      />
                                                      <label>NRIC Front</label>
                                                  </div>
                                              </div>
                                          </Grid>

                                          <Grid item md={6} sm={6} xs={6} >
                                              <div className="clearfix">
                                                  <div className="">
                                                      <CardMedia
                                                        className={classes.media}
                                                        image={"https://api.earental.com/uploads/profile/"+currentInvoice.cd_photo_nricback2}
                                                      />
                                                      <label>NRIC Back</label>
                                                  </div>
                                              </div>
                                          </Grid>

                                          <Grid item md={6} sm={6} xs={6} >
                                              <div className="clearfix">
                                                  <div className="">
                                                      <CardMedia
                                                        className={classes.media}
                                                        image={"https://api.earental.com/uploads/profile/"+currentInvoice.cd_photo_driverlicfront2}
                                                      />
                                                      <label>License Front</label>
                                                  </div>
                                              </div>
                                          </Grid>

                                          <Grid item md={6} sm={6} xs={6} >
                                              <div className="clearfix">
                                                  <div className="">
                                                      <CardMedia
                                                        className={classes.media}
                                                        image={"https://api.earental.com/uploads/profile/"+currentInvoice.cd_photo_driverlicback2}
                                                      />
                                                      <label>License Back</label>
                                                  </div>
                                              </div>
                                          </Grid>

                                      </Grid>
                                  </>
                              }

                              <br />
                              <br />
                              <p><b><DateRangeOutlinedIcon /> Rental Information</b></p>
                              <Divider />
                              <br />

                              <Grid container spacing={3} className={classes.newCardForm}>
                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="Category"
                                          type="text"
                                          value={currentInvoice.category_name}
                                          fullWidth


                                       />
                                  </Grid>
                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="Vehicle Name"
                                          type="text"
                                          value={currentInvoice.product_name}
                                          fullWidth


                                       />
                                  </Grid>
                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="Plate No."
                                          type="text"
                                          value={currentInvoice.product_platenumber}
                                          fullWidth


                                       />
                                  </Grid>
                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="Start Date"
                                          type="text"
                                          value={currentInvoice.s_start_date}
                                          fullWidth


                                       />
                                  </Grid>
                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="End Date"
                                          type="text"
                                          value={currentInvoice.s_end_date}
                                          fullWidth


                                       />
                                  </Grid>
                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="Term"
                                          type="text"
                                          value={currentInvoice.product_rent_terms}
                                          fullWidth


                                       />
                                  </Grid>
                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="Rental Type"
                                          type="text"
                                          value={currentInvoice.s_payment_mode}
                                          fullWidth


                                       />
                                  </Grid>
                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="Rate"
                                          type="text"
                                          value={currentInvoice.s_lease_rate}
                                          fullWidth


                                       />
                                  </Grid>
                                  <Grid item md={4} sm={12} xs={12}>
                                      <TextField
                                          label="Deposit Amount"
                                          type="text"
                                          value={currentInvoice.s_deposit_amount}
                                          fullWidth


                                       />
                                  </Grid>
                              </Grid>
                              <br />
                              <br />
                              <Grid container spacing={3} className={classes.newCardForm}>
                                <Grid item md={12} sm={12} xs={12} align="right">
                                    <Button variant="contained"
                                      size="large"
                                      color="default"
                                      target="_blank"
                                      href={"https://api.earental.com/lessor/view_agreement/lease/"+currentInvoice.s_id+"/"+currentInvoice.vo_id}>
                                      signed Lease agreement
                                    </Button>
                                    &nbsp;
                                    &nbsp;
                                    <Button variant="contained"
                                      size="large"
                                      color="default"
                                      target="_blank"
                                      href={"https://api.earental.com/lessor/view_agreement/insurance/"+currentInvoice.s_id+"/"+currentInvoice.vo_id}>
                                      signed Insurance Agreement
                                    </Button>
                                </Grid>
                            </Grid>

                              <br />
                              <br />
                              <p><b><AddShoppingCartOutlinedIcon /> Rental Addons</b></p>
                              <Divider />
                              <br />
                              {!currentInvoice.sa_items && (
                                  <Typography>No Addons availed</Typography>
                              )}
                              {currentInvoice.sa_items && (
                                  <Grid container spacing={3} className={classes.newCardForm}>
                                      {currentInvoice.sa_items.map((item,i) => {
                                          return (
                                              <>
                                                  <Grid item md={4} sm={12} xs={12}>
                                                      <TextField
                                                          label="Item"
                                                          type="text"
                                                          value={item.trim()}
                                                          fullWidth


                                                       />
                                                  </Grid>
                                                  <Grid item md={4} sm={12} xs={12}>
                                                      <TextField
                                                          label="Quantity"
                                                          type="text"
                                                          value={currentInvoice.sa_qtys[i].trim()}
                                                          fullWidth


                                                       />
                                                  </Grid>
                                                  <Grid item md={4} sm={12} xs={12}>
                                                      <TextField
                                                          label="Price"
                                                          type="text"
                                                          value={currentInvoice.sa_amounts[i].trim()}
                                                          fullWidth


                                                       />
                                                  </Grid>
                                              </>
                                          );

                                      })}

                                  </Grid>
                              )}

                          </DialogContent>
                          <DialogActions>
                              {props.type == 1 && (
                                  <>
                                      <Button variant="contained" size="large"  color="primary" onClick={handleClickReject.bind(this,currentInvoice)}>
                                        Reject
                                      </Button>
                                      <Button variant="contained" size="large" color="primary" onClick={handleClickApprove.bind(this,currentInvoice)} >
                                          Approve
                                      </Button>
                                  </>
                              )}

                              {props.type == 2 && (
                                  <>
                                      <Button variant="contained" size="large"  color="primary" onClick={handleClickCancel.bind(this,currentInvoice,0)} >
                                        Change/Cancel Order
                                      </Button>
                                      <Button variant="contained" size="large" color="primary" onClick={handleClickApprove.bind(this,currentInvoice,1)} >
                                          Successful Delivery/Collection
                                      </Button>
                                  </>
                              )}

                              {props.type == 3 && (
                                  <>
                                      <Button variant="contained" size="large" color="primary" onClick={handleClickCancel.bind(this,currentInvoice)} >
                                          Return
                                      </Button>
                                  </>
                              )}

                          </DialogActions>
                        </Dialog>
                    }

                    <div className="schedule-table">
                     <MaterialTable
                       icons={tableIcons}
                       title={props.title}
                       isLoading={props.isLoading}
                       onOrderChange={(orderedColumnField, orderDirection) => {
                          props.handleSortChange(orderedColumnField,orderDirection)
                        }}
                       columns={columns}
                       data={currentRental}
                       actions={action}
                       options={{
                        sorting: true,
                        thirdSortClick: false,
                        draggable:false,
                        search:false,
                        pageSize: currentRental.length,
                        selection: false,
                        doubleHorizontalScroll: true,
                        minBodyHeight: 200,
                        pageSizeOptions : [10,50,100],
                        rowStyle: rowData => ({
                            backgroundColor: ((rowData.due_start == 1 || rowData.due_end == 1) && props.type == 1 ) ? '#B22222' : '#fff',
                            color: ((rowData.due_start == 1 || rowData.due_end == 1) && props.type == 1 ) ? '#fff' : '#111',
                        }),

                       }}
                       components={{
                          Pagination: props => (
                            (
                                <Pager
                                    total={totalPage}
                                    current={currentPage}
                                    visiblePages={5}
                                    titles={{ first: 'First', last: 'Last' }}
                                    className="pagination-md"
                                    onPageChanged={onChangePage}
                                    className="pull-right"
                                />
                            )
                          )
                        }}
                     />
                    </div>
                </>


          );
        }

        function Alert(props) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }


    function SimpleTabs(props) {
      // const classes = useStyles();
      const [value, setValue] = React.useState(props.currentTab);

      const handleChange = (event, newValue) => {
          console.log(newValue);
          props.changeCurrentTable(newValue);
        setValue(newValue);
      };

      function a11yProps(index) {
          return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
          };
        }

        function TabPanel(props) {
          const { children, value, index, ...other } = props;

          return (
            <div
              role="tabpanel"
              className="schedule-tab"
              hidden={value !== index}
              id={`simple-tabpanel-${index}`}
              aria-labelledby={`simple-tab-${index}`}
              {...other}
            >
              {value === index && (
                <Box p={3}>
                  <Typography>{children}</Typography>
                </Box>
              )}
            </div>
          );
        }

        TabPanel.propTypes = {
          children: PropTypes.node,
          index: PropTypes.any.isRequired,
          value: PropTypes.any.isRequired,
        };


      return (
        <div>
          <AppBar position="static">
            <Tabs value={value} onChange={handleChange} aria-label="schedule tabs">
                <Tab label={<Badge badgeContent={props.totalRentActive} color="secondary">
                    Active Schedules &nbsp;&nbsp;&nbsp;
                </Badge>} {...a11yProps(0)} />
                <Tab label={<Badge badgeContent={props.totalRentPending} color="secondary">
                  Pending Schedules &nbsp;&nbsp;&nbsp;
                </Badge>} {...a11yProps(1)} />
                <Tab label={<Badge badgeContent={props.totalRentOrder} color="secondary">
                  Delivery Order &nbsp;&nbsp;&nbsp;
                </Badge>} {...a11yProps(2)} />
                <Tab label={<Badge badgeContent={props.totalRentOut} color="secondary">
                  Out &nbsp;&nbsp;&nbsp;
                </Badge>} {...a11yProps(3)} />
                <Tab label={<Badge badgeContent={props.totalRentIn} color="secondary">
                  In &nbsp;&nbsp;&nbsp;
                </Badge>} {...a11yProps(4)} />

            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            {props.ActiveList}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {props.PendingList}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {props.DeliveryOrderList}
          </TabPanel>
          <TabPanel value={value} index={3}>
            {props.OutList}
          </TabPanel>
          <TabPanel value={value} index={4}>
            {props.InList}
          </TabPanel>
        </div>
      );
    }

    function SelectVehicle(props) {
        // const classes = useStyles();

        const [checked, setChecked] = React.useState(0);
        const handleChange = (value) => () => {
            props.handleChangeVehicle(value)
            setChecked(value);
        };


        console.log(props.products)

        return (

            <List dense>
             {props.products.map((value) => {
               const labelId = "checkbox-list-secondary-label-${value.product_id}";
               return (
                    <div key={"sched-"+value.product_id}>
                        <ListItem key={value} button onClick={handleChange(value.product_id)}>
                            <ListItemAvatar>
                            <Avatar
                                alt={value.product_name}
                                src={"https://api.earental.com/uploads/vehicles/"+value.product_image}
                            />
                            </ListItemAvatar>
                            <ListItemText className="change-product-list" id={labelId}
                                primary={value.product_name+" | "+value.product_platenumber+" | "+value.product_brand+" "+value.product_model}
                                secondary={value.product_type+" | "+value.product_transmission+" | "+value.product_gas}
                            />
                            <ListItemSecondaryAction>
                            <Radio
                                edge="end"
                                checked={checked == value.product_id}
                                onChange={handleChange(value.product_id)}
                                value="a"
                                name="radio-button-demo"
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </div>

               );
             })}
           </List>
        );
    }


    function LateCharges(props){
        const [inputList, setInputList] = useState(props.lateCharges);
        const [totalCharges, setTotalCharges] = useState(0.00);
        const [totalChargesWithFee, setTotalChargesWithFee] = useState(0.00);
        const [notifyCS, setNotifyCS] = useState(false);

        React.useEffect(() => {
          var depositAmount = parseFloat(props.schedule.s_deposit_amount);
          var unpaidMonthsAmount = props.chargeEarlyReturn ? parseFloat(props.unpaidMonthsAmount) : 0;
          var subTotal = 0.00;
          var subTotalWithFee = 0.00;
          var totalCharges = 0.00;
          for (var i = 0 ; i < props.lateCharges.length ; i++) {
            if(props.lateCharges[i].amount != ""){
              subTotal += parseFloat(props.lateCharges[i].amount);
              if (props.lateCharges[i].type != 1 || typeof props.lateCharges[i].type == "undefined") {
                subTotalWithFee += parseFloat(props.lateCharges[i].amount);
              }
            }
          }
          // totalCharges = (parseFloat(props.transactionFee) / 100) * subTotal;
          totalCharges = (parseFloat(props.transactionFee) / 100) * subTotalWithFee;
          totalCharges = subTotal + totalCharges;
          setTotalCharges(subTotal);
          setTotalChargesWithFee(totalCharges);

          if (totalCharges + unpaidMonthsAmount > depositAmount) {
            setNotifyCS(true);
          } else {
            setNotifyCS(false);
          }
        }, [props.lateCharges]);

         // handle input change
         const handleAddOnChange = (e, index) => {
            const depositAmount = parseFloat(props.schedule.s_deposit_amount);
            const unpaidMonthsAmount = props.chargeEarlyReturn ? parseFloat(props.unpaidMonthsAmount) : 0;
            const { name, value } = e.target;
            const list = [...inputList];

            list[index][name] = value;

            setInputList(list);
            var subTotal = 0.00;
            var totalCharges = 0.00;
            for(var i = 0 ; i < inputList.length ; i++)
            {
                if(inputList[i].amount != ""){
                    subTotal += parseFloat(inputList[i].amount);
                }

            }
            totalCharges = (parseFloat(props.transactionFee) / 100) * subTotal
            totalCharges = subTotal + totalCharges;
            setTotalCharges(subTotal);
            setTotalChargesWithFee(totalCharges);

            if (totalCharges + unpaidMonthsAmount > depositAmount) {
              setNotifyCS(true);
            } else {
              setNotifyCS(false);
            }
         };

         // handle click event of the Remove button
         const handleRemoveClick = index => {
           const list = [...inputList];
           list.splice(index, 1);
           setInputList(list);
         };

         // handle click event of the Add button
         const handleAddClick = () => {
           setInputList([...inputList, { title: "", amount: "0.00" }]);
         };

         return(
             <div className="clearfix">
                 <Grid container spacing={0} >
                     {inputList.map((x, i) => {
                       return (
                           <Grid container md={12} sm={12} xs={12}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                    label="Title"
                                    value={x.title}
                                    name="title"
                                    fullWidth
                                    onChange={e => handleAddOnChange(e, i)}
                                    disabled={x.disabled ? x.disabled : false}
                                    />
                                </Grid>
                                <Grid item md={4} sm={12} xs={12} align="right">
                                    <TextField
                                    min="0"
                                    type="number"
                                    label="Amount"
                                    name="amount"
                                    value={x.amount}
                                    name="amount"
                                    onChange={e => handleAddOnChange(e, i)}
                                    disabled={x.disabled ? x.disabled : false}
                                    />
                                </Grid>

                                <Grid item md={2} sm={12} xs={12} align="right">

                                    {inputList.length !== 1 && !x.disabled &&
                                    <BackspaceRoundedIcon onClick={() => handleRemoveClick(i)} style={{fill: "red",fontSize:"30px",marginTop:"10px"}} alt="remove row" title="remove row" />
                                    }
                                    {inputList.length - 1 === i &&
                                    <AddBoxRoundedIcon onClick={handleAddClick} style={{fill: "green",fontSize:"30px",marginTop:"10px"}} alt="add row"  title="add row"  />}
                                </Grid>

                            </Grid>
                       );
                     })}
                 </Grid>
                 <Grid container spacing={5} >
                     <Grid item md={10} sm={12} xs={12} align="right">
                          <p style={{margin:"0px"}}>
                              <b>Sub Total : </b>
                              <NumberFormat style={{fontSize:"20px"}} value={totalCharges} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'S$'} />
                          </p>
                          <p style={{margin:"0px"}}>
                              <b>Transaction Fee : </b>
                              <NumberFormat style={{fontSize:"20px"}} value={parseFloat(props.transactionFee)} displayType={'text'} decimalScale={2} thousandSeparator={true} suffix="%" />
                          </p>
                          <p style={{margin:"0px"}}>
                              <b>Total Charges : </b>
                              <NumberFormat style={{fontSize:"20px"}} value={totalChargesWithFee} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'S$'} />
                          </p>
                          <p style={{margin:"0px"}}>
                              <b>Deposit : </b>
                              <NumberFormat style={{ fontSize: "20px" }} value={parseFloat(props.schedule.s_deposit_amount)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'S$'} />
                          </p>
                          {notifyCS && props.unpaidMonthsAmount > 0 && props.chargeEarlyReturn && (
                            <p style={{ margin: "0px" }}>
                              <b>Remaining {props.unpaidMonthsCount} months : </b>
                              <NumberFormat style={{ fontSize: "20px" }} value={props.unpaidMonthsAmount} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'S$'} />
                            </p>
                          )}
                          {notifyCS && (
                              <p style={{margin:"0px"}}>
                                  <b>Amount Short : </b>
                                  <NumberFormat
                                    style={{ fontSize: "20px" }}
                                    value={parseFloat(totalChargesWithFee) + (props.chargeEarlyReturn ? props.unpaidMonthsAmount : 0) - parseFloat(props.schedule.s_deposit_amount)}
                                    displayType={'text'}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    prefix={'S$'} />
                              </p>
                          )}

                     </Grid>
                 </Grid>
                 <br/>
                {notifyCS && (
                  <React.Fragment>
                    <Grid>
                      <Typography variant="caption" component="p" color="secondary" style={{ fontSize: 14, marginBottom: 16 }}>
                        Lessee's deposit is short by an amount of <NumberFormat value={parseFloat(totalChargesWithFee) + (props.chargeEarlyReturn ? props.unpaidMonthsAmount : 0) - parseFloat(props.schedule.s_deposit_amount)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'S$'} /> to completely settle the additional charges you specified.
                      </Typography>
                      <Typography variant="caption" component="p" style={{ fontSize: 12 }}>
                        By clicking "Notify Customer Service", we will process the additional charges based on the available deposit. Our Customer Service will attempt to contact the lessee for the balance payment. Once received, our Accounts Team will transfer the payment to you asap.
                      </Typography>
                    </Grid>
                    <br/>
                  </React.Fragment>
                )}
                 <DialogActions>
                     {!props.actionProcessing && (
                        <>
                            <Button variant="contained" size="large" onClick={props.handleChangeLateCharges.bind(this,0,0)} color="secondary">
                                Do it later
                            </Button>
                            <Button variant="contained" size="large" onClick={props.handleChangeLateCharges.bind(this,5,inputList)} color="primary">
                                {!notifyCS ? "Proceed with late charges" : "Notify Customer Service"}
                            </Button>
                        </>
                    )}

                    {props.actionProcessing && (
                        <Button variant="contained" size="large" color="primary"  startIcon={<CircularProgress size="1.5rem" style={{ color: "#FFF" }} />} autoFocus>
                           Processing...
                        </Button>
                    )}

                 </DialogActions>

             </div>
         );
    }


    return (
      <React.Fragment>
        <div className="schedule-cont">
            <div className="">
                <Breadcrumbs aria-label="breadcrumb" style={{position:"relative"}}>
                  <Link color="inherit" href="/">
                      Dashboard
                  </Link>
                  <Typography color="textPrimary">Lease</Typography>
                  <Fab size="small" variant="extended" style={{position:"absolute",right:"0",top:"-6px"}} onClick={this.handleReloadTable}>
                    <SyncIcon  />
                    Refresh list
                  </Fab>
                </Breadcrumbs>

                <br />
                <div className="row">
                    <div className="col-md-12">

                        <Dialog
                          open={this.state.openCancelConfirm}
                          onClose={this.handleCloseCancel.bind(this,false)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{"You're about to cancel a schedule"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Are you sure you want to proceed on cancellation of this schedule #{this.state.currentSchduleId}?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                                {!this.state.actionProcessing && (
                                    <>
                                        <Button variant="contained" size="large" onClick={this.handleCloseCancel.bind(this,false)} color="secondary">
                                          No
                                        </Button>
                                        <Button variant="contained" size="large" onClick={this.handleCloseCancel.bind(this,true)} color="primary" autoFocus>
                                          Yes
                                        </Button>
                                    </>
                                )}

                                {this.state.actionProcessing && (
                                    <Button variant="contained" size="large" color="primary"  startIcon={<CircularProgress size="1.5rem" style={{ color: "#FFF" }} />} autoFocus>
                                       Processing...
                                    </Button>
                                )}
                          </DialogActions>
                        </Dialog>

                        <Dialog
                          open={this.state.openApproveConfirm}
                          onClose={this.handleCloseApprove.bind(this,false)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{"Congratulations! You are about to approve a new lease"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Do you wish to proceed?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                              {!this.state.actionProcessing && (
                                  <>
                                      <Button variant="contained" size="large" onClick={this.handleCloseApprove.bind(this,false)} color="secondary">
                                        No
                                      </Button>
                                      <Button variant="contained" size="large" onClick={this.handleCloseApprove.bind(this,true)} color="primary" autoFocus>
                                        Yes
                                      </Button>
                                  </>
                              )}

                              {this.state.actionProcessing && (
                                  <Button variant="contained" size="large" color="primary"  startIcon={<CircularProgress size="1.5rem" style={{ color: "#FFF" }} />} autoFocus>
                                     Processing...
                                  </Button>
                              )}

                          </DialogActions>
                        </Dialog>

                        <Dialog
                          open={this.state.openRejectConfirm}
                          onClose={this.handleCloseReject.bind(this,false)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">You're about to reject a schedule</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              <Typography variant="body1" gutterBottom>
                                Kindly select your reason for rejecting schedule #{this.state.currentSchduleId}
                              </Typography>
                              <Select variant="outlined" value={this.state.rejectReason} onChange={this.handleRejectReasonChange} fullWidth style={{ marginBottom: 8 }}>
                                <MenuItem value="urgent_maintenance">
                                  Urgent Maintenance (documentation proof required)
                                </MenuItem>
                                <MenuItem value="vehicle_not_available">
                                  Vehicle Not Available
                                </MenuItem>
                                <MenuItem value="lessee_request">
                                  Lessee Requested Cancellation
                                </MenuItem>
                              </Select>
                              {this.state.rejectReason == "urgent_maintenance" && (
                                <Box textAlign="right" marginBottom={1} display="flex" justifyContent="space-between" alignItems="center">
                                  <input
                                    ref={this.fileInputUrgentMaintenance}
                                    type="file"
                                    onChange={this.handleRejectDocUpload}
                                    style={{ display: "none" }}
                                  />
                                  <Typography variant="body1">
                                    {this.state.rejectDocument ? this.state.rejectDocument.name : ""}
                                    {this.state.rejectDocument && (
                                      <Button type="button" onClick={() => this.setState({ rejectDocument: null })} color="default" style={{
                                        minWidth: 0,
                                        borderRadius: '50%',
                                      }}><CloseIcon /></Button>
                                    )}
                                  </Typography>
                                  <Button type="button" variant="contained" onClick={() => this.fileInputUrgentMaintenance.current.click()} color="default">Upload Document</Button>
                                </Box>
                              )}
                              <Typography variant="body1"  gutterBottom>
                                Your comments will be seen by the lessee. Keep your comments to the issue
                              </Typography>
                              <HtmlTooltip
                                title={
                                  <React.Fragment>
                                    <Typography variant="body1">Writing Guidlines</Typography>
                                    <p>{'Your comments will be view by the lessee.'}</p>
                                    <p>{'1. Keep your comment focused on the issue.'}</p>
                                    <p>{'2. Be professional.'}</p>
                                    <p>{'3. Refrain from using vulgarity or inappropriate language.'}</p>
                                    <p>{'4. Do not include any personal contact information.'}</p>

                                  </React.Fragment>
                                }
                                arrow
                                placement="left"
                              >
                                  <TextField
                                    className="reason-text"
                                    label="Message"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    fullWidth={true}
                                    inputRef={this.rejectReason}
                                  />
                              </HtmlTooltip>
                              <Alert variant="outlined" severity="warning" fullWidth={true} elevation={0} style={{ marginTop: 16 }}>
                                Wrongfully reject a lease will result in $200 in cancellation fee. Do you wish to proceed?
                              </Alert>
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>

                            {!this.state.actionProcessing && (
                                 <>
                                     <Button variant="contained" size="large" onClick={this.handleCloseReject.bind(this,false)} color="secondary">
                                       No
                                     </Button>
                                     <Button variant="contained" size="large" onClick={this.handleCloseReject.bind(this,true)} color="primary" autoFocus>
                                       Yes
                                     </Button>
                                 </>
                            )}

                             {this.state.actionProcessing && (
                                 <Button variant="contained" size="large" color="primary"  startIcon={<CircularProgress size="1.5rem" style={{ color: "#FFF" }} />} autoFocus>
                                    Processing...
                                 </Button>
                             )}
                          </DialogActions>
                        </Dialog>

                        <Dialog
                          open={this.state.openSuccessulConfirm}
                          onClose={this.handleCloseSuccessful.bind(this,0)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{"You're about to confirm a Collected/Delivered Product"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              <Typography variant="h6" >
                                Kindly specify start time of collection:
                              </Typography>
                              <br />
                              <Grid container>

                                  <Grid item md={7} sm={12} xs={12} align="center">
                                      <TimeKeeper
                                          time={this.state.selectedStartTime}
                                          onChange={this.handleTimeChange.bind(this)}
                                        />
                                  </Grid>
                                  <Grid item md={5} sm={12} xs={12} align="left">
                                      <h2>This time will be set as start and end rent time of the order</h2>
                                  </Grid>
                              </Grid>

                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                              {(!this.state.actionProcessing && this.state.selectedStartTime) && (
                                   <>
                                       <Button variant="contained" size="large" onClick={this.handleCloseSuccessful.bind(this,1)} color="primary">
                                         Change Product
                                       </Button>
                                       <Button variant="contained" size="large" onClick={this.handleCloseSuccessful.bind(this,2)} color="primary" autoFocus>
                                         Proceed Confirm
                                       </Button>
                                   </>
                               )}

                               {(!this.state.actionProcessing && !this.state.selectedStartTime) && (
                                    <>
                                        <Button variant="contained" size="large" onClick={this.handleCloseSuccessful.bind(this,1)} color="primary">
                                          Change Product
                                        </Button>
                                        <Button variant="contained" size="large" onClick={this.handleCloseSuccessful.bind(this,2)} color="primary" disabled autoFocus>
                                          Proceed Confirm
                                        </Button>
                                    </>
                                )}


                               {this.state.actionProcessing && (
                                   <Button variant="contained" size="large" color="primary"  startIcon={<CircularProgress size="1.5rem" style={{ color: "#FFF" }} />} autoFocus>
                                      Processing...
                                   </Button>
                               )}

                          </DialogActions>
                        </Dialog>


                        <Dialog
                            open={this.state.openChangeVehicle}
                            onClose={this.handleCloseChangeVehicle.bind(this,0)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            fullWidth={true}
                            maxWidth = {'md'}
                        >
                          <DialogTitle id="alert-dialog-title">{"You're about to change product for this order"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Typography variant="h6" >
                                  Kindly select a product you want to replace for this order.
                                </Typography>
                                {this.state.products <= 0 && (
                                    <Alert variant="outlined" severity="warning" fullWidth={true} elevation={0}>
                                        No available product for replacement
                                    </Alert>
                                )}
                                {this.state.products.length > 0 && (
                                  <SelectVehicle handleChangeVehicle={this.handleChangeVehicle} currentChangeVehicle={this.state.currentChangeVehicle} products={this.state.products} />
                                )}

                            </DialogContentText>
                            <Typography variant="h6" >
                              Kindly specify start time of collection:
                            </Typography>
                            <br />
                            <Grid container>

                                <Grid item md={7} sm={12} xs={12} align="center">
                                    <TimeKeeper
                                        time={this.state.selectedStartTime}
                                        onChange={this.handleTimeChange.bind(this)}
                                      />
                                </Grid>
                                <Grid item md={5} sm={12} xs={12} align="left">
                                    <h2>This time will be set as start and end rent time of the order</h2>
                                </Grid>
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                          {(!this.state.actionProcessing && this.state.selectedStartTime) && (
                               <>
                                   <Button variant="contained" size="large" onClick={this.handleCloseChangeVehicle.bind(this,0)} color="secondary" autoFocus>
                                       Do it later
                                   </Button>
                                   <Button variant="contained" size="large" onClick={this.handleCloseChangeVehicle.bind(this,2)} color="primary" autoFocus>
                                       Confirm and use this product
                                   </Button>
                               </>
                           )}

                           {(!this.state.actionProcessing && !this.state.selectedStartTime) && (
                                <>
                                    <Button variant="contained" size="large" onClick={this.handleCloseChangeVehicle.bind(this,0)} color="secondary" autoFocus>
                                      Do it later
                                    </Button>
                                    <Button variant="contained" size="large" onClick={this.handleCloseChangeVehicle.bind(this,2)} color="primary" autoFocus>
                                      Confirm and use this product
                                    </Button>
                                </>
                            )}

                           {this.state.actionProcessing && (
                               <Button variant="contained" size="large" color="primary"  startIcon={<CircularProgress size="1.5rem" style={{ color: "#FFF" }} />} autoFocus>
                                  Processing...
                               </Button>
                           )}

                          </DialogActions>
                        </Dialog>

                        <Dialog
                          open={this.state.openFailedConfirm}
                          onClose={this.handleCloseCancelOrder.bind(this,0)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{"You're about to cancel a Collected/Delivered Product"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              <p>Do you want to change this vehicle?</p>
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button variant="contained" size="large" onClick={this.handleCloseCancelOrder.bind(this,1)} color="primary">
                              Change Product
                            </Button>
                            <Button variant="contained" size="large" onClick={this.handleCloseCancelOrder.bind(this,2)} color="primary" autoFocus>
                              Cancellation Reason
                            </Button>
                          </DialogActions>
                        </Dialog>

                        <Dialog
                          open={this.state.openCancelReason}
                          onClose={this.handleCloseCancelOrder.bind(this,0)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{"You're about to cancel a Collected/Delivered Product"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              <p>Kindly select reason for cancellation. </p>

                              <FormControl component="fieldset">
                                  <RadioGroup aria-label="reason" name="reason" value={this.state.currentCancelReason} onChange={this.handleChangeReason}>
                                      <FormControlLabel value="1" control={<Radio />} label="Lessee Reject: Not as per listing (Lessor's fault)" />
                                      <FormControlLabel value="2" control={<Radio />} label="Lessee changed mind (Lessee's fault)" />
                                  </RadioGroup>
                              </FormControl>
                              <TextField
                                className="reason-text"
                                label="Detailed reason (Optional)"
                                multiline
                                rows={4}
                                variant="outlined"
                                fullWidth={true}
                                inputRef={this.cancelReason}
                              />
                              <br />
                              <br />
                              <Alert variant="outlined" severity="warning" fullWidth={true} elevation={0}>
                                  {this.state.cancelFaultMsg}
                              </Alert>
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            {!this.state.actionProcessing && (
                              <React.Fragment>
                                <Button variant="contained" size="large" onClick={this.handleCloseCancelOrder.bind(this,0)} color="secondary">
                                  Do it later
                                </Button>
                                <Button variant="contained" size="large" onClick={this.handleCloseCancelOrder.bind(this,3)} color="primary" autoFocus>
                                  Proceed Cancellation
                                </Button>
                              </React.Fragment>
                            )}
                            {this.state.actionProcessing &&
                              <Button variant="contained" size="large" color="primary" startIcon={<CircularProgress size="1.5rem" style={{ color: "#FFF" }} />}>
                                Processing...
                              </Button>
                            }
                          </DialogActions>
                        </Dialog>

                        <Dialog
                          open={this.state.openReturnConfirm}
                          onClose={this.handleCloseReturn.bind(this,0,0)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          fullWidth={true}
                          maxWidth = {'sm'}
                        >
                          <DialogTitle id="alert-dialog-title">{"You're about to confirm a returned product"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            <Typography variant="h6" >
                              Kindly specify exact date and time of return:
                            </Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Grid container justify="space-around">
                                <KeyboardDatePicker
                                  margin="normal"
                                  label="Date"
                                  format="MMM d, yyyy"
                                  value={this.state.selectedReturnDate}
                                  onChange={this.handleReturnDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                  inputProps={{style: {fontSize: "15px"}}}
                                  InputLabelProps={{style: {fontSize: "15px"}}}
                                />
                                <KeyboardTimePicker
                                  margin="normal"
                                  label="Time"
                                  value={this.state.selectedReturnDate}
                                  onChange={this.handleReturnDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                  }}
                                  inputProps={{style: {fontSize: "15px"}}}
                                  InputLabelProps={{style: {fontSize: 13}}}
                                />
                              </Grid>
                            </MuiPickersUtilsProvider>

                            {this.state.lateReturn &&
                                <>
                                    <br/>
                                    <Alert severity="warning" style={{fontSize:"15px"}}>
                                        <AlertTitle>Looks like the product was returned late</AlertTitle>
                                        You might want to add additional charges to it.
                                    </Alert>
                                </>
                            }
                              {this.state.earlyReturn && (this.state.currentSchedule.s_payment_mode == "daily" || this.state.currentSchedule.s_payment_mode == "weekend" || this.state.currentSchedule.s_payment_mode == "weekly") && (
                                <Box marginTop={1}>
                                    <Alert severity="warning" style={{fontSize:"15px"}}>
                                      <AlertTitle>This lease will be ended prematurely</AlertTitle>
                                      Remaining days or weeks not used will be forfeited.
                                    </Alert>
                                </Box>
                              )}

                              {this.state.earlyReturn && (this.state.currentSchedule.s_payment_mode == "monthly" || this.state.currentSchedule.s_payment_mode == "1-year" || this.state.currentSchedule.s_payment_mode == "2-years") && (
                                <Box marginTop={1}>
                                    <Alert severity="warning" style={{fontSize:"15px"}}>
                                      <AlertTitle style={{ fontSize: '1.1rem' }}>This lease will be ended prematurely</AlertTitle>
                                      <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body1" style={{ fontSize: '1.1rem' }}>
                                          Do you wish to charge the lessee the amount of <NumberFormat style={{ fontWeight: 600 }} decimalScale={2} value={(this.state.monthlyRate * this.state.unpaidMonthsCount)} displayType={'text'} thousandSeparator={true} prefix={'S$'} decimalScale={2} fixedDecimalScale={(this.state.monthlyRate * this.state.unpaidMonthsCount) % 1 != 0} /> for the remaining unpaid {this.state.unpaidMonthsCount} months?
                                        </Typography>
                                        <FormControlLabel
                                          control={<Checkbox
                                            name="chargeRemainingAmount"
                                            value="1"
                                            onChange={this.handleChargeRemainingAmount}
                                            checked={this.state.chargeRemainingAmount}
                                            style={{ padding: 0, paddingRight: 4, color: '#FFF', transform: 'scale(1.1)' }}
                                          />}
                                          label={(
                                            <Typography variant="body1" style={{ fontSize: '1.1rem' }}>Yes</Typography>
                                          )}
                                          // style={{ margin: 0, marginLeft: 16 }}
                                        />
                                      </Box>
                                    </Alert>
                                </Box>
                              )}

                            <br/>

                              <p style={{fontSize:"15px"}}>
                                  Are there additional charges for this order? &nbsp;&nbsp;
                                  {this.state.returnType == 1 &&
                                      <Button variant="contained" size="small" onClick={this.handleCloseReturn.bind(this,3,0)} color="primary" elevation={0} >
                                          Yes, I will Indicate Charges
                                      </Button>
                                  }
                                  {this.state.returnType == 2 &&
                                      <Button variant="contained" size="small" onClick={this.handleCloseReturn.bind(this,4,0)} color="primary" elevation={0} >
                                          No, Everyhing is fine
                                      </Button>
                                  }

                              </p>
                                {this.state.returnType == 2 &&
                                    <LateCharges
                                        handleChangeLateCharges={this.handleCloseReturn}
                                        lateCharges={this.state.lateCharges}
                                        schedule={this.state.currentSchedule}
                                        actionProcessing={this.state.actionProcessing}
                                        transactionFee={this.state.transactionFee}
                                        unpaidMonthsAmount={this.state.unpaidMonthsCount * this.state.monthlyRate}
                                        unpaidMonthsCount={this.state.unpaidMonthsCount}
                                        chargeEarlyReturn={this.state.chargeRemainingAmount}
                                    />
                                }


                            </DialogContentText>
                          </DialogContent>

                            <DialogActions>
                            {(this.state.returnType == 1 && !this.state.actionProcessing) &&
                                <>
                                    <Button variant="contained" size="large" onClick={this.handleCloseReturn.bind(this,0,0)} color="secondary">
                                        Do it later
                                    </Button>
                                    <Button variant="contained" size="large" onClick={this.handleCloseReturn.bind(this,1,0)} color="primary">
                                        Proceed without late charges
                                    </Button>
                                </>

                            }

                            {(this.state.returnType == 1 && this.state.actionProcessing) &&
                                <Button variant="contained" size="large" color="primary"  startIcon={<CircularProgress size="1.5rem" style={{ color: "#FFF" }} />}>
                                   Processing...
                                </Button>
                            }

                            </DialogActions>




                        </Dialog>



                        <TextField id="standard-basic"
                            label="Search Keyword"
                            className="table-search"
                            value={this.state.keyword}
                            onChange={e => this.handleSearch(e.target.value)}
                            />
                        <SimpleTabs
                            currentTab={this.state.currentTab}
                            totalRentActive={this.state.totalRentsActive}
                            totalRentPending={this.state.totalRentsPending}
                            totalRentOrder={this.state.totalRentsOrder}
                            totalRentIn={this.state.totalRentsIn}
                            totalRentOut={this.state.totalRentsOut}
                            changeCurrentTable={this.handleCurrentTab}
                            ActiveList={
                                <GridTable
                                    type="0"
                                    title={'Active Listing'}
                                    rentals={this.state.ongoingRentalActive}
                                    totalPages={this.state.totalPagesActive}
                                    currentPage={this.state.currentPageActive}
                                    onChangePage={this.handlePageChangedActive}
                                    handleClickCancel={this.handleClickCancel}
                                    handleSortChange={this.handleSortChangeActive}
                                    currentSort={this.state.currentSort}
                                    isLoading={this.state.tableLoading}
                                />}

                            PendingList={
                                <GridTable
                                    type="1"
                                    title={'Pending Listing'}
                                    rentals={this.state.ongoingRentalPending}
                                    totalPages={this.state.totalPagesPending}
                                    currentPage={this.state.currentPagePending}
                                    onChangePage={this.handlePageChangedPending}
                                    handleClickApprove={this.handleClickApprove}
                                    handleClickCancel={this.handleClickCancelPending}
                                    handleClickReject={this.handleClickRejectPending}
                                    handleSortChange={this.handleSortChangePending}
                                    currentSort={this.state.currentSort}
                                    isLoading={this.state.tableLoading}
                                />}
                            DeliveryOrderList={
                                <GridTable
                                    type="2"
                                    title={'Delivery Order Listing'}
                                    rentals={this.state.ongoingRentalOrder}
                                    totalPages={this.state.totalPagesOrder}
                                    currentPage={this.state.currentPageOrder}
                                    onChangePage={this.handlePageChangedOrder}
                                    handleClickApprove={this.handleClickSuccessul}
                                    handleClickCancel={this.handleClickSuccessul}
                                    currentSort={this.state.currentSort}
                                    isLoading={this.state.tableLoading}
                                />}
                            OutList={
                                <GridTable
                                    type="3"
                                    title={'Out Listing'}
                                    rentals={this.state.ongoingRentalOut}
                                    totalPages={this.state.totalPagesOut}
                                    currentPage={this.state.currentPageOut}
                                    onChangePage={this.handlePageChangedOut}
                                    handleClickCancel={this.handleClickOutReturn}
                                    currentSort={this.state.currentSort}
                                    isLoading={this.state.tableLoading}
                                />}
                            InList={
                                <GridTable
                                    type="4"
                                    title={'In Listing'}
                                    rentals={this.state.ongoingRentalIn}
                                    totalPages={this.state.totalPagesIn}
                                    currentPage={this.state.currentPageIn}
                                    onChangePage={this.handlePageChangedIn}
                                    handleClickApprove={this.handleClickSuccessul}
                                    handleClickCancel={this.handleClickSuccessul}
                                    currentSort={this.state.currentSort}
                                    isLoading={this.state.tableLoading}
                                />}

                        />

                    </div>
                </div>
            </div>

            <Snackbar open={this.state.openSb} autoHideDuration={6000} onClose={this.handleCloseSb}>
                <Alert onClose={this.handleCloseSb} severity={this.state.sbstatus} className="sb-msg">
                    {this.state.sbMessage}
                </Alert>
            </Snackbar>


            <div className="gap"></div>

        </div>
        <CancelPayment open={this.state.openCancelPayment} onClose={this.handleCancelPaymentDialogClose} onScheduleCancelled={this.handleScheduleCancelled} sid={this.state.currentSchduleId} cancellationReason={this.state.cancelDetailedReason} cancellationFee={this.state.cancellationFee} cancellationProcessingFee={this.state.cancellationProcessingFee} deposit={this.state.deposit} onError={this.handleCancelPaymentError} />
        <RejectPayment open={this.state.openRejectPayment} onClose={this.handleRejectPaymentDialogClose} onScheduleCancelled={this.handleScheduleCancelled} sid={this.state.currentSchduleId} cancellationReason={this.rejectReason.current ? this.rejectReason.current.value : ""} rejectReason={this.state.rejectReason} rejectDocument={this.state.rejectDocument} cancellationFee={this.state.cancellationFee} cancellationProcessingFee={this.state.cancellationProcessingFee} deposit={this.state.deposit} onError={this.handleRejectPaymentError} />
      </React.Fragment>

    );
  }
}

// export default Schedule
export default withStyles(useStyles)(Schedule);
