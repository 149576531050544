import React from "react";
import { useLocation } from 'react-router-dom'
import {
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import axios from 'axios';
import moment from 'moment';
import { store } from 'react-notifications-component';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CircularProgress from '@material-ui/core/CircularProgress';
import { grey, red, blue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    '& button': {
      fontSize: '1rem',
    },
  },
  message: {
    fontSize: '1.2rem',
  },
  rentDetails: {
    marginTop: theme.spacing(2),
    '& h5': {
      fontWeight: 500,
    },
  },
  image: {
    width: '100%',
    maxWidth: 150,
  },
  rentDate: {
    marginBottom: theme.spacing(-1),
    fontSize: '1.2rem',
  },
  rentDay: {
    fontSize: '0.9rem',
  },
  circularProgress: {
    color: red[500],
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: grey[200] + '!important',
    height: 3,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PendingPayment(props) {
  const classes = useStyles();
  // const path = useReactPath();
  const location = useLocation();
  const theme = useTheme();

  const api = {
    url: props.mainState.apiURL,
    key: props.mainState.apiKey,
    accessToken: localStorage.getItem('ctoken'),
    jhamToken: localStorage.getItem('token'),
    // jhamToken: "b80a47892a5f250cc6bee347d78cc543",
  };

  const [open, setOpen] = React.useState(false);
  const [transaction, setTransaction] = React.useState(null);
  const [confirmCancel, setConfirmCancel] = React.useState(false);
  const [cancelLoading, setCancelLoading] = React.useState(false);

  const [extension, setExtension] = React.useState(null);
  const [extensionType, setExtensionType] = React.useState("");
  const [confirmCancelExtension, setConfirmCancelExtension] = React.useState(false);
  const [cancelLoadingExtension, setCancelLoadingExtension] = React.useState(false);

  const [paypalSchedules, setPaypalSchedules] = React.useState(null);
  const [updateSchedulePayment, setUpdateSchedulePayment] = React.useState(false);

  React.useEffect(() => {
    var ctoken = localStorage.getItem('ctoken');
    if (location.pathname.indexOf('checkout') == -1 && props.mainState.currentUserName && ctoken) {
      checkPendingPayment();
    };
  }, [location]);

  const checkPendingPayment = () => {
    if (location.pathname.indexOf('payment') == -1 && location.pathname.indexOf('terms-of-service') == -1) {
      let formData = new FormData();
          formData.append('api_key', api.key);
          formData.append('access_token', api.accessToken);
          formData.append('jham_token', api.jhamToken);
          
      axios({
        method: 'post',
        url: api.url + '/transaction/pending',
        data: formData,
        withCredentials: true,
        headers: {'Content-Type': 'multipart/form-data'}
      })
      .then((response) => {
        if (response.data.paypal_schedules) {
          var paypalSchedules = response.data.paypal_schedules;
          setPaypalSchedules(paypalSchedules);
          setOpen(true)
        } else if (response.data.transaction) {
          var transaction = response.data.transaction;
          setTransaction(transaction);
          setOpen(true)
        } else if (response.data.extension) {
          var extension = response.data.extension;
          var extensionType = "";
          if (extension.s_payment_mode == "daily") {
            extensionType = "days";
          } else if (extension.s_payment_mode == "weekly") {
            extensionType = "weeks";
          } else if (extension.s_payment_mode == "monthly" || extension.s_payment_mode == "1-year" || extension.s_payment_mode == "2-years") {
            extensionType = "months";
          }
          setExtension(extension);
          setExtensionType(extensionType);
          setOpen(true)
        }
      })
      .catch(error => {
        // handle error
        if (error.response && error.response.status == 403) {
          store.addNotification({
            title: "Oops Something went wrong",
            message: "The action you have requested is not allowed. please reload the page",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              click: false,
              showIcon: true,
            },
          });
        }
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setCancelLoading(true);

    let accessToken = localStorage.getItem('ctoken');
    let jhamToken = localStorage.getItem('token');

    if (accessToken) {
      let formData = new FormData();
          formData.append('api_key', api.key);
          formData.append('access_token', accessToken);
          formData.append('jham_token', jhamToken);
          
      axios({
        method: 'post',
        url: api.url + '/transaction/cancel',
        data: formData,
        withCredentials: true,
        headers: {'Content-Type': 'multipart/form-data'}
      })
      .then((response) => {
        if (response.data.status == 1) {
          props.handlerState('checkoutProgress', null);
          setTimeout(() => {
            setOpen(false);
            setTransaction(null);
            setConfirmCancel(false);
            setCancelLoading(false);
          }, 1500)
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }

  const handleEdit = () => {
    setCancelLoading(true);

    let accessToken = localStorage.getItem('ctoken');
    let jhamToken = localStorage.getItem('token');

    if (accessToken) {
      let formData = new FormData();
          formData.append('api_key', api.key);
          formData.append('access_token', accessToken);
          formData.append('jham_token', jhamToken);
          
      axios({
        method: 'post',
        url: api.url + '/transaction/cancel',
        data: formData,
        withCredentials: true,
        headers: {'Content-Type': 'multipart/form-data'}
      })
      .then((response) => {
        if (response.data.status == 1) {
          var productSlug = response.data.transaction.product_name.replace(/\s+/g, '-').toLowerCase();
          localStorage.removeItem('checkoutProgress');
          localStorage.removeItem('hasPendingPayment');
          props.handlerState('checkoutProgress', null);
          if (response.data.checkout) {
            localStorage.setItem('currrent_vehicle', JSON.stringify(response.data.checkout));
          }
          setTimeout(() => {
            // setOpen(false);
            // setTransaction(null);
            // setConfirmCancel(false);
            // setCancelLoading(false);
            window.location.href = "/vehicle-details/" + productSlug;
          }, 1000)
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }

  const handlePayNow = () => {
    window.location.href = "/checkout/payment";
  }

  const handlePayExtension = () => {
    window.location.href = "/extension/payment";
  }

  const handleCancelExtension = () => {
    setCancelLoadingExtension(true);

    let accessToken = localStorage.getItem('ctoken');
    let jhamToken = localStorage.getItem('token');

    if (accessToken) {
      let formData = new FormData();
      formData.append('api_key', api.key);
      formData.append('access_token', accessToken);
      formData.append('jham_token', jhamToken);

      axios({
        method: 'post',
        url: api.url + '/transaction/cancel_extension',
        data: formData,
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then((response) => {
          if (response.data.status == 1) {
            props.handlerState('checkoutProgress', null);
            setTimeout(() => {
              setOpen(false);
              setExtension(null);
              setConfirmCancelExtension(false);
              setCancelLoadingExtension(false);
            }, 1500)
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  const handleUpdatePayment = () => {
    window.location.href = "/checkout/payment";
  }
  
  return (
    <React.Fragment>
      {transaction && (
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={open}
          TransitionComponent={Transition}
          onClose={handleClose}
          className={classes.root}
          disableBackdropClick
        >
          <DialogTitle>
            Pending Payment
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" color="textSecondary" gutterBottom className={classes.message}>
              You have booked a schedule that is pending for payment. Keep your booking by settling the balance now. Check your rental details below.
            </Typography>
            <Grid container spacing={2} className={classes.rentDetails}>
              <Grid item xs={12} sm={4} align="center">
                <img src={api.url + "/uploads/vehicles/" + transaction.product_image} className={classes.image} />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography variant="h5" component="h5" gutterBottom>
                  {transaction.product_name}
                </Typography>
                <Typography variant="h6" color="textSecondary" gutterBottom style={{ marginTop: 16 }}>
                  Rental Period
                </Typography>
                <Grid container spacing={2} alignItems="center" align="center">
                  <Grid item>
                    <Typography variant="body1" color="textSecondary" className={classes.rentDate}>
                      { moment(transaction.s_start_date).format('MMM D, YYYY') }
                    </Typography>
                    <Typography variant="caption" color="textSecondary" className={classes.rentDay}>
                      { moment(transaction.s_start_date).format('dddd') }
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ArrowForwardIcon fontSize="small" />
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" color="textSecondary" className={classes.rentDate}>
                      { moment(transaction.s_end_date).format('MMM D, YYYY') }
                    </Typography>
                    <Typography variant="caption" color="textSecondary" className={classes.rentDay}>
                      { moment(transaction.s_end_date).format('dddd') }
                    </Typography>
                  </Grid>
                </Grid>
                <Typography variant="h5" component="h5" color="textSecondary" style={{ marginTop: 16 }}>
                  Amount: <Box component="span" color="success.dark">S${transaction.s_payable_amount}</Box>
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {cancelLoading == false && (
              confirmCancel == false ? (
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Button onClick={() => { setConfirmCancel(true) }}>
                      <Box component="span" color="error.dark">Cancel</Box>
                    </Button>
                  </Grid>
                  <Grid item xs={8} align="right">
                    <Button variant="" onClick={handleEdit} style={{ color: blue[600], fontWeight: 600, marginRight: theme.spacing(2) }}>
                      Edit
                    </Button>
                    <Button variant="contained" onClick={handlePayNow} color="primary">
                      Pay Now
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <React.Fragment>
                  <Typography variant="subtitle1">
                    Are you sure you wish to cancel this schedule?
                  </Typography>
                  <Button onClick={() => { setConfirmCancel(false) }}>
                    <Box component="span" color="success.dark">No</Box>
                  </Button>
                  <Button variant="contained" onClick={handleCancel} color="secondary">
                    Yes
                  </Button>
                </React.Fragment>
              )
            )}
            {cancelLoading && (
              <Box align="right">
                <CircularProgress size={24} className={classes.circularProgress} />
              </Box>
            )}
          </DialogActions>
        </Dialog>
      )}
      {extension && (
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={open}
          TransitionComponent={Transition}
          onClose={handleClose}
          className={classes.root}
          disableBackdropClick
        >
          <DialogTitle>
            Pending Extension Payment
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" color="textSecondary" gutterBottom className={classes.message}>
              You have a pending payment for the extension of your lease with schedule no. <strong>{extension.s_id}</strong>. Complete your extension by settling the balance now.
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom className={classes.message} style={{ marginTop: theme.spacing(1) }}>
              Check the details below:
            </Typography>
            <Grid container spacing={2} className={classes.rentDetails}>
              <Grid item xs={12} sm={4} align="center">
                <img src={api.url + "/uploads/vehicles/" + extension.product_image} className={classes.image} />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography variant="h5" component="h5" gutterBottom>
                  {extension.product_name}
                </Typography>
                <Typography variant="h6" color="textSecondary" gutterBottom style={{ marginTop: 16 }}>
                  Extension Period: {extension.es_count} {extensionType}
                </Typography>
                <Typography variant="h5" component="h5" color="textSecondary" style={{ marginTop: 16 }}>
                  Amount: <Box component="span" color="success.dark">S${extension.es_amount}</Box>
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {cancelLoadingExtension == false && (
              confirmCancelExtension == false ? (
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Button onClick={() => { setConfirmCancelExtension(true) }}>
                      <Box component="span" color="error.dark">Cancel</Box>
                    </Button>
                  </Grid>
                  <Grid item xs={8} align="right">
                    <Button variant="contained" onClick={handlePayExtension} color="primary">
                      Pay Now
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <React.Fragment>
                  <Typography variant="subtitle1">
                    Are you sure you wish to cancel this extension?
                  </Typography>
                  <Button onClick={() => { setConfirmCancelExtension(false) }}>
                    <Box component="span" color="success.dark">No</Box>
                  </Button>
                  <Button variant="contained" onClick={handleCancelExtension} color="secondary">
                    Yes
                  </Button>
                </React.Fragment>
              )
            )}
            {cancelLoadingExtension && (
              <Box align="right">
                <CircularProgress size={24} className={classes.circularProgress} />
              </Box>
            )}
          </DialogActions>
        </Dialog>
      )}
      {paypalSchedules && (
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={open}
          TransitionComponent={Transition}
          onClose={handleClose}
          className={classes.root}
          disableBackdropClick
        >
          <DialogTitle>
            Update Recurring Payment Information
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" color="textSecondary" gutterBottom className={classes.message}>
              Please update your payment method for the following recurring lease:
            </Typography>
              {paypalSchedules.map((schedule, i) => {
                return (
                  <React.Fragment>
                    <Grid container spacing={2} alignItems="center" className={classes.rentDetails}>
                      <Grid item xs={8}>
                        <Typography variant="h5" component="h5" gutterBottom>
                          {schedule.s_id}
                        </Typography>
                        <Typography variant="body1" color="textSecondary" className={classes.rentDate}>
                          Rent Period: {moment(schedule.s_start_date).format('MMM D, YYYY')} - {moment(schedule.s_end_date).format('MMM D, YYYY')}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} align="center">
                        <a href={"/lease/update-payment/" + schedule.s_id}>
                          <strong>Update Payment</strong>
                        </a>
                      </Grid>
                    </Grid>
                    {i < paypalSchedules.length - 1 &&
                      <Divider variant="middle" className={classes.divider} />
                    }
                  </React.Fragment>
                )
              })}
              <br />
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
}