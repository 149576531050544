import React from "react";
import {
  makeStyles,
  styled,
  withTheme
} from '@material-ui/core/styles';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import ContentLoader, { Facebook } from "react-content-loader";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { grey, orange, green, blue, red, teal } from '@material-ui/core/colors';

const DashedDivider = styled(withTheme(Divider))(props => ({
  borderTop: '2px dashed',
  borderColor: grey[400],
  height: 0,
  backgroundColor: 'transparent !important',
  margin: props.theme.spacing(2, 0),
}));

const useStyles = makeStyles((theme) => ({
  root: {
    color: grey[600],
  },
  cardContent: {
    padding: theme.spacing(2, 3, 3),
  },
  checkoutTitle: {
    marginBottom: theme.spacing(3),
    '& h2': {
      fontSize: '2rem',
      marginTop: theme.spacing(2),
    },
    '& img': {
      maxWidth: 150,
    },
  },
  checkoutDetails: {
    marginBottom: theme.spacing(3),
    '&:last-child': {
      marginBottom: 0,
    },
    '& .MuiTypography-h5': {
      fontSize: '1.3rem',
      fontWeight: 500,
      marginBottom: theme.spacing(2),
    },
    '& .MuiTypography-body1': {
      fontSize: '1.2rem',
      fontWeight: 500,
    },
    '& .MuiTypography-caption': {
      fontSize: '1rem',
    },
  },
  expensesBreakdown: {
    '& .MuiGrid-item': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  rentTotal: {
    fontSize: '1.5rem',
  },
  textOrange: {
    color: orange[800],
  },
  textGreen: {
    color: green[600],
  },
  textBlue: {
    color: blue[600],
  },
  textRed: {
    // color: red[400],
    color: '#D40029',
  },
  textTeal: {
    color: teal[500],
  },
}));

export default function CheckoutDetails(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    rate: 0,
    discount: 0,
    deposit: 0,
    addons: 0,
    rentTotal: 0,
  });

  React.useEffect(() => {
    if (props.schedule != null) {
      setState({
        rate: parseFloat(props.schedule.total) + parseFloat(props.schedule.discountAmount),
        discount: parseFloat(props.schedule.discountAmount),
        deposit: parseFloat(props.schedule.deposit),
        addons: parseFloat(props.schedule.totalAddons),
        rentTotal: parseFloat(props.schedule.rentalTotal),
      });
    }
  }, [props.schedule])

  function Loader (props) {
    return (
      <ContentLoader
        height={300}
        speed={1}
        {...props}
      >
        <rect x="10" y="20" rx="4" ry="4" width="60" height="60" />
        <rect x="100" y="30" rx="4" ry="4" width="180" height="20" />
        <rect x="100" y="60" rx="4" ry="4" width="180" height="10" />
        <rect x="10" y="100" rx="4" ry="4" width="150" height="20" />
        <rect x="10" y="130" rx="4" ry="4" width="300" height="15" />
        <rect x="10" y="155" rx="4" ry="4" width="300" height="15" />
        <rect x="10" y="190" rx="4" ry="4" width="150" height="20" />
        <rect x="10" y="220" rx="4" ry="4" width="300" height="15" />
        <rect x="10" y="245" rx="4" ry="4" width="300" height="15" />
      </ContentLoader>
    )
  }

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContent}>
        {props.vehicle ? (
          <React.Fragment>
            <Box className={classes.checkoutTitle} align="center">
              <Box>
                <img src={"https://api.earental.com/uploads/vehicles/" + props.vehicle.image} />
              </Box>
              <Box display="inline-block">
                <Typography variant="h4" component="h2">
                  {props.vehicle.name}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.checkoutDetails}>
              <Typography variant="h5" gutterBottom>
                Rental Period
              </Typography>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={5} align="center">
                  <Typography variant="body1">
                    { moment(props.schedule.startDate).format('MMM D, YYYY') }
                  </Typography>
                  <Typography variant="caption">
                    { moment(props.schedule.startDate).format('dddd') }
                  </Typography>
                </Grid>
                <Grid item xs={2} align="center">
                  <ArrowForwardIcon fontSize="large" />
                </Grid>
                <Grid item xs={5} align="center">
                  <Typography variant="body1">
                    { moment(props.schedule.endDate).format('MMM D, YYYY') }
                  </Typography>
                  <Typography variant="caption" gutterBottom>
                    { moment(props.schedule.endDate).format('dddd') }
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.checkoutDetails}>
              <Typography variant="h5" gutterBottom>
                Payment Details
              </Typography>
              <Grid container spacing={3} alignItems="flex-start" className={classes.expensesBreakdown}>
                <Grid item xs={9}>
                  <Typography variant="body1">
                    {props.schedule.rateTitle}
                  </Typography>
                  <Typography variant="caption">
                    {(props.schedule.rateType == "Daily Rate" ||
                      props.schedule.rateType == "Weekend Rate" ||
                      props.schedule.rateType == "Weekly Rate") && (
                      <React.Fragment>
                        ({props.schedule.rateCount} x {props.schedule.rateType} <NumberFormat value={props.schedule.rate} displayType={'text'} thousandSeparator={true} prefix={'S$'} />)
                      </React.Fragment>
                    )}
                    {(props.schedule.rateType == "Monthly Rate" ||
                      props.schedule.rateType == "1-Yr Lease Rate" ||
                      props.schedule.rateType == "2-Yr Lease Rate") && (
                      <React.Fragment>
                        (Contract: {props.schedule.rateCount} x monthly rate <NumberFormat value={props.schedule.rate} displayType={'text'} thousandSeparator={true} prefix={'S$'} />)
                      </React.Fragment>
                    )}
                  </Typography>
                  {props.schedule.freeDays > 0 && (
                    <Typography variant="caption" component="p">
                      &nbsp;+ {props.schedule.freeDays} Day(s) <strong style={{ color: "#D40029" }}>FREE</strong>
                    </Typography>
                  )}
                  {props.schedule.freeMonths > 0 && (
                    <Typography variant="caption" component="p">
                      + {props.schedule.freeMonths} Month(s) <strong style={{ color: "#D40029" }}>FREE</strong>
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={3} align="right">
                  <Typography variant="body1" className={classes.textGreen}>
                    <NumberFormat value={state.rate} displayType={'text'} thousandSeparator={true} prefix={'S$'} decimalScale={2} fixedDecimalScale={state.rate % 1 != 0} />
                  </Typography>
                </Grid>
              </Grid>
              {props.schedule.discountAmount > 0 && (
                <Grid container spacing={3} alignItems="flex-start" className={classes.expensesBreakdown}>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      Discount
                    </Typography>
                  </Grid>
                  <Grid item xs={4} align="right">
                    <Typography variant="body1" className={classes.textOrange}>
                      (<NumberFormat decimalScale={2} value={state.discount} displayType={'text'} thousandSeparator={true} prefix={'S$'} decimalScale={2} fixedDecimalScale={state.discount % 1 != 0} />)
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={3} alignItems="flex-start" className={classes.expensesBreakdown}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    Deposit
                  </Typography>
                </Grid>
                <Grid item xs={4} align="right">
                  <Typography variant="body1" className={classes.textBlue}>
                    <NumberFormat decimalScale={2} value={state.deposit} displayType={'text'} thousandSeparator={true} prefix={'S$'} decimalScale={2} fixedDecimalScale={state.deposit % 1 != 0} />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} alignItems="flex-start" className={classes.expensesBreakdown}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    Addons
                  </Typography>
                </Grid>
                <Grid item xs={4} align="right">
                  <Typography variant="body1">
                    <NumberFormat value={state.addons} displayType={'text'} thousandSeparator={true} prefix={'S$'} decimalScale={2} fixedDecimalScale={state.addons % 1 != 0} />
                  </Typography>
                </Grid>
              </Grid>
              <DashedDivider />
              <Grid container spacing={3} alignItems="flex-start" className={classes.expensesBreakdown}>
                <Grid item xs={8}>
                  <Typography variant="h6" className={classes.rentTotal}>
                    Rental Total
                  </Typography>
                </Grid>
                <Grid item xs={4} align="right">
                  <Typography variant="h6" className={`${classes.rentTotal} ${classes.textRed}`}>
                    <NumberFormat decimalScale={2} value={state.rentTotal} displayType={'text'} thousandSeparator={true} prefix={'S$'} decimalScale={2} fixedDecimalScale={state.rentTotal % 1 != 0} />
                  </Typography>
                </Grid>
              </Grid>
              {props.type == "recurring" && props.schedule.pay1stMonth == false && (
                <Grid container spacing={3} alignItems="flex-start" className={classes.expensesBreakdown}>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      To Collect
                  </Typography>
                  </Grid>
                  <Grid item xs={4} align="right">
                    <Typography variant="body1" className={classes.textTeal}>
                      <NumberFormat decimalScale={2} value={state.deposit} displayType={'text'} thousandSeparator={true} prefix={'S$'} decimalScale={2} fixedDecimalScale={state.deposit % 1 != 0} />
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Box>
          </React.Fragment>
        ) : (
          <Loader style={{ width: '100%' }} />
        )}
      </CardContent>
    </Card>
  );
}
