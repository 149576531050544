import React, {forwardRef,Component,useState } from "react";
import {
  NavLink,
  HashRouter
} from "react-router-dom";

import axios, { post } from 'axios';
import { store } from 'react-notifications-component';

import noImage from './../../assets/img/no-image.jpg';
import DatePicker from "react-datepicker";
import Image, { Shimmer } from 'react-shimmer'
import ContentLoader, { Facebook } from "react-content-loader";
import Pager from 'react-pager';
import ScriptTag from 'react-script-tag';
import moment from "moment";

import { makeStyles } from '@material-ui/core/styles';
import {
  createMuiTheme,
  withStyles,
  ThemeProvider
} from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import AirportShuttleRoundedIcon from '@material-ui/icons/AirportShuttleRounded';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Input from '@material-ui/core/Input';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import BackspaceRoundedIcon from '@material-ui/icons/BackspaceRounded';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { grey, orange } from '@material-ui/core/colors';
import Chip from '@material-ui/core/Chip';
import ProductPreview from '../dashboardPages/preview/ProductPreview';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { DataGrid } from '@material-ui/data-grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        outline: 'none'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paragraph: {
        fontSize:15
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
        fontSize:"20px",
        textTransform:"uppercase"
    },
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(3),
    },
    button: {
      marginTop: '35px',
      float: 'right'
    },
    newCardForm: {
      color: grey[800],
      '& .MuiFormControl-root': {
        '& .MuiFormLabel-root': {
          fontSize: '1.2rem',
          '&.Mui-focused': {
            color: grey[800],
            backgroundColor: 'white',
            paddingRight: 5,
          },
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: 0,
          '&:hover fieldset': {
            borderColor: '#B4B4B4',
          },
          '&.Mui-focused fieldset': {
            borderColor: orange[600],
          },
          '& input': {
            fontSize: 15,
            font:'auto'
          },
        },
        '& .MuiInput-root': {
          borderRadius: 0,
          '&:hover fieldset': {
            borderColor: '#B4B4B4',
          },
          '&.Mui-focused fieldset': {
            borderColor: orange[600],
          },
          '& input': {
            fontSize: 15,
            font:'auto'
          },
        },
      }
    }
}));

const MyLoader = () => (
    <ContentLoader
        height={170}
        width={262}
        speed={1}
    >
        <rect x="0" y="0" rx="0" ry="0" width="262" height="170" />
    </ContentLoader>
);

const columns = [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 's_start_date', headerName: 'Start Date', width: 130 },
    { field: 's_end_date', headerName: 'End Date', width: 130 },
    { field: 's_date_added', headerName: 'Date Added', width: 150 }
];



class Vehicles extends Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
        this.handlePageChanged = this.handlePageChanged.bind(this);

        this.state = {
             products: [],
             loadingclass: 'noHidden',
             driverDob: "",
             openSb: false,
             openAddModal: false,
             openSbDeleted: false,
             openSbEdit: false,
             openDeleteConfirm: false,
             currentVehicle:false,
             currentVehicleData:false,
             openEditModal: false,
             limit:8,
             totalPage:0,
             currentPage:0,
             visiblePage:5,
             offset:0,
             addDname:"",
             addDDob:"",
             addDMobile:"",
             addDExp:"",
             addDLicense:"",
             addDNric:"",
             addDAddress:"",
             noResult:false,
             hidePager:"",
             src: null,
             crop: {
               unit: '%',
               width: '120',
               aspect: 16 / 16,
           },
           lessorCode:false,
           disableFields:false,
           selectedRangeDate:null,
           restrictedDates:null,
           openSchedule:false,
           proceedSchedule: true,
           currentProductRestricted:null,
           currentVehicleName:"",
           currentRestrictedList:null,
           deleteFromRestricted:null,
           openSbSchedule:false,
           manualSchedText:"update schedule"
        };

        this.onAddFormSubmit = this.onAddFormSubmit.bind(this);
        this.onEditFormSubmit = this.onEditFormSubmit.bind(this);
        this.handleCloseSb = this.handleCloseSb.bind(this);
        this.handleCloseSbDelete = this.handleCloseSbDelete.bind(this);
        this.openAddModal = this.openAddModal.bind(this);
        this.closeAddModal = this.closeAddModal.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.openDeleteConfirmModal = this.openDeleteConfirmModal.bind(this);
        this.handleChangeAddInput = this.handleChangeAddInput.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.handleCloseSbEdit = this.handleCloseSbEdit.bind(this);

        this.onSelectFile = this.onSelectFile.bind(this);
        this.onImageLoaded = this.onImageLoaded.bind(this);
        this.onCropComplete = this.onCropComplete.bind(this);
        this.onCropChange = this.onCropChange.bind(this);
        this.handleRangeSelect = this.handleRangeSelect.bind(this);
        this.disableCalendarRestrictedOnly = this.disableCalendarRestrictedOnly.bind(this);
        this.handleToggleSchedule = this.handleToggleSchedule.bind(this);
        this.handleRowSelection = this.handleRowSelection.bind(this);
        this.handleUpdateSchedule = this.handleUpdateSchedule.bind(this);
        this.handleCloseSbSchedule = this.handleCloseSbSchedule.bind(this);


    }

    disableCalendarRestrictedOnly = (date, event) => {
        if(this.state.restrictedDates){
                for (let i = 0; i < this.state.restrictedDates.length; i++) {
                    var endDate = moment(this.state.restrictedDates[i].s_end_date).add(1, 'day').format("YYYY-MM-DD");
                    var startDate = moment(this.state.restrictedDates[i].s_start_date).subtract(1, 'day').format("YYYY-MM-DD");
                    if(moment(date.date).isBetween(startDate, endDate)){
                        return true;
                    }
                }

        }else{
            return false;
        }
    }

    handleRowSelection = (newSelections) => {

        if(!this.state.selectedRangeDate && newSelections.rows.length <= 0){
            this.setState({
                proceedSchedule:true,
                deleteFromRestricted:newSelections.rows,
                manualSchedText:"Update schedule"
            })
        }else{
            if(newSelections.rows.length <= 0){
                this.setState({
                    deleteFromRestricted:null,
                    manualSchedText:"Update schedule"
                })
            }else{
                this.setState({
                    proceedSchedule:false,
                    deleteFromRestricted:newSelections.rows,
                    manualSchedText:"REMOVE SCHEDULE"
                })
            }

        }

    };


    handleRangeSelect = (value, event) => {

        var startDate = moment(value[0]).format("YYYY-MM-DD");
        var endDate = moment(value[1]).format("YYYY-MM-DD");

        this.setState({
          proceedSchedule:false,
          selectedRangeDate:[new Date(startDate),new Date(endDate)]
        })


    };

    onSelectFile = e => {
      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          this.setState({ src: reader.result })
        );
        reader.readAsDataURL(e.target.files[0]);
      }
    };

    // If you setState the crop in here you should return false.
      onImageLoaded = image => {
        this.imageRef = image;
      };

      onCropComplete = crop => {
        this.makeClientCrop(crop);
      };

      onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
      };

      async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
          );
          this.setState({ croppedImageUrl });

        }
      }


      getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
        const base64Image = canvas.toDataURL('image/jpeg');

        return base64Image;

      }

      handleUpdateSchedule(name,product_id){
          this.setState({
              proceedSchedule:true
          });

          var bodyFormData = new FormData();
          var token = localStorage.getItem('token');
          var ctoken = localStorage.getItem('ctoken');
          var bodyFormData = new FormData();

          bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
          bodyFormData.append('jham_token', token);
          bodyFormData.append('access_token', ctoken);
          bodyFormData.append('product_id', this.state.currentProductRestricted);

          if(this.state.selectedRangeDate){

             var startDate = moment(this.state.selectedRangeDate[0]).format("YYYY-MM-DD");
             var endDate = moment(this.state.selectedRangeDate[1]).format("YYYY-MM-DD");
             var rangeDate = startDate+","+endDate;
             console.log(rangeDate);
             bodyFormData.append('rangeDate', rangeDate);
          }
          if(this.state.deleteFromRestricted){
              bodyFormData.append('toDelete', JSON.stringify(this.state.deleteFromRestricted));
          }


          axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/update_restricted_date',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
            if(response.data.error > 0){
                store.addNotification({
                  title: "Error",
                  message: response.data.error_msg,
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                    duration: 3000,
                    onScreen: false
                  }
                });
                this.setState({
                    selectedRangeDate:null,
                    deleteFromRestricted:null,
                    proceedSchedule:true
                });
            }else{
                if(response.data){
                    this.setState({
                        restrictedDates: response.data.data,
                        currentRestrictedList: response.data.list,
                    });
                }else{
                    this.setState({
                        restrictedDates: null,
                        currentRestrictedList: null
                    });
                }

                this.setState({
                    selectedRangeDate:null,
                    deleteFromRestricted:null,
                    proceedSchedule:true,
                    openSbSchedule:true
                });
            }



          })
          .catch((error) => {


          });



      }

    handleToggleSchedule(name,product_id){
        if(this.state.openSchedule == false){
            var bodyFormData = new FormData();
            var token = localStorage.getItem('token');
            var ctoken = localStorage.getItem('ctoken');
            var bodyFormData = new FormData();

            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
            bodyFormData.append('jham_token', token);
            bodyFormData.append('product_id', product_id);

            axios({
                method: 'post',
                url: 'https://api.earental.com/lessor/restricted_date',
                data: bodyFormData,
                withCredentials: true,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then((response) => {
                if(response.data){
                    this.setState({
                        restrictedDates: response.data.data,
                        currentRestrictedList: response.data.list,
                    });
                }else{
                    this.setState({
                        restrictedDates: null,
                        currentRestrictedList: null
                    });
                }

                this.setState({
                    currentVehicleName:name,
                    currentProductRestricted:product_id
                })



            })
            .catch((error) => {


            });
        }else{
            this.setState({
                restrictedDates: null,
                currentRestrictedList: null,
                currentVehicleName:null,
                currentProductRestricted:null,
                selectedRangeDate:null,
                deleteFromRestricted:null,
            });
        }

        this.setState({
            openSchedule:!this.state.openSchedule
        })
    }

    handleCloseSbSchedule(){
        this.setState({
          openSbSchedule:false,
        })
    }
    handleCloseSb(){
        this.setState({
          openSb:false,
        })
    }

    handleCloseSbDelete(){
        this.setState({
          openSbDeleted:false,
        })
    }

    handleCloseSbEdit(){
        this.setState({
          openSbEdit:false,
        })
    }

    openAddModal(){
        this.setState({
          openAddModal:true,
        })
    }

    closeAddModal(){
        this.setState({
          openAddModal:false,
        })

    }

    openEditModal(data,e){
        console.log(data);
        this.setState({
          openEditModal:true,
          currentVehicleData:data,
          currentVehicle:data.product_id
        })
    }

    closeEditModal(){

        this.setState({
          openEditModal:false,
          currentVehicleData:false,
          currentVehicle: false
        })

    }


    handleChangeAddInput(name,e){
        this.setState({
          [name]:e.target.value,
        })
    }


    openDeleteConfirmModal(driverId,e){
        this.setState({
          openDeleteConfirm:true,
          currentVehicle: driverId
        })
    }

    closeDeleteModal(confirmation,e){
        console.log(confirmation);

        if(confirmation){


            var bodyFormData = new FormData();
            var token = localStorage.getItem('token');
            var ctoken = localStorage.getItem('ctoken');

            bodyFormData.append('product_id', this.state.currentVehicle);
            bodyFormData.append('jham_token', token);
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


            axios({
                  method: 'post',
                  url: 'https://api.earental.com/lessor/delete_vehicle',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {

                 if(response.data.error > 0){

                     store.addNotification({
                       title: "Error",
                       message: response.data.error_message,
                       type: "danger",
                       insert: "top",
                       container: "top-right",
                       animationIn: ["animated", "fadeIn"],
                       animationOut: ["animated", "fadeOut"],
                       dismiss: {
                         duration: 3000,
                         onScreen: false
                       }
                     });

                     this.setState({
                       openDeleteConfirm:false,
                       openSbDeleted: false,
                       currentVehicle: false
                     })


                 }else{
                     this.setState({
                       openDeleteConfirm:false,
                       openSbDeleted: true,
                       currentVehicle: false,
                       loadingclass: 'noHidden',
                     })


                     this.getVehicles();


                 }

              })
              .catch((error) => {
                  //handle error
                  console.log(error);

            });

        }else{
            this.setState({
              openDeleteConfirm:false,
              currentVehicle: false
            })
        }

    }





    onAddFormSubmit(addOns,event){
        event.preventDefault();

        document.getElementById("addVehicleBtn").disabled = true;
        document.getElementById("addVehicleBtn").innerText = "Saving...";


        var bodyFormData = new FormData();
        var token = localStorage.getItem('token');
        var ctoken = localStorage.getItem('ctoken');

        // var hourlyBonus = event.target.hourlyBonus.checked;
        var monthlyBonusCheck = event.target.monthlyBonus.checked;
        var oneYearBonusCheck = event.target.oneYearBonus.checked;
        var twoYearsBonusCheck = event.target.twoYearsBonus.checked;


        var hourlyBonus = 0;
        var hourlyBonusCount = 0;
        var hourlyBonusType = 0;
        var hourlyBonusStart = 0;
        var hourlyBonusEnd = 0;
        // if(hourlyBonus){
        //     hourlyBonus = 1;
        //     hourlyBonusCount = event.target.vBonusCount1.value;
        //     hourlyBonusType = event.target.vBonusType1.value;
        //     hourlyBonusStart = event.target.vBonusStart1.value;
        //     hourlyBonusEnd = event.target.vBonusEnd1.value;
        // }

        var dailyBonus = 0;
        var dailyBonusCount = 0;
        var dailyBonusType = 0;
        var dailyBonusStart = 0;
        var dailyBonusEnd = 0;
        // if(dailyBonus){
        //     dailyBonus = 1;
        //     dailyBonusCount = event.target.vBonusCount1.value;
        //     dailyBonusType = event.target.vBonusType1.value;
        //     dailyBonusStart = event.target.vBonusStart1.value;
        //     dailyBonusEnd = event.target.vBonusEnd1.value;
        // }

        var weeklyBonus = 0;
        var weeklyBonusCount = 0;
        var weeklyBonusType = 0;
        var weeklyBonusStart = 0;
        var weeklyBonusEnd = 0;
        // if(weeklyBonus){
        //     weeklyBonus = 1;
        //     weeklyBonusCount = event.target.vBonusCount1.value;
        //     weeklyBonusType = event.target.vBonusType1.value;
        //     weeklyBonusStart = event.target.vBonusStart1.value;
        //     weeklyBonusEnd = event.target.vBonusEnd1.value;
        // }

        var weekendBonus = 0;
        var weekendBonusCount = 0;
        var weekendBonusType = 0;
        var weekendBonusStart = 0;
        var weekendBonusEnd = 0;
        // if(weekendBonus){
        //     weekendBonus = 1;
        //     weekendBonusCount = event.target.vBonusCount1.value;
        //     weekendBonusType = event.target.vBonusType1.value;
        //     weekendBonusStart = event.target.vBonusStart1.value;
        //     weekendBonusEnd = event.target.vBonusEnd1.value;
        // }

        var monthlyBonus = 0;
        var monthlyBonusCount = 0;
        var monthlyBonusType = 0;
        var monthlyBonusStart = 0;
        var monthlyBonusEnd = 0;
        if(monthlyBonusCheck){
            monthlyBonus = 1;
            monthlyBonusCount = event.target.vBonusCount5.value;
            monthlyBonusType = event.target.vBonusType5.value;
            monthlyBonusStart = event.target.vBonusStart5.value;
            monthlyBonusEnd = event.target.vBonusEnd5.value;
        }

        var biannuallyBonus = 0;
        var biannuallyBonusCount = 0;
        var biannuallyBonusType = 0;
        var biannuallyBonusStart = 0;
        var biannuallyBonusEnd = 0;
        // if(biannuallyBonus){
        //     biannuallyBonus = 1;
        //     biannuallyBonusCount = event.target.vBonusCount1.value;
        //     biannuallyBonusType = event.target.vBonusType1.value;
        //     biannuallyBonusStart = event.target.vBonusStart1.value;
        //     biannuallyBonusEnd = event.target.vBonusEnd1.value;
        // }

        var oneyearBonus = 0;
        var oneyearBonusCount = 0;
        var oneyearBonusType = 0;
        var oneyearBonusStart = 0;
        var oneyearBonusEnd = 0;
        if(oneYearBonusCheck){
            oneyearBonus = 1;
            oneyearBonusCount = event.target.vBonusCount7.value;
            oneyearBonusType = event.target.vBonusType7.value;
            oneyearBonusStart = event.target.vBonusStart7.value;
            oneyearBonusEnd = event.target.vBonusEnd7.value;
        }

        var twoyearBonus = 0;
        var twoyearBonusCount = 0;
        var twoyearBonusType = 0;
        var twoyearBonusStart = 0;
        var twoyearBonusEnd = 0;
        if(twoYearsBonusCheck){
            twoyearBonus = 1;
            twoyearBonusCount = event.target.vBonusCount8.value;
            twoyearBonusType = event.target.vBonusType8.value;
            twoyearBonusStart = event.target.vBonusStart8.value;
            twoyearBonusEnd = event.target.vBonusEnd8.value;
        }



        var min_deposits = [
            event.target.vMindeposit1.value,
            event.target.vMindeposit2.value,
            event.target.vMindeposit3.value,
            event.target.vMindeposit4.value,
            event.target.vMindeposit5.value,
            event.target.vMindeposit6.value,
            event.target.vMindeposit7.value,
            event.target.vMindeposit8.value
        ]

        min_deposits = min_deposits[0]+','+min_deposits[1]+','+min_deposits[2]+','+min_deposits[3]+','+min_deposits[4]+','+min_deposits[5]+','+min_deposits[6]+','+min_deposits[7];
        if(addOns.length > 0){
            var addOns = JSON.stringify(addOns);
            addOns = addOns.replace(",{}", "");
            bodyFormData.append('addons',addOns);
        }

        var vimages0 = 0;
        var vimages1 = 0;
        var vimages2 = 0;
        var vimages3 = 0;
        var vimages4 = 0;
        var vimages5 = 0;
        var vimages6 = 0;
        var vimages7 = 0;
        var vimages8 = 0;
        var withThumb = 0;
        var errors = 0;
        var errorDescs = [];

        if (typeof event.target.vimages0.files[0] !== 'undefined'){
            event.target.vimages0.files[0]['sequence'] = 0;
            vimages0 = event.target.vimages0.files[0];
        }

        if (typeof event.target.vimages1.files[0] !== 'undefined'){
            event.target.vimages1.files[0]['sequence'] = 1;
            vimages1 = event.target.vimages1.files[0];
        }

        if (typeof event.target.vimages2.files[0] !== 'undefined'){
            event.target.vimages2.files[0]['sequence'] = 2;
            vimages2 = event.target.vimages2.files[0];
        }

        if (typeof event.target.vimages3.files[0] !== 'undefined'){
            event.target.vimages3.files[0]['sequence'] = 3;
            vimages3 = event.target.vimages3.files[0];
        }

        if (typeof event.target.vimages4.files[0] !== 'undefined'){
            vimages4 = event.target.vimages4.files[0];
        }

        if (typeof event.target.vimages5.files[0] !== 'undefined'){
            vimages5 = event.target.vimages5.files[0];
        }

        if (typeof event.target.vimages6.files[0] !== 'undefined'){
            vimages6 = event.target.vimages6.files[0];
        }

        if (typeof event.target.vimages7.files[0] !== 'undefined'){
            vimages7 = event.target.vimages7.files[0];
        }

        if (typeof event.target.vimages8.files[0] !== 'undefined'){
            vimages8 = event.target.vimages8.files[0];
        }

        if((parseFloat(event.target.vHourly.value) > 0 && (parseFloat(event.target.vMindeposit1.value) <= 0 || event.target.vMindeposit1.value == "")))  {
            errors++;
            errorDescs.push({value: "Min Deposit for hourly"});

        }
        if((parseFloat( event.target.vDaily.value) > 0 && (parseFloat(event.target.vMindeposit2.value) <= 0 || event.target.vMindeposit2.value == "")))  {
            errors++;
            errorDescs.push({value: "Min Deposit for daily"});

        }
        if((parseFloat( event.target.vWeekly.value) > 0 && (parseFloat(event.target.vMindeposit3.value) <= 0 || event.target.vMindeposit3.value == "")))  {
            errors++;
            errorDescs.push({value: "Min Deposit for weekly"});

        }
        if((parseFloat( event.target.vWeekend.value) > 0 && (parseFloat(event.target.vMindeposit4.value) <= 0 || event.target.vMindeposit4.value == "")))  {
            errors++;
            errorDescs.push({value: "Min Deposit for weekend"});

        }
        if((parseFloat( event.target.vMonthly.value) > 0 && (parseFloat(event.target.vMindeposit5.value) <= 0 || event.target.vMindeposit5.value == "")))  {
            errors++;
            errorDescs.push({value: "Min Deposit for montly"});

        }
        if((parseFloat( event.target.vBiannually.value) > 0 && (parseFloat(event.target.vMindeposit6.value) <= 0 || event.target.vMindeposit6.value == "")))  {
            errors++;
            errorDescs.push({value: "Min Deposit for bi-annually"});

        }
        if((parseFloat( event.target.vYearly.value) > 0 && (parseFloat(event.target.vMindeposit7.value) <= 0 || event.target.vMindeposit7.value == "")))  {
            errors++;
            errorDescs.push({value: "Min Deposit for 1-year"});

        }
        if((parseFloat( event.target.v2yr.value) > 0 && (parseFloat(event.target.vMindeposit8.value) <= 0 || event.target.vMindeposit8.value == "")))  {
            errors++;
            errorDescs.push({value: "Min Deposit for 2-year"});

        }

        if(!errors){
            bodyFormData.append('vehiclefile[0]', vimages0);
            bodyFormData.append('vehiclefile[1]', vimages1);
            bodyFormData.append('vehiclefile[2]', vimages2);
            bodyFormData.append('vehiclefile[3]', vimages3);
            bodyFormData.append('vehiclefile[4]', vimages4);
            bodyFormData.append('vehiclefile[5]', vimages5);
            bodyFormData.append('vehiclefile[6]', vimages6);
            bodyFormData.append('vehiclefile[7]', vimages7);
            bodyFormData.append('vehiclefile[8]', vimages8);
            bodyFormData.append('product_name', event.target.vName.value);
            bodyFormData.append('product_type', event.target.vType.value);
            bodyFormData.append('product_model', event.target.vModel.value);
            bodyFormData.append('product_registration_year', event.target.vRYear.value);
            bodyFormData.append('product_brand', event.target.vBrand.value);
            bodyFormData.append('category_name', event.target.vCategory.value);
            bodyFormData.append('product_colour', event.target.vColour.value);
            bodyFormData.append('product_platenumber', event.target.dPlate.value);
            bodyFormData.append('product_description', event.target.vDesc.value);


            bodyFormData.append('product_min_deposit_amount', min_deposits);
            bodyFormData.append('product_price_hour', event.target.vHourly.value);
            bodyFormData.append('product_price_day', event.target.vDaily.value);
            bodyFormData.append('product_price_week', event.target.vWeekly.value);
            bodyFormData.append('product_price_month', event.target.vMonthly.value);
            bodyFormData.append('product_price_biannually', event.target.vBiannually.value);
            bodyFormData.append('product_price_year', event.target.vYearly.value);
            bodyFormData.append('product_price_weekend', event.target.vWeekend.value);
            bodyFormData.append('product_price_year_2', event.target.v2yr.value);

            bodyFormData.append('product_transmission', event.target.vTransmission.value);
            bodyFormData.append('product_engine', event.target.vEngine.value);
            bodyFormData.append('product_mileage', event.target.vMileage.value);
            bodyFormData.append('product_rental_options', event.target.vTerm.value);
            bodyFormData.append('product_rent_terms', event.target.vTerm.value);
            bodyFormData.append('product_gas', event.target.vGas.value);
            bodyFormData.append('product_passengers', event.target.vPassengers.value);
            bodyFormData.append('product_doors', event.target.vDoors.value);
            bodyFormData.append('product_baggage', event.target.vBaggage.value);
            bodyFormData.append('product_instruction', event.target.vInstruction.value);

            bodyFormData.append('product_min_age', event.target.vMinAge.value);
            bodyFormData.append('product_experience', event.target.vMinExp.value);
            bodyFormData.append('product_min_days', event.target.vMindays.value);


            bodyFormData.append('product_review_rate', '');

            bodyFormData.append('product_insurance_excess_1', event.target.insuranceExcess1.value);
            bodyFormData.append('product_insurance_excess_2', event.target.insuranceExcess2.value);
            bodyFormData.append('product_insurance_excess_3', event.target.insuranceExcess3.value);

            bodyFormData.append('hourly_bonus',hourlyBonus);
            bodyFormData.append('hourly_bonus_count', hourlyBonusCount);
            bodyFormData.append('hourly_bonus_type', hourlyBonusType);
            bodyFormData.append('hourly_bonus_start', hourlyBonusStart);
            bodyFormData.append('hourly_bonus_end',hourlyBonusEnd);

            bodyFormData.append('daily_bonus',dailyBonus);
            bodyFormData.append('daily_bonus_count', dailyBonusCount);
            bodyFormData.append('daily_bonus_type', dailyBonusType);
            bodyFormData.append('daily_bonus_start', dailyBonusStart);
            bodyFormData.append('daily_bonus_end',dailyBonusEnd);

            bodyFormData.append('weekly_bonus',weeklyBonus);
            bodyFormData.append('weekly_bonus_count', weeklyBonusCount);
            bodyFormData.append('weekly_bonus_type', weeklyBonusType);
            bodyFormData.append('weekly_bonus_start', weeklyBonusStart);
            bodyFormData.append('weekly_bonus_end',weeklyBonusEnd);

            bodyFormData.append('weekend_bonus',weekendBonus);
            bodyFormData.append('weekend_bonus_count', weekendBonusCount);
            bodyFormData.append('weekend_bonus_type', weekendBonusType);
            bodyFormData.append('weekend_bonus_start', weekendBonusStart);
            bodyFormData.append('weekend_bonus_end',weekendBonusEnd);

            bodyFormData.append('monthly_bonus',monthlyBonus);
            bodyFormData.append('monthly_bonus_count', monthlyBonusCount);
            bodyFormData.append('monthly_bonus_type', monthlyBonusType);
            bodyFormData.append('monthly_bonus_start', monthlyBonusStart);
            bodyFormData.append('monthly_bonus_end',monthlyBonusEnd);

            bodyFormData.append('binannully_bonus',biannuallyBonus);
            bodyFormData.append('binannully_bonus_count', biannuallyBonusCount);
            bodyFormData.append('binannully_bonus_type', biannuallyBonusType);
            bodyFormData.append('binannully_bonus_start', biannuallyBonusStart);
            bodyFormData.append('binannully_bonus_end',biannuallyBonusEnd);

            bodyFormData.append('oneyear_bonus',oneyearBonus);
            bodyFormData.append('oneyear_bonus_count', oneyearBonusCount);
            bodyFormData.append('oneyear_bonus_type', oneyearBonusType);
            bodyFormData.append('oneyear_bonus_start', oneyearBonusStart);
            bodyFormData.append('oneyear_bonus_end',oneyearBonusEnd);

            bodyFormData.append('twoyear_bonus',twoyearBonus);
            bodyFormData.append('twoyear_bonus_count', twoyearBonusCount);
            bodyFormData.append('twoyear_bonus_type', twoyearBonusType);
            bodyFormData.append('twoyear_bonus_start', twoyearBonusStart);
            bodyFormData.append('twoyear_bonus_end',twoyearBonusEnd);



            bodyFormData.append('jham_token', token);
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


            axios({
                  method: 'post',
                  url: 'https://api.earental.com/lessor/add_product',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {

                 if(response.data.error > 0){
                     function ErrorMsg(props) {
                       return (
                           props.msgs.map((item,i) =>
                               <li key={"err-"+item.i}>{item.value}</li>
                           )

                       );
                     }
                     store.addNotification({
                       title: "Invalid information",
                       message: <ErrorMsg msgs={response.data.error_message} />,
                       type: "danger",
                       insert: "top",
                       container: "top-right",
                       animationIn: ["animated", "fadeIn"],
                       animationOut: ["animated", "fadeOut"],
                       dismiss: {
                         duration: 20000,
                         onScreen: false
                       }
                     });
                 }else{

                     this.setState({ openSb: true });
                     this.setState({ openAddModal: false });
                     this.getVehicles();
                     document.getElementById("vehicle-add-form").reset();

                 }

                 document.getElementById("addVehicleBtn").disabled = false;
                 document.getElementById("addVehicleBtn").innerText = "save this vehicle";


              })
              .catch((error) => {
                  //handle error
                  console.log(error);

            });
        }else{
            function ErrorMsg(props) {
              return (
                  props.msgs.map((item,i) =>
                      <li key={"err-"+item.i}>{item.value}</li>
                  )

              );
            }
            store.addNotification({
              title: "Required Fields",
              message: <ErrorMsg msgs={errorDescs} />,
              type: "warning",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 10000,
                onScreen: false
              }
            });
            document.getElementById("addVehicleBtn").disabled = false;
            document.getElementById("addVehicleBtn").innerText = "save this vehicle";
        }



    }

    onEditFormSubmit(addOns,removeImgs,event){
        event.preventDefault();

        document.getElementById("updateVehicleBtn").disabled = true;
        document.getElementById("updateVehicleBtn").innerText = "Updating...";


        var bodyFormData = new FormData();
        var token = localStorage.getItem('token');
        // var token = "78d33428fd0b54e3ad360c95a7d1960d";
        var ctoken = localStorage.getItem('ctoken');
        // var ctoken = '78d33428fd0b54e3ad360c95a7d1960d';

        var monthlyBonusCheck = event.target.monthlyBonus.checked;
        var oneYearBonusCheck = event.target.oneYearBonus.checked;
        var twoYearsBonusCheck = event.target.twoYearsBonus.checked;


        var hourlyBonus = 0;
        var hourlyBonusCount = 0;
        var hourlyBonusType = 0;
        var hourlyBonusStart = 0;
        var hourlyBonusEnd = 0;
        // if(hourlyBonus){
        //     hourlyBonus = 1;
        //     hourlyBonusCount = event.target.vBonusCount1.value;
        //     hourlyBonusType = event.target.vBonusType1.value;
        //     hourlyBonusStart = event.target.vBonusStart1.value;
        //     hourlyBonusEnd = event.target.vBonusEnd1.value;
        // }

        var dailyBonus = 0;
        var dailyBonusCount = 0;
        var dailyBonusType = 0;
        var dailyBonusStart = 0;
        var dailyBonusEnd = 0;
        // if(dailyBonus){
        //     dailyBonus = 1;
        //     dailyBonusCount = event.target.vBonusCount1.value;
        //     dailyBonusType = event.target.vBonusType1.value;
        //     dailyBonusStart = event.target.vBonusStart1.value;
        //     dailyBonusEnd = event.target.vBonusEnd1.value;
        // }

        var weeklyBonus = 0;
        var weeklyBonusCount = 0;
        var weeklyBonusType = 0;
        var weeklyBonusStart = 0;
        var weeklyBonusEnd = 0;
        // if(weeklyBonus){
        //     weeklyBonus = 1;
        //     weeklyBonusCount = event.target.vBonusCount1.value;
        //     weeklyBonusType = event.target.vBonusType1.value;
        //     weeklyBonusStart = event.target.vBonusStart1.value;
        //     weeklyBonusEnd = event.target.vBonusEnd1.value;
        // }

        var weekendBonus = 0;
        var weekendBonusCount = 0;
        var weekendBonusType = 0;
        var weekendBonusStart = 0;
        var weekendBonusEnd = 0;
        // if(weekendBonus){
        //     weekendBonus = 1;
        //     weekendBonusCount = event.target.vBonusCount1.value;
        //     weekendBonusType = event.target.vBonusType1.value;
        //     weekendBonusStart = event.target.vBonusStart1.value;
        //     weekendBonusEnd = event.target.vBonusEnd1.value;
        // }

        var monthlyBonus = 0;
        var monthlyBonusCount = 0;
        var monthlyBonusType = 0;
        var monthlyBonusStart = 0;
        var monthlyBonusEnd = 0;
        if(monthlyBonusCheck){
            monthlyBonus = 1;
            monthlyBonusCount = event.target.vBonusCount5.value;
            monthlyBonusType = event.target.vBonusType5.value;
            monthlyBonusStart = event.target.vBonusStart5.value;
            monthlyBonusEnd = event.target.vBonusEnd5.value;
        }

        var biannuallyBonus = 0;
        var biannuallyBonusCount = 0;
        var biannuallyBonusType = 0;
        var biannuallyBonusStart = 0;
        var biannuallyBonusEnd = 0;
        // if(biannuallyBonus){
        //     biannuallyBonus = 1;
        //     biannuallyBonusCount = event.target.vBonusCount1.value;
        //     biannuallyBonusType = event.target.vBonusType1.value;
        //     biannuallyBonusStart = event.target.vBonusStart1.value;
        //     biannuallyBonusEnd = event.target.vBonusEnd1.value;
        // }

        var oneyearBonus = 0;
        var oneyearBonusCount = 0;
        var oneyearBonusType = 0;
        var oneyearBonusStart = 0;
        var oneyearBonusEnd = 0;
        if(oneYearBonusCheck){
            oneyearBonus = 1;
            oneyearBonusCount = event.target.vBonusCount7.value;
            oneyearBonusType = event.target.vBonusType7.value;
            oneyearBonusStart = event.target.vBonusStart7.value;
            oneyearBonusEnd = event.target.vBonusEnd7.value;
        }

        var twoyearBonus = 0;
        var twoyearBonusCount = 0;
        var twoyearBonusType = 0;
        var twoyearBonusStart = 0;
        var twoyearBonusEnd = 0;
        if(twoYearsBonusCheck){
            twoyearBonus = 1;
            twoyearBonusCount = event.target.vBonusCount8.value;
            twoyearBonusType = event.target.vBonusType8.value;
            twoyearBonusStart = event.target.vBonusStart8.value;
            twoyearBonusEnd = event.target.vBonusEnd8.value;
        }

        var min_deposits = [
            event.target.vMindeposit1.value,
            event.target.vMindeposit2.value,
            event.target.vMindeposit3.value,
            event.target.vMindeposit4.value,
            event.target.vMindeposit5.value,
            event.target.vMindeposit6.value,
            event.target.vMindeposit7.value,
            event.target.vMindeposit8.value
        ]

        min_deposits = min_deposits[0]+','+min_deposits[1]+','+min_deposits[2]+','+min_deposits[3]+','+min_deposits[4]+','+min_deposits[5]+','+min_deposits[6]+','+min_deposits[7];

        if(addOns.length > 0){
            var addOns = JSON.stringify(addOns);
            addOns = addOns.replace(",{}", "");
            bodyFormData.append('addons',addOns);
        }


        var removeImgs = JSON.stringify(removeImgs);


        bodyFormData.append('removeImgs',removeImgs);


        var vimages0 = 0;
        var vimages1 = 0;
        var vimages2 = 0;
        var vimages3 = 0;
        var vimages4 = 0;
        var vimages5 = 0;
        var vimages6 = 0;
        var vimages7 = 0;
        var vimages8 = 0;
        var withThumb = 0;
        var errors = 0;
        var errorDescs = [];
        var imagesUpdates = [];
        if (typeof event.target.vimages0.files[0] !== 'undefined'){
            event.target.vimages0.files[0]['sequence'] = 0;
            withThumb = 1;
            vimages0 = event.target.vimages0.files[0];
            imagesUpdates.push(0);
        }

        if (typeof event.target.vimages1.files[0] !== 'undefined'){
            event.target.vimages1.files[0]['sequence'] = 1;
            vimages1 = event.target.vimages1.files[0];
            imagesUpdates.push(1);
        }

        if (typeof event.target.vimages2.files[0] !== 'undefined'){
            event.target.vimages2.files[0]['sequence'] = 2;
            vimages2 = event.target.vimages2.files[0];
            imagesUpdates.push(2);
        }

        if (typeof event.target.vimages3.files[0] !== 'undefined'){
            event.target.vimages3.files[0]['sequence'] = 3;
            vimages3 = event.target.vimages3.files[0];
            imagesUpdates.push(3);
        }

        if (typeof event.target.vimages4.files[0] !== 'undefined'){
            vimages4 = event.target.vimages4.files[0];
            imagesUpdates.push(4);
        }

        if (typeof event.target.vimages5.files[0] !== 'undefined'){
            vimages5 = event.target.vimages5.files[0];
            imagesUpdates.push(5);
        }

        if (typeof event.target.vimages6.files[0] !== 'undefined'){
            vimages6 = event.target.vimages6.files[0];
            imagesUpdates.push(6);
        }

        if (typeof event.target.vimages7.files[0] !== 'undefined'){
            vimages7 = event.target.vimages7.files[0];
            imagesUpdates.push(7);
        }

        if (typeof event.target.vimages8.files[0] !== 'undefined'){
            vimages8 = event.target.vimages8.files[0];
            imagesUpdates.push(8);
        }


        // bodyFormData.append('product_price_hour', event.target.vHourly.value);
        // bodyFormData.append('product_price_day', event.target.vDaily.value);
        // bodyFormData.append('product_price_week', event.target.vWeekly.value);
        // bodyFormData.append('product_price_month', event.target.vMonthly.value);
        // bodyFormData.append('product_price_biannually', event.target.vBiannually.value);
        // bodyFormData.append('product_price_year', event.target.vYearly.value);
        // bodyFormData.append('product_price_weekend', event.target.vWeekend.value);
        // bodyFormData.append('product_price_year_2', event.target.v2yr.value);
        if((parseFloat(event.target.vHourly.value) > 0 && (parseFloat(event.target.vMindeposit1.value) <= 0 || event.target.vMindeposit1.value == "")))  {
            errors++;
            errorDescs.push({value: "Min Deposit for hourly"});

        }
        if((parseFloat( event.target.vDaily.value) > 0 && (parseFloat(event.target.vMindeposit2.value) <= 0 || event.target.vMindeposit2.value == "")))  {
            errors++;
            errorDescs.push({value: "Min Deposit for daily"});

        }
        if((parseFloat( event.target.vWeekly.value) > 0 && (parseFloat(event.target.vMindeposit3.value) <= 0 || event.target.vMindeposit3.value == "")))  {
            errors++;
            errorDescs.push({value: "Min Deposit for weekly"});

        }
        if((parseFloat( event.target.vWeekend.value) > 0 && (parseFloat(event.target.vMindeposit4.value) <= 0 || event.target.vMindeposit4.value == "")))  {
            errors++;
            errorDescs.push({value: "Min Deposit for weekend"});

        }
        if((parseFloat( event.target.vMonthly.value) > 0 && (parseFloat(event.target.vMindeposit5.value) <= 0 || event.target.vMindeposit5.value == "")))  {
            errors++;
            errorDescs.push({value: "Min Deposit for montly"});

        }
        if((parseFloat( event.target.vBiannually.value) > 0 && (parseFloat(event.target.vMindeposit6.value) <= 0 || event.target.vMindeposit6.value == "")))  {
            errors++;
            errorDescs.push({value: "Min Deposit for bi-annually"});

        }
        if((parseFloat( event.target.vYearly.value) > 0 && (parseFloat(event.target.vMindeposit7.value) <= 0 || event.target.vMindeposit7.value == "")))  {
            errors++;
            errorDescs.push({value: "Min Deposit for 1-year"});

        }
        if((parseFloat( event.target.v2yr.value) > 0 && (parseFloat(event.target.vMindeposit8.value) <= 0 || event.target.vMindeposit8.value == "")))  {
            errors++;
            errorDescs.push({value: "Min Deposit for 2-year"});

        }


        //
        // bodyFormData.append('product_price_hour', event.target.vHourly.value);
        // bodyFormData.append('product_price_day', event.target.vDaily.value);
        // bodyFormData.append('product_price_week', event.target.vWeekly.value);
        // bodyFormData.append('product_price_month', event.target.vMonthly.value);
        // bodyFormData.append('product_price_biannually', event.target.vBiannually.value);
        // bodyFormData.append('product_price_year', event.target.vYearly.value);
        // bodyFormData.append('product_price_weekend', event.target.vWeekend.value);
        // bodyFormData.append('product_price_year_2', event.target.v2yr.value);

        if(!errors){
            bodyFormData.append('product_id', this.state.currentVehicle);
            bodyFormData.append('vehiclefile[0]', vimages0);
            bodyFormData.append('vehiclefile[1]', vimages1);
            bodyFormData.append('vehiclefile[2]', vimages2);
            bodyFormData.append('vehiclefile[3]', vimages3);
            bodyFormData.append('vehiclefile[4]', vimages4);
            bodyFormData.append('vehiclefile[5]', vimages5);
            bodyFormData.append('vehiclefile[6]', vimages6);
            bodyFormData.append('vehiclefile[7]', vimages7);
            bodyFormData.append('vehiclefile[8]', vimages8);
            bodyFormData.append('withThumb', withThumb);
            bodyFormData.append('imagesUpdates', imagesUpdates);
            bodyFormData.append('product_name', event.target.vName.value);
            bodyFormData.append('product_type', event.target.vType.value);
            bodyFormData.append('product_model', event.target.vModel.value);
            bodyFormData.append('product_registration_year', event.target.vRYear.value);
            bodyFormData.append('product_brand', event.target.vBrand.value);
            bodyFormData.append('category_name', event.target.vCategory.value);
            bodyFormData.append('product_colour', event.target.vColour.value);
            bodyFormData.append('product_platenumber', event.target.dPlate.value);
            bodyFormData.append('product_description', event.target.vDesc.value);


            bodyFormData.append('product_min_deposit_amount', min_deposits);

            bodyFormData.append('product_price_hour', event.target.vHourly.value);
            bodyFormData.append('product_price_day', event.target.vDaily.value);
            bodyFormData.append('product_price_week', event.target.vWeekly.value);
            bodyFormData.append('product_price_month', event.target.vMonthly.value);
            bodyFormData.append('product_price_biannually', event.target.vBiannually.value);
            bodyFormData.append('product_price_year', event.target.vYearly.value);
            bodyFormData.append('product_price_weekend', event.target.vWeekend.value);
            bodyFormData.append('product_price_year_2', event.target.v2yr.value);

            bodyFormData.append('product_transmission', event.target.vTransmission.value);
            bodyFormData.append('product_engine', event.target.vEngine.value);
            bodyFormData.append('product_mileage', event.target.vMileage.value);
            bodyFormData.append('product_rental_options', event.target.vTerm.value);
            bodyFormData.append('product_rent_terms', event.target.vTerm.value);
            bodyFormData.append('product_gas', event.target.vGas.value);
            bodyFormData.append('product_passengers', event.target.vPassengers.value);
            bodyFormData.append('product_doors', event.target.vDoors.value);
            bodyFormData.append('product_baggage', event.target.vBaggage.value);
            bodyFormData.append('product_instruction', event.target.vInstruction.value);

            bodyFormData.append('product_min_age', event.target.vMinAge.value);
            bodyFormData.append('product_experience', event.target.vMinExp.value);

            bodyFormData.append('product_review_rate', event.target.vReview.value);
            bodyFormData.append('product_min_days', event.target.vMindays.value);

            bodyFormData.append('product_insurance_excess_1', event.target.insuranceExcess1.value);
            bodyFormData.append('product_insurance_excess_2', event.target.insuranceExcess2.value);
            bodyFormData.append('product_insurance_excess_3', event.target.insuranceExcess3.value);

            bodyFormData.append('hourly_bonus',hourlyBonus);
            bodyFormData.append('hourly_bonus_count', hourlyBonusCount);
            bodyFormData.append('hourly_bonus_type', hourlyBonusType);
            bodyFormData.append('hourly_bonus_start', hourlyBonusStart);
            bodyFormData.append('hourly_bonus_end',hourlyBonusEnd);

            bodyFormData.append('daily_bonus',dailyBonus);
            bodyFormData.append('daily_bonus_count', dailyBonusCount);
            bodyFormData.append('daily_bonus_type', dailyBonusType);
            bodyFormData.append('daily_bonus_start', dailyBonusStart);
            bodyFormData.append('daily_bonus_end',dailyBonusEnd);

            bodyFormData.append('weekly_bonus',weeklyBonus);
            bodyFormData.append('weekly_bonus_count', weeklyBonusCount);
            bodyFormData.append('weekly_bonus_type', weeklyBonusType);
            bodyFormData.append('weekly_bonus_start', weeklyBonusStart);
            bodyFormData.append('weekly_bonus_end',weeklyBonusEnd);

            bodyFormData.append('weekend_bonus',weekendBonus);
            bodyFormData.append('weekend_bonus_count', weekendBonusCount);
            bodyFormData.append('weekend_bonus_type', weekendBonusType);
            bodyFormData.append('weekend_bonus_start', weekendBonusStart);
            bodyFormData.append('weekend_bonus_end',weekendBonusEnd);

            bodyFormData.append('monthly_bonus',monthlyBonus);
            bodyFormData.append('monthly_bonus_count', monthlyBonusCount);
            bodyFormData.append('monthly_bonus_type', monthlyBonusType);
            bodyFormData.append('monthly_bonus_start', monthlyBonusStart);
            bodyFormData.append('monthly_bonus_end',monthlyBonusEnd);

            bodyFormData.append('binannully_bonus',biannuallyBonus);
            bodyFormData.append('binannully_bonus_count', biannuallyBonusCount);
            bodyFormData.append('binannully_bonus_type', biannuallyBonusType);
            bodyFormData.append('binannully_bonus_start', biannuallyBonusStart);
            bodyFormData.append('binannully_bonus_end',biannuallyBonusEnd);

            bodyFormData.append('oneyear_bonus',oneyearBonus);
            bodyFormData.append('oneyear_bonus_count', oneyearBonusCount);
            bodyFormData.append('oneyear_bonus_type', oneyearBonusType);
            bodyFormData.append('oneyear_bonus_start', oneyearBonusStart);
            bodyFormData.append('oneyear_bonus_end',oneyearBonusEnd);

            bodyFormData.append('twoyear_bonus',twoyearBonus);
            bodyFormData.append('twoyear_bonus_count', twoyearBonusCount);
            bodyFormData.append('twoyear_bonus_type', twoyearBonusType);
            bodyFormData.append('twoyear_bonus_start', twoyearBonusStart);
            bodyFormData.append('twoyear_bonus_end',twoyearBonusEnd);


            bodyFormData.append('jham_token', token);
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');


            axios({
                  method: 'post',
                  url: 'https://api.earental.com/lessor/update_product',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {
                 if(response.data.error > 0){
                     function ErrorMsg(props) {
                       return (
                           props.msgs.map((item,i) =>
                               <li key={"err-"+item.i}>{item.value}</li>
                           )

                       );
                     }
                     store.addNotification({
                       title: "Invalid information",
                       message: <ErrorMsg msgs={response.data.error_message} />,
                       type: "danger",
                       insert: "top",
                       container: "top-right",
                       animationIn: ["animated", "fadeIn"],
                       animationOut: ["animated", "fadeOut"],
                       dismiss: {
                         duration: 2000,
                         onScreen: false
                       }
                     });
                 }else{

                     this.setState({ openSbEdit: true });
                     this.setState({ openEditModal: false });

                     this.getVehicles();




                 }

                 document.getElementById("updateVehicleBtn").disabled = false;
                 document.getElementById("updateVehicleBtn").innerText = "Update this vehicle";

              })
              .catch((error) => {
                  //handle error
                  console.log(error);
                //   document.getElementById("updateVehicleBtn").disabled = false;
                //   document.getElementById("updateVehicleBtn").innerText = "Update this vehicle";

            });
        }else{
            function ErrorMsg(props) {
              return (
                  props.msgs.map((item,i) =>
                      <li key={"err-"+item.i}>{item.value}</li>
                  )

              );
            }
            store.addNotification({
              title: "Required Fields",
              message: <ErrorMsg msgs={errorDescs} />,
              type: "warning",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 10000,
                onScreen: false
              }
            });
            document.getElementById("updateVehicleBtn").disabled = false;
            document.getElementById("updateVehicleBtn").innerText = "Update this vehicle";
        }






    }




    handlePageChanged(newPage) {
        this.setState({ currentPage : newPage });
        setTimeout(() => {
            this.getVehicles();

        }, 150);
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = event => {
        event.preventDefault();

        const request = {
            pickup: this.state.pickup,
            checkin: this.state.checkin,
            timein: this.state.timein,
            dropoff: this.state.dropoff,
            checkout: this.state.checkout,
            timeout: this.state.timeout,
        };

        axios.post(`https://jsonplaceholder.typicode.com/users`, { request })
        .then(res => {
          console.log(res);
          console.log(res.data);
        })
    }






  getVehicles() {
      var ctoken = localStorage.getItem('ctoken');
      var token = localStorage.getItem('token');
      var bodyFormData = new FormData();

      var currentOffset = this.state.limit * this.state.currentPage;
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      bodyFormData.append('limit', this.state.limit);
      bodyFormData.append('offset', currentOffset);
      bodyFormData.append('keyword', "");


      axios({
              method: 'post',
              url: 'https://api.earental.com/lessor/lessor_vehicles',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
              console.log(response);
              if(response.data.lessor_vehicles && response.data.lessor_vehicles !== null){
                console.log(response.data.data);
                  this.setState({
                      products:response.data.lessor_vehicles,
                      loading:false,
                      loadingclass:'isHidden',
                      totalPage:response.data.total_pages,
                      noResult:false,
                      hidePager: ""
                  });
              }else{
                  this.setState({
                      products:[],
                      loading:false,
                      loadingclass:'isHidden',
                      totalPage:0,
                      noResult:true,
                      hidePager:"isHidden"
                  });
              }



          })
          .catch((error) => {
              //handle error
              this.setState({
                  products:[],
                  loading:false,
                  loadingclass:'isHidden',
                  totalPage:0
              });

      });


  }

  componentDidMount() {
      this.getVehicles();
  }


    renderListing() {
        function Driver(props){
            const classes = useStyles();
            const [expanded, setExpanded] = React.useState(false);

            const handleExpandClick = () => {
                setExpanded(!expanded);
            };
            var driverImage = noImage;
            if(props.details.pi_images){
                var piImagesTemp = props.details.pi_images.split(',');
                driverImage = 'https://api.earental.com/uploads/vehicles/'+piImagesTemp[0];
            }
            var paAddOns = [];
            var paAddOnsPrice = [];
            var productName = props.details.product_name+" ("+props.details.product_registration_year+")";

            if(props.details.pa_addons){
                paAddOns = props.details.pa_addons.split(",");
                paAddOnsPrice = props.details.pa_addons_price.split(",");
            }
            return (
                <Card className={classes.root+' driver-card'}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={classes.avatar}>
                        {props.details.product_name.charAt(0)}
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="delete" title="Remove this driver" onClick={props.openDeleteConfirmModal.bind(this,props.details.product_id)}>
                        <DeleteIcon title="Remove this driver" />
                      </IconButton>
                    }
                    title={props.details.product_status}
                    subheader={props.details.product_platenumber}
                  />

                  <CardMedia
                    className={classes.media}
                    image={driverImage}
                    title="Image not available"
                    onClick={props.openEditModal.bind(this,props.details)}
                  />
                  <CardContent>
                    <Typography variant="h6">
                        {props.details.product_name} ({props.details.product_registration_year})
                    </Typography>
                    <Typography variant="body2"  component="p">
                        {props.details.product_description}
                    </Typography>
                  </CardContent>
                  <CardActions disableSpacing>
                    <IconButton  title="Schedules" aria-label="Schedules" onClick={props.handleToggleSchedule.bind(this,productName,props.details.product_id)}>
                        <EventBusyIcon  />
                    </IconButton>
                    <IconButton
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                      })}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </CardActions>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                   <CardContent>
                    <hr />
                    <Typography variant="h5">Add Ons:</Typography>
                    <hr />
                    <List>
                        {paAddOns.map((addOn,i) =>
                            <ListItem key={"pa-"+i}>
                                <ListItemText edge="start" primary={addOn.trim()} />
                                <ListItemSecondaryAction>
                                    <b>S${paAddOnsPrice[i].trim()}</b>
                                </ListItemSecondaryAction>
                            </ListItem>
                            )
                        }

                    </List>
                   </CardContent>
                 </Collapse>
                </Card>
              );

        }
        if (!this.state.noResult) {
            return (
                <div className="row row-wrap">
                    <div className={this.state.loadingclass}>
                        <div className="col-md-3">
                            <MyLoader  />
                        </div>
                        <div className="col-md-3">
                            <MyLoader  />
                        </div>
                        <div className="col-md-3">
                            <MyLoader  />
                        </div>
                        <div className="col-md-3">
                            <MyLoader  />
                        </div>
                    </div>

                    { this.state.products.map(product =>
                        <div className="col-md-3" key={"dbox-"+product.product_id}>
                            <Driver details={product} openDeleteConfirmModal={this.openDeleteConfirmModal} openEditModal={this.openEditModal} handleToggleSchedule={this.handleToggleSchedule} />
                        </div>
                        )
                    }

                </div>
            );
        }else{
            return (
                <div className="vehicle-listing text-center">
                    <br />
                    <p className="not-found"><b>No Vehicle Found</b></p>
                    <p>Start creating now by clicking the "Blue icon" Button at the Bottom Right
                    </p>
                </div>
            );
        }

    }



  render() {
      function DriverAdd(props){
          const classes = useStyles();
          const [expanded, setExpanded] = React.useState(false);

          const handleExpandClick = () => {
          setExpanded(!expanded);
          };
          return (
              <Button
                   variant="contained"
                   color="primary"
                   size="large"
                   className={classes.button+' popup-text'}
                   data-effect="mfp-zoom-out"
                   startIcon={<AddIcon />}
                   onClick={props.click}
                 >
                   Add New Driver
             </Button>
            );

      }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }


    function ModalAdd(props){
        const classes = useStyles();
        const [isOpen, setIsOpen] = useState(false);
        const [selectedDate, setSelectedDate] = React.useState(new Date())

        const handleDateChange = (date) => {
           setSelectedDate(date);
           setIsOpen(false)
         };

         const [currentName, setCurrentName] = useState("");
         const [currentPlate, setCurrentPlate] = useState("");
         const [currentModel, setCurrentModel] = useState("");
         const [currentColour, setCurrentColour] = useState("");
         const [currentBrand, setCurrentBrand] = useState("");
         const [currentCategory, setCurrentCategory] = useState("");
         const [currentType, setCurrentType] = useState("");
         const [currentReview, setCurrentReview] = useState("");

         const [currentTransmission, setCurrentTransmission] = useState("");
         const [currentEngine, setCurrentEngine] = useState("");
         const [currentGas, setCurrentGas] = useState("");
         const [currentTerm, setCurrentTerm] = useState("");
         const [currentPassenger, setCurrentPassenger] = useState("");
         const [currentMileage, setCurrentMileage] = useState("");
         const [currentDoors, setCurrentDoors] = useState("");
         const [currentBaggage, setCurrentBaggage] = useState("");
         const [currentDesc, setCurrentDesc] = useState("");
         const [currentInstruction, setCurrentInstruction] = useState("");

         const [currentMinAge, setCurrentMinAge] = useState("");
         const [currentMinExp, setCurrentMinExp] = useState("");
         const [currentRegistrationYear, setCurrentRegistrationYear] = useState("");

         const [currentMinDeposit, setCurrentMinDeposit] = useState(0);
         const [currentHourly, setCurrentHourly] = useState(0);
         const [currentDaily, setCurrentDaily] = useState(0);
         const [currentWeekly, setCurrentWeekly] = useState(0);
         const [currentWeekend, setCurrentWeekend] = useState(0);
         const [currentMonthly, setCurrentMonthly] = useState(0);
         const [currentBiAnnually, setCurrentBiAnnually] = useState(0);
         const [currentYearly, setCurrentYearly] = useState(0);
         const [current2Yr, setCurrent2Yr] = useState(0);
         const [currentMinDays, setCurrentMinDays] = useState(1);

         const [currentMinDeposit1, setCurrentMinDeposit1] = useState(0);
         const [currentMinDeposit2, setCurrentMinDeposit2] = useState(0);
         const [currentMinDeposit3, setCurrentMinDeposit3] = useState(0);
         const [currentMinDeposit4, setCurrentMinDeposit4] = useState(0);
         const [currentMinDeposit5, setCurrentMinDeposit5] = useState(0);
         const [currentMinDeposit6, setCurrentMinDeposit6] = useState(0);
         const [currentMinDeposit7, setCurrentMinDeposit7] = useState(0);
         const [currentMinDeposit8, setCurrentMinDeposit8] = useState(0);

         const [currentImages0, setCurrentImages0] = useState(false);
         const [currentImages1, setCurrentImages1] = useState(false);
         const [currentImages2, setCurrentImages2] = useState(false);
         const [currentImages3, setCurrentImages3] = useState(false);
         const [currentImages4, setCurrentImages4] = useState(false);
         const [currentImages5, setCurrentImages5] = useState(false);
         const [currentImages6, setCurrentImages6] = useState(false);
         const [currentImages7, setCurrentImages7] = useState(false);
         const [currentImages8, setCurrentImages8] = useState(false);
         const [showPreview, setShowPreview] = useState(false);

         const [currentInsuranceExcess1, setcurrentInsuranceExcess1] = useState(2000);
         const [currentInsuranceExcess2, setcurrentInsuranceExcess2] = useState(4000);
         const [currentInsuranceExcess3, setcurrentInsuranceExcess3] = useState(3000);


         const [currentRateBonus1, setCurrentRateBonus1] = useState(false);
         const [currentRateBonus2, setCurrentRateBonus2] = useState(false);
         const [currentRateBonus3, setCurrentRateBonus3] = useState(false);
         const [currentRateBonus4, setCurrentRateBonus4] = useState(false);
         const [currentRateBonus5, setCurrentRateBonus5] = useState(false);
         const [currentRateBonus6, setCurrentRateBonus6] = useState(false);
         const [currentRateBonus7, setCurrentRateBonus7] = useState(false);
         const [currentRateBonus8, setCurrentRateBonus8] = useState(false);

         const [vBonusCount1, setvBonusCount1] = useState(0);
         const [vBonusCount2, setvBonusCount2] = useState(0);
         const [vBonusCount3, setvBonusCount3] = useState(0);
         const [vBonusCount4, setvBonusCount4] = useState(0);
         const [vBonusCount5, setvBonusCount5] = useState(0);
         const [vBonusCount6, setvBonusCount6] = useState(0);
         const [vBonusCount7, setvBonusCount7] = useState(0);
         const [vBonusCount8, setvBonusCount8] = useState(0);

         const [vBonusType1, setVBonusType1] = useState("months");
         const [vBonusType2, setVBonusType2] = useState("months");
         const [vBonusType3, setVBonusType3] = useState("months");
         const [vBonusType4, setVBonusType4] = useState("months");
         const [vBonusType5, setVBonusType5] = useState("months");
         const [vBonusType6, setVBonusType6] = useState("months");
         const [vBonusType7, setVBonusType7] = useState("months");
         const [vBonusType8, setVBonusType8] = useState("months");

        const [vBonusStart1, setVBonusStart1] = useState(null);
        const [vBonusStart2, setVBonusStart2] = useState(null);
        const [vBonusStart3, setVBonusStart3] = useState(null);
        const [vBonusStart4, setVBonusStart4] = useState(null);
        const [vBonusStart5, setVBonusStart5] = useState(null);
        const [vBonusStart6, setVBonusStart6] = useState(null);
        const [vBonusStart7, setVBonusStart7] = useState(null);
        const [vBonusStart8, setVBonusStart8] = useState(null);

        const [vBonusEnd1, setVBonusEnd1] = useState(null);
        const [vBonusEnd2, setVBonusEnd2] = useState(null);
        const [vBonusEnd3, setVBonusEnd3] = useState(null);
        const [vBonusEnd4, setVBonusEnd4] = useState(null);
        const [vBonusEnd5, setVBonusEnd5] = useState(null);
        const [vBonusEnd6, setVBonusEnd6] = useState(null);
        const [vBonusEnd7, setVBonusEnd7] = useState(null);
        const [vBonusEnd8, setVBonusEnd8] = useState(null);



        const handleChangeBonusCount = (event) => {

            if(event.target.name == "vBonusCount1"){
                setvBonusCount1(event.target.value);
            }else if(event.target.name == "vBonusCount2"){
                setvBonusCount2(event.target.value);
            }else if(event.target.name == "vBonusCount3"){
                setvBonusCount3(event.target.value);
            }else if(event.target.name == "vBonusCount4"){
                setvBonusCount4(event.target.value);
            }else if(event.target.name == "vBonusCount5"){
                setvBonusCount5(event.target.value);
            }else if(event.target.name == "vBonusCount6"){
                setvBonusCount6(event.target.value);
            }else if(event.target.name == "vBonusCount7"){
                setvBonusCount7(event.target.value);
            }else if(event.target.name == "vBonusCount8"){
                setvBonusCount8(event.target.value);
            }

        };

        const handleChangeBonusType = (event) => {

            if(event.target.name == "vBonusType1"){
                setVBonusType1(event.target.value);
            }else if(event.target.name == "vBonusType2"){
                setVBonusType2(event.target.value);
            }else if(event.target.name == "vBonusType3"){
                setVBonusType3(event.target.value);
            }else if(event.target.name == "vBonusType4"){
                setVBonusType4(event.target.value);
            }else if(event.target.name == "vBonusType5"){
                setVBonusType5(event.target.value);
            }else if(event.target.name == "vBonusType6"){
                setVBonusType6(event.target.value);
            }else if(event.target.name == "vBonusType7"){
                setVBonusType7(event.target.value);
            }else if(event.target.name == "vBonusType8"){
                setVBonusType8(event.target.value);
            }

        };

        const handleChangeBonusStart1 = (date) => {
            setVBonusStart1(date);
        };
        const handleChangeBonusStart2 = (date) => {
            setVBonusStart2(date);
        };
        const handleChangeBonusStart3 = (date) => {
            setVBonusStart3(date);
        };
        const handleChangeBonusStart4 = (date) => {
            setVBonusStart4(date);
        };
        const handleChangeBonusStart5 = (date) => {
            setVBonusStart5(date);
        };
        const handleChangeBonusStart6 = (date) => {
            setVBonusStart6(date);
        };
        const handleChangeBonusStart7 = (date) => {
            setVBonusStart7(date);
        };
        const handleChangeBonusStart8 = (date) => {
            setVBonusStart8(date);
        };

        const handleChangeBonusEnd1 = (date) => {
            setVBonusEnd1(date);
        };
        const handleChangeBonusEnd2 = (date) => {
            setVBonusEnd2(date);
        };
        const handleChangeBonusEnd3 = (date) => {
            setVBonusEnd3(date);
        };
        const handleChangeBonusEnd4 = (date) => {
            setVBonusEnd4(date);
        };
        const handleChangeBonusEnd5 = (date) => {
            setVBonusEnd5(date);
        };
        const handleChangeBonusEnd6 = (date) => {
            setVBonusEnd6(date);
        };
        const handleChangeBonusEnd7 = (date) => {
            setVBonusEnd7(date);
        };
        const handleChangeBonusEnd8 = (date) => {
            setVBonusEnd8(date);
        };



         const handleChangeBonus = (event) => {

             if(event.target.name == "hourlyBonus"){
                 setCurrentRateBonus1(!currentRateBonus1);
             }else if(event.target.name == "dailyBonus"){
                 setCurrentRateBonus2(!currentRateBonus2);
             }else if(event.target.name == "weeklyBonus"){
                 setCurrentRateBonus3(!currentRateBonus3);
             }else if(event.target.name == "weekendBonus"){
                 setCurrentRateBonus4(!currentRateBonus4);
             }else if(event.target.name == "monthlyBonus"){
                 setCurrentRateBonus5(!currentRateBonus5);
             }else if(event.target.name == "biannuallyBonus"){
                 setCurrentRateBonus6(!currentRateBonus6);
             }else if(event.target.name == "oneYearBonus"){
                 setCurrentRateBonus7(!currentRateBonus7);
             }else if(event.target.name == "twoYearsBonus"){
                 setCurrentRateBonus8(!currentRateBonus8);
             }

         };

         const togglePreview = () => {
            setShowPreview(!showPreview);
          };

          const handleNameChange = (e) => {
              setCurrentName(e.target.value);
          };
          const handlePlateChange = (e) => {
              setCurrentPlate(e.target.value);
          };
          const handleModelChange = (e) => {
              setCurrentModel(e.target.value);
          };
          const handleBrandChange = (e) => {
              setCurrentBrand(e.target.value);
          };
          const handleColourChange = (e) => {
              setCurrentColour(e.target.value);
          };
          const handleCategoryChange = (e,values) => {
              setCurrentCategory(values);
          };
          const handleTypeChange = (e,values) => {
              setCurrentType(values);
          };

          const handleTransmissionChange = (e,values) => {
              setCurrentTransmission(values);
          };
          const handleEngineChange = (e) => {
              setCurrentEngine(e.target.value);
          };
          const handleGasChange = (e,values) => {
              setCurrentGas(values);
          };
          const handleTermChange = (e,values) => {
              setCurrentTerm(values);
          };
          const handlePassengerChange = (e) => {
              setCurrentPassenger(e.target.value);
          };
          const handleMileageChange = (e) => {
              setCurrentMileage(e.target.value);
          };
          const handleDoorsChange = (e) => {
              setCurrentDoors(e.target.value);
          };
          const handleBaggageChange = (e) => {
              setCurrentBaggage(e.target.value);
          };
          const handleDescChange = (e) => {
              setCurrentDesc(e.target.value);
          };

          const handleMinAgeChange = (e) => {
              setCurrentMinAge(e.target.value);
          };
          const handleMinExpChange = (e) => {
              setCurrentMinExp(e.target.value);
          };
          const handleRegistrationYearChange = (e) => {
              setCurrentRegistrationYear(e.target.value);
          };

         const handleHourlyChange = (e) => {
             let val = parseFloat(e.target.value);
             if(val <= 0){
                 val = 0.00;
             }
             setCurrentHourly(val);
         };
         const handleDailyChange = (e) => {
             let val = parseFloat(e.target.value);
             if(val <= 0){
                 val = 0.00;
             }
             setCurrentDaily(val);
         };
         const handleWeeklyChange = (e) => {
             let val = parseFloat(e.target.value);
             if(val <= 0){
                 val = 0.00;
             }
             setCurrentWeekly(val);
         };
         const handleWeekendChange = (e) => {
             let val = parseFloat(e.target.value);
             if(val <= 0){
                 val = 0.00;
             }
             setCurrentWeekend(val);
         };
         const handleMonthlyChange = (e) => {
             let val = parseFloat(e.target.value);
             if(val <= 0){
                 val = 0.00;
             }
             setCurrentMonthly(val);
         };
         const handleBiAnnuallyChange = (e) => {
             let val = parseFloat(e.target.value);
             if(val <= 0){
                 val = 0.00;
             }
             setCurrentBiAnnually(val);
         };
         const handleYearlyChange = (e) => {
             let val = parseFloat(e.target.value);
             if(val <= 0){
                 val = 0.00;
             }
             setCurrentYearly(val);
         };
         const handle2YrChange = (e) => {
             let val = parseFloat(e.target.value);
             if(val <= 0){
                 val = 0.00;
             }
             setCurrent2Yr(val);
         };

         const handleMinDaysChange = (e) => {
             let val = parseFloat(e.target.value);
             if(val <= 0){
                 val = 0.00;
             }
             setCurrentMinDays(val);
         };
         const handleMinDeposit1Change = (e) => {
             let val = parseFloat(e.target.value);
             if(val <= 0){
                 val = 0.00;
             }
             setCurrentMinDeposit1(val);
         };
         const handleMinDeposit2Change = (e) => {
             let val = parseFloat(e.target.value);
             if(val <= 0){
                 val = 0.00;
             }
             setCurrentMinDeposit2(val);
         };
         const handleMinDeposit3Change = (e) => {
             let val = parseFloat(e.target.value);
             if(val <= 0){
                 val = 0.00;
             }
             setCurrentMinDeposit3(val);
         };
         const handleMinDeposit4Change = (e) => {
             let val = parseFloat(e.target.value);
             if(val <= 0){
                 val = 0.00;
             }
             setCurrentMinDeposit4(val);
         };
         const handleMinDeposit5Change = (e) => {
             let val = parseFloat(e.target.value);
             if(val <= 0){
                 val = 0.00;
             }
             setCurrentMinDeposit5(val);
         };
         const handleMinDeposit6Change = (e) => {
             let val = parseFloat(e.target.value);
             if(val <= 0){
                 val = 0.00;
             }
             setCurrentMinDeposit6(val);
         };
         const handleMinDeposit7Change = (e) => {
             let val = parseFloat(e.target.value);
             if(val <= 0){
                 val = 0.00;
             }
             setCurrentMinDeposit7(val);
         };
         const handleMinDeposit8Change = (e) => {
             let val = parseFloat(e.target.value);
             if(val <= 0){
                 val = 0.00;
             }
             setCurrentMinDeposit8(val);
         };
         const handleInsuranceExcess1Change = (e) => {
          let val = parseFloat(e.target.value);
          if (val <= 0) val = 0.00;
          setcurrentInsuranceExcess1(val);
         }
         const handleInsuranceExcess2Change = (e) => {
          let val = parseFloat(e.target.value);
          if (val <= 0) val = 0.00;
          setcurrentInsuranceExcess2(val);
         }
         const handleInsuranceExcess3Change = (e) => {
          let val = parseFloat(e.target.value);
          if (val <= 0) val = 0.00;
          setcurrentInsuranceExcess3(val);
         }


         const types = [
           { title: 'Van' },
           { title: 'Lorry' },
           { title: 'Truck'},
           { title: 'Car'},
           { title: 'Item'},
         ];

         const categories = [
           { title: 'Vehicle' }
         ];

         const transmissions = [
           { title: 'Automatic' },
           { title: 'Manual' },
         ];

         const engines = [
           { title: 'Gas' },
           { title: 'Electric' },
           { title: 'Hybrid' }
         ];

         const gases = [
           { title: 'Gas' },
           { title: 'Diesel' }
         ];

         const terms = [
           { title: 'Short Term' },
           { title: 'Long Term' },
           { title: 'Short Term,Long Term' },
         ];

         const [inputList, setInputList] = useState([{ title: "", price: "0.00",ve_max_qty:1}]);

          // handle input change
          const handleAddOnChange = (e, index) => {
            const { name, value } = e.target;
            const list = [...inputList];
            list[index][name] = value.replace(/,/g, '');
            setInputList(list);
          };

          // handle click event of the Remove button
          const handleRemoveClick = index => {
            const list = [...inputList];
            list.splice(index, 1);
            setInputList(list);
          };

          // handle click event of the Add button
          const handleAddClick = () => {
            setInputList([...inputList, { title: "", price: "0.00",ve_max_qty:1  }]);
          };

          var piImages = [];
          for (var i = 0; i < 9; i++) {
                piImages.push(noImage);
          }

          const handleChangeImage = (index,e) => {
                var file    = e.target.files[0];
                var preview = URL.createObjectURL(file);



                if(index == 0){
                    setCurrentImages0(preview);
                }else if(index == 1){
                    setCurrentImages1(preview);
                }else if(index == 2){
                    setCurrentImages2(preview);
                }else if(index == 3){
                    setCurrentImages3(preview);
                }else if(index == 4){
                    setCurrentImages4(preview);
                }else if(index == 5){
                    setCurrentImages5(preview);
                }else if(index == 6){
                    setCurrentImages6(preview);
                }else if(index == 7){
                    setCurrentImages7(preview);
                }else if(index == 8){
                    setCurrentImages8(preview);
                }


            };

            const handleResetFile = (index,e) => {

                  if(index == 0){
                      document.getElementById("vimgAdd0").value = "";
                      setCurrentImages0(false);
                  }else if(index == 1){
                      document.getElementById("vimgAdd1").value = "";
                      setCurrentImages1(false);
                  }else if(index == 2){
                      document.getElementById("vimgAdd2").value = "";
                      setCurrentImages2(false);
                  }else if(index == 3){
                      document.getElementById("vimgAdd3").value = "";
                      setCurrentImages3(false);
                  }else if(index == 4){
                      document.getElementById("vimgAdd4").value = "";
                      setCurrentImages4(false);
                  }else if(index == 5){
                      document.getElementById("vimgAdd5").value = "";
                      setCurrentImages5(false);
                  }else if(index == 6){
                      document.getElementById("vimgAdd6").value = "";
                      setCurrentImages6(false);
                  }else if(index == 7){
                      document.getElementById("vimgAdd7").value = "";
                      setCurrentImages7(false);
                  }else if(index == 8){
                      document.getElementById("vimgAdd8").value = "";
                      setCurrentImages8(false);
                  }


              };

              const handlePasteChange = (e) => {
                  e.preventDefault();
              }

              var details = {
                  product_name:currentName,
                  product_brand:currentBrand,
                  product_model:currentModel,
                  product_registration_year:currentRegistrationYear,
                  product_colour:currentColour,
                  product_min_age:currentMinAge,
                  product_experience:currentMinExp,
                  product_transmission:currentTransmission,
                  product_passengers:currentPassenger,
                  product_doors:currentDoors,
                  product_baggage:currentBaggage,
                  product_gas:currentGas,
                  product_description:currentDesc,
                  product_rent_terms: currentTerm,
                  images:[
                      {
                          original: currentImages0 || piImages[0],
                          thumbnail: currentImages0 || piImages[0],
                      },
                      {
                          original: currentImages1 || piImages[1],
                          thumbnail: currentImages1 || piImages[1],
                      },
                      {
                          original: currentImages2 || piImages[2],
                          thumbnail: currentImages2 || piImages[2],
                      },
                      {
                          original: currentImages3 || piImages[3],
                          thumbnail: currentImages3 || piImages[3],
                      },
                      {
                          original: currentImages4 || piImages[4],
                          thumbnail: currentImages4 || piImages[4],
                      },
                      {
                          original: currentImages5 || piImages[5],
                          thumbnail: currentImages5 || piImages[5],
                      },
                      {
                          original: currentImages6 || piImages[6],
                          thumbnail: currentImages6 || piImages[6],
                      },
                      {
                          original: currentImages7 || piImages[7],
                          thumbnail: currentImages7 || piImages[7],
                      },
                      {
                          original: currentImages8 || piImages[8],
                          thumbnail: currentImages8 || piImages[8],
                      }
                  ],
                  addOns: inputList

              }

        return (

            <Dialog
                disableEnforceFocus={false}
                fullWidth={true}
                maxWidth = {'md'}
                open={props.openState}
                onClose={props.closeModal}
                disableBackdropClick={true}
                aria-labelledby="form-dialog-title"
                className="driver-modal">
                <DialogTitle>
                    New Vehicle
                    <IconButton aria-label="close" onClick={props.closeModal} style={{float:"right"}}>
                        <CloseIcon fontSize="large"	/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="mfp-with-anim mfp-driver-dialog" id="new-card-dialog">
                        <form className="cc-form" id="vehicle-add-form" onSubmit={props.onAddFormSubmit.bind(this,inputList)}>
                            <fieldset disabled={props.disableFields} >
                                <p><b>Basic Information</b></p>
                                <Divider />
                                <br />
                                <Grid container spacing={3} className={classes.newCardForm}>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Title"
                                            name="vName"
                                            type="text"
                                            value={currentName}
                                            onChange={handleNameChange}
                                            fullWidth
                                            required

                                         />
                                    </Grid>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Plate No."
                                            name="dPlate"
                                            type="text"
                                            value={currentPlate}
                                            onChange={handlePlateChange}
                                            fullWidth
                                            required
                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Brand"
                                            name="vBrand"
                                            type="text"
                                            value={currentBrand}
                                            onChange={handleBrandChange}
                                            fullWidth
                                            required

                                         />
                                    </Grid>
                                    <Grid item md={3} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Registration Year"
                                            name="vRYear"
                                            type="text"
                                            value={currentRegistrationYear}
                                            onChange={handleRegistrationYearChange}
                                            fullWidth
                                            required
                                         />
                                    </Grid>

                                    <Grid item md={3} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Model"
                                            name="vModel"
                                            type="text"
                                            value={currentModel}
                                            onChange={handleModelChange}
                                            fullWidth
                                            required
                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <Autocomplete
                                           freeSolo
                                           value={currentCategory}
                                           onChange={handleCategoryChange}
                                           options={categories.map((option) => option.title)}
                                           renderInput={(params) => (
                                             <TextField {...params} label="Category" name="vCategory" variant="outlined" required />
                                           )}
                                         />
                                    </Grid>

                                    <Grid item md={3} sm={12} xs={12}>
                                        <Autocomplete
                                           freeSolo
                                           value={currentType}
                                           onChange={handleTypeChange}
                                           options={types.map((option) => option.title)}
                                           renderInput={(params) => (
                                             <TextField {...params} label="Type" name="vType" variant="outlined" required />
                                           )}
                                         />
                                    </Grid>

                                    <Grid item md={3} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Colour"
                                            name="vColour"
                                            type="text"
                                            value={currentColour}
                                            onChange={handleColourChange}
                                            fullWidth
                                            required
                                         />
                                    </Grid>

                                    <Grid item md={12} sm={12} xs={12}>
                                        <div className="form-group-driver-name">
                                            <label>Description*</label>
                                            <textarea
                                            className="form-control"
                                            value={currentDesc}
                                            onChange={handleDescChange}
                                            name="vDesc"
                                             required />
                                        </div>
                                    </Grid>



                                </Grid>



                                <div className="clearfix">

                                </div>


                                <br />
                                <p><b>Rates</b></p>
                                <Divider />
                                <br />

                                <Grid container spacing={3} className={classes.newCardForm}>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Min Deposit Amount"
                                            name="vMindeposit1"
                                            type="number"
                                            onPaste={handlePasteChange}
                                            fullWidth
                                            value={currentMinDeposit1}
                                            onChange={handleMinDeposit1Change}

                                         />
                                    </Grid>

                                    <Grid item md={4} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Hourly"
                                            name="vHourly"
                                            type="number"
                                            onPaste={handlePasteChange}
                                            fullWidth
                                            value={currentHourly}
                                            onChange={handleHourlyChange}

                                         />
                                    </Grid>
                                    {currentRateBonus1 && (
                                        <Grid item md={2} sm={12} xs={12}>
                                            <FormControlLabel
                                              control={<Checkbox color="primary" name="hourlyBonus" onChange={handleChangeBonus} />}
                                              label="Bonus Free Rental"
                                            />
                                        </Grid>
                                    )}



                                    {currentRateBonus1 && (
                                        <Grid container spacing={3} xs={12} style={{border:"1px solid #ccc",margin:"5px",background:"#eee"}}>
                                            <Grid item md={6} sm={12} xs={12}>
                                                <TextField
                                                    label="How many Days/Months"
                                                    name="vBonusCount1"
                                                    type="number"
                                                    fullWidth
                                                    value={vBonusCount1}
                                                    style= {{fontSize: "15px"}}
                                                    onChange={handleChangeBonusCount}

                                                 />
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                 <FormControl component="fieldset">
                                                  <RadioGroup row aria-label="position" name="vBonusType1" value={vBonusType1} onChange={handleChangeBonusType}>
                                                      <FormControlLabel value="days" control={<Radio color="primary" />} label="Days" />
                                                      <FormControlLabel value="months" control={<Radio color="primary" />} label="Months" />
                                                  </RadioGroup>
                                                </FormControl>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      margin="normal"
                                                      label="Start Date"
                                                      format="MMM d, yyyy"
                                                      name="vBonusStart1"
                                                      value={vBonusStart1}
                                                      onChange={handleChangeBonusStart1}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                      inputProps={{style: {fontSize: "15px"}}}
                                                      InputLabelProps={{style: {fontSize: "15px"}}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      margin="normal"
                                                      label="End Date"
                                                      format="MMM d, yyyy"
                                                      name="vBonusEnd1"
                                                      value={vBonusEnd1}
                                                      onChange={handleChangeBonusEnd1}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                      inputProps={{style: {fontSize: "15px"}}}
                                                      InputLabelProps={{style: {fontSize: "15px"}}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </Grid>
                                    )}




                                    <Grid item md={3} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Min Deposit Amount"
                                            name="vMindeposit2"
                                            type="number"
                                            onPaste={handlePasteChange}
                                            fullWidth
                                            value={currentMinDeposit2}
                                            onChange={handleMinDeposit2Change}

                                         />
                                    </Grid>

                                    <Grid item md={3} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Min Days"
                                            name="vMindays"
                                            type="number"
                                            fullWidth
                                            InputProps={{
                                                inputProps: { min: 1 }
                                            }}
                                            value={currentMinDays}
                                            onChange={handleMinDaysChange}

                                         />
                                    </Grid>

                                    <Grid item md={4} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Daily"
                                            name="vDaily"
                                            type="number"
                                            onPaste={handlePasteChange}
                                            fullWidth
                                            value={currentDaily}
                                            onChange={handleDailyChange}

                                         />
                                    </Grid>

                                    {currentRateBonus1 && (
                                        <Grid item md={2} sm={12} xs={12}>
                                            <FormControlLabel
                                              control={<Checkbox color="primary" name="dailyBonus" onChange={handleChangeBonus} />}
                                              label="Bonus Free Rental"
                                            />
                                        </Grid>
                                    )}


                                    {currentRateBonus2 && (
                                        <Grid container spacing={3} xs={12} style={{border:"1px solid #ccc",margin:"5px",background:"#eee"}}>
                                            <Grid item md={6} sm={12} xs={12}>
                                                <TextField
                                                    label="How many Days/Months"
                                                    name="vBonusCount2"
                                                    type="number"
                                                    fullWidth
                                                    value={vBonusCount2}
                                                    style= {{fontSize: "15px"}}
                                                    onChange={handleChangeBonusCount}

                                                 />
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                 <FormControl component="fieldset">
                                                  <RadioGroup row aria-label="position" name="vBonusType2" value={vBonusType2} onChange={handleChangeBonusType}>
                                                      <FormControlLabel value="days" control={<Radio color="primary" />} label="Days" />
                                                      <FormControlLabel value="months" control={<Radio color="primary" />} label="Months" />
                                                  </RadioGroup>
                                                </FormControl>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      margin="normal"
                                                      label="Start Date"
                                                      format="MMM d, yyyy"
                                                      name="vBonusStart2"
                                                      value={vBonusStart2}
                                                      onChange={handleChangeBonusStart2}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                      inputProps={{style: {fontSize: "15px"}}}
                                                      InputLabelProps={{style: {fontSize: "15px"}}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      margin="normal"
                                                      label="End Date"
                                                      format="MMM d, yyyy"
                                                      name="vBonusEnd2"
                                                      value={vBonusEnd2}
                                                      onChange={handleChangeBonusEnd2}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                      inputProps={{style: {fontSize: "15px"}}}
                                                      InputLabelProps={{style: {fontSize: "15px"}}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Min Deposit Amount"
                                            name="vMindeposit3"
                                            type="number"
                                            onPaste={handlePasteChange}
                                            fullWidth
                                            value={currentMinDeposit3}
                                            onChange={handleMinDeposit3Change}

                                         />
                                    </Grid>

                                    <Grid item md={4} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Weekly"
                                            name="vWeekly"
                                            type="number"
                                            onPaste={handlePasteChange}
                                            fullWidth
                                            value={currentWeekly}
                                            onChange={handleWeeklyChange}

                                         />
                                    </Grid>


                                    {currentRateBonus1 && (
                                        <Grid item md={2} sm={12} xs={12}>
                                            <FormControlLabel
                                              control={<Checkbox color="primary" name="weeklyBonus" onChange={handleChangeBonus} />}
                                              label="Bonus Free Rental"
                                            />
                                        </Grid>
                                    )}

                                    {currentRateBonus3 && (
                                        <Grid container spacing={3} xs={12} style={{border:"1px solid #ccc",margin:"5px",background:"#eee"}}>
                                            <Grid item md={6} sm={12} xs={12}>
                                                <TextField
                                                    label="How many Days/Months"
                                                    name="vBonusCount3"
                                                    type="number"
                                                    fullWidth
                                                    value={vBonusCount3}
                                                    style= {{fontSize: "15px"}}
                                                    onChange={handleChangeBonusCount}

                                                 />
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                 <FormControl component="fieldset">
                                                  <RadioGroup row aria-label="position" name="vBonusType3" value={vBonusType3} onChange={handleChangeBonusType}>
                                                      <FormControlLabel value="days" control={<Radio color="primary" />} label="Days" />
                                                      <FormControlLabel value="months" control={<Radio color="primary" />} label="Months" />
                                                  </RadioGroup>
                                                </FormControl>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      margin="normal"
                                                      label="Start Date"
                                                      format="MMM d, yyyy"
                                                      name="vBonusStart3"
                                                      value={vBonusStart3}
                                                      onChange={handleChangeBonusStart3}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                      inputProps={{style: {fontSize: "15px"}}}
                                                      InputLabelProps={{style: {fontSize: "15px"}}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      margin="normal"
                                                      label="End Date"
                                                      format="MMM d, yyyy"
                                                      name="vBonusEnd3"
                                                      value={vBonusEnd3}
                                                      onChange={handleChangeBonusEnd3}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                      inputProps={{style: {fontSize: "15px"}}}
                                                      InputLabelProps={{style: {fontSize: "15px"}}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Min Deposit Amount"
                                            name="vMindeposit4"
                                            type="number"
                                            onPaste={handlePasteChange}
                                            fullWidth
                                            value={currentMinDeposit4}
                                            onChange={handleMinDeposit4Change}

                                         />
                                    </Grid>


                                    <Grid item md={4} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Weekend"
                                            name="vWeekend"
                                            type="number"
                                            onPaste={handlePasteChange}
                                            fullWidth
                                            value={currentWeekend}
                                            onChange={handleWeekendChange}

                                         />
                                    </Grid>



                                    {currentRateBonus1 && (
                                        <Grid item md={2} sm={12} xs={12}>
                                            <FormControlLabel
                                              control={<Checkbox color="primary" name="weekendBonus" onChange={handleChangeBonus} />}
                                              label="Bonus Free Rental"
                                            />
                                        </Grid>
                                    )}

                                    {currentRateBonus4 && (
                                        <Grid container spacing={3} xs={12} style={{border:"1px solid #ccc",margin:"5px",background:"#eee"}}>
                                            <Grid item md={6} sm={12} xs={12}>
                                                <TextField
                                                    label="How many Days/Months"
                                                    name="vBonusCount4"
                                                    type="number"
                                                    fullWidth
                                                    value={vBonusCount4}
                                                    style= {{fontSize: "15px"}}
                                                    onChange={handleChangeBonusCount}

                                                 />
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                 <FormControl component="fieldset">
                                                  <RadioGroup row aria-label="position" name="vBonusType4" value={vBonusType4} onChange={handleChangeBonusType}>
                                                      <FormControlLabel value="days" control={<Radio color="primary" />} label="Days" />
                                                      <FormControlLabel value="months" control={<Radio color="primary" />} label="Months" />
                                                  </RadioGroup>
                                                </FormControl>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      margin="normal"
                                                      label="Start Date"
                                                      format="MMM d, yyyy"
                                                      name="vBonusStart4"
                                                      value={vBonusStart4}
                                                      onChange={handleChangeBonusStart4}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                      inputProps={{style: {fontSize: "15px"}}}
                                                      InputLabelProps={{style: {fontSize: "15px"}}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      margin="normal"
                                                      label="End Date"
                                                      format="MMM d, yyyy"
                                                      name="vBonusEnd4"
                                                      value={vBonusEnd4}
                                                      onChange={handleChangeBonusEnd4}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                      inputProps={{style: {fontSize: "15px"}}}
                                                      InputLabelProps={{style: {fontSize: "15px"}}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Min Deposit Amount"
                                            name="vMindeposit5"
                                            type="number"
                                            onPaste={handlePasteChange}
                                            fullWidth
                                            value={currentMinDeposit5}
                                            onChange={handleMinDeposit5Change}

                                         />
                                    </Grid>

                                    <Grid item md={4} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Monthly"
                                            name="vMonthly"
                                            type="number"
                                            onPaste={handlePasteChange}
                                            fullWidth
                                            value={currentMonthly}
                                            onChange={handleMonthlyChange}

                                         />
                                    </Grid>

                                    <Grid item md={2} sm={12} xs={12}>
                                        <FormControlLabel
                                          control={<Checkbox color="primary" name="monthlyBonus" onChange={handleChangeBonus} />}
                                          label="Bonus Free Rental"
                                        />
                                    </Grid>

                                    {currentRateBonus5 && (
                                        <Grid item container spacing={3} style={{border:"1px solid #ccc",margin:"5px",background:"#eee"}}>
                                            <Grid item md={6} sm={12} xs={12}>
                                                <TextField
                                                    label="How many Days/Months"
                                                    name="vBonusCount5"
                                                    type="number"
                                                    fullWidth
                                                    value={vBonusCount5}
                                                    style= {{fontSize: "15px"}}
                                                    onChange={handleChangeBonusCount}

                                                 />
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                 <FormControl component="fieldset">
                                                  <RadioGroup row aria-label="position" name="vBonusType5" value={vBonusType5} onChange={handleChangeBonusType}>
                                                      <FormControlLabel value="days" control={<Radio color="primary" />} label="Days" />
                                                      <FormControlLabel value="months" control={<Radio color="primary" />} label="Months" />
                                                  </RadioGroup>
                                                </FormControl>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      margin="normal"
                                                      label="Start Date"
                                                      format="MMM d, yyyy"
                                                      name="vBonusStart5"
                                                      value={vBonusStart5}
                                                      onChange={handleChangeBonusStart5}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                      inputProps={{style: {fontSize: "15px"}}}
                                                      InputLabelProps={{style: {fontSize: "15px"}}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      margin="normal"
                                                      label="End Date"
                                                      format="MMM d, yyyy"
                                                      name="vBonusEnd5"
                                                      value={vBonusEnd5}
                                                      onChange={handleChangeBonusEnd5}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                      inputProps={{style: {fontSize: "15px"}}}
                                                      InputLabelProps={{style: {fontSize: "15px"}}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Min Deposit Amount"
                                            name="vMindeposit6"
                                            type="number"
                                            onPaste={handlePasteChange}
                                            fullWidth
                                            value={currentMinDeposit6}
                                            onChange={handleMinDeposit6Change}

                                         />
                                    </Grid>


                                    <Grid item md={4} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Bi-Annually"
                                            name="vBiannually"
                                            type="number"
                                            onPaste={handlePasteChange}
                                            fullWidth
                                            value={currentBiAnnually}
                                            onChange={handleBiAnnuallyChange}

                                         />
                                    </Grid>



                                    {currentRateBonus1 && (
                                        <Grid item md={2} sm={12} xs={12}>
                                            <FormControlLabel
                                              control={<Checkbox color="primary" name="biannuallyBonus" onChange={handleChangeBonus} />}
                                              label="Bonus Free Rental"
                                            />
                                        </Grid>
                                    )}


                                    {currentRateBonus6 && (
                                        <Grid container spacing={3} xs={12} style={{border:"1px solid #ccc",margin:"5px",background:"#eee"}}>
                                            <Grid item md={6} sm={12} xs={12}>
                                                <TextField
                                                    label="How many Days/Months"
                                                    name="vBonusCount6"
                                                    type="number"
                                                    fullWidth
                                                    value={vBonusCount6}
                                                    style= {{fontSize: "15px"}}
                                                    onChange={handleChangeBonusCount}

                                                 />
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                 <FormControl component="fieldset">
                                                  <RadioGroup row aria-label="position" name="vBonusType6" value={vBonusType6} onChange={handleChangeBonusType}>
                                                      <FormControlLabel value="days" control={<Radio color="primary" />} label="Days" />
                                                      <FormControlLabel value="months" control={<Radio color="primary" />} label="Months" />
                                                  </RadioGroup>
                                                </FormControl>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      margin="normal"
                                                      label="Start Date"
                                                      format="MMM d, yyyy"
                                                      name="vBonusStart6"
                                                      value={vBonusStart6}
                                                      onChange={handleChangeBonusStart6}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                      inputProps={{style: {fontSize: "15px"}}}
                                                      InputLabelProps={{style: {fontSize: "15px"}}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      margin="normal"
                                                      label="End Date"
                                                      format="MMM d, yyyy"
                                                      name="vBonusEnd6"
                                                      value={vBonusEnd6}
                                                      onChange={handleChangeBonusEnd6}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                      inputProps={{style: {fontSize: "15px"}}}
                                                      InputLabelProps={{style: {fontSize: "15px"}}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Min Deposit Amount"
                                            name="vMindeposit7"
                                            type="number"
                                            onPaste={handlePasteChange}
                                            fullWidth
                                            value={currentMinDeposit7}
                                            onChange={handleMinDeposit7Change}

                                         />
                                    </Grid>




                                    <Grid item md={4} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="1-Yr Lease"
                                            name="vYearly"
                                            type="number"
                                            onPaste={handlePasteChange}
                                            fullWidth
                                            value={currentYearly}
                                            onChange={handleYearlyChange}

                                         />
                                    </Grid>

                                    <Grid item md={2} sm={12} xs={12}>
                                        <FormControlLabel
                                          control={<Checkbox color="primary" name="oneYearBonus" onChange={handleChangeBonus} />}
                                          label="Bonus Free Rental"
                                        />
                                    </Grid>

                                    {currentRateBonus7 && (
                                        <Grid item container spacing={3} style={{border:"1px solid #ccc",margin:"5px",background:"#eee"}}>
                                            <Grid item md={6} sm={12} xs={12}>
                                                <TextField
                                                    label="How many Days/Months"
                                                    name="vBonusCount7"
                                                    type="number"
                                                    fullWidth
                                                    value={vBonusCount7}
                                                    style= {{fontSize: "15px"}}
                                                    onChange={handleChangeBonusCount}

                                                 />
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                 <FormControl component="fieldset">
                                                  <RadioGroup row aria-label="position" name="vBonusType7" value={vBonusType7} onChange={handleChangeBonusType}>
                                                      <FormControlLabel value="days" control={<Radio color="primary" />} label="Days" />
                                                      <FormControlLabel value="months" control={<Radio color="primary" />} label="Months" />
                                                  </RadioGroup>
                                                </FormControl>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      margin="normal"
                                                      label="Start Date"
                                                      format="MMM d, yyyy"
                                                      name="vBonusStart7"
                                                      value={vBonusStart7}
                                                      onChange={handleChangeBonusStart7}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                      inputProps={{style: {fontSize: "15px"}}}
                                                      InputLabelProps={{style: {fontSize: "15px"}}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      margin="normal"
                                                      label="End Date"
                                                      format="MMM d, yyyy"
                                                      name="vBonusEnd7"
                                                      value={vBonusEnd7}
                                                      onChange={handleChangeBonusEnd7}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                      inputProps={{style: {fontSize: "15px"}}}
                                                      InputLabelProps={{style: {fontSize: "15px"}}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Min Deposit Amount"
                                            name="vMindeposit8"
                                            type="number"
                                            onPaste={handlePasteChange}
                                            fullWidth
                                            value={currentMinDeposit8}
                                            onChange={handleMinDeposit8Change}

                                         />
                                    </Grid>


                                    <Grid item md={4} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="2-Yr Lease"
                                            name="v2yr"
                                            type="number"
                                            onPaste={handlePasteChange}
                                            fullWidth
                                            value={current2Yr}
                                            onChange={handle2YrChange}

                                         />
                                    </Grid>

                                    <Grid item md={2} sm={12} xs={12}>
                                        <FormControlLabel
                                          control={<Checkbox color="primary" name="twoYearsBonus" onChange={handleChangeBonus} />}
                                          label="Bonus Free Rental"
                                        />
                                    </Grid>

                                    {currentRateBonus8 && (
                                        <Grid item container spacing={3} style={{border:"1px solid #ccc",margin:"5px",background:"#eee"}}>
                                            <Grid item md={6} sm={12} xs={12}>
                                                <TextField
                                                    label="How many Days/Months"
                                                    name="vBonusCount8"
                                                    type="number"
                                                    fullWidth
                                                    value={vBonusCount8}
                                                    style= {{fontSize: "15px"}}
                                                    onChange={handleChangeBonusCount}

                                                 />
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                 <FormControl component="fieldset">
                                                  <RadioGroup row aria-label="position" name="vBonusType8" value={vBonusType8} onChange={handleChangeBonusType}>
                                                      <FormControlLabel value="days" control={<Radio color="primary" />} label="Days" />
                                                      <FormControlLabel value="months" control={<Radio color="primary" />} label="Months" />
                                                  </RadioGroup>
                                                </FormControl>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      margin="normal"
                                                      label="Start Date"
                                                      format="MMM d, yyyy"
                                                      name="vBonusStart8"
                                                      value={vBonusStart8}
                                                      onChange={handleChangeBonusStart8}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                      inputProps={{style: {fontSize: "15px"}}}
                                                      InputLabelProps={{style: {fontSize: "15px"}}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      margin="normal"
                                                      label="End Date"
                                                      format="MMM d, yyyy"
                                                      name="vBonusEnd8"
                                                      value={vBonusEnd8}
                                                      onChange={handleChangeBonusEnd8}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                      inputProps={{style: {fontSize: "15px"}}}
                                                      InputLabelProps={{style: {fontSize: "15px"}}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </Grid>
                                    )}

                                </Grid>

                                <br />
                                <p><b>Others</b></p>
                                <Divider />
                                <br />

                                <Grid container spacing={3} className={classes.newCardForm}>
                                    <Grid item md={6} sm={12} xs={12}>
                                         <Autocomplete
                                            freeSolo
                                            value={currentTransmission}
                                            onChange={handleTransmissionChange}
                                            options={transmissions.map((option) => option.title)}
                                            renderInput={(params) => (
                                              <TextField {...params} label="Transmission" name="vTransmission" variant="outlined" required />
                                            )}
                                          />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                         <TextField
                                             variant="outlined"
                                             label="Engine"
                                             name="vEngine"
                                             value={currentEngine}
                                             onChange={handleEngineChange}
                                             type="text"
                                             fullWidth
                                             required
                                          />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <Autocomplete
                                           freeSolo
                                           value={currentGas}
                                           onChange={handleGasChange}
                                           options={gases.map((option) => option.title)}
                                           renderInput={(params) => (
                                             <TextField {...params} label="Gas" name="vGas" variant="outlined" required />
                                           )}
                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <Autocomplete
                                           freeSolo
                                           value={currentTerm}
                                           onChange={handleTermChange}
                                           options={terms.map((option) => option.title)}
                                           renderInput={(params) => (
                                             <TextField {...params} label="Terms" name="vTerm" variant="outlined" required  />
                                           )}
                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Passengers"
                                            name="vPassengers"
                                            value={currentPassenger}
                                            onChange={handlePassengerChange}
                                            type="number"
                                            fullWidth
                                            required
                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Mileage"
                                            name="vMileage"
                                            value={currentMileage}
                                            onChange={handleMileageChange}
                                            type="number"
                                            fullWidth

                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Doors"
                                            name="vDoors"
                                            value={currentDoors}
                                            onChange={handleDoorsChange}
                                            type="number"
                                            fullWidth
                                            required
                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Baggage"
                                            name="vBaggage"
                                            value={currentBaggage}
                                            onChange={handleBaggageChange}
                                            type="number"
                                            fullWidth

                                         />
                                    </Grid>

                                </Grid>

                                <br />
                                <p><b>Add Ons</b></p>
                                <Divider />
                                <br />
                                <div className="clearfix">
                                    {inputList.map((x, i) => {
                                      return (
                                        <div key={"add-addons-"+i}>
                                          <div className="form-group form-group-driver-name">
                                              <TextField
                                                 label="Title"
                                                 value={x.title}
                                                 name="title"
                                                 fullWidth={true}
                                                 onChange={e => handleAddOnChange(e, i)}
                                               />
                                           </div>
                                           <div className="form-group">
                                               <TextField
                                                  label="Price"
                                                  name="price"
                                                  value={x.price}
                                                  name="price"
                                                  fullWidth={true}
                                                  onChange={e => handleAddOnChange(e, i)}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextField
                                                   label="Max Qty"
                                                   value={x.ve_max_qty}
                                                   name="ve_max_qty"
                                                   type="number"
                                                   InputProps={{
                                                       inputProps: { min: 0 }
                                                   }}
                                                   fullWidth={true}
                                                   onChange={e => handleAddOnChange(e, i)}
                                                 />
                                             </div>
                                            <div className="form-group">
                                                <div className="btn-box">
                                                    {inputList.length !== 1 &&
                                                        <BackspaceRoundedIcon onClick={() => handleRemoveClick(i)} style={{fill: "red",fontSize:"30px",marginTop:"10px"}} alt="remove row" title="remove row" />
                                                    }
                                                    {inputList.length - 1 === i &&
                                                        <AddBoxRoundedIcon onClick={handleAddClick} style={{fill: "green",fontSize:"30px",marginTop:"10px"}} alt="add row"  title="add row"  />}
                                                </div>
                                             </div>
                                            <br />

                                        </div>
                                      );
                                    })}
                                </div>

                                <Grid container spacing={3} className={classes.newCardForm}>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <p><b>Images</b></p>
                                        <Divider />
                                        <p><i>Note: Image should be 1800x900 dimension</i></p>
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={6} >
                                        <div className="clearfix">
                                            <div className="">
                                                <input id="vimgAdd0" className="form-control" name={"vimages0"} placeholder="" type="file" onChange={handleChangeImage.bind(this,0)} />
                                                <CardMedia
                                                  className={classes.media}
                                                  image={currentImages0 || piImages[0]}
                                                />
                                                <ButtonGroup size="small" fullWidth>
                                                    <Button onClick={handleResetFile.bind(this,0)}>Reset</Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={6} >
                                        <div className="clearfix">
                                            <div className="">
                                                <input id="vimgAdd1" className="form-control" name={"vimages1"} placeholder="" type="file" onChange={handleChangeImage.bind(this,1)} />
                                                <CardMedia
                                                  className={classes.media}
                                                  image={currentImages1 || piImages[1]}
                                                />
                                                <ButtonGroup size="small" fullWidth>
                                                    <Button onClick={handleResetFile.bind(this,1)}>Reset</Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={6} >
                                        <div className="clearfix">
                                            <div className="">
                                                <input id="vimgAdd2" className="form-control" name={"vimages2"} placeholder="" type="file" onChange={handleChangeImage.bind(this,2)} />
                                                <CardMedia
                                                  className={classes.media}
                                                  image={currentImages2 || piImages[2]}
                                                />
                                                <ButtonGroup size="small" fullWidth>
                                                    <Button onClick={handleResetFile.bind(this,2)}>Reset</Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={6} >
                                        <div className="clearfix">
                                            <div className="">
                                                <input id="vimgAdd3" className="form-control" name={"vimages3"} placeholder="" type="file" onChange={handleChangeImage.bind(this,3)} />
                                                <CardMedia
                                                  className={classes.media}
                                                  image={currentImages3 || piImages[3]}
                                                />
                                                <ButtonGroup size="small" fullWidth>
                                                    <Button onClick={handleResetFile.bind(this,3)}>Reset</Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={6} key={"update-vimages-"+i}>
                                        <div className="clearfix">
                                            <div className="">
                                                <input id="vimgAdd4" className="form-control" name={"vimages4"} placeholder="" type="file" onChange={handleChangeImage.bind(this,4)} />
                                                <CardMedia
                                                  className={classes.media}
                                                  image={currentImages4 || piImages[4]}
                                                />
                                                <ButtonGroup size="small" fullWidth>
                                                    <Button onClick={handleResetFile.bind(this,4)}>Reset</Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={6}>
                                        <div className="clearfix">
                                            <div className="">
                                                <input id="vimgAdd5" className="form-control" name={"vimages5"} placeholder="" type="file" onChange={handleChangeImage.bind(this,5)} />
                                                <CardMedia
                                                  className={classes.media}
                                                  image={currentImages5 || piImages[5]}
                                                />
                                                <ButtonGroup size="small" fullWidth>
                                                    <Button onClick={handleResetFile.bind(this,5)}>Reset</Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={6}>
                                        <div className="clearfix">
                                            <div className="">
                                                <input id="vimgAdd6" className="form-control" name={"vimages6"} placeholder="" type="file" onChange={handleChangeImage.bind(this,6)} />
                                                <CardMedia
                                                  className={classes.media}
                                                  image={currentImages6 || piImages[6]}
                                                />
                                                <ButtonGroup size="small" fullWidth>
                                                    <Button onClick={handleResetFile.bind(this,6)}>Reset</Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={6}>
                                        <div className="clearfix">
                                            <div className="">
                                                <input id="vimgAdd7" className="form-control" name={"vimages7"} placeholder="" type="file" onChange={handleChangeImage.bind(this,7)} />
                                                <CardMedia
                                                  className={classes.media}
                                                  image={currentImages7 || piImages[7]}
                                                />
                                                <ButtonGroup size="small" fullWidth>
                                                    <Button onClick={handleResetFile.bind(this,7)}>Reset</Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={6}>
                                        <div className="clearfix">
                                            <div className="">
                                                <input id="vimgAdd8" className="form-control" name={"vimages8"} placeholder="" type="file" onChange={handleChangeImage.bind(this,8)} />
                                                <CardMedia
                                                  className={classes.media}
                                                  image={currentImages8 || piImages[8]}
                                                />
                                                <ButtonGroup size="small" fullWidth>
                                                    <Button onClick={handleResetFile.bind(this,8)}>Reset</Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Grid>





                                    <br />
                                </Grid>

                                <br />
                                <p><b>Leasing Requirements</b></p>
                                <Divider />
                                <br />
                                <Grid container spacing={3} className={classes.newCardForm}>
                                    <Grid item md={6} sm={12} xs={12}>
                                          <TextField
                                              variant="outlined"
                                              label="Min Age"
                                              name="vMinAge"
                                              type="number"
                                              fullWidth
                                              required
                                           />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                          <TextField
                                              variant="outlined"
                                              label="Min Experience"
                                              name="vMinExp"
                                              type="number"
                                              fullWidth
                                              required
                                           />
                                    </Grid>
                                </Grid>

                                <br />
                                <p><b>Insurance Excess</b></p>
                                <Divider />
                                <br />
                                <Grid container spacing={3} className={classes.newCardForm}>
                                  <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                      defaultValue={2000}
                                      variant="outlined"
                                      label="Insurance Excess: Age between 24 and 60"
                                      name="insuranceExcess1"
                                      type="number"
                                      onChange={handleInsuranceExcess1Change}
                                      fullWidth
                                      required
                                    />
                                  </Grid>
                                  <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                      defaultValue={4000}
                                      variant="outlined"
                                      label="Insurance Excess: Age < 24 or Age > 60"
                                      name="insuranceExcess2"
                                      type="number"
                                      onChange={handleInsuranceExcess2Change}
                                      fullWidth
                                      required
                                    />
                                  </Grid>
                                  <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                      defaultValue={3000}
                                      variant="outlined"
                                      label="Insurance Excess: Own Damage"
                                      name="insuranceExcess3"
                                      type="number"
                                      onChange={handleInsuranceExcess3Change}
                                      fullWidth
                                      required
                                    />
                                  </Grid>
                                </Grid>

                                <br />
                                <p><b>Instruction</b></p>
                                <Divider />
                                <br />
                                <div className="clearfix">
                                    <p><i>Note: this instruction will be shown to lessee for further instruction</i></p>
                                    <div className="form-group-driver-name">
                                        <textarea
                                        className="form-control"
                                        name="vInstruction"
                                         required />
                                    </div>
                                </div>

                                <br />
                                {showPreview && (
                                  <>
                                      <p><b>Preview</b></p>
                                      <Divider />
                                      <ProductPreview details={details} />
                                  </>
                                )}

                                <Divider />
                                <br />
                                <div className="clearfix">
                                    <Button id="addVehicleBtn" type="submit" variant="contained" className="pull-right" size="large" color="primary">
                                      Save this vehicle
                                    </Button>

                                    <Button onClick={togglePreview}  variant="contained" className="pull-left" size="large" color="default">
                                      Toggle Preview
                                    </Button>
                                </div>
                            </fieldset>


                        </form>

                    </div>

                </DialogContent>

            </Dialog>
          );
    }

    function ModalEdit(props){
        const classes = useStyles();
        const [isOpen, setIsOpen] = useState(false);
        const [selectedDate, setSelectedDate] = React.useState(new Date())

        const [currentName, setCurrentName] = useState(props.currentVehicleData.product_name);
        const [currentPlate, setCurrentPlate] = useState(props.currentVehicleData.product_platenumber);
        const [currentModel, setCurrentModel] = useState(props.currentVehicleData.product_model);
        const [currentColour, setCurrentColour] = useState(props.currentVehicleData.product_colour);
        const [currentBrand, setCurrentBrand] = useState(props.currentVehicleData.product_brand);
        const [currentCategory, setCurrentCategory] = useState(props.currentVehicleData.category_name);
        const [currentType, setCurrentType] = useState(props.currentVehicleData.product_type);
        const [currentReview, setCurrentReview] = useState(props.currentVehicleData.product_review_rate);

        const [currentMinDeposit, setCurrentMinDeposit] = useState(props.currentVehicleData.product_min_deposit_amount);
        const [currentHourly, setCurrentHourly] = useState(props.currentVehicleData.product_price_hour);
        const [currentDaily, setCurrentDaily] = useState(props.currentVehicleData.product_price_day);
        const [currentWeekly, setCurrentWeekly] = useState(props.currentVehicleData.product_price_week);
        const [currentWeekend, setCurrentWeekend] = useState(props.currentVehicleData.product_price_weekend);
        const [currentMonthly, setCurrentMonthly] = useState(props.currentVehicleData.product_price_month);
        const [currentBiAnnually, setCurrentBiAnnually] = useState(props.currentVehicleData.product_price_biannually);
        const [currentYearly, setCurrentYearly] = useState(props.currentVehicleData.product_price_year);
        const [current2Yr, setCurrent2Yr] = useState(props.currentVehicleData.product_price_year_2);

        const [currentMinDeposit1, setCurrentMinDeposit1] = useState(props.currentVehicleData.product_price_hour_min_deposit);
        const [currentMinDeposit2, setCurrentMinDeposit2] = useState(props.currentVehicleData.product_price_day_min_deposit);
        const [currentMinDeposit3, setCurrentMinDeposit3] = useState(props.currentVehicleData.product_price_week_min_deposit);
        const [currentMinDeposit4, setCurrentMinDeposit4] = useState(props.currentVehicleData.product_price_weekend_min_deposit);
        const [currentMinDeposit5, setCurrentMinDeposit5] = useState(props.currentVehicleData.product_price_month_min_deposit);
        const [currentMinDeposit6, setCurrentMinDeposit6] = useState(props.currentVehicleData.product_price_biannually_min_deposit);
        const [currentMinDeposit7, setCurrentMinDeposit7] = useState(props.currentVehicleData.product_price_year_min_deposit);
        const [currentMinDeposit8, setCurrentMinDeposit8] = useState(props.currentVehicleData.product_price_year_2_min_deposit);


        const [currentTransmission, setCurrentTransmission] = useState(props.currentVehicleData.product_transmission);
        const [currentEngine, setCurrentEngine] = useState(props.currentVehicleData.product_engine);
        const [currentGas, setCurrentGas] = useState(props.currentVehicleData.product_gas);
        const [currentTerm, setCurrentTerm] = useState(props.currentVehicleData.product_rent_terms);
        const [currentPassenger, setCurrentPassenger] = useState(props.currentVehicleData.product_passengers);
        const [currentMileage, setCurrentMileage] = useState(props.currentVehicleData.product_mileage);
        const [currentDoors, setCurrentDoors] = useState(props.currentVehicleData.product_doors);
        const [currentBaggage, setCurrentBaggage] = useState(props.currentVehicleData.product_baggage);
        const [currentDesc, setCurrentDesc] = useState(props.currentVehicleData.product_description);
        const [currentInstruction, setCurrentInstruction] = useState(props.currentVehicleData.product_instruction);

        const [currentMinAge, setCurrentMinAge] = useState(props.currentVehicleData.product_min_age);
        const [currentMinExp, setCurrentMinExp] = useState(props.currentVehicleData.product_experience);
        const [currentRegistrationYear, setCurrentRegistrationYear] = useState(props.currentVehicleData.product_registration_year);

        const [currentMinDays, setCurrentMinDays] = useState(props.currentVehicleData.product_min_days);

        const [currentImages0, setCurrentImages0] = useState(false);
        const [currentImages1, setCurrentImages1] = useState(false);
        const [currentImages2, setCurrentImages2] = useState(false);
        const [currentImages3, setCurrentImages3] = useState(false);
        const [currentImages4, setCurrentImages4] = useState(false);
        const [currentImages5, setCurrentImages5] = useState(false);
        const [currentImages6, setCurrentImages6] = useState(false);
        const [currentImages7, setCurrentImages7] = useState(false);
        const [currentImages8, setCurrentImages8] = useState(false);
        const [currentRemove, setCurrentRemove] = useState([
            0,0,0,0,0,0,0,0,0
        ]);
        const [currentRemove1, setCurrentRemove1] = useState(0);
        const [currentRemove2, setCurrentRemove2] = useState(0);
        const [currentRemove3, setCurrentRemove3] = useState(0);
        const [currentRemove4, setCurrentRemove4] = useState(0);
        const [currentRemove5, setCurrentRemove5] = useState(0);
        const [currentRemove6, setCurrentRemove6] = useState(0);
        const [currentRemove7, setCurrentRemove7] = useState(0);
        const [currentRemove8, setCurrentRemove8] = useState(0);

        const [showPreview, setShowPreview] = useState(false);

        const [currentInsuranceExcess1, setcurrentInsuranceExcess1] = useState(props.currentVehicleData.product_insurance_excess_1);
        const [currentInsuranceExcess2, setcurrentInsuranceExcess2] = useState(props.currentVehicleData.product_insurance_excess_2);
        const [currentInsuranceExcess3, setcurrentInsuranceExcess3] = useState(props.currentVehicleData.product_insurance_excess_3);

        const [currentRateBonus1, setCurrentRateBonus1] = useState(false);
        const [currentRateBonus2, setCurrentRateBonus2] = useState(false);
        const [currentRateBonus3, setCurrentRateBonus3] = useState(false);
        const [currentRateBonus4, setCurrentRateBonus4] = useState(false);
        const [currentRateBonus5, setCurrentRateBonus5] = useState(props.currentVehicleData.product_month_bonus_rental_fee == "0" ? false : true);
        const [currentRateBonus6, setCurrentRateBonus6] = useState(false);
        const [currentRateBonus7, setCurrentRateBonus7] = useState(props.currentVehicleData.product_year_bonus_rental_fee == "0" ? false : true);
        const [currentRateBonus8, setCurrentRateBonus8] = useState(props.currentVehicleData.product_year2_bonus_rental_fee == "0" ? false : true);

        const [vBonusCount1, setvBonusCount1] = useState(0);
        const [vBonusCount2, setvBonusCount2] = useState(0);
        const [vBonusCount3, setvBonusCount3] = useState(0);
        const [vBonusCount4, setvBonusCount4] = useState(0);
        const [vBonusCount5, setvBonusCount5] = useState(props.currentVehicleData.product_month_bonus_days_months_count);
        const [vBonusCount6, setvBonusCount6] = useState(0);
        const [vBonusCount7, setvBonusCount7] = useState(props.currentVehicleData.product_year_bonus_days_months_count);
        const [vBonusCount8, setvBonusCount8] = useState(props.currentVehicleData.product_year2_bonus_days_months_count);

        const [vBonusType1, setVBonusType1] = useState("months");
        const [vBonusType2, setVBonusType2] = useState("months");
        const [vBonusType3, setVBonusType3] = useState("months");
        const [vBonusType4, setVBonusType4] = useState("months");
        const [vBonusType5, setVBonusType5] = useState(props.currentVehicleData.product_month_bonus_type == "" ? "months" : props.currentVehicleData.product_month_bonus_type);
        const [vBonusType6, setVBonusType6] = useState("months");
        const [vBonusType7, setVBonusType7] = useState(props.currentVehicleData.product_year_bonus_type == "" ? "months" : props.currentVehicleData.product_year_bonus_type);
        const [vBonusType8, setVBonusType8] = useState(props.currentVehicleData.product_year2_bonus_type == "" ? "months" : props.currentVehicleData.product_year2_bonus_type);

        const [vBonusStart1, setVBonusStart1] = useState(null);
        const [vBonusStart2, setVBonusStart2] = useState(null);
        const [vBonusStart3, setVBonusStart3] = useState(null);
        const [vBonusStart4, setVBonusStart4] = useState(null);
        const [vBonusStart5, setVBonusStart5] = useState(props.currentVehicleData.product_month_bonus_start_date);
        const [vBonusStart6, setVBonusStart6] = useState(null);
        const [vBonusStart7, setVBonusStart7] = useState(props.currentVehicleData.product_year_bonus_start_date);
        const [vBonusStart8, setVBonusStart8] = useState(props.currentVehicleData.product_year2_bonus_start_date);

        const [vBonusEnd1, setVBonusEnd1] = useState(null);
        const [vBonusEnd2, setVBonusEnd2] = useState(null);
        const [vBonusEnd3, setVBonusEnd3] = useState(null);
        const [vBonusEnd4, setVBonusEnd4] = useState(null);
        const [vBonusEnd5, setVBonusEnd5] = useState(props.currentVehicleData.product_month_bonus_end_date);
        const [vBonusEnd6, setVBonusEnd6] = useState(null);
        const [vBonusEnd7, setVBonusEnd7] = useState(props.currentVehicleData.product_year_bonus_end_date);
        const [vBonusEnd8, setVBonusEnd8] = useState(props.currentVehicleData.product_year2_bonus_end_date);

        const handleChangeBonusCount = (event) => {

            if(event.target.name == "vBonusCount1"){
                setvBonusCount1(event.target.value);
            }else if(event.target.name == "vBonusCount2"){
                setvBonusCount2(event.target.value);
            }else if(event.target.name == "vBonusCount3"){
                setvBonusCount3(event.target.value);
            }else if(event.target.name == "vBonusCount4"){
                setvBonusCount4(event.target.value);
            }else if(event.target.name == "vBonusCount5"){
                setvBonusCount5(event.target.value);
            }else if(event.target.name == "vBonusCount6"){
                setvBonusCount6(event.target.value);
            }else if(event.target.name == "vBonusCount7"){
                setvBonusCount7(event.target.value);
            }else if(event.target.name == "vBonusCount8"){
                setvBonusCount8(event.target.value);
            }

        };

        const handleChangeBonusType = (event) => {

            if(event.target.name == "vBonusType1"){
                setVBonusType1(event.target.value);
            }else if(event.target.name == "vBonusType2"){
                setVBonusType2(event.target.value);
            }else if(event.target.name == "vBonusType3"){
                setVBonusType3(event.target.value);
            }else if(event.target.name == "vBonusType4"){
                setVBonusType4(event.target.value);
            }else if(event.target.name == "vBonusType5"){
                setVBonusType5(event.target.value);
            }else if(event.target.name == "vBonusType6"){
                setVBonusType6(event.target.value);
            }else if(event.target.name == "vBonusType7"){
                setVBonusType7(event.target.value);
            }else if(event.target.name == "vBonusType8"){
                setVBonusType8(event.target.value);
            }

        };

        const handleChangeBonusStart1 = (date) => {
            setVBonusStart1(date);
        };
        const handleChangeBonusStart2 = (date) => {
            setVBonusStart2(date);
        };
        const handleChangeBonusStart3 = (date) => {
            setVBonusStart3(date);
        };
        const handleChangeBonusStart4 = (date) => {
            setVBonusStart4(date);
        };
        const handleChangeBonusStart5 = (date) => {
            setVBonusStart5(date);
        };
        const handleChangeBonusStart6 = (date) => {
            setVBonusStart6(date);
        };
        const handleChangeBonusStart7 = (date) => {
            setVBonusStart7(date);
        };
        const handleChangeBonusStart8 = (date) => {
            setVBonusStart8(date);
        };

        const handleChangeBonusEnd1 = (date) => {
            setVBonusEnd1(date);
        };
        const handleChangeBonusEnd2 = (date) => {
            setVBonusEnd2(date);
        };
        const handleChangeBonusEnd3 = (date) => {
            setVBonusEnd3(date);
        };
        const handleChangeBonusEnd4 = (date) => {
            setVBonusEnd4(date);
        };
        const handleChangeBonusEnd5 = (date) => {
            setVBonusEnd5(date);
        };
        const handleChangeBonusEnd6 = (date) => {
            setVBonusEnd6(date);
        };
        const handleChangeBonusEnd7 = (date) => {
            setVBonusEnd7(date);
        };
        const handleChangeBonusEnd8 = (date) => {
            setVBonusEnd8(date);
        };



         const handleChangeBonus = (event) => {

             if(event.target.name == "hourlyBonus"){
                 setCurrentRateBonus1(!currentRateBonus1);
             }else if(event.target.name == "dailyBonus"){
                 setCurrentRateBonus2(!currentRateBonus2);
             }else if(event.target.name == "weeklyBonus"){
                 setCurrentRateBonus3(!currentRateBonus3);
             }else if(event.target.name == "weekendBonus"){
                 setCurrentRateBonus4(!currentRateBonus4);
             }else if(event.target.name == "monthlyBonus"){
                 setCurrentRateBonus5(!currentRateBonus5);
             }else if(event.target.name == "biannuallyBonus"){
                 setCurrentRateBonus6(!currentRateBonus6);
             }else if(event.target.name == "oneYearBonus"){
                 setCurrentRateBonus7(!currentRateBonus7);
             }else if(event.target.name == "twoYearsBonus"){
                 setCurrentRateBonus8(!currentRateBonus8);
             }

         };

        var noImagaAvail = noImage;

        const togglePreview = () => {
           setShowPreview(!showPreview);
         };

        const handleDateChange = (date) => {
           setSelectedDate(date);
           setIsOpen(false)
         };

        const handleNameChange = (e) => {
            setCurrentName(e.target.value);
        };
        const handlePlateChange = (e) => {
            setCurrentPlate(e.target.value);
        };
        const handleModelChange = (e) => {
            setCurrentModel(e.target.value);
        };
        const handleBrandChange = (e) => {
            setCurrentBrand(e.target.value);
        };
        const handleColourChange = (e) => {
            setCurrentColour(e.target.value);
        };
        const handleCategoryChange = (e,values) => {
            setCurrentCategory(values);
        };
        const handleTypeChange = (e,values) => {
            setCurrentType(values);
        };
        const handleMinDepositChange = (e) => {
            let val = parseFloat(e.target.value);
            if(val <= 0){
                val = 0.00;
            }
            setCurrentMinDeposit(val);
        };

        const handleHourlyChange = (e) => {
            let val = parseFloat(e.target.value);
            if(val <= 0){
                val = 0.00;
            }
            setCurrentHourly(val);
        };
        const handleDailyChange = (e) => {
            let val = parseFloat(e.target.value);
            if(val <= 0){
                val = 0.00;
            }
            setCurrentDaily(val);
        };
        const handleWeeklyChange = (e) => {
            let val = parseFloat(e.target.value);
            if(val <= 0){
                val = 0.00;
            }
            setCurrentWeekly(val);
        };
        const handleWeekendChange = (e) => {
            let val = parseFloat(e.target.value);
            if(val <= 0){
                val = 0.00;
            }
            setCurrentWeekend(val);
        };
        const handleMonthlyChange = (e) => {
            let val = parseFloat(e.target.value);
            if(val <= 0){
                val = 0.00;
            }
            setCurrentMonthly(val);
        };
        const handleBiAnnuallyChange = (e) => {
            let val = parseFloat(e.target.value);
            if(val <= 0){
                val = 0.00;
            }
            setCurrentBiAnnually(val);
        };
        const handleYearlyChange = (e) => {
            let val = parseFloat(e.target.value);
            if(val <= 0){
                val = 0.00;
            }
            setCurrentYearly(val);
        };
        const handle2YrChange = (e) => {
            let val = parseFloat(e.target.value);
            if(val <= 0){
                val = 0.00;
            }
            setCurrent2Yr(val);
        };
        const handleTransmissionChange = (e,values) => {
            setCurrentTransmission(values);
        };
        const handleEngineChange = (e) => {
            setCurrentEngine(e.target.value);
        };
        const handleGasChange = (e,values) => {
            setCurrentGas(values);
        };
        const handleTermChange = (e,values) => {
            setCurrentTerm(values);
        };
        const handlePassengerChange = (e) => {
            setCurrentPassenger(e.target.value);
        };
        const handleMileageChange = (e) => {
            setCurrentMileage(e.target.value);
        };
        const handleDoorsChange = (e) => {
            setCurrentDoors(e.target.value);
        };
        const handleBaggageChange = (e) => {
            setCurrentBaggage(e.target.value);
        };
        const handleDescChange = (e) => {
            setCurrentDesc(e.target.value);
        };

        const handleMinDaysChange = (e) => {
            let val = parseFloat(e.target.value);
            if(val <= 0){
                val = 0.00;
            }
            setCurrentMinDays(val);
        };
        const handleMinDeposit1Change = (e) => {
            let val = parseFloat(e.target.value);
            if(val <= 0){
                val = 0.00;
            }
            setCurrentMinDeposit1(val);
        };
        const handleMinDeposit2Change = (e) => {
            let val = parseFloat(e.target.value);
            if(val <= 0){
                val = 0.00;
            }
            setCurrentMinDeposit2(val);
        };
        const handleMinDeposit3Change = (e) => {
            let val = parseFloat(e.target.value);
            if(val <= 0){
                val = 0.00;
            }
            setCurrentMinDeposit3(val);
        };
        const handleMinDeposit4Change = (e) => {
            let val = parseFloat(e.target.value);
            if(val <= 0){
                val = 0.00;
            }
            setCurrentMinDeposit4(val);
        };
        const handleMinDeposit5Change = (e) => {
            let val = parseFloat(e.target.value);
            if(val <= 0){
                val = 0.00;
            }
            setCurrentMinDeposit5(val);
        };
        const handleMinDeposit6Change = (e) => {
            let val = parseFloat(e.target.value);
            if(val <= 0){
                val = 0.00;
            }
            setCurrentMinDeposit6(val);
        };
        const handleMinDeposit7Change = (e) => {
            let val = parseFloat(e.target.value);
            if(val <= 0){
                val = 0.00;
            }
            setCurrentMinDeposit7(val);
        };
        const handleMinDeposit8Change = (e) => {
            let val = parseFloat(e.target.value);
            if(val <= 0){
                val = 0.00;
            }
            setCurrentMinDeposit8(val);
        };
        const handleInstructionChange = (e) => {
            setCurrentInstruction(e.target.value);
        };

        const handleMinAgeChange = (e) => {
            setCurrentMinAge(e.target.value);
        };
        const handleMinExpChange = (e) => {
            setCurrentMinExp(e.target.value);
        };
        const handleRegistrationYearChange = (e) => {
            setCurrentRegistrationYear(e.target.value);
        };
        const handleInsuranceExcess1Change = (e) => {
         let val = parseFloat(e.target.value);
         if (val <= 0) val = 0.00;
         setcurrentInsuranceExcess1(val);
        }
        const handleInsuranceExcess2Change = (e) => {
         let val = parseFloat(e.target.value);
         if (val <= 0) val = 0.00;
         setcurrentInsuranceExcess2(val);
        }
        const handleInsuranceExcess3Change = (e) => {
         let val = parseFloat(e.target.value);
         if (val <= 0) val = 0.00;
         setcurrentInsuranceExcess3(val);
        }

         const types = [
           { title: 'Van' },
           { title: 'Lorry' },
           { title: 'Truck'},
           { title: 'Car' },
           { title: 'Item'},
         ];

         const categories = [
           { title: 'Vehicle' }
         ];

         const transmissions = [
           { title: 'Automatic' },
           { title: 'Manual' },
         ];

         const engines = [
           { title: 'Gas' },
           { title: 'Electric' },
           { title: 'Hybrid' }

         ];

         const gases = [
           { title: 'Gas' },
           { title: 'Diesel' }
         ];

         const terms = [
           { title: 'Short Term' },
           { title: 'Long Term' },
           { title: 'Short Term,Long Term' },
         ];

         var currentAddOns = [];
         var paAddOns = [];
         var paAddOnsPrice = [];
         var paAddOnsQty = [];

         const [inputList, setInputList] = useState(currentAddOns);

         if(props.currentVehicleData.pa_addons){
            paAddOns = props.currentVehicleData.pa_addons.split(",");
            paAddOnsPrice = props.currentVehicleData.pa_addons_price.split(",");
            paAddOnsQty = props.currentVehicleData.pa_addons_ve_max_qty.split(",");
            for (var i = 0, cnt = paAddOns.length; i < cnt; i++) {
                var titleAddOn = paAddOns[i].trim();
                var priceAddOn = paAddOnsPrice[i].trim();
                var qtyAddon = paAddOnsQty[i].trim();
                currentAddOns.push({ title: titleAddOn, price: priceAddOn,ve_max_qty:qtyAddon });

            }
            //  setInputList(currentAddOns);
         }else{
             currentAddOns.push({ title: "", price: "0.00",ve_max_qty:1});
            //  setInputList(currentAddOns);
         }


          // handle input change
          const handleAddOnChange = (e, index) => {
            const { name, value } = e.target;
            const list = [...inputList];
            list[index][name] = value.replace(/,/g, '');
            setInputList(list);
            // console.log(inputList);
          };

          // handle click event of the Remove button
          const handleRemoveClick = index => {
            const list = [...inputList];
            list.splice(index, 1);
            setInputList(list);
          };

          // handle click event of the Add button
          const handleAddClick = () => {
            setInputList([...inputList, { title: "", price: "0.00",ve_max_qty:1  }]);
          };

            var piImages = [];
            if(props.currentVehicleData.pi_images){
                var piImagesTemp = props.currentVehicleData.pi_images.split(',');
                for (var i = 0; i < 9; i++) {
                  if(piImagesTemp[i]){
                      piImages.push("https://api.earental.com/uploads/vehicles/"+piImagesTemp[i].trim());
                  }else{
                      piImages.push(noImage);
                  }
                }
            }

            const handleChangeImage = (index,e) => {
                  var file    = e.target.files[0];
                  var preview = URL.createObjectURL(file);



                  if(index == 0){
                      setCurrentImages0(preview);
                  }else if(index == 1){
                      setCurrentImages1(preview);
                  }else if(index == 2){
                      setCurrentImages2(preview);
                  }else if(index == 3){
                      setCurrentImages3(preview);
                  }else if(index == 4){
                      setCurrentImages4(preview);
                  }else if(index == 5){
                      setCurrentImages5(preview);
                  }else if(index == 6){
                      setCurrentImages6(preview);
                  }else if(index == 7){
                      setCurrentImages7(preview);
                  }else if(index == 8){
                      setCurrentImages8(preview);
                  }


              };

              const handleResetFile = (index,e) => {

                    if(index == 0){
                        document.getElementById("vimg0").value = "";
                        setCurrentImages0(false);
                    }else if(index == 1){
                        document.getElementById("vimg1").value = "";
                        setCurrentImages1(false);
                    }else if(index == 2){
                        document.getElementById("vimg2").value = "";
                        setCurrentImages2(false);
                    }else if(index == 3){
                        document.getElementById("vimg3").value = "";
                        setCurrentImages3(false);
                    }else if(index == 4){
                        document.getElementById("vimg4").value = "";
                        setCurrentImages4(false);
                    }else if(index == 5){
                        document.getElementById("vimg5").value = "";
                        setCurrentImages5(false);
                    }else if(index == 6){
                        document.getElementById("vimg6").value = "";
                        setCurrentImages6(false);
                    }else if(index == 7){
                        document.getElementById("vimg7").value = "";
                        setCurrentImages7(false);
                    }else if(index == 8){
                        document.getElementById("vimg8").value = "";
                        setCurrentImages8(false);
                    }


                };

                const handleRemoveImg = (index,e) => {
                    var tempArr = currentRemove;
                    var status = 0;
                    if(tempArr[index] == 1){
                        tempArr[index] = 0;
                    }else{
                        tempArr[index] = 1;
                        status = 1;
                    }

                    if(index == 1){
                        setCurrentRemove1(status);
                    }else if(index == 2){
                        setCurrentRemove2(status);
                    }else if(index == 3){
                        setCurrentRemove3(status);
                    }else if(index == 4){
                        setCurrentRemove4(status);
                    }else if(index == 5){
                        setCurrentRemove5(status);
                    }else if(index == 6){
                        setCurrentRemove6(status);
                    }else if(index == 7){
                        setCurrentRemove7(status);
                    }else if(index == 8){
                        setCurrentRemove8(status);
                    }

                    setCurrentRemove(tempArr);



                };

                var details = {
                    product_name:currentName,
                    product_brand:currentBrand,
                    product_model:currentModel,
                    product_registration_year:currentRegistrationYear,
                    product_colour:currentColour,
                    product_min_age:currentMinAge,
                    product_experience:currentMinExp,
                    product_transmission:currentTransmission,
                    product_passengers:currentPassenger,
                    product_doors:currentDoors,
                    product_baggage:currentBaggage,
                    product_gas:currentGas,
                    product_description:currentDesc,
                    product_rent_terms: currentTerm,
                    images:[
                        {
                            original: currentImages0 || piImages[0],
                            thumbnail: currentImages0 || piImages[0],
                        },
                        {
                            original: currentImages1 || piImages[1],
                            thumbnail: currentImages1 || piImages[1],
                        },
                        {
                            original: currentImages2 || piImages[2],
                            thumbnail: currentImages2 || piImages[2],
                        },
                        {
                            original: currentImages3 || piImages[3],
                            thumbnail: currentImages3 || piImages[3],
                        },
                        {
                            original: currentImages4 || piImages[4],
                            thumbnail: currentImages4 || piImages[4],
                        },
                        {
                            original: currentImages5 || piImages[5],
                            thumbnail: currentImages5 || piImages[5],
                        },
                        {
                            original: currentImages6 || piImages[6],
                            thumbnail: currentImages6 || piImages[6],
                        },
                        {
                            original: currentImages7 || piImages[7],
                            thumbnail: currentImages7 || piImages[7],
                        },
                        {
                            original: currentImages8 || piImages[8],
                            thumbnail: currentImages8 || piImages[8],
                        }
                    ],
                    addOns: inputList

                }


        return (

            <Dialog
                fullWidth={true}
                maxWidth = {'md'}
                disableBackdropClick={true}
                disableEnforceFocus={false}
                open={props.openState}
                onClose={props.closeModal}
                aria-labelledby="form-dialog-title"
                className="driver-modal">
                <DialogTitle id="form-dialog-title">
                    Update Vehicle
                    <IconButton aria-label="close" onClick={props.closeModal} style={{float:"right"}}>
                        <CloseIcon fontSize="large"	/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="mfp-with-anim mfp-driver-dialog" id="new-card-dialog">
                        <form className="cc-form" onSubmit={props.onEditFormSubmit.bind(this,inputList,currentRemove)}>
                            <fieldset disabled={props.disableFields} >
                                <input
                                className="form-control"
                                name="vReview"
                                value={currentReview}
                                type="hidden"
                                  />
                                <p><b>Basic Information</b></p>
                                <Divider />
                                <br />
                                <Grid container spacing={3} className={classes.newCardForm}>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Title"
                                            name="vName"
                                            type="text"
                                            value={currentName}
                                            onChange={handleNameChange}
                                            fullWidth
                                            required

                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Plate No."
                                            name="dPlate"
                                            type="text"
                                            value={currentPlate}
                                            onChange={handlePlateChange}
                                            fullWidth
                                            required
                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Brand"
                                            name="vBrand"
                                            type="text"
                                            value={currentBrand}
                                            onChange={handleBrandChange}
                                            fullWidth
                                            required

                                         />
                                    </Grid>

                                    <Grid item md={3} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Registration Year"
                                            name="vRYear"
                                            type="text"
                                            value={currentRegistrationYear}
                                            onChange={handleRegistrationYearChange}
                                            fullWidth
                                            required
                                         />
                                    </Grid>

                                    <Grid item md={3} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Model"
                                            name="vModel"
                                            type="text"
                                            value={currentModel}
                                            onChange={handleModelChange}
                                            fullWidth
                                            required
                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <Autocomplete
                                           freeSolo
                                           value={currentCategory}
                                           onChange={handleCategoryChange}
                                           options={categories.map((option) => option.title)}
                                           renderInput={(params) => (
                                             <TextField {...params} label="Category" name="vCategory" variant="outlined" required />
                                           )}
                                         />
                                    </Grid>

                                    <Grid item md={3} sm={12} xs={12}>
                                        <Autocomplete
                                           freeSolo
                                           value={currentType}
                                           options={types.map((option) => option.title)}
                                           renderInput={(params) => (
                                             <TextField {...params} label="Type" name="vType" variant="outlined" required />
                                           )}
                                         />
                                    </Grid>


                                    <Grid item md={3} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Colour"
                                            name="vColour"
                                            type="text"
                                            value={currentColour}
                                            onChange={handleColourChange}
                                            fullWidth
                                            required
                                         />
                                    </Grid>

                                    <Grid item md={12} sm={12} xs={12}>
                                        <div className="form-group-driver-name">
                                            <label>Description*</label>
                                            <textarea
                                            className="form-control"
                                            value={currentDesc}
                                            onChange={handleDescChange}
                                            name="vDesc"
                                             required />
                                        </div>
                                    </Grid>


                                </Grid>

                                <br />
                                <p><b>Rates</b></p>
                                <Divider />
                                <br />
                                <Grid container spacing={3} className={classes.newCardForm}>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Min Deposit Amount"
                                            name="vMindeposit1"
                                            type="number"
                                            fullWidth
                                            value={currentMinDeposit1}
                                            onChange={handleMinDeposit1Change}

                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Hourly"
                                            name="vHourly"
                                            type="number"
                                            fullWidth
                                            value={currentHourly}
                                            onChange={handleHourlyChange}

                                         />
                                    </Grid>

                                    <Grid item md={3} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Min Deposit Amount"
                                            name="vMindeposit2"
                                            type="number"
                                            fullWidth
                                            value={currentMinDeposit2}
                                            onChange={handleMinDeposit2Change}

                                         />
                                    </Grid>

                                    <Grid item md={3} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Min Days"
                                            name="vMindays"
                                            type="number"
                                            fullWidth
                                            InputProps={{
                                                inputProps: { min: 1 }
                                            }}
                                            value={currentMinDays}
                                            onChange={handleMinDaysChange}

                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Daily"
                                            name="vDaily"
                                            type="number"
                                            step={0.5}
                                            fullWidth
                                            value={currentDaily}
                                            onChange={handleDailyChange}

                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Min Deposit Amount"
                                            name="vMindeposit3"
                                            type="number"
                                            fullWidth
                                            value={currentMinDeposit3}
                                            onChange={handleMinDeposit3Change}

                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Weekly"
                                            name="vWeekly"
                                            type="number"
                                            fullWidth
                                            value={currentWeekly}
                                            onChange={handleWeeklyChange}

                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Min Deposit Amount"
                                            name="vMindeposit4"
                                            type="number"
                                            fullWidth
                                            value={currentMinDeposit4}
                                            onChange={handleMinDeposit4Change}

                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Weekend"
                                            name="vWeekend"
                                            type="number"
                                            fullWidth
                                            value={currentWeekend}
                                            onChange={handleWeekendChange}

                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Min Deposit Amount"
                                            name="vMindeposit5"
                                            type="number"
                                            fullWidth
                                            value={currentMinDeposit5}
                                            onChange={handleMinDeposit5Change}

                                         />
                                    </Grid>

                                    <Grid item md={4} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Monthly"
                                            name="vMonthly"
                                            type="number"
                                            fullWidth
                                            value={currentMonthly}
                                            onChange={handleMonthlyChange}

                                         />
                                    </Grid>

                                    <Grid item md={2} sm={12} xs={12}>
                                        <FormControlLabel
                                          control={<Checkbox color="primary" checked={currentRateBonus5} name="monthlyBonus" onChange={handleChangeBonus} />}
                                          label="Bonus Free Rental"
                                        />
                                    </Grid>

                                    {currentRateBonus5 && (
                                        <Grid item container spacing={3} style={{border:"1px solid #ccc",margin:"5px",background:"#eee"}}>
                                            <Grid item md={6} sm={12} xs={12}>
                                                <TextField
                                                    label="How many Days/Months"
                                                    name="vBonusCount5"
                                                    type="number"
                                                    fullWidth
                                                    value={vBonusCount5}
                                                    style= {{fontSize: "15px"}}
                                                    onChange={handleChangeBonusCount}

                                                 />
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                 <FormControl component="fieldset">
                                                  <RadioGroup row aria-label="position" name="vBonusType5" value={vBonusType5} onChange={handleChangeBonusType}>
                                                      <FormControlLabel value="days" control={<Radio color="primary" />} label="Days" />
                                                      <FormControlLabel value="months" control={<Radio color="primary" />} label="Months" />
                                                  </RadioGroup>
                                                </FormControl>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      margin="normal"
                                                      label="Start Date"
                                                      format="MMM d, yyyy"
                                                      name="vBonusStart5"
                                                      value={vBonusStart5}
                                                      onChange={handleChangeBonusStart5}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                      inputProps={{style: {fontSize: "15px"}}}
                                                      InputLabelProps={{style: {fontSize: "15px"}}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      margin="normal"
                                                      label="End Date"
                                                      format="MMM d, yyyy"
                                                      name="vBonusEnd5"
                                                      value={vBonusEnd5}
                                                      onChange={handleChangeBonusEnd5}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                      inputProps={{style: {fontSize: "15px"}}}
                                                      InputLabelProps={{style: {fontSize: "15px"}}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Min Deposit Amount"
                                            name="vMindeposit6"
                                            type="number"
                                            fullWidth
                                            value={currentMinDeposit6}
                                            onChange={handleMinDeposit6Change}

                                         />
                                    </Grid>



                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Bi-Annually"
                                            name="vBiannually"
                                            type="number"
                                            fullWidth
                                            value={currentBiAnnually}
                                            onChange={handleBiAnnuallyChange}

                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Min Deposit Amount"
                                            name="vMindeposit7"
                                            type="number"
                                            fullWidth
                                            value={currentMinDeposit7}
                                            onChange={handleMinDeposit7Change}

                                         />
                                    </Grid>

                                    <Grid item md={4} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="1-Yr Lease"
                                            name="vYearly"
                                            type="number"
                                            fullWidth
                                            value={currentYearly}
                                            onChange={handleYearlyChange}

                                         />
                                    </Grid>

                                    <Grid item md={2} sm={12} xs={12}>
                                        <FormControlLabel
                                          control={<Checkbox color="primary" name="oneYearBonus" checked={currentRateBonus7} onChange={handleChangeBonus} />}
                                          label="Bonus Free Rental"
                                        />
                                    </Grid>

                                    {currentRateBonus7 && (
                                        <Grid item container spacing={3} style={{border:"1px solid #ccc",margin:"5px",background:"#eee"}}>
                                            <Grid item md={6} sm={12} xs={12}>
                                                <TextField
                                                    label="How many Days/Months"
                                                    name="vBonusCount7"
                                                    type="number"
                                                    fullWidth
                                                    value={vBonusCount7}
                                                    style= {{fontSize: "15px"}}
                                                    onChange={handleChangeBonusCount}

                                                 />
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                 <FormControl component="fieldset">
                                                  <RadioGroup row aria-label="position" name="vBonusType7" value={vBonusType7} onChange={handleChangeBonusType}>
                                                      <FormControlLabel value="days" control={<Radio color="primary" />} label="Days" />
                                                      <FormControlLabel value="months" control={<Radio color="primary" />} label="Months" />
                                                  </RadioGroup>
                                                </FormControl>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      margin="normal"
                                                      label="Start Date"
                                                      format="MMM d, yyyy"
                                                      name="vBonusStart7"
                                                      value={vBonusStart7}
                                                      onChange={handleChangeBonusStart7}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                      inputProps={{style: {fontSize: "15px"}}}
                                                      InputLabelProps={{style: {fontSize: "15px"}}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                      margin="normal"
                                                      label="End Date"
                                                      format="MMM d, yyyy"
                                                      name="vBonusEnd7"
                                                      value={vBonusEnd7}
                                                      onChange={handleChangeBonusEnd7}
                                                      KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                      }}
                                                      inputProps={{style: {fontSize: "15px"}}}
                                                      InputLabelProps={{style: {fontSize: "15px"}}}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid item md={6} sm={12} xs={12}>
                                       <TextField
                                           variant="outlined"
                                           label="Min Deposit Amount"
                                           name="vMindeposit8"
                                           type="number"
                                           fullWidth
                                           value={currentMinDeposit8}
                                           onChange={handleMinDeposit8Change}

                                        />
                                   </Grid>

                                   <Grid item md={4} sm={12} xs={12}>
                                       <TextField
                                           variant="outlined"
                                           label="2-Yr Lease"
                                           name="v2yr"
                                           type="number"
                                           fullWidth
                                           value={current2Yr}
                                           onChange={handle2YrChange}

                                        />
                                   </Grid>

                                   <Grid item md={2} sm={12} xs={12}>
                                       <FormControlLabel
                                         control={<Checkbox color="primary" name="twoYearsBonus" checked={currentRateBonus8} onChange={handleChangeBonus} />}
                                         label="Bonus Free Rental"
                                       />
                                   </Grid>

                                   {currentRateBonus8 && (
                                       <Grid item container spacing={3} style={{border:"1px solid #ccc",margin:"5px",background:"#eee"}}>
                                           <Grid item md={6} sm={12} xs={12}>
                                               <TextField
                                                   label="How many Days/Months"
                                                   name="vBonusCount8"
                                                   type="number"
                                                   fullWidth
                                                   value={vBonusCount8}
                                                   style= {{fontSize: "15px"}}
                                                   onChange={handleChangeBonusCount}

                                                />
                                           </Grid>

                                           <Grid item md={6} sm={12} xs={12}>
                                                <FormControl component="fieldset">
                                                 <RadioGroup row aria-label="position" name="vBonusType8" value={vBonusType8} onChange={handleChangeBonusType}>
                                                     <FormControlLabel value="days" control={<Radio color="primary" />} label="Days" />
                                                     <FormControlLabel value="months" control={<Radio color="primary" />} label="Months" />
                                                 </RadioGroup>
                                               </FormControl>
                                           </Grid>

                                           <Grid item md={6} sm={12} xs={12}>
                                               <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                   <KeyboardDatePicker
                                                     margin="normal"
                                                     label="Start Date"
                                                     format="MMM d, yyyy"
                                                     name="vBonusStart8"
                                                     value={vBonusStart8}
                                                     onChange={handleChangeBonusStart8}
                                                     KeyboardButtonProps={{
                                                       'aria-label': 'change date',
                                                     }}
                                                     inputProps={{style: {fontSize: "15px"}}}
                                                     InputLabelProps={{style: {fontSize: "15px"}}}
                                                   />
                                               </MuiPickersUtilsProvider>
                                           </Grid>

                                           <Grid item md={6} sm={12} xs={12}>
                                               <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                   <KeyboardDatePicker
                                                     margin="normal"
                                                     label="End Date"
                                                     format="MMM d, yyyy"
                                                     name="vBonusEnd8"
                                                     value={vBonusEnd8}
                                                     onChange={handleChangeBonusEnd8}
                                                     KeyboardButtonProps={{
                                                       'aria-label': 'change date',
                                                     }}
                                                     inputProps={{style: {fontSize: "15px"}}}
                                                     InputLabelProps={{style: {fontSize: "15px"}}}
                                                   />
                                               </MuiPickersUtilsProvider>
                                           </Grid>
                                       </Grid>
                                   )}

                                </Grid>
                                <br />
                                <p><b>Others</b></p>
                                <Divider />
                                <br />
                                <Grid container spacing={3} className={classes.newCardForm}>
                                    <Grid item md={6} sm={12} xs={12}>
                                         <Autocomplete
                                            freeSolo
                                            value={currentTransmission}
                                            onChange={handleTransmissionChange}
                                            options={transmissions.map((option) => option.title)}
                                            renderInput={(params) => (
                                              <TextField {...params} label="Transmission" name="vTransmission" variant="outlined" required />
                                            )}
                                          />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Engine"
                                            name="vEngine"
                                            value={currentEngine}
                                            onChange={handleEngineChange}
                                            type="text"
                                            fullWidth
                                            required
                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <Autocomplete
                                           freeSolo
                                           value={currentGas}
                                           onChange={handleGasChange}
                                           options={gases.map((option) => option.title)}
                                           renderInput={(params) => (
                                             <TextField {...params} label="Gas" name="vGas" variant="outlined" required />
                                           )}
                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <Autocomplete
                                           freeSolo
                                           value={currentTerm}
                                           onChange={handleTermChange}
                                           options={terms.map((option) => option.title)}
                                           renderInput={(params) => (
                                             <TextField {...params} label="Terms" name="vTerm" variant="outlined" required  />
                                           )}
                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Passengers"
                                            name="vPassengers"
                                            type="number"
                                            value={currentPassenger}
                                            onChange={handlePassengerChange}
                                            fullWidth
                                            required
                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Mileage"
                                            name="vMileage"
                                            type="number"
                                            fullWidth
                                            value={currentMileage}
                                            onChange={handleMileageChange}

                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Doors"
                                            name="vDoors"
                                            type="number"
                                            fullWidth
                                            required
                                            value={currentDoors}
                                            onChange={handleDoorsChange}

                                         />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Baggage"
                                            name="vBaggage"
                                            type="number"
                                            value={currentBaggage}
                                            onChange={handleBaggageChange}
                                            fullWidth

                                         />
                                    </Grid>


                                </Grid>


                                <br />
                                <Grid container spacing={3} className={classes.newCardForm}>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <p><b>Add Ons</b></p>
                                        <Divider />
                                        <br />
                                        <div className="clearfix">
                                            {inputList.map((x, i) => {
                                              return (
                                                <div key={"edit-addons-"+i}>
                                                  <div className="form-group form-group-driver-name">
                                                      <TextField
                                                         label="Title"
                                                         value={x.title}
                                                         name="title"
                                                         fullWidth={true}
                                                         onChange={e => handleAddOnChange(e, i)}
                                                       />
                                                   </div>
                                                   <div className="form-group">
                                                       <TextField
                                                          label="Price"
                                                          value={x.price}
                                                          name="price"
                                                          type="number"
                                                          fullWidth={true}
                                                          onChange={e => handleAddOnChange(e, i)}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <TextField
                                                           label="Max Qty"
                                                           value={x.ve_max_qty}
                                                           name="ve_max_qty"
                                                           type="number"
                                                           InputProps={{
                                                               inputProps: { min: 0 }
                                                           }}
                                                           fullWidth={true}
                                                           onChange={e => handleAddOnChange(e, i)}
                                                         />
                                                     </div>
                                                    <div className="form-group">
                                                        <div className="btn-box">
                                                            {inputList.length !== 1 &&
                                                                <BackspaceRoundedIcon onClick={() => handleRemoveClick(i)} style={{fill: "red",fontSize:"30px",marginTop:"10px"}} alt="remove row" title="remove row" />
                                                            }
                                                            {inputList.length - 1 === i &&
                                                                <AddBoxRoundedIcon onClick={handleAddClick} style={{fill: "green",fontSize:"30px",marginTop:"10px"}} alt="add row"  title="add row"  />}
                                                        </div>
                                                     </div>
                                                    <br />

                                                </div>
                                              );
                                            })}
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} className={classes.newCardForm}>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <p><b>Images</b></p>
                                        <Divider />
                                        <p><i>Note: Image should be 1800x900 dimension</i></p>
                                    </Grid>


                                    <Grid item md={4} sm={6} xs={6} >
                                        <div className="clearfix">
                                            <div className="">
                                                <input id="vimg0" className="form-control" name={"vimages0"} placeholder="" type="file" onChange={handleChangeImage.bind(this,0)} />
                                                <CardMedia
                                                  className={classes.media}
                                                  image={currentImages0 || piImages[0]}
                                                />
                                                <ButtonGroup size="small" fullWidth>
                                                      <Button onClick={handleResetFile.bind(this,0)}>Reset</Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={6} >
                                        <div className="clearfix">
                                            <div className="">
                                                <input id="vimg1" className="form-control" name={"vimages1"} placeholder="" type="file" onChange={handleChangeImage.bind(this,1)} />
                                                <CardMedia
                                                  className={classes.media}
                                                  image={currentImages1 || piImages[1]}
                                                />
                                                <ButtonGroup size="small" fullWidth>
                                                      <Button onClick={handleResetFile.bind(this,1)}>Reset</Button>
                                                      {currentRemove1 == 0 && (
                                                        <Button color="secondary" onClick={handleRemoveImg.bind(this,1)}>
                                                            Remove
                                                        </Button>
                                                      )}

                                                      {currentRemove1 == 1 && (
                                                        <Button color="secondary" onClick={handleRemoveImg.bind(this,1)}>
                                                            Un-Remove
                                                        </Button>
                                                      )}

                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={6} >
                                        <div className="clearfix">
                                            <div className="">
                                                <input id="vimg2" className="form-control" name={"vimages2"} placeholder="" type="file" onChange={handleChangeImage.bind(this,2)} />
                                                <CardMedia
                                                  className={classes.media}
                                                  image={currentImages2 || piImages[2]}
                                                />
                                                <ButtonGroup size="small" fullWidth>
                                                      <Button onClick={handleResetFile.bind(this,2)}>Reset</Button>
                                                      {currentRemove2 == 0 && (
                                                        <Button color="secondary" onClick={handleRemoveImg.bind(this,2)}>
                                                            Remove
                                                        </Button>
                                                      )}

                                                      {currentRemove2 == 1 && (
                                                        <Button color="secondary" onClick={handleRemoveImg.bind(this,2)}>
                                                            Un-Remove
                                                        </Button>
                                                      )}
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={6} >
                                        <div className="clearfix">
                                            <div className="">
                                                <input id="vimg3" className="form-control" name={"vimages3"} placeholder="" type="file" onChange={handleChangeImage.bind(this,3)} />
                                                <CardMedia
                                                  className={classes.media}
                                                  image={currentImages3 || piImages[3]}
                                                />
                                                <ButtonGroup size="small" fullWidth>
                                                      <Button onClick={handleResetFile.bind(this,3)}>Reset</Button>
                                                      {currentRemove3 == 0 && (
                                                        <Button color="secondary" onClick={handleRemoveImg.bind(this,3)}>
                                                            Remove
                                                        </Button>
                                                      )}

                                                      {currentRemove3 == 1 && (
                                                        <Button color="secondary" onClick={handleRemoveImg.bind(this,3)}>
                                                            Un-Remove
                                                        </Button>
                                                      )}
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={6} key={"update-vimages-"+i}>
                                        <div className="clearfix">
                                            <div className="">
                                                <input id="vimg4" className="form-control" name={"vimages4"} placeholder="" type="file" onChange={handleChangeImage.bind(this,4)} />
                                                <CardMedia
                                                  className={classes.media}
                                                  image={currentImages4 || piImages[4]}
                                                />
                                                <ButtonGroup size="small" fullWidth>
                                                      <Button onClick={handleResetFile.bind(this,4)}>Reset</Button>
                                                      {currentRemove4 == 0 && (
                                                        <Button color="secondary" onClick={handleRemoveImg.bind(this,4)}>
                                                            Remove
                                                        </Button>
                                                      )}

                                                      {currentRemove4 == 1 && (
                                                        <Button color="secondary" onClick={handleRemoveImg.bind(this,4)}>
                                                            Un-Remove
                                                        </Button>
                                                      )}
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={6}>
                                        <div className="clearfix">
                                            <div className="">
                                                <input id="vimg5" className="form-control" name={"vimages5"} placeholder="" type="file" onChange={handleChangeImage.bind(this,5)} />
                                                <CardMedia
                                                  className={classes.media}
                                                  image={currentImages5 || piImages[5]}
                                                />
                                                <ButtonGroup size="small" fullWidth>
                                                      <Button onClick={handleResetFile.bind(this,5)}>Reset</Button>
                                                      {currentRemove5 == 0 && (
                                                        <Button color="secondary" onClick={handleRemoveImg.bind(this,5)}>
                                                            Remove
                                                        </Button>
                                                      )}

                                                      {currentRemove5 == 1 && (
                                                        <Button color="secondary" onClick={handleRemoveImg.bind(this,5)}>
                                                            Un-Remove
                                                        </Button>
                                                      )}
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={6}>
                                        <div className="clearfix">
                                            <div className="">
                                                <input id="vimg6" className="form-control" name={"vimages6"} placeholder="" type="file" onChange={handleChangeImage.bind(this,6)} />
                                                <CardMedia
                                                  className={classes.media}
                                                  image={currentImages6 || piImages[6]}
                                                />
                                                <ButtonGroup size="small" fullWidth>
                                                      <Button onClick={handleResetFile.bind(this,6)}>Reset</Button>
                                                      {currentRemove6 == 0 && (
                                                        <Button color="secondary" onClick={handleRemoveImg.bind(this,6)}>
                                                            Remove
                                                        </Button>
                                                      )}

                                                      {currentRemove6 == 1 && (
                                                        <Button color="secondary" onClick={handleRemoveImg.bind(this,6)}>
                                                            Un-Remove
                                                        </Button>
                                                      )}
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={6}>
                                        <div className="clearfix">
                                            <div className="">
                                                <input id="vimg7" className="form-control" name={"vimages7"} placeholder="" type="file" onChange={handleChangeImage.bind(this,7)} />
                                                <CardMedia
                                                  className={classes.media}
                                                  image={currentImages7 || piImages[7]}
                                                />
                                                <ButtonGroup size="small" fullWidth>
                                                      <Button onClick={handleResetFile.bind(this,7)}>Reset</Button>
                                                      {currentRemove7 == 0 && (
                                                        <Button color="secondary" onClick={handleRemoveImg.bind(this,7)}>
                                                            Remove
                                                        </Button>
                                                      )}

                                                      {currentRemove7 == 1 && (
                                                        <Button color="secondary" onClick={handleRemoveImg.bind(this,7)}>
                                                            Un-Remove
                                                        </Button>
                                                      )}
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={6}>
                                        <div className="clearfix">
                                            <div className="">
                                                <input id="vimg8" className="form-control" name={"vimages8"} placeholder="" type="file" onChange={handleChangeImage.bind(this,8)} />
                                                <CardMedia
                                                  className={classes.media}
                                                  image={currentImages8 || piImages[8]}
                                                />
                                                <ButtonGroup size="small" fullWidth>
                                                      <Button onClick={handleResetFile.bind(this,8)}>Reset</Button>
                                                      {currentRemove8 == 0 && (
                                                        <Button color="secondary" onClick={handleRemoveImg.bind(this,8)}>
                                                            Remove
                                                        </Button>
                                                      )}

                                                      {currentRemove8 == 1 && (
                                                        <Button color="secondary" onClick={handleRemoveImg.bind(this,8)}>
                                                            Un-Remove
                                                        </Button>
                                                      )}
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Grid>


                                    <br />
                                </Grid>


                                <br />
                                <p><b>Leasing Requirements</b></p>
                                <Divider />
                                <br />
                                <Grid container spacing={3} className={classes.newCardForm}>
                                    <Grid item md={6} sm={12} xs={12}>
                                          <TextField
                                              variant="outlined"
                                              label="Min Age"
                                              name="vMinAge"
                                              type="number"
                                              value={currentMinAge}
                                              onChange={handleMinAgeChange}
                                              fullWidth
                                              required
                                           />
                                    </Grid>

                                    <Grid item md={6} sm={12} xs={12}>
                                          <TextField
                                              variant="outlined"
                                              label="Min Experience"
                                              name="vMinExp"
                                              type="number"
                                              value={currentMinExp}
                                              onChange={handleMinExpChange}
                                              fullWidth
                                              required
                                           />
                                    </Grid>

                                </Grid>

                                <br />
                                <p><b>Insurance Excess</b></p>
                                <Divider />
                                <br />
                                <Grid container spacing={3} className={classes.newCardForm}>
                                  <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                      variant="outlined"
                                      label="Insurance Excess: Age between 24 and 60"
                                      name="insuranceExcess1"
                                      type="number"
                                      value={currentInsuranceExcess1}
                                      onChange={handleInsuranceExcess1Change}
                                      fullWidth
                                      required
                                    />
                                  </Grid>
                                  <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                      variant="outlined"
                                      label="Insurance Excess: Age < 24 or Age > 60"
                                      name="insuranceExcess2"
                                      type="number"
                                      value={currentInsuranceExcess2}
                                      onChange={handleInsuranceExcess2Change}
                                      fullWidth
                                      required
                                    />
                                  </Grid>
                                  <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                      variant="outlined"
                                      label="Insurance Excess: Own Damage"
                                      name="insuranceExcess3"
                                      type="number"
                                      value={currentInsuranceExcess3}
                                      onChange={handleInsuranceExcess3Change}
                                      fullWidth
                                      required
                                    />
                                  </Grid>
                                </Grid>

                                <br />
                                <p><b>Instruction</b></p>
                                <Divider />
                                <br />
                                <div className="clearfix">
                                    <p><i>Note: this instruction will be shown to lessee for further instruction</i></p>
                                    <div className="form-group-driver-name">
                                        <textarea
                                        className="form-control"
                                        value={currentInstruction}
                                        onChange={handleInstructionChange}
                                        name="vInstruction"
                                         required />
                                    </div>
                                </div>

                                <br />
                                {showPreview && (
                                  <>
                                      <p><b>Preview</b></p>
                                      <Divider />
                                      <ProductPreview details={details} />
                                  </>
                                )}


                                <Divider />
                                <br />
                                <div className="clearfix">

                                    <Button id="updateVehicleBtn" type="submit" variant="contained" className="pull-right" size="large" color="primary">
                                      Update this vehicle
                                    </Button>

                                    <Button onClick={togglePreview}  variant="contained" className="pull-left" size="large" color="default">
                                      Toggle Preview
                                    </Button>


                                </div>
                            </fieldset>

                        </form>

                    </div>

                </DialogContent>

            </Dialog>
          );
    }


    function ModalConfirmDelete(props){
        const classes = useStyles();
        const [isOpen, setIsOpen] = useState(false);

        const Transition = React.forwardRef(function Transition(props, ref) {
          return <Slide direction="up" ref={ref} {...props} />;
        });

        return (
            <Dialog
              open={props.openState}
              TransitionComponent={Transition}
              keepMounted
              onClose={props.closeDeleteModal.bind(this,false)}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">{"You're about to delete a vehicle"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Are you sure you want to delete this vehicle?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" size="large" onClick={props.closeDeleteModal.bind(this,false)} color="secondary">
                  No
                </Button>
                <Button variant="contained" size="large"  onClick={props.closeDeleteModal.bind(this,true)} color="primary">
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          );
    }

    return (
        <div className="vehicle-cont">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/">
              Dashboard
          </Link>
          <Typography color="textPrimary">Vehicles</Typography>
        </Breadcrumbs>
        <br />

        <div className="">
            <ModalAdd
                onAddFormSubmit={this.onAddFormSubmit}
                openState={this.state.openAddModal}
                closeModal={this.closeAddModal}
                handleInputChange={this.handleChangeAddInput}
                driverState={this.state}
                onSelectFile={this.onSelectFile}
                onImageLoaded={this.onImageLoaded}
                onCropComplete={this.onCropComplete}
                onCropChange={this.onCropChange}
                disableFields={this.state.disableFields}
             />
             <ModalConfirmDelete
                 openState={this.state.openDeleteConfirm}
                 closeDeleteModal={this.closeDeleteModal}
              />

             <ModalEdit
                  onEditFormSubmit={this.onEditFormSubmit}
                  openState={this.state.openEditModal}
                  closeModal={this.closeEditModal}
                  currentVehicleData={this.state.currentVehicleData}
                  disableFields={this.state.disableFields}
             />

             <Dialog
                 open={this.state.openSchedule}
                 fullWidth={true}
                 maxWidth = {'md'}
                 disableBackdropClick={true}
                 aria-labelledby="form-dialog-title"
                 className="driver-modal">
                 <DialogTitle>
                     Schedule Calendar | {this.state.currentVehicleName}
                     <IconButton aria-label="close" onClick={this.handleToggleSchedule.bind(this,"",null)} style={{float:"right"}}>
                         <CloseIcon fontSize="large"	/>
                     </IconButton>
                 </DialogTitle>
                 <DialogContent>
                 <Grid container>
                     <Grid item md={12} sm={12} xs={12} align="center">
                         <Calendar
                             selectRange={true}
                             minDate={new Date()}
                             calendarType={"US"}
                             showDoubleView={true}
                             value={this.state.selectedRangeDate}
                             onChange={this.handleRangeSelect}
                             tileDisabled={this.disableCalendarRestrictedOnly}
                             tileClassName="cal-date-listing"
                         />
                     </Grid>

                     <Grid item md={12} sm={12} xs={12} align="center">
                         <br />
                         <Chip style={{background:"#3CB371",color:"#fff",marginRight:"5px",fontSize:"12px"}} label="Selected" />
                         <Chip style={{background:"#fff",color:"#111",marginRight:"5px",fontSize:"12px"}} label="Available" />
                         <Chip style={{background:"#f0f0f0",fontSize:"12px"}} label="Unavailable" />
                     </Grid>

                 </Grid>

                 <div style={{ height: 400, width: '100%',marginTop:'20px' }}>
                    <Typography variant="h6">
                        Manual Schedule listing | <small>check rows you want to delete</small>
                    </Typography>
                    {this.state.currentRestrictedList && (
                        <DataGrid
                            rows={this.state.currentRestrictedList}
                            columns={columns}
                            pageSize={5}
                            checkboxSelection
                            onSelectionChange={this.handleRowSelection}
                        />
                    )}

                 </div>


                 </DialogContent>
                 <DialogActions>
                     <span>Select range of dates to add in schedule</span>
                   <Button variant="contained" size="large" onClick={this.handleUpdateSchedule} color="primary" disabled={this.state.proceedSchedule}>
                     {this.state.manualSchedText}
                   </Button>
                 </DialogActions>

             </Dialog>
            <div className="row">
                <div className="col-md-12">
                    {this.renderListing()}

                    <Pager
                        total={this.state.totalPage}
                        current={this.state.currentPage}
                        visiblePages={this.state.visiblePage}
                        titles={{ first: 'First', last: 'Last' }}
                        className={"pagination-md "+this.state.hidePager}
                        onPageChanged={this.handlePageChanged}
                        showPagination={false}
                    />

                </div>

            </div>

             <Snackbar open={this.state.openSb} autoHideDuration={6000} onClose={this.handleCloseSb}>
                 <Alert onClose={this.handleCloseSb} severity="success" className="sb-msg">
                     Vehicle successfully added.
                 </Alert>
             </Snackbar>

             <Snackbar open={this.state.openSbDeleted} autoHideDuration={6000} onClose={this.handleCloseSbDelete}>
                 <Alert onClose={this.handleCloseSbDelete} severity="success" className="sb-msg">
                     Vehicle successfully deleted.
                 </Alert>
             </Snackbar>

             <Snackbar open={this.state.openSbEdit} autoHideDuration={6000} onClose={this.handleCloseSbEdit}>
                 <Alert onClose={this.handleCloseSbEdit} severity="success" className="sb-msg">
                     Vehicle successfully updated.
                 </Alert>
             </Snackbar>

             <Snackbar open={this.state.openSbSchedule} autoHideDuration={6000} onClose={this.handleCloseSbSchedule}>
                 <Alert onClose={this.handleCloseSbSchedule} severity="success" className="sb-msg">
                     Schedule successfully updated.
                 </Alert>
             </Snackbar>
             <div className="fab-cont-right">
                 <Fab color="primary" aria-label="add" onClick={this.openAddModal}>
                   <AirportShuttleRoundedIcon />
                 </Fab>
             </div>

        </div>

        <div className="gap"></div>


        </div>
    );
  }
}

export default Vehicles
