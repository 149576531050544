import React, { Component } from "react";

import "./../assets/css/aboutus.css";
import {Helmet} from "react-helmet";


class Terms extends Component {

    constructor (props) {
      super(props)
      this.state = {
        startDate: new Date(),
        endDate: new Date(),
        MetaTitle:"EARental | Content Policy",
        MetaDescription:"EARental is Singapore No.1 Online Van, Lorry & Truck commercial vehicles for lease for businesses and individuals. Hassle-Free, book and reserve your vehicle Online Now!",
        MetaKeywords:"Content Policy,online rental, van rental, lorry rental, truck rental, commercial vehicles for rent, leasing, daily rental, weekly rental, weekend rental, monthly rental, yearly rental, short term rental, long term rental, cargo van rental singapore, cheap van rental, van rental price, Toyota hiace van rental singapore, van rental daily, auto van rental singapore, commercial van for rent, van rental near me, daily van rental singapore",
        Canonical:"https://www.earental.com/content-policy",
      };
    }



  componentDidMount() {
      window.scrollTo(0, 0)
    }

  render() {
    return (
        <div classNameName="about-us-cont">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="title" content={this.state.MetaTitle} />
                <meta content={this.state.MetaTitle} property="og:title" />
                <meta name="description" content={this.state.MetaDescription} />
                <meta content={this.state.MetaDescription} property="og:description" />
                <meta name="keywords" content={this.state.MetaKeywords} />
                <meta name="robots" content="index, follow" />
                <title>{this.state.MetaTitle}</title>
                <link rel="canonical" href={this.state.Canonical} />
            </Helmet>
            <div className="container">
                <h1 className="page-title">Content Policy</h1>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <article className="post">
                            <header className="post-header"></header>
                            <div className="post-inner">
                                <p>
                                    By posting content on EARental, you agree to abide by this policy. We reserve the right to remove any content, in whole or part, that violates this policy, our <a href="/terms-of-service">Terms of Service</a>, or for any other reason at our sole discretion.
                                </p>

                                <p>
                                    In the event of repeated or severe violations, we may suspend or permanently deactivate the account(s) in question.
                                </p>

                                <p>
                                    You can report the content directly or <a href="/contact">contact us</a> to report content that appears to violate this policy.
                                </p>

                                <p>
                                    The following content is never allowed on EARental:
                                </p>

                                <ul style={{marginLeft:"35px"}}>
                                    <li>Content created solely for the purpose of advertising or other commercial content, including company logos, links, or company names</li>
                                    <li>Spam, unwanted contact, or content that is shared repeatedly in a disruptive manner</li>
                                    <li>Content that endorses or promotes illegal or harmful activity, or that is sexually explicit, violent, graphic, threatening, or harassing</li>
                                    <li>Content that is discriminatory</li>
                                    <li>Attempts to impersonate another person, account, or entity, including a representative of EARental</li>
                                    <li>Content that is illegal or that violates another person’s or entity’s rights, including intellectual property rights and privacy rights</li>
                                    <li>Content that includes another person’s private or confidential information, including content that is sufficient to identify a listing’s location</li>
                                </ul>

                                <p>
                                    The following are policy violations specific to a type of the content:
                                </p>

                                <div className="gap gap-small"></div>
                                <h4>Listing titles</h4>
                                <ul style={{marginLeft:"35px"}}>
                                    <li>Listing titles that include information irrelevant to the listing type, style, or experience</li>
                                    <li>Listing titles that include symbols or emojisl</li>
                                </ul>

                                <div className="gap gap-small"></div>
                                <h4>Listing or profile page</h4>
                                <ul style={{marginLeft:"35px"}}>
                                    <li>Listings and profiles that provide fraudulent, false, misleading, or deceptive information</li>
                                </ul>

                                <div className="gap gap-small"></div>
                                <h4>Community Centre</h4>
                                <ul style={{marginLeft:"35px"}}>
                                    <li>Content that is off-topic, doesn’t ask a question, or doesn’t offer knowledge in response to a question as part of a larger discussion</li>
                                    <li>Trolling or repeatedly targeting community members</li>
                                </ul>

                                <div className="gap gap-small"></div>
                                <h4>Reviews</h4>
                                <ul style={{marginLeft:"35px"}}>
                                    <li>Reviews that are biased and exhibit indications of extortion/incentivisation, conflicts of interest, or direct competition</li>
                                    <li>Reviews that contain no relevant information about a host or guest, listing, or experience will be removed. Reviews that contain mostly irrelevant information are also subject to removal, but only where the otherwise relevant information would not be expected to meaningfully inform the booking decisions of other community members.</li>
                                </ul>

                            </div>
                        </article>
                    </div>
                </div>
            </div>

        </div>
    );
  }
}

export default Terms;
