import React from "react";
import {
  createMuiTheme,
  makeStyles,
} from '@material-ui/core/styles';
import { store } from 'react-notifications-component';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import StripeInput from './StripeInput';
import PaymentIcon from 'react-payment-icons';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import { grey } from '@material-ui/core/colors';
import CustomCircularProgress from "../widgets/CircularProgress";

const theme = createMuiTheme({
  typography: {
    fontSize: 18,
  },
});

const paymentStyles = makeStyles((theme) => ({
  layout: {
    padding: theme.spacing(5, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
    }
  },
  title: {
    fontWeight: 300,
    marginBottom: theme.spacing(3),
  },
  cardsDropdown: {
    color: grey[800],
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '& .MuiFormLabel-root': {
      fontSize: '1.7rem',
      marginBottom: theme.spacing(-2),
      '&.Mui-focused': {
        color: grey[800],
      },
    },
    '& .MuiFormControl-root': {
      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        '&:hover fieldset': {
          borderColor: '#B4B4B4',
        },
        '&.Mui-focused fieldset': {
          borderColor: "#2F96B4",
        },
      },
    },
  },
  cardsDropdownMenuItem: {
    padding: theme.spacing(2),
    '& span': {
      fontSize: '1.25rem',
    }
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  paymentForm: {
    color: grey[800],
    '& .MuiFormControlLabel-root': {
      marginBottom: 0,
    },
    '& .MuiFormControl-root': {
      '& .MuiFormLabel-root': {
        backgroundColor: 'white',
        '&.Mui-focused': {
          color: "#2F96B4",
        },
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        '&:hover fieldset': {
          borderColor: '#B4B4B4',
        },
        '&.Mui-focused fieldset': {
          borderColor: "#2F96B4",
        },
        '& input': {
          fontFamily: "sans-serif",
          fontSize: "14px",
        },
      },
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    color: '#FFF',
    backgroundColor: '#262626',
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  gridItem1: {
    order: 2,
    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  gridItem2: {
    order: 1,
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
  },
  paymentProcessing: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: grey[200] + '!important',
    height: 3,
  },
}));

const Payment = (props) => {
  const api = {
    siteUrl: "https://earental.com",
    url: "https://api.earental.com",
    key: "72ffc1303c7c0286887a3276f0ab61b8",
  };
  const styles = paymentStyles();
  const stripe = useStripe();
  const elements = useElements();
  const inputStyle = {
    base: {
      // fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
  };
  const [isInitializing, setInitializing] = React.useState(true);
  const [isPaymentProcessing, setPaymentProcessing] = React.useState(false);
  const [cardholdersName, setCardholdersName] = React.useState("");
  const [paymentError, setPaymentError] = React.useState("");
  const [card, setCard] = React.useState(0);
  const [cardList, setCardList] = React.useState([]);
  const [rememberCard, setRememberCard] = React.useState(false);

  React.useEffect(() => {
    if (stripe && isInitializing) {
      // Get saved cards
      let formData = new FormData();
      formData.append('api_key', api.key);
      formData.append('access_token', localStorage.getItem('ctoken'));
      formData.append('jham_token', localStorage.getItem('token'));
      axios({
        method: 'post',
        url: api.url + '/account/saved_cards',
        data: formData,
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then((response) => {
          if (response.data.error == 0) {
            var cards = [];
            var primaryCard = 0;
            response.data.cards.map((card) => {
              cards.push({
                id: card.stripe_pm_id,
                number: card.stripe_card_last4,
                type: card.stripe_card_type,
                primary: parseInt(card.stripe_card_primary)
              });
              if (card.stripe_card_primary == 1) {
                primaryCard = card.stripe_pm_id;
              }
            });
            setCardList(cards);
            setCard(primaryCard);
            setInitializing(false);
          } else {
            let reload = false;
            let redirect = false;
            let redirectURL = "";
            let notifTitle = "";
            let notifMessage = "";
            let notifType = "danger";
            switch (response.data.error_type) {
              case "validation_error":
                function ErrorMsg(props) {
                  return (
                    props.msgs.map((item, i) =>
                      <li key={"err-" + item.i}>{item.value}</li>
                    )
                  );
                }
                notifTitle = "Invalid Request";
                notifMessage = <ErrorMsg msgs={response.data.error_msg} />;
                notifType = "danger";
                break;
              case "cancel_error":
                notifTitle = "An error has occurred";
                notifMessage = response.data.error_msg;
                notifType = "danger";
                break;
              case "authorization_error":
                redirect = true;
                redirectURL = api.siteUrl + "/signin";
                notifTitle = "Your session has expired";
                notifMessage = "Please login again. This page will automatically reload.";
                notifType = "danger";
                break;
              default:
                notifTitle = "Something went wrong";
                notifMessage = response.data.error_msg;
                notifType = "danger";
                break;
            }
            if (reload == true) {
              setTimeout(function () {
                window.location.href = redirectURL;
              }.bind(this), 3000);
            }
            if (redirect == true) {
              setTimeout(function () {
                props.history.push(redirectURL);
              }.bind(this), 3000);
            }
            showNotification(notifTitle, notifMessage, notifType); // Show error notification
          }
        })
        .catch(error => {
          if (error.response && error.response.status == 403) {
            let errorTitle = "Oops Something went wrong";
            let errorMessage = "The action you have requested is not allowed. Please reload the page";
            showNotification(errorTitle, errorMessage, "danger"); // Show error notification
          }
        });

      if (props.paymentType == "recurring") {
        setRememberCard(true);
      } else {
        setRememberCard(false);
      }
    }
  });

  const handleCardChange = (event) => {
    const target = event.target;
    setCard(target.value);
  }

  const handleSetCardholdersName = (event) => {
    const target = event.target;
    setCardholdersName(target.value);
  }

  const handleRememberCardCheckChange = (event) => {
    if (props.paymentType == "recurring") {
      setRememberCard(true);
    } else {
      const target = event.target;
      setRememberCard(target.checked);
    }
  }

  const handlePayment = async (e) => {
    e.preventDefault();

    setPaymentError("");
    setPaymentProcessing(true);

    let stripeDataObject = null;
    if (card == 0) {
      // Use new card
      if (cardholdersName == "") {
        setPaymentError("Enter cardholder's name.");
        setPaymentProcessing(false);
        return false;
      }
      const cardElement = elements.getElement(CardNumberElement);
      stripeDataObject = {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: cardholdersName,
            // email: customerEmail
          }
        }
      };
      if (rememberCard) {
        stripeDataObject.setup_future_usage = "off_session";
        localStorage.setItem('rememberCard', 1);
      } else {
        localStorage.setItem('rememberCard', 0);
      }
      localStorage.setItem('newCard', 1);
    } else {
      // Use saved card
      stripeDataObject = {
        payment_method: card,
      };
      localStorage.setItem('newCard', 0);
    }

    const { paymentIntent, error } = await stripe.confirmCardPayment(props.clientSecret, stripeDataObject);
    if (error && error.type == "validation_error") {
      setPaymentError(error.message);
      setPaymentProcessing(false);
    } else if (error && error.type === "card_error") {
      props.onFailed(error);
      setPaymentProcessing(false);
    } else if (paymentIntent && paymentIntent.status === props.expectedPaymentStatus) {
      props.onSuccess(paymentIntent.id);
    }
  }

  return (
    <React.Fragment>
      {isPaymentProcessing && (
        <Box className={styles.paymentProcessing}>
          <Box className={styles.loadingWrapper} style={{ height: '100%' }}>
            <CustomCircularProgress />
          </Box>
        </Box>
      )}
      {isInitializing &&
        <Box className={styles.loadingWrapper} style={{ height: '100%' }}>
          <CustomCircularProgress />
        </Box>
      }
      {!isInitializing &&
        <form onSubmit={handlePayment} className={styles.paymentForm}>
          <Grid container spacing={4}>
            {cardList.length > 0 &&
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth={true}>
                  <InputLabel id="card-list">Select a card to use</InputLabel>
                  <Select
                    labelId="card-list"
                    id="card-list"
                    value={card}
                    onChange={handleCardChange}
                    label="Select a card to use"
                  >
                    {cardList.map((item, i) => {
                      return (
                        <MenuItem key={i} value={item.id} className={styles.cardsDropdownMenuItem}>
                          <PaymentIcon id={item.type.toLowerCase()} style={{ marginRight: 10, width: 50 }} className="payment-icon" />
                          <Typography component="span">
                            {item.type.toUpperCase()} ending in <strong>&#9679;&#9679;{item.number}</strong> {item.primary ? <Typography component="span">(<em>Primary</em>)</Typography> : ""}
                          </Typography>
                        </MenuItem>
                      )
                    })}
                    <MenuItem value={0}>
                      <CreditCardIcon className="payment-icon" style={{ marginRight: 10, width: 50, verticalAlign: 'middle' }} />
                      <Typography component="span">
                        <em>Enter new card</em>
                      </Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            }
          </Grid>
          <Divider className={styles.divider} variant="middle" />
          <Grid container spacing={4} style={{ display: card == 0 ? "flex" : "none" }}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                id="cardholders-name"
                name="cardholders-name"
                label="Cardholder's Name"
                autoComplete="off"
                size="small"
                value={cardholdersName}
                onChange={handleSetCardholdersName}
                InputLabelProps={{ shrink: true }}
                placeholder="Name on card"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                id="credit-card-number"
                name="credit-card-number"
                label="Credit Card Number"
                autoComplete="off"
                size="small"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardNumberElement,
                    options: {
                      placeholder: "4242 4242 4242 4242",
                      style: inputStyle
                    },
                  },
                }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                id="expiry-date"
                name="expiry-date"
                label="Expiry Date"
                autoComplete="off"
                size="small"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardExpiryElement,
                    options: { style: inputStyle },
                  },
                }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                id="cvc"
                name="cvc"
                label="Verification Code"
                autoComplete="off"
                size="small"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardCvcElement,
                    options: { style: inputStyle },
                  },
                }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox color="secondary" onChange={handleRememberCardCheckChange} checked={rememberCard} />}
                label={props.paymentType == "recurring" ?
                  "This card will be used for your recurring payments of this lease" :
                  "Remember this card for future lease"
                }
              />
            </Grid>
            {paymentError != "" && (
              <Grid item xs={12}>
                <Alert variant="outlined" severity="error">
                  {paymentError}
                </Alert>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box textAlign="right">
                <Button type="submit" variant="contained" color="primary" disabled={isPaymentProcessing}>
                  {!isPaymentProcessing ? "Pay" : "Please wait"}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      }
    </React.Fragment>
  )
}

export default function CheckoutForm(props) {
  const styles = paymentStyles();
  const [isPaymentLoading, setPaymentLoading] = React.useState(true);
  const [stripePromise, setStripePromise] = React.useState(null);

  React.useEffect(() => {
    if (props.publishableKey != "") {
      const stripe = loadStripe(props.publishableKey);
      setStripePromise(stripe);
      setPaymentLoading(false);
    }
  }, [props.publishableKey]);

  return (
    <Paper elevation={0}>
      {isPaymentLoading && (
        <Box className={styles.loadingWrapper}>
          <CustomCircularProgress />
        </Box>
      )}
      {!isPaymentLoading && stripePromise && (
        <Elements stripe={stripePromise} clientSecret={props.clientSecret}>
          <Payment clientSecret={props.clientSecret} onSuccess={props.onSuccess} onFailed={props.onFailed} expectedPaymentStatus={props.expectedPaymentStatus} paymentType={props.paymentType} />
        </Elements>
      )}
    </Paper>
  )
}

const showNotification = (title, message, type) => {
  store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: false
    }
  });
}