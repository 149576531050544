import React, { Component } from "react";
import {Helmet} from "react-helmet";
import "./../assets/css/aboutus.css";



class Terms extends Component {

    constructor (props) {
      super(props)
      this.state = {
        startDate: new Date(),
        endDate: new Date(),
        MetaTitle:"EARental | Security Deposit",
        MetaDescription:"EARental is Singapore No.1 Online Van, Lorry & Truck commercial vehicles for lease for businesses and individuals. Hassle-Free, book and reserve your vehicle Online Now!",
        MetaKeywords:"security deposit,online rental, van rental, lorry rental, truck rental, commercial vehicles for rent, leasing, daily rental, weekly rental, weekend rental, monthly rental, yearly rental, short term rental, long term rental, cargo van rental singapore, cheap van rental, van rental price, Toyota hiace van rental singapore, van rental daily, auto van rental singapore, commercial van for rent, van rental near me, daily van rental singapore",
        Canonical:"https://www.earental.com/security-deposit",
      };
    }



  componentDidMount() {
      window.scrollTo(0, 0)
    }

  render() {
    return (
        <div classNameName="about-us-cont">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="title" content={this.state.MetaTitle} />
                <meta content={this.state.MetaTitle} property="og:title" />
                <meta name="description" content={this.state.MetaDescription} />
                <meta content={this.state.MetaDescription} property="og:description" />
                <meta name="keywords" content={this.state.MetaKeywords} />
                <meta name="robots" content="index, follow" />
                <title>{this.state.MetaTitle}</title>
                <link rel="canonical" href={this.state.Canonical} />
            </Helmet>
            <div className="container">
                <h1 className="page-title">How does EARental handle security deposits?</h1>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <article className="post">
                            <header className="post-header"></header>
                            <div className="post-inner">
                                <p>
                                    Security deposits can be required either by EARental or by Lessor. EARental security deposit requests are applicable to Lessor. Lessor security deposit requests applied to Equipment and are never requested based on anyone’s race, national origin, ethnicity, sex, gender identity, or age.
                                </p>

                                <div className="gap gap-small"></div>
                                <h4>EARental-required security deposits</h4>
                                <p>
                                    EARental required a security deposit of S$1,000 from Lessor to access the Lessor Dashboard to manage their listing. The security deposit is to ensure the Equipment is available when Lessee checkout on EARental. A S$200 cancellation fee will be deducted from the security deposit for each unsuccessful lease.
                                </p>
                                <p>
                                The security deposits must be made by cheque or bank transfer to EARental. If the security deposit runs low, you’ll be notified by email and will have 12 hours to top up the account. If that doesn’t work out, your listing will be suspended.
                                </p>

                                <div className="gap gap-small"></div>
                                <h4>Lessor-required security deposits</h4>
                                <p>
                                Some Lessor require a security deposit for their listing. The amount, between S$100 and S$5,000, is set by the Lessor, not by EARental. If you’re a Lessee and you’re booking a listing with a Lessor-required security deposit, you’ll be shown this amount before you make your reservation.
                                </p>
                                <p>
                                For short-term lease (less than one month), an authorisation hold for the security deposit amount will be placed on your payment method. You won’t be charged, but the hold means that you won’t be able to access these funds until the hold is released. For example, if you’re paying with a credit card with a total credit limit of S$3,000, and there's a security deposit of S$100, your credit card limit would then be S$2,900 until the hold is released.
                                </p>
                                <p>
                                For long-term lease (one month or more), no authorisation hold will be placed. The security deposit amount will be charged on your payment method upon checkout.
                                </p>
                                <p>
                                As long as no Equipment damage, fines occur during the leasing period, vehicle returned with full tank of diesel, we’ll initiate a release/refund of the security deposit placed on hold or charged to your card 14 days after returning of the Equipment. Your bank may take 3–12 business days to process the release of funds. If it turns out that we can’t authorise the hold for the security deposit amount, you’ll be notified by email and will have 12 hours to try another payment method. If that doesn’t work out, we’ll cancel your reservation and you’ll be refunded.
                                </p>
                                <p>
                                Try to make sure your payment method has enough money available to cover the security deposit. (If you’re not able to do that, EARental is not responsible for any associated fees, including overdraft fees.)
                                </p>

                                <div className="gap gap-small"></div>
                                <h4>How security deposits work if there’s an issue</h4>
                                <p>
                                If there’s an issue during your lease, for Lessor-required deposits, the claims will be submitted via the Lessor Dashboard. Any additional charges will be charged to or deducted from the security deposits. The balance security deposits will be refunded/released within 14 days of returning the Equipment.
                                </p>

                                <p>
                                <b>Note:</b> All payments, including security deposits, should always be made through EARental. Don’t ever exchange money outside of the EARental website.
                                </p>
                            </div>
                        </article>
                    </div>
                </div>
            </div>

        </div>
    );
  }
}

export default Terms;
