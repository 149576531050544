import React, { Component } from "react";
import {
  NavLink,
} from "react-router-dom";
import axios from 'axios';
import { store } from 'react-notifications-component';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import queryString from 'query-string';
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie';

// import Home from "./Home";
// import Stuff from "./Stuff";
// import Contact from "./Contact";
// import ScriptTag from 'react-script-tag';





class Footer extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor (props) {
      super(props)
      const { cookies } = props;
        this.state = {
          ctoken: cookies.get('ctoken') || false,
          token: cookies.get('csrf_cookie_jham') || false
        };
        localStorage.setItem('ctoken', cookies.get('ctoken') || false);

    }


  render() {
      function Copyright() {
        return (
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.earental.com/">
              EARental
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
      }
      return (
          <Box pt={4}>
            <Copyright />
          </Box>
      );
  }


}
export default withCookies(Footer)
