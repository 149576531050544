import React from "react";
import ReactDOM from "react-dom";
import Cms from "./dashboard/Dashboard";//try
import Main from "./Main";
import { BrowserRouter } from "react-router-dom";
// import './assets/js/bootstrap-datepicker.js';
//d2 wala naman niload na css


// let owlCarousel = require('../node_modules/owl.carousel/dist/owl.carousel.min.js');

let host = window.location.host;
let protocol = window.location.protocol;
let parts = host.split(".");
let subdomain = "";
// If we get more than 3 parts, then we have a subdomain
// INFO: This could be 4, if you have a co.uk TLD or something like that.
if (parts.length >= 3) {
  subdomain = parts[0];
  console.log(subdomain);
  // Remove the subdomain from the parts list
  parts.splice(0, 1);
  // Set the location to the new url
  //window.location = protocol + "//" + parts.join(".") + "/" + subdomain;
  if(subdomain == "lessor"){
    ReactDOM.render(
        <Cms/>,
    document.getElementById("root")
    );
  }else if(subdomain == "staging"){

    ReactDOM.render(
        <Main/>,
    document.getElementById("root")
    );
  }else{

    ReactDOM.render(
        <Main/>,
    document.getElementById("root")
    );
  }
}else{

    ReactDOM.render(
        <Main/>,
    document.getElementById("root")
    );

}
