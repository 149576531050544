import React, { Component } from "react";
import axios from 'axios';
import { store } from 'react-notifications-component';
import parallaxBg1 from './assets/img/bg-van.jpg';
import parallaxBg2 from './assets/img/EARental-BG2.jpg';
import parallaxBg3 from './assets/img/BG.jpg';
import sanatizeD from './assets/img/sanatize.jpg';
import sanatizeM from './assets/img/sanatize-m.jpg';
import howToBook from './assets/img/HowtoBookVehicleOnlineSection.png';


import avatarReview1 from './assets/img/avatar-f1.jpg';
import avatarReview2 from './assets/img/avatar-f2.jpg';
import avatarReview3 from './assets/img/avatar-m1.jpg';
import avatarReview4 from './assets/img/avatar-m2.jpg';

import bannerPromoD05 from './assets/img/hbd-05.png';
import bannerPromoM05 from './assets/img/hbm-05.png';
import imgPromotion from './assets/img/promotion.png';
import imgVanR from './assets/img/Van-R.png';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import moment from "moment";
import DatePicker from "react-datepicker";
import sliderBg1 from './assets/img/bestdeal-1.jpg';
import sliderBg2 from './assets/img/bestdeal-2.jpg';
import sliderBg3 from './assets/img/bestdeal-3.jpg';

import topservieBg1 from './assets/img/service-weekly.jpg';
import topservieBg2 from './assets/img/service-monthly.jpg';
import topservieBg3 from './assets/img/service-6month.jpg';
import topservieBg4 from './assets/img/service-yearly.jpg';
import { red,grey, orange } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Hidden from '@material-ui/core/Hidden';
import ReactGA from 'react-ga';

import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import {Helmet} from "react-helmet";


import {
  Redirect
} from "react-router-dom";
import "./assets/css/home.css";





var howToBookStyle = {
  backgroundImage: "url(" + howToBook + ")",
};

var parallaxBgStyle = {
  backgroundImage: "url(" + parallaxBg1 + ")",
  marginTop:"0px"
};
var mobileSearchHead = {
  backgroundImage: "url(" + parallaxBg3 + ")",
  backgroundPosition:"center",
  padding:"20px",
  backgroundSize:"cover",
};


var sliderImage1 = {
  backgroundImage: "url(" + sliderBg1 + ")"
};

var sliderImage2 = {
  backgroundImage: "url(" + sliderBg2 + ")"
};

var sliderImage3 = {
  backgroundImage: "url(" + sliderBg3 + ")"
};

var sanatizeDBg = {
  backgroundImage: "url(" + sanatizeD + ")"
};

var sanatizeMBg = {
  backgroundImage: "url(" + sanatizeM + ")",
  backgroundPosition:"center top"
};



var topserviceImage1 = topservieBg1;
var topserviceImage2 = topservieBg2;
var topserviceImage3 = topservieBg3;
var topserviceImage4 = topservieBg4;

const date = new Date();


const useStyles = makeStyles((theme) => ({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#d40029',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#d40029',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#d40029',
      },
    },
    newCardForm: {
          color: grey[800],
          '& .MuiFormControl-root': {
            '& .MuiFormLabel-root': {
              fontSize: '1.2rem',
              '&.Mui-focused': {
                color: grey[800],
                backgroundColor: 'white',
                paddingRight: 5,
              },
            },
            '& .MuiOutlinedInput-root': {
              borderRadius: 0,
              '&:hover fieldset': {
                borderColor: '#B4B4B4',
              },
              '&.Mui-focused fieldset': {
                borderColor: orange[600],
              },
              '& input': {
                fontSize: 20,
                font:'auto'
              },
            },
        },
    }
}));



class Home extends React.Component {

    constructor (props) {
        super(props)
        ReactGA.set({ page: window.location.pathname });
        ReactGA.pageview(window.location.pathname);
        var currentDate = props.mainState.currentDate;
        currentDate = moment(currentDate).add(1, 'days');
        this.state = {
            MetaTitle:"EARental Singapore Online Van, Truck, Lorry Rental/Hire/Lease",
            MetaDescription:"EARental is Singapore No.1 Online Van, Lorry & Truck commercial vehicles for lease for businesses and individuals. Hassle-Free, book and reserve your vehicle Online Now!",
            MetaKeywords:"online rental, van rental, lorry rental, truck rental, commercial vehicles for rent, leasing, daily rental, weekly rental, weekend rental, monthly rental, yearly rental, short term rental, long term rental, cargo van rental singapore, cheap van rental, van rental price, Toyota hiace van rental singapore, van rental daily, auto van rental singapore, commercial van for rent, van rental near me, daily van rental singapore",
            Canonical:"https://www.earental.com",
            minDate: new Date(currentDate),
            startDate: new Date(currentDate),
            endDate: new Date(currentDate),
            startDateQuery:moment(currentDate).format("YYYY-MM-DD"),
            endDateQuery: moment(currentDate).format("YYYY-MM-DD"),
            term: 'all',
            dateIsOpen: false,
            promotionImgD: "",
            promotionImgM: ""
        };


        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClickTerm = this.handleClickTerm.bind(this);
    }

  handleChangeStart(date) {
    this.setState({
      startDate:date,
      startDateQuery:moment(date).format("YYYY-MM-DD")
    })
    var now = moment(date).format("YYYY-MM-DD");
    var dateCheck = moment(this.state.endDateQuery).unix();
    now = moment(now).unix();
    if (now > dateCheck) {
        this.setState({
            endDate:date,
            endDateQuery:moment(date).format("YYYY-MM-DD")
        })
    }


  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date,
      endDateQuery:moment(date).format("YYYY-MM-DD")
    })
  }

  handleClickTerm(term,e) {
    this.setState({
      term: term
    })
  }


  handleSubmit(e) {
    e.preventDefault();
    console.log(e.target.startDate.value)
    var type = e.target.leasingType.value;
    var endDate = "";
    var count = "";
    if(type == "daily"){
        count = e.target.noDays.value;
        var checkCurrentDay = moment(e.target.startDate.value);
        if(checkCurrentDay.day() == 5 && count < 5) {
            var noDays = 3;
            count = "";
            type = "weekend";
            endDate = moment(e.target.startDate.value).add(noDays, 'days').format("YYYY-MM-DD");
        }else{
            var noDays = e.target.noDays.value;
                noDays = parseInt(noDays);
            endDate = moment(e.target.startDate.value).add(noDays, 'days').format("YYYY-MM-DD");

            var checkCurrentDay = moment(new Date(endDate));
            if(checkCurrentDay.day() == 6 || checkCurrentDay.day() == 0) {
                store.addNotification({
                    title: "Oops!",
                    message: "Saturday/Sunday is not available as End date",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: false
                    }
                });

                return false;
            }
        }



    }else if(type == "weekend"){
        var noDays = 3;
        endDate = moment(e.target.startDate.value).add(noDays, 'days').format("YYYY-MM-DD");
    }else if(type == "weekly"){
        count = e.target.noWeeks.value;
        var noWeeks = e.target.noWeeks.value;
            noWeeks = parseInt(noWeeks) * 7;
            noWeeks = noWeeks;
        endDate = moment(e.target.startDate.value).add(noWeeks, 'days').format("YYYY-MM-DD");
    }else if(type == "monthly"){
        count = e.target.noMonths.value;
        endDate = moment(e.target.startDate.value).add(count, 'months').format("YYYY-MM-DD");
        endDate = moment(endDate).subtract(1, 'days').format("YYYY-MM-DD");
    }else if(type == "1-year"){
        endDate = moment(e.target.startDate.value).add(1, 'years').format("YYYY-MM-DD");
        endDate = moment(endDate).subtract(1, 'days').format("YYYY-MM-DD");
    }else if(type == "2-years"){
        endDate = moment(e.target.startDate.value).add(2, 'years').format("YYYY-MM-DD");
        endDate = moment(endDate).subtract(1, 'days').format("YYYY-MM-DD");
    }

    var startDate = moment(e.target.startDate.value).format("YYYY-MM-DD")
    window.location.href = "/vehicles/van-rental?start="+startDate+"&end="+endDate+"&type="+type+"&count="+count;

  }

  componentDidMount() {
      window.scrollTo(0, 0);
      var bodyFormData = new FormData();
      var token = localStorage.getItem('token');
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      axios({
        method: 'post',
        url: 'https://api.earental.com/listing/check_for_promotion?c='+moment().unix(),
        data: bodyFormData,
        withCredentials: true,
        headers: {'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {

        if(parseInt(response.data.with_promo) > 0){
            this.setState({
                promotionImgD: bannerPromoD05,
                promotionImgM: bannerPromoM05
            });

        }else{
            this.setState({
                promotionImgD: imgPromotion,
                promotionImgM: imgPromotion
            });

        }

        // window.scrollTo(0, 0);
      })
      .catch((error) => {
          this.setState({
              promotionImgD: imgPromotion,
              promotionImgM: imgPromotion
          });
      });
  }

    handleDateChange = (date) => {
        console.log(date)
        this.setState({
          startDate:date,
          startDateQuery:moment(date).format("YYYY-MM-DD")
        })
    };


  render() {
    function StyledRadio(props) {
        const classes = useStyles();

        return (
            <Radio
              className={classes.root}
              disableRipple
              color="default"
              checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
              icon={<span className={classes.icon} />}
              {...props}
            />
        );
    }

    function disableWeekDays(date) {
        return date.getDay() === 6 || date.getDay() === 0;
    }

    function disableWeekEnd(date) {
        return date.getDay() === 1 || date.getDay() === 2 || date.getDay() === 3 || date.getDay() === 4 || date.getDay() === 6 || date.getDay() === 0;
    }

    function SearchForm(props){
        const classes = useStyles();
        const [isOpen, setIsOpen] = React.useState(false);
        const [selectedDate, setSelectedDate] =  React.useState(null);
        const [selectedDateWeekEnd, setSelectedDateWeekEnd] =  React.useState(null);
        const [selectedDateWeekly, setSelectedDateWeekly] =  React.useState(null);
        const [selectedDateMonthly, setSelectedDateMonthly] =  React.useState(null);
        const [noDays, setNoDays] =  React.useState(null);
        const [type, setType] = React.useState("monthly");
        const [calendarNote, setCalendarNote] = React.useState("Note: Saturday/Sunday not available as start/end date");
        const handleDateChange = (date) => {
            if(selectedDate !== null){
                setIsOpen(false);
            }
            setSelectedDate(date);

        };

        const handleDateChangeWeekEnd = (date) => {
            if(selectedDateWeekEnd !== null){
                setIsOpen(false);
            }
            setSelectedDateWeekEnd(date);
        };

        const handleDateChangeWeekly = (date) => {
            if(selectedDateWeekly !== null){
                setIsOpen(false);
            }
            setSelectedDateWeekly(date);
        };

        const handleDateChangeMonthly = (date) => {
            if(selectedDateMonthly !== null){
                setIsOpen(false);
            }
            setSelectedDateMonthly(date);
        };

        const handlePasteChange = (e) => {
            e.preventDefault();
        }

        const handleDaysChange = (e) => {
            e.preventDefault();
            if(e.target.value == ""){
                setNoDays("");
            }else{
                if(parseInt(e.target.value) <= 0 || isNaN(parseInt(e.target.value))){
                    setNoDays(1);
                }else{
                    setNoDays(e.target.value);
                }
            }

        }


       const days = [
       ];

       const months = [
           { title: "1" },
           { title: "2" },
           { title: "3" },
           { title: "4" },
           { title: "5" },
           { title: "6" },
           { title: "7" },
           { title: "8" },
           { title: "9" },
           { title: "10" },
           { title: "11" },
       ];

       const handleTypeChange = (e) => {
           setType(e.target.value)
           if(e.target.value == "daily"){
               setCalendarNote("Note: Saturday/Sunday not available as start/end date");
           }else if(e.target.value == "weekend"){
               setCalendarNote("Note: Weekend Fri – Mon (3 days)");
           }else if(e.target.value == "weekly"){
               setCalendarNote("Note: Saturday/Sunday not available as start/end date");
           }else if(e.target.value == "monthly"){
               setCalendarNote("Note: Saturday/Sunday not available as start/end date");
           }else if(e.target.value == "1-year"){
               setCalendarNote("Note: Saturday/Sunday not available as start/end date");
           }else if(e.target.value == "2-years"){
               setCalendarNote("Note: Saturday/Sunday not available as start/end date");
           }
       };


      return(
          <Grid container className={classes.newCardForm}>
            <Grid item md={4} sm={5} xs={5}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Leasing Type</FormLabel>
                    <RadioGroup defaultValue={type} value={type} aria-label="leasing-type" onChange={handleTypeChange} name="leasingType">
                        <FormControlLabel value="daily" control={<StyledRadio />} label="Daily" />
                        <FormControlLabel value="weekend" control={<StyledRadio />} label="Weekend" />
                        <FormControlLabel value="weekly" control={<StyledRadio />} label="Weekly" />
                        <FormControlLabel value="monthly" control={<StyledRadio />} label="Monthly" />
                        <FormControlLabel value="1-year" control={<StyledRadio />} label="1-Year Lease" />
                        <FormControlLabel value="2-years" control={<StyledRadio />} label="2-Year Lease" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item md={8} sm={7} xs={7}  className="home-search-right">
                <i>{calendarNote}</i>
                <Grid item md={12} sm={12} xs={12}>
                    <br />
                    {type == "weekend" && (
                        <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                            <KeyboardDatePicker
                                required
                                autoOk={true}
                                disablePast
                                variant="inline"
                                label="Start Date"
                                format="MMM d, yyyy"
                                name="startDate"
                                className="startDate"
                                minDate={props.homeState.minDate}
                                shouldDisableDate={disableWeekEnd}
                                value={selectedDateWeekEnd}
                                defaultValue={selectedDateWeekEnd}
                                onChange={handleDateChangeWeekEnd}
                                fullWidth
                                className="home-date-picker"
                                KeyboardButtonProps={{
                                'aria-label': 'change date',
                                }}
                                KeyboardButtonProps={{
                                onFocus: e => {
                                    setIsOpen(true);
                                }
                                }}
                                PopoverProps={{
                                disableRestoreFocus: true,
                                onClose: () => {
                                    setIsOpen(false);
                                }
                                }}
                                InputProps={{
                                onFocus: () => {
                                    setIsOpen(true);
                                }
                                }}
                                open={isOpen}

                            />
                        </MuiPickersUtilsProvider>
                    )}

                     {type == "daily" && (
                         <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                             <KeyboardDatePicker
                                 required
                                 autoOk={true}
                                 disablePast
                                 variant="inline"
                                 label="Start Date"
                                 format="MMM d, yyyy"
                                 name="startDate"
                                 minDate={props.homeState.minDate}
                                 shouldDisableDate={disableWeekDays}
                                 className="startDate"
                                 value={selectedDate}
                                 defaultValue={selectedDate}
                                 onChange={handleDateChange}
                                 fullWidth
                                 className="home-date-picker"
                                 KeyboardButtonProps={{
                                 'aria-label': 'change date',
                                 }}
                                 KeyboardButtonProps={{
                                     onFocus: e => {
                                         setIsOpen(true);
                                     }
                                 }}
                                 PopoverProps={{
                                     disableRestoreFocus: true,
                                     onClose: () => {
                                         setIsOpen(false);
                                     }
                                 }}
                                 InputProps={{
                                     onFocus: () => {
                                       setIsOpen(true);
                                     }
                                 }}
                                 open={isOpen}

                             />
                         </MuiPickersUtilsProvider>
                    )}
                    {type == "weekly" && (
                      <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                          <KeyboardDatePicker
                              required
                              autoOk={true}
                              disablePast
                              variant="inline"
                              label="Start Date"
                              format="MMM d, yyyy"
                              name="startDate"
                              minDate={props.homeState.minDate}
                              shouldDisableDate={disableWeekDays}
                              className="startDate"
                              value={selectedDateWeekly}
                              defaultValue={selectedDateWeekly}
                              onChange={handleDateChangeWeekly}
                              fullWidth
                              className="home-date-picker"
                              KeyboardButtonProps={{
                              'aria-label': 'change date',
                              }}
                              KeyboardButtonProps={{
                                  onFocus: e => {
                                      setIsOpen(true);
                                  }
                              }}
                              PopoverProps={{
                                  disableRestoreFocus: true,
                                  onClose: () => {
                                      setIsOpen(false);
                                  }
                              }}
                              InputProps={{
                                  onFocus: () => {
                                    setIsOpen(true);
                                  }
                              }}
                              open={isOpen}

                          />
                      </MuiPickersUtilsProvider>
                    )}

                    {(type == "monthly" || type == "1-year" || type == "2-years" ) && (
                        <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                           <KeyboardDatePicker
                               required
                               autoOk={true}
                               disablePast
                               variant="inline"
                               label="Start Date"
                               format="MMM d, yyyy"
                               name="startDate"
                               minDate={props.homeState.minDate}
                               shouldDisableDate={disableWeekDays}
                               className="startDate"
                               value={selectedDateMonthly}
                               onChange={handleDateChangeMonthly}
                               fullWidth
                               className="home-date-picker"
                               KeyboardButtonProps={{
                               'aria-label': 'change date',
                               }}
                               KeyboardButtonProps={{
                                   onFocus: e => {
                                       setIsOpen(true);
                                   }
                               }}
                               PopoverProps={{
                                   disableRestoreFocus: true,
                                   onClose: () => {
                                       setIsOpen(false);
                                   }
                               }}
                               InputProps={{
                                   onFocus: () => {
                                     setIsOpen(true);
                                   }
                               }}
                               open={isOpen}

                           />
                        </MuiPickersUtilsProvider>
                    )}
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <br />
                    {type == "daily" && (
                         <TextField
                             label="No. of days"
                             type="number"
                             name="noDays"
                             value={noDays}
                             className="home-no-days"
                             InputProps={{
                              inputProps: { min: 1},
                            }}
                            onChange={handleDaysChange}
                            onPaste={handlePasteChange}
                             fullWidth
                             required
                         />
                    )}

                    {type == "weekly" && (
                         <TextField
                             label="No. of weeks"
                             type="number"
                             name="noWeeks"
                             value={noDays}
                             className="home-no-days"
                             InputProps={{
                              inputProps: { min: 1 }
                            }}
                            onChange={handleDaysChange}
                            onPaste={handlePasteChange}
                             fullWidth
                             required
                         />
                    )}

                    {type == "monthly" && (
                        <Autocomplete
                           freeSolo={false}
                           options={months.map((option) => option.title)}
                           renderInput={(params) => (
                             <TextField {...params} label="No. of months" name="noMonths" type="number" className="home-no-days" required />
                           )}
                         />
                    )}

                </Grid>
                <Grid item md={12} sm={12} xs={12} align="center">
                    <br />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        endIcon={<SearchIcon>Start Search</SearchIcon>}
                        >
                        Start Search
                    </Button>
                </Grid>
            </Grid>

          </Grid>
      );
    }


    function TabPanel(props) {
      const { children, value, index, ...other } = props;

      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box p={3}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
    }

    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    };

    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }

    function disableWeekDays(date) {
        return date.getDay() === 6 || date.getDay() === 0;
    }

    function disableWeekEnd(date) {
        return date.getDay() === 1 || date.getDay() === 2 || date.getDay() === 3 || date.getDay() === 4 || date.getDay() === 6 || date.getDay() === 0;
    }

    function SimpleTabs(props) {
      const [value, setValue] = React.useState(0);

      const classes = useStyles();
      const [isOpen, setIsOpen] = React.useState(false);

      const [currentStartDate, setCurrentStartDate] =  React.useState(null);
      const [selectedDate, setSelectedDate] =  React.useState(null);
      const [selectedDateWeekEnd, setSelectedDateWeekEnd] =  React.useState(null);
      const [selectedDateWeekly, setSelectedDateWeekly] =  React.useState(null);
      const [selectedDateMonthly, setSelectedDateMonthly] =  React.useState(null);
      const [noDays, setNoDays] =  React.useState(null);
      const [type, setType] = React.useState(null);
      const [calendarNote, setCalendarNote] = React.useState("Note: Saturday/Sunday not available as start/end date");
      const handleDateChange = (date) => {
          if(selectedDate !== null){
              setIsOpen(false);
          }
          setSelectedDate(date);
          setCurrentStartDate(date);

      };

      const handleDateChangeWeekEnd = (date) => {
          if(selectedDateWeekEnd !== null){
              setIsOpen(false);
          }
          setSelectedDateWeekEnd(date);
          setCurrentStartDate(date);
      };

      const handleDateChangeWeekly = (date) => {
          if(selectedDateWeekly !== null){
              setIsOpen(false);
          }
          setSelectedDateWeekly(date);
          setCurrentStartDate(date);
      };

      const handleDateChangeMonthly = (date) => {
          if(selectedDateMonthly !== null){
              setIsOpen(false);
          }
          setSelectedDateMonthly(date);
          setCurrentStartDate(date);

          if(type == "1-year"){
              setNoDays(1);
          }else if(type == "2-year"){
              setNoDays(1);
          }
      };

      const handlePasteChange = (e) => {
          e.preventDefault();
      }

      const handleDaysChange = (e) => {
          e.preventDefault();
          if(e.target.value == ""){
              setNoDays(null);
          }else{
              if(parseInt(e.target.value) <= 0 || isNaN(parseInt(e.target.value))){
                  setNoDays(1);
              }else{
                  setNoDays(e.target.value);
              }
          }

      }

      const handleMonthlyCountChange = (e,values) => {
          setNoDays(values);
      };


     const days = [
     ];

     const months = [
         { title: "1" },
         { title: "2" },
         { title: "3" },
         { title: "4" },
         { title: "5" },
         { title: "6" },
         { title: "7" },
         { title: "8" },
         { title: "9" },
         { title: "10" },
         { title: "11" },
     ];

     const handleTypeChange = (typeVal) => {
         setType(typeVal);
         setCurrentStartDate(null);
         setSelectedDate(null);
         setSelectedDateWeekEnd(null);
         setSelectedDateWeekly(null);
         setSelectedDateMonthly(null);
         setNoDays(null);
         if(typeVal == "daily"){
             setCalendarNote("Note: Saturday/Sunday not available as start/end date");
         }else if(typeVal == "weekend"){
             setCalendarNote("Note: Weekend Fri – Mon (3 days)");
         }else if(typeVal == "weekly"){
             setCalendarNote("Note: Saturday/Sunday not available as start/end date");
         }else if(typeVal == "monthly"){
             setCalendarNote("Note: Saturday/Sunday not available as start/end date");
         }else if(typeVal == "1-year"){
             setCalendarNote("Note: Saturday/Sunday not available as start/end date");
         }else if(typeVal == "2-years"){
             setCalendarNote("Note: Saturday/Sunday not available as start/end date");
         }
         setValue(1);
     };

      const handleChange1 = () => {
        setValue(0);
      };

      const handleChange2 = () => {
        setValue(1);
      };

      const handleChange3 = () => {
        setValue(2);
      };

      const handleQuote = () => {
          var endDate = "";
          var count = "";
          var currentType = type;
          if(currentStartDate == null){
              store.addNotification({
                  title: "Oops!",
                  message: "Please select your start date to proceed.",
                  type: "warning",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                      duration: 5000,
                      onScreen: false
                  }
              });
              setValue(1);
              return false;
          }

          if(currentStartDate == null){
              store.addNotification({
                  title: "Oops!",
                  message: "Please enter how long do you want to rent for.",
                  type: "warning",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                      duration: 5000,
                      onScreen: false
                  }
              });
              return false;
          }
          if(type == "daily"){
              count = noDays;
              var checkCurrentDay = moment(currentStartDate);
              if(checkCurrentDay.day() == 5 && count < 5) {
                  count = "";
                  setType('weekend');
                  currentType = "weekend";
                  endDate = moment(currentStartDate).add(3, 'days').format("YYYY-MM-DD");
              }else{
                  endDate = moment(currentStartDate).add(noDays, 'days').format("YYYY-MM-DD");

                  var checkCurrentDay = moment(new Date(endDate));
                  if(checkCurrentDay.day() == 6 || checkCurrentDay.day() == 0) {
                      store.addNotification({
                          title: "Oops!",
                          message: "Saturday/Sunday is not available as End date",
                          type: "warning",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animated", "fadeIn"],
                          animationOut: ["animated", "fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: false
                          }
                      });

                      return false;
                  }
              }

          }else if(type == "weekend"){
              endDate = moment(currentStartDate).add(3, 'days').format("YYYY-MM-DD");
          }else if(type == "weekly"){
              count = noDays;
              var noWeeks = parseInt(noDays) * 7;
              noWeeks = noWeeks;
              endDate = moment(currentStartDate).add(noWeeks, 'days').format("YYYY-MM-DD");
          }else if(type == "monthly"){
              count = noDays;
              endDate = moment(currentStartDate).add(noDays, 'months').format("YYYY-MM-DD");
              endDate = moment(endDate).subtract(1, 'days').format("YYYY-MM-DD");
          }else if(type == "1-year"){
              endDate = moment(currentStartDate).add(1, 'years').format("YYYY-MM-DD");
              endDate = moment(endDate).subtract(1, 'days').format("YYYY-MM-DD");
          }else if(type == "2-years"){
              endDate = moment(currentStartDate).add(2, 'years').format("YYYY-MM-DD");
              endDate = moment(endDate).subtract(1, 'days').format("YYYY-MM-DD");
          }

          var startDate = moment(currentStartDate).format("YYYY-MM-DD")
          window.location.href = "/vehicles/van-rental?start="+startDate+"&end="+endDate+"&type="+currentType+"&count="+count;
      };


      return (
        <div >
            <input type="hidden" name="leasingType" value={type} />
            <ul className="process-steps text-center row" style={{paddingLeft:"0px",margin:"0px"}}>
                <li className="col-sm-4 col-lg-4 ">
                    <a>
                        {type && (
                          <i className="box-icon-gray box-icon-center round box-icon-border  box-icon-info box-icon-big  round active-prog">1</i>
                        )}
                        {!type && (
                            <i className="box-icon-gray box-icon-center round box-icon-border  box-icon-info box-icon-big  round ">1</i>

                        )}

                    </a>
                </li>
                <li className="col-sm-4 col-lg-4">
                    <a>
                        {currentStartDate && (
                          <i className="box-icon-gray box-icon-center round box-icon-border  box-icon-info box-icon-big  round active-prog">2</i>
                        )}
                        {!currentStartDate && (
                            <i className="box-icon-gray box-icon-center round box-icon-border  box-icon-info box-icon-big  round">2</i>

                        )}
                    </a>
                </li>
                <li className="col-sm-4 col-lg-4">
                    <a>
                        {noDays && (
                          <i className="box-icon-gray box-icon-center round box-icon-border  box-icon-info box-icon-big  round active-prog">3</i>
                        )}
                        {!noDays && (
                            <i className="box-icon-gray box-icon-center round box-icon-border  box-icon-info box-icon-big  round">3</i>

                        )}
                    </a>
                </li>
            </ul>
            <SwipeableViews
              index={value}
            >
              <TabPanel value={value} index={0} dir={"x"}>
                  <div id="ptab1">
                      <h4 className="mb30">1. How do you like to rent?</h4>
                      <div className="mb10 " data-toggle="buttons">
                          <label className="btn btn-primary btn-ghost" style={{borderRadius:"20px",marginRight:"5px"}} onClick={() => handleTypeChange("daily")}>
                              <input type="radio" name="options" value="daily" onClick={handleTypeChange} />Daily
                          </label>
                          <label className="btn btn-primary btn-ghost" style={{borderRadius:"20px",marginRight:"5px"}} onClick={() => handleTypeChange("weekend")}>
                              <input type="radio" name="options" value="weekend" onClick={handleTypeChange}  />Weekend
                          </label>
                          <label className="btn btn-primary btn-ghost" style={{borderRadius:"20px",marginRight:"5px"}} onClick={() => handleTypeChange("weekly")}>
                              <input type="radio" name="options" value="weekly" onClick={handleTypeChange}  />Weekly
                          </label>
                          <label className="btn btn-primary btn-ghost" style={{borderRadius:"20px",marginRight:"5px"}} onClick={() => handleTypeChange("monthly")}>
                              <input type="radio" name="options" value="monthly" onClick={handleTypeChange}  />Monthly
                          </label>
                          <br/><br/>
                          <label className="btn btn-primary btn-ghost" style={{borderRadius:"20px",marginRight:"5px"}} onClick={() => handleTypeChange("1-year")}>
                              <input type="radio" name="options" value="1-year" onClick={handleTypeChange}  />1-Year Lease
                          </label>
                          <label className="btn btn-primary btn-ghost" style={{borderRadius:"20px",marginRight:"5px"}} onClick={() => handleTypeChange("2-years")}>
                              <input type="radio" name="options" value="2-years" onClick={handleTypeChange}  />2-Year Lease
                          </label>
                      </div>
                  </div>
              </TabPanel>
              <TabPanel value={value} index={1} dir={"x"}>

                  <h4 className="mb30">2. When do you want to start the rental?</h4>
                  <div className="row row-wrap" >
                      <div className="col-md-12">
                      {type == "weekend" && (
                          <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                              <KeyboardDatePicker
                                  required
                                  autoOk={true}
                                  disablePast
                                  variant="inline"
                                  label="Start Date"
                                  format="MMM d, yyyy"
                                  name="startDate"
                                  className="startDate"
                                  minDate={props.homeState.minDate}
                                  shouldDisableDate={disableWeekEnd}
                                  value={selectedDateWeekEnd}
                                  defaultValue={selectedDateWeekEnd}
                                  onChange={handleDateChangeWeekEnd}
                                  fullWidth
                                  className="home-date-picker"
                                  KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                  }}
                                  KeyboardButtonProps={{
                                  onFocus: e => {
                                      setIsOpen(true);
                                  }
                                  }}
                                  PopoverProps={{
                                  disableRestoreFocus: true,
                                  onClose: () => {
                                      setIsOpen(false);
                                  }
                                  }}
                                  InputProps={{
                                  onFocus: () => {
                                      setIsOpen(true);
                                  }
                                  }}
                                  open={isOpen}

                              />
                          </MuiPickersUtilsProvider>
                      )}

                       {type == "daily" && (
                           <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                               <KeyboardDatePicker
                                   required
                                   autoOk={true}
                                   disablePast
                                   variant="inline"
                                   label="Start Date"
                                   format="MMM d, yyyy"
                                   name="startDate"
                                   minDate={props.homeState.minDate}
                                   shouldDisableDate={disableWeekDays}
                                   className="startDate"
                                   value={selectedDate}
                                   defaultValue={selectedDate}
                                   onChange={handleDateChange}
                                   fullWidth
                                   className="home-date-picker"
                                   KeyboardButtonProps={{
                                   'aria-label': 'change date',
                                   }}
                                   KeyboardButtonProps={{
                                       onFocus: e => {
                                           setIsOpen(true);
                                       }
                                   }}
                                   PopoverProps={{
                                       disableRestoreFocus: true,
                                       onClose: () => {
                                           setIsOpen(false);
                                       }
                                   }}
                                   InputProps={{
                                       onFocus: () => {
                                         setIsOpen(true);
                                       }
                                   }}
                                   open={isOpen}

                               />
                           </MuiPickersUtilsProvider>
                      )}
                      {type == "weekly" && (
                        <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                            <KeyboardDatePicker
                                required
                                autoOk={true}
                                disablePast
                                variant="inline"
                                label="Start Date"
                                format="MMM d, yyyy"
                                name="startDate"
                                minDate={props.homeState.minDate}
                                shouldDisableDate={disableWeekDays}
                                className="startDate"
                                value={selectedDateWeekly}
                                defaultValue={selectedDateWeekly}
                                onChange={handleDateChangeWeekly}
                                fullWidth
                                className="home-date-picker"
                                KeyboardButtonProps={{
                                'aria-label': 'change date',
                                }}
                                KeyboardButtonProps={{
                                    onFocus: e => {
                                        setIsOpen(true);
                                    }
                                }}
                                PopoverProps={{
                                    disableRestoreFocus: true,
                                    onClose: () => {
                                        setIsOpen(false);
                                    }
                                }}
                                InputProps={{
                                    onFocus: () => {
                                      setIsOpen(true);
                                    }
                                }}
                                open={isOpen}

                            />
                        </MuiPickersUtilsProvider>
                      )}

                      {(type == "monthly" || type == "1-year" || type == "2-years" ) && (
                          <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                             <KeyboardDatePicker
                                 required
                                 autoOk={true}
                                 disablePast
                                 variant="inline"
                                 label="Start Date"
                                 format="MMM d, yyyy"
                                 name="startDate"
                                 minDate={props.homeState.minDate}
                                 shouldDisableDate={disableWeekDays}
                                 className="startDate"
                                 value={selectedDateMonthly}
                                 onChange={handleDateChangeMonthly}
                                 fullWidth
                                 className="home-date-picker"
                                 KeyboardButtonProps={{
                                 'aria-label': 'change date',
                                 }}
                                 KeyboardButtonProps={{
                                     onFocus: e => {
                                         setIsOpen(true);
                                     }
                                 }}
                                 PopoverProps={{
                                     disableRestoreFocus: true,
                                     onClose: () => {
                                         setIsOpen(false);
                                     }
                                 }}
                                 InputProps={{
                                     onFocus: () => {
                                       setIsOpen(true);
                                     }
                                 }}
                                 open={isOpen}

                             />
                          </MuiPickersUtilsProvider>
                      )}
                      </div>
                  </div>
                  <div className="line line-sm mb-4"></div>
                  <a onClick={handleChange1 } className="btn btn-primary tab-linker" style={{borderRadius: "20px",backgroundColor:"#21697e",borderColor:"#21697e",marginRight:"5px"}}  rel="1"><i className="fa fa-angle-left"></i> Back</a>
                  <a onClick={handleChange3 } className="btn btn-primary float-right tab-linker" style={{borderRadius: "20px",backgroundColor:"#d40029",borderColor:"#d40029"}} rel="3">Next <i className="fa fa-angle-right"></i></a>

              </TabPanel>
              <TabPanel value={value} index={2} dir={"x"}>
                  <div id="ptab3">
                      <h4 className="mb30">3. How long do you want to rent for? </h4>
                      <div className="mb10 row row-wrap">
                          <div className="col-md-12">
                              {type == "daily" && (
                                   <TextField
                                       label="No. of days"
                                       type="number"
                                       name="noDays"
                                       value={noDays}
                                       className="home-no-days"
                                       InputProps={{
                                        inputProps: { min: 1},
                                      }}
                                      onChange={handleDaysChange}
                                      onPaste={handlePasteChange}
                                       fullWidth
                                       required
                                   />
                              )}

                              {type == "weekly" && (
                                   <TextField
                                       label="No. of weeks"
                                       type="number"
                                       name="noWeeks"
                                       value={noDays}
                                       className="home-no-days"
                                       InputProps={{
                                        inputProps: { min: 1 }
                                      }}
                                      onChange={handleDaysChange}
                                      onPaste={handlePasteChange}
                                       fullWidth
                                       required
                                   />
                              )}

                              {type == "monthly" && (
                                  <Autocomplete
                                     freeSolo={false}
                                     onChange={handleMonthlyCountChange}
                                     options={months.map((option) => option.title)}
                                     renderInput={(params) => (
                                       <TextField {...params} label="No. of months" name="noMonths" type="number" className="home-no-days" required />
                                     )}
                                   />
                              )}

                              {type == "weekend" && (
                                  <h3>Weekend</h3>
                              )}
                              {type == "1-year" && (
                                  <h3>1 Year</h3>
                              )}
                              {type == "2-years" && (
                                  <h3>2 Years</h3>
                              )}
                          </div>
                      </div>
                      <div className="line line-sm mb-4"></div>
                      <a onClick={handleChange2}  className="btn btn-primary tab-linker" style={{borderRadius: "20px",backgroundColor:"#21697e",borderColor:"#21697e",marginRight:"5px"}} rel="2"><i className="fa fa-angle-left"></i> Back</a>

                      <Button type="submit"className="btn btn-primary tab-linker" style={{borderRadius: "20px",backgroundColor:"#d40029",borderColor:"#d40029",color:"#fff"}} rel="" onClick={handleQuote}>Get Quote Now &nbsp; <i className="fa fa-search"></i></Button>
                  </div>
              </TabPanel>
            </SwipeableViews>
        </div>
      );
    }
    function SimpleTabsM(props) {
      const [value, setValue] = React.useState(0);

      const classes = useStyles();
      const [isOpen, setIsOpen] = React.useState(false);

      const [currentStartDate, setCurrentStartDate] =  React.useState(null);
      const [selectedDate, setSelectedDate] =  React.useState(null);
      const [selectedDateWeekEnd, setSelectedDateWeekEnd] =  React.useState(null);
      const [selectedDateWeekly, setSelectedDateWeekly] =  React.useState(null);
      const [selectedDateMonthly, setSelectedDateMonthly] =  React.useState(null);
      const [noDays, setNoDays] =  React.useState(null);
      const [type, setType] = React.useState(null);
      const [calendarNote, setCalendarNote] = React.useState("Note: Saturday/Sunday not available as start/end date");
      const handleDateChange = (date) => {
          if(selectedDate !== null){
              setIsOpen(false);
          }
          setSelectedDate(date);
          setCurrentStartDate(date);

      };

      const handleDateChangeWeekEnd = (date) => {
          if(selectedDateWeekEnd !== null){
              setIsOpen(false);
          }
          setSelectedDateWeekEnd(date);
          setCurrentStartDate(date);
      };

      const handleDateChangeWeekly = (date) => {
          if(selectedDateWeekly !== null){
              setIsOpen(false);
          }
          setSelectedDateWeekly(date);
          setCurrentStartDate(date);
      };

      const handleDateChangeMonthly = (date) => {
          if(selectedDateMonthly !== null){
              setIsOpen(false);
          }
          setSelectedDateMonthly(date);
          setCurrentStartDate(date);

          if(type == "1-year"){
              setNoDays(1);
          }else if(type == "2-year"){
              setNoDays(1);
          }
      };

      const handlePasteChange = (e) => {
          e.preventDefault();
      }

      const handleDaysChange = (e) => {
          e.preventDefault();
          if(e.target.value == ""){
              setNoDays(null);
          }else{
              if(parseInt(e.target.value) <= 0 || isNaN(parseInt(e.target.value))){
                  setNoDays(1);
              }else{
                  setNoDays(e.target.value);
              }
          }

      }

      const handleMonthlyCountChange = (e,values) => {
          setNoDays(values);
      };


     const days = [
     ];

     const months = [
         { title: "1" },
         { title: "2" },
         { title: "3" },
         { title: "4" },
         { title: "5" },
         { title: "6" },
         { title: "7" },
         { title: "8" },
         { title: "9" },
         { title: "10" },
         { title: "11" },
     ];

     const handleTypeChange = (typeVal) => {
         setType(typeVal);
         setCurrentStartDate(null);
         setSelectedDate(null);
         setSelectedDateWeekEnd(null);
         setSelectedDateWeekly(null);
         setSelectedDateMonthly(null);
         setNoDays(null);
         if(typeVal == "daily"){
             setCalendarNote("Note: Saturday/Sunday not available as start/end date");
         }else if(typeVal == "weekend"){
             setCalendarNote("Note: Weekend Fri – Mon (3 days)");
         }else if(typeVal == "weekly"){
             setCalendarNote("Note: Saturday/Sunday not available as start/end date");
         }else if(typeVal == "monthly"){
             setCalendarNote("Note: Saturday/Sunday not available as start/end date");
         }else if(typeVal == "1-year"){
             setCalendarNote("Note: Saturday/Sunday not available as start/end date");
         }else if(typeVal == "2-years"){
             setCalendarNote("Note: Saturday/Sunday not available as start/end date");
         }
         setValue(1);
     };

      const handleChange1 = () => {
        setValue(0);
      };

      const handleChange2 = () => {
        setValue(1);
      };

      const handleChange3 = () => {
        setValue(2);
      };

      const handleQuote = () => {
          var endDate = "";
          var count = "";
          if(currentStartDate == null){
              store.addNotification({
                  title: "Oops!",
                  message: "Please select your start date to proceed.",
                  type: "warning",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                      duration: 5000,
                      onScreen: false
                  }
              });
              setValue(1);
              return false;
          }

          if(currentStartDate == null){
              store.addNotification({
                  title: "Oops!",
                  message: "Please enter how long do you want to rent for.",
                  type: "warning",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                      duration: 5000,
                      onScreen: false
                  }
              });
              return false;
          }
          if(type == "daily"){
              count = noDays;
              var checkCurrentDay = moment(currentStartDate);
              if(checkCurrentDay.day() == 5 && count < 5) {
                  count = "";
                  type = "weekend";
                  endDate = moment(currentStartDate).add(3, 'days').format("YYYY-MM-DD");
              }else{
                  endDate = moment(currentStartDate).add(noDays, 'days').format("YYYY-MM-DD");

                  var checkCurrentDay = moment(new Date(endDate));
                  if(checkCurrentDay.day() == 6 || checkCurrentDay.day() == 0) {
                      store.addNotification({
                          title: "Oops!",
                          message: "Saturday/Sunday is not available as End date",
                          type: "warning",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animated", "fadeIn"],
                          animationOut: ["animated", "fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: false
                          }
                      });

                      return false;
                  }
              }

          }else if(type == "weekend"){
              endDate = moment(currentStartDate).add(3, 'days').format("YYYY-MM-DD");
          }else if(type == "weekly"){
              count = noDays;
              var noWeeks = parseInt(noDays) * 7;
              noWeeks = noWeeks;
              endDate = moment(currentStartDate).add(noWeeks, 'days').format("YYYY-MM-DD");
          }else if(type == "monthly"){
              count = noDays;
              endDate = moment(currentStartDate).add(noDays, 'months').format("YYYY-MM-DD");
              endDate = moment(endDate).subtract(1, 'days').format("YYYY-MM-DD");
          }else if(type == "1-year"){
              endDate = moment(currentStartDate).add(1, 'years').format("YYYY-MM-DD");
              endDate = moment(endDate).subtract(1, 'days').format("YYYY-MM-DD");
          }else if(type == "2-years"){
              endDate = moment(currentStartDate).add(2, 'years').format("YYYY-MM-DD");
              endDate = moment(endDate).subtract(1, 'days').format("YYYY-MM-DD");
          }

          var startDate = moment(currentStartDate).format("YYYY-MM-DD")
          window.location.href = "/vehicles/van-rental?start="+startDate+"&end="+endDate+"&type="+type+"&count="+count;
      };


      return (
        <div >
            <input type="hidden" name="leasingType" value={type} />
            <ul className="process-steps text-center row" style={{paddingLeft:"0px",margin:"0px"}}>
                <li className="col-xs-4 col-lg-4 ">
                    <a>
                        {type && (
                          <i className="box-icon-gray box-icon-center round box-icon-border  box-icon-info box-icon-md  round active-prog">1</i>
                        )}
                        {!type && (
                            <i className="box-icon-gray box-icon-center round box-icon-border  box-icon-info box-icon-md  round ">1</i>

                        )}

                    </a>
                </li>
                <li className="col-xs-4 col-lg-4">
                    <a>
                        {currentStartDate && (
                          <i className="box-icon-gray box-icon-center round box-icon-border  box-icon-info box-icon-md  round active-prog">2</i>
                        )}
                        {!currentStartDate && (
                            <i className="box-icon-gray box-icon-center round box-icon-border  box-icon-info box-icon-md  round">2</i>

                        )}
                    </a>
                </li>
                <li className="col-xs-4 col-lg-4">
                    <a>
                        {noDays && (
                          <i className="box-icon-gray box-icon-center round box-icon-border  box-icon-info box-icon-md  round active-prog">3</i>
                        )}
                        {!noDays && (
                            <i className="box-icon-gray box-icon-center round box-icon-border  box-icon-info box-icon-md  round">3</i>

                        )}
                    </a>
                </li>
            </ul>
            <SwipeableViews
              index={value}
            >
              <TabPanel value={value} index={0} dir={"x"} style={{padding:"0"}}>
                  <div id="ptab1">
                      <h4 className="mb30 text-center" style={{fontSize:"16px",fontWeight:"400"}}>1. How do you like to rent?</h4>
                      <div className="mb10 text-center" data-toggle="buttons">
                          <label className="btn btn-primary btn-ghost" style={{borderRadius:"20px",marginRight:"10px",marginBottom:"10px"}} onClick={() => handleTypeChange("daily")}>
                              <input type="radio" name="options" value="daily" onClick={handleTypeChange} />Daily
                          </label>
                          <label className="btn btn-primary btn-ghost" style={{borderRadius:"20px",marginRight:"10px",marginBottom:"10px"}} onClick={() => handleTypeChange("weekend")}>
                              <input type="radio" name="options" value="weekend" onClick={handleTypeChange}  />Weekend
                          </label>
                          <label className="btn btn-primary btn-ghost" style={{borderRadius:"20px",marginRight:"10px",marginBottom:"10px"}} onClick={() => handleTypeChange("weekly")}>
                              <input type="radio" name="options" value="weekly" onClick={handleTypeChange}  />Weekly
                          </label>
                          <label className="btn btn-primary btn-ghost" style={{borderRadius:"20px",marginRight:"10px",marginBottom:"10px"}} onClick={() => handleTypeChange("monthly")}>
                              <input type="radio" name="options" value="monthly" onClick={handleTypeChange}  />Monthly
                          </label>
                          <label className="btn btn-primary btn-ghost" style={{borderRadius:"20px",marginRight:"10px",marginBottom:"10px"}} onClick={() => handleTypeChange("1-year")}>
                              <input type="radio" name="options" value="1-year" onClick={handleTypeChange}  />1-Year Lease
                          </label>
                          <label className="btn btn-primary btn-ghost" style={{borderRadius:"20px",marginRight:"10px",marginBottom:"10px"}} onClick={() => handleTypeChange("2-years")}>
                              <input type="radio" name="options" value="2-years" onClick={handleTypeChange}  />2-Year Lease
                          </label>
                      </div>
                  </div>
              </TabPanel>
              <TabPanel value={value} index={1} dir={"x"}>

                  <h4 className="mb30 text-center" style={{fontSize:"16px",fontWeight:"400"}}>2. When do you want to start the rental?</h4>
                  <div className="row row-wrap" >
                      <div className="col-md-12">
                      {type == "weekend" && (
                          <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                              <KeyboardDatePicker
                                  required
                                  autoOk={true}
                                  disablePast
                                  variant="inline"
                                  label="Start Date"
                                  format="MMM d, yyyy"
                                  name="startDate"
                                  className="startDate"
                                  minDate={props.homeState.minDate}
                                  shouldDisableDate={disableWeekEnd}
                                  value={selectedDateWeekEnd}
                                  defaultValue={selectedDateWeekEnd}
                                  onChange={handleDateChangeWeekEnd}
                                  fullWidth
                                  className="home-date-picker"
                                  KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                  }}
                                  KeyboardButtonProps={{
                                  onFocus: e => {
                                      setIsOpen(true);
                                  }
                                  }}
                                  PopoverProps={{
                                  disableRestoreFocus: true,
                                  onClose: () => {
                                      setIsOpen(false);
                                  }
                                  }}
                                  InputProps={{
                                  onFocus: () => {
                                      setIsOpen(true);
                                  }
                                  }}
                                  open={isOpen}

                              />
                          </MuiPickersUtilsProvider>
                      )}

                       {type == "daily" && (
                           <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                               <KeyboardDatePicker
                                   required
                                   autoOk={true}
                                   disablePast
                                   variant="inline"
                                   label="Start Date"
                                   format="MMM d, yyyy"
                                   name="startDate"
                                   minDate={props.homeState.minDate}
                                   shouldDisableDate={disableWeekDays}
                                   className="startDate"
                                   value={selectedDate}
                                   defaultValue={selectedDate}
                                   onChange={handleDateChange}
                                   fullWidth
                                   className="home-date-picker"
                                   KeyboardButtonProps={{
                                   'aria-label': 'change date',
                                   }}
                                   KeyboardButtonProps={{
                                       onFocus: e => {
                                           setIsOpen(true);
                                       }
                                   }}
                                   PopoverProps={{
                                       disableRestoreFocus: true,
                                       onClose: () => {
                                           setIsOpen(false);
                                       }
                                   }}
                                   InputProps={{
                                       onFocus: () => {
                                         setIsOpen(true);
                                       }
                                   }}
                                   open={isOpen}

                               />
                           </MuiPickersUtilsProvider>
                      )}
                      {type == "weekly" && (
                        <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                            <KeyboardDatePicker
                                required
                                autoOk={true}
                                disablePast
                                variant="inline"
                                label="Start Date"
                                format="MMM d, yyyy"
                                name="startDate"
                                minDate={props.homeState.minDate}
                                shouldDisableDate={disableWeekDays}
                                className="startDate"
                                value={selectedDateWeekly}
                                defaultValue={selectedDateWeekly}
                                onChange={handleDateChangeWeekly}
                                fullWidth
                                className="home-date-picker"
                                KeyboardButtonProps={{
                                'aria-label': 'change date',
                                }}
                                KeyboardButtonProps={{
                                    onFocus: e => {
                                        setIsOpen(true);
                                    }
                                }}
                                PopoverProps={{
                                    disableRestoreFocus: true,
                                    onClose: () => {
                                        setIsOpen(false);
                                    }
                                }}
                                InputProps={{
                                    onFocus: () => {
                                      setIsOpen(true);
                                    }
                                }}
                                open={isOpen}

                            />
                        </MuiPickersUtilsProvider>
                      )}

                      {(type == "monthly" || type == "1-year" || type == "2-years" ) && (
                          <MuiPickersUtilsProvider utils={DateFnsUtils} className="">
                             <KeyboardDatePicker
                                 required
                                 autoOk={true}
                                 disablePast
                                 variant="inline"
                                 label="Start Date"
                                 format="MMM d, yyyy"
                                 name="startDate"
                                 minDate={props.homeState.minDate}
                                 shouldDisableDate={disableWeekDays}
                                 className="startDate"
                                 value={selectedDateMonthly}
                                 onChange={handleDateChangeMonthly}
                                 fullWidth
                                 className="home-date-picker"
                                 KeyboardButtonProps={{
                                 'aria-label': 'change date',
                                 }}
                                 KeyboardButtonProps={{
                                     onFocus: e => {
                                         setIsOpen(true);
                                     }
                                 }}
                                 PopoverProps={{
                                     disableRestoreFocus: true,
                                     onClose: () => {
                                         setIsOpen(false);
                                     }
                                 }}
                                 InputProps={{
                                     onFocus: () => {
                                       setIsOpen(true);
                                     }
                                 }}
                                 open={isOpen}

                             />
                          </MuiPickersUtilsProvider>
                      )}
                      </div>
                  </div>
                  <div className="line line-sm mb-4"></div>
                  <div className="text-center">
                      <a onClick={handleChange1 } className="btn btn-primary tab-linker" style={{borderRadius: "20px",backgroundColor:"#21697e",borderColor:"#21697e",marginRight:"5px"}}  rel="1"><i className="fa fa-angle-left"></i> Back</a>
                      <a onClick={handleChange3 } className="btn btn-primary float-right tab-linker" style={{borderRadius: "20px",backgroundColor:"#d40029",borderColor:"#d40029"}} rel="3">Next <i className="fa fa-angle-right"></i></a>
                  </div>

              </TabPanel>
              <TabPanel value={value} index={2} dir={"x"}>
                  <div id="ptab3">
                      <h4 className="mb30 text-center" style={{fontSize:"16px",fontWeight:"400"}}>3. How long do you want to rent for? </h4>
                      <div className="mb10 row row-wrap text-center">
                          <div className="col-md-12">
                              {type == "daily" && (
                                   <TextField
                                       label="No. of days"
                                       type="number"
                                       name="noDays"
                                       value={noDays}
                                       className="home-no-days"
                                       InputProps={{
                                        inputProps: { min: 1},
                                      }}
                                      onChange={handleDaysChange}
                                      onPaste={handlePasteChange}
                                       fullWidth
                                       required
                                   />
                              )}

                              {type == "weekly" && (
                                   <TextField
                                       label="No. of weeks"
                                       type="number"
                                       name="noWeeks"
                                       value={noDays}
                                       className="home-no-days"
                                       InputProps={{
                                        inputProps: { min: 1 }
                                      }}
                                      onChange={handleDaysChange}
                                      onPaste={handlePasteChange}
                                       fullWidth
                                       required
                                   />
                              )}

                              {type == "monthly" && (
                                  <Autocomplete
                                     freeSolo={false}
                                     onChange={handleMonthlyCountChange}
                                     options={months.map((option) => option.title)}
                                     renderInput={(params) => (
                                       <TextField {...params} label="No. of months" name="noMonths" type="number" className="home-no-days" required />
                                     )}
                                   />
                              )}

                              {type == "weekend" && (
                                  <h3>Weekend</h3>
                              )}
                              {type == "1-year" && (
                                  <h3>1 Year</h3>
                              )}
                              {type == "2-years" && (
                                  <h3>2 Years</h3>
                              )}
                          </div>
                      </div>
                      <div className="line line-sm mb-4"></div>
                      <div className="text-center">
                          <a onClick={handleChange2}  className="btn btn-primary tab-linker" style={{borderRadius: "20px",backgroundColor:"#21697e",borderColor:"#21697e",marginRight:"5px"}} rel="2"><i className="fa fa-angle-left"></i> Back</a>
                          <Button type="submit"className="btn btn-primary tab-linker" style={{borderRadius: "20px",backgroundColor:"#d40029",borderColor:"#d40029",color:"#fff"}} rel="" onClick={handleQuote}>Get Quote Now &nbsp; <i className="fa fa-search"></i></Button>
                      </div>

                  </div>
              </TabPanel>
            </SwipeableViews>
        </div>
      );
    }
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="title" content={this.state.MetaTitle} />
                <meta content={this.state.MetaTitle} property="og:title" />
                <meta name="description" content={this.state.MetaDescription} />
                <meta content={this.state.MetaDescription} property="og:description" />
                <meta name="keywords" content={this.state.MetaKeywords} />
                <meta name="robots" content="index, follow" />
                <title>{this.state.MetaTitle}</title>
                <link rel="canonical" href={this.state.Canonical} />
            </Helmet>
            <div className="top-area show-onload">
                <Hidden mdUp>
                    <div>
                            <div className="bg-parallax" style={parallaxBgStyle}></div>

                            <div style={mobileSearchHead} className="bg-front">
            					<div className="mt20 mb20">
            						<img src={this.state.promotionImgM} alt="" title=""style={{width:"100%"}} />
            					</div>
                                <div className="text-center">
                                    <Button
                                        href="/vehicles/van-rental"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        style={{fontSize:"13px",padding:"8px 50px"}}
                                        >
                                        BOOK NOW
                                    </Button>
                                </div>
                                <div className="mt20 mb20 text-center">
                                    <img src={imgVanR} alt="" title="" style={{width:"230px"}} />
                                </div>

            					<div className="" style={{width:"100%"}}>
            						<div className="search-tabs search-tabs-bg">
            							<div className="tabbable">
            								<div className="tab-content">
            									<div className="tab-pane fade in active" style={{borderRadius:"20px"}}>
            										<h3 className="mb30 text-center" style={{fontSize:"25px",fontWeight:"400",color:"#2f96b4"}}>Instant Quotation</h3>
            										<div id="processTabs" data-plugin="tabs" className="customjs">
                                                        <SimpleTabsM homeState={this.state} />
            										</div>
            									</div>
            								</div>
            							</div>
            						</div>
            					</div>
            				</div>
                    </div>
                </Hidden>
                <div className="bg-holder full text-center desktop-home-search">
                    <div className=""></div>
                        <div className="bg-parallax" style={parallaxBgStyle}></div>

                        <div className="bg-front full-center">
        					<div className="mt20 mb20">
        						<img src={this.state.promotionImgD} alt="" title=""style={{width:"700px"}} />
        					</div>
        					<div className="container" style={{width:"600px"}}>
        						<div className="search-tabs search-tabs-bg">
        							<div className="tabbable">
        								<div className="tab-content">
        									<div className="tab-pane fade in active" style={{borderRadius:"20px"}}>
        										<h3 className="mb30">Instant Quotation in 3-Easy Steps</h3>
        										<div id="processTabs" data-plugin="tabs" className="customjs">
                                                    <SimpleTabs homeState={this.state} />
        										</div>
        									</div>
        								</div>
        							</div>
        						</div>
        					</div>
        				</div>
                </div>
            </div>


            <div className="gap"></div>


            <div className="container text-center">
                <div className="row row-wrap">
                    <div className="col-md-4">
                        <div className="thumb">
                            <header className="thumb-header"><i className="fa fa-money box-icon-gray box-icon-center round box-icon-border  box-icon-info box-icon-big animate-icon-border-rise round"></i>
                            </header>
                            <div className="thumb-caption">
                                <h5 className="thumb-title"><a className="text-darken">Best Price Guaranteed</a></h5>
                                <p className="thumb-desc">Competitive, Attractive & Reasonable price</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="thumb">
                            <header className="thumb-header"><i className="fa fa-lock box-icon-gray box-icon-center round box-icon-border  box-icon-info box-icon-big animate-icon-border-rise round"></i>
                            </header>
                            <div className="thumb-caption">
                                <h5 className="thumb-title"><a className="text-darken">Maintainance & Safety</a></h5>
                                <p className="thumb-desc">GPS tracking system, Comprehensive checks</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="thumb">
                            <header className="thumb-header"><i className="fa fa-thumbs-o-up box-icon-gray box-icon-center round box-icon-border  box-icon-info box-icon-big animate-icon-border-rise round"></i>
                            </header>
                            <div className="thumb-caption">
                                <h5 className="thumb-title"><a className="text-darken">Excellent Service</a></h5>
                                <p className="thumb-desc">24/7 Recovery Service, Replacement Scheme </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gap gap-small"></div>
            </div>
            <div className="container">
            </div>
            <div className="special-area">
            <OwlCarousel items={1}
            id="owl-carousel-slider"
            className="owl-theme owl-carousel owl-slider owl-carousel-area"
            loop
            margin={10}
            nav={false}
            >

            <div className="bg-holder full text-center text-white">
                <div className="bg-mask"></div>
                <div className="bg-img"  style={sliderImage1}></div>
                <div className="bg-front full-center">
                    <div className="owl-cap">
                        <div className="owl-cap-weather"><span>BEST DEAL</span>
                        </div>
                        <h2 className="owl-cap-title">Toyota HiAce</h2>
                        <div className="owl-cap-price"><small>from</small>
                            <h5>S$888/month</h5>
                        </div><a href="/vehicles/van-rental" className="btn btn-white btn-ghost"><i className="fa fa-angle-right"></i> Book Now</a>
                    </div>
                </div>
            </div>

            </OwlCarousel>
            </div>
            <div className="gap gap-small"></div>
            <div className="container">
    			<div className="row">
    				<div className="col-md-9 col-md-offset-3">

    					<div className="booking-item-details">
    						<h2 className="lh1em mt20"  style={{fontWeight:"700"}}>Hear from our customers!</h2>

    						<div className="row mt20">
    							<div className="col-md-3" style={{borderRight:"solid #6c757d4a 0.5px"}}>
    								<div className="booking-item-meta mt20">

    									<h1 style={{fontWeight:"600"}}>4.8</h1>
    									<div className="booking-item-rating">
    										<ul className="icon-list icon-group booking-item-rating-stars" style={{paddingTop:"5px"}}>
    											<li><i className="fa fa-star"></i>
    											</li>
    											<li><i className="fa fa-star"></i>
    											</li>
    											<li><i className="fa fa-star"></i>
    											</li>
    											<li><i className="fa fa-star"></i>
    											</li>
    											<li><i className="fa fa-star"></i>
    											</li>
    										</ul>

    									</div>
    								</div>
    							</div>
    							<div className="col-md-9">
    								<div className="row">
    									<div className="col-md-10">
    										<ul className="list booking-item-raiting-list">
    											<li>
    												<div className="booking-item-raiting-list-title">
    													<ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
    														<li><i className="fa fa-star"></i>
    														</li>
    														<li><i className="fa fa-star"></i>
    														</li>
    														<li><i className="fa fa-star"></i>
    														</li>
    														<li><i className="fa fa-star"></i>
    														</li>
    														<li><i className="fa fa-star"></i>
    														</li>
    													</ul>
    												</div>
    												<div className="booking-item-raiting-list-bar">
    													<div style={{width:"94%"}}></div>
    												</div>
    											</li>
    											<li>
    												<div className="booking-item-raiting-list-title">
    													<ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
    														<li><i className="fa fa-star"></i>
    														</li>
    														<li><i className="fa fa-star"></i>
    														</li>
    														<li><i className="fa fa-star"></i>
    														</li>
    														<li><i className="fa fa-star"></i>
    														</li>
    														<li><i className="fa fa-star-o"></i>
    														</li>
    													</ul>
    												</div>
    												<div className="booking-item-raiting-list-bar">
    													<div style={{width:"6%"}}></div>
    												</div>
    											</li>
    											<li>
    												<div className="booking-item-raiting-list-title">
    													<ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
    														<li><i className="fa fa-star"></i>
    														</li>
    														<li><i className="fa fa-star"></i>
    														</li>
    														<li><i className="fa fa-star"></i>
    														</li>
    														<li><i className="fa fa-star-o"></i>
    														</li>
    														<li><i className="fa fa-star-o"></i>
    														</li>
    													</ul>
    												</div>
    												<div className="booking-item-raiting-list-bar">
    													<div style={{width:"0%"}}></div>
    												</div>
    											</li>
    											<li>
    												<div className="booking-item-raiting-list-title">
    													<ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
    														<li><i className="fa fa-star"></i>
    														</li>
    														<li><i className="fa fa-star"></i>
    														</li>
    														<li><i className="fa fa-star-o"></i>
    														</li>
    														<li><i className="fa fa-star-o"></i>
    														</li>
    														<li><i className="fa fa-star-o"></i>
    														</li>
    													</ul>
    												</div>
    												<div className="booking-item-raiting-list-bar">
    													<div style={{width:"0%"}}></div>
    												</div>
    											</li>
    											<li>
    												<div className="booking-item-raiting-list-title">
    													<ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
    														<li><i className="fa fa-star"></i>
    														</li>
    														<li><i className="fa fa-star-o"></i>
    														</li>
    														<li><i className="fa fa-star-o"></i>
    														</li>
    														<li><i className="fa fa-star-o"></i>
    														</li>
    														<li><i className="fa fa-star-o"></i>
    														</li>
    													</ul>
    												</div>
    												<div className="booking-item-raiting-list-bar">
    													<div style={{width:"0%"}}></div>
    												</div>
    											</li>
    										</ul>
    									</div>
    								</div>
    							</div>
    						</div>
    					</div>

    				</div>
    			</div>

    		</div>
    		<div className="gap gap-small"></div>
            <Hidden smDown>
                <div className="bg-color" style={{backgroundColor:"#28394f",height:"410px",position:"relative"}}>


                    <div className="bg-holder full full-center">
                        <div className="bg-front full-center" style={{padding:"40px"}}>
                            <OwlCarousel items={4}
                            id="owl-carousel-review"
                            className="owl-carousel owl-theme owl-carousel.owl-slider"
                            loop
                            nav={true}
                            pagination={false}
                            dots={true}
                            navElement={"div"}
                            navText={['','']}

                            >
                                <div className="item">
                                    <div className="testimonial">
                                        <div className="">
                                            <div className="testimonial-author">
                                                <img src={avatarReview2} alt="" title="" />
                                                <p className="testimonial-author-name">Noraini Mohd Noor</p>
                                                <cite>
                                                    <ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star-o"></i>
                                                        </li>
                                                    </ul>
                                                </cite>
                                            </div>

                                            <blockquote className="mt20">
                                                <h5 style={{fontWeight:"500",color:"#d61f2c"}}>Hassle Free</h5>
                                                <p>Really enjoy & hassle free for the rental process & also the rental charges are affordable too. The staff are friendly & customer service oriented. For sure will come back again to rent the vechicle perharps this time round for longer period 😊</p>
                                            </blockquote>
                                        </div>
                                    </div>

                                </div>

                                <div className="item">
                                    <div className="testimonial">
                                        <div className="">
                                            <div className="testimonial-author">
                                                <img src={avatarReview3} alt="" title="" />
                                                <p className="testimonial-author-name">Imtiaz Shah</p>
                                                <cite>
                                                    <ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                    </ul>
                                                </cite>
                                            </div>

                                            <blockquote className="mt20">
                                                <h5 style={{fontWeight:"500",color:"#d61f2c"}}>Excellent Customer Service</h5>
                                                <p>Very convenient and easy to deal with. The CS excellent and friendly. Vehicle also in good condition TIP TOP.</p>
                                            </blockquote>
                                        </div>
                                    </div>

                                </div>

                                <div className="item">

                                    <div className="testimonial">
                                        <div className="">
                                            <div className="testimonial-author">
                                                <img src={avatarReview4} alt="" title="" />
                                                <p className="testimonial-author-name">John Paul Koh</p>
                                                <cite>
                                                    <ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                    </ul>
                                                </cite>
                                            </div>

                                            <blockquote className="mt20">
                                                <h5 style={{fontWeight:"500",color:"#d61f2c"}}>Easy and straightforward</h5>
                                                <p>Working with EARental was easy and straightforward.</p>
                                            </blockquote>
                                        </div>
                                    </div>

                                </div>

                                <div className="item">

                                    <div className="testimonial">
                                        <div className="">
                                            <div className="testimonial-author">
                                                <img src={avatarReview3} alt="" title="" />
                                                <p className="testimonial-author-name">Kenneth Pan</p>
                                                <cite>
                                                    <ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                    </ul>
                                                </cite>
                                            </div>

                                            <blockquote className="mt20">
                                                <h5 style={{fontWeight:"500",color:"#d61f2c"}}>Seamless booking platform</h5>
                                                <p>Most seamless online booking platform with top notch customer service.</p>
                                            </blockquote>
                                        </div>
                                    </div>

                                </div>

                                <div className="item">

                                    <div className="testimonial">
                                        <div className="">
                                            <div className="testimonial-author">
                                                <img src={avatarReview3} alt="" title="" />
                                                <p className="testimonial-author-name">Kenric Ng</p>
                                                <cite>
                                                    <ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                    </ul>
                                                </cite>
                                            </div>

                                            <blockquote className="mt20">
                                                <h5 style={{fontWeight:"500",color:"#d61f2c"}}>Excellent Platform</h5>
                                                <p>Fast, responsive and reliable service, user friendly too</p>
                                            </blockquote>
                                        </div>
                                    </div>

                                </div>

                                <div className="item">

                                    <div className="testimonial">
                                        <div className="">
                                            <div className="testimonial-author">
                                                <img src={avatarReview1} alt="" title="" />
                                                <p className="testimonial-author-name">May Song</p>
                                                <cite>
                                                    <ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                    </ul>
                                                </cite>
                                            </div>

                                            <blockquote className="mt20">
                                                <h5 style={{fontWeight:"500",color:"#d61f2c"}}>Reasonable, Efficient</h5>
                                                <p>Charges are reasonable, admin is efficient, and delivery staff is very friendly and helpful. 😊</p>
                                            </blockquote>
                                        </div>
                                    </div>

                                </div>

                                <div className="item">

                                    <div className="testimonial">
                                        <div className="">
                                            <div className="testimonial-author">
                                                <img src={avatarReview4} alt="" title="" />
                                                <p className="testimonial-author-name">Hezri Sheh</p>
                                                <cite>
                                                    <ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                    </ul>
                                                </cite>
                                            </div>

                                            <blockquote className="mt20">
                                                <h5 style={{fontWeight:"500",color:"#d61f2c"}}>Hassle free and fast service</h5>
                                                <p>Very prompt reply.  Hassle free and fast service.</p>
                                            </blockquote>
                                        </div>
                                    </div>

                                </div>

                                <div className="item">

                                    <div className="testimonial">
                                        <div className="">
                                            <div className="testimonial-author">
                                                <img src={avatarReview3} alt="" title="" />
                                                <p className="testimonial-author-name">Zhang Yong</p>
                                                <cite>
                                                    <ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star-o"></i>
                                                        </li>
                                                    </ul>
                                                </cite>
                                            </div>

                                            <blockquote className="mt20">
                                                <h5 style={{fontWeight:"500",color:"#d61f2c"}}>Excellent Customer Service</h5>
                                                <p>Excellent service. Thank you</p>
                                            </blockquote>
                                        </div>
                                    </div>

                                </div>

                                <div className="item">
                                    <div className="testimonial">
                                        <div className="">
                                            <div className="testimonial-author">
                                                <img src={avatarReview4} alt="" title="" />
                                                <p className="testimonial-author-name">Bao Logistics Pte. Ltd.</p>
                                                <cite>
                                                    <ul className="icon-list icon-group booking-item-rating-stars"  style={{color:"#ed8323"}}>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                    </ul>
                                                </cite>
                                            </div>

                                            <blockquote className="mt20">
                                                <h5 style={{fontWeight:"500",color:"#d61f2c"}}>Excellent Customer Service, Seamless transactions</h5>
                                                <p>EAs service towards us is excellent, not just seamless transactions but also providing sound advice going beyond our expectations.</p>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>

                </div>
            </Hidden>

            <Hidden mdUp>
                <div className="bg-color" style={{backgroundColor:"#28394f",padding:"20px",position:"relative"}}>


                    <div>
                        <div>
                            <OwlCarousel items={1}
                            id="owl-carousel-review"
                            className="owl-carousel owl-theme owl-carousel.owl-slider"
                            loop
                            nav={true}
                            pagination={false}
                            dots={false}
                            navElement={"div"}
                            navText={['','']}
                            style={{margin:"0px auto"}}

                            >
                                <div className="item-m">
                                    <div className="testimonial">
                                        <div className="">
                                            <div className="testimonial-author">
                                                <img src={avatarReview2} alt="" title="" />
                                                <p className="testimonial-author-name">Noraini Mohd Noor</p>
                                                <cite>
                                                    <ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star-o"></i>
                                                        </li>
                                                    </ul>
                                                </cite>
                                            </div>

                                            <blockquote className="mt20">
                                                <h5 style={{fontWeight:"500",color:"#d61f2c"}}>Hassle Free</h5>
                                                <p>Really enjoy & hassle free for the rental process & also the rental charges are affordable too. The staff are friendly & customer service oriented. For sure will come back again to rent the vechicle perharps this time round for longer period 😊</p>
                                            </blockquote>
                                        </div>
                                    </div>

                                </div>

                                <div className="item-m">
                                    <div className="testimonial">
                                        <div className="">
                                            <div className="testimonial-author">
                                                <img src={avatarReview3} alt="" title="" />
                                                <p className="testimonial-author-name">Imtiaz Shah</p>
                                                <cite>
                                                    <ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                    </ul>
                                                </cite>
                                            </div>

                                            <blockquote className="mt20">
                                                <h5 style={{fontWeight:"500",color:"#d61f2c"}}>Excellent Customer Service</h5>
                                                <p>Very convenient and easy to deal with. The CS excellent and friendly. Vehicle also in good condition TIP TOP.</p>
                                            </blockquote>
                                        </div>
                                    </div>

                                </div>

                                <div className="item-m">

                                    <div className="testimonial">
                                        <div className="">
                                            <div className="testimonial-author">
                                                <img src={avatarReview4} alt="" title="" />
                                                <p className="testimonial-author-name">John Paul Koh</p>
                                                <cite>
                                                    <ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                    </ul>
                                                </cite>
                                            </div>

                                            <blockquote className="mt20">
                                                <h5 style={{fontWeight:"500",color:"#d61f2c"}}>Easy and straightforward</h5>
                                                <p>Working with EARental was easy and straightforward.</p>
                                            </blockquote>
                                        </div>
                                    </div>

                                </div>

                                <div className="item-m">

                                    <div className="testimonial">
                                        <div className="">
                                            <div className="testimonial-author">
                                                <img src={avatarReview3} alt="" title="" />
                                                <p className="testimonial-author-name">Kenneth Pan</p>
                                                <cite>
                                                    <ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                    </ul>
                                                </cite>
                                            </div>

                                            <blockquote className="mt20">
                                                <h5 style={{fontWeight:"500",color:"#d61f2c"}}>Seamless booking platform</h5>
                                                <p>Most seamless online booking platform with top notch customer service.</p>
                                            </blockquote>
                                        </div>
                                    </div>

                                </div>

                                <div className="item-m">

                                    <div className="testimonial">
                                        <div className="">
                                            <div className="testimonial-author">
                                                <img src={avatarReview3} alt="" title="" />
                                                <p className="testimonial-author-name">Kenric Ng</p>
                                                <cite>
                                                    <ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                    </ul>
                                                </cite>
                                            </div>

                                            <blockquote className="mt20">
                                                <h5 style={{fontWeight:"500",color:"#d61f2c"}}>Excellent Platform</h5>
                                                <p>Fast, responsive and reliable service, user friendly too</p>
                                            </blockquote>
                                        </div>
                                    </div>

                                </div>

                                <div className="item-m">

                                    <div className="testimonial">
                                        <div className="">
                                            <div className="testimonial-author">
                                                <img src={avatarReview1} alt="" title="" />
                                                <p className="testimonial-author-name">May Song</p>
                                                <cite>
                                                    <ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                    </ul>
                                                </cite>
                                            </div>

                                            <blockquote className="mt20">
                                                <h5 style={{fontWeight:"500",color:"#d61f2c"}}>Reasonable, Efficient</h5>
                                                <p>Charges are reasonable, admin is efficient, and delivery staff is very friendly and helpful. 😊</p>
                                            </blockquote>
                                        </div>
                                    </div>

                                </div>

                                <div className="item-m">

                                    <div className="testimonial">
                                        <div className="">
                                            <div className="testimonial-author">
                                                <img src={avatarReview4} alt="" title="" />
                                                <p className="testimonial-author-name">Hezri Sheh</p>
                                                <cite>
                                                    <ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                    </ul>
                                                </cite>
                                            </div>

                                            <blockquote className="mt20">
                                                <h5 style={{fontWeight:"500",color:"#d61f2c"}}>Hassle free and fast service</h5>
                                                <p>Very prompt reply.  Hassle free and fast service.</p>
                                            </blockquote>
                                        </div>
                                    </div>

                                </div>

                                <div className="item-m">

                                    <div className="testimonial">
                                        <div className="">
                                            <div className="testimonial-author">
                                                <img src={avatarReview3} alt="" title="" />
                                                <p className="testimonial-author-name">Zhang Yong</p>
                                                <cite>
                                                    <ul className="icon-list icon-group booking-item-rating-stars" style={{color:"#ed8323"}}>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star-o"></i>
                                                        </li>
                                                    </ul>
                                                </cite>
                                            </div>

                                            <blockquote className="mt20">
                                                <h5 style={{fontWeight:"500",color:"#d61f2c"}}>Excellent Customer Service</h5>
                                                <p>Excellent service. Thank you</p>
                                            </blockquote>
                                        </div>
                                    </div>

                                </div>

                                <div className="item-m">
                                    <div className="testimonial">
                                        <div className="">
                                            <div className="testimonial-author">
                                                <img src={avatarReview4} alt="" title="" />
                                                <p className="testimonial-author-name">Bao Logistics Pte. Ltd.</p>
                                                <cite>
                                                    <ul className="icon-list icon-group booking-item-rating-stars"  style={{color:"#ed8323"}}>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                        <li><i className="fa fa-star"></i>
                                                        </li>
                                                    </ul>
                                                </cite>
                                            </div>

                                            <blockquote className="mt20">
                                                <h5 style={{fontWeight:"500",color:"#d61f2c"}}>Excellent Customer Service, Seamless transactions</h5>
                                                <p>EA's service towards us is excellent, not just seamless transactions but also providing sound advice going beyond our expectations.</p>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>

                </div>
            </Hidden>

            <div className="bg-holder">
                <div className="bg-mask"></div>
                <div className="bg-parallax" style={howToBookStyle}></div>
                <div className="bg-content">
                    <div className="container">
                        <div className="gap gap-big text-center text-white">
                            <h2 className=" mb20">How To Book Vehicle Online</h2>
                            <iframe width="100%" height="515" src="https://www.youtube.com/embed/gft3bKaVgik" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container">
                <div className="gap"></div>
                <h2 className="text-center">Top Services</h2>
                <div className="gap">
                    <div className="row row-wrap">
                        <div className="col-md-3">
                            <div className="thumb">
                                <header className="thumb-header">
                                    <a className="hover-img curved">
                                        <img src={topserviceImage1} alt="Weekly Rental" title="Weekly Rental" /><i className="fa fa-plus box-icon-white box-icon-border hover-icon-top-right round"></i>
                                    </a>
                                </header>
                                <div className="thumb-caption">
                                    <h4 className="thumb-title">Weekly Rental</h4>
                                    <p className="thumb-desc">Rent with us on weekly basis. Just as low as S$350 per week.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="thumb">
                                <header className="thumb-header">
                                    <a className="hover-img curved">
                                        <img src={topserviceImage2} alt="Monthly Rental" title="Monthly Rental" /><i className="fa fa-plus box-icon-white box-icon-border hover-icon-top-right round"></i>
                                    </a>
                                </header>
                                <div className="thumb-caption">
                                    <h4 className="thumb-title">Monthly Rental</h4>
                                    <p className="thumb-desc">Perfect business solution. Monthly rental as low as S$888 per month.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="thumb">
                                <header className="thumb-header">
                                    <a className="hover-img curved">
                                        <img src={topserviceImage3} alt="Daily Rental" title="Daily Rental" /><i className="fa fa-plus box-icon-white box-icon-border hover-icon-top-right round"></i>
                                    </a>
                                </header>
                                <div className="thumb-caption">
                                    <h4 className="thumb-title">Daily Rental</h4>
                                    <p className="thumb-desc">Hassle free downpayment. S$65 per day.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="thumb">
                                <header className="thumb-header">
                                    <a className="hover-img curved">
                                        <img src={topserviceImage4} alt="Yearly Rental" title="Yearly Rental" /><i className="fa fa-plus box-icon-white box-icon-border hover-icon-top-right round"></i>
                                    </a>
                                </header>
                                <div className="thumb-caption">
                                    <h4 className="thumb-title">Yearly Rental</h4>
                                    <p className="thumb-desc">No Risks Depreciation. As low as S$888 per month.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-holder hidden-xs">
                <div className=""></div>
                <div className="bg-img" style={sanatizeDBg}></div>
                <div className="bg-content">
                    <div className="container">
                        <div className="gap gap-big text-center text-white">
                            <div className="row " data-gutter="">
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-6">
                                    <div className="alert " style={{marginBottom:"0px",backgroundColor:"#d40029a6"}}>
                                        <div className="owl-cap-weather text-uc" style={{paddingTop:"20px"}}><span>your safety is our priority</span></div>
                                        <h2 className="owl-cap-title" style={{fontSize:"39px"}}>we disinfect all returned vehicles thoroughly</h2>
                                    </div>
                                </div>
                             </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-holder hidden-md hidden-lg hidden-sm">
                <div className=""></div>
                <div className="bg-img" style={sanatizeMBg}></div>
                <div className="bg-content">
                    <div className="container">
                        <div className="gap gap-big text-center text-white">
                            <div className="row " data-gutter="">
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-6">
                                    <div className="alert " style={{marginBottom:"135px",backgroundColor:"#d40029a6"}}>
                                        <div className="owl-cap-weather text-uc" style={{paddingTop:"15px !important"}}><span style={{fontSize:"20px"}}>your safety is our priority</span></div>
                                        <h3 className="owl-cap-title" style={{fontSize:"28px"}}>we disinfect all returned vehicles thoroughly</h3>
                                    </div>
                                </div>
                             </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default Home;
