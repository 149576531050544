import React from "react";
import {
  NavLink,
  HashRouter,
  Redirect
} from "react-router-dom";
import axios from 'axios';
import { store } from 'react-notifications-component';
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";

import ScriptTag from 'react-script-tag';
import queryString from 'query-string';
import TextTransition, { presets } from "react-text-transition";
import momentPrecise from 'moment-precise-range-plugin';
import NumberFormat from 'react-number-format';
import SliderImage from 'react-zoom-slider';
// import parallaxBg1 from './assets/img/196_365_2048x1365.jpg';
// import DatePicker from "react-datepicker";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import noImage from './../../../assets/img/no-image.jpg';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Rating from '@material-ui/lab/Rating';
import Badge from '@material-ui/core/Badge';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import RadioMu from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import SearchIcon from '@material-ui/icons/Search';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import Chip from '@material-ui/core/Chip';
import { red,grey, orange } from '@material-ui/core/colors';
import EllipsisText from "react-ellipsis-text"
import CheckIcon from '@material-ui/icons/Check';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import InputAdornment from '@material-ui/core/InputAdornment';
import Alert from '@material-ui/lab/Alert';
import Calendar from 'react-calendar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import 'react-calendar/dist/Calendar.css';

// var parallaxBgStyle = {
//   backgroundImage: "url(" + parallaxBg1 + ")"
// };
import "./../../../assets/css/vehicles.css";

import {Checkbox, Radio} from 'react-icheck';

const moment = extendMoment(originalMoment);

const useStyles = makeStyles((theme) => ({
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ed8323',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#ed8323',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#ed8323',
        },
    },
    newCardForm: {
    color: grey[800],
    '& .MuiFormControl-root': {
    '& .MuiFormLabel-root': {
      fontSize: '1.2rem',
      '&.Mui-focused': {
        color: grey[800],
        backgroundColor: 'white',
        paddingRight: 5,
      },
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      '&:hover fieldset': {
        borderColor: '#B4B4B4',
      },
      '&.Mui-focused fieldset': {
        borderColor: orange[600],
      },
      '& input': {
        fontSize: 20,
        font:'auto'
      },
    },
    },
}
}));

export default class ProductPreview extends React.Component {

    constructor(props) {
        super(props);
        this.apiURL = "https://api.earental.com";
        this.apiKey = "72ffc1303c7c0286887a3276f0ab61b8";
        this.accessToken = localStorage.getItem('ctoken');
        this.jhamToken = localStorage.getItem('token');


        var additionalsArr = [];
        var additionalsAddedArr = [];
        console.log(this.props.details.addOns);

        const images = [];
        // if(this.props.location.state.detail.images){
        //     for (let i = 0; i < this.props.location.state.detail.images.length; i++) {
        //         images.push(
        //             {
        //                 original: "https://api.earental.com/uploads/vehicles/"+this.props.location.state.detail.images[i].pi_image,
        //                 thumbnail: "https://api.earental.com/uploads/vehicles/"+this.props.location.state.detail.images[i].pi_image,
        //             }
        //         );
        //
        //     }
        //
        // }else{
        //     images.push(
        //         {
        //             original: noImage,
        //             thumbnail: noImage,
        //         }
        //     );
        // }




  }


  componentDidMount() {


  }


  render() {
      function Equipments(props) {

          if(props.equipments == null){
              return false;
          }else{
              return (
                    props.equipments.map((item,i) => {
                        if(parseInt(item.price) == 0 && item.title.trim() != ""){
                            return (<li title={item.title.trim()} key={'xve-edit-'+i}><i><CheckIcon style={{fill: "green"}} /></i><span className="booking-item-feature-title">{item.title.trim()}</span></li>)
                        }
                    })
              );



          }

      }

      function TermIcons(props) {
          if(props.terms){
              var terms = props.terms.split(',');
              return (
                    terms.map((item,i) =>
                      (item == "Short Term") ?
                      (<li title={item} key={'t-'+i}><i className="fa fa-clock-o"></i><span className="booking-item-feature-title">{item}</span></li>) :
                      (<li rel="tooltip" data-placement="top" title={item} key={'t-'+i}><i className="fa fa-calendar-o"></i><span className="booking-item-feature-title">{item}</span></li>)
                    )
              );

          }else{
            return false;
          }



      }

      return (
          <div>
              <div className="">
                  <div className="booking-item-details">
                      <header className="booking-item-header">
                          <div className="row">
                              <div className="col-md-9">
                                  <h2 className="lh1em">{this.props.details.product_name}</h2>
                              </div>
                          </div>
                      </header>
                      <div className="gap gap-small"></div>
                      <div className="row row-wrap">
                          <div className="col-md-12">
                              <div className="row">
                                  <div className="col-md-12">
                                        <ImageGallery
                                            lazyLoad={true}
                                            showNav={false}
                                            thumbnailPosition={"bottom"}
                                            showFullscreenButton={false}
                                            items={this.props.details.images}
                                            useBrowserFullscreen={false}
                                            showPlayButton={false}
                                        />
                                  </div>

                              </div>
                              <br />

                              <Card>
                                  <CardContent>
                                      <Typography variant="h4">
                                        <InfoOutlinedIcon />  Vehicle Specs
                                      </Typography>
                                      <Grid container spacing={0} alignItems="center">
                                        <Grid item md={3} sm={12} xs={12} align="center">
                                            <List disablePadding className="product-info-list">
                                                <ListItem >
                                                    <ListItemText
                                                    primary="Brand"
                                                    secondary={this.props.details.product_brand}
                                                    />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                        <Grid item md={4} sm={12} xs={12} align="center">
                                            <List disablePadding className="product-info-list">
                                                <ListItem >
                                                    <ListItemText
                                                    primary="Model"
                                                    secondary={this.props.details.product_model}
                                                    />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                        <Grid item md={2} sm={12} xs={12} align="center">
                                            <List disablePadding className="product-info-list">
                                                <ListItem >
                                                    <ListItemText
                                                    primary="Year"
                                                    secondary={this.props.details.product_registration_year}
                                                    />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                        <Grid item md={3} sm={12} xs={12} align="center">
                                            <List disablePadding className="product-info-list">
                                                <ListItem >
                                                    <ListItemText
                                                    primary="Colour"
                                                    secondary={this.props.details.product_colour}
                                                    />
                                                </ListItem>
                                            </List>
                                        </Grid>

                                       </Grid>
                                       <br/>
                                       <Typography variant="h4">
                                         <AssignmentIndOutlinedIcon /> Driver’s Requirements
                                       </Typography>
                                       <Grid container spacing={0} alignItems="center">
                                           <Grid item md={6} sm={12} xs={12} align="center">
                                               <List disablePadding className="product-info-list">
                                                   <ListItem >
                                                       <ListItemText
                                                       primary="Minimum Age"
                                                       secondary={this.props.details.product_min_age+" yr(s) old"}
                                                       />
                                                   </ListItem>
                                               </List>
                                           </Grid>
                                           <Grid item md={6} sm={12} xs={12} align="center">
                                               <List disablePadding className="product-info-list">
                                                   <ListItem >
                                                       <ListItemText
                                                       primary="Minimum Exp"
                                                       secondary={this.props.details.product_experience+" yr(s) of driving"}
                                                       />
                                                   </ListItem>
                                               </List>
                                           </Grid>


                                        </Grid>

                                  </CardContent>
                              </Card>

                              <br />

                              <hr />


                              <div className="row row-wrap">
                                  <div className="col-md-4">
                                      <h5>Features</h5>
                                      <ul className="booking-item-features booking-item-features-expand clearfix">
                                          <li title="Passengers"><i className="fa fa-male"></i><span className="booking-item-feature-title">x {this.props.details.product_passengers}</span>
                                          </li>
                                          <li title="Doors"><i className="im im-car-doors"></i><span className="booking-item-feature-title">x {this.props.details.product_doors}</span>
                                          </li>
                                          <li title="Baggage Quantity"><i className="fa fa-briefcase"></i><span className="booking-item-feature-title">x {this.props.details.product_baggage}</span>
                                          </li>
                                          <li title={this.props.details.product_transmission}><i className="im im-shift"></i><span className="booking-item-feature-title">{this.props.details.product_transmission}</span>
                                          </li>
                                          <li title={this.props.details.product_gas}><i className="im im-diesel"></i>
                                              <span className="booking-item-feature-title">{this.props.details.product_gas}</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div className="col-md-4">
                                      <h5>Default Equipment</h5>
                                      <ul className="booking-item-features booking-item-features-expand clearfix">
                                          <Equipments equipments={this.props.details.addOns}/>
                                      </ul>
                                  </div>
                                  <div className="col-md-4">
                                      <h5>Available Rental</h5>
                                      <ul className="booking-item-features booking-item-features-expand booking-item-features-dark clearfix">
                                          <TermIcons terms={this.props.details.product_rent_terms}  />
                                      </ul>
                                  </div>
                                  <div className="col-md-12">
                                      <br />
                                      <Typography  style={{whiteSpace:"pre-line",fontSize:"16px"}}>
                                        {this.props.details.product_description}
                                      </Typography>
                                  </div>

                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <ScriptTag isHydrating={true} type="text/javascript" src="js/vehicle/vehicle.js" />
          </div>
      )


  }
}
