import React, { Component } from "react";
import {
  NavLink,
  HashRouter
} from "react-router-dom";

import axios, { post } from 'axios';
import { store } from 'react-notifications-component';
import moment from "moment";
import DatePicker from "react-datepicker";

import aboutTopBanner from './../assets/img/about-us.jpg';
import aboutParallaxBanner from './../assets/img/parallax-about.jpg';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  createMuiTheme,
  withStyles,
  ThemeProvider,
  makeStyles
} from '@material-ui/core/styles';
import { orange, grey } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import OtpInput from 'react-otp-input';
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';

import "./../assets/css/profile.css";

import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import Alert from '@material-ui/lab/Alert';


var aboutTopBannerImg = {
  backgroundImage: "url(" + aboutTopBanner + ")"
};

var aboutParallaxBannerImg = {
  backgroundImage: "url(" + aboutParallaxBanner + ")"
};

const OrangeTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      '&:hover fieldset': {
        borderColor: '#B4B4B4',
      },
      '&.Mui-focused fieldset': {
        borderColor: orange[600],
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      minWidth: '30ch',
    },
  },
  searchAddressInput: {
    '& .MuiInputLabel-root': {
      fontSize: '1.75rem !important',
    },
    '& .MuiInput-root': {
      '& input': {
        fontSize: '1.5rem',
        textAlign: 'center',
        textTransform: 'uppercase',
        paddingTop: theme.spacing(2),
      },
      '& .MuiCircularProgress-root': {
        color: orange[600],
      },
      '&:after': {
        borderColor: orange[600],
      },
      '&:hover::before': {
        borderColor: grey[500],
      },
    },
  },
  searchAddressResult: {
    '& .MuiListItemText-primary': {
      fontSize: '1.25rem',
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: orange[600],
    },
  },
  newAddressForm: {
    color: grey[800],
    '& .MuiFormControl-root': {
      '& .MuiFormLabel-root': {
        fontSize: '1.2rem',
        '&.Mui-focused': {
          color: grey[800],
          backgroundColor: 'white',
          paddingRight: 5,
        },
      },
      '& .MuiFormControlLabel-label': {
        fontSize: '1.2rem',
      },
      '& .MuiRadio-colorSecondary.Mui-checked': {
        color: orange[600],
      },
      '& .MuiSelect-select': {
        fontSize: '1.2rem',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        '&:hover fieldset': {
          borderColor: '#B4B4B4',
        },
        '&.Mui-focused fieldset': {
          borderColor: orange[600],
        },
        '& input': {
          fontSize: '1.2rem',
        },
      },
      '& .MuiRadio-root': {
        '& .Mui-focused': {
          color: orange[600],
        },
      },
    },
  },
}));


class Settings extends Component {

    constructor (props) {
      super(props)
      window.scrollTo(0, 0);
      this.state = {
        customer_id: false,
        firstName:'',
        lastName: '',
        firstName2:'',
        lastName2: '',
        email: '',
        phoneNumber: '',
        gender:'Male',
        purpose: '',
        dob: '',
        driver_license:'',
        driver_license_expiration: '',
        nric: '',
        customer_news_subscription:"1",
        company_address:'',
        company_contact:'',
        company_name: '',
        company_postal: '',
        company_roc: '',
        houseAptB:'',
        blockhouse:'',
        street:'',
        floor:'',
        unit:'',
        buildingName:'',
        lessorCode:false,
        openSearchAddress: false,
        buttonLoading: false,
        currentTab: 0,
        customer_lessor_bankname:'',
        customer_lessor_bankaccountnumber: '',
        customer_lessor_recipientname: '',
      };

      this.handleChangePassword = this.handleChangePassword.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleChangeDob = this.handleChangeDob.bind(this);
      this.handleChangeDLE = this.handleChangeDLE.bind(this);
      this.handleUpdateProfile = this.handleUpdateProfile.bind(this);
    }

  componentDidMount() {
      var ctoken = localStorage.getItem('ctoken');
      var token = localStorage.getItem('token');

      if(ctoken){
          var bodyFormData = new FormData();
          bodyFormData.append('access_token', ctoken);
          bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
          bodyFormData.append('jham_token', token);
          axios({
                  method: 'post',
                  url: 'https://api.earental.com/account/my_profile',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {
                  if(response.data.error > 0){
                      store.addNotification({
                        title: "Session expire",
                        message: response.data.error_msg,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: false
                        }
                      });
                  }else{
                      var dob = moment(response.data.data.customer_dob).toDate();
                      if(response.data.data.customer_dob == "0000-00-00" || response.data.data.customer_dob == null){
                          dob = null;

                      }

                      var licenseExp = moment(response.data.data.customer_driver_license_expiration).toDate();
                      if(response.data.data.customer_driver_license_expiration == "0000-00-00" || response.data.data.customer_driver_license_expiration == null){
                          licenseExp = new Date();
                          licenseExp = moment(licenseExp).toDate();
                      }

                      this.setState({
                        firstName:response.data.data.customer_firstname,
                        lastName:response.data.data.customer_lastname,
                        firstName2:response.data.data.customer_firstname,
                        lastName2:response.data.data.customer_lastname,
                        lessorCode:response.data.data.c_lessor_code,
                        email:response.data.data.customer_email,
                        nric: response.data.data.customer_nric,
                        gender: response.data.data.customer_gender,
                        dateCreated: moment(response.data.data.customer_date_created).format('LL'),
                        phoneNumber:response.data.data.customer_phonenumber,
                        dob:dob,
                        driver_license:response.data.data.customer_driver_license,
                        driver_license_expiration:licenseExp,
                        purpose:response.data.data.customer_purpose,
                        company_address:response.data.data.customer_company_address,
                        company_contact:response.data.data.customer_company_contact,
                        company_name:response.data.data.customer_company_name,
                        company_name_acronym:response.data.data.customer_company_name_acronym,
                        company_postal: response.data.data.customer_company_postaladdress,
                        company_roc:response.data.data.customer_company_roc,
                        customer_news_subscription:response.data.data.customer_news_subscription,
                        currentAvatar:response.data.data.customer_profile_image,
                        houseAptB:response.data.data.customer_address_house_apt_bussiness,
                        blockhouse:response.data.data.customer_address_house_no_block,
                        street:response.data.data.customer_address_street,
                        floor: response.data.data.customer_address_floor,
                        unit:response.data.data.customer_address_unit,
                        gstRegistered:response.data.data.company_gst_registered,
                        gstNo:response.data.data.company_gst_no,
                        instruction:response.data.data.customer_lessor_instruction,
                        buildingName:response.data.data.customer_address_building_name,
                        customer_agreement_signed:response.data.data.customer_agreement_signed,
                        customer_lessor_bankname:response.data.data.customer_lessor_bankname,
                        customer_lessor_bankaccountnumber: response.data.data.customer_lessor_bankaccountnumber,
                        customer_lessor_recipientname: response.data.data.customer_lessor_recipientname,
                        customer_acra: response.data.data.customer_acra,
                      })

                      localStorage.setItem('current_name', response.data.data.customer_firstname);
                      localStorage.setItem('current_avatar', response.data.data.customer_profile_image);
                    //   this.props.handlerState('currentAvatar',response.data.data.customer_profile_image);
                  }
              })
              .catch((error) => {
                  //handle error
                  localStorage.removeItem("ctoken");
                  localStorage.removeItem("current_name");
                  localStorage.removeItem("current_avatar");
                  localStorage.removeItem('checkout');
                  localStorage.removeItem('checkoutProgress');
                  localStorage.removeItem('checkoutCompleted');
                  localStorage.removeItem('hasPendingPayment');

                  setTimeout(
                    () => window.location.href = "/sign-in",
                    150
                  );

          });
      }else{
          localStorage.removeItem("ctoken");
          localStorage.removeItem("current_name");
          localStorage.removeItem("current_avatar");
          localStorage.removeItem('checkout');
          localStorage.removeItem('checkoutProgress');
          localStorage.removeItem('checkoutCompleted');
          localStorage.removeItem('hasPendingPayment');

          setTimeout(
            () => window.location.href = "/sign-in",
            150
          );
      }




  }

  handleChangePassword(e) {
      e.preventDefault();
      this.setState({
        buttonLoading:true,
        currentTab: 1,
      })

      var currentPassword = e.target.elements.password.value;
      var newPassword = e.target.elements.newpassword.value;
      var newPasswordAgain = e.target.elements.newpasswordagain.value;


      if(newPassword != newPasswordAgain){
          store.addNotification({
            title: "Invalid",
            message: "New password and New password again is not the same.",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false
            }
          });
          this.setState({
            buttonLoading:false,
            currentTab: 1,
          })
      }else{
          var ctoken = localStorage.getItem('ctoken');
          var token = localStorage.getItem('token');
          var bodyFormData = new FormData();
          bodyFormData.append('access_token', ctoken);
          bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
          bodyFormData.append('jham_token', token);
          bodyFormData.append('current_password', currentPassword);
          bodyFormData.append('new_password', newPassword);
          e.target.reset();

          axios({
                  method: 'post',
                  url: 'https://api.earental.com/account/change_password',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {
                  if(response.data.error > 0){
                      store.addNotification({
                        title: "Session expire",
                        message: response.data.error_msg,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: false
                        }
                      });
                  }else{
                      store.addNotification({
                        title: "Great!",
                        message: "Password successfully updated.",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: false
                        }
                      });

                  }
                  this.setState({
                    buttonLoading:false,
                    currentTab: 1,
                  })

              })
              .catch((error) => {
                  //handle error
                  this.setState({ loading:false });
                  if (error.response.status == 403) {

                      store.addNotification({
                        title: "Oops Something went wrong",
                        message: "The action you have requested is not allowed. please re-load the page",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: false
                        }
                      });

                 }
                 this.setState({
                   buttonLoading:true,
                   currentTab: 1,
                 })


          });

      }

  }

  handleClickOpenAddress = () => {
    this.setState({openSearchAddress: true});
  }

  handleCloseAddress = () => {
    this.setState({openSearchAddress: false});
  }


  handleSearchAddressChange = (selectedAddress) => {
    console.log(selectedAddress);
    this.setState({openSearchAddress: false});

    this.setState({

      company_address:selectedAddress.ADDRESS,
      company_postal: selectedAddress.POSTAL,
      blockhouse:selectedAddress.BLK_NO,
      street:selectedAddress.ROAD_NAME,
      buildingName:selectedAddress.BUILDING,
    })

  }


  handleUpdateProfile(e) {
        e.preventDefault();
        this.setState({
          buttonLoading:true
        })

        var firstName = e.target.elements.firstName2.value;
        var lastName = e.target.elements.lastName2.value;
        var email = e.target.elements.email.value;
        var phoneno = e.target.elements.phoneNumber.value;
        //   var licenseNo = e.target.elements.driver_license.value;
        //   var licenseExp = e.target.elements.driver_license_expiration.value;
        var nric = e.target.elements.nric.value;
        var gender = e.target.elements.gender.value;
        var dob = e.target.elements.dob.value;
        dob = moment(dob).format("YYYY-MM-DD");
        //   var purpose = e.target.elements.purpose.value;
        var subscription = e.target.elements.customer_news_subscription.value;
        var company_name = e.target.elements.company_name.value;
        var company_name_acronym = e.target.elements.company_name_acronym.value;
        var company_roc = e.target.elements.company_roc.value;
        var company_contact = e.target.elements.company_contact.value;
        //   var company_address = e.target.elements.company_address.value;
        var company_postal = e.target.elements.company_postal.value;
        var adress_hab = e.target.elements.houseAptB.value;
        var address_houseblock = e.target.elements.blockhouse.value;
        var address_street = e.target.elements.street.value;
        var address_floor = e.target.elements.floor.value;
        var address_unit = e.target.elements.unit.value;
        var address_building_name = e.target.elements.bname.value;

        var gstRegistered = e.target.elements.gstRegistered.value;
        var gstNo = e.target.elements.gstNo.value;
        var instruction = e.target.elements.instruction.value;

        var company_address = address_houseblock+' '+address_street;
        if(address_floor && address_unit){
        company_address = company_address+ ' #'+address_floor+'-'+address_unit;
        }
        company_address = company_address+' '+company_postal;





        var ctoken = localStorage.getItem('ctoken');
        var token = localStorage.getItem('token');
        var bodyFormData = new FormData();
        bodyFormData.append('access_token', ctoken);
        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
        bodyFormData.append('jham_token', token);

        bodyFormData.append('customer_firstname', firstName);
        bodyFormData.append('customer_lastname', lastName);
        bodyFormData.append('customer_username', email);
        bodyFormData.append('customer_phonenumber', phoneno);
        bodyFormData.append('customer_gender', gender);
        bodyFormData.append('customer_dob', dob);
        bodyFormData.append('customer_nric', nric);
        bodyFormData.append('customer_driver_license', "");
        bodyFormData.append('customer_driver_license_expiration', "");
        bodyFormData.append('customer_purpose', "");
        bodyFormData.append('customer_news_subscription', subscription);

        bodyFormData.append('customer_company_name', company_name);
        bodyFormData.append('customer_company_name_acronym', company_name_acronym);
        bodyFormData.append('customer_company_roc', company_roc);
        bodyFormData.append('customer_company_contact', company_contact);
        bodyFormData.append('customer_company_address', company_address);
        bodyFormData.append('customer_company_postaladdress', company_postal);

        bodyFormData.append('customer_address_house_apt_bussiness', adress_hab);
        bodyFormData.append('customer_address_house_no_block', address_houseblock);
        bodyFormData.append('customer_address_street', address_street);
        bodyFormData.append('customer_address_floor', address_floor);
        bodyFormData.append('customer_address_unit', address_unit);
        bodyFormData.append('customer_address_building_name', address_building_name);
        bodyFormData.append('company_gst_registered', gstRegistered);
        bodyFormData.append('company_gst_no', gstNo);
        bodyFormData.append('customer_lessor_instruction', instruction);





        axios({
              method: 'post',
              url: 'https://api.earental.com/account/my_profile_update',
              data: bodyFormData,
              withCredentials: true,
              headers: {'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
              var errors = "";
              if(response.data.error > 0){

                  function ErrorMsg(props) {
                    return (
                        props.msgs.map((item,i) =>
                            <li key={"err-"+item.i}>{item.value}</li>
                        )

                    );
                  }

                  store.addNotification({
                    title: "Invalid fields",
                    message: <ErrorMsg msgs={response.data.error_message} />,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000
                    }
                  });
              }else{
                  store.addNotification({
                    title: "Great!",
                    message: "Information successfully updated.",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: false
                    }
                  });

              }
              this.setState({
                buttonLoading:false,
                currentTab: 0,
              })
          })
          .catch((error) => {
              //handle error
              if (error.response.status == 403) {

                  store.addNotification({
                    title: "Oops Something went wrong",
                    message: "The action you have requested is not allowed. please re-load the page",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: false
                    }
                  });

             }
             this.setState({
               buttonLoading:false,
               currentTab: 0,
             })

        });


  }

  handleInputChange(e) {
      this.setState({
          [e.target.name]:e.target.value
      })
  }

  handleChangeDob(date) {
    this.setState({
      dob:date
    })
  }

  handleChangeDLE(date) {
    this.setState({
      driver_license_expiration:date
    })
  }




  render() {
      function AddressFillDialog(props){
          const [isOpen, setIsOpen] = React.useState(false);
          const [addressList, setAddressList] = React.useState([]);
          const [noAddressFound, setNoAddressFound] = React.useState(false);
          const [checked, setChecked] = React.useState(0);
          const [withResult, setWithResult] = React.useState(false);
          var timeout = 0;

          const handleChange = (event) => {
              var currentSearch = event.target.value;
              if(currentSearch == ""){
                  setAddressList([]);
              }else{
                  if(timeout) clearTimeout(timeout);
                  timeout = setTimeout(() => {
                      axios.get('https://developers.onemap.sg/commonapi/search?searchVal='+currentSearch+'&returnGeom=Y&getAddrDetails=Y&pageNum=1')
                      .then(res => {
                          console.log(res);
                          setAddressList(res.data.results);
                          if(res.data.results.length <= 0){
                              setWithResult(false);
                          }
                      })
                  }, 300);


              }


          };


          const handleChangeList = (value) => () => {
              setChecked(value);
              props.handleSearchAddressChange(value);

          };



          return (
              <Dialog
                open={props.openSearchAddress}
                onClose={props.handleCloseAddress}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                fullWidth={true}
                maxWidth = {'md'}
              >
                <DialogTitle id="alert-dialog-slide-title">{"Search Your Address"}</DialogTitle>
                <DialogContent>
                  <DialogContentText className="dialogContentCustom">
                  <Grid item xs={12} sm={12}>
                    <OrangeTextField
                      required
                      label="ENTER YOUR POSTAL CODE OR LOCATION NAME"
                      fullWidth
                      autoComplete="given-name"
                      onChange={handleChange}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                      <List dense>
                          {addressList.map((value,i) => {
                          const labelId = "search-address-list-label-${i}";
                          return (
                          <div key={"addList-"+i}>
                              <ListItem key={value} button onClick={handleChangeList(value)}>
                                  <ListItemText className="search-address-list" id={labelId}
                                      primary={value.ADDRESS}
                                  />
                                  <ListItemSecondaryAction>
                                      <Radio
                                      edge="end"
                                      checked={checked == value}
                                      onChange={handleChangeList(value)}
                                      value={i}
                                      inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                  </ListItemSecondaryAction>
                              </ListItem>
                              <Divider component="li" />
                          </div>

                          );
                          })}
                      </List>
                  </Grid>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid item xs={12} align="right">
                        <span>Cant find your address?</span> &nbsp;&nbsp;
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={props.handleCloseAddress}
                        >
                          Add Address Manually
                        </Button>
                     </Grid>
                </DialogActions>
              </Dialog>
            );
      }
      function PersonalInfoForm(props) {
        const classes = useStyles();


        const [firstName, setFirstName] = React.useState(props.profileState.firstName2);
        const [lastName, setLastName] = React.useState(props.profileState.lastName2);
        const [nric, setNric] = React.useState(props.profileState.nric);
        const [gender, setGender] = React.useState(props.profileState.gender);
        const [phone, setPhone] = React.useState(props.profileState.phoneNumber);
        const [email, setEmail] = React.useState(props.profileState.email);
        const [businessName, setBusinessName] = React.useState(props.profileState.company_name);
        const [businessNameAcronym, setBusinessNameAcronym] = React.useState(props.profileState.company_name_acronym);

        const [roc, setRoc] = React.useState(props.profileState.company_roc);
        const [contactNumber, setContactNumber] = React.useState(props.profileState.company_contact);
        const [postalCode, setPostalCode] = React.useState(props.profileState.company_postal);
        const [houseAptB, setHouseAptB] = React.useState(props.profileState.houseAptB);
        const [blockhouse, setBlockhouse] = React.useState(props.profileState.blockhouse);
        const [subscription, setSubscription] = React.useState(props.profileState.customer_news_subscription);
        const [street, setStreet] = React.useState(props.profileState.street);
        const [floor, setFloor] = React.useState(props.profileState.floor);
        const [unit, setUnit] = React.useState(props.profileState.unit);
        const [buildingName, setBuildingName] = React.useState(props.profileState.buildingName);
        const [gstRegistered, setGstRegistered] = React.useState(props.profileState.gstRegistered);
        const [gstNo, setGstNo] = React.useState(props.profileState.gstNo);
        const [instruction, setInstruction] = React.useState(props.profileState.instruction);
        const [bankName, setBankName] = React.useState(props.profileState.customer_lessor_bankname);
        const [accountNo, setAccountNo] = React.useState(props.profileState.customer_lessor_bankaccountnumber);
        const [recipient, setRecipient] = React.useState(props.profileState.customer_lessor_recipientname);

        const [isOpen, setIsOpen] = React.useState(false);
        const [selectedDate, setSelectedDate] =  React.useState(props.profileState.dob);
        const [btnDisabled, setBtnDisabled] =  React.useState(false);
        const [openOTP, setOpenOTP] =  React.useState(false);
        const [verification, setVerification] =  React.useState("");
        const [uid, setUid] =  React.useState("");

        var disabledBusiness = true;
        var disabledMainGst = true;
        var disabledGst = true;
        var requiredBusiness = false;
        var requiredGst = false;
        if(businessName != ""){
            disabledBusiness = false;
            disabledMainGst = false;
            requiredBusiness = true;
        }else{
            disabledBusiness = false;
        }

        if(gstRegistered == "1"){
            disabledGst = false;
            requiredGst = true;
        }

        var checkSigned = parseInt(props.profileState.customer_agreement_signed);
        if(checkSigned == 1){
            disabledBusiness = true;
            disabledMainGst = false;
        }

        const [disabledFieldForBusiness, setDisabledFieldForBusiness] =  React.useState(disabledBusiness);
        const [requiredFieldForBusiness, setRequiredFieldForBusiness] =  React.useState(requiredBusiness);
        const [disabledFieldMainGst, setDisabledFieldMainGst] =  React.useState(disabledMainGst);
        const [disabledFieldForGst, setDisabledFieldForGst] =  React.useState(disabledGst);
        const [requiredFieldForGst, setRequiredFieldForGst] =  React.useState(requiredGst);

        const [openSearchAddress, setOpenSearchAddress] = React.useState(false);
        const [addressList, setAddressList] = React.useState([]);
        const [noAddressFound, setNoAddressFound] = React.useState(false);
        const [checked, setChecked] = React.useState(0);
        const [withResult, setWithResult] = React.useState(false);
        const [loadingSearch, setLoadingSearch] = React.useState(false);
        var timeout = 0;

        const handleChange = (event) => {
            var currentSearch = event.target.value;
            if(currentSearch == ""){
                setAddressList([]);
            }else{
                setLoadingSearch(true);
                if(timeout) clearTimeout(timeout);
                timeout = setTimeout(() => {
                    axios.get('https://developers.onemap.sg/commonapi/search?searchVal='+currentSearch+'&returnGeom=Y&getAddrDetails=Y&pageNum=1')
                    .then(res => {
                        console.log(res);
                        setAddressList(res.data.results);
                        if(res.data.results.length <= 0){
                            setWithResult(false);
                        }
                        setLoadingSearch(false);
                    })
                }, 300);


            }


        };

        const handleCloseOTP = () => {
            setOpenOTP(false);
        };


        const handleChangeVerification = otp => setVerification(otp);

        const handleSubmitOTP = () => {
            document.getElementById("profileUpdateSubmitBtn").click();
        };


        const handleClickOpenAddress = () => {
            setOpenSearchAddress(true);
        };

        const handleCloseAddress = () => {
          setOpenSearchAddress(false);
        }

        const handleChangeList = (selectedAddress) => () => {
            setChecked(selectedAddress);
            setOpenSearchAddress(false);

            setPostalCode(selectedAddress.POSTAL);
            setBlockhouse(selectedAddress.BLK_NO);
            setStreet(selectedAddress.ROAD_NAME);
            setBuildingName(selectedAddress.BUILDING);

        };


        const handleDateChange = (date) => {
           setSelectedDate(date);
           setIsOpen(false)
         };

        const handleChangeFirstName = (event, newValue) => {
          setFirstName(event.target.value);
        };
        const handleChangeLastName = (event, newValue) => {
          setLastName(event.target.value);
        };
        const handleChangeNric = (event, newValue) => {
          setNric(event.target.value);
        };
        const handleChangeGender = (event, newValue) => {
          setGender(event.target.value);
        };
        const handleChangeSubscription = (event, newValue) => {
          setSubscription(event.target.value);
        };
        const handleChangePhone = (event, newValue) => {
          setPhone(event.target.value);
        };
        const handleChangeEmail = (event, newValue) => {
          setEmail(event.target.value);
        };
        const handleChangeBusinessName = (event, newValue) => {
            setBusinessName(event.target.value);
            if(event.target.value != ""){
              setDisabledFieldForBusiness(false);
              setRequiredFieldForBusiness(true);
              setDisabledFieldMainGst(false);
              var strBusiness = event.target.value;
              var matches = strBusiness.match(/\b(\w)/g);
              var acronym = matches.join('');
              acronym = acronym.toUpperCase();
              setBusinessNameAcronym(acronym);
            }else{
                setDisabledFieldMainGst(true);
                setDisabledFieldForBusiness(true);
                setRequiredFieldForBusiness(false);
                setRoc("");
                setBusinessNameAcronym("");
            }
        };

        const handleChangeAcronym = (event, newValue) => {
          setBusinessNameAcronym(event.target.value);
        };
        const handleChangeRoc = (event, newValue) => {
          setRoc(event.target.value);
        };
        const handleChangeContactNumber = (event, newValue) => {
          setContactNumber(event.target.value);
        }

        const handleChangeBankName = (event, newValue) => {
          setBankName(event.target.value);
        };
        const handleChangeAccountNo = (event, newValue) => {
          setAccountNo(event.target.value);
        };
        const handleChangeRecipient = (event, newValue) => {
          setRecipient(event.target.value);
        }

        const handleChangePostalCode = (event, newValue) => {
          setPostalCode(event.target.value);
        };
        const handleChangeHouseAptB = (event, newValue) => {
          setHouseAptB(event.target.value);
        };
        const handleChangeBlockhouse = (event, newValue) => {
          setBlockhouse(event.target.value);
        };
        const handleChangeStreet = (event, newValue) => {
          setStreet(event.target.value);
        };
        const handleChangeFloor = (event, newValue) => {
          setFloor(event.target.value);
        };
        const handleChangeUnit = (event, newValue) => {
          setUnit(event.target.value);
        };
        const handleChangeBuildingName = (event, newValue) => {
          setBuildingName(event.target.value);
        }

        const handleChangeGstRegistered = (event, newValue) => {
          setGstRegistered(event.target.value);
          if(event.target.value != ""){
            setDisabledFieldForGst(false);
            setRequiredFieldForGst(true);
          }else{
            setDisabledFieldForGst(true);
            setRequiredFieldForGst(false);
            setGstNo("");
          }
        };
        const handleChangeGstNo= (event, newValue) => {
          setGstNo(event.target.value);

        }

        const handleChangeInstruction = (event, newValue) => {
          setInstruction(event.target.value);
        };


        const handleUpdateProfile = (e) => {
            e.preventDefault();
            setBtnDisabled(true);

            var firstName = e.target.elements.firstName2.value;
            var lastName = e.target.elements.lastName2.value;
            var email = e.target.elements.email.value;
            var phoneno = e.target.elements.phoneNumber.value;
            //   var licenseNo = e.target.elements.driver_license.value;
            //   var licenseExp = e.target.elements.driver_license_expiration.value;
            var nric = e.target.elements.nric.value;
            var gender = e.target.elements.gender.value;
            var dob = e.target.elements.dob.value;
            dob = moment(dob).format("YYYY-MM-DD");
            //   var purpose = e.target.elements.purpose.value;
            var subscription = e.target.elements.customer_news_subscription.value;
            var company_name = e.target.elements.company_name.value;
            var company_name_acronym = e.target.elements.company_name_acronym.value;
            var company_roc = e.target.elements.company_roc.value;
            var company_contact = e.target.elements.company_contact.value;
            //   var company_address = e.target.elements.company_address.value;
            var company_postal = e.target.elements.company_postal.value;
            var adress_hab = e.target.elements.houseAptB.value;
            var address_houseblock = e.target.elements.blockhouse.value;
            var address_street = e.target.elements.street.value;
            var address_floor = e.target.elements.floor.value;
            var address_unit = e.target.elements.unit.value;
            var address_building_name = e.target.elements.bname.value;

            var gstRegistered = e.target.elements.gstRegistered.value;
            var gstNo = e.target.elements.gstNo.value;
            var instruction = e.target.elements.instruction.value;

            var company_address = address_houseblock+' '+address_street;
            if(address_floor && address_unit){
            company_address = company_address+ ' #'+address_floor+'-'+address_unit;
            }
            company_address = company_address+' '+company_postal;

            var bankName = e.target.elements.bank_name.value;
            var accountNo = e.target.elements.account_no.value;
            var recipient = e.target.elements.recipient_name.value;


            var ctoken = localStorage.getItem('ctoken');
            var token = localStorage.getItem('token');
            var bodyFormData = new FormData();
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
            bodyFormData.append('jham_token', token);

            bodyFormData.append('customer_firstname', firstName);
            bodyFormData.append('customer_lastname', lastName);
            bodyFormData.append('customer_username', email);
            bodyFormData.append('customer_email', email);
            bodyFormData.append('customer_phonenumber', phoneno);
            bodyFormData.append('customer_gender', gender);
            bodyFormData.append('customer_dob', dob);
            bodyFormData.append('customer_nric', nric);
            bodyFormData.append('customer_driver_license', "");
            bodyFormData.append('customer_driver_license_expiration', "");
            bodyFormData.append('customer_purpose', "");
            bodyFormData.append('customer_news_subscription', subscription);


            bodyFormData.append('customer_company_contact', company_contact);
            bodyFormData.append('customer_company_address', company_address);
            bodyFormData.append('customer_company_postaladdress', company_postal);

            if(parseInt(props.profileState.customer_agreement_signed) == 0){
                bodyFormData.append('customer_company_name', company_name);
                bodyFormData.append('customer_company_name_acronym', company_name_acronym);
                bodyFormData.append('customer_company_roc', company_roc);
            }

            bodyFormData.append('customer_address_house_apt_bussiness', adress_hab);
            bodyFormData.append('customer_address_house_no_block', address_houseblock);
            bodyFormData.append('customer_address_street', address_street);
            bodyFormData.append('customer_address_floor', address_floor);
            bodyFormData.append('customer_address_unit', address_unit);
            bodyFormData.append('customer_address_building_name', address_building_name);
            bodyFormData.append('company_gst_registered', gstRegistered);
            bodyFormData.append('company_gst_no', gstNo);
            bodyFormData.append('customer_lessor_instruction', instruction);

            bodyFormData.append('customer_lessor_bankname', bankName);
            bodyFormData.append('customer_lessor_bankaccountnumber', accountNo);
            bodyFormData.append('customer_lessor_recipientname', recipient);
            bodyFormData.append('verification', verification);
            bodyFormData.append('uid', uid);


            axios({
                  method: 'post',
                  url: 'https://api.earental.com/account/my_profile_update',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {
                  var errors = "";
                  if(response.data.error > 0){

                      function ErrorMsg(props) {
                        return (
                            props.msgs.map((item,i) =>
                                <li key={"err-"+item.i}>{item.value}</li>
                            )

                        );
                      }


                      if(response.data.otp_required == 1){
                          store.addNotification({
                            title: "Invalid fields",
                            message: response.data.error_message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                              duration: 5000
                            }
                          });
                          setOpenOTP(true);
                          setUid(response.data.otp_uid);
                      }else{
                          store.addNotification({
                            title: "Invalid fields",
                            message: <ErrorMsg msgs={response.data.error_message} />,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                              duration: 5000
                            }
                          });
                      }

                  }else{

                      if(response.data.otp_required == 1){
                          setOpenOTP(true);
                          setUid(response.data.otp_uid);
                      }else{
                          store.addNotification({
                            title: "Great!",
                            message: "Information successfully updated.",
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                              duration: 5000,
                              onScreen: false
                            }
                          });
                          setUid("");
                          setOpenOTP(false);
                          setVerification("");
                      }


                  }

                  setBtnDisabled(false);
              })
              .catch((error) => {
                  //handle error
                //   if (error.response.status == 403) {
                 //
                //       store.addNotification({
                //         title: "Oops Something went wrong",
                //         message: "The action you have requested is not allowed. please re-load the page",
                //         type: "danger",
                //         insert: "top",
                //         container: "top-right",
                //         animationIn: ["animated", "fadeIn"],
                //         animationOut: ["animated", "fadeOut"],
                //         dismiss: {
                //           duration: 5000,
                //           onScreen: false
                //         }
                //       });
                 //
                //  }
                //  setBtnDisabled(false);

            });
        };

        const handleUpdateProfileInformation = (e) => {
            e.preventDefault();
            setBtnDisabled(true);

            const urlQuery = new URLSearchParams(window.location.search);
            const fromCheck = urlQuery.get('from');


            var firstName = e.target.elements.firstName2.value;
            var lastName = e.target.elements.lastName2.value;
            var email = e.target.elements.email.value;
            var phoneno = e.target.elements.phoneNumber.value;
            var nric = e.target.elements.nric.value;
            var gender = e.target.elements.gender.value;
            var dob = e.target.elements.dob.value;
            dob = moment(dob).format("YYYY-MM-DD");
            var subscription = e.target.elements.customer_news_subscription.value;

            var ctoken = localStorage.getItem('ctoken');
            var token = localStorage.getItem('token');
            var bodyFormData = new FormData();
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
            bodyFormData.append('jham_token', token);
            bodyFormData.append('action', "update_personal_info");
            bodyFormData.append('customer_firstname', firstName);
            bodyFormData.append('customer_lastname', lastName);
            bodyFormData.append('customer_username', email);
            bodyFormData.append('customer_email', email);
            bodyFormData.append('customer_phonenumber', phoneno);
            bodyFormData.append('customer_gender', gender);
            bodyFormData.append('customer_dob', dob);
            bodyFormData.append('customer_nric', nric);
            bodyFormData.append('customer_driver_license', "");
            bodyFormData.append('customer_driver_license_expiration', "");
            bodyFormData.append('customer_purpose', "");
            bodyFormData.append('customer_news_subscription', subscription);

            bodyFormData.append('verification', verification);
            bodyFormData.append('uid', uid);


            axios({
                  method: 'post',
                  url: 'https://api.earental.com/account/my_profile_update',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {
                  var errors = "";
                  if(response.data.error > 0){

                      function ErrorMsg(props) {
                        return (
                            props.msgs.map((item,i) =>
                                <li key={"err-"+item.i}>{item.value}</li>
                            )

                        );
                      }


                      if(response.data.otp_required == 1){
                          store.addNotification({
                            title: "Invalid fields",
                            message: response.data.error_message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                              duration: 5000
                            }
                          });
                          setOpenOTP(true);
                          setUid(response.data.otp_uid);
                      }else{
                          if(Array.isArray(response.data.error_message)){
                              store.addNotification({
                                title: "Invalid fields",
                                message: <ErrorMsg msgs={response.data.error_message} />,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                  duration: 5000
                                }
                              });
                          }else{
                              store.addNotification({
                                title: "Error",
                                message: response.data.error_message,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                  duration: 5000
                                }
                              });
                          }

                      }

                  }else{

                      if(response.data.otp_required == 1){
                          setOpenOTP(true);
                          setUid(response.data.otp_uid);
                      }else{
                          store.addNotification({
                            title: "Great!",
                            message: "Personal Information successfully updated.",
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                              duration: 5000,
                              onScreen: false
                            }
                          });
                          setUid("");
                          setOpenOTP(false);
                          setVerification("");

                          if(fromCheck){
                              window.location.href = "/vehicle-details/"+fromCheck
                          }
                      }


                  }

                  setBtnDisabled(false);
              })
              .catch((error) => {
                  store.addNotification({
                    title: "Error!",
                    message: "Something went wrong,please try again.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: false
                    }
                  });

            });
        };

        const handleUpdateBillingDetails = (e) => {
            e.preventDefault();
            setBtnDisabled(true);

            var company_name = e.target.elements.company_name.value;
            var company_name_acronym = e.target.elements.company_name_acronym.value;
            var company_roc = e.target.elements.company_roc.value;
            var company_contact = e.target.elements.company_contact.value;
            //   var company_address = e.target.elements.company_address.value;
            var company_postal = e.target.elements.company_postal.value;
            var adress_hab = e.target.elements.houseAptB.value;
            var address_houseblock = e.target.elements.blockhouse.value;
            var address_street = e.target.elements.street.value;
            var address_floor = e.target.elements.floor.value;
            var address_unit = e.target.elements.unit.value;
            var address_building_name = e.target.elements.bname.value;

            var gstRegistered = e.target.elements.gstRegistered.value;
            var gstNo = e.target.elements.gstNo.value;
            var instruction = e.target.elements.instruction.value;

            var company_address = address_houseblock+' '+address_street;
            if(address_floor && address_unit){
            company_address = company_address+ ' #'+address_floor+'-'+address_unit;
            }
            company_address = company_address+' '+company_postal;

            var bankName = e.target.elements.bank_name.value;
            var accountNo = e.target.elements.account_no.value;
            var recipient = e.target.elements.recipient_name.value;


            var ctoken = localStorage.getItem('ctoken');
            var token = localStorage.getItem('token');
            var bodyFormData = new FormData();
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
            bodyFormData.append('jham_token', token);
            bodyFormData.append('action', "update_billing_details");

            bodyFormData.append('customer_company_contact', company_contact);
            bodyFormData.append('customer_company_address', company_address);
            bodyFormData.append('customer_company_postaladdress', company_postal);

            if(parseInt(props.profileState.customer_agreement_signed) == 0){
                bodyFormData.append('customer_company_name', company_name);
                bodyFormData.append('customer_company_name_acronym', company_name_acronym);
                bodyFormData.append('customer_company_roc', company_roc);
            }

            bodyFormData.append('customer_address_house_apt_bussiness', adress_hab);
            bodyFormData.append('customer_address_house_no_block', address_houseblock);
            bodyFormData.append('customer_address_street', address_street);
            bodyFormData.append('customer_address_floor', address_floor);
            bodyFormData.append('customer_address_unit', address_unit);
            bodyFormData.append('customer_address_building_name', address_building_name);
            bodyFormData.append('company_gst_registered', gstRegistered);
            bodyFormData.append('company_gst_no', gstNo);
            bodyFormData.append('customer_lessor_instruction', instruction);

            bodyFormData.append('customer_lessor_bankname', bankName);
            bodyFormData.append('customer_lessor_bankaccountnumber', accountNo);
            bodyFormData.append('customer_lessor_recipientname', recipient);



            axios({
                  method: 'post',
                  url: 'https://api.earental.com/account/my_profile_update',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {
                  var errors = "";
                  if(response.data.error > 0){

                      function ErrorMsg(props) {
                        return (
                            props.msgs.map((item,i) =>
                                <li key={"err-"+item.i}>{item.value}</li>
                            )

                        );
                      }

                      if(Array.isArray(response.data.error_message)){
                          store.addNotification({
                            title: "Invalid fields",
                            message: <ErrorMsg msgs={response.data.error_message} />,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                              duration: 5000
                            }
                          });
                      }else{
                          store.addNotification({
                            title: "Error",
                            message: response.data.error_message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                              duration: 5000
                            }
                          });
                      }


                  }else{

                      store.addNotification({
                        title: "Great!",
                        message: "Billing Details successfully updated.",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: false
                        }
                      });



                  }

                  setBtnDisabled(false);
              })
              .catch((error) => {
                  store.addNotification({
                    title: "Error!",
                    message: "Something went wrong,please try again.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: false
                    }
                  });
            });
        };

        const handleUpdateAcra = (e) => {
            e.preventDefault();
            setBtnDisabled(true);
            var ctoken = localStorage.getItem('ctoken');
            var token = localStorage.getItem('token');
            var bodyFormData = new FormData();
            bodyFormData.append('access_token', ctoken);
            bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
            bodyFormData.append('jham_token', token);


            var acra = 0;
            if (typeof e.target.acra.files[0] !== 'undefined'){
                e.target.acra.files[0]['sequence'] = 0;
                acra = e.target.acra.files[0];
            }



            bodyFormData.append('acra[0]', acra);



            axios({
                  method: 'post',
                  url: 'https://api.earental.com/account/update_acra',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {

                 if(response.data.error > 0){
                     function ErrorMsg(props) {
                       return (
                           props.msgs.map((item,i) =>
                               <li key={"err-"+item.i}>{item.value}</li>
                           )

                       );
                     }
                     store.addNotification({
                       title: "Invalid information",
                       message: <ErrorMsg msgs={response.data.error_message} />,
                       type: "danger",
                       insert: "top",
                       container: "top-right",
                       animationIn: ["animated", "fadeIn"],
                       animationOut: ["animated", "fadeOut"],
                       dismiss: {
                         duration: 20000,
                         onScreen: false
                       }
                     });

                     setBtnDisabled(false);
                 }else{

                     store.addNotification({
                       title: "Great!",
                       message: "File successfully submitted.",
                       type: "success",
                       insert: "top",
                       container: "top-right",
                       animationIn: ["animated", "fadeIn"],
                       animationOut: ["animated", "fadeOut"],
                       dismiss: {
                         duration: 5000,
                         onScreen: false
                       }
                     });

                 }

                 document.getElementById("acraFile").value = "";
                 setTimeout(
                   () => window.location.reload(false),
                   1000
                 );


              })
              .catch((error) => {
                  //handle error
                  store.addNotification({
                    title: "Oops!",
                    message: "Something went wrong, please try again.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 20000,
                      onScreen: false
                    }
                  });
                  setBtnDisabled(false);

            });
        };




        // const handleChangeFirstName = (event, newValue) => {
        //   setFirstName(newValue);
        // };

        return (
            <>
                <Dialog onClose={handleCloseOTP} aria-labelledby="customized-dialog-title" open={openOTP}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseOTP}>
                        OTP Required
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            Kindly key-in the verification code that was sent in your phone number
                        </Typography>
                        <OtpInput
                            value={verification}
                            onChange={handleChangeVerification}
                            numInputs={6}
                            inputStyle={{margin:"5px",width:"45px",fontSize:"30px",textAlign:"center",padding:"5px"}}
                            separator={<span>-</span>}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                          onClick={handleSubmitOTP}
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={btnDisabled}
                        >
                        {btnDisabled && <CircularProgress size={14} />}
                        {!btnDisabled && 'Submit'}

                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                  open={openSearchAddress}
                  onClose={handleCloseAddress}
                  keepMounted
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                  fullWidth={true}
                  maxWidth = {'md'}
                >
                  <DialogTitle id="alert-dialog-slide-title">{"Search Your Address"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText className="dialogContentCustom">
                    <Grid item xs={12} sm={12}  className={classes.newAddressForm}>
                      <TextField
                        required
                        label="ENTER YOUR POSTAL CODE OR LOCATION NAME"
                        fullWidth
                        onChange={handleChange}
                        className={classes.searchAddressInput}
                        size="small"
                        InputProps={{
                          endAdornment: loadingSearch && (
                            <InputAdornment position="end">
                              <CircularProgress size="1.5rem" />
                            </InputAdornment>
                          )
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <List className={classes.searchAddressResult}>
                            {addressList.map((value,i) => {
                                if (value.POSTAL != "NIL") {
                                    const labelId = "search-address-list-label-${i}";
                                    return (
                                    <div key={"addList-"+i}>
                                        <ListItem key={value} button onClick={handleChangeList(value)}>
                                            <ListItemText className="search-address-list" id={labelId}
                                                primary={value.ADDRESS}
                                            />
                                            <ListItemSecondaryAction>
                                                <Radio
                                                edge="end"
                                                checked={checked == value}
                                                onChange={handleChangeList(value)}
                                                value={i}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider component="li" />
                                    </div>

                                    );
                                }
                            })}
                        </List>
                    </Grid>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                      <Grid item xs={12} align="right">
                          <span>Cant find your address?</span> &nbsp;&nbsp;
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCloseAddress}
                          >
                            Add Address Manually
                          </Button>
                       </Grid>
                  </DialogActions>
                </Dialog>

                <form className={classes.root}  autoComplete="off" onSubmit={handleUpdateProfileInformation} >
                      <h4>Personal Information</h4>

                      <hr />
                      <Grid container spacing={1} >
                          <Grid item md={4} sm={12} xs={12}>
                              <TextField required value={firstName} label="First Name" name="firstName2" onChange={handleChangeFirstName} className="profile-inputs" fullWidth  />
                          </Grid>
                          <Grid item md={4} sm={12} xs={12}>
                              <TextField required value={lastName} label="Last Name" name="lastName2" onChange={handleChangeLastName} className="profile-inputs"   fullWidth />
                          </Grid>
                      </Grid>


                      <Grid container spacing={1} >
                          <Grid item md={4} sm={12} xs={12}>
                              <TextField required value={nric} label="NRIC" name="nric" onChange={handleChangeNric} className="profile-inputs" fullWidth  />
                          </Grid>
                          <Grid item md={4} sm={12} xs={12}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} className="dobCalendar">
                              <KeyboardDatePicker
                                 autoOk={true}
                                 disableFuture
                                 variant="inline"
                                 label="Date of birth"
                                 format="MMM d, yyyy"
                                 name="dob"
                                 className="dobCalendar"
                                 value={selectedDate}
                                 onChange={handleDateChange}
                                 fullWidth
                                 required
                                 className="profile-inputs"
                                 KeyboardButtonProps={{
                                   'aria-label': 'change date',
                                 }}
                                 KeyboardButtonProps={{
                                    onFocus: e => {
                                      setIsOpen(true);
                                    }
                                  }}
                                  PopoverProps={{
                                    disableRestoreFocus: true,
                                    onClose: () => {
                                      setIsOpen(false);
                                    }
                                  }}
                                  InputProps={{
                                    onFocus: () => {
                                      setIsOpen(true);
                                    }
                                  }}
                                  open={isOpen}

                               />
                           </MuiPickersUtilsProvider>
                          </Grid>
                      </Grid>
                        <Alert variant="outlined" severity="info">
                            Note: Changing E-mail/Phone Number will require OTP verification.
                        </Alert>
                        <Grid container spacing={1} >
                            <Grid item md={4} sm={12} xs={12}>
                                <TextField required value={email} label="E-mail" name="email" onChange={handleChangeEmail} className="profile-inputs" fullWidth  />
                            </Grid>
                            <Grid item md={4} sm={12} xs={12}>
                                <TextField required value={phone} label="Phone Number" name="phoneNumber" onChange={handleChangePhone} className="profile-inputs" fullWidth />
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} >
                            <Grid item md={4} sm={12} xs={12}>
                                <FormControl component="fieldset">
                                    <label>Gender</label>
                                    <RadioGroup row aria-label="gender" label="First Name" onChange={handleChangeGender} name="gender" value={gender} className="profile-inputs" >
                                        <FormControlLabel value="Male" control={<Radio color="primary" />} label="Male" />
                                        <FormControlLabel value="Female" control={<Radio color="primary" />} label="Female" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item md={4} sm={12} xs={12}>
                                <FormControl component="fieldset">
                                    <label>Subscribe to our news letter</label>
                                    <RadioGroup row aria-label="customer_news_subscription" onChange={handleChangeSubscription } value={subscription} name="customer_news_subscription" className="profile-inputs" >
                                        <FormControlLabel value="1" control={<Radio color="primary" />} label="Yes" />
                                        <FormControlLabel value="0" control={<Radio color="danger" />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Button
                          id="profileUpdateSubmitBtn"
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={btnDisabled}
                        >
                        {btnDisabled && <CircularProgress size={14} />}
                        {!btnDisabled && 'Update Personal Information'}

                        </Button>

                    </form>
                    <br />
                    <br />
                    <form className={classes.root}  autoComplete="off" onSubmit={handleUpdateBillingDetails} >
                      <Grid container>
                        <Grid item xs={7}>
                            <h4>Lessor Billing Details</h4>
                        </Grid>
                        <Grid item xs={5} align="right">
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={handleClickOpenAddress}
                              endIcon={<SearchIcon>Search Address</SearchIcon>}
                              style={{fontSize:"15px"}}
                            >
                              Search Address
                            </Button>
                         </Grid>
                         </Grid>
                      <hr />
                      <Alert variant="outlined" severity="info">
                          Note: Business Name,Acronym & ROC cannot be changed once you agree in lessor agreement.
                      </Alert>
                      <Grid container spacing={1} >
                          <Grid item md={4} sm={12} xs={12}>
                              <TextField value={businessName} label="Business Name" name="company_name" disabled={disabledBusiness} onChange={handleChangeBusinessName} className="profile-inputs" fullWidth   />
                          </Grid>
                          <Grid item md={4} sm={12} xs={12}>
                              <TextField value={businessNameAcronym} label="Business Acronym" name="company_name_acronym" onChange={handleChangeAcronym} disabled={disabledFieldForBusiness} required={requiredBusiness}  className="profile-inputs" fullWidth   />
                          </Grid>
                          <Grid item md={4} sm={12} xs={12}>
                              <TextField value={roc} label="ROC" name="company_roc" onChange={handleChangeRoc} disabled={disabledFieldForBusiness} required={requiredBusiness} className="profile-inputs" fullWidth   />
                          </Grid>
                      </Grid>

                      <Grid container spacing={1} >
                          <Grid item md={4} sm={12} xs={12}>
                              <FormControl className={classes.formControl} fullWidth>
                                  <InputLabel id="gst-registered">GST Registered</InputLabel>
                                  <Select
                                      disabled={disabledFieldMainGst}
                                      required={requiredBusiness}
                                      labelId="gst-registered"
                                      name="gstRegistered"
                                      value={gstRegistered}
                                      onChange={handleChangeGstRegistered}
                                      className="profile-inputs"

                                      >
                                      <MenuItem value={1}>Yes</MenuItem>
                                      <MenuItem value={0}>No</MenuItem>
                                  </Select>
                              </FormControl>
                          </Grid>
                          <Grid item md={4} sm={12} xs={12}>
                              <TextField required value={gstNo} label="GST No." name="gstNo" onChange={handleChangeGstNo} disabled={disabledFieldForGst} required={requiredFieldForGst} className="profile-inputs" fullWidth  />
                          </Grid>
                      </Grid>

                      <Grid container spacing={1} >
                          <Grid item md={4} sm={12} xs={12}>
                              <TextField value={bankName} label="Bank Name" name="bank_name"  onChange={handleChangeBankName} required className="profile-inputs" fullWidth  />
                          </Grid>
                          <Grid item md={4} sm={12} xs={12}>
                              <TextField value={accountNo} label="Account No." name="account_no" onChange={handleChangeAccountNo}  required className="profile-inputs" fullWidth  />
                          </Grid>
                          <Grid item md={4} sm={12} xs={12}>
                              <TextField value={recipient} label="Recipient's Name" name="recipient_name" onChange={handleChangeRecipient}  required className="profile-inputs" fullWidth  />
                          </Grid>
                      </Grid>

                      <Grid container spacing={1} >
                          <Grid item md={4} sm={12} xs={12}>
                              <TextField required value={contactNumber} label="Contact Number" name="company_contact" onChange={handleChangeContactNumber} className="profile-inputs" fullWidth  />
                          </Grid>
                          <Grid item md={4} sm={12} xs={12}>
                              <TextField required value={postalCode} label="Postal Code" name="company_postal" onChange={handleChangePostalCode} className="profile-inputs" fullWidth  />
                          </Grid>
                      </Grid>


                      <Grid container spacing={1} >
                          <Grid item md={4} sm={12} xs={12}>
                              <FormControl className={classes.formControl} fullWidth>
                              <InputLabel id="demo-simple-select-label">House/Apt/Business*</InputLabel>
                                  <Select
                                      required
                                      labelId="demo-simple-select-label"
                                      name="houseAptB"
                                      value={houseAptB}
                                      onChange={handleChangeHouseAptB}
                                      className="profile-inputs"
                                      >
                                      <MenuItem value={"Apartment"}>Apartment</MenuItem>
                                      <MenuItem value={"House"}>House</MenuItem>
                                      <MenuItem value={"Business"}>Business</MenuItem>
                                  </Select>
                              </FormControl>
                          </Grid>
                          <Grid item md={4} sm={12} xs={12}>
                              <TextField required value={blockhouse} label="Block/House No."  name="blockhouse" onChange={handleChangeBlockhouse} className="profile-inputs" fullWidth  />
                          </Grid>
                          <Grid item md={4} sm={12} xs={12}>
                              <TextField required value={street} label="Street" name="street" onChange={handleChangeStreet} className="profile-inputs" fullWidth  />
                          </Grid>

                          <Grid item md={4} sm={12} xs={12}>
                              <TextField value={floor} label="Floor" name="floor" onChange={handleChangeFloor} className="profile-inputs"  fullWidth />
                          </Grid>
                          <Grid item md={4} sm={12} xs={12}>
                              <TextField value={unit} label="Unit" name="unit" onChange={handleChangeUnit} className="profile-inputs" fullWidth  />
                          </Grid>
                          <Grid item md={4} sm={12} xs={12}>
                              <TextField value={buildingName} label="Building Name" name="bname" onChange={handleChangeBuildingName} className="profile-inputs" fullWidth  />
                          </Grid>
                      </Grid>

                      <br />
                      <h4>Instruction (For Lessor)</h4>
                      <hr />

                      <TextField
                        label="Message"
                        multiline
                        rows={4}
                        name="instruction"
                        variant="outlined"
                        fullWidth={true}
                        onChange={handleChangeInstruction}
                        className="profile-inputs"
                        value={instruction}
                      />

                      <br />
                      <br />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={btnDisabled}
                      >
                      {btnDisabled && <CircularProgress size={14} />}
                      {!btnDisabled && 'Update Billing Details'}

                      </Button>

                </form>
                <br />
                <form className={classes.root}  onSubmit={handleUpdateAcra} autoComplete="off" >
                    <h4>Lessor ACRA (Business Registration License)</h4>
                    <hr />
                    {props.profileState.customer_acra && (
                        <div className="col-xs-12 text-right">
                            <ButtonGroup color="primary" aria-label="contained primary button group">
                                <Button varien size="small" color="primary" href={"https://api.earental.com/uploads/acra/"+props.profileState.customer_acra} target="_blank"
                                endIcon={<PictureAsPdfRoundedIcon />}
                                >Current ACRA</Button>
                            </ButtonGroup>
                        </div>
                    )}
                    <TextField
                        variant="outlined"
                        type='file'
                        id="acraFile"
                        required
                        name="acra"
                        fullWidth
                        inputProps={{ accept: 'application/pdf' }}
                        label="PDF format"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={btnDisabled}
                    >
                    {btnDisabled && <CircularProgress size={14} />}
                    {!btnDisabled && 'Submit File'}

                    </Button>
                </form>


            </>
        );
      }

      function TabPanel(props) {
          const { children, value, index, ...other } = props;

          return (
            <div
              role="tabpanel"
              hidden={value !== index}
              id={`simple-tabpanel-${index}`}
              aria-labelledby={`simple-tab-${index}`}
              {...other}
            >
              {value === index && (
                <Box p={3}>
                  <Typography>{children}</Typography>
                </Box>
              )}
            </div>
          );
        }

        TabPanel.propTypes = {
          children: PropTypes.node,
          index: PropTypes.any.isRequired,
          value: PropTypes.any.isRequired,
        };

        function a11yProps(index) {
          return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
          };
        }

    function SimpleTabs(props) {
      const [value, setValue] = React.useState(0);

      const handleChange = (event, newValue) => {
        setValue(newValue);
      };

      return (
        <div >
          <AppBar position="static">
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Personal Information" {...a11yProps(0)} />
              <Tab label="Change Password" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            {props.personalInfo}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {props.changePassword}
          </TabPanel>
        </div>
      );
    }


    function SimpleTabs(props) {
          const [value, setValue] = React.useState(props.currentTab);

          const handleChange = (event, newValue) => {
            setValue(newValue);
          };

          return (
            <div >
              <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                  <Tab label="Personal Information" {...a11yProps(0)} />
                  <Tab label="Change Password" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                {props.personalInfo}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {props.changePassword}
              </TabPanel>
            </div>
          );
        }
    return (
        <>
        <AddressFillDialog openSearchAddress={this.state.openSearchAddress} handleSearchAddressChange={this.handleSearchAddressChange} handleCloseAddress={this.handleCloseAddress} />
        <SimpleTabs
            currentTab={this.state.currentTab}
            personalInfo={<PersonalInfoForm profileState={this.state} handleUpdateProfile={this.handleUpdateProfile} handleInputChange={this.handleInputChange} handleClickOpenAddress={this.handleClickOpenAddress}  />}
            changePassword={
                <form onSubmit={ this.handleChangePassword } autoComplete="off">
                    <div className="form-group form-group-icon-left">
                        <TextField required label="Password" name="password" type="password" fullWidth={true} className="profile-inputs" autoComplete="new-password"  />
                    </div>
                    <div className="form-group form-group-icon-left">
                        <TextField required label="New Password" name="newpassword" type="password" fullWidth={true} className="profile-inputs" autoComplete="new-password"   />
                    </div>
                    <div className="form-group form-group-icon-left">
                        <TextField required label="New Password Again" name="newpasswordagain" type="password" fullWidth={true} className="profile-inputs" autoComplete="new-password"  />
                    </div>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="btn pull-right btn-primary"
                      disabled={this.state.buttonLoading}
                    >
                    {this.state.buttonLoading && <CircularProgress size={14} />}
                    {!this.state.buttonLoading && 'Change Password'}

                    </Button>
                </form>
            }
         />

        <div className="gap"></div>

        </>
    );
  }
}

export default Settings
