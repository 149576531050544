import React, { Component } from "react";
import aboutTopBanner from './../assets/img/about-us.jpg';
import aboutParallaxBanner from './../assets/img/parallax-about.jpg';
import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";
import "./../assets/css/aboutus.css";

var aboutTopBannerImg = {
  backgroundImage: "url(" + aboutTopBanner + ")"
};

var aboutParallaxBannerImg = {
  backgroundImage: "url(" + aboutParallaxBanner + ")"
};


class Aboutus extends Component {

    constructor (props) {
      super(props)
      ReactGA.set({ page: window.location.pathname });
      ReactGA.pageview(window.location.pathname);

      this.state = {
        startDate: new Date(),
        endDate: new Date(),
        MetaTitle:"EARental Singapore Online Van, Truck, Lorry Rental/Hire/Lease",
        MetaDescription:"EARental is Singapore No.1 Online Van, Lorry & Truck commercial vehicles for lease for businesses and individuals. Hassle-Free, book and reserve your vehicle Online Now!",
        MetaKeywords:"online rental, van rental, lorry rental, truck rental, commercial vehicles for rent, leasing, daily rental, weekly rental, weekend rental, monthly rental, yearly rental, short term rental, long term rental, cargo van rental singapore, cheap van rental, van rental price, Toyota hiace van rental singapore, van rental daily, auto van rental singapore, commercial van for rent, van rental near me, daily van rental singapore",
        Canonical:"https://www.earental.com/about-us",
      };


    }



    componentDidMount() {
        window.scrollTo(0, 0);
        // ReactGA.event({
        //   category: 'Checkout',
        //   action: 'Payment',
        // });
    }

  render() {
    return (
        <div className="about-us-cont">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="title" content={this.state.MetaTitle} />
                <meta content={this.state.MetaTitle} property="og:title" />
                <meta name="description" content={this.state.MetaDescription} />
                <meta content={this.state.MetaDescription} property="og:description" />
                <meta name="keywords" content={this.state.MetaKeywords} />
                <meta name="robots" content="index, follow" />
                <title>{this.state.MetaTitle}</title>
                <link rel="canonical" href={this.state.Canonical} />
            </Helmet>
            <div className="bg-holder">
                <div className="bg-mask"></div>
                <div className="bg-blur" style={aboutTopBannerImg}></div>
                <div className="bg-content">
                    <div className="container">
                        <div className="gap gap-big text-center text-white">
                            <h2 className="text-uc mb20">No calling + No hustle = Hassle-Free</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gap"></div>
            <div className="container text-center">
                <div className="row">
                    <div className="col-md-12">
                        <p className="text-bigger">Why buy when you can lease? Owning a vehicle in Singapore is costly with high interest rate, depreciation, maintenance and many more. With a lease, we will take care of you. </p>
                        <p className="text-bigger">At EARental, we’ve made leasing transparent. Simply search for the desire vehicle, the vehicle you see will be the exact vehicle you will be leasing. Select the lease period, make payment, wait for confirmation, and we shall deliver the vehicle to you upon request.</p>
                    </div>
                </div>
                <div className="gap"></div>
            </div>
            <div className="bg-holder">
                <div className="bg-parallax" style={aboutParallaxBannerImg}></div>
                <div className="bg-mask"></div>
                <div className="bg-holder-content">
                    <div className="container text-center">
                        <div className="gap gap-big text-white">
                            <div className="row">
                                <div className="col-md-10 col-md-offset-1">
                                    <h2>We strive to provide the best service possible!</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container text-center">
                <div className="gap"></div>
                <h2>Why Rent From Us</h2>
                <div className="gap"></div>
                <div className="text-center row">
                    <div className="col-md-12">
                        <div className="row row-wrap" data-gutter="60">
                            <div className="col-md-4">
                                <div className="thumb">
                                    <header className="thumb-header"><i className="fa fa-wrench box-icon-gray box-icon-center round box-icon-border  box-icon-info box-icon-big animate-icon-border-rise round"></i>
                                    </header>
                                    <div className="thumb-caption">
                                        <h5 className="thumb-title"><span className="text-darken">Maintainance</span></h5>
                                        <p className="thumb-desc">All vehicles are properly maintained and serviced regularly.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="thumb">
                                    <header className="thumb-header"><i className="fa fa-search box-icon-gray box-icon-center round box-icon-border  box-icon-info box-icon-big animate-icon-border-rise round"></i>
                                    </header>
                                    <div className="thumb-caption">
                                        <h5 className="thumb-title"><span className="text-darken">Inspection</span></h5>
                                        <p className="thumb-desc">Each vehicle must pass strict inspection before rented out.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="thumb">
                                    <header className="thumb-header"><i className="fa fa-thumbs-o-up box-icon-gray box-icon-center round box-icon-border  box-icon-info box-icon-big animate-icon-border-rise round"></i>
                                    </header>
                                    <div className="thumb-caption">
                                        <h5 className="thumb-title"><span className="text-darken">Online Booking</span></h5>
                                        <p className="thumb-desc">Hassle-Free 24/7 Online Booking System.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gap"></div>
                <h2 className="mb20">We Care for Your Safety</h2>
            </div>
            <div className="row row-no-gutter">
                <div className="col-md-3">
                    <img src="img/bts01.jpg" alt="" title="" />
                </div>
                <div className="col-md-3">
                    <img src="img/bts02.jpg" alt="" title="" />
                </div>
                <div className="col-md-3">
                    <img src="img/bts03.jpg" alt="" title="" />
                </div>
                <div className="col-md-3">
                    <img src="img/bts04.jpg" alt="" title="" />
                </div>
                <div className="col-md-3">
                    <img src="img/bts05.jpg" alt="" title="" />
                </div>
                <div className="col-md-3">
                    <img src="img/bts06.jpg" alt="" title="" />
                </div>
                <div className="col-md-3">
                    <img src="img/bts07.jpg" alt="" title="" />
                </div>
                <div className="col-md-3">
                    <img src="img/bts08.jpg" alt="" title="" />
                </div>
            </div>


            <div className="container">
            </div>

        </div>
    );
  }
}

export default Aboutus;
