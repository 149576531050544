import React, { Component } from "react";


import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Helmet} from "react-helmet";
import "./../assets/css/aboutus.css";

class Faq extends Component {

    constructor (props) {
      super(props)
      this.state = {
        topics: [
            { title: 'Common FAQ',anchor:"faq-1"},
            { title: 'Who can rent?',anchor:"faq-2"},
            { title: 'What document need to bring when collection?',anchor:"faq-3" },
            { title: 'Do I need to pay a deposit?',anchor:"faq-4"},
            { title: 'When do I get my deposit back?',anchor:"faq-5"},
            { title: 'Is there a penalty if I cancel my booking?',anchor:"faq-6" },
            { title: 'Is there a penalty if I cancel my lease earlier?',anchor:"faq-7"},
            { title: 'Returning of vehicle.',anchor:"8"},
            { title: 'Do you allow rental for personal usage?',anchor:"faq-9" },
            { title: 'Can multiple people drive the vehicle?',anchor:"faq-10" },
            { title: 'What is the late-return policy?',anchor:"faq-11" },
            { title: 'What happen in the event of an accident/car breakdown?',anchor:"faq-12"},
            { title: 'Road speeding and parking fines',anchor:"faq-13"},
            { title: 'Can I drive the vehicle to Malaysia?',anchor:"faq-14" },
            { title: 'Is fuel included in the lease?',anchor:"faq-15" },
            { title: 'Is insurance included in the lease?',anchor:"faq-16"},
            { title: 'Do I have to pay the insurance excess during an accident?',anchor:"faq-17"},
            { title: 'What happen if the vehicle is not the same as the one listed on the website?',anchor:"faq-18" },
            { title: 'Is there any listing fee on EARental?',anchor:"faq-19" },
            { title: 'What is the prerequisite to become a Lessor on EARental?',anchor:"faq-20"},
            { title: 'How do I list my vehicle on EARental?',anchor:"faq-21"},
            { title: 'When do I get back the deposit?',anchor:"faq-22" },
            { title: 'Can I upload a standard vehicle image?',anchor:"faq-23" },
            { title: 'Do I have to provide full insurance coverage?',anchor:"faq-24"},
            { title: 'What happen if the vehicle is not available?',anchor:"faq-25"},
            { title: 'How & when do I get paid?',anchor:"faq-26"},
        ],
        MetaTitle:"EARental | FAQ",
        MetaDescription:"EARental is Singapore No.1 Online Van, Lorry & Truck commercial vehicles for lease for businesses and individuals. Hassle-Free, book and reserve your vehicle Online Now!",
        MetaKeywords:"Faq,online rental, van rental, lorry rental, truck rental, commercial vehicles for rent, leasing, daily rental, weekly rental, weekend rental, monthly rental, yearly rental, short term rental, long term rental, cargo van rental singapore, cheap van rental, van rental price, Toyota hiace van rental singapore, van rental daily, auto van rental singapore, commercial van for rent, van rental near me, daily van rental singapore",
        Canonical:"https://www.earental.com/content-policy",
      };
      this.handleAnchor = this.handleAnchor.bind(this);
    }


  componentDidMount() {
      window.scrollTo(0, 0)
    }

    handleAnchor = (e,values) => {
        if(values){
            const yourElement = document.getElementById(values.anchor);
            const y = yourElement.getBoundingClientRect().top + window.pageYOffset - 50;

            window.scrollTo({top: y, behavior: 'smooth'});
        }

    };
  render() {

    return (
        <div className="faq-cont">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="title" content={this.state.MetaTitle} />
                <meta content={this.state.MetaTitle} property="og:title" />
                <meta name="description" content={this.state.MetaDescription} />
                <meta content={this.state.MetaDescription} property="og:description" />
                <meta name="keywords" content={this.state.MetaKeywords} />
                <meta name="robots" content="index, follow" />
                <title>{this.state.MetaTitle}</title>
                <link rel="canonical" href={this.state.Canonical} />
            </Helmet>
            <div className="container">
                <h1 className="page-title">Frequently Ask Question</h1>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <aside className="sidebar-left">
                            <div className="sidebar-widget">
                                <div className="Form" style={{fontSize:"20px" }}>
                                    <Autocomplete
                                      id="search-topic"
                                      options={this.state.topics}
                                      getOptionLabel={(option) => option.title}
                                      style={{ width: 250,fontSize:"20px" }}
                                      onChange={this.handleAnchor}
                                      renderInput={(params) => <TextField {...params} label="Questions" variant="outlined" />}
                                    />
                                </div>
                            </div>
                            <div className="sidebar-widget isHidden">
                                <h4>Categories</h4>
                                <ul className="icon-list list-category">
                                    <li><a href="#"><i className="fa fa-angle-right"></i>Rental Rates <small >(79)</small></a>
                                    </li>
                                    <li><a href="#"><i className="fa fa-angle-right"></i>Lorry <small >(97)</small></a>
                                    </li>
                                    <li><a href="#"><i className="fa fa-angle-right"></i>Truck <small >(100)</small></a>
                                    </li>
                                    <li><a href="#"><i className="fa fa-angle-right"></i>Van <small >(74)</small></a>
                                    </li>
                                    <li><a href="#"><i className="fa fa-angle-right"></i>Other <small >(94)</small></a>
                                    </li>
                                </ul>
                            </div>
                        </aside>
                    </div>
                    <div className="col-md-9">
                        <article className="post">
                            <header className="post-header"></header>

                            <div className="post-inner">
                                <h4 id="faq-1" className="post-title text-darken">Common FAQ</h4>
                                <p>Through our years of experience in the industry, we have been able to consolidate and accumulate the common questions. We have categorized them for your browsing convenience so do check out the relevant categories to find the information you need.</p>

                                <div className="gap gap-small"></div>
                                <h4 className="post-title text-darken">Lessee</h4>
                                <hr />
                                <h4 id="faq-2">Who can rent?</h4>
                                <p>The driver must be between 24 – 65 years of age with valid Singapore class 3 driver’s license and minimum 2 years of driving experience.</p>

                                <hr />
                                <h4 id="faq-3">What document need to bring when collection?</h4>
                                <p>You need to bring the following documents:
                                    <ul>
                                        <li>- NRIC</li>
                                        <li>- Driving License</li>
                                    </ul>
                                </p>

                                <hr />
                                <h4 id="faq-4">Do I need to pay a deposit</h4>
                                <p>Yes. Deposit vary according to the duration of the lease and type of vehicle. Typically, for short term rentals, deposits range from $300 – $500, and for longer-term rentals, deposits tend to range from $800 – 1 month of rental. We only accept credit/debit card for deposit payments.</p>
                                <hr />
                                <h4 id="faq-5">When do I get my deposit back?</h4>
                                <p>Deposit will be refunded to your credit/debit card 14 days after returning of vehicle less any deductions for cleaning, damages, fuel, traffic summons.</p>
                                <hr />
                                <h4 id="faq-6">Is there a penalty if I cancel my booking?</h4>
                                <p>For short-term lease (29 days or less), if you cancel the booking before the Lessor approved the lease. No cancellation fee will be charge. If you cancel the booking after the Lessor have approved, a cancellation fee of S$200 (Two Hundred Singapore Dollars) will be deducted from your deposit. The balance will be refunded. </p>
                                <p>For long-term lease (one month or more), a cancellation fee of S$200 (Two Hundred Singapore Dollars) will be deducted from your deposit.</p>
                                <p>Once the lease has commenced, no cancellation is allowed.</p>
                                <hr />
                                <h4 id="faq-7">Is there a penalty if I cancel my lease earlier?</h4>
                                <p>Yes. You are required to pay in full the remaining lease period.</p>
                                <hr />
                                <h4 id="faq-8">Returning of vehicle.</h4>
                                <p>Vehicle must be return in the same condition as you received it.</p>
                                <hr />
                                <h4 id="faq-9">Do you allow rental for personal usage?</h4>
                                <p>Yes, we offer rental on vehicles for personal and company usage.</p>
                                <hr />
                                <h4 id="faq-10">Can multiple people drive the vehicle?</h4>
                                <p>If the leasing agreement is under a company, yes, any employee with a valid driving license can drive the vehicle provided they meet the minimum requirement. However, if vehicle rented under an individual’s name, only that person plus one is allowed to drive.</p>
                                <hr />
                                <h4 id="faq-11">What is the late-return policy?</h4>
                                <p>Rental charges are based on a 24-hour rental day, with each additional hour up to 6 hours will be pro-rated hourly at times two rate. Late returns past 6 hours will be charged as an additional full day at times two rate.</p>
                                <hr />
                                <h4 id="faq-12">What happen in the event of an accident/car breakdown?</h4>
                                <p>In the event of an accident (whether it is your fault or not), please inform the Lessor immediately by calling the Roadside Assistance number located in each vehicle. Ensure you have taken adequate pictures to document the accident as well as exchange personal particulars information if there are other driver(s) involved.
                                <br />
                                In the event of a car breakdown, please call the 24-hours Roadside Assistance number located inside the vehicle.
                                 </p>

                                <hr />
                                <h4 id="faq-13">Road speeding and parking fines</h4>
                                <p>The lessee is responsible for all traffic offences and fines incurred during their hire term.</p>

                                <hr />
                                <h4 id="faq-14">Can I drive the vehicle to Malaysia?</h4>
                                <p>Selected vehicle is permitted to drive to Malaysia with add-on fees. Check the vehicle specification for details.  </p>

                                <hr />
                                <h4 id="faq-15">Is fuel included in the rent?</h4>
                                <p>Fuel is not included in the rental. Driver need to return the vehicle with the same level of fuel as when they collected it.</p>
                                <hr />
                                <h4 id="faq-16">Is insurance included in the lease?</h4>
                                <p>Yes, the Lessor is responsible to ensure the assigned driver(s) are insured with comprehensive insurance.</p>
                                <hr />
                                <h4 id="faq-17">Do I have to pay the insurance excess during an accident?</h4>
                                <p>Yes, the insurance excess for each vehicle is specified in the vehicle specification and . Insurance Excess must be paid upon demand. Insurance Excess cannot be deducted from the Deposit.</p>
                                <hr />
                                <h4 id="faq-18">What happen if the vehicle is not the same as the one listed on the website?</h4>
                                <p>Lessor is required to give you the vehicle as per the image on the website as per the lease agreement (except fair wear and tear). If the vehicle is substantially different, you may reject the vehicle and request for a replacement. Take a picture of the vehicle and email to EARental for investigation. </p>
                                <hr />
                                <div className="gap gap-small"></div>
                                <h4 className="post-title text-darken">Lessor</h4>
                                <hr />
                                <h4 id="faq-19">Is there any listing fee on EARental?</h4>
                                <p>It is FREE to list on EARental. A nominal transaction fee will be imposed on all transactions.</p>

                                <hr />
                                <h4 id="faq-20">What is the prerequisite to become a Lessor on EARental?</h4>
                                <p>You will need a valid business registration in Singapore with ACRA. If you intend to lease vehicle, ensure the principle activities registered as "Renting and Leasing of Land Transport Equipment". A refundable of $1,000 is required once the account has been approved.</p>

                                <hr />
                                <h4 id="faq-21">How do I list my vehicle on EARental?</h4>
                                <p>Once your account had been approved. You may upload vehicle via the Lessor Dashboard located in the Profile.</p>
                                <p>You will need photos of your vehicle as well as your vehicle documentation. Make sure to have those on hand to finish the process as fast as possible</p>

                                <hr />
                                <h4 id="faq-22">When do I get back the deposit?</h4>
                                <p>The refundable deposit will be return upon termination of account less any funds owing to EARental.</p>

                                <hr />
                                <h4 id="faq-23">Can I upload a standard vehicle image?</h4>
                                <p>No. The image shown must clearly illustrate the vehicle you intend to lease (except fair wear and tear). Penalty fee maybe imposed if the vehicle is substantially different from the image shown on the website.</p>

                                <hr />
                                <h4 id="faq-24">Do I have to provide full insurance coverage?</h4>
                                <p>Yes, you are required to provide full insurance for the driver’s stated in the Lease Agreement during the entire lease period.</p>

                                <hr />
                                <h4 id="faq-25">What happen if the vehicle is not available?</h4>
                                <p>You are required to keep the vehicle schedule updated in the lessor's dashboard. If the said vehicle is not available on commencement date, a penalty of S$200 (two hundred Singapore Dollars) will be deducted from your deposit regardless of rental type.</p>

                                <hr />
                                <h4 id="faq-26">How & when do I get paid?</h4>
                                <p>Payment will be deposited via bank transfer to your designated bank account, 14 days from the invoice date.</p>

                            </div>

                        </article>
                    </div>
                </div>
            </div>

        </div>
    );
  }
}

export default Faq;
