import React from "react";
import Link from "react-router-dom";
import {
  createMuiTheme,
  withStyles,
  ThemeProvider
} from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import { red } from '@material-ui/core/colors';

import "../assets/css/checkout.css";

const useStyles = theme => ({
  layout: {
    padding: theme.spacing(4),
    minHeight: 500,
    [theme.breakpoints.up('xs')]: {
      minHeight: 400,
    },
  },
  icon: {
    fontSize: '10rem',
    color: red[600],
  },
  title: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('xs')]: {
      fontSize: '3.5rem',
    },
  },
  list: {
    marginBottom: theme.spacing(2),
    '& li': {
      marginLeft: theme.spacing(3),
    },
  },
});

const theme = createMuiTheme({
  typography: {
    fontSize: 18,
  },
});

class PaymentFailed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  };

  componentDidMount() {
    let paymentFailed = localStorage.getItem('paymentFailed');
    let hasPendingPayment = localStorage.getItem('hasPendingPayment');
    if (paymentFailed) {
      localStorage.removeItem('paymentFailed');
    } else {
      if (hasPendingPayment == 1) {
        this.props.history.push('/checkout/payment');
      } else {
        this.props.history.push('/');
      }
    }
  }


  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="sm" className="checkout" classes={{root: classes.layout}}>
            <Typography variant="h3" align="center">
              <CancelIcon className={classes.icon} />
            </Typography>
            <Typography variant="h3" component="h1" align="center" gutterBottom className={classes.title}>
              Your payment has failed
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Here are some of the reasons why this transaction was declined.
            </Typography>
            <Typography variant="body1" component="ul" className={classes.list} gutterBottom>
              <li>Invalid card details was entered on the payment form;</li>
              <li>You have insufficient funds in your account;</li>
              <li>Your card may have a temporary hold;</li>
              <li>The card you used may have been designated as lost or stolen;</li>
            </Typography>
            <Typography variant="body1">
              Please <a href='/checkout/payment'>try again with a valid card details</a> and contact your bank if necessary.
            </Typography>
          </Container>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(PaymentFailed);