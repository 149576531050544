import React from 'react';
import { 
  makeStyles,
  useTheme,
 } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from 'axios';
import { store } from 'react-notifications-component';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CloseIcon from '@material-ui/icons/Close';
import { orange, grey } from '@material-ui/core/colors';
import ImageUpload from './ImageUpload';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minHeight: 150,
    paddingBottom: theme.spacing(4),
  },
  dialogActions: {
    justifyContent: 'space-between',
    '& button': {
      fontSize: '1rem',
    },
  },
  newAddressForm: {
    color: grey[800],
    '& .MuiFormControl-root': {
      '& .MuiFormLabel-root': {
        fontSize: '1.2rem',
        '&.Mui-focused': {
          color: grey[800],
          backgroundColor: 'white',
          paddingRight: 5,
        },
      },
      '& .MuiFormControlLabel-label': {
        fontSize: '1.2rem',
      },
      '& .MuiRadio-colorSecondary.Mui-checked': {
        // color: orange[600],
      },
      '& .MuiSelect-select': {
        fontSize: '1.2rem',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        '&:hover fieldset': {
          borderColor: '#B4B4B4',
        },
        '&.Mui-focused fieldset': {
          borderColor: orange[600],
        },
        '& input': {
          fontSize: '1.2rem',
        },
      },
      '& .MuiRadio-root': {
        '& .Mui-focused': {
          color: orange[600],
        },
      },
    },
  },
  radioGroup: {
    paddingBottom: '0 !important',
    '& .MuiFormLabel-root': {
      marginBottom: 0,
    },
    '& .MuiFormControlLabel-root': {
      marginBottom: 0,
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditAddress(props) {
  const theme = useTheme();
  const classes = useStyles();
  const api = {
    url: props.mainState.apiURL,
    key: props.mainState.apiKey,
    accessToken: localStorage.getItem('ctoken'),
    jhamToken: localStorage.getItem('token'),
    // url: 'https://api.earental.dev',
    // jhamToken: "2b648ed81a5a93e6b20449f254b53d48",
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [state, setState] = React.useState({
    purpose: "personal",
    firstName: "",
    lastName: "",
    nric: "",
    nricFrontImg: "",
    updateNricFrontImg: false,
    nricBackImg: "",
    updateNricBackImg: false,
    type: "apartment",
    block: "",
    street: "",
    floor: "",
    unit: "",
    building: "",
    country: "Singapore",
    postal: "",
    company: "",
    roc: "",
    acra: "",
    updateAcra: false,
    telephone: "",
    mobile: "",
  });
  const [nricImages, setNricImages] = React.useState({
    front: "",
    back: "",
  });
  const [initialAcra, setInitialAcra] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  let formSubmitButton = React.useRef(null);
  let fileInputAcra = React.useRef(null);

  React.useEffect(() => {
    var address = props.addressDetails;
    if (props.open) {
      setState({
        ...state,
        purpose: address.purpose,
        firstName: address.firstName,
        lastName: address.lastName,
        nric: address.nric,
        type: address.type,
        block: address.block,
        street: address.street,
        floor: address.floor,
        unit: address.unit,
        building: address.building,
        country: address.country,
        postal: address.postal,
        company: address.company,
        roc: address.roc,
        telephone: address.telephone,
        mobile: address.mobile,
      });
      setNricImages({
        front: address.nricFrontImg,
        back: address.nricBackImg,
      });
      setInitialAcra(address.acra);
    };
  }, [props.open]);

  const handlePurposeChange = (event) => {
    event.persist();
    setState({
      ...state,
      purpose: event.target.value,
    });
  };

  const handleInputChange = (event) => {
    let target = event.target;
    setState({
      ...state,
      [target.name]: target.value,
    });
  };

  const handleSaveButton = (event) => {
    formSubmitButton.click();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSaving(true);
    let bodyFormData = new FormData();
        bodyFormData.append('api_key', api.key);
        bodyFormData.append('access_token', api.accessToken);
        bodyFormData.append('jham_token', api.jhamToken);
        bodyFormData.append('address_id', props.addressDetails.id);
        bodyFormData.append('purpose', state.purpose);
        bodyFormData.append('fname', state.firstName);
        bodyFormData.append('lname', state.lastName);
        bodyFormData.append('nric', state.nric);
        bodyFormData.append('type', state.type);
        bodyFormData.append('block', state.block);
        bodyFormData.append('street', state.street);
        bodyFormData.append('floor', state.floor);
        bodyFormData.append('unit', state.unit);
        bodyFormData.append('building', state.building);
        bodyFormData.append('country', state.country);
        bodyFormData.append('postal', state.postal);
        bodyFormData.append('company', state.purpose == "business" ? state.company : "");
        bodyFormData.append('roc', state.purpose == "business" ? state.roc : "");
        bodyFormData.append('telephone', state.telephone);
        bodyFormData.append('mobile', state.mobile);
    if (state.updateNricFrontImg == true) {
      bodyFormData.append('nric_front_img', state.nricFrontImg);
      bodyFormData.append('update_nric_front_img', state.updateNricFrontImg);
    }
    if (state.updateNricBackImg == true) {
      bodyFormData.append('nric_back_img', state.nricBackImg);
      bodyFormData.append('update_nric_back_img', state.updateNricBackImg);
    }
    if (state.updateAcra == true) {
      bodyFormData.append('acra', state.acra);
      bodyFormData.append('update_acra', state.updateAcra);
    }
    // for (var pair of bodyFormData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }
    // return false;

    axios({
      method: 'post',
      url: api.url + '/account/update_address',
      data: bodyFormData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      if (response.data.error > 0) {
        function ErrorMsg(props) {
          return (
            props.msgs.map((item, i) =>
              <li key={i}>{item.value}</li>
            )
          );
        }
        store.addNotification({
          title: "Invalid information",
          message: <ErrorMsg msgs={response.data.error_msg} />,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 20000,
            onScreen: false
          }
        });
      } else {
        props.onAddressUpdated();
      }
      setSaving(false);
    })
    .catch((error) => {
      // Handle error
      if (error.response && error.response.status == 403) {
        store.addNotification({
          title: "Oops Something went wrong",
          message: "The action you have requested is not allowed. Please reload the page",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: false
          }
        });
      }
    });
  };

  const onImageChange = (img, key) => {
    let updateNricFrontImg = state.updateNricFrontImg;
    let updateNricBackImg = state.updateNricBackImg;
    if (key == "nricFrontImg")
      updateNricFrontImg = true;
    if (key == "nricBackImg")
      updateNricBackImg = true;
    setState({
      ...state,
      [key]: img,
      updateNricFrontImg: updateNricFrontImg,
      updateNricBackImg: updateNricBackImg,
    });
  };

  const handleAcraUpload = (event) => {
    setState({
      ...state,
      acra: event.target.files[0],
      updateAcra: true,
    });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={props.open}
      TransitionComponent={Transition}
      onClose={props.onClose}
      className={classes.root}
      fullScreen={fullScreen}
    >
      <DialogTitle>
        Update Billing Address
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form onSubmit={handleSubmit}>
          <Button type="submit" ref={button => formSubmitButton = button} style={{ display: 'none' }}>hidden</Button>
          <Grid container spacing={3} className={classes.newAddressForm}>
            <Grid item xs={12} sm={12} className={classes.radioGroup}>
              <FormControl component="fieldset">
                <FormLabel>Purpose</FormLabel>
                <RadioGroup name="purpose" value={state.purpose} onChange={handlePurposeChange} row>
                  <FormControlLabel value="personal" control={<Radio />} label="Personal" />
                  <FormControlLabel value="business" control={<Radio />} label="Business" />
                </RadioGroup>
              </FormControl>
            </Grid>
            {state.purpose == "business" && (
              <React.Fragment>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    variant="outlined"
                    id="company"
                    name="company"
                    label="Company Name"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={state.company}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    variant="outlined"
                    id="roc"
                    name="roc"
                    label="ROC No."
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={state.roc}
                    onChange={handleInputChange}
                    disabled={state.company == ""}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                  <Box textAlign="right" marginBottom={1} display="flex" justifyContent="flex-end" alignItems="center">
                    <input
                      ref={fileInputAcra}
                      type="file"
                      onChange={handleAcraUpload}
                      style={{ display: "none" }}
                    />
                    <Typography variant="body1" style={{ marginRight: 8 }}>
                      {state.acra && (
                        <React.Fragment>
                          {state.acra.name}
                          <Button type="button" onClick={() => setState({ ...state, acra: "" })} color="default" style={{ minWidth: 0, borderRadius: '50%' }}><CloseIcon /></Button>
                        </React.Fragment>
                      )}
                      {state.acra == "" && initialAcra != "" && (
                        <a href={api.url + "/uploads/customer-address/" + initialAcra} target="_blank">{initialAcra}</a>
                      )}
                    </Typography>
                    <Button type="button" variant="outlined" color="secondary" onClick={() => fileInputAcra.current.click()}>Upload ACRA</Button>
                  </Box>
                </Grid>
              </React.Fragment>
            )}
            <Grid item xs={12} sm={4}>
              <TextField
                required
                variant="outlined"
                id="firstName"
                name="firstName"
                label="First name"
                fullWidth
                autoComplete="off"
                size="small"
                value={state.firstName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                variant="outlined"
                id="lastName"
                name="lastName"
                label="Last name"
                fullWidth
                autoComplete="off"
                size="small"
                value={state.lastName}
                onChange={handleInputChange}
              />
            </Grid>
            <React.Fragment>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  variant="outlined"
                  id="nric"
                  name="nric"
                  label="NRIC"
                  fullWidth
                  autoComplete="off"
                  size="small"
                  value={state.nric}
                  onChange={handleInputChange}
                />
              </Grid>
            </React.Fragment>
            {state.purpose == "personal" && (
              <React.Fragment>
                <Grid item xs={12} sm={6}>
                  <ImageUpload label="NRIC (Front)" onImageChange={onImageChange} type="edit" stateKey="nricFrontImg" initialImage={nricImages.front} api={api} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ImageUpload label="NRIC (Back)" onImageChange={onImageChange} type="edit" stateKey="nricBackImg" initialImage={nricImages.back} api={api} />
                </Grid>
              </React.Fragment>
            )}
            <Grid item xs={12} sm={4}>
              <TextField
                required
                select
                SelectProps={{native: true}}
                variant="outlined"
                id="type"
                name="type"
                label="House/Apt/Business"
                fullWidth
                autoComplete="off"
                size="small"
                value={state.type}
                onChange={handleInputChange}
              >
                <option value={'apartment'}>Apartment</option>
                <option value={'house'}>House</option>
                <option value={'business'}>Business</option>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                variant="outlined"
                id="block"
                name="block"
                label="Block/House No."
                fullWidth
                autoComplete="off"
                size="small"
                value={state.block}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                variant="outlined"
                id="street"
                name="street"
                label="Street"
                fullWidth
                autoComplete="off"
                size="small"
                value={state.street}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="floor"
                    name="floor"
                    label="Floor"
                    autoComplete="off"
                    size="small"
                    value={state.floor}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={2} align="center">
                  <Typography component="span">&mdash;</Typography>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="unit"
                    name="unit"
                    label="Unit"
                    autoComplete="off"
                    size="small"
                    value={state.unit}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                fullWidth
                variant="outlined"
                id="building"
                name="building"
                label="Building Name"
                autoComplete="off"
                size="small"
                value={state.building}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                id="country"
                name="country"
                label="Country"
                fullWidth
                autoComplete="off"
                value="Singapore"
                readOnly
                size="small"
                value={state.country}
                onChange={handleInputChange}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                id="postal"
                name="postal"
                label="Postal Code"
                fullWidth
                autoComplete="off"
                size="small"
                value={state.postal}
                onChange={handleInputChange}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                id="telephone"
                name="telephone"
                label="Home/Work Number"
                fullWidth
                autoComplete="off"
                size="small"
                value={state.telephone}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                variant="outlined"
                id="mobile"
                name="mobile"
                label="Mobile Number"
                fullWidth
                autoComplete="off"
                size="small"
                InputProps={{
                  startAdornment: <InputAdornment position="start">+65</InputAdornment>,
                  maxLength: 12,
                }}
                value={state.mobile}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <React.Fragment>
          <Button type="button" onClick={props.onClose} disabled={saving}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleSaveButton} disabled={saving}>
            Save Address
          </Button>
        </React.Fragment>
      </DialogActions>
    </Dialog>
  );
}
