import React, { Component } from "react";
import {
  NavLink,
} from "react-router-dom";
import axios from 'axios';
import { store } from 'react-notifications-component';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import moment from "moment";

// import Home from "./Home";
// import Stuff from "./Stuff";
// import Contact from "./Contact";
// import ScriptTag from 'react-script-tag';

class Footer extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor (props) {
        super(props)
        const { cookies } = props;
        this.state ={
            redirect:false,
        }
    }

  componentDidMount() {
    var ctoken = localStorage.getItem('ctoken');
    if(ctoken){
      this.checkUserToken();
      setInterval(this.checkUserToken.bind(this), 30000);
      // setInterval(this.checkUserToken.bind(this), 5000);
    }
  }

  async checkUserToken(){
    const { cookies } = this.props;
    var ctoken = localStorage.getItem('ctoken');
    var token = localStorage.getItem('token');
    if(ctoken){
      var bodyFormData = new FormData();
      bodyFormData.append('access_token', ctoken);
      bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
      bodyFormData.append('jham_token', token);
      axios({
        method: 'post',
        url: 'https://api.earental.com/account/checkpass?c='+moment().unix(),
        data: bodyFormData,
        withCredentials: true,
        headers: {'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        if(response.data.error > 0){
          store.addNotification({
            title: "Oops you have been logout",
            message: "Your session have expired/used in different device, please re-login",
            type: "warning",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
            duration: 4000,
            onScreen: false
            }
          });
          localStorage.removeItem("ctoken");
          localStorage.removeItem("current_name");
          localStorage.removeItem("current_avatar");
          localStorage.removeItem('checkout');
          localStorage.removeItem('checkoutProgress');
          localStorage.removeItem('checkoutCompleted');
          localStorage.removeItem('hasPendingPayment');
          this.props.handlerState('checkoutProgress', null);
          this.props.handlerState('currentUserName',false);
          this.props.handlerState('currentAvatar',false);
          this.props.handlerState('ctoken',false);
          cookies.remove('ctoken', { path:'/',domain: '.earental.com' });
          cookies.remove('cavatar', { path:'/',domain: '.earental.com' });

          setTimeout(
            () => window.location.href = "/sign-in",
            150
          );
        }

        // window.scrollTo(0, 0);
      })
      .catch((error) => {
      });
    }
  }

  render() {
    return (
      <footer id="main-footer">
        <div className="container">
          <div className="row row-wrap">
            <div className="col-md-3">
              <NavLink exact to="/" className="logo" >
                <img src="/img/EARental-Logo-w.png" alt="" title="EARental | Your Trusted Something"  />
              </NavLink>
              <p className="mb20">Hassle-Free Leasing</p>
              <ul className="list list-horizontal list-space">
                <li>
                  <a className="fa fa-facebook box-icon-normal round animate-icon-bottom-to-top" href="https://www.facebook.com/EARental" target="_blank"></a>
                </li>
                <li>
                  <a className="fa fa-twitter box-icon-normal round animate-icon-bottom-to-top" href="#"></a>
                </li>
                <li>
                  <a className="fa fa-google-plus box-icon-normal round animate-icon-bottom-to-top" href="#"></a>
                </li>
                <li>
                  <a className="fa fa-linkedin box-icon-normal round animate-icon-bottom-to-top" href="#"></a>
                </li>
                <li>
                  <a className="fa fa-pinterest box-icon-normal round animate-icon-bottom-to-top" href="#"></a>
                </li>
              </ul>
            </div>
            <div className="col-md-2">
              <h4>Quick Link</h4>
              <ul className="list list-footer">
                <li><NavLink to="/about-us">About</NavLink>
                </li>
                <li><NavLink to="/faq">FAQ</NavLink>
                </li>
                <li><NavLink to="/privacy-policy">Privacy Policy</NavLink>
                </li>
                <li><NavLink to="/terms-of-service">Terms of Service</NavLink>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <h4>Have Questions?</h4>
              <h4><a href="mailto:support@EARental.com" className="text-color">support@EARental.com</a></h4>
              <h4><a href="tel:+65 6235 1780" className="text-color">(+65) 6235 1780</a></h4>
            </div>
            <div className="col-md-3">
              <p>
                207 Woodlands Avenue 9
                <br />
                #06-56 Woodlands Spectrum 2
                <br />
                Singapore 738958
                <br />
                Hours of operation
                <br />
                Monday – Friday 9AM – 6PM.
                <br />
                Saturday, Sunday, Public Holiday: Closed
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default withCookies(Footer)
