import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { store } from 'react-notifications-component';
import axios from 'axios';
import ContentLoader from "react-content-loader";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { orange, grey } from '@material-ui/core/colors';
import NewAddress from '../checkout/NewAddress';
import EditAddress from '../checkout/EditAddress';
import DeleteAddress from '../checkout/DeleteAddress';
import "../assets/css/checkout.css";

const useStyles = theme => ({
  layout: {
    padding: theme.spacing(5, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
    }
  },
  title: {
    fontWeight: 300,
    marginBottom: theme.spacing(3),
  },
  address: {
    marginTop: theme.spacing(3),
  },
  addressLoader: {
    '& .MuiCardContent-root': {
      height: 155,
    },
  },
  addressCard: {
    '& .MuiCardContent-root': {
      padding: theme.spacing(3, 3, 0),
      '& h5': {
        fontSize: '1.75rem',
      },
      '& p': {
        fontSize: '1.25rem',
        lineHeight: '1.5em',
        maxHeight: '3em',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
      },
    },
    '& button': {
      fontSize: '1.1rem !important',
      fontWeight: '600',
    },
  },
  addressSelected: {
    border: '3px solid ' + orange[600],
  },
  newAddress: {
    color: grey[600],
    cursor: 'pointer',
    '& .MuiCardContent-root': {
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 155,
      '& > *': {
        color: '#2F96B4',
        fontSize: '1.5rem',
        fontWeight: 500,
      },
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
      },
    },
  },
});

class BillingAddresses extends React.Component {
  constructor(props) {
    super(props);

    this.api = {
      url: props.mainState.apiURL,
      key: props.mainState.apiKey,
      accessToken: localStorage.getItem('ctoken'),
      jhamToken: localStorage.getItem('token'),
      // url: 'https://api.earental.dev',
      // jhamToken: "2b648ed81a5a93e6b20449f254b53d48",
    };

    this.state = {
      loading: true,
      addresses: [],
      openAddDialog: false,
      openEditDialog: false,
      openDeleteDialog: false,
      addressToEdit: null,
      addressToDelete: null,
    };
  };

  componentDidMount() {
    // Get current checkout data
    let formData = new FormData();
        formData.append('api_key', this.api.key);
        formData.append('access_token', this.api.accessToken);
        formData.append('jham_token', this.api.jhamToken);
    // Get customer addresses
    axios({
      method: 'post',
      url: this.api.url + '/account/customer_address_list',
      data: formData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      let addresses = [];
      response.data.data.map((address, i) => {
        addresses.push({
          id: address.id,
          purpose: address.purpose,
          firstName: address.billing_first_name,
          lastName: address.billing_last_name,
          nric: address.billing_nric,
          nricFrontImg: address.billing_nric_front_img,
          nricBackImg: address.billing_nric_back_img,
          type: address.billing_type,
          block: address.billing_block,
          street: address.billing_street,
          floor: address.billing_floor,
          unit: address.billing_unit,
          building: address.billing_building,
          country: address.billing_country,
          postal: address.billing_postal,
          company: address.billing_company,
          roc: address.billing_roc,
          acra: address.billing_acra,
          telephone: address.billing_phone,
          mobile: address.billing_mobile,
        });
      });
      this.setState({
        loading: false,
        addresses: addresses,
      });
    })
    .catch(error => {
      // handle error
      // if (error.response && error.response.status == 403) {
      // }
      store.addNotification({
        title: "Something went wrong",
        message: "The action you have requested is not allowed. Please reload the page",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
            onScreen: false
        }
      });
  });
  }

  handleAddDialogClose = () => {
    this.setState({ openAddDialog: false });
  }

  handleAddDialogOpen = () => {
    this.setState({ openAddDialog: true });
  }

  handleEditDialogClose = () => {
    this.setState({ openEditDialog: false });
  }

  handleEditDialogOpen = (address) => {
    this.setState({
      openEditDialog: true,
      addressToEdit: address,
    });
  }

  handleDeleteDialogClose = () => {
    this.setState({ openDeleteDialog: false });
  }

  handleDeleteDialogOpen = (address) => {
    this.setState({
      openDeleteDialog: true,
      addressToDelete: address,
    });
  }

  reloadAddressList = () => {
    let bodyFormData = new FormData();
        bodyFormData.append('api_key', this.api.key);
        bodyFormData.append('access_token', this.api.accessToken);
        bodyFormData.append('jham_token', this.api.jhamToken);
    axios({
      method: 'post',
      url: this.api.url + '/account/customer_address_list',
      data: bodyFormData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      let addresses = [];
      response.data.data.map((address, i) => {
        addresses.push({
          id: address.id,
          purpose: address.purpose,
          firstName: address.billing_first_name,
          lastName: address.billing_last_name,
          nric: address.billing_nric,
          nricFrontImg: address.billing_nric_front_img,
          nricBackImg: address.billing_nric_back_img,
          type: address.billing_type,
          block: address.billing_block,
          street: address.billing_street,
          floor: address.billing_floor,
          unit: address.billing_unit,
          building: address.billing_building,
          country: address.billing_country,
          postal: address.billing_postal,
          company: address.billing_company,
          roc: address.billing_roc,
          acra: address.billing_acra,
          telephone: address.billing_phone,
          mobile: address.billing_mobile,
        });
      });
      this.setState({
        addresses: addresses,
        openAddDialog: false,
        openEditDialog: false,
        openDeleteDialog: false,
        addressToEdit: null,
        addressToDelete: null,
      });
    })
    .catch((error) => {
      // Handle error
      if (error.response && error.response.status == 403) {
        store.addNotification({
          title: "Something went wrong",
          message: "Please reload the page",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: false
          }
        });
      }
    });
  }

  buildBillingAddress = (billingAddress) => {
    var address = capitalize(billingAddress.type) + " " + billingAddress.block + " " + billingAddress.street;
    if (billingAddress.floor && billingAddress.unit) {
      address += ", #" + billingAddress.floor + "-" + billingAddress.unit;
    }
    if (billingAddress.building) {
      if (!billingAddress.floor && !billingAddress.unit) {
        address += ", " + billingAddress.building;
      } else {
        address += " " + billingAddress.building;
      }
    }
    address += ", " + billingAddress.country + " " + billingAddress.postal;
    return address;
  }

  render() {
    const { classes } = this.props;

    function NewAddressCard(props) {
      return (
        <Grid item xs={12} sm={6}>
          <Card className={classes.newAddress} onClick={props.onClick}>
            <CardContent>
              <AddCircleIcon fontSize="large" color="primary" />
              <Typography variant="h5" component="span" align="center" color="primary">
                Add new address
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      );
    }

    function AddressListLoader() {
      return (
        <ContentLoader
          height={155}
          speed={1}
          style={{
            width: '100%',
          }}
        >
          <rect x="15" y="15" rx="4" ry="4" width="180" height="20" />
          <rect x="15" y="50" rx="4" ry="4" width="300" height="15" />
          <rect x="15" y="75" rx="4" ry="4" width="280" height="15" />
        </ContentLoader>
      )
    }

    function AddressCard(props) {
      var addressType = props.address.type.charAt(0).toUpperCase() + props.address.type.slice(1);
      var billingAddress = addressType + " " +
                           props.address.block + " " +
                           props.address.street;
      if (props.address.floor && props.address.unit) {
        billingAddress += ", #" + props.address.floor + "-" + props.address.unit;
      }
      if (props.address.building) {
        if (!props.address.floor && !props.address.unit) {
          billingAddress += ", " + props.address.building;
        } else {
          billingAddress += " " + props.address.building;
        }
      }
      billingAddress += ", " +
                        props.address.country + " " +
                        props.address.postal;
      return (
        <Grid item xs={12} sm={6} key={props.address.id} className={classes.addressCard}>
          <Card className={props.selected ? classes.addressSelected : null}>
            <CardContent>
              <Typography variant="h5" component="h5" gutterBottom>
                {props.address.purpose == "personal" ? props.address.firstName + " " + props.address.lastName : props.address.company}
              </Typography>
              <Typography variant="body1" component="p" color="textSecondary" title={billingAddress}>
                {billingAddress}
              </Typography>
            </CardContent>
            <CardActions style={{ justifyContent: "space-between" }}>
              <Button color="default" onClick={() => props.openDeleteDialog(props.address)}>
                Delete
              </Button>
              <Button color="secondary" onClick={() => props.openEditDialog(props.address)}>
                Edit
              </Button>
            </CardActions>
          </Card>
        </Grid>
      );
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <Container component="main" maxWidth="lg" className="profile" classes={{root: classes.layout}}>
          <Paper elevation={0}>
            <Grid container spacing={3} className={classes.address}>
              {this.state.loading ? (
                <React.Fragment>
                  <Grid item xs={12} sm={6} className={classes.addressLoader}>
                    <Card>
                      <CardContent>
                        <AddressListLoader />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.addressLoader}>
                    <Card>
                      <CardContent>
                        <AddressListLoader />
                      </CardContent>
                    </Card>
                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {this.state.addresses.map((address, index) =>
                    <AddressCard
                      key={index}
                      address={address}
                      openEditDialog={this.handleEditDialogOpen}
                      openDeleteDialog={this.handleDeleteDialogOpen}
                    />
                  )}
                  <NewAddressCard onClick={this.handleAddDialogOpen} />
                </React.Fragment>
              )}
            </Grid>
          </Paper>
        </Container>
        <NewAddress
          open={this.state.openAddDialog}
          onClose={this.handleAddDialogClose}
          onAddressAdded={this.reloadAddressList}
          handlerState={this.handlerState}
          mainState={this.props.mainState}
        />
        <EditAddress
          addressDetails={this.state.addressToEdit}
          open={this.state.openEditDialog}
          onClose={this.handleEditDialogClose}
          onAddressUpdated={this.reloadAddressList}
          handlerState={this.handlerState}
          mainState={this.props.mainState}
        />
        <DeleteAddress
          addressDetails={this.state.addressToDelete}
          open={this.state.openDeleteDialog}
          onClose={this.handleDeleteDialogClose}
          onAddressUpdated={this.reloadAddressList}
          handlerState={this.handlerState}
          mainState={this.props.mainState}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(BillingAddresses);

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}