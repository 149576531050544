import React, { PureComponent } from 'react';
import {
  ResponsiveContainer, AreaChart, Area, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import axios, { post } from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import Grid from '@material-ui/core/Grid';
import NumberFormat from 'react-number-format';
import Fab from '@material-ui/core/Fab';
import SyncIcon from '@material-ui/icons/Sync';

class Chart extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props)
        const { cookies } = props;
        this.state = {
          chartData: null,
        };

        this.handleReloadTable = this.handleReloadTable.bind(this);


    }
    componentDidMount() {
        const { cookies } = this.props;
        var ctoken = localStorage.getItem('ctoken');
        var token = localStorage.getItem('token');
        var cTokenCookie = cookies.get('ctoken') || false;

        var bodyFormData = new FormData();

        bodyFormData.append('access_token', ctoken);
        bodyFormData.append('jham_token', token);
        bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
        bodyFormData.append('days', 10);



        axios({
          method: 'post',
          url: 'https://api.earental.com/lessor/get_chartdata',
          data: bodyFormData,
          withCredentials: true,
          headers: {'Content-Type': 'multipart/form-data' }
        })
        .then((response) => {
          console.log(response);
          this.setState({
              chartData: response.data.data,
              lessorDeposit: response.data.deposit
          });

        })
        .catch((error) => {
          //handle error

        });

    }

    handleReloadTable(){
        window.location.reload();
    }

  render() {
    return (
      <div className="chart-cont" style={{ width: '100%', height: 300 }}>
          <Grid className="dashboardhome" container>
              {/* Chart */}
              <Grid item xs={9} md={9} lg={9}>

                  <h3>
                      Active Schedules last 9 days

                      <Fab size="small" variant="extended" style={{float:"right"}} onClick={this.handleReloadTable}>
                        <SyncIcon  />
                        Refresh
                      </Fab>

                  </h3>

              </Grid>
              <Grid item xs={3} md={3} lg={3} align="center">

                 <h3>Deposit</h3>

              </Grid>

              <Grid item xs={9} md={9} lg={9} style={{height:300}}>
                  <ResponsiveContainer>
                      <AreaChart
                        width={500}
                        height={300}
                        data={this.state.chartData}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <Tooltip />
                        <Legend />
                        <Area type="monotone" dataKey="amount" stackId="1" stroke="#8884d8" fill="#8884d8" />
                      </AreaChart>
                  </ResponsiveContainer>
              </Grid>
              <Grid item xs={3} md={3} lg={3} align="center">
                 <span><NumberFormat style={{fontSize:"30px"}}  value={this.state.lessorDeposit} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'S$'} /></span>
              </Grid>
          </Grid>




      </div>
    );
  }
}

export default withCookies(Chart)
