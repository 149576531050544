import React, { Component } from "react";

import "./assets/css/index.css";

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/font-awesome.css";
import "./assets/css/icomoon.css";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'react-datepicker/dist/react-datepicker.css';
import "./assets/css/earental.css";
import "./assets/css/nprogress.css";
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
// import ScriptTag from 'react-script-tag';
import Content from "./Content";
import $ from 'jquery';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import ReactGA from 'react-ga';


window.$ = $;

class Main extends Component {
    constructor (props) {
      super(props)
      this.state = {
        currentUserName:localStorage.getItem('current_name'),
        currentAvatar:localStorage.getItem('current_avatar'),
        token: '',
        ctoken: '',
        currentDate: new Date(),
        checkoutProgress: localStorage.getItem('checkoutProgress'),
        apiURL: "https://api.earental.com",
        // apiURL: "https://api.earental.dev",
        apiKey: "72ffc1303c7c0286887a3276f0ab61b8",
      };
      this.handlerState = this.handlerState.bind(this);
      ReactGA.initialize('UA-101763196-2');
      ReactGA.plugin.require('ec');
    }

    componentDidMount() {

    }

    handlerState(key,val) {
      this.setState({
        [key]: val
      })
    }

  render() {
    return (
      <div className="content-wrapper">
        <ReactNotification />
        <Content handlerState = {this.handlerState} mainState={this.state} />
      </div>
    );
  }
}

export default Main;
