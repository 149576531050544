import React, { Component } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Chart from '../Chart';
import Deposits from '../Deposits';
import Schedules from '../Schedule';
import "../../assets/css/dashboard.css";


export default function dashboard() {
    return (
        <Grid className="dashboardhome" container>
            {/* Chart */}
            <Grid item xs={12} md={12} lg={12}>

                <Chart />

            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={4} lg={3}>
            </Grid>
            {/* Recent Schedules */}
            <Grid item xs={12}>
                <Schedules/>
            </Grid>
        </Grid>
    );
}
