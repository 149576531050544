import React from "react";
import {
  makeStyles,
  styled,
  withTheme
} from '@material-ui/core/styles';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import ContentLoader, { Facebook } from "react-content-loader";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { grey, orange, green, blue, red, teal } from '@material-ui/core/colors';

const DashedDivider = styled(withTheme(Divider))(props => ({
  borderTop: '2px dashed',
  borderColor: grey[400],
  height: 0,
  backgroundColor: 'transparent !important',
  margin: props.theme.spacing(2, 0),
}));

const useStyles = makeStyles((theme) => ({
  root: {
    color: grey[600],
  },
  cardContent: {
    padding: theme.spacing(3, 3, 3),
  },
  scheduleTitle: {
    marginBottom: theme.spacing(3),
    '& h2': {
      fontSize: '2rem',
      marginTop: theme.spacing(2),
    },
    '& img': {
      maxWidth: 150,
    },
  },
  scheduleDetails: {
    marginBottom: theme.spacing(3),
    '&:last-child': {
      marginBottom: 0,
    },
    '& .MuiTypography-h5': {
      fontSize: '1.3rem',
      fontWeight: 500,
      marginBottom: theme.spacing(2),
    },
    '& .MuiTypography-body1': {
      fontSize: '1.2rem',
      fontWeight: 500,
    },
    '& .MuiTypography-caption': {
      fontSize: '1rem',
    },
  },
  expensesBreakdown: {
    '& .MuiGrid-item': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  textOrange: {
    color: orange[800],
  },
  textGreen: {
    color: green[600],
  },
  textBlue: {
    color: blue[600],
  },
  textRed: {
    // color: red[400],
    color: '#D40029',
  },
  textTeal: {
    color: teal[500],
  },
}));

export default function UpdatePaymentDetails(props) {
  const classes = useStyles();

  function Loader (props) {
    return (
      <ContentLoader
        height={300}
        speed={1}
        {...props}
      >
        <rect x="10" y="20" rx="4" ry="4" width="60" height="60" />
        <rect x="100" y="30" rx="4" ry="4" width="180" height="20" />
        <rect x="100" y="60" rx="4" ry="4" width="180" height="10" />
        <rect x="10" y="100" rx="4" ry="4" width="150" height="20" />
        <rect x="10" y="130" rx="4" ry="4" width="300" height="15" />
        <rect x="10" y="155" rx="4" ry="4" width="300" height="15" />
        <rect x="10" y="190" rx="4" ry="4" width="150" height="20" />
        <rect x="10" y="220" rx="4" ry="4" width="300" height="15" />
        <rect x="10" y="245" rx="4" ry="4" width="300" height="15" />
      </ContentLoader>
    )
  }

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContent}>
        {props.vehicle ? (
          <React.Fragment>
            <Box className={classes.scheduleTitle} align="center">
              <Box marginBottom={1}>
                <img src={"https://api.earental.com/uploads/vehicles/" + props.vehicle.image} />
              </Box>
              <Box display="inline-block">
                <Typography variant="h6" component="h6">
                  {props.vehicle.name}
                </Typography>
                <Typography variant="subtitle1" component="p">
                  ({props.vehicle.plateNumber})
                </Typography>
              </Box>
            </Box>
            <DashedDivider />
            <Box className={classes.scheduleDetails}>
              <Typography variant="h6" component="h6" align="center">
                {props.schedule.id}
              </Typography>
            </Box>
            <Box className={classes.scheduleDetails}>
              <Typography variant="h5" gutterBottom>
                Rental Period
              </Typography>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={5} align="center">
                  <Typography variant="body1">
                    {moment(props.schedule.startDate).format('MMM D, YYYY')}
                  </Typography>
                  <Typography variant="caption">
                    {moment(props.schedule.startDate).format('dddd')}
                  </Typography>
                </Grid>
                <Grid item xs={2} align="center">
                  <ArrowForwardIcon fontSize="large" />
                </Grid>
                <Grid item xs={5} align="center">
                  <Typography variant="body1">
                    {moment(props.schedule.endDate).format('MMM D, YYYY')}
                  </Typography>
                  <Typography variant="caption" gutterBottom>
                    {moment(props.schedule.endDate).format('dddd')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            {/* <Box className={classes.scheduleDetails}>
              <Typography variant="h5" gutterBottom>
                Update Payment For
              </Typography>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={5} align="center">
                  <Typography variant="body1">
                    {moment(props.schedule.updateStartDate).format('MMM D, YYYY')}
                  </Typography>
                  <Typography variant="caption">
                    {moment(props.schedule.updateEndDate).format('dddd')}
                  </Typography>
                </Grid>
                <Grid item xs={2} align="center">
                  <ArrowForwardIcon fontSize="large" />
                </Grid>
                <Grid item xs={5} align="center">
                  <Typography variant="body1">
                    {moment(props.schedule.endDate).format('MMM D, YYYY')}
                  </Typography>
                  <Typography variant="caption" gutterBottom>
                    {moment(props.schedule.endDate).format('dddd')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.scheduleDetails}>
              <Grid container spacing={3} alignItems="flex-start" className={classes.expensesBreakdown}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    Monthly Rate
                  </Typography>
                </Grid>
                <Grid item xs={4} align="right">
                  <Typography variant="body1" className={classes.textGreen}>
                    <NumberFormat decimalScale={2} value={props.schedule.monthlyCharge} displayType={'text'} thousandSeparator={true} prefix={'S$'} decimalScale={2} fixedDecimalScale={props.schedule.monthlyCharge % 1 != 0} />
                  </Typography>
                </Grid>
              </Grid>
              {props.schedule.setupFee > 0 &&
                <React.Fragment>
                  <DashedDivider />
                  <Grid container spacing={3} alignItems="flex-start" className={classes.expensesBreakdown}>
                    <Grid item xs={8}>
                      <Typography variant="body1">
                        To Collect
                      </Typography>
                    </Grid>
                    <Grid item xs={4} align="right">
                      <Typography variant="body1" className={classes.textTeal}>
                        <NumberFormat decimalScale={2} value={props.schedule.setupFee} displayType={'text'} thousandSeparator={true} prefix={'S$'} decimalScale={2} fixedDecimalScale={props.schedule.monthlyCharge % 1 != 0} />
                      </Typography>
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            </Box> */}
          </React.Fragment>
        ) : (
          <Loader style={{ width: '100%' }} />
        )}
      </CardContent>
    </Card>
  );
}
