import React from "react";
import {
  NavLink,
  HashRouter
} from "react-router-dom";
import axios from 'axios';
// import parallaxBg1 from './assets/img/196_365_2048x1365.jpg';
import DatePicker from "react-datepicker";
import Image, { Shimmer } from 'react-shimmer'
import ContentLoader, { Facebook } from "react-content-loader";


// var parallaxBgStyle = {
//   backgroundImage: "url(" + parallaxBg1 + ")"
// };
const MyLoader = () => (
    <ContentLoader
        height={220}
        speed={1}
        className="test"
    >
        <rect x="0" y="0" rx="0" ry="0" width="262" height="131" />
        <rect x="0" y="137" rx="0" ry="0" width="262" height="30" />
        <rect x="0" y="175" rx="0" ry="0" width="30" height="30" />
        <rect x="35" y="175" rx="0" ry="0" width="30" height="30" />
        <rect x="70" y="175" rx="0" ry="0" width="30" height="30" />
        <rect x="105" y="175" rx="0" ry="0" width="30" height="30" />
        <rect x="140" y="175" rx="0" ry="0" width="30" height="30" />
    </ContentLoader>
);

export default class VehicleList extends React.Component {
    state = {
        vehicles: [],
        pickup: 'test',
        checkin: '',
        timein: '',
        dropoff: '',
        checkout: '',
        timeout: '',
        loadingClass: 'noHidden'

    }




    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }


    handleSubmit = event => {
        event.preventDefault();

        const request = {
            pickup: this.state.pickup,
            checkin: this.state.checkin,
            timein: this.state.timein,
            dropoff: this.state.dropoff,
            checkout: this.state.checkout,
            timeout: this.state.timeout,
        };

        axios.post(`https://jsonplaceholder.typicode.com/users`, { request })
        .then(res => {
          console.log(res);
          console.log(res.data);
        })
    }

    componentDidMount() {
        axios.get('https://jsonplaceholder.typicode.com/users?type=3')
        .then(res => {
            this.setState({ vehicles:res.data });
            this.setState({ loadingClass:'isHidden' });
        })
    }

  render() {
    return (
        <HashRouter>
            <div>
                <div className="container">
                    <h1 className="page-title">Search for Vehicle</h1>
                </div>

                <div className="container">
                    <form onSubmit={this.handleSubmit}>
                        <div className="input-daterange" data-date-format="MM d, D">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group form-group-icon-left"><i className="fa fa-map-marker input-icon input-icon-hightlight"></i>
                                        <label>Pick Up Location</label>
                                        <input className="typeahead form-control" placeholder="City" name="pickup" type="text" value={this.state.pickup} onChange={this.handleChange}  />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group form-group-icon-left"><i className="fa fa-calendar input-icon input-icon-hightlight"></i>
                                                <label>Check in</label>
                                                <input className="form-control"  type="text"  name="checkin" value={this.state.checkin} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group form-group-icon-left"><i className="fa fa-clock-o input-icon input-icon-hightlight"></i>
                                                <label>Time</label>
                                                <input className="time-pick form-control" value="12:00 AM" type="text" name="timein"  value={this.state.timein} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group form-group-icon-left"><i className="fa fa-map-marker input-icon input-icon-hightlight"></i>
                                        <label>Drop Off Location</label>
                                        <input className="typeahead form-control" placeholder="City or Airport" type="text" name="dropoff" value={this.state.dropoff} onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group form-group-icon-left"><i className="fa fa-calendar input-icon input-icon-hightlight"></i>
                                                <label>Check out</label>
                                                <input className="form-control"  type="text" name="checkout" value={this.state.checkout} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group form-group-icon-left"><i className="fa fa-clock-o input-icon input-icon-hightlight"></i>
                                                <label>Time</label>
                                                <input className="time-pick form-control" type="text"  name="timeout" value={this.state.timeout} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <input className="btn btn-primary mt10" type="submit"  />
                    </form>
                    <div className="gap gap-small"></div>
                    <h3 className="mb20">Top Deals</h3>
                    <div className="row row-wrap">

                        <div className={this.state.loadingClass}>
                            <div className="col-md-3">
                                <MyLoader  />
                            </div>
                            <div className="col-md-3">
                                <MyLoader />
                            </div>
                            <div className="col-md-3">
                                <MyLoader />
                            </div>
                            <div className="col-md-3">
                                <MyLoader />
                            </div>
                        </div>
                     { this.state.vehicles.map(vehicle =>
                             <div className="col-md-3" key={vehicle.id}>
                                 <div className="thumb">
                                     <header className="thumb-header">
                                         <NavLink to={'/vehicle-details/'+vehicle.name}>
                                            <Image
                                                src="img/truck.jpg"
                                                fallback={<Shimmer width={262} height={200} />}
                                            />
                                         </NavLink>
                                     </header>
                                     <div className="thumb-caption">
                                         <h5 className="thumb-title"><a className="text-darken" href="vehicle-details.html">{vehicle.name}</a></h5><small>Truck</small>
                                         <ul className="booking-item-features booking-item-features-small booking-item-features-sign clearfix mt5">
                                             <li rel="tooltip" data-placement="top" title="Passengers"><i className="fa fa-male"></i><span className="booking-item-feature-sign">x 5</span>
                                             </li>
                                             <li rel="tooltip" data-placement="top" title="Doors"><i className="im im-car-doors"></i><span className="booking-item-feature-sign">x 5</span>
                                             </li>
                                             <li rel="tooltip" data-placement="top" title="Baggage Quantity"><i className="fa fa-briefcase"></i><span className="booking-item-feature-sign">x 1</span>
                                             </li>
                                             <li rel="tooltip" data-placement="top" title="Automatic Transmission"><i className="im im-shift-auto"></i><span className="booking-item-feature-sign">auto</span>
                                             </li>
                                             <li rel="tooltip" data-placement="top" title="Electric Vehicle"><i className="im im-electric"></i>
                                             </li>
                                         </ul>
                                         <p className="text-darken mb0 text-color">$41<small> /day</small>
                                         </p>
                                     </div>
                                 </div>
                             </div>
                         )
                     }


                    </div>
                    <div className="gap gap-small"></div>
                    <h3 className="mb20">Truck</h3>
                    <div className="row row-wrap">
                        <div className={this.state.loadingClass}>
                            <div className="col-md-3">
                                <MyLoader  />
                            </div>
                            <div className="col-md-3">
                                <MyLoader />
                            </div>
                            <div className="col-md-3">
                                <MyLoader />
                            </div>
                            <div className="col-md-3">
                                <MyLoader />
                            </div>
                        </div>
                     { this.state.vehicles.map(vehicle =>
                             <div className="col-md-3" key={vehicle.id}>
                                 <div className="thumb">
                                     <header className="thumb-header">
                                         <a href="vehicle-details.html">
                                             <img src="img/truck.jpg" alt="Image Alternative text" title="Image Title" />
                                         </a>
                                     </header>
                                     <div className="thumb-caption">
                                         <h5 className="thumb-title"><a className="text-darken" href="vehicle-details.html">{vehicle.name}</a></h5><small>Truck</small>
                                         <ul className="booking-item-features booking-item-features-small booking-item-features-sign clearfix mt5">
                                             <li rel="tooltip" data-placement="top" title="Passengers"><i className="fa fa-male"></i><span className="booking-item-feature-sign">x 5</span>
                                             </li>
                                             <li rel="tooltip" data-placement="top" title="Doors"><i className="im im-car-doors"></i><span className="booking-item-feature-sign">x 5</span>
                                             </li>
                                             <li rel="tooltip" data-placement="top" title="Baggage Quantity"><i className="fa fa-briefcase"></i><span className="booking-item-feature-sign">x 1</span>
                                             </li>
                                             <li rel="tooltip" data-placement="top" title="Automatic Transmission"><i className="im im-shift-auto"></i><span className="booking-item-feature-sign">auto</span>
                                             </li>
                                             <li rel="tooltip" data-placement="top" title="Electric Vehicle"><i className="im im-electric"></i>
                                             </li>
                                         </ul>
                                         <p className="text-darken mb0 text-color">$41<small> /day</small>
                                         </p>
                                     </div>
                                 </div>
                             </div>
                         )
                     }


                    </div>

                    <div className="gap gap-small"></div>
                    <h3 className="mb20">Lorry</h3>
                    <div className="row row-wrap">
                        <div className={this.state.loadingClass}>
                            <div className="col-md-3">
                                <MyLoader  />
                            </div>
                            <div className="col-md-3">
                                <MyLoader />
                            </div>
                            <div className="col-md-3">
                                <MyLoader />
                            </div>
                            <div className="col-md-3">
                                <MyLoader />
                            </div>
                        </div>
                     { this.state.vehicles.map(vehicle =>
                             <div className="col-md-3" key={vehicle.id}>
                                 <div className="thumb">
                                     <header className="thumb-header">
                                         <a href="vehicle-details.html">
                                             <img src="img/truck.jpg" alt="Image Alternative text" title="Image Title" />
                                         </a>
                                     </header>
                                     <div className="thumb-caption">
                                         <h5 className="thumb-title"><a className="text-darken" href="vehicle-details.html">{vehicle.name}</a></h5><small>Truck</small>
                                         <ul className="booking-item-features booking-item-features-small booking-item-features-sign clearfix mt5">
                                             <li rel="tooltip" data-placement="top" title="Passengers"><i className="fa fa-male"></i><span className="booking-item-feature-sign">x 5</span>
                                             </li>
                                             <li rel="tooltip" data-placement="top" title="Doors"><i className="im im-car-doors"></i><span className="booking-item-feature-sign">x 5</span>
                                             </li>
                                             <li rel="tooltip" data-placement="top" title="Baggage Quantity"><i className="fa fa-briefcase"></i><span className="booking-item-feature-sign">x 1</span>
                                             </li>
                                             <li rel="tooltip" data-placement="top" title="Automatic Transmission"><i className="im im-shift-auto"></i><span className="booking-item-feature-sign">auto</span>
                                             </li>
                                             <li rel="tooltip" data-placement="top" title="Electric Vehicle"><i className="im im-electric"></i>
                                             </li>
                                         </ul>
                                         <p className="text-darken mb0 text-color">$41<small> /day</small>
                                         </p>
                                     </div>
                                 </div>
                             </div>
                         )
                     }


                    </div>

                    <div className="gap gap-small"></div>
                    <h3 className="mb20">Van</h3>
                    <div className="row row-wrap">
                        <div className={this.state.loadingClass}>
                            <div className="col-md-3">
                                <MyLoader  />
                            </div>
                            <div className="col-md-3">
                                <MyLoader />
                            </div>
                            <div className="col-md-3">
                                <MyLoader />
                            </div>
                            <div className="col-md-3">
                                <MyLoader />
                            </div>
                        </div>
                     { this.state.vehicles.map(vehicle =>
                             <div className="col-md-3" key={vehicle.id}>
                                 <div className="thumb">
                                     <header className="thumb-header">
                                         <a href="vehicle-details.html">
                                             <img src="img/truck.jpg" alt="Image Alternative text" title="Image Title" />
                                         </a>
                                     </header>
                                     <div className="thumb-caption">
                                         <h5 className="thumb-title"><a className="text-darken" href="vehicle-details.html">{vehicle.name}</a></h5><small>Truck</small>
                                         <ul className="booking-item-features booking-item-features-small booking-item-features-sign clearfix mt5">
                                             <li rel="tooltip" data-placement="top" title="Passengers"><i className="fa fa-male"></i><span className="booking-item-feature-sign">x 5</span>
                                             </li>
                                             <li rel="tooltip" data-placement="top" title="Doors"><i className="im im-car-doors"></i><span className="booking-item-feature-sign">x 5</span>
                                             </li>
                                             <li rel="tooltip" data-placement="top" title="Baggage Quantity"><i className="fa fa-briefcase"></i><span className="booking-item-feature-sign">x 1</span>
                                             </li>
                                             <li rel="tooltip" data-placement="top" title="Automatic Transmission"><i className="im im-shift-auto"></i><span className="booking-item-feature-sign">auto</span>
                                             </li>
                                             <li rel="tooltip" data-placement="top" title="Electric Vehicle"><i className="im im-electric"></i>
                                             </li>
                                         </ul>
                                         <p className="text-darken mb0 text-color">$41<small> /day</small>
                                         </p>
                                     </div>
                                 </div>
                             </div>
                         )
                     }


                    </div>



                    <div className="gap gap-small"></div>
                </div>


            </div>
        </HashRouter>
    )
  }
}
