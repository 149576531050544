import React from "react";
import axios from 'axios';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Container from "@material-ui/core/Container";
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box";
import Fab from '@material-ui/core/Fab';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import CircularProgress from '@material-ui/core/CircularProgress';

import NewRentalPromotion from './components/NewRentalPromotion';
import EditRentalPromotion from './components/EditRentalPromotion';
import DeleteRentalPromotion from './components/DeleteRentalPromotion';

const useStyles = (theme) => ({
  hidden: {
    display: 'none !important',
  },
  breadcrumbs: {
    padding: theme.spacing(3, 2),
  },
  layout: {
    position: 'relative',
    padding: theme.spacing(3, 2),
  },
  title: {
    fontWeight: 300,
    marginBottom: theme.spacing(4),
  },
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
  },
  promotionList: {
    
  },
  promotionCard: {
    '& .MuiCardContent-root': {
      padding: theme.spacing(3, 3, 0),
      '& h5': {
        fontSize: '1.75rem',
      },
      '& hr': {
        margin: '10px 0',
      },
      '& p': {
        color: '#4C4C4C',
        fontSize: '1.1rem',
      },
    },
    '& .MuiCardActions-root': {
      justifyContent: 'space-between',
    },
    '& button': {
      fontSize: '1.1rem !important',
      fontWeight: '600',
    },
  },
  addButton: {
    position: 'fixed',
    right: 20,
    bottom: 20,
  },
});

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#1a90ff',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

function FacebookCircularProgress(props) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}

class RentalPromotions extends React.Component {
  constructor(props) {
    super(props);
    this.api = {
      url: "https://api.earental.com",
      key: "72ffc1303c7c0286887a3276f0ab61b8",
      accessToken: localStorage.getItem('ctoken'),
      jhamToken: localStorage.getItem('token'),
    };
    this.state = {
      init: true,
      loading: true,
      openSnackbar: false,
      errorMsg: "",
      errorType: "success",
      searchKeyword: "",
      listPage: 1,
      itemsPerPage: 10,
      promotions: [],
      promotionToUpdate: null,
      promotionToDelete: null,
      openCreateDialog: false,
      openEditDialog: false,
      openDeleteDialog: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    var formData = new FormData();
    formData.append('api_key', this.api.key);
    formData.append('access_token', this.api.accessToken);
    formData.append('jham_token', this.api.jhamToken);
    formData.append('keyword', this.state.searchKeyword);
    formData.append('limit', this.state.itemsPerPage);
    formData.append('offset', this.state.itemsPerPage * (this.state.listPage - 1));

    axios({
      method: 'post',
      url: this.api.url + '/lessor/rental_promotion_list',
      data: formData,
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((response) => {
      var promotions = [];
      response.data.list.map((promotion, index) => {
        var startDateArr = promotion.rp_start_date.split('-');
        var endDateArr = promotion.rp_end_date.split('-');
        promotions.push({
          id: promotion.rp_id,
          title: promotion.rp_title,
          startDate: startDateArr[1] + "/" + startDateArr[2] + "/" + startDateArr[0],
          endDate: endDateArr[1] + "/" + endDateArr[2] + "/" + endDateArr[0],
          rateType: promotion.rp_rate_type,
          freeType: promotion.rp_rent_type,
          freeCount: promotion.rp_rent_count,
          status: promotion.rp_status,
          dateAdded: promotion.rp_date_added,
        });
      });
      this.setState({
        init: false,
        loading: false,
        promotions: promotions
      });
    })
    .catch((error) => {
      // handle error
    });
  }

  handleCreateDialogOpen = () => {
    this.setState({
      openCreateDialog: true,
    });
  }

  handleEditDialogOpen = (promotion) => {
    this.setState({
      openEditDialog: true,
      promotionToUpdate: promotion,
    });
  }

  handleDeleteDialogOpen = (promotion) => {
    this.setState({
      openDeleteDialog: true,
      promotionToDelete: promotion,
    });
  }

  handleCreateDialogClose = () => {
    this.setState({ openCreateDialog: false });
  }

  handleEditDialogClose = () => {
    this.setState({ openEditDialog: false });
  }

  handleDeleteDialogClose = () => {
    this.setState({ openDeleteDialog: false });
  }

  reloadPromotionList = () => {
    var formData = new FormData();
    formData.append('api_key', this.api.key);
    formData.append('access_token', this.api.accessToken);
    formData.append('jham_token', this.api.jhamToken);
    formData.append('keyword', this.state.searchKeyword);
    formData.append('limit', this.state.itemsPerPage);
    formData.append('offset', this.state.itemsPerPage * (this.state.listPage - 1));

    axios({
      method: 'post',
      url: this.api.url + '/lessor/rental_promotion_list',
      data: formData,
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => {
        var promotions = [];
        response.data.list.map((promotion, index) => {
          var startDateArr = promotion.rp_start_date.split('-');
          var endDateArr = promotion.rp_end_date.split('-');
          promotions.push({
            id: promotion.rp_id,
            title: promotion.rp_title,
            startDate: startDateArr[1] + "/" + startDateArr[2] + "/" + startDateArr[0],
            endDate: endDateArr[1] + "/" + endDateArr[2] + "/" + endDateArr[0],
            rateType: promotion.rp_rate_type,
            freeType: promotion.rp_rent_type,
            freeCount: promotion.rp_rent_count,
            status: promotion.rp_status,
            dateAdded: promotion.rp_date_added,
          });
        });
        this.setState({
          init: false,
          loading: false,
          promotions: promotions
        });
      })
      .catch((error) => {
        // handle error
      });
      this.setState({
        openCreateDialog: false,
        openEditDialog: false,
        openDeleteDialog: false,
      });
  }

  handleSnackbarClose = () => {
    this.setState({
      openSnackbar: false,
    })
  }

  render() {
    const { classes } = this.props;

    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    function Promotion(props) {
      let applicableTo = "Monthly";
      if (props.promotion.rateType == "year") {
        applicableTo = "1 Year";
      } else if (props.promotion.rateType == "year2") {
        applicableTo = "2 Years";
      }
      return (
        <Grid item xs={12} sm={3} key={props.promotion.id} className={classes.promotionCard}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h5" gutterBottom>
                {props.promotion.title}
              </Typography>
              <hr />
              <Typography component="p" gutterBottom>
                Free {props.promotion.freeCount} {props.promotion.freeType}
              </Typography>
              <Typography component="p" gutterBottom>
                Applicable to: {applicableTo} lease
              </Typography>
              {props.promotion.status == "Active" ? (
                <Typography variant="caption" component="p" gutterBottom>
                  Status: <strong style={{ color: 'green' }}>{props.promotion.status}</strong>
                </Typography>
              ) : (
                <Typography variant="caption" component="p" gutterBottom>
                  Status: <strong>{props.promotion.status}</strong>
                </Typography>
              )}
              <Typography variant="caption" component="p" gutterBottom>
                Validity Date: {props.promotion.startDate} - {props.promotion.endDate}
              </Typography>
            </CardContent>
            <CardActions>
              <Button color="default" onClick={() => props.onDeleteClick(props.promotion)}>
                Delete
              </Button>
              <Button color="secondary" onClick={() => props.onEditClick(props.promotion)}>
                Edit
              </Button>
            </CardActions>
          </Card>
        </Grid>
      );
    }

    return (
      <React.Fragment>
        <Breadcrumbs className={classes.breadcrumbs}>
          <Link color="inherit" href="/">
            Dashboard
          </Link>
          <Typography color="textPrimary">
            Rental Promotions
          </Typography>
        </Breadcrumbs>
        <Container component="main" maxWidth={false} className={classes.layout}>
          {this.state.promotions.length > 0 && (
            <Grid container spacing={3} className={classes.promotionList}>
              {this.state.promotions.map((promotion, index) =>
                <Promotion key={index} promotion={promotion} onEditClick={this.handleEditDialogOpen} onDeleteClick={this.handleDeleteDialogOpen} />
              )}
            </Grid>
          )}
          {this.state.promotions.length == 0 && (
            <Box>
              <Typography variant="h5" component="h5" color="error" align="center" gutterBottom>
                <strong>No promotions found</strong>
              </Typography>
              <Typography component="p" align="center">
                Start creating now by clicking the "Blue icon" Button at the Bottom Right
              </Typography>
            </Box>
          )}

          {this.state.init && (
            <Box className={classes.loadingWrapper}>
              <FacebookCircularProgress />
            </Box>
          )}

          <Fab color="primary" onClick={this.handleCreateDialogOpen} className={classes.addButton}>
            <GroupAddIcon />
          </Fab>

          <NewRentalPromotion
            open={this.state.openCreateDialog}
            onClose={this.handleCreateDialogClose}
            onCreate={this.reloadPromotionList}
            api={this.api}
          />
          <EditRentalPromotion
            promotion={this.state.promotionToUpdate}
            open={this.state.openEditDialog}
            onClose={this.handleEditDialogClose}
            onUpdate={this.reloadPromotionList}
            api={this.api}
          />
          <DeleteRentalPromotion
            promotion={this.state.promotionToDelete}
            open={this.state.openDeleteDialog}
            onClose={this.handleDeleteDialogClose}
            onDelete={this.reloadPromotionList}
            api={this.api}
          />

          <Snackbar open={this.state.openSnackbar} autoHideDuration={6000} onClose={this.handleSnackbarClose}>
            <Alert onClose={this.handleSnackbarClose} severity={this.state.errorType} className="sb-msg">
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
        </Container>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(RentalPromotions);

function replaceAll (str, find, replace) {
  // return str.replace(new RegExp(find, 'g'), replace);
  return str.split(find).join(replace);
}