import React, { Component } from "react";
import {Helmet} from "react-helmet";
import "./../assets/css/aboutus.css";



class Terms extends Component {

    constructor (props) {
      super(props)
      this.state = {
        startDate: new Date(),
        endDate: new Date(),
        MetaTitle:"EARental | Offline Fee",
        MetaDescription:"EARental is Singapore No.1 Online Van, Lorry & Truck commercial vehicles for lease for businesses and individuals. Hassle-Free, book and reserve your vehicle Online Now!",
        MetaKeywords:"Offline Fee,online rental, van rental, lorry rental, truck rental, commercial vehicles for rent, leasing, daily rental, weekly rental, weekend rental, monthly rental, yearly rental, short term rental, long term rental, cargo van rental singapore, cheap van rental, van rental price, Toyota hiace van rental singapore, van rental daily, auto van rental singapore, commercial van for rent, van rental near me, daily van rental singapore",
        Canonical:"https://www.earental.com/offline-fee",
      };
    }



  componentDidMount() {
      window.scrollTo(0, 0)
    }

  render() {
    return (
        <div classNameName="about-us-cont">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="title" content={this.state.MetaTitle} />
                <meta content={this.state.MetaTitle} property="og:title" />
                <meta name="description" content={this.state.MetaDescription} />
                <meta content={this.state.MetaDescription} property="og:description" />
                <meta name="keywords" content={this.state.MetaKeywords} />
                <meta name="robots" content="index, follow" />
                <title>{this.state.MetaTitle}</title>
                <link rel="canonical" href={this.state.Canonical} />
            </Helmet>
            <div className="container">
                <h1 className="page-title">What is the EARental policy for collecting fees in person?</h1>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <article className="post">
                            <header className="post-header"></header>
                            <div className="post-inner">
                                <p>
                                Generally, lessor may not collect any additional fees or charges outside the EARental platform unless expressly authorized by EARental.
                                </p>
                            </div>
                        </article>
                    </div>
                </div>
            </div>

        </div>
    );
  }
}

export default Terms;
