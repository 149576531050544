import React, { Component }  from "react";
import {
    BrowserRouter,
    Route,
    Switch,
    withRouter
} from "react-router-dom";
import { createBrowserHistory } from "history";
import PropTypes from 'prop-types';
import axios, { post } from 'axios';
import { store } from 'react-notifications-component';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';


//PROFILES
import Overview from "./Overview";
import Settings from "./Settings";
import History from "./History";
import Cards from "./Cards";
import LoyaltyMiles from "./LoyaltyMiles";
import BillingAddresses from "./BillingAddresses";
import Drivers from "./Drivers";
import ProfileCard from "./Profilecard";
//END


class Content extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor (props) {
        const { cookies } = props;
        super(props)
        this.state = {
              xxx: ""
        }
    }

  componentDidMount() {
      const { cookies } = this.props;
      window.scrollTo(0, 0)
      var ctoken = localStorage.getItem('ctoken');
      var token = localStorage.getItem('token');
      if(ctoken){
          var bodyFormData = new FormData();
          bodyFormData.append('access_token', ctoken);
          bodyFormData.append('api_key', '72ffc1303c7c0286887a3276f0ab61b8');
          bodyFormData.append('jham_token', token);


          axios({
                  method: 'post',
                  url: 'https://api.earental.com/account/my_profile',
                  data: bodyFormData,
                  withCredentials: true,
                  headers: {'Content-Type': 'multipart/form-data' }
              })
              .then((response) => {
                  if(response.data.error > 0){
                      store.addNotification({
                        title: "Session expire",
                        message: response.data.error_msg,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: false
                        }
                      });

                      localStorage.removeItem("ctoken");
                      localStorage.removeItem("current_name");
                      localStorage.removeItem("current_avatar");
                      localStorage.removeItem('checkout');
                      localStorage.removeItem('checkoutProgress');
                      localStorage.removeItem('checkoutCompleted');
                      localStorage.removeItem('hasPendingPayment');
                      cookies.remove('ctoken', { path:'/',domain: '.earental.com' });
                      cookies.remove('cavatar', { path:'/',domain: '.earental.com' });

                      setTimeout(
                        () => window.location.href = "/sign-in",
                        150
                      );
                  }else{

                      var totaLongTerms = 0;
                      var totaShortTerms = 0;
                      if(response.data.schedules !== null){
                          totaLongTerms = response.data.schedules.longterms;
                          totaShortTerms = response.data.schedules.shortterms;
                      }

                      var ongoingRental = [];
                      if(response.data.ongoingrental !== null){
                          ongoingRental = response.data.ongoingrental;
                      }else{
                          this.setState({noResult:true});
                      }

                      this.setState({
                        firstName:response.data.data.customer_firstname,
                        lastName:response.data.data.customer_lastname,
                        email:response.data.data.customer_email,
                        lessorCode:response.data.data.c_lessor_code,
                        agreementSigned:response.data.data.customer_agreement_signed,
                        dateCreated: response.data.data.customer_date_created,
                        phoneNumber:response.data.data.customer_phonenumber,
                        totalOngoinRental:response.data.customer_ongoing_rental_total,
                        currentAvatar:response.data.data.customer_profile_image,
                        customer_company_name:response.data.data.customer_company_name,
                        customer_company_address:response.data.data.customer_company_address,
                        customer_acra:response.data.data.customer_acra,
                        customer_lessor_status: response.data.data.customer_lessor_status,
                        totalLongTerm:totaLongTerms,
                        totalShortTerm:totaShortTerms,
                        ongoingRental:ongoingRental,
                      })

                      localStorage.setItem('current_name', response.data.data.customer_firstname);
                      localStorage.setItem('current_avatar', response.data.data.customer_profile_image);
                      this.props.handlerState('currentAvatar',response.data.data.customer_profile_image);

                  }
              })
              .catch((error) => {
                  //handle error
                  console.log(error);
                //   this.setState({ loading:false });
                //   if (error.response.status == 403) {
                 //
                      store.addNotification({
                        title: "Oops Something went wrong",
                        message: "The action you have requested is not allowed.",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: false
                        }
                      });
                 //
                //  }
                 //
                 localStorage.removeItem("ctoken");
                 localStorage.removeItem("current_name");
                 localStorage.removeItem("current_avatar");
                 localStorage.removeItem('checkout');
                 localStorage.removeItem('checkoutProgress');
                 localStorage.removeItem('checkoutCompleted');
                 localStorage.removeItem('hasPendingPayment');
                 cookies.remove('ctoken', { path:'/',domain: '.earental.com' });
                 cookies.remove('cavatar', { path:'/',domain: '.earental.com' });

                 setTimeout(
                   () => window.location.href = "/sign-in",
                   150
                 );

              });

      }else{
          localStorage.removeItem("ctoken");
          localStorage.removeItem("current_name");
          localStorage.removeItem("current_avatar");
          localStorage.removeItem('checkout');
          localStorage.removeItem('checkoutProgress');
          localStorage.removeItem('checkoutCompleted');
          localStorage.removeItem('hasPendingPayment');
          cookies.remove('ctoken', { path:'/',domain: '.earental.com' });
          cookies.remove('cavatar', { path:'/',domain: '.earental.com' });

          setTimeout(
            () => window.location.href = "/sign-in",
            150
          );

      }




  }


  render() {
    const hist = createBrowserHistory();
    function PageTitle(props){
        return(
            <h1 className="page-title">{props.title}</h1>
        );
    }
    return (
        <BrowserRouter>
          <div className="profile-cont">
              <div className="container">
                  <Switch>
                      <Route path="/user-profile" render={() => (
                          <PageTitle title = {"Overview"} />
                      )} />
                      <Route path="/user-rental-history" render={() => (
                          <PageTitle title = {"Rental History"} />
                      )} />
                      <Route path="/user-settings" render={() => (
                          <PageTitle title = {"Profile Settings"} />
                      )} />
                      <Route path="/user-cards" render={() => (
                          <PageTitle title = {"Credit/Debit Cards"} />
                      )} />
                      <Route path="/user-loyalty-miles" render={() => (
                          <PageTitle title = {"Loyalty Miles"} />
                      )} />
                      <Route path="/user-lessee-addresses" render={() => (
                          <PageTitle title = {"Lessee Billing Addresses"} />
                      )} />
                      <Route path="/user-drivers" render={() => (
                          <PageTitle title = {"Drivers"} />
                      )} />
                  </Switch>
              </div>

              <div className="container">
                  <div className="row">
                      <div className="col-md-3">
                          <ProfileCard profileState={this.state} />
                      </div>
                      <div className="col-md-9">
                          <Switch>
                              <Route exact path="/user-profile" component={Overview}/>
                              <Route path="/user-rental-history" component={History}/>
                              <Route path="/user-settings" component={Settings}/>
                              <Route path="/user-cards" component={Cards}/>
                              <Route path="/user-loyalty-miles" component={LoyaltyMiles}/>
                              {/* <Route path="/user-lessee-addresses" component={BillingAddresses}/> */}
                              <Route path="/user-lessee-addresses" render={() => (
                                  <BillingAddresses handlerState = {this.props.handlerState} mainState={this.props.mainState} />
                              )} />
                              <Route path="/user-drivers" component={Drivers}/>
                          </Switch>

                      </div>
                  </div>
              </div>

              <div className="gap"></div>

          </div>
        </BrowserRouter>
    );
  }
}

export default withCookies(Content)
