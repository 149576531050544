import React, { useCallback } from "react";
import {
  withStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from 'axios';
import moment from 'moment';
import { store } from 'react-notifications-component';
import {Img} from 'react-image';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ReactCrop from 'react-image-crop';
import CircularProgress from '@material-ui/core/CircularProgress';
import EventIcon from '@material-ui/icons/Event';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import { grey, orange, blue } from '@material-ui/core/colors';

import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

import NoImage from './../assets/img/no-image.jpg';

import ImageSearchIcon from '@material-ui/icons/ImageSearch';

import getCroppedImg from './CropImage'

const DashedDivider = withStyles({
  root: {
    borderTop: '2px dashed',
    borderColor: grey[300],
    height: 0,
    backgroundColor: 'transparent',
  },
})(Divider);

const useStyles = makeStyles((theme) => ({
    cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
      marginRight:'15px'
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
  hidden: {
    display: 'none',
  },
  root: {
    color: grey[400],
  },
  inputUpload: {
    display: 'none !important',
  },
  uploadButton: {
    backgroundColor: blue[400] + ' !important',
  },
  noImage: {
    width: '100%'
  },
  dialogContent: {
    '& h6': {
      color: grey[700],
    },
    '& .MuiFormLabel-root': {
      color: grey[800],
      '&.Mui-focused': {
        color: grey[900],
      }
    },
  },
  dialogActions: {
    justifyContent: 'space-between',
    '& button': {
      fontSize: '1rem',
    },
  },
  newDriverForm: {
    alignItems: 'start',
    color: grey[800],
    '& .MuiFormControl-root': {
      '& .MuiFormLabel-root': {
        fontSize: '1.2rem',
        backgroundColor: 'white',
        paddingRight: 5,
        '&.Mui-focused': {
          color: grey[800],
        },
      },
      '& .MuiFormControlLabel-label': {
        fontSize: '1.2rem',
      },
      '& .MuiRadio-colorSecondary.Mui-checked': {
        color: orange[600],
      },
      '& .MuiSelect-select': {
        fontSize: '1.2rem',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        '&:hover fieldset': {
          borderColor: '#B4B4B4',
        },
        '&.Mui-focused fieldset': {
          borderColor: orange[600],
        },
        '& input': {
          fontSize: '1.2rem',
          '&.Mui-disabled': {
            color: grey[800],
          },
        },
      },
      '& .MuiRadio-root': {
        '& .Mui-focused': {
          color: orange[600],
        },
      },
      '& .MuiFormHelperText-root': {
        fontSize: '1rem',
        marginTop: 0,
      },
    },
  },
  imgError: {
    color: '#F44336',
    fontSize: '1rem',
    margin: 0,
    marginLeft: 14,
    marginRight: 14,
  },
  searchAddressInput: {
    '& label': {
      fontSize: '1.5rem !important',
    },
    '& .MuiInput-root': {
      '& input': {
        fontSize: '1.25rem',
        textAlign: 'center',
        textTransform: 'uppercase',
        paddingTop: theme.spacing(2),
      },
      '& .MuiCircularProgress-root': {
        color: orange[600],
      },
      '&:after': {
        borderColor: orange[600],
      },
      '&:hover::before': {
        borderColor: grey[500],
      },
    },
  },
  searchAddressResult: {
    position: 'absolute',
    width: '100%',
    backgroundColor: '#FFF',
    zIndex: 9,
    '& .MuiListItemText-primary': {
      fontSize: '1.1rem',
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: orange[600],
    },
  },
  closeSearchButton: {
    display: 'flex',
    fontWeight: 600,
    justifyContent: 'center',
    backgroundColor: grey[200],
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewDriver(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const apiURL = "https://api.earental.com";
  const apiKey = "72ffc1303c7c0286887a3276f0ab61b8";
  const accessToken = localStorage.getItem('ctoken');
  const jhamToken = localStorage.getItem('token');
  const currentDate = moment(localStorage.getItem('currentDate'), 'YYYY-MM-DD');

  // File upload and crop
  const [openCropper, setOpenCropper] = React.useState(false);
  const [selectedSrc, setSelectedSrc] = React.useState(false);
  const [selectedSrcCropped, setSelectedSrcCropped] = React.useState(false);
  const [selectedSrcType, setSelectedSrcType] = React.useState(false);
  const [selectedCrop, setSelectedCrop] = React.useState(false);
  const [src1, setSrc1] = React.useState(false);
  const [src2, setSrc2] = React.useState(false);
  const [src3, setSrc3] = React.useState(false);
  const [src4, setSrc4] = React.useState(false);

  const [src1Cam, setSrc1Cam] = React.useState(false);
  const [src2Cam, setSrc2Cam] = React.useState(false);
  const [src3Cam, setSrc3Cam] = React.useState(false);
  const [src4Cam, setSrc4Cam] = React.useState(false);

  const [imageRef1, setImageRef1] = React.useState(false);
  const [imageRef2, setImageRef2] = React.useState(false);
  const [imageRef3, setImageRef3] = React.useState(false);
  const [imageRef4, setImageRef4] = React.useState(false);
  const [crop1, setCrop1] = React.useState({
      unit: 'px',
      width: 0,
      height: 0,
      x: 0,
      y: 0
  });
  const [crop2, setCrop2] = React.useState({
      unit: 'px',
      width: 0,
      height: 0,
      x: 0,
      y: 0
  });
  const [crop3, setCrop3] = React.useState({
      unit: 'px',
      width: 0,
      height: 0,
      x: 0,
      y: 0
  });
  const [crop4, setCrop4] = React.useState({
      unit: 'px',
      width: 0,
      height: 0,
      x: 0,
      y: 0
  });

  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [showSearchResults, setShowSearchResults] = React.useState(false);
  const [searchAddressResult, setSearchAddressResult] = React.useState([]);
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const [searchTimeout, setSearchTimeout] = React.useState(null);
  const [state, setState] = React.useState({
    name: "",
    // birthDate: currentDate.subtract(24, 'years'),
    birthDate: null,
    mobile: "",
    expYears: 0,
    license: "",
    licenseImgFront: null,
    licenseImgBack: null,
    nric: "",
    nricImgFront: null,
    nricImgBack: null,
    addressType: "apartment",
    addressBlock: "",
    addressStreet: "",
    addressFloor: "",
    addressUnit: "",
    addressBuilding: "",
    addressCountry: "Singapore",
    addressPostal: "",
  });

  const [errors, setErrors] = React.useState({
    name: "",
    birthDate: "",
    mobile: "",
    expYears: "",
    license: "",
    licenseImgFront: "",
    licenseImgBack: "",
    nric: "",
    nricImgFront: "",
    nricImgBack: "",
    addressType: "",
    addressBlock: "",
    addressStreet: "",
    addressFloor: "",
    addressUnit: "",
    addressBuilding: "",
    addressCountry: "",
    addressPostal: "",
  });

  // EASY CROP
  let formSubmitButton = React.useRef(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 })
  const [rotation, setRotation] = React.useState(0)
  const [zoom, setZoom] = React.useState(0)
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null)
  const [croppedImage, setCroppedImage] = React.useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleDoneCrop = useCallback(async () => {
      try {
          const croppedImage = await getCroppedImg(
            selectedSrc,
            croppedAreaPixels,
            rotation
          )
          console.log('donee', { croppedImage })
          setSelectedSrcCropped(croppedImage)
          if (selectedSrcType == 1) {
            setState({
              ...state,
              licenseImgFront: croppedImage,
            });
            setSrc1(croppedImage);
          } else if (selectedSrcType == 2) {
            setState({
              ...state,
              licenseImgBack: croppedImage,
            });
            setSrc2(croppedImage)
          } else if (selectedSrcType == 3) {
            setState({
              ...state,
              nricImgFront: croppedImage,
            });
            setSrc3(croppedImage)
          } else if (selectedSrcType == 4) {
            setState({
              ...state,
              nricImgBack: croppedImage,
            });
            setSrc4(croppedImage)
          }

          setTimeout(() => {
              setSelectedSrcCropped(false);
              setOpenCropper(false);
              setSelectedSrc(false);
              setSelectedSrcType(false);
              setSrc1Cam(false);
          }, 350);

      } catch (e) {
      console.error(e)
      }
  }, [croppedAreaPixels, rotation])

  const onClose = useCallback(() => {
      setCroppedImage(null)
  }, [])

  const handleOpenCropper = (selected,type,crop,camera,e) => {
      setOpenCropper(true);
      setSelectedSrc(false);
      setSelectedSrcType(type);
      setSrc1Cam(camera);
      setRotation(0);
      setZoom(1);
  }
  const onCloseCropper = (e) => {
      setSelectedSrcCropped(false);
      setOpenCropper(false);
      setSelectedSrc(false);
      setSelectedSrcType(false);
      setSrc1Cam(false);
      setImgRef(false);
      setCropv2(
          {
              unit: '%',
              width: 90,
              height: 50,
              x: 5,
              y: 25
          }
      );
  }
  // END EASY CROP

  // CROP V2
  const [cropv2, setCropv2] = React.useState(
    {
        unit: '%',
        width: 90,
        height: 50,
        x: 5,
        y: 25
    }
  );

  const [imgRef, setImgRef] = React.useState(false);
  const onCropChange = (crop, percentCrop) => setCropv2(percentCrop);
  const onLoad = image => {
      setImgRef(image);
  };


  const getCroppedImgv2 = (image, crop, fileName) => {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);
      const base64Image = canvas.toDataURL('image/jpeg');
      return base64Image;
  }

  const handleDoneCropv2 = (type, crop) => {
      if(selectedSrc){
          const croppedImage = getCroppedImgv2(imgRef, cropv2, 'newFile.jpeg');

          try {
              if (selectedSrcType == 1) {
                setState({
                  ...state,
                  licenseImgFront: croppedImage,
                });
                setSrc1(croppedImage);
              } else if (selectedSrcType == 2) {
                setState({
                  ...state,
                  licenseImgBack: croppedImage,
                });
                setSrc2(croppedImage)
              } else if (selectedSrcType == 3) {
                setState({
                  ...state,
                  nricImgFront: croppedImage,
                });
                setSrc3(croppedImage)
              } else if (selectedSrcType == 4) {
                setState({
                  ...state,
                  nricImgBack: croppedImage,
                });
                setSrc4(croppedImage)
              }

              setTimeout(() => {
                  setSelectedSrcCropped(false);
                  setOpenCropper(false);
                  setSelectedSrc(false);
                  setSelectedSrcType(false);
                  setSrc1Cam(false);
                  setImgRef(false);
                  setCropv2(
                      {
                          unit: '%',
                          width: 90,
                          height: 50,
                          x: 5,
                          y: 25
                      }
                  );
              }, 350);

          } catch (e) {
          console.error(e)
          }
      }else{
          setSelectedSrcCropped(false);
          setOpenCropper(false);
          setSelectedSrc(false);
          setSelectedSrcType(false);
          setSrc1Cam(false);
          setImgRef(false);
          setCropv2(
              {
                  unit: '%',
                  width: 90,
                  height: 50,
                  x: 5,
                  y: 25
              }
          );
      }

  }
  //END

  const handleClickCam = (cam,e) => {
    setSrc1Cam(!src1Cam);
  }
  const handleInputChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  const handleDatePickerChange = (date) => {
    setState({
      ...state,
      birthDate: date,
    });
  }

  const handleFileSelect = (type, e) => {
    if (e.target.files && e.target.files.length > 0) {
      var reader = new FileReader();
      reader.addEventListener('load', () =>{
          setSelectedSrc(reader.result);
          setSelectedSrcCropped(reader.result);
        // if (type == 1) {
        //   setSrc1(reader.result)
        // } else if(type == 2) {
        //   setSrc2(reader.result)
        // } else if(type == 3) {
        //   setSrc3(reader.result)
        // } else if(type == 4) {
        //   setSrc4(reader.result)
        // }
      });
      reader.readAsDataURL(e.target.files[0]);
      setSrc1Cam(false);
    }
  };


  const handleTakePhoto = (dataUri,cam) => {
      setSelectedSrc(dataUri);
      setSelectedSrcCropped(dataUri);
      setSrc1Cam(!src1Cam);
  }

  const handleSearchAddressChange = (event) => {
    var currentSearch = event.target.value;

    if (currentSearch == "") {
      setSearchAddressResult([]);
      clearTimeout(searchTimeout);
    } else {
      setLoadingSearch(true);
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      setSearchTimeout(setTimeout(() => {
        var searchAPI = "https://developers.onemap.sg/commonapi/search?searchVal=" + currentSearch + "&returnGeom=Y&getAddrDetails=Y&pageNum=1";
        axios.get(searchAPI)
        .then(response => {
          var searchResult = [];
          response.data.results.map((address, i) => {
            if (address.POSTAL != "NIL") {
              searchResult.push({
                text: address.ADDRESS,
                block: address.BLK_NO != "NIL" ? address.BLK_NO : "",
                building: address.BUILDING != "NIL" ? address.BUILDING : "",
                street: address.ROAD_NAME != "NIL" ? address.ROAD_NAME : "",
                postal: address.POSTAL,
              });
            }
          });
          setSearchAddressResult(searchResult);
          setLoadingSearch(false);
          if (searchResult.length > 0) {
            setShowSearchResults(true);
          }
        });
      }, 750));
    }
  };

  const handleSearchAddressFocus = (event) => {
    var currentSearch = event.target.value;

    if (currentSearch != "" && searchAddressResult.length > 0) {
      setShowSearchResults(true);
    }
  };

  const handleSearchResultClick = (address) => {
    setSelectedAddress(address);
    setShowSearchResults(false);
    setState({
      ...state,
      addressBlock: address.block,
      addressStreet: address.street,
      addressBuilding: address.building,
      addressPostal: address.postal,
    });
  };

  const handleSaveButton = (event) => {
    formSubmitButton.click();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    var errorsCount = 0;
    var errorsFound = {
      name: "",
      birthDate: "",
      mobile: "",
      expYears: "",
      license: "",
      licenseImgFront: "",
      licenseImgBack: "",
      nric: "",
      nricImgFront: "",
      nricImgBack: "",
      addressType: "",
      addressBlock: "",
      addressStreet: "",
      addressFloor: "",
      addressUnit: "",
      addressBuilding: "",
      addressCountry: "",
      addressPostal: "",
    };
    if (state.licenseImgFront == null) {
      errorsCount++;
      errorsFound.licenseImgFront = "Upload license ID front image";
    }
    if (state.licenseImgBack == null) {
      errorsCount++;
      errorsFound.licenseImgBack = "Upload license ID back image";
    }
    if (state.nricImgFront == null) {
      errorsCount++;
      errorsFound.nricImgFront = "Upload NRIC ID front image";
    }
    if (state.nricImgBack == null) {
      errorsCount++;
      errorsFound.nricImgBack = "Upload NRIC ID back image";
    }

    if (errorsCount > 0) {
      setErrors(errorsFound);
    } else {
      let bodyFormData = new FormData();
          bodyFormData.append('api_key', apiKey);
          bodyFormData.append('jham_token', jhamToken);
          bodyFormData.append('access_token', accessToken);
          bodyFormData.append('cd_driver_name', state.name);
          bodyFormData.append('cd_birthdate', moment(state.birthDate).format('YYYY-MM-DD'));
          bodyFormData.append('cd_mobile_no', state.mobile);
          bodyFormData.append('cd_driver_yearexp', state.expYears);
          bodyFormData.append('cd_address_type', state.addressType);
          bodyFormData.append('cd_address_block', state.addressBlock);
          bodyFormData.append('cd_address_street', state.addressStreet);
          bodyFormData.append('cd_address_floor', state.addressFloor);
          bodyFormData.append('cd_address_unit', state.addressUnit);
          bodyFormData.append('cd_address_building', state.addressBuilding);
          bodyFormData.append('cd_address_country', state.addressCountry);
          bodyFormData.append('cd_address_postal', state.addressPostal);
          bodyFormData.append('cd_driver_licenseno', state.license);
          bodyFormData.append('cd_photo_driverlicfront', state.licenseImgFront);
          bodyFormData.append('cd_photo_driverlicback', state.licenseImgBack);
          bodyFormData.append('cd_nric', state.nric);
          bodyFormData.append('cd_photo_nricfront', state.nricImgFront);
          bodyFormData.append('cd_photo_nricback', state.nricImgBack);

      axios({
        method: 'post',
        url: apiURL + '/account/add_driver',
        data: bodyFormData,
        withCredentials: true,
        headers: {'Content-Type': 'multipart/form-data'}
      })
      .then((response) => {
        console.log(response.data)
        if (response.data.error > 0) {
          function ErrorMsg(props) {
            return (
              props.msgs.map((item, i) =>
                <li key={"err-"+item.i}>{item.value}</li>
              )
            );
          }
          store.addNotification({
            title: "Invalid information",
            message: <ErrorMsg msgs={response.data.error_message} />,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 20000,
              onScreen: false
            }
          });
        } else {
          resetFields();
          props.onDriverAdded();
        }
      })
      .catch((error) => {
        // Handle error
        console.log(error);
      });
    }
  }

  const resetFields = () => {
    setOpenCropper(false);
    setSelectedSrc(false);
    setSelectedSrcCropped(false);
    setSelectedSrcType(false);
    setSelectedCrop(false);
    setSrc1(false);
    setSrc2(false);
    setSrc3(false);
    setSrc4(false);
    setSrc1Cam(false);
    setSrc2Cam(false);
    setSrc3Cam(false);
    setSrc4Cam(false);
    setImageRef1(false);
    setImageRef2(false);
    setImageRef3(false);
    setImageRef4(false);
    setCrop1({
      unit: 'px',
      width: 0,
      height: 0,
      x: 0,
      y: 0
    });
    setCrop2({
      unit: 'px',
      width: 0,
      height: 0,
      x: 0,
      y: 0
    });
    setCrop3({
      unit: 'px',
      width: 0,
      height: 0,
      x: 0,
      y: 0
    });
    setCrop4({
      unit: 'px',
      width: 0,
      height: 0,
      x: 0,
      y: 0
    });
    setLoadingSearch(false);
    setShowSearchResults(false);
    setSearchAddressResult([]);
    setSelectedAddress(null);
    setSearchTimeout(null);
    setState({
      name: "",
      birthDate: null,
      mobile: "",
      expYears: 0,
      license: "",
      licenseImgFront: null,
      licenseImgBack: null,
      nric: "",
      nricImgFront: null,
      nricImgBack: null,
      addressType: "apartment",
      addressBlock: "",
      addressStreet: "",
      addressFloor: "",
      addressUnit: "",
      addressBuilding: "",
      addressCountry: "Singapore",
      addressPostal: "",
    });
    setErrors({
      name: "",
      birthDate: "",
      mobile: "",
      expYears: "",
      license: "",
      licenseImgFront: "",
      licenseImgBack: "",
      nric: "",
      nricImgFront: "",
      nricImgBack: "",
      addressType: "",
      addressBlock: "",
      addressStreet: "",
      addressFloor: "",
      addressUnit: "",
      addressBuilding: "",
      addressCountry: "",
      addressPostal: "",
    });
    setCrop({ x: 0, y: 0 });
    setRotation(0);
    setZoom(0);
    setCroppedAreaPixels(null);
    setCroppedImage(null);
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={props.open}
        TransitionComponent={Transition}
        onClose={props.onClose}
        className={classes.root}
        disableBackdropClick
        fullScreen={fullScreen}
      >
        <DialogTitle>
          New Driver
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form onSubmit={handleSubmit}>
            <Button type="submit" ref={button => formSubmitButton = button} style={{ display: 'none' }}>hidden</Button>
            <Grid container spacing={4} className={classes.newDriverForm}>
              <Grid container item xs={12} sm={8} spacing={3} style={{ marginRight: 0 }}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Driver Details
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    variant="outlined"
                    id="name"
                    name="name"
                    label="Name"
                    fullWidth
                    size="small"
                    value={state.name}
                    onChange={handleInputChange}
                    error={errors.name != ""}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      required
                      disableFuture
                      fullWidth
                      autoOk={true}
                      inputVariant="outlined"
                      variant="inline"
                      id="birthDate"
                      name="birthDate"
                      label="Birth Date"
                      format="MM/dd/yyyy"
                      placeholder="mm/dd/yyyy"
                      size="small"
                      value={state.birthDate}
                      onChange={handleDatePickerChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end"><EventIcon /></InputAdornment>,
                      }}
                      error={errors.birthDate != ""}
                      helperText={errors.birthDate}
                      initialFocusedDate={currentDate.subtract(24, 'years')}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    variant="outlined"
                    id="Mobile"
                    name="mobile"
                    label="Mobile"
                    fullWidth
                    size="small"
                    value={state.mobile}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+65</InputAdornment>,
                      endAdornment: <InputAdornment position="end"><SmartphoneIcon /></InputAdornment>,
                    }}
                    error={errors.mobile != ""}
                    helperText={errors.mobile}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    variant="outlined"
                    id="expYears"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    name="expYears"
                    label="Years of Exp."
                    fullWidth
                    size="small"
                    value={state.expYears}
                    onChange={handleInputChange}
                    error={errors.expYears != ""}
                    helperText={errors.expYears}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    variant="outlined"
                    id="license"
                    name="license"
                    label="Driver's License"
                    fullWidth
                    size="small"
                    value={state.license}
                    onChange={handleInputChange}
                    error={errors.license != ""}
                    helperText={errors.license}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    variant="outlined"
                    id="nric"
                    name="nric"
                    label="NRIC"
                    fullWidth
                    size="small"
                    value={state.nric}
                    onChange={handleInputChange}
                    error={errors.nric != ""}
                    helperText={errors.nric}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DashedDivider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Address Details
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Search address via postal code or location name"
                    size="small"
                    className={classes.searchAddressInput}
                    onChange={handleSearchAddressChange}
                    onFocus={handleSearchAddressFocus}
                    InputProps={{
                      endAdornment: loadingSearch && (
                        <InputAdornment position="end">
                          <CircularProgress size="1.5rem" />
                        </InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} style={{ paddingTop: 0, position: 'relative' }} className={!showSearchResults && "hidden"}>
                  <List className={classes.searchAddressResult}>
                    {searchAddressResult.map((address, i) => {
                      return (
                        <React.Fragment key={i}>
                          <ListItem button onClick={() => { handleSearchResultClick(address) }}>
                            <ListItemText
                              primary={address.text}
                            />
                            <ListItemSecondaryAction>
                              <Radio
                                edge="end"
                                checked={selectedAddress == address}
                                onChange={() => { handleSearchResultClick(address) }}
                                value={i}
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider component="li" />
                        </React.Fragment>
                      );
                    })}
                    <ListItem button onClick={() => setShowSearchResults(false)} className={classes.closeSearchButton}>
                        CLOSE SEARCH
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    select
                    SelectProps={{native: true}}
                    variant="outlined"
                    id="addressType"
                    name="addressType"
                    label="House/Apt/Business"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={state.addressType}
                    onChange={handleInputChange}
                    error={errors.addressType != ""}
                    helperText={errors.addressType}
                  >
                    <option value={'apartment'}>Apartment</option>
                    <option value={'house'}>House</option>
                    <option value={'business'}>Business</option>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    variant="outlined"
                    id="addressBlock"
                    name="addressBlock"
                    label="Block/House No."
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={state.addressBlock}
                    onChange={handleInputChange}
                    error={errors.addressBlock != ""}
                    helperText={errors.addressBlock}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    variant="outlined"
                    id="addressStreet"
                    name="addressStreet"
                    label="Street"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={state.addressStreet}
                    onChange={handleInputChange}
                    error={errors.addressStreet != ""}
                    helperText={errors.addressStreet}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="addressFloor"
                        name="addressFloor"
                        label="Floor"
                        autoComplete="off"
                        size="small"
                        value={state.addressFloor}
                        onChange={handleInputChange}
                        error={errors.addressFloor != ""}
                        helperText={errors.addressFloor}
                      />
                    </Grid>
                    <Grid item xs={2} align="center">
                      <Typography component="span">&mdash;</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="addressUnit"
                        name="addressUnit"
                        label="Unit"
                        autoComplete="off"
                        size="small"
                        value={state.addressUnit}
                        onChange={handleInputChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="addressBuilding"
                    name="addressBuilding"
                    label="Building Name"
                    autoComplete="off"
                    size="small"
                    value={state.addressBuilding}
                    onChange={handleInputChange}
                    error={errors.addressBuilding != ""}
                    helperText={errors.addressBuilding}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    variant="outlined"
                    id="addressCountry"
                    name="addressCountry"
                    label="Country"
                    fullWidth
                    autoComplete="off"
                    value="Singapore"
                    size="small"
                    value={state.addressCountry}
                    onChange={handleInputChange}
                    error={errors.addressCountry != ""}
                    helperText={errors.addressCountry}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    variant="outlined"
                    id="addressPostal"
                    name="addressPostal"
                    label="Postal Code"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={state.addressPostal}
                    onChange={handleInputChange}
                    error={errors.addressPostal != ""}
                    helperText={errors.addressPostal}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} sm={4} spacing={2} style={{ marginLeft: 0 }}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Upload photos of your License Card and NRIC below
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={{ width: '100%' }}>
                    <Grid container justify="space-between" alignItems="center" spacing={2}>
                        <Grid item>
                          License (Front)
                        </Grid>
                        <Grid item>
                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                <Button  onClick={handleOpenCropper.bind(this,src1,1,crop1,true)}><CameraAltIcon /></Button>
                                <Button  onClick={handleOpenCropper.bind(this,src1,1,crop1,false)}>Upload</Button>
                            </ButtonGroup>
                        </Grid>
                      </Grid>
                    {src1 ? (
                        <Box className={classes.imageContainer}>
                          <Img
                            src={src1}
                            loader={<CircularProgress size="1.5rem" className={classes.imageLoader} />}
                            unloader={<img src={NoImage} />}
                          />
                        </Box>
                    ) : (
                      <Box>
                        <img src={NoImage} className={classes.noImage} />
                      </Box>
                    )}
                    {errors.licenseImgFront != "" && (
                      <Typography component="span" className={classes.imgError}>
                        {errors.licenseImgFront}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={{ width: '100%' }}>
                    <Grid container justify="space-between" alignItems="center" spacing={2}>
                        <Grid item>
                            License (Back)
                        </Grid>
                        <Grid item>
                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                <Button  onClick={handleOpenCropper.bind(this,src2,2,crop2,true)}><CameraAltIcon /></Button>
                                <Button  onClick={handleOpenCropper.bind(this,src2,2,crop2,false)}>Upload</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                    {src2 ? (
                        <Box className={classes.imageContainer}>
                          <Img
                            src={src2}
                            loader={<CircularProgress size="1.5rem" className={classes.imageLoader} />}
                            unloader={<img src={NoImage} />}
                          />
                        </Box>
                    ) : (
                      <Box>
                        <img src={NoImage} className={classes.noImage} />
                      </Box>
                    )}
                    {errors.licenseImgBack != "" && (
                      <Typography component="span" className={classes.imgError}>
                        {errors.licenseImgBack}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={{ width: '100%' }}>
                    <Grid container justify="space-between" alignItems="center" spacing={2}>
                        <Grid item>
                           NRIC (Front)
                        </Grid>
                        <Grid item>
                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                <Button  onClick={handleOpenCropper.bind(this,src3,3,crop3,true)}><CameraAltIcon /></Button>
                                <Button  onClick={handleOpenCropper.bind(this,src3,3,crop3,false)}>Upload</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                    {src3 ? (
                        <Box className={classes.imageContainer}>
                          <Img
                            src={src3}
                            loader={<CircularProgress size="1.5rem" className={classes.imageLoader} />}
                            unloader={<img src={NoImage} />}
                          />
                        </Box>
                    ) : (
                      <Box>
                        <img src={NoImage} className={classes.noImage} />
                      </Box>
                    )}
                    {errors.nricImgFront != "" && (
                      <Typography component="span" className={classes.imgError}>
                        {errors.nricImgFront}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={{ width: '100%' }}>
                    <Grid container justify="space-between" alignItems="center" spacing={2}>
                        <Grid item>
                           NRIC (Back)
                        </Grid>
                        <Grid item>
                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                <Button  onClick={handleOpenCropper.bind(this,src4,4,crop4,true)}><CameraAltIcon /></Button>
                                <Button  onClick={handleOpenCropper.bind(this,src4,4,crop4,false)}>Upload</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                    {src4 ? (
                        <Box className={classes.imageContainer}>
                          <Img
                            src={src4}
                            loader={<CircularProgress size="1.5rem" className={classes.imageLoader} />}
                            unloader={<img src={NoImage} />}
                          />
                        </Box>
                    ) : (
                      <Box>
                        <img src={NoImage} className={classes.noImage} />
                      </Box>
                    )}
                    {errors.nricImgBack != "" && (
                      <Typography component="span" className={classes.imgError}>
                        {errors.nricImgBack}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button color="default" onClick={props.onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSaveButton}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openCropper}
      TransitionComponent={Transition}
      onClose={onCloseCropper}
      className={classes.root}
      disableBackdropClick
      fullScreen={fullScreen}
      >
          <DialogTitle>
              Upload Photo
          </DialogTitle>
          <DialogContent className={classes.dialogContent} style={{padding:"0 30px"}}>
              <Grid container spacing={2} style={{ marginLeft: 0 }}>
                  <Grid item xs={12} >
                      {src1Cam && (
                          <Camera
                              idealFacingMode = {"environment"}
                              isImageMirror = {false}
                              imageCompression = {0.97}
                              isSilentMode = {true}
                              idealResolution = {{width: 640, height: 480}}
                              isMaxResolution = {true}
                              isImageMirror = {false}
                              isFullscreen = {false}
                              sizeFactor = {1}
                              onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri,selectedSrcType) } }
                          />
                      )}
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                      <ButtonGroup color="primary" aria-label="outlined primary button group">
                          <input
                            accept="image/*"
                            id="licenseFront"
                            type="file"
                            onChange={handleFileSelect.bind(this, selectedSrcType)}
                            className={classes.inputUpload}
                          />
                          <label htmlFor="licenseFront">
                            <Button startIcon={<ImageSearchIcon />}  component="span"  disableElevation>
                              Browse File
                            </Button>
                          </label>
                          <label>
                              <Button
                                  startIcon={<CameraAltIcon />}
                                  component="span"
                                  onClick={handleClickCam.bind(this,selectedSrcType)}
                                  disableElevation>
                                Toggle Camera
                              </Button>
                          </label>
                      </ButtonGroup>

                  </Grid>
                </Grid>
                <br />
                {selectedSrc && (
                    <div>
                        <ReactCrop src={selectedSrc} onChange={onCropChange} crop={cropv2} onChange={newCrop => setCropv2(newCrop)} onImageLoaded={onLoad} />
                    </div>
                )}
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
              <Button color="default" onClick={onCloseCropper}>
              Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={handleDoneCropv2.bind(this,selectedSrcType)}>
              Done
              </Button>
          </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
