import React, { Component } from "react";
import {
  NavLink,
} from "react-router-dom";
import axios from 'axios';
import { store } from 'react-notifications-component';
import { instanceOf } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// import Home from "./Home";
// import Stuff from "./Stuff";
// import Contact from "./Contact";
// import ScriptTag from 'react-script-tag';
const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(find, 'gi'), replace);
}

class LessorAgreement extends Component {
    constructor (props) {
        super(props)
        var agreement = this.props.agreement;
        if(this.props.signature){
            console.log(this.props.signature);
            agreement = agreement.replaceAll("[[YOUR_SIGNATURE_GOES_HERE]]", '<img src="' + this.props.signature + '" class="img-lessor-signature" />')
            console.log(agreement);

        }
        this.state ={
            redirect:false,
            customer_company_name: this.props.profileState.customer_company_name,
            customer_company_address:this.props.profileState.customer_company_address,
            agreement:agreement,
            signature:this.props.signature

        }






    }


  render() {
    return (
        <>
            <Box className="lessor-agreement" dangerouslySetInnerHTML={{ __html: this.state.agreement }} />
        </>

    );
  }
}
export default LessorAgreement
