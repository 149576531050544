import React from "react";
import { withRouter } from 'react-router-dom';
import {
  createMuiTheme,
  withStyles,
  ThemeProvider
} from '@material-ui/core/styles';
import axios from 'axios';
import ReactGA from 'react-ga';
import { store } from 'react-notifications-component';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import RecurringManualPaymentDetails from './RecurringManualPaymentDetails';
import CheckoutForm from "./CheckoutForm";
import CustomCircularProgress from "../widgets/CircularProgress";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelIcon from '@material-ui/icons/Cancel';
import { green, red } from '@material-ui/core/colors';

import "../assets/css/checkout.css";

const useStyles = theme => ({
  layout: {
    padding: theme.spacing(5, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
    }
  },
  title: {
    fontWeight: 300,
    marginBottom: theme.spacing(3),
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    color: "#FFF",
    backgroundColor: "#262626",
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  gridItem1: {
    order: 2,
    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  gridItem2: {
    order: 1,
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
  },
  completedStatusIcon: {
    fontSize: '10rem',
    color: green[600],
  },
  failedStatusIcon: {
    fontSize: '10rem',
    color: red[600],
  },
  statusMessage: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '3.5rem',
    },
  },
  statusCaption: {
    fontSize: '2rem !important',
    margin: '0 auto',
    marginTop: theme.spacing(4),
    maxWidth: 800,
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.5rem !important',
    },
  },
  list: {
    '& li': {
      marginLeft: theme.spacing(3),
    },
  },
});

const theme = createMuiTheme({
  typography: {
    fontSize: 18,
  },
});

class RecurringManualPayment extends React.Component {
  constructor(props) {
    super(props);

    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);

    this.api = {
      url: props.mainState.apiURL,
      key: props.mainState.apiKey,
      accessToken: localStorage.getItem('ctoken'),
      jhamToken: localStorage.getItem('token'),
      // url: "https://api.earental.com",
      // jhamToken: "7753531b4957b2ebf2c9f1cb346a0b34",
    };

    this.state = {
      loading: true,
      stripePublishableKey: "",
      stripeClientSecret: "",
      billingAddress: null,
      vehicle: null,
      schedule: null,
      billingPeriod: null,
      paymentStatus: "pending",
    };
  };

  componentDidMount() {
    const sid = this.props.match.params.id;

    let formData = new FormData();
    formData.append('api_key', this.api.key);
    formData.append('access_token', localStorage.getItem('ctoken'));
    formData.append('jham_token', localStorage.getItem('token'));
    formData.append('schedule_id', sid);

    // Check for pending payment
    axios({
      method: 'post',
      url: this.api.url + '/transaction/check_schedule_failed_payment',
      data: formData,
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => {
        if (response.data.error == 0) {
          var scheduleData = {
            id: response.data.schedule.id,
            startDate: response.data.schedule.start_date,
            endDate: response.data.schedule.end_date,
          };
          var vehicleData = {
            id: response.data.vehicle.id,
            name: response.data.vehicle.name,
            image: response.data.vehicle.image,
            plateNumber: response.data.vehicle.plate_number,
          };
          var billingPeriod = {
            startDate: response.data.billing_period.start_date,
            endDate: response.data.billing_period.end_date,
            chargeAmount: response.data.billing_period.charge_amount,
          };
          this.setState({
            loading: false,
            stripePublishableKey: response.data.payment_params.publishable_key,
            stripeClientSecret: response.data.payment_params.client_secret,
            schedule: scheduleData,
            vehicle: vehicleData,
            billingPeriod: billingPeriod,
          });
        } else {
          var redirect = false;
          var redirectURL = "";
          var errorTitle = "";
          var errorMsg = "";
          switch (response.data.error_type) {
            case "login_error":
              redirect = true;
              redirectURL = "/sign-in?r=/lease/manual-payment/" + sid;
              errorTitle = "Your session has expired";
              errorMsg = "Please login again. This page will automatically reload.";
              break;
            case "invalid_parameter":
              redirect = true;
              redirectURL = "/";
              errorTitle = "Something went wrong";
              errorMsg = response.data.error_msg + " This page will automatically reload.";
              break;
            default:
              break;
          }
          showNotification(errorTitle, errorMsg, "danger");
          // Redirect
          if (redirect == true) {
            setTimeout(function () {
              this.props.history.push(redirectURL);
            }.bind(this), 3000);
          }
          return false;
        }
      })
      .catch(error => {
        // handle error
        if (error.response && error.response.status == 403) {
          let notifTitle = "Something went wrong";
          let notifMessage = "The action you have requested is not allowed. Please reload the page";
          let notifType = "danger";
          showNotification(notifTitle, notifMessage, notifType);
        }
      });
  }

  handlePaymentSuccess = (paymentIntentID) => {
    this.setState({
      loading: true,
    });
    let formData = new FormData();
    formData.append('api_key', this.api.key);
    formData.append('access_token', localStorage.getItem('ctoken'));
    formData.append('jham_token', localStorage.getItem('token'));
    formData.append('schedule_id', this.state.schedule.id);
    formData.append('payment_intent_id', paymentIntentID);
    formData.append('new_card', localStorage.getItem('newCard'));

    axios({
      method: 'post',
      url: this.api.url + '/transaction/recurring_manual_payment',
      data: formData,
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => {
        if (response.data.error == 0) {
          this.setState({
            loading: false,
            paymentStatus: "completed",
          });
        } else if (response.data.error > 0 && response.data.error_type == "card_error") {
          this.setState({
            loading: false,
            paymentStatus: "failed",
          });
        } else {
          var redirect = false;
          var redirectURL = "";
          var errorTitle = "";
          var errorMsg = "";
          switch (response.data.error_type) {
            case "login_error":
              redirect = true;
              redirectURL = "/sign-in";
              errorTitle = "Your session has expired";
              errorMsg = "Please login again. This page will automatically reload.";
              break;
            case "invalid_parameter":
              redirect = false;
              redirectURL = "/";
              errorTitle = "Something went wrong";
              errorMsg = response.data.error_msg + " Please reload this page.";
              break;
            default:
              break;
          }
          showNotification(errorTitle, errorMsg, "danger");
          // Redirect
          if (redirect == true) {
            setTimeout(function () {
              this.props.history.push(redirectURL);
            }.bind(this), 3000);
          }
          return false;
        }
      })
      .catch((error) => {
        // handle error
        if (error.response && error.response.status == 403) {
          let errorTitle = "Oops Something went wrong";
          let errorMsg = "The action you have requested is not allowed. Please reload the page";
          showNotification(errorTitle, errorMsg, "danger"); // Show error notification
        }
        this.setState({ loading: false });
      });
  }

  handlePaymentFailed = (error) => {
    this.setState({
      paymentStatus: "failed",
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="lg" className="checkout" classes={{ root: classes.layout }}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={7} md={8} className={classes.gridItem1}>
                {this.state.loading && (
                  <Box className={classes.loadingWrapper}>
                    <CustomCircularProgress />
                  </Box>
                )}

                {!this.state.loading && this.state.paymentStatus == "pending" &&
                  <React.Fragment>
                    <Typography variant="body1" style={{ marginBottom: theme.spacing(4) }}>
                      Your recurring payment for your lease: {this.state.schedule.id} has failed. Please complete your payment below.
                    </Typography>
                    <CheckoutForm publishableKey={this.state.stripePublishableKey} clientSecret={this.state.stripeClientSecret} onSuccess={this.handlePaymentSuccess} onFailed={this.handlePaymentFailed} expectedPaymentStatus="succeeded" paymentType="recurring" />
                  </React.Fragment>
                }

                {!this.state.loading && this.state.paymentStatus == "completed" &&
                  <Container component="main" maxWidth="lg" className={classes.layout}>
                    <Typography variant="h3" align="center" gutterBottom>
                      <CheckCircleRoundedIcon className={classes.completedStatusIcon} />
                    </Typography>
                    <Typography variant="h3" component="h1" align="center" gutterBottom className={classes.statusMessage}>
                      Transaction Completed
                    </Typography>
                    <Typography variant="caption" component="h1" align="center" className={classes.statusCaption}>
                      Thank you for leasing with EARental. You will receive an Email shortly with the invoice attached. Have a wonderful day.
                    </Typography>
                  </Container>
                }

                {!this.state.loading && this.state.paymentStatus == "failed" &&
                  <Container component="main" maxWidth="sm" className={classes.layout}>
                    <Typography variant="h3" align="center">
                      <CancelIcon className={classes.failedStatusIcon} />
                    </Typography>
                    <Typography variant="h4" component="h1" align="center" gutterBottom>
                      Your payment has failed
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      Here are some of the reasons why this transaction was declined.
                    </Typography>
                    <Typography variant="body1" component="ul" className={classes.list} gutterBottom>
                      <li>Invalid card details was entered on the payment form;</li>
                      <li>You have insufficient funds in your account;</li>
                      <li>Your card may have a temporary hold;</li>
                      <li>The card you used may have been designated as lost or stolen;</li>
                    </Typography>
                    <Typography variant="body1">
                      Please <a href={"/lease/manual-payment/" + this.state.schedule.id}>try again with a valid card details</a> and contact your bank if necessary.
                    </Typography>
                  </Container>
                }
              </Grid>

              <Grid item xs={12} sm={5} md={4} className={classes.gridItem2}>
                <RecurringManualPaymentDetails vehicle={this.state.vehicle} schedule={this.state.schedule} billingPeriod={this.state.billingPeriod} />
              </Grid>
            </Grid>
          </Container>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(withRouter(RecurringManualPayment));

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const showNotification = (title, message, type) => {
  store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: false
    }
  });
}