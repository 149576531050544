import React, { Component, useState } from "react";
import { NavLink, HashRouter } from "react-router-dom";
import axios, { post } from "axios";
import { store } from "react-notifications-component";
import { makeStyles } from "@material-ui/core/styles";
import Pager from "react-pager";
import moment from "moment";
import Tooltip from '@material-ui/core/Tooltip';
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import Chip from "@material-ui/core/Chip";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import { Box } from "@material-ui/core";
import { grey, orange } from "@material-ui/core/colors";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import clsx from "clsx";
import FaceOutlinedIcon from "@material-ui/icons/FaceOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import AddShoppingCartOutlinedIcon from "@material-ui/icons/AddShoppingCartOutlined";
import PictureAsPdfRoundedIcon from "@material-ui/icons/PictureAsPdfRounded";
import NumberFormat from "react-number-format";
import "./../assets/css/profile.css";
import aboutTopBanner from "./../assets/img/about-us.jpg";
import aboutParallaxBanner from "./../assets/img/parallax-about.jpg";
import Link from '@material-ui/core/Link';

//SIDE CARD UNG MY AVATAR
import ProfileCard from "./../profile/Profilecard";
import ScheduleExtension from "./components/ScheduleExtension";

var aboutTopBannerImg = {
  backgroundImage: "url(" + aboutTopBanner + ")",
};

var aboutParallaxBannerImg = {
  backgroundImage: "url(" + aboutParallaxBanner + ")",
};

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  root: {
    backgroundColor: "blue",
    color: "green",
  },
  toolbar: {
    backgroundColor: "white",
  },
  caption: {
    color: "red",
    fontSize: "20px",
  },
  selectIcon: {
    color: "green",
  },
  select: {
    color: "green",
    fontSize: "20px",
  },
  actions: {
    color: "blue",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    outline: "none",
  },
  newCardForm: {
    color: grey[800],
    "& .MuiFormControl-root": {
      "& .MuiFormLabel-root": {
        fontSize: "1.2rem",
        "&.Mui-focused": {
          color: grey[800],
          backgroundColor: "white",
          paddingRight: 5,
        },
      },
      "& .MuiInputBase-root": {
        borderRadius: 0,
        "&:hover fieldset": {
          borderColor: "#B4B4B4",
        },
        "&.Mui-focused fieldset": {
          borderColor: orange[600],
        },
        "& input": {
          fontSize: 15,
          font: "auto",
        },
      },
    },
  },
  link: {
    underlineHover: 'rgb(245, 0, 87)',
  },
}));

class Overview extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    
    this.api = {
      url: "https://api.earental.com",
      key: "72ffc1303c7c0286887a3276f0ab61b8",
    };
    this.state = {
      customer_id: 0,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      totalOngoinRental: 0,
      totalLongTerm: 0,
      totalShortTerm: 0,
      ongoingRental: [],
      limit: 5,
      offset: 0,
      visiblePage: 5,
      currentPage: 0,
      totalPages: 0,
      noResult: false,
      lessorCode: false,
      openInvoice: false,
      currentInvoice: null,
      openCancelConfirm: false,
      loyaltyMiles: 0,
      openExtendDialog: false,
    };

    this.handlePageChanged = this.handlePageChanged.bind(this);
    this.confirmCancel = this.confirmCancel.bind(this);
    this.cancelTransaction = this.cancelTransaction.bind(this);
    this.handleGetInvoice = this.handleGetInvoice.bind(this);
    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleCloseInvoice = this.handleCloseInvoice.bind(this);
  }

  componentDidMount() {
    var ctoken = localStorage.getItem("ctoken");
    var token = localStorage.getItem("token");
    if (ctoken) {
      var bodyFormData = new FormData();
      bodyFormData.append("access_token", ctoken);
      bodyFormData.append("api_key", "72ffc1303c7c0286887a3276f0ab61b8");
      bodyFormData.append("jham_token", token);
      bodyFormData.append("active", "Active");
      bodyFormData.append("limit", this.state.limit);
      bodyFormData.append("offset", this.state.offset);

      axios({
        method: "post",
        url: "https://api.earental.com/account/my_profile",
        data: bodyFormData,
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          if (response.data.error > 0) {
            store.addNotification({
              title: "Session expire",
              message: response.data.error_msg,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: false,
              },
            });
          } else {
            //   var totalPages = parseInt(response.data.customer_ongoing_rental_total) / parseInt(this.state.limit);
            //       totalPages = Math.ceil(totalPages);
            var totaLongTerms = 0;
            var totaShortTerms = 0;
            if (response.data.schedules !== null) {
              totaLongTerms = response.data.schedules.longterms;
              totaShortTerms = response.data.schedules.shortterms;
            }

            var ongoingRental = [];
            if (
              response.data.ongoingrental !== null &&
              response.data.ongoingrental.length > 0
            ) {
              ongoingRental = response.data.ongoingrental;
            } else {
              this.setState({ noResult: true });
            }

            this.setState({
              firstName: response.data.data.customer_firstname,
              lastName: response.data.data.customer_lastname,
              email: response.data.data.customer_email,
              lessorCode: response.data.data.c_lessor_code,
              dateCreated: moment(
                response.data.data.customer_date_created
              ).format("LL"),
              phoneNumber: response.data.data.customer_phonenumber,
              totalOngoinRental: response.data.customer_ongoing_rental_total,
              currentAvatar: response.data.data.customer_profile_image,
              totalLongTerm: totaLongTerms,
              totalShortTerm: totaShortTerms,
              ongoingRental: ongoingRental,
              totalPages: response.data.total_pages,
              loyaltyMiles: response.data.data.customer_loyalty_miles,
            });
            // this.props.handlerState('currentAvatar',response.data.data.customer_profile_image);
            localStorage.setItem(
              "current_name",
              response.data.data.customer_firstname
            );
            localStorage.setItem(
              "current_avatar",
              response.data.data.customer_profile_image
            );
          }
        })
        .catch((error) => {
          localStorage.removeItem("ctoken");
          localStorage.removeItem("current_name");
          localStorage.removeItem("current_avatar");
          localStorage.removeItem("checkout");
          localStorage.removeItem("checkoutProgress");
          localStorage.removeItem("checkoutCompleted");
          localStorage.removeItem("hasPendingPayment");

          setTimeout(() => (window.location.href = "/sign-in"), 150);
        });
    } else {
      localStorage.removeItem("ctoken");
      localStorage.removeItem("current_name");
      localStorage.removeItem("current_avatar");
      localStorage.removeItem("checkout");
      localStorage.removeItem("checkoutProgress");
      localStorage.removeItem("checkoutCompleted");
      localStorage.removeItem("hasPendingPayment");

      setTimeout(() => (window.location.href = "/sign-in"), 150);
    }
  }

  confirmCancel(rental, e) {
    this.setState({
      openCancelConfirm: true,
      currentTransaction: rental,
    });
  }

  handleExpandClick(e) {
    // this.setState({
    //   openCancelConfirm:true,
    //   currentTransaction: rental
    // })
  }

  confirmCancel(rental, e) {
    this.setState({
      openCancelConfirm: true,
      currentTransaction: rental,
    });
  }

  handleCloseInvoice() {
    this.setState({
      openInvoice: false,
      currentInvoice: false,
    });
  }

  handleGetInvoice(s_id, e) {
    var bodyFormData = new FormData();
    var token = localStorage.getItem("token");
    var ctoken = localStorage.getItem("ctoken");
    // var ctoken = "e52fedd02acc588f4dd2a9e334903564";
    bodyFormData.append("jham_token", token);
    bodyFormData.append("access_token", ctoken);
    bodyFormData.append("s_id", s_id);
    bodyFormData.append("api_key", "72ffc1303c7c0286887a3276f0ab61b8");

    axios({
      method: "post",
      url: "https://api.earental.com/account/lease_schedule_details",
      data: bodyFormData,
      withCredentials: true,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        if (response.data.error > 0) {
          store.addNotification({
            title: "Error",
            message:
              "Invoice not found, kindly refresh the page and try again.",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false,
            },
          });
        } else {
          this.setState({
            openInvoice: true,
            currentInvoice: response.data.data,
          });
        }
      })
      .catch((error) => {
        //handle error
        console.log(error);
      });
  }

  cancelTransaction(confirmation, e) {
    if (confirmation) {
      localStorage.setItem(
        "scheduleToCancel",
        this.state.currentTransaction.s_id
      );
      this.props.history.push("/cancellation");
      // alert('redirect to payment');
      console.log(this.state.currentTransaction);
      this.setState({
        openCancelConfirm: false,
        currentTransaction: false,
      });
      return false;
      var bodyFormData = new FormData();
      var token = localStorage.getItem("token");
      var ctoken = localStorage.getItem("ctoken");

      bodyFormData.append("s_id", this.state.currentTransaction);
      bodyFormData.append("jham_token", token);
      bodyFormData.append("access_token", ctoken);
      bodyFormData.append("api_key", "72ffc1303c7c0286887a3276f0ab61b8");

      axios({
        method: "post",
        url: "https://api.earental.com/account/cancel_transaction",
        data: bodyFormData,
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          if (response.data.error > 0) {
            function ErrorMsg(props) {
              return props.msgs.map((item, i) => (
                <li key={"err-" + item.id}>{item.value}</li>
              ));
            }
            store.addNotification({
              title: "Invalid information",
              message: <ErrorMsg msgs={response.data.error_message} />,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 20000,
                onScreen: false,
              },
            });

            this.setState({
              openCancelConfirm: false,
              currentTransaction: false,
              openSb: false,
            });
          } else {
            this.componentDidMount();

            this.setState({
              openCancelConfirm: false,
              currentTransaction: false,
              openSb: true,
            });
          }
        })
        .catch((error) => {
          //handle error
          console.log(error);
        });
    } else {
      this.setState({
        openCancelConfirm: false,
        currentTransaction: false,
      });
    }
  }

  handlePageChanged(newPage) {
    this.setState({ currentPage: newPage });

    var ctoken = localStorage.getItem("ctoken");
    var token = localStorage.getItem("token");
    var currentOffset = this.state.limit * newPage;
    var bodyFormData = new FormData();
    bodyFormData.append("access_token", ctoken);
    bodyFormData.append("api_key", "72ffc1303c7c0286887a3276f0ab61b8");
    bodyFormData.append("jham_token", token);
    bodyFormData.append("limit", this.state.limit);
    bodyFormData.append("offset", currentOffset);
    bodyFormData.append("active", "Active");

    axios({
      method: "post",
      url: "https://api.earental.com/account/my_profile",
      data: bodyFormData,
      withCredentials: true,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        if (response.data.error > 0) {
          store.addNotification({
            title: "Session expire",
            message: response.data.error_msg,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false,
            },
          });
        } else {
          this.setState({
            ongoingRental: response.data.ongoingrental,
            customer_id: response.data.data.customer_id,
          });
        }
      })
      .catch((error) => {
        //handle error
        this.setState({ loading: false });
        if (error.response.status == 403) {
          store.addNotification({
            title: "Oops Something went wrong",
            message:
              "The action you have requested is not allowed. please re-load the page",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false,
            },
          });
        }
      });
  }

  reloadOngoingRental() {
    this.setState({
      openExtendDialog: false,
    });
    
    var bodyFormData = new FormData();
    bodyFormData.append('api_key', "72ffc1303c7c0286887a3276f0ab61b8");
    bodyFormData.append('access_token', localStorage.getItem('ctoken'));
    bodyFormData.append('jham_token', localStorage.getItem('token'));
    bodyFormData.append('active', "Active");
    bodyFormData.append('limit', this.state.limit);
    bodyFormData.append('offset', this.state.offset);

    axios({
      method: "post",
      url: "https://api.earental.com/account/my_profile",
      data: bodyFormData,
      withCredentials: true,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        if (response.data.error > 0) {
        } else {
          var ongoingRental = this.state.ongoingRental;
          var totalOngoingRental = this.state.totalOngoinRental;
          if (response.data.ongoingrental !== null && response.data.ongoingrental.length > 0) {
            ongoingRental = response.data.ongoingrental;
            totalOngoingRental = response.data.customer_ongoing_rental_total;
          }

          this.setState({
            totalOngoinRental: totalOngoingRental,
            ongoingRental: ongoingRental,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  renderOngoing() {
    function ViewMore(props) {
      const classes = useStyles();
      const [expanded, setExpanded] = React.useState(false);
      const handleExpandClick = (e) => {
        e.stopPropagation();

        if (!expanded) {
        }
        setExpanded(!expanded);
      };

      const handlePdfClick = (e) => {
        e.stopPropagation();

        if (!expanded) {
        }
        setExpanded(!expanded);
      };
      return (
        <>
          <div className="text-right">
            <ButtonGroup
              color="primary"
              aria-label="contained primary button group"
            >
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={props.onClickOpenExtendDialog}
              >
                Extend
              </Button>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={handleExpandClick}
                endIcon={
                  <ExpandMoreIcon
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                  />
                }
              >
                Recurring payment
              </Button>
            </ButtonGroup>
          </div>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <p>Next Payment ( {props.ongoingRental.length} mos )</p>
            <ul className="order-payment-list list mb30">
              {props.ongoingRental.map((rental, i) => (
                <li key={"tree-inv-" + props.sid + "-" + i}>
                  <div className="row">
                    <div className="col-xs-8">
                      <p>
                        {moment(rental.start_date).format("LL")}{" "}
                        <ArrowForwardIcon fontSize="small" />{" "}
                        {moment(rental.end_date).format("LL")}
                      </p>
                    </div>
                    <div className="col-xs-4">
                      <p className="text-right">
                        <span className="text-lg">{rental.payment}</span>
                      </p>
                    </div>
                    {rental.payment == "Paid" && (
                      <div className="col-xs-12 text-right">
                        <ButtonGroup
                          color="primary"
                          aria-label="contained primary button group"
                        >
                          <Button
                            size="small"
                            color="primary"
                            href={
                              "https://api.earental.com/listing/print_invoice/" +
                              props.sid +
                              "/" +
                              props.customerId +
                              "/" +
                              (i + 1)
                            }
                            target="_blank"
                            endIcon={<PictureAsPdfRoundedIcon />}
                          >
                            Invoice PDF
                          </Button>
                        </ButtonGroup>
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </Collapse>
        </>
      );
    }
    if (this.state.noResult) {
      return (
        <div className="vehicle-listing text-center">
          <br />
          <p className="not-found">
            <b>No Active Rental Found</b>
          </p>
          <p>On going rental will be listed here...</p>
        </div>
      );
    } else {
      return (
        <div>
          <ul className="order-payment-list list mb30">
            {this.state.ongoingRental.map((rental) => (
              <li key={rental.data.s_id}>
                <div className="row">
                  <div className="col-xs-8">
                    <p></p>
                    <h5>
                      {" "}
                      <i className="fa fa-truck"></i>{" "}
                      <Chip
                        label={rental.data.s_id}
                        onClick={this.handleGetInvoice.bind(
                          this,
                          rental.data.s_id
                        )}
                      />{" "}
                      <ArrowForwardIcon fontSize="small" />{" "}
                      {rental.data.product_name}{" "}
                    </h5>
                    <p>
                      <small>
                        {moment(rental.data.s_start_date).format("LL")} -{" "}
                        {moment(rental.data.s_end_date).format("LL")}
                      </small>
                      <Link color="secondary" href={"/lease/update-payment/" + rental.data.s_id} style={{ marginLeft: 16 }}>
                        Update Payment
                      </Link>
                    </p>
                    <p>
                    </p>
                  </div>
                  <div className="col-xs-4">
                    {rental.data.s_approve != "" &&
                      parseInt(rental.data.s_release) != 2 && (
                        <p className="text-right">
                          <span className="text-lg">
                            {rental.data.s_status}
                          </span>
                        </p>
                      )}

                    {rental.data.s_approve != "" &&
                      parseInt(rental.data.s_release) == 2 && (
                        <p className="text-right">
                          <span className="text-lg">Completed</span>
                        </p>
                      )}

                    {rental.data.s_approve == "" && (
                      <p className="text-right">
                        <span className="text-lg">Pending</span>
                      </p>
                    )}
                  </div>
                  <div className="col-xs-12">
                    {rental.tree && rental.data.s_approve != "" && (
                      <ViewMore
                        ongoingRental={rental.tree}
                        sid={rental.data.s_id}
                        customerId={rental.data.c_id}
                        handleGetInvoice={this.handleGetInvoice}
                        onClickOpenExtendDialog={this.handleExtendDialogOpenFromList.bind(this, rental.data.s_id)}
                      />
                    )}

                    {!rental.tree && rental.data.s_approve != "" && (
                      <div className="text-right">
                        <ButtonGroup
                          color="primary"
                          aria-label="contained primary button group"
                        >
                          <Button
                            // variant="contained"
                            size="large"
                            color="primary"
                            onClick={this.handleExtendDialogOpenFromList.bind(this, rental.data.s_id)}
                          >
                            Extend
                          </Button>
                          <Button
                            size="small"
                            color="primary"
                            href={
                              "https://api.earental.com/listing/print_invoice/" +
                              rental.data.s_id +
                              "/" +
                              rental.data.c_id +
                              "/1"
                            }
                            target="_blank"
                            endIcon={<PictureAsPdfRoundedIcon />}
                          >
                            Invoice PDF
                          </Button>
                        </ButtonGroup>
                      </div>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <Pager
            total={this.state.totalPages}
            current={this.state.currentPage}
            visiblePages={this.state.visiblePage}
            titles={{ first: "First", last: "Last" }}
            className="pagination-md"
            onPageChanged={this.handlePageChanged}
          />
        </div>
      );
    }
  }

  handleExtendDialogOpen = () => {
    this.setState({
      openExtendDialog: true,
      openInvoice: false,
    });
  };

  handleExtendDialogOpenFromList = (s_id, e) => {
    var bodyFormData = new FormData();
    var token = localStorage.getItem('token');
    var ctoken = localStorage.getItem('ctoken');
    bodyFormData.append('jham_token', token);
    bodyFormData.append('access_token', ctoken);
    bodyFormData.append('s_id', s_id);
    bodyFormData.append('api_key', "72ffc1303c7c0286887a3276f0ab61b8");

    axios({
      method: 'post',
      url: "https://api.earental.com/account/lease_schedule_details",
      data: bodyFormData,
      withCredentials: true,
      headers: { 'Content-Type': "multipart/form-data" },
    })
      .then((response) => {
        if (response.data.error > 0) {
          store.addNotification({
            title: "Error",
            message:
              "Invoice not found, kindly refresh the page and try again.",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false,
            },
          });
        } else {
          this.setState({
            openExtendDialog: true,
            openInvoice: false,
            currentInvoice: response.data.data,
          });
        }
      })
      .catch((error) => {
        //handle error
        console.log(error);
      });
  }

  handleExtendDialogClose = () => {
    this.setState({
      openExtendDialog: false,
      openInvoice: true,
    });
  }

  render() {
    function ModalConfirmCancel(props) {
      const [isOpen, setIsOpen] = React.useState(false);

      const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
      });

      return (
        <Dialog
          open={props.openState}
          TransitionComponent={Transition}
          keepMounted
          onClose={props.cancelTransaction.bind(this, false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth={true}
          maxWidth={"sm"}
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Your about to cancel your rental"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText className="dialogContentCustom">
              {props.rental && (
                <>
                  <b>{props.rental.product_name}</b>
                  <br />
                </>
              )}
              Are you sure you want to proceed?
              <br />
              <br />
              <Alert
                variant="outlined"
                severity="warning"
                elevation={0}
                className="alert-dialog"
              >
                There will be a <b>S$50</b> processing & cancellation fee
              </Alert>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              onClick={props.cancelTransaction.bind(this, false)}
              color="secondary"
            >
              Do it later
            </Button>
            <Button
              variant="contained"
              size="large"
              onClick={props.cancelTransaction.bind(this, true)}
              color="primary"
            >
              Proceed Cancellation
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    function InvoiceModal(props) {
      const classes = useStyles();
      const [currentInvoice, setCurrentInvoice] = useState(
        props.currentInvoice
      );
      const [openInvoice, setOpenInvoice] = useState(props.openInvoice);

      //   var action = [
      //        {
      //            icon: () =>  <DeleteOutline  style={{fill: "red"}} title="test" />,
      //            tooltip: <Typography>Cancel Schedule</Typography>,
      //            onClick: (event, rowData) => handleClickCancel(rowData.s_id)
      //        }
      //    ];

      const handleCloseInvoice = (e) => {
        setCurrentInvoice(false);
        setOpenInvoice(false);
      };

      return (
        <>
          {currentInvoice && (
            <Dialog
              fullWidth={true}
              maxWidth={"md"}
              open={openInvoice}
              onClose={props.closeInvoice}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <Grid container>
                  <Grid item md={6} sm={6} xs={6}>
                    {currentInvoice.s_id}
                  </Grid>
                  <Grid item md={6} sm={6} xs={6} align="right">
                    <p style={{ fontSize: "15px", fontWeight: "300" }}>
                      Transaction Date:{" "}
                      {moment(currentInvoice.s_date_added).format("lll")}
                    </p>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <p>
                  <b>
                    <DateRangeOutlinedIcon /> Rental Information
                  </b>
                </p>
                <Divider />
                <br />

                <Grid container spacing={3} className={classes.newCardForm}>
                  <Grid item container md={3}>
                    <Grid item md={12} sm={12} xs={12}>
                      <img
                        src={
                          "https://api.earental.com/uploads/vehicles/" +
                          currentInvoice.product_image
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container md={9} spacing={3}>
                    <Grid item md={3} sm={12} xs={12}>
                      <TextField
                        label="Category"
                        type="text"
                        value={currentInvoice.category_name}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                      <TextField
                        label="Plate No."
                        type="text"
                        value={currentInvoice.product_platenumber}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <TextField
                        label="Vehicle Name"
                        type="text"
                        value={currentInvoice.product_name}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                      <TextField
                        label="Start Date"
                        type="text"
                        value={currentInvoice.s_start_date}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                      <TextField
                        label="End Date"
                        type="text"
                        value={currentInvoice.s_end_date}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                      <TextField
                        label="Term"
                        type="text"
                        value={currentInvoice.product_rent_terms}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                      <TextField
                        label="Rental Type"
                        type="text"
                        value={currentInvoice.s_payment_mode}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                      <TextField
                        label="Rate"
                        type="text"
                        value={currentInvoice.s_lease_rate}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                      <TextField
                        label="Deposit Amount"
                        type="text"
                        value={currentInvoice.s_deposit_amount}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <br />
                <br />
                <p>
                  <b>
                    <AddShoppingCartOutlinedIcon /> Rental Addons
                  </b>
                </p>
                <Divider />
                <br />
                {!currentInvoice.sa_items && (
                  <Typography>No Addons availed</Typography>
                )}
                {currentInvoice.sa_items && (
                  <Grid container spacing={3} className={classes.newCardForm}>
                    {currentInvoice.sa_items.map((item, i) => {
                      return (
                        <Grid item container key={"sa-" + i} spacing={3}>
                          <Grid item md={4} sm={12} xs={12}>
                            <TextField
                              label="Item"
                              type="text"
                              value={item.trim()}
                              fullWidth
                            />
                          </Grid>
                          <Grid item md={4} sm={12} xs={12}>
                            <TextField
                              label="Quantity"
                              type="text"
                              value={currentInvoice.sa_qtys[i].trim()}
                              fullWidth
                            />
                          </Grid>
                          <Grid item md={4} sm={12} xs={12}>
                            <TextField
                              label="Price"
                              type="text"
                              value={currentInvoice.sa_amounts[i].trim()}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
                <br />
                <br />
                <p>
                  <FaceOutlinedIcon /> <b>First Assigned Driver Information</b>
                </p>
                <Divider />
                <br />

                <Grid container spacing={3} className={classes.newCardForm}>
                  <Grid item md={4} sm={12} xs={12}>
                    <TextField
                      label="Full Name"
                      type="text"
                      value={currentInvoice.cd_driver_name1}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <TextField
                      label="NRIC"
                      type="text"
                      value={currentInvoice.cd_nric1}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <TextField
                      label="License No."
                      type="text"
                      value={currentInvoice.cd_driver_licenseno1}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <TextField
                      label="Contact No."
                      type="text"
                      value={currentInvoice.cd_mobile_no1}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <TextField
                      label="Date of birth"
                      type="text"
                      value={currentInvoice.cd_birthdate1}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <TextField
                      label="Driving Exp"
                      type="text"
                      value={currentInvoice.cd_driver_yearexp1}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      label="Address"
                      type="text"
                      value={currentInvoice.cd_address1}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                {currentInvoice.cd_driver_name2 && (
                  <>
                    <br />
                    <br />
                    <p>
                      <FaceOutlinedIcon /> <b>Second Driver Information</b>
                    </p>
                    <Divider />
                    <br />

                    <Grid container spacing={3} className={classes.newCardForm}>
                      <Grid item md={4} sm={12} xs={12}>
                        <TextField
                          label="Full Name"
                          type="text"
                          value={currentInvoice.cd_driver_name2}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <TextField
                          label="NRIC"
                          type="text"
                          value={currentInvoice.cd_nric2}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <TextField
                          label="License No."
                          type="text"
                          value={currentInvoice.cd_driver_licenseno2}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <TextField
                          label="Contact No."
                          type="text"
                          value={currentInvoice.cd_mobile_no2}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <TextField
                          label="Date of birth"
                          type="text"
                          value={currentInvoice.cd_birthdate2}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <TextField
                          label="Driving Exp"
                          type="text"
                          value={currentInvoice.cd_driver_yearexp2}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <TextField
                          label="Address"
                          type="text"
                          value={currentInvoice.cd_address2}
                          fullWidth
                        />
                      </Grid>

                      <Grid item md={6} sm={6} xs={6}>
                        <div className="clearfix">
                          <div className="">
                            <CardMedia
                              className={classes.media}
                              image={
                                "https://api.earental.com/uploads/profile/" +
                                currentInvoice.cd_photo_nricfront2
                              }
                            />
                            <label>NRIC Front</label>
                          </div>
                        </div>
                      </Grid>

                      <Grid item md={6} sm={6} xs={6}>
                        <div className="clearfix">
                          <div className="">
                            <CardMedia
                              className={classes.media}
                              image={
                                "https://api.earental.com/uploads/profile/" +
                                currentInvoice.cd_photo_nricback2
                              }
                            />
                            <label>NRIC Back</label>
                          </div>
                        </div>
                      </Grid>

                      <Grid item md={6} sm={6} xs={6}>
                        <div className="clearfix">
                          <div className="">
                            <CardMedia
                              className={classes.media}
                              image={
                                "https://api.earental.com/uploads/profile/" +
                                currentInvoice.cd_photo_driverlicfront2
                              }
                            />
                            <label>License Front</label>
                          </div>
                        </div>
                      </Grid>

                      <Grid item md={6} sm={6} xs={6}>
                        <div className="clearfix">
                          <div className="">
                            <CardMedia
                              className={classes.media}
                              image={
                                "https://api.earental.com/uploads/profile/" +
                                currentInvoice.cd_photo_driverlicback2
                              }
                            />
                            <label>License Back</label>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                )}

                <br />
                <br />
              </DialogContent>
              <DialogActions>
                <Box flexGrow={1} textAlign="left">
                  {(props.currentInvoice.s_release != 1) ? (
                    <React.Fragment>
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={props.onClickOpenExtendDialog}
                        disabled={true}
                      >
                        Extend
                      </Button>
                      <Typography variant="body1" component="span" color="error" style={{ marginLeft: 8, fontWeight: 600 }}>
                        Vehicle is not collected/delivered yet
                      </Typography>
                    </React.Fragment>
                  ) : (
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={props.onClickOpenExtendDialog}
                    >
                      Extend
                    </Button>
                  )}
                </Box>

                <Button
                  variant="contained"
                  size="large"
                  color="default"
                  target="_blank"
                  href={
                    "https://api.earental.com/account/view_agreement/lease/" +
                    currentInvoice.s_id +
                    "/" +
                    currentInvoice.vo_id
                  }
                >
                  signed Lease agreement
                </Button>

                <Button
                  variant="contained"
                  size="large"
                  color="default"
                  target="_blank"
                  href={
                    "https://api.earental.com/account/view_agreement/insurance/" +
                    currentInvoice.s_id +
                    "/" +
                    currentInvoice.vo_id
                  }
                >
                  signed Insurance Agreement
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </>
      );
    }
    return (
      <>
        <ModalConfirmCancel
          openState={this.state.openCancelConfirm}
          cancelTransaction={this.cancelTransaction}
          rental={this.state.currentTransaction}
        />
        <InvoiceModal
          currentInvoice={this.state.currentInvoice}
          openInvoice={this.state.openInvoice}
          closeInvoice={this.handleCloseInvoice}
          onClickOpenExtendDialog={this.handleExtendDialogOpen}
        />
        <ScheduleExtension 
          open={this.state.openExtendDialog}
          onClose={this.handleExtendDialogClose}
          onExtend={this.reloadOngoingRental.bind(this)}
          schedule={this.state.currentInvoice}
          api={this.api}
        />
        <h4>Total Rental</h4>
        <ul className="list list-inline user-profile-statictics mb30">
          <li>
            <i className="fa fa-globe user-profile-statictics-icon"></i>
            <h5>{this.state.totalOngoinRental}</h5>
            <p>Rental</p>
          </li>
          <li>
            <i className="fa fa-clock-o user-profile-statictics-icon"></i>
            <h5>{this.state.totalShortTerm}</h5>
            <p>Short Terms</p>
          </li>
          <li>
            <i className="fa fa-calendar user-profile-statictics-icon"></i>
            <h5>{this.state.totalLongTerm}</h5>
            <p>Long Terms</p>
          </li>
          <li>
            <i className="fa fa-road user-profile-statictics-icon"></i>
            <h5>
              <NumberFormat
                value={this.state.loyaltyMiles}
                displayType={"text"}
                decimalScale={2}
                thousandSeparator={true}
              />
            </h5>
            <p>Loyalty Miles</p>
          </li>
        </ul>

        <h4 className="text mb30">Ongoing Rental</h4>
        {this.renderOngoing()}
      </>
    );
  }
}

export default Overview;
