import React from "react";
import axios from 'axios';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Container from "@material-ui/core/Container";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box";
import { grey } from '@material-ui/core/colors';
import { Editor } from '@tinymce/tinymce-react';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = (theme) => ({
  hidden: {
    display: 'none !important',
  },
  breadcrumbs: {
    padding: theme.spacing(3, 2),
  },
  layout: {
    padding: theme.spacing(3, 2),
  },
  title: {
    fontWeight: 300,
    marginBottom: theme.spacing(4),
  },
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
  },
  placeholderWrapper: {
    margin: theme.spacing(2, 0),
  },
  placeholderOptions: {
    marginLeft: theme.spacing(1) - 4,
    marginRight: theme.spacing(1) - 4,
    marginBottom: theme.spacing(1) - 4,
    textTransform: 'inherit',
  },
  editorWrapper: {
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: grey[200],
    minWidth: 800,
    minHeight: 300,
    maxHeight: 600,
    '& *': {
      color: grey[800],
    },
  },
});

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#1a90ff',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

function FacebookCircularProgress(props) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}

class LeaseAgreement extends React.Component {
  constructor(props) {
    super(props);
    this.api = {
      url: "https://api.earental.com",
      key: "72ffc1303c7c0286887a3276f0ab61b8",
      accessToken: localStorage.getItem('ctoken'),
      jhamToken: localStorage.getItem('token'),
    };
    // this.api = {
    //   url: "https://api.earental.dev",
    //   key: "72ffc1303c7c0286887a3276f0ab61b8",
    //   accessToken: localStorage.getItem('ctoken'),
    //   jhamToken: "345cc7dcfebbc38944bde62699389fc1",
    // }
    this.editorKey = "8ycb08a4nuy6xo28rzrfgb1xr052sauiwbn46qqvqukii2pe";
    this.state = {
      init: true,
      openSnackbar: false,
      errorMsg: "",
      errorType: "success",
      useLessor: false,
      standard: "",
      lessor: "",
      editorContent: "",
      previewOrigContent: "",
      placeholders: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    // var formData = new FormData();
    // formData.append('api_key', this.api.key);
    // formData.append('access_token', this.api.accessToken);
    // formData.append('jham_token', this.api.jhamToken);

    // axios({
    //   method: 'post',
    //   url: this.api.url + '/lessor/insurance_letter',
    //   data: formData,
    //   withCredentials: true,
    //   headers: { 'Content-Type': 'multipart/form-data' }
    // })
    // .then((response) => {
    //   let htmlContent = "";
    //   if (response.data.use_lessor == false) {
    //     htmlContent = response.data.standard;
    //   } else {
    //     htmlContent = response.data.lessor;
    //   }
    //   this.setState({
    //     useLessor: response.data.use_lessor,
    //     standard: response.data.standard,
    //     lessor: response.data.lessor,
    //     editorContent: htmlContent
    //   });
    // })
    // .catch((error) => {
    //   // handle error
    // });
  }

  loadTemplates = () => {
    var formData = new FormData();
    formData.append('api_key', this.api.key);
    formData.append('access_token', this.api.accessToken);
    formData.append('jham_token', this.api.jhamToken);

    axios({
      method: 'post',
      url: this.api.url + '/lessor/lease_agreement',
      data: formData,
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((response) => {
      let htmlContent = "";
      if (response.data.use_lessor == false) {
        htmlContent = response.data.standard;
      } else {
        htmlContent = response.data.lessor;
      }
      this.setState({
        init: false,
        useLessor: response.data.use_lessor,
        standard: response.data.standard,
        lessor: response.data.lessor,
        editorContent: htmlContent,
        placeholders: response.data.placeholders,
      });
    })
    .catch((error) => {
      // handle error
      this.setState({
        openSnackbar: true,
        errorMsg: "Something went wrong please try again.",
        errorType: "error",
      });
    });
  }

  handleRadioChange = (event) => {
    let htmlContent = "";
    let useLessor = event.target.value == "true" ? true : false;
    if (useLessor == false) {
      htmlContent = this.state.standard;
    } else {
      htmlContent = this.state.lessor;
    }
    this.setState({
      useLessor: useLessor,
      editorContent: htmlContent,
    });
  };

  handleTemplateSwitch = () => {
    if (this.state.editorContent != "") {
      let formData = new FormData();
          formData.append('api_key', this.api.key);
          formData.append('access_token', this.api.accessToken);
          formData.append('jham_token', this.api.jhamToken);
          formData.append('use_lessor', this.state.useLessor);
      axios({
        method: 'post',
        url: this.api.url + '/lessor/switch_lease_agreement',
        data: formData,
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        if (response.data.error != 0) {
          this.setState({
            openSnackbar: true,
            errorMsg: "Something went wrong please try again.",
            errorType: "error",
          });
        } else {
          this.setState({
            openSnackbar: true,
            errorMsg: "Successfully switched agreement template to use.",
            errorType: "success",
          });
        }
      })
      .catch((error) => {
        // handle error
        this.setState({
          openSnackbar: true,
          errorMsg: "Something went wrong please try again, contact support if possible.",
          errorType: "error",
        });
      });

    } else {
      this.setState({
        openSnackbar: true,
        errorMsg: "Your letter template is empty",
        errorType: "error",
      });
    }
  }

  handleCopyStandard = () => {
    window.tinymce.activeEditor.setContent(this.state.standard);
    this.setState({
      lessor: this.state.standard,
    });
  }

  handleEditorChange = (content, editor) => {
    this.setState({
      editorContent: content,
    });
    if (this.state.useLessor) {
      this.setState({
        lessor: content,
      });
    }
  }

  handleInsertPlaceholder = (placeholder) => {
    window.tinymce.execCommand('mceInsertContent', false, placeholder);
  }

  handleUpdateAgreement = () => {
    if (this.state.editorContent != "") {
      let formData = new FormData();
      formData.append('api_key', this.api.key);
      formData.append('access_token', this.api.accessToken);
      formData.append('jham_token', this.api.jhamToken);
      formData.append('lease_agreement', this.state.editorContent);

      axios({
        method: 'post',
        url: this.api.url + '/lessor/update_lease_agreement',
        data: formData,
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        if (response.data.error != 0) {
          this.setState({
            openSnackbar: true,
            errorMsg: "Something went wrong please try again.",
            errorType: "error",
          });
        } else {
          this.setState({
            openSnackbar: true,
            errorMsg: "Your letter template was successfully updated",
            errorType: "success",
            lessor: this.state.editorContent,
          });
        }
      })
      .catch((error) => {
        // handle error
        this.setState({
          openSnackbar: true,
          errorMsg: "Something went wrong please try again, contact support if possible.",
          errorType: "error",
        });
      });
    } else {
      this.setState({
        openSnackbar: true,
        errorMsg: "Your letter template is empty",
        errorType: "error",
      });
    }
  }

  handleSnackbarClose = () => {
    this.setState({
      openSnackbar: false,
    })
  }

  render() {
    const { classes } = this.props;

    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    return (
      <React.Fragment>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
          <Link color="inherit" href="/">
            Dashboard
          </Link>
          <Typography color="textPrimary">
            Insurance Letter
          </Typography>
        </Breadcrumbs>
        <Container component="main" maxWidth="lg" className={classes.layout}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={3}>
              <Typography variant="h5" gutterBottom>
                Select a template to use:
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup value={this.state.useLessor} onChange={this.handleRadioChange}>
                  <FormControlLabel control={<Radio color="primary" value={false} />} label="Standard" disabled={this.state.init} />
                  <FormControlLabel control={<Radio color="primary" value={true} />} label="Your template" disabled={this.state.init} />
                </RadioGroup>
              </FormControl>
              <Box textAlign="right">
                <Button size="small" variant="contained" color="primary" onClick={this.handleTemplateSwitch} disabled={this.state.init}>
                  Update
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography variant="h4" component="h4" className={classes.title}>
                Lease Agreement
              </Typography>
              <Box className={!this.state.init ? "" : classes.hidden}>
                {!this.state.init && this.state.useLessor && (
                  <Box textAlign="right">
                    <Button color="primary" onClick={this.handleCopyStandard}>
                      Copy Standard Template
                    </Button>
                  </Box>
                )}
                <Editor
                  apiKey={this.editorKey}
                  init={{
                    height: 500,
                    plugins: [
                      'advlist lists charmap preview code table searchreplace',
                    ],
                    toolbar:
                      'undo redo | formatselect | fontsizeselect forecolor backcolor bold italic underline | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | preview',
                    init_instance_callback: (editor) => {
                      this.loadTemplates();
                      editor.on('BeforeExecCommand', (e) => {
                        if (e.command == "mcePreview") {
                          // //store content prior to changing.
                          this.state.previewOrigContent = editor.getContent();
                          var editorContent = editor.getContent();
                          this.state.placeholders.map((value, index) => {
                            editorContent = replaceAll(editorContent, value.placeholder, value.value);
                          });
                          editor.setContent(editorContent);
                        }
                      });
                      editor.on("ExecCommand", (e) => {
                        if (e.command == "mcePreview") {
                          //Restore initial content.
                          editor.setContent(this.state.previewOrigContent);
                        }
                      });
                    }
                  }}
                  value={this.state.editorContent}
                  onEditorChange={this.handleEditorChange}
                  disabled={!this.state.useLessor}
                />
              </Box>
              {this.state.init && (
                <Box className={classes.loadingWrapper}>
                  <FacebookCircularProgress />
                </Box>
              )}
              {!this.state.init && this.state.useLessor && (
                <Box className={classes.placeholderWrapper}>
                  <Typography variant="h6" component="p" gutterBottom>
                    Click on a placeholder to insert:
                  </Typography>
                  {this.state.placeholders.map((value, i) => {
                    return (
                      <Button
                        key={i}
                        variant="contained"
                        size="small"
                        className={classes.placeholderOptions}
                        onClick={() => this.handleInsertPlaceholder(value.placeholder)}
                      >
                        {value.label}
                      </Button>
                    )
                  })}
                </Box>
              )}
              {!this.state.init && this.state.useLessor && (
                <Box textAlign="right">
                  <Button size="large" variant="contained" color="primary" onClick={this.handleUpdateAgreement}>
                    Save Changes
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>

          <Snackbar open={this.state.openSnackbar} autoHideDuration={6000} onClose={this.handleSnackbarClose}>
            <Alert onClose={this.handleSnackbarClose} severity={this.state.errorType} className="sb-msg">
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
        </Container>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(LeaseAgreement);

function replaceAll (str, find, replace) {
  // return str.replace(new RegExp(find, 'g'), replace);
  return str.split(find).join(replace);
}