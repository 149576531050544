import React from "react";
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  stepper: {
    padding: theme.spacing(4, 0, 5),
    marginBottom: theme.spacing(5),
  }
}));

const theme = createMuiTheme({
  overrides: {
    MuiStep: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiStepIcon: {
      root: {
        fontSize: '2.5rem',
        fontWeight: 500,
        '&$completed': {
          color: '#2F96B4',
        },
        '&$active': {
          color: '#2F96B4',
        },
      },
      text: {
        fontSize: '1rem',
      },
    },
    MuiStepLabel: {
      label: {
        '&$alternativeLabel': {
          color: grey[600],
          fontSize: '1.25rem',
          fontWeight: 400,
          '&$active': {
            color: grey[800],
            fontWeight: 500,
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiStepConnector: {
      root: {
        '&$alternativeLabel': {
          top: 14,
        },
      }
    }
  },
});

export default function CheckoutStepper(props) {
  const classes = useStyles();
  const steps = ['Checkout', 'Driver', 'Agreement', 'Insurance', 'Payment'];
  const stepsCompleted = props.completed;
  const stepLinks = [
    '/checkout',
    '/checkout/driver',
    '/checkout/agreement',
    '/checkout/insurance',
    '/checkout/payment',
  ];

  const handleStepClick = (index) => {
    console.log(stepsCompleted);
    if (index == 0 || stepsCompleted.indexOf(index) > -1) {
      props.history.push(stepLinks[index]);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Hidden only="xs">
        <Paper elevation={0}>
          <Stepper activeStep={props.active} nonLinear alternativeLabel className={classes.stepper}>
            {steps.map((label, index) => (
              <Step key={label} onClick={() => handleStepClick(index)} completed={stepsCompleted.indexOf(index + 1) > -1}>
                <StepLabel>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Paper>
      </Hidden>
    </ThemeProvider>
  );
}