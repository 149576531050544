import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { store } from 'react-notifications-component';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: 'none',
  },
  dialogContent: {
    paddingBottom: theme.spacing(4),
    '& .MuiTypography-body1': {
      fontSize: '1.25rem',
    },
  },
  dialogActions: {
    justifyContent: 'space-between',
    '& button': {
      fontSize: '1rem',
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditAddress(props) {
  const classes = useStyles();
  const api = {
    url: props.mainState.apiURL,
    key: props.mainState.apiKey,
    accessToken: localStorage.getItem('ctoken'),
    jhamToken: localStorage.getItem('token'),
    // url: 'https://api.earental.dev',
    // jhamToken: "2b648ed81a5a93e6b20449f254b53d48",
  };
  const [address, setAddress] = React.useState("");
  const [deleting, setDeleting] = React.useState(false);
  
  React.useEffect(() => {
    var address = props.addressDetails;
    if (props.open) {
      setAddress(buildAddress(address));
    };
  }, [props.open]);

  const buildAddress = (billingAddress) => {
    var address = capitalize(billingAddress.type) + " " + billingAddress.block + " " + billingAddress.street;
    if (billingAddress.floor && billingAddress.unit) {
      address += ", #" + billingAddress.floor + "-" + billingAddress.unit;
    }
    if (billingAddress.building) {
      if (!billingAddress.floor && !billingAddress.unit) {
        address += ", " + billingAddress.building;
      } else {
        address += " " + billingAddress.building;
      }
    }
    address += ", " + billingAddress.country + " " + billingAddress.postal;
    return address;
  }

  const handleDeleteButton = (event) => {
    event.preventDefault();
    setDeleting(true);
    let bodyFormData = new FormData();
        bodyFormData.append('api_key', api.key);
        bodyFormData.append('access_token', api.accessToken);
        bodyFormData.append('jham_token', api.jhamToken);
        bodyFormData.append('address_id', props.addressDetails.id);

    axios({
      method: 'post',
      url: api.url + '/account/delete_address',
      data: bodyFormData,
      withCredentials: true,
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((response) => {
      if (response.data.error > 0) {
        function ErrorMsg(props) {
          return (
            props.msgs.map((item, i) =>
              <li key={i}>{item.value}</li>
            )
          );
        }
        store.addNotification({
          title: "Invalid information",
          message: <ErrorMsg msgs={response.data.error_message} />,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 20000,
            onScreen: false
          }
        });
      } else {
        props.onAddressUpdated();
      }
      setDeleting(false);
    })
    .catch((error) => {
      // Handle error
      if (error.response && error.response.status == 403) {
        store.addNotification({
          title: "Oops Something went wrong",
          message: "The action you have requested is not allowed. Please reload the page",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: false
          }
        });
      }
    });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={props.open}
      TransitionComponent={Transition}
      onClose={props.onClose}
      className={classes.root}
    >
      <DialogTitle>
        Delete Billing Address
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body1">
          You are about to delete address <strong>{address}</strong>. Do you want to continue?
        </Typography>
        <Typography variant="body1">
          
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <React.Fragment>
          <Button type="button" onClick={props.onClose} disabled={deleting}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleDeleteButton} disabled={deleting}>
            Delete Address
          </Button>
        </React.Fragment>
      </DialogActions>
    </Dialog>
  );
}

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}